/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */
/* eslint-disable unicorn/prefer-add-event-listener */
import { useCallback, useContext, useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

const useConfirmExit = (confirmExit, when = true) => {
	const { navigator } = useContext(NavigationContext);

	useEffect(() => {
		if (!when) {
			return;
		}

		const push = navigator.push;

		navigator.push = (...args) => {
			const result = confirmExit();
			if (result !== false) {
				push(...args);
			}
		};

		return () => {
			navigator.push = push;
		};
	}, [navigator, confirmExit, when]);
};

const usePrompt = (message, when = true) => {
	useEffect(() => {
		const handleTabClose = (event) => {
			event.preventDefault();

			return (event.returnValue = message);
		};

		const handleBackPress = (event) => {
			event.preventDefault();

			return (event.returnValue = message);
		};

		if (when) {
			window.addEventListener("beforeunload", handleTabClose);
			window.addEventListener("onpopstate", handleBackPress);

			window.onbeforeunload = function () {
				return message;
			};
		}

		return () => {
			window.removeEventListener("beforeunload", handleTabClose);
			window.removeEventListener("popstate", handleBackPress);
			window.onbeforeunload = null;
		};
	}, [message, when]);

	const confirmExit = useCallback(() => {
		// eslint-disable-next-line no-alert
		const confirm = window.confirm(message);
		return confirm;
	}, [message]);
	useConfirmExit(confirmExit, when);
};

export default usePrompt;
