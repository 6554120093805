import { useState, memo, useEffect, forwardRef } from "react";
import { makeStyles } from "@mui/styles";
import { Paper, Dialog, DialogTitle, DialogContent, DialogContentText, Grid, Button, Typography, Slide } from "@mui/material";

const useStyles = makeStyles((theme) => ({
	boxBorder: {
		border: `2px solid ${theme.palette.primary.main}`,
		borderRadius: theme.spacing(2),
	},
}));

const Transition = forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

// eslint-disable-next-line max-len
const StatusDialog = ({ open: openDialog, status: projectStatus, name: projectName, onClose: closeCallaback, onChange: changeCallback }) => {
	const [open, setOpen] = useState(openDialog);
	const [status, setStatus] = useState(projectStatus);
	const [name, setName] = useState(projectName);

	const classes = useStyles();

	useEffect(() => {
		setOpen(openDialog);
		setStatus(projectStatus);
		setName(projectName);
	}, [openDialog, projectStatus, projectName]);

	return (
		<Paper className={classes.dialog} elevation={10}>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				maxWidth="md"
				scroll="body"
				PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4", maxWidth: "700px", zIndex: 999 } }}
				onClose={() => closeCallaback(false)}
			>
				<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
					<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
						{"Project Status"}
					</Typography>
				</DialogTitle>
				<DialogContent dividers sx={{ overflowY: "hidden" }}>
					<DialogContentText>
						<Grid item container sx={{ p: 1 }} alignItems="start" justifyContent="center">
							<Typography>
								{"Are you sure you want to"}
								&nbsp;
								<b>{status === "In Progress" ? "submit" : "revert"}</b>
								&nbsp;
								{"the Project:"}
								&nbsp;
								<b>{name}</b>
							</Typography>
						</Grid>
					</DialogContentText>
					<Grid container mt={3} alignItems="center" justifyContent="space-between">
						<Grid item sm={3} display="flex" justifyContent="start">
							<Button
								fullWidth
								variant="outlined"
								size="large"
								sx={{ width: "150px", height: "40px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
								color="primary"
								onClick={() => closeCallaback(false)}
							>
								{"Cancel"}
							</Button>
						</Grid>
						<Grid item sm={1} />
						<Grid item display="flex" sm={3} justifyContent="end">
							<Button
								fullWidth
								variant="contained"
								size="large"
								sx={{ width: "150px", height: "40px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
								color="primary"
								onClick={() => { changeCallback(); closeCallaback(false); }}
							>
								{status === "In Progress" ? "Submit" : "Revert"}
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</Paper>
	);
};

export default memo(StatusDialog);
