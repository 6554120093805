import { Typography, Link } from "@mui/material";
import PropTypes from "prop-types";

import sefaTheme from "../../utils/theme";

const LoginLinkText = ({ text, link }) => (
	<Typography variant="h7" sx={{ color: sefaTheme.palette.green.main, letterSpacing: sefaTheme.spacing(0.1) }}>
		<Link color="inherit" sx={{ "&:hover": { color: sefaTheme.palette.green.light } }} underline="none" href={link}>
			{text}
		</Link>
	</Typography>
);

LoginLinkText.propTypes = {
	text: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
};

export default LoginLinkText;
