const names = {
	profit_loss: {
		name: "Income Statement",
		revenue: "Revenue",
		service_revenue: "Service Revenue",
		software_sales: "Software Sales",
		hardware_sales: "Hardware Sales",
		cost_of_sales: "Cost of Sales",
		cost_of_sales_value: "Cost of Sales",
		gross_profit: "Gross Profit",
		other_income: "Other Income",
		rent: "Rent",
		royalties: "Royalties (technical process, patent, trade mark)",
		distribution_costs: "Distribution Costs",
		delivery_expense: "Delivery Expense",
		depreciation_of_delivery_vehicles: "Depreciation of Delivery Vehicles",
		sales_prospecting_market_research: "Sales Prospecting/Market Research",
		marketing_promotion_expenses: "Marketing/Promotion Expenses",
		sales_commission: "Sales Commission",
		administrative_costs: "Administrative Costs",
		administrative_salaries: "Administrative Salaries",
		insurance: "Insurance",
		accounting_legal_fees: "Accounting/Legal Fees",
		office_supplies: "Office Supplies",
		utilities: "Utilities (internet, water, electricity, ...)",
		licenses_and_permits: "Licenses and Permits",
		office_rental: "Office Rental",
		bank_fees: "Bank Fees",
		other_expenses: "Other Expenses",
		consulting: "Consulting",
		membership_fees: "Membership Fees",
		repair_and_maintenance: "Repair and Maintenance",
		vehicle_operating_costs: "Vehicle Operating Costs",
		royalty_fees: "Royalty Fees",
		ebitda: "Earnings before Interests, Tax, Depreciation, Amortization",
		financial_income_interest_income: "Financial Income - Interest Income",
		financial_income_interest_income_value: "Financial Income - Interest Income",
		financial_income_other: "Financial Income - Other",
		interest_deposit: "Interest Deposit",
		foreign_exchange_gain: "Foreign Exchange Gain",
		other_gains_and_losses: "Other Gains and Losses",
		other_gains_and_losses_value: "Other Gains and Losses",
		finance_costs: "Finance Costs",
		interest_payments: "Interest Payments",
		foreign_exchange_loss: "Foreign Exchange Loss",
		depreciation: "Depreciation",
		depreciation_value: "Depreciation",
		earnings_before_tax: "Earnings before Tax",
		income_tax: "Income Tax",
		income_tax_value: "Income Tax",
		profit_after_tax: "Profit after Tax",
	},
	financial_position: {
		name: "Balance Sheet",
		goodwill: "Goodwill",
		intangible_assets: "Intangible Assets",
		property_plant_and_equipment: "Property, Plant and Equipment",
		investment_property: "Investment Property",
		other_tangible_assets: "Other Tangible Assets",
		financial_assets: "Financial Assets",
		finance_lease_receivables: "Finance Lease Receivables",
		other_non_current_assets: "Other Non-Current Assets",
		total_non_current_assets: "Total Non-Current Assets",
		inventories: "Inventories",
		investments: "Investments",
		finance_lease_receivables_current: "Finance Lease Receivables",
		trade_and_other_receivables: "Trade and Other Receivables",
		other_current_assets: "Other Current Assets",
		assets_classified_as_held_for_sale: "Assets Classified as Held for Sale",
		cash_and_bank_balances: "Cash and Bank Balances",
		total_current_assets: "Total Current Assets",
		total_assets: "Total Assets",
		issued_share_capital_and_share_premium: "Issued Share Capital and Share Premium",
		other_reserves: "Other Reserves",
		retained_earnings: "Retained Earnings",
		total_equity: "Total Equity",
		borrowings: "Borrowings",
		convertible_loan_notes: "Convertible Loan Notes",
		obligations_under_finance_lease: "Obligations under Finance Lease",
		long_term_provisions: "Long-Term Provisions",
		other_non_current_liabilities: "Other Non-Current Liabilities",
		total_non_current_liabilities: "Total Non-Current Liabilities",
		trade_and_other_payables: "Trade and Other Payables",
		current_tax_liabilities: "Current Tax Liabilities",
		obligations_under_finance_lease_current: "Obligations under Finance Lease",
		borrowings_current: "Borrowings",
		provisions: "Provisions",
		other_financial_liabilities: "Other Financial Liabilities",
		other_current_liabilities: "Other Current Liabilities",
		total_current_liabilities: "Total Current Liabilities",
		total_liabilities: "Total Liabilities",
		total_equity_and_liabilities: "Total Equity and Liabilities",
		goodwill_value: "Goodwill",
		computer_software: "Computer Software",
		patent_copyrights_licenses: "Patents, Copyrights, Licenses",
		right_of_use: "Right of Use (lease contract)",
		development_activities: "Development Activities",
		land_and_buildings: "Land and Buildings",
		machinery_production_equipment: "Machinery/Production Equipment",
		vehicles: "Vehicles",
		office_equipment: "Office Equipment",
		investment_property_value: "Investment Property",
		other_tangible_assets_value: "Other Tangible Assets",
		investment_in_associates: "Investment in Associates",
		interests_in_joint_ventures: "Interests in Joint Ventures",
		investment_in_financial_assets: "Investment in Financial Assets",
		finance_lease_receivables_value: "Finance Lease Receivables",
		contract_assets: "Contract Assets",
		contract_costs: "Contract Costs",
		inventories_value: "Inventories",
		investments_value: "Investments",
		finance_lease_receivables_current_value: "Finance Lease Receivables",
		trade_and_other_receivables_value: "Trade and Other Receivables",
		differed_expenses: "Differed Expenses",
		contract_assets_current: "Contract Assets",
		contract_costs_current: "Contract Costs",
		advance_payments: "Advance Payments",
		assets_classified_as_held_for_sale_value: "Assets Classified as Held for Sale",
		cash_and_bank_balances_value: "Cash and Bank Balances",
		issued_share_capital_and_share_premium_value: "Issued Share Capital and Share Premium",
		other_reserves_value: "Other Reserves",
		retained_earnings_value: "Retained Earnings",
		borrowings_value: "Borrowings",
		convertible_loan_notes_value: "Convertible Loan Notes",
		obligations_under_finance_lease_value: "Obligations under Finance Lease",
		long_term_provisions_value: "Long-Term Provisions",
		retirement_benefit_obligations: "Retirement Benefit Obligations",
		deferred_income_government_grant: "Deferred Income - Government Grant",
		contract_liabilities: "Contract Liabilities",
		lease_incentives: "Lease Incentives",
		trade_and_other_payables_value: "Trade and Other Payables",
		current_tax_liabilities_value: "Current Tax Liabilities",
		obligations_under_finance_lease_current_value: "Obligations under Finance Lease",
		borrowings_current_value: "Borrowings",
		provisions_value: "Provisions",
		short_term_loan_payable: "Short-Term Loan Payable",
		current_portion_of_long_term_debt: "Current Portion of Long-Term Debt",
		interest_payable: "Interest Payable",
		deferred_income_government_grant_current: "Deferred Income - Government Grant",
		contract_liabilities_current: "Contract Liabilities",
		customer_prepayments: "Customer Prepayments",
		refund_liability: "Refund Liability",
		liabilities_directly_associated: "Liabilities directly Associated with Assets Classified as Held for Sale",
	},
	cash_flow: {
		name: "Cash Flow",
		depreciation: "Depreciation",
		loss_on_sale_of_ppe: "Loss on Sale of PPE",
		interest_expense: "Interest Expense",
		cash_from_operations_before_working_capital_changes: "Cash from Operations before Working Capital Changes",
		decrease_in_trade_and_other_receivables: "Decrease in Trade and other Receivables",
		increase_in_inventories: "Increase in Inventories",
		increase_in_payables: "Increase in Payables",
		cash_generated_from_operations: "Cash generated from Operations",
		interest_paid: "Interest paid",
		dividend_paid: "Dividend paid",
		income_taxes_paid: "Income Taxes paid",
		net_cash_from_operating_activities: "Net Cash from Operating Activities",
		purchase_of_ppe: "Purchase of PPE",
		proceeds_from_sale_of_ppe: "Proceeds from Sale of PPE",
		net_cash_used_in_investing_activities: "Net Cash used in Investing Activities",
		proceeds_from_issue_of_share_capital: "Proceeds from Issue of Share Capital",
		payment_of_long_term_borrowings: "Payment of long-term Borrowings",
		proceeds_from_finance_lease_liabilities: "Proceeds from Finance Lease Liabilities",
		net_cash_from_financing_activities: "Net Cash from Financing Activities",
		net_increase_in_cash_and_cash_equivalents: "Net Increase in Cash and Cash Equivalents",
		cash_and_cash_equivalents_at_the_beginning_of_period: "Cash and Cash Equivalents at the Beginning of Period",
		cash_and_cash_equivalents_at_the_end_of_the_period: "Cash and Cash Equivalents at the End of the Period",
		loss_on_sale_of_ppe_value: "Loss on Sale of PPE",
		interest_expense_value: "Interest Expense",
		decrease_in_trade_and_other_receivables_value: "Decrease in Trade and other Receivables",
		increase_in_inventories_value: "Increase in Inventories",
		increase_in_payables_value: "Increase in Payables",
		interest_paid_value: "Interest paid",
		dividend_paid_value: "Dividend paid",
		income_taxes_paid_value: "Income Taxes paid",
		purchase_of_ppe_value: "Purchase of PPE",
		proceeds_from_sale_of_ppe_value: "Proceeds from Sale of PPE",
		proceeds_from_issue_of_share_capital_value: "Proceeds from Issue of Share Capital",
		payment_of_long_term_borrowings_value: "Payment of long-term Borrowings",
		proceeds_from_finance_lease_liabilities_value: "Proceeds from Finance Lease Liabilities",
		cash_and_cash_equivalents_at_the_beginning_of_period_value: "Cash and Cash Equivalents at the Beginning of Period",
	},
	kpis: {
		name: "KPIs",
		gross_profit_margin: "Gross Profit Margin",
		other_income_as_a_percentage_of_revenue: "Other Income as a % of Revenue",
		distribution_costs_as_a_percentage_of_revenue: "Distribution Costs as a % of Revenue",
		administrative_costs_as_a_percentage_of_revenue: "Administrative Costs as a % of Revenue",
		other_expenses_as_a_percentage_of_revenue: "Other Expenses as a % of Revenue",
		operation_cash_flow_as_a_percentage_of_revenue: "Operation Cash Flow as a % of Revenue",
		ebt_as_a_percentage_of_revenue: "EBT as a % of Revenue",
		profit_after_tax_as_a_percentage_of_revenue: "Profit after Tax as a % of Revenue",
		current_ratio: "Current Ratio (current assets/current liabilities)",
		acid_test_ratio: "Acid Test Ratio (current assets less inventories/current liabilities)",
		working_capital: "Working Capital",
		debt_to_equity_ratio: "Debt to Equity Ratio",
	},
	output: {
		name: "Overview",
		revenue_output: "Revenue",
		gross_profit_output: "Gross Profit",
		ebitda_output: "Earnings before Interests, Tax, Depreciation, Amortization",
		profit_after_tax_output: "Profit after Tax",
		total_assets_output: "Total Assets",
		total_liabilities_output: "Total Liabilities",
		cashflow_output: "Cashflow",
	},
	year_3: "Year 3",
	year_2: "Year 2",
	year_1: "Year 1",
	current_quarter_1: "Q1",
	current_quarter_2: "Q2",
	current_quarter_3: "Q3",
	current_quarter_4: "Q4",
	forecast_1_quarter_1: "Q1",
	forecast_1_quarter_2: "Q2",
	forecast_1_quarter_3: "Q3",
	forecast_1_quarter_4: "Q4",
	forecast_2_quarter_1: "Q1",
	forecast_2_quarter_2: "Q2",
	forecast_2_quarter_3: "Q3",
	forecast_2_quarter_4: "Q4",
};

export default names;
