import { memo, useCallback, useEffect, useState, forwardRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Button, Grid, LinearProgress, MenuItem, Typography, Link, ToggleButton, Dialog, Slide, DialogTitle, DialogContent, Paper, DialogContentText, DialogActions, Switch, InputAdornment, Box, FormControl, InputLabel, Select, Tab, Tabs } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import querySearch from "stringquery";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import TextField from "../../components/TextField";
import { jwt, useSnackbar, httpResponseIsValid } from "../../utils";
import { deleteFile, signingCompleted, getProjectContract, updateProjectContract, getUserData } from "../../api";
import FileUpload from "../../components/FileUpload";
import Spinner from "../../components/Spinner";
import usePrompt from "../../utils/use-blocker";

const Transition = forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const { REACT_APP_MAIN_SERVER_URL } = process.env;

const localization = {
	UK: {
		text: "English - UK",
		code: "en",
	},
	Deutschland: {
		text: "Deutsch - Deutschland",
		code: "de",
	},
	Italia: {
		text: "Italiano - Italia",
		code: "it",
	},
	France: {
		text: "France - Français",
		code: "fr",
	},
};

const useStyles = makeStyles((theme) => ({
	menuBox: {
		width: "100%",
	},
	boxBorder: {
		border: `2px solid ${theme.palette.primary.main}`,
		borderRadius: theme.spacing(2),
		backgroundColor: "white",
	},
	screenTitle: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		letterSpacing: theme.spacing(0.2),
	},
}));

const currencies = {
	euro: "Euro (€)",
	dollar: "Dollar ($)",
	pound: "GBP (£)",
	chf: "CHF",
	zloty: "Zloty (zl)",
};

const outputs = {
	lighting: "Lighting",
	heating: "Heating",
	cooling: "Cooling",
	energy_generation: "Energy generation",
	other: "Other",
};

const interests = [
	"SONIA",
	"ESTR",
	"EURIBOR",
	"Base Lending Rate of Service Provider Bank",
];

const AvailableTabs = ["", "company_details", "service_subscriber_details", "contract_schedules", "finished"];

const Contract = ({ disableAccess = true, isProjectDeveloper = false }) => {
	const { projectid } = useParams();
	const { search } = useLocation();
	const { event } = querySearch(search);

	const classes = useStyles();

	const { t, i18n } = useTranslation();

	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(disableAccess);
	const [isLoading, setIsLoading] = useState(false);
	const [spinnerOpen, setSpinnerOpen] = useState(false);
	const [errors, setErrors] = useState({
		service_provider_name: false,
		director_name: false,
		country_of_registration: false,
		company_number: false,
		registered_office_address: false,
		registered_office_postcode: false,
		registered_office_city: false,
		output_type: false,
		business_hours: false,
		service_subscriber_name: false,
		service_subscriber_email: false,
		service_subscriber_director_name: false,
		service_subscriber_country_of_registration: false,
		service_subscriber_company_number: false,
		service_subscriber_registered_office_address: false,
		service_subscriber_registered_office_postcode: false,
		service_subscriber_registered_office_city: false,
		professional_indemnity_insurance_amount: false,
		days_to_pay_security_deposit: false,
		interest: false,
		registered_office_currency: false,
		schedule_1: false,
		schedule_2: false,
		schedule_3: false,
		schedule_4: false,
		schedule_5: false,
		schedule_6: false,
		schedule_7: false,
		custom_contract: false,
	});
	const [projectInfo, setProjectInfo] = useState({
		saved_tab: "",
		service_provider_name: "",
		director_name: "",
		country_of_registration: "",
		company_number: "",
		registered_office_address: "",
		registered_office_postcode: "",
		registered_office_city: "",
		registered_office_currency: "euro",
		registered_office_location: "UK",
		output_type: "",
		business_hours_from: "08:00",
		business_hours_to: "17:00",
		service_subscriber_name: "",
		service_subscriber_email: "",
		service_subscriber_director_name: "",
		service_subscriber_country_of_registration: "",
		service_subscriber_company_number: "",
		service_subscriber_registered_office_address: "",
		service_subscriber_registered_office_postcode: "",
		service_subscriber_registered_office_city: "",
		professional_indemnity_insurance_amount: 0,
		days_to_pay_security_deposit: 0,
		interest_percentage: 0,
		interest_option: "",
		schedule_1: {
			original_name: "",
			save_name: "",
		},
		schedule_2: {
			original_name: "",
			save_name: "",
		},
		schedule_3: {
			original_name: "",
			save_name: "",
		},
		schedule_4: {
			original_name: "",
			save_name: "",
		},
		schedule_5: {
			original_name: "",
			save_name: "",
		},
		schedule_6: {
			original_name: "",
			save_name: "",
		},
		schedule_7: {
			original_name: "",
			save_name: "",
		},
		contract_signed: {
			original_name: "",
			save_name: "",
		},
		schedule_1_signed: {
			original_name: "",
			save_name: "",
		},
		schedule_2_signed: {
			original_name: "",
			save_name: "",
		},
		schedule_3_signed: {
			original_name: "",
			save_name: "",
		},
		schedule_4_signed: {
			original_name: "",
			save_name: "",
		},
		schedule_5_signed: {
			original_name: "",
			save_name: "",
		},
		schedule_6_signed: {
			original_name: "",
			save_name: "",
		},
		schedule_7_signed: {
			original_name: "",
			save_name: "",
		},
		custom_contract: false,
		custom_contract_file: {
			original_name: "",
			save_name: "",
		},
		custom_contract_signed: {
			original_name: "",
			save_name: "",
		},
		status: "-",
		createdAt: "-",
	});
	const { success, error } = useSnackbar();
	const [deleteModal, setDeleteModal] = useState({ component: null, variable: null });

	const [enableEditing, setEnableEditing] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);
	const [savedData, setSavedData] = useState("");

	const [docusignDialogOpen, setDocusignDialogOpen] = useState(false);

	const [tabError, setTabError] = useState("");
	const [selectedTab, setSelectedTab] = useState("company_details");

	usePrompt("You have unsaved changes which will be lost. Are you sure you want to leave the page?", dataChanged);

	const disableTab = (tab) => {
		if (shouldUiBeDisabled) {
			return true;
		}

		const savedTabIndex = AvailableTabs.indexOf(projectInfo.saved_tab);
		const selectedIndex = AvailableTabs.indexOf(tab);

		if (selectedIndex > savedTabIndex + 1) {
			return true;
		}

		return false;
	};

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			let response;
			try {
				response = await getProjectContract(projectid);
				if (httpResponseIsValid(response)) {
					setProjectInfo({
						...response,
					});

					setSavedData({
						...response,
					});

					const languageCode = localization?.[response?.registered_office_location];
					if (languageCode) {
						i18n.changeLanguage(languageCode.code);
					}

					setDataChanged(false);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured while fetching contracts's data: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, i18n, projectid],
	);

	const fetchSchedules = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getProjectContract(projectid);
				if (httpResponseIsValid(response)) {
					setProjectInfo({
						...projectInfo,
						schedule_1: response?.schedule_1,
						schedule_2: response?.schedule_2,
						schedule_3: response?.schedule_3,
						schedule_4: response?.schedule_4,
						schedule_5: response?.schedule_5,
						schedule_6: response?.schedule_6,
						schedule_7: response?.schedule_7,
						custom_contract_file: response?.custom_contract_file,
					});
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured while fetching contracts's schedules: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, projectInfo, projectid],
	);

	const submit = useCallback(
		async (displayMessage = false, customContract = null) => {
			setIsLoading(true);

			try {
				const savedTabIndex = AvailableTabs.indexOf(projectInfo.saved_tab);
				const selectedIndex = AvailableTabs.indexOf(selectedTab);

				const newTab = selectedIndex > savedTabIndex ? selectedTab : projectInfo.saved_tab;

				const response = await updateProjectContract(
					// eslint-disable-next-line max-len
					projectid, (customContract === null) ? { ...projectInfo, saved_tab: newTab } : { ...projectInfo, custom_contract: customContract },
				);

				if (httpResponseIsValid(response)) {
					await fetchData();

					if (displayMessage) {
						success(t("Contract.savedSuccessfully"));
					}
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured while submiting contracts's data: ${_error}`);
			}

			setIsLoading(false);
		}, [error, fetchData, projectid, projectInfo, success, selectedTab, t],
	);

	const submitLanguage = useCallback(
		async (location) => {
			setIsLoading(true);

			try {
				const response = await updateProjectContract(
					projectid, { ...projectInfo, registered_office_location: location },
				);

				if (httpResponseIsValid(response)) {
					await fetchData();
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured while submiting contracts's data: ${_error}`);
			}
		}, [error, fetchData, projectid, projectInfo],
	);

	const onValueChange = (e, name) => {
		setProjectInfo({ ...projectInfo, [name]: e.target.value });

		setErrors({ ...errors, [name]: false });
	};

	useEffect(() => {
		setShouldUiBeDisabled(disableAccess);
	}, [disableAccess]);

	const verifyTab = async (tab) => {
		let emailValid;
		let validationSchema;
		let errorsFound = false;
		const tempErrors = Object.fromEntries(Object.keys(errors).map((e) => [e, false]));

		switch (tab) {
			case "custom_contract":
				for (const key of ["service_subscriber_email",
					"service_subscriber_director_name"]) {
					if (!projectInfo[key]) {
						errorsFound = true;
						tempErrors[key] = true;
					}
				}

				if (!projectInfo.custom_contract_file.save_name) {
					errorsFound = true;
					tempErrors.custom_contract_file = true;
				}

				validationSchema = Yup.object().shape({
					email: Yup.string().matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/).required(),
				});

				emailValid = await validationSchema.isValid({ email: projectInfo.service_subscriber_email });
				if (!emailValid) {
					errorsFound = true;
					tempErrors.service_subscriber_email = true;
				}

				break;
			case "contract_schedules":
				for (const key of ["schedule_1",
					"schedule_2",
					"schedule_3",
					"schedule_4",
					"schedule_5",
					"schedule_6",
					"schedule_7"]) {
					if (!projectInfo[key].save_name) {
						errorsFound = true;
						tempErrors[key] = true;
					}
				}

				if (errorsFound) {
					setTabError("contract_schedules");
					break;
				}

			// eslint-disable-next-line no-fallthrough
			case "service_subscriber_details":
				for (const key of ["service_subscriber_name",
					"service_subscriber_director_name",
					"service_subscriber_country_of_registration",
					"service_subscriber_company_number",
					"service_subscriber_registered_office_address",
					"service_subscriber_registered_office_postcode",
					"service_subscriber_registered_office_city",
					"professional_indemnity_insurance_amount",
					"days_to_pay_security_deposit"]) {
					if (!projectInfo[key]) {
						errorsFound = true;
						tempErrors[key] = true;
					}
				}

				validationSchema = Yup.object().shape({
					email: Yup.string().matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/).required(),
				});

				emailValid = await validationSchema.isValid({ email: projectInfo.service_subscriber_email });
				if (!emailValid) {
					errorsFound = true;
					tempErrors.service_subscriber_email = true;
				}

				if (errorsFound) {
					setTabError("service_subscriber_details");
					break;
				}

			// eslint-disable-next-line no-fallthrough
			case "company_details":
				for (const key of ["service_provider_name",
					"director_name",
					"country_of_registration",
					"company_number",
					"registered_office_address",
					"registered_office_postcode",
					"registered_office_city",
					"registered_office_currency",
					"output_type"]) {
					if (!projectInfo[key]) {
						errorsFound = true;
						tempErrors[key] = true;
					}
				}

				if (errorsFound) {
					setTabError("company_details");
					break;
				}

				break;
			default:
				break;
		}

		if (errorsFound) {
			setErrors(tempErrors);

			return false;
		}

		setErrors(tempErrors);
		setTabError("");

		return true;
	};

	const next = async () => {
		if (projectInfo.custom_contract) {
			if (await verifyTab("custom_contract")) {
				await submit(true, true);
			}
		} else {
			const savedTabIndex = AvailableTabs.indexOf(projectInfo.saved_tab);
			const currentIndex = AvailableTabs.indexOf(selectedTab);

			if (await verifyTab((currentIndex >= savedTabIndex ? selectedTab : projectInfo.saved_tab))) {
				await submit(true);
			}
		}
	};

	const onOpenDocusignDialog = async () => {
		if (projectInfo.custom_contract) {
			if (await verifyTab("custom_contract")) {
				setDocusignDialogOpen(true);
			} else {
				setDocusignDialogOpen(false);
			}
		} else if (await verifyTab("contract_schedules")) {
			setDocusignDialogOpen(true);
		} else {
			setDocusignDialogOpen(false);
		}
	};

	const docusignDisable = () => (!isProjectDeveloper || (projectInfo.custom_contract ? dataChanged : (dataChanged || projectInfo.saved_tab !== "contract_schedules")));

	const sign = async () => {
		setSpinnerOpen(true);

		try {
			const { success: userSuccess } = await getUserData(projectid);
			if (userSuccess) {
				window.open(`${REACT_APP_MAIN_SERVER_URL}/api/docusign/login?projectId=${projectid}&token=${jwt.getToken()}`);
			} else {
				error("Please fill out your email and full name in your profile.");
			}
		} catch (_error) {
			error(`Error occured while signing in to Docusign: ${_error}`);
		}

		setDocusignDialogOpen(false);
		setSpinnerOpen(false);
	};

	const getSelectedTabColor = () => {
		if (selectedTab === tabError) {
			return "#F34949";
		}

		const savedTabIndex = AvailableTabs.indexOf(projectInfo.saved_tab);
		const selectedIndex = AvailableTabs.indexOf(selectedTab);

		return savedTabIndex >= selectedIndex ? "#51BEA5" : "#346690";
	};

	const getTabColor = (tab) => {
		if (tab === tabError) {
			return "#F34949";
		}

		const savedTabIndex = AvailableTabs.indexOf(projectInfo.saved_tab);
		const selectedIndex = AvailableTabs.indexOf(tab);

		return savedTabIndex >= selectedIndex ? "#51BEA5" : "grey";
	};

	const getButtonTitle = () => {
		if (projectInfo.custom_contract) {
			return "FINISH";
		}

		const savedTabIndex = AvailableTabs.indexOf(projectInfo.saved_tab);
		const selectedIndex = AvailableTabs.indexOf(selectedTab);

		if (savedTabIndex >= 2 && selectedIndex >= savedTabIndex) {
			return "FINISH";
		}

		return "NEXT";
	};

	useEffect(() => {
		const savedIndex = AvailableTabs.indexOf(projectInfo.saved_tab);
		if (savedIndex > -1 && savedIndex < 3) {
			setSelectedTab(AvailableTabs[savedIndex + 1]);
		} else if (savedIndex >= 3) {
			setSelectedTab("contract_schedules");
		} else {
			setSelectedTab("company_details");
		}
	}, [projectInfo.saved_tab, fetchData, savedData]);

	useEffect(() => {
		if (JSON.stringify(projectInfo) === JSON.stringify(savedData)) {
			setDataChanged(false);
		} else {
			setDataChanged(true);
		}
	}, [projectInfo, savedData]);

	useEffect(() => {
		setShouldUiBeDisabled(disableAccess || !enableEditing);
	}, [disableAccess, enableEditing]);

	useEffect(() => {
		(async () => {
			setSpinnerOpen(true);
			if (((projectInfo.custom_contract && projectInfo.custom_contract_signed.save_name)
				|| (!projectInfo.custom_contract && projectInfo.contract_signed.save_name))
				|| event === "signing_complete") {
				await signingCompleted(projectid);
			}

			await fetchData();

			setSpinnerOpen(false);
		})();
	}, [error, event, fetchData,
		projectInfo.contract_signed.save_name, projectInfo.custom_contract, projectInfo.custom_contract_signed.save_name, projectid]);

	const onDelete = async (comp) => {
		try {
			setIsLoading(true);
			const { success: deleteSuccess } = await deleteFile({ projectid, component: comp.component, variable: comp.variable });
			if (deleteSuccess) {
				success("File deleted successfully");
				await fetchSchedules();
				setIsLoading(false);
			} else {
				error();
				setIsLoading(false);
			}
		} catch { /* empty */ }
	};

	const renderCompanyDetails = () => (
		<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 } }}>
			<Grid item container sx={{ p: 1 }} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.serviceProviderName")}</Typography>
				</Grid>
				<Grid item xs={12} md={8}>
					<TextField
						fullWidth
						error={errors.service_provider_name}
						helperText={errors.service_provider_name ? t("Contract.blankField") : ""}
						id="service-provider-name"
						value={projectInfo.service_provider_name}
						variant="filled"
						placeholder={t("Contract.serviceProviderNameText")}
						disabled={disableTab("company_details")}
						onChange={(e) => onValueChange(e, "service_provider_name")}
					/>
				</Grid>
			</Grid>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.directorName")}</Typography>
				</Grid>
				<Grid item xs={12} md={8}>
					<TextField
						fullWidth
						error={errors.director_name}
						helperText={errors.director_name ? t("Contract.blankField") : ""}
						id="director-name"
						value={projectInfo.director_name}
						variant="filled"
						placeholder={t("Contract.directorNameText")}
						disabled={disableTab("company_details")}
						onChange={(e) => onValueChange(e, "director_name")}
					/>
				</Grid>
			</Grid>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.countryOfRegistration")}</Typography>
				</Grid>
				<Grid item xs={12} md={8}>
					<TextField
						fullWidth
						error={errors.country_of_registration}
						helperText={errors.country_of_registration ? t("Contract.blankField") : ""}
						id="country-of-registration"
						value={projectInfo.country_of_registration}
						variant="filled"
						placeholder={t("Contract.countryOfRegistrationText")}
						disabled={disableTab("company_details")}
						onChange={(e) => onValueChange(e, "country_of_registration")}
					/>
				</Grid>
			</Grid>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.companyNumber")}</Typography>
				</Grid>
				<Grid item xs={12} md={8}>
					<TextField
						fullWidth
						error={errors.company_number}
						helperText={errors.company_number ? t("Contract.blankField") : ""}
						id="company-number"
						value={projectInfo.company_number}
						variant="filled"
						placeholder={t("Contract.companyNumberText")}
						disabled={disableTab("company_details")}
						onChange={(e) => onValueChange(e, "company_number")}
					/>
				</Grid>
			</Grid>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.registeredOfficeAddress")}</Typography>
				</Grid>
				<Grid item xs={12} md={8}>
					<TextField
						fullWidth
						error={errors.registered_office_address}
						helperText={errors.registered_office_address ? t("Contract.blankField") : ""}
						id="registered-office-address"
						value={projectInfo.registered_office_address}
						variant="filled"
						placeholder={t("Contract.registeredOfficeAddressText")}
						disabled={disableTab("company_details")}
						onChange={(e) => onValueChange(e, "registered_office_address")}
					/>
				</Grid>
			</Grid>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.registeredOfficePostcode")}</Typography>
				</Grid>
				<Grid item xs={12} md={8}>
					<TextField
						fullWidth
						error={errors.registered_office_postcode}
						helperText={errors.registered_office_postcode ? t("Contract.blankField") : ""}
						id="registered-office-postcode"
						value={projectInfo.registered_office_postcode}
						variant="filled"
						placeholder={t("Contract.registeredOfficePostcodeText")}
						disabled={disableTab("company_details")}
						onChange={(e) => onValueChange(e, "registered_office_postcode")}
					/>
				</Grid>
			</Grid>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.registeredOfficeCity")}</Typography>
				</Grid>
				<Grid item xs={12} md={8}>
					<TextField
						fullWidth
						error={errors.registered_office_city}
						helperText={errors.registered_office_city ? t("Contract.blankField") : ""}
						id="registered-office-city"
						value={projectInfo.registered_office_city}
						variant="filled"
						placeholder={t("Contract.registeredOfficeCityText")}
						disabled={disableTab("company_details")}
						onChange={(e) => onValueChange(e, "registered_office_city")}
					/>
				</Grid>
			</Grid>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.currency")}</Typography>
				</Grid>
				<Grid item xs={12} md={8}>
					<TextField
						fullWidth
						select
						error={errors.registered_office_currency}
						helperText={errors.registered_office_currency ? t("Contract.blankField") : ""}
						id="registered_office_currency"
						value={projectInfo.registered_office_currency}
						variant="filled"
						placeholder="The desired currency"
						disabled={disableTab("company_details")}
						onChange={(e) => onValueChange(e, "registered_office_currency")}
					>
						{Object.keys(currencies).map((cur) => (
							<MenuItem key={cur} value={cur}>
								{currencies[cur]}
							</MenuItem>
						))}
					</TextField>
				</Grid>
			</Grid>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.outputType")}</Typography>
				</Grid>
				<Grid item xs={12} md={8}>
					<TextField
						fullWidth
						select
						error={errors.output_type}
						helperText={errors.output_type ? t("Contract.blankField") : ""}
						id="output_type"
						value={projectInfo.output_type}
						variant="filled"
						placeholder={t("Contract.registeredOutputType")}
						disabled={disableTab("company_details")}
						onChange={(e) => onValueChange(e, "output_type")}
					>
						{Object.keys(outputs).map((out) => (
							<MenuItem key={out} value={out}>
								{outputs[out]}
							</MenuItem>
						))}
					</TextField>
					{/* <TextField
						fullWidth
						error={errors.output_type}
						helperText={errors.output_type ? t("Contract.blankField") : ""}
						id="output-type"
						value={projectInfo.output_type}
						variant="filled"
						placeholder={t("Contract.registeredOutputType")}
						disabled={disableTab("company_details")}
						onChange={(e) => onValueChange(e, "output_type")}
					/> */}
				</Grid>
			</Grid>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.businessHours")}</Typography>
				</Grid>
				<Grid item xs={12} md={8}>
					<Grid item container xs={12}>
						<Grid item xs={12} md={4}>
							<TextField
								fullWidth
								id="signer-name"
								value={projectInfo.business_hours_from}
								variant="filled"
								type="time"
								disabled={disableTab("company_details")}
								onChange={(e) => onValueChange(e, "business_hours_from")}
							/>
						</Grid>
						<Grid item xs={0} md={1} />
						<Grid item xs={12} md={2} display="flex" alignItems="center" justifyContent="center">
							<Typography variant="h6">{t("Contract.businessHoursTo")}</Typography>
						</Grid>
						<Grid item xs={0} md={1} />
						<Grid item xs={12} md={4}>
							<TextField
								fullWidth
								id="signer-name"
								value={projectInfo.business_hours_to}
								variant="filled"
								type="time"
								disabled={disableTab("company_details")}
								onChange={(e) => onValueChange(e, "business_hours_to")}
							/>
						</Grid>
					</Grid>
					{errors.business_hours
						&& (
							<Grid item xs={12} px={1.5}>
								<Typography color="red" variant="caption">{t("Contract.blankFields")}</Typography>
							</Grid>
						)}
				</Grid>
			</Grid>
		</Grid>
	);

	const renderServiceSubscriberDetails = () => (
		<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 } }}>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.serviceSubscriberName")}</Typography>
				</Grid>
				<Grid item xs={12} md={8}>
					<TextField
						fullWidth
						error={errors.service_subscriber_name}
						helperText={errors.service_subscriber_name ? t("Contract.blankField") : ""}
						id="signer-name"
						value={projectInfo.service_subscriber_name}
						variant="filled"
						placeholder={t("Contract.serviceSubscriberNameText")}
						disabled={disableTab("service_subscriber_details")}
						onChange={(e) => onValueChange(e, "service_subscriber_name")}
					/>
				</Grid>
			</Grid>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.serviceSubscriberEmail")}</Typography>
				</Grid>
				<Grid item xs={12} md={8}>
					<TextField
						fullWidth
						error={errors.service_subscriber_email}
						helperText={errors.service_subscriber_email ? t("Contract.signerEmailError") : ""}
						id="signer-email"
						value={projectInfo.service_subscriber_email}
						variant="filled"
						placeholder={t("Contract.serviceSubscriberEmailText")}
						disabled={disableTab("service_subscriber_details")}
						onChange={(e) => onValueChange(e, "service_subscriber_email")}
					/>
				</Grid>
			</Grid>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.serviceSubscriberDirectorName")}</Typography>
				</Grid>
				<Grid item xs={12} md={8}>
					<TextField
						fullWidth
						error={errors.service_subscriber_director_name}
						helperText={errors.service_subscriber_director_name ? t("Contract.blankField") : ""}
						id="signer-name"
						value={projectInfo.service_subscriber_director_name}
						variant="filled"
						placeholder={t("Contract.serviceSubscriberDirectorNameText")}
						disabled={disableTab("service_subscriber_details")}
						onChange={(e) => onValueChange(e, "service_subscriber_director_name")}
					/>
				</Grid>
			</Grid>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.serviceSubscriberCountryOfRegistration")}</Typography>
				</Grid>
				<Grid item xs={12} md={8}>
					<TextField
						fullWidth
						error={errors.service_subscriber_country_of_registration}
						helperText={errors.service_subscriber_country_of_registration ? t("Contract.blankField") : ""}
						id="signer-name"
						value={projectInfo.service_subscriber_country_of_registration}
						variant="filled"
						placeholder={t("Contract.serviceSubscriberCountryOfRegistrationText")}
						disabled={disableTab("service_subscriber_details")}
						onChange={(e) => onValueChange(e, "service_subscriber_country_of_registration")}
					/>
				</Grid>
			</Grid>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.serviceSubscriberCompanyNumber")}</Typography>
				</Grid>
				<Grid item xs={12} md={8}>
					<TextField
						fullWidth
						error={errors.service_subscriber_company_number}
						helperText={errors.service_subscriber_company_number ? t("Contract.blankField") : ""}
						id="signer-name"
						value={projectInfo.service_subscriber_company_number}
						variant="filled"
						placeholder={t("Contract.serviceSubscriberCompanyNumberText")}
						disabled={disableTab("service_subscriber_details")}
						onChange={(e) => onValueChange(e, "service_subscriber_company_number")}
					/>
				</Grid>
			</Grid>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.serviceSubscriberRegisteredOfficeAddress")}</Typography>
				</Grid>
				<Grid item xs={12} md={8}>
					<TextField
						fullWidth
						error={errors.service_subscriber_registered_office_address}
						helperText={errors.service_subscriber_registered_office_address ? t("Contract.blankField") : ""}
						id="signer-name"
						value={projectInfo.service_subscriber_registered_office_address}
						variant="filled"
						placeholder={t("Contract.serviceSubscriberRegisteredOfficeAddressText")}
						disabled={disableTab("service_subscriber_details")}
						onChange={(e) => onValueChange(e, "service_subscriber_registered_office_address")}
					/>
				</Grid>
			</Grid>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.serviceSubscriberRegisteredOfficePostcode")}</Typography>
				</Grid>
				<Grid item xs={12} md={8}>
					<TextField
						fullWidth
						error={errors.service_subscriber_registered_office_postcode}
						helperText={errors.service_subscriber_registered_office_postcode ? t("Contract.blankField") : ""}
						id="signer-name"
						value={projectInfo.service_subscriber_registered_office_postcode}
						variant="filled"
						placeholder={t("Contract.serviceSubscriberRegisteredOfficePostcodeText")}
						disabled={disableTab("service_subscriber_details")}
						onChange={(e) => onValueChange(e, "service_subscriber_registered_office_postcode")}
					/>
				</Grid>
			</Grid>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.serviceSubscriberRegisteredOfficeCity")}</Typography>
				</Grid>
				<Grid item xs={12} md={8}>
					<TextField
						fullWidth
						error={errors.service_subscriber_registered_office_city}
						helperText={errors.service_subscriber_registered_office_city ? t("Contract.blankField") : ""}
						id="signer-name"
						value={projectInfo.service_subscriber_registered_office_city}
						variant="filled"
						placeholder={t("Contract.serviceSubscriberRegisteredOfficeCityText")}
						disabled={disableTab("service_subscriber_details")}
						onChange={(e) => onValueChange(e, "service_subscriber_registered_office_city")}
					/>
				</Grid>
			</Grid>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.professionalIndemnityInsuranceAmount")}</Typography>
				</Grid>
				<Grid item xs={12} md={8}>
					<TextField
						fullWidth
						error={errors.professional_indemnity_insurance_amount}
						helperText={errors.professional_indemnity_insurance_amount ? t("Contract.blankField") : ""}
						id="signer-name"
						value={projectInfo.professional_indemnity_insurance_amount}
						variant="filled"
						type="number"
						placeholder={t("Contract.professionalIndemnityInsuranceAmountText")}
						disabled={disableTab("service_subscriber_details")}
						onChange={(e) => onValueChange(e, "professional_indemnity_insurance_amount")}
					/>
				</Grid>
			</Grid>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.daysToPaySecurityDeposit")}</Typography>
				</Grid>
				<Grid item xs={12} md={8}>
					<TextField
						fullWidth
						error={errors.days_to_pay_security_deposit}
						helperText={errors.days_to_pay_security_deposit ? t("Contract.blankField") : ""}
						id="signer-name"
						value={projectInfo.days_to_pay_security_deposit}
						variant="filled"
						type="number"
						InputProps={{ inputProps: { min: 0 } }}
						placeholder={t("Contract.daysToPaySecurityDepositText")}
						disabled={disableTab("service_subscriber_details")}
						onChange={(e) => onValueChange(e, "days_to_pay_security_deposit")}
					/>
				</Grid>
			</Grid>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.interest")}</Typography>
				</Grid>
				<Grid item xs={12} md={8}>
					<Grid item container xs={12}>
						<Grid item xs={12} md={4}>
							<TextField
								fullWidth
								id="signer-name"
								value={projectInfo.interest_percentage}
								variant="filled"
								type="number"
								InputProps={{
									endAdornment: <InputAdornment position="end">{"%"}</InputAdornment>,
								}}
								disabled={disableTab("service_subscriber_details")}
								onChange={(e) => onValueChange(e, "interest_percentage")}
							/>
						</Grid>
						<Grid item xs={0} md={1} />
						<Grid item xs={12} md={2} display="flex" alignItems="center" justifyContent="center">
							<Typography variant="h6">{t("Contract.interestOver")}</Typography>
						</Grid>
						<Grid item xs={0} md={1} />
						<Grid item xs={12} md={4} display="flex" alignItems="center" justifyContent="center">
							<TextField
								fullWidth
								select
								size="small"
								id="interest"
								value={projectInfo.interest_option}
								variant="outlined"
								placeholder="Interest Option"
								disabled={disableTab("service_subscriber_details")}
								onChange={(e) => onValueChange(e, "interest_option")}
							>
								{interests.map((inter) => (
									<MenuItem key={inter} value={inter}>
										{inter}
									</MenuItem>
								))}
							</TextField>
						</Grid>
					</Grid>
					{errors.business_hours
						&& (
							<Grid item xs={12} px={1.5}>
								<Typography color="red" variant="caption">{t("Contract.blankFields")}</Typography>
							</Grid>
						)}
				</Grid>
			</Grid>
		</Grid>
	);

	const renderContractSchedules = () => (
		<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 } }}>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.schedule1")}</Typography>
					<Link href={`${REACT_APP_MAIN_SERVER_URL}/templates/${projectInfo.registered_office_location}/schedule1template.pdf?token=${jwt.getToken()}`} target="_blank">{t("Contract.downloadTemplate")}</Link>
				</Grid>
				<Grid item xs={8} md={6}>
					{projectInfo.schedule_1.save_name
						&& (
							<Link href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${projectid}/${projectInfo.schedule_1.save_name}?token=${jwt.getToken()}`} target="_blank">
								{projectInfo.schedule_1.original_name || t("Contract.downloadFile")}
							</Link>
						)}
					{!projectInfo.schedule_1.save_name
						&& (
							<Typography color="grey.dark">{t("Contract.noUploadedFile")}</Typography>
						)}
					{errors.schedule_1
						&& (
							<Typography color="error.main">{t("Contract.needFile")}</Typography>
						)}
				</Grid>
				<Grid item xs={4} md={2} display="flex" justifyContent="space-evenly">
					<FileUpload disabled={disableTab("contract_schedules")} projectId={projectid} variableComponent="contract" variable="schedule_1" component="toggle" oldFile={projectInfo.schedule_1.save_name} onSuccess={fetchSchedules} />
					{projectInfo.schedule_1.save_name
						&& (
							<ToggleButton
								value="delete"
								title="delete"
								aria-label="delete"
								sx={{ borderColor: "primary.main" }}
								disabled={disableTab("contract_schedules")}
								onClick={() => setDeleteModal({ component: "contract", variable: "schedule_1" })}
							>
								<Delete color="error" />
							</ToggleButton>
						)}
				</Grid>
			</Grid>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.schedule2")}</Typography>
					<Link href={`${REACT_APP_MAIN_SERVER_URL}/templates/${projectInfo.registered_office_location}/schedule2template.pdf?token=${jwt.getToken()}`} target="_blank">{t("Contract.downloadTemplate")}</Link>
				</Grid>
				<Grid item xs={8} md={6}>
					{projectInfo.schedule_2.save_name
						&& (
							<Link href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${projectid}/${projectInfo.schedule_2.save_name}?token=${jwt.getToken()}`} target="_blank">
								{projectInfo.schedule_2.original_name || t("Contract.downloadFile")}
							</Link>
						)}
					{!projectInfo.schedule_2.save_name
						&& (
							<Typography color="grey.dark">{t("Contract.noUploadedFile")}</Typography>
						)}
					{errors.schedule_2
						&& (
							<Typography color="error.main">{t("Contract.needFile")}</Typography>
						)}
				</Grid>
				<Grid item xs={4} md={2} display="flex" justifyContent="space-evenly">
					<FileUpload disabled={disableTab("contract_schedules")} projectId={projectid} variableComponent="contract" variable="schedule_2" component="toggle" oldFile={projectInfo.schedule_2.save_name} onSuccess={fetchSchedules} />
					{projectInfo.schedule_2.save_name
						&& (
							<ToggleButton
								value="delete"
								title="delete"
								aria-label="delete"
								sx={{ borderColor: "primary.main" }}
								disabled={disableTab("contract_schedules")}
								onClick={() => setDeleteModal({ component: "contract", variable: "schedule_2" })}
							>
								<Delete color="error" />
							</ToggleButton>
						)}
				</Grid>
			</Grid>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.schedule3")}</Typography>
					<Link href={`${REACT_APP_MAIN_SERVER_URL}/templates/${projectInfo.registered_office_location}/schedule3template.pdf?token=${jwt.getToken()}`} target="_blank">{t("Contract.downloadTemplate")}</Link>
				</Grid>
				<Grid item xs={8} md={6}>
					{projectInfo.schedule_3.save_name
						&& (
							<Link href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${projectid}/${projectInfo.schedule_3.save_name}?token=${jwt.getToken()}`} target="_blank">
								{projectInfo.schedule_3.original_name || t("Contract.downloadFile")}
							</Link>
						)}
					{!projectInfo.schedule_3.save_name
						&& (
							<Typography color="grey.dark">{t("Contract.noUploadedFile")}</Typography>
						)}
					{errors.schedule_3
						&& (
							<Typography color="error.main">{t("Contract.needFile")}</Typography>
						)}
				</Grid>
				<Grid item xs={4} md={2} display="flex" justifyContent="space-evenly">
					<FileUpload disabled={disableTab("contract_schedules")} projectId={projectid} variableComponent="contract" variable="schedule_3" component="toggle" oldFile={projectInfo.schedule_3.save_name} onSuccess={fetchSchedules} />
					{projectInfo.schedule_3.save_name
						&& (
							<ToggleButton
								value="delete"
								title="delete"
								aria-label="delete"
								sx={{ borderColor: "primary.main" }}
								disabled={disableTab("contract_schedules")}
								onClick={() => setDeleteModal({ component: "contract", variable: "schedule_3" })}
							>
								<Delete color="error" />
							</ToggleButton>
						)}
				</Grid>
			</Grid>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.schedule4")}</Typography>
					<Link href={`${REACT_APP_MAIN_SERVER_URL}/templates/${projectInfo.registered_office_location}/schedule4template.pdf?token=${jwt.getToken()}`} target="_blank">{t("Contract.downloadTemplate")}</Link>
				</Grid>
				<Grid item xs={8} md={6}>
					{projectInfo.schedule_4.save_name
						&& (
							<Link href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${projectid}/${projectInfo.schedule_4.save_name}?token=${jwt.getToken()}`} target="_blank">
								{projectInfo.schedule_4.original_name || t("Contract.downloadFile")}
							</Link>
						)}
					{!projectInfo.schedule_4.save_name
						&& (
							<Typography color="grey.dark">{t("Contract.noUploadedFile")}</Typography>
						)}
					{errors.schedule_4
						&& (
							<Typography color="error.main">{t("Contract.needFile")}</Typography>
						)}
				</Grid>
				<Grid item xs={4} md={2} display="flex" justifyContent="space-evenly">
					<FileUpload disabled={disableTab("contract_schedules")} projectId={projectid} variableComponent="contract" variable="schedule_4" component="toggle" oldFile={projectInfo.schedule_4.save_name} onSuccess={fetchSchedules} />
					{projectInfo.schedule_4.save_name
						&& (
							<ToggleButton
								value="delete"
								title="delete"
								aria-label="delete"
								sx={{ borderColor: "primary.main" }}
								disabled={disableTab("contract_schedules")}
								onClick={() => setDeleteModal({ component: "contract", variable: "schedule_4" })}
							>
								<Delete color="error" />
							</ToggleButton>
						)}
				</Grid>
			</Grid>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.schedule5")}</Typography>
					<Link href={`${REACT_APP_MAIN_SERVER_URL}/templates/${projectInfo.registered_office_location}/schedule5template.pdf?token=${jwt.getToken()}`} target="_blank">{t("Contract.downloadTemplate")}</Link>
				</Grid>
				<Grid item xs={8} md={6}>
					{projectInfo.schedule_5.save_name
						&& (
							<Link href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${projectid}/${projectInfo.schedule_5.save_name}?token=${jwt.getToken()}`} target="_blank">
								{projectInfo.schedule_5.original_name || t("Contract.downloadFile")}
							</Link>
						)}
					{!projectInfo.schedule_5.save_name
						&& (
							<Typography color="grey.dark">{t("Contract.noUploadedFile")}</Typography>
						)}
					{errors.schedule_5
						&& (
							<Typography color="error.main">{t("Contract.needFile")}</Typography>
						)}
				</Grid>
				<Grid item xs={4} md={2} display="flex" justifyContent="space-evenly">
					<FileUpload disabled={disableTab("contract_schedules")} projectId={projectid} variableComponent="contract" variable="schedule_5" component="toggle" oldFile={projectInfo.schedule_5.save_name} onSuccess={fetchSchedules} />
					{projectInfo.schedule_5.save_name
						&& (
							<ToggleButton
								value="delete"
								title="delete"
								aria-label="delete"
								sx={{ borderColor: "primary.main" }}
								disabled={disableTab("contract_schedules")}
								onClick={() => setDeleteModal({ component: "contract", variable: "schedule_5" })}
							>
								<Delete color="error" />
							</ToggleButton>
						)}
				</Grid>
			</Grid>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.schedule6")}</Typography>
					<Link href={`${REACT_APP_MAIN_SERVER_URL}/templates/${projectInfo.registered_office_location}/schedule6template.pdf?token=${jwt.getToken()}`} target="_blank">{t("Contract.downloadTemplate")}</Link>
				</Grid>
				<Grid item xs={8} md={6}>
					{projectInfo.schedule_6.save_name
						&& (
							<Link href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${projectid}/${projectInfo.schedule_6.save_name}?token=${jwt.getToken()}`} target="_blank">
								{projectInfo.schedule_6.original_name || t("Contract.downloadFile")}
							</Link>
						)}
					{!projectInfo.schedule_6.save_name
						&& (
							<Typography color="grey.dark">{t("Contract.noUploadedFile")}</Typography>
						)}
					{errors.schedule_6
						&& (
							<Typography color="error.main">{t("Contract.needFile")}</Typography>
						)}
				</Grid>
				<Grid item xs={4} md={2} display="flex" justifyContent="space-evenly">
					<FileUpload disabled={disableTab("contract_schedules")} projectId={projectid} variableComponent="contract" variable="schedule_6" component="toggle" oldFile={projectInfo.schedule_6.save_name} onSuccess={fetchSchedules} />
					{projectInfo.schedule_6.save_name
						&& (
							<ToggleButton
								value="delete"
								title="delete"
								aria-label="delete"
								sx={{ borderColor: "primary.main" }}
								disabled={disableTab("contract_schedules")}
								onClick={() => setDeleteModal({ component: "contract", variable: "schedule_6" })}
							>
								<Delete color="error" />
							</ToggleButton>
						)}
				</Grid>
			</Grid>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<Grid item xs={12} md={4}>
					<Typography variant="h6" fontWeight="bold">{t("Contract.schedule7")}</Typography>
					<Link href={`${REACT_APP_MAIN_SERVER_URL}/templates/${projectInfo.registered_office_location}/schedule7template.pdf?token=${jwt.getToken()}`} target="_blank">{t("Contract.downloadTemplate")}</Link>
				</Grid>
				<Grid item xs={8} md={6}>
					{projectInfo.schedule_7.save_name
						&& (
							<Link href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${projectid}/${projectInfo.schedule_7.save_name}?token=${jwt.getToken()}`} target="_blank">
								{projectInfo.schedule_7.original_name || t("Contract.downloadFile")}
							</Link>
						)}
					{!projectInfo.schedule_7.save_name
						&& (
							<Typography color="grey.dark">{t("Contract.noUploadedFile")}</Typography>
						)}
					{errors.schedule_7
						&& (
							<Typography color="error.main">{t("Contract.needFile")}</Typography>
						)}
				</Grid>
				<Grid item xs={4} md={2} display="flex" justifyContent="space-evenly">
					<FileUpload disabled={disableTab("contract_schedules")} projectId={projectid} variableComponent="contract" variable="schedule_7" component="toggle" oldFile={projectInfo.schedule_7.save_name} onSuccess={fetchSchedules} />
					{projectInfo.schedule_7.save_name
						&& (
							<ToggleButton
								value="delete"
								title="delete"
								aria-label="delete"
								sx={{ borderColor: "primary.main" }}
								disabled={disableTab("contract_schedules")}
								onClick={() => setDeleteModal({ component: "contract", variable: "schedule_7" })}
							>
								<Delete color="error" />
							</ToggleButton>
						)}
				</Grid>
			</Grid>
		</Grid>
	);

	const renderSelectedTabs = () => {
		switch (selectedTab) {
			case "company_details":
				return renderCompanyDetails();
			case "service_subscriber_details":
				return renderServiceSubscriberDetails();
			case "contract_schedules":
				return renderContractSchedules();
			default:
				return null;
		}
	};

	return (
		<>
			<Spinner open={spinnerOpen} />
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<Grid container direction="column" alignItems="center" py={1} px={3} sx={{ "> .MuiGrid-item": { p: 1 }, position: "fixed", maxWidth: "1300px", width: "calc(100% - 300px)", background: "white", zIndex: 999 }}>
				<Grid container item direction="row" alignItems="center" sx={{ "> .MuiGrid-item": { p: 0 }, background: "#F4F4F4", borderRadius: "10px 10px 0px 0px" }}>
					<Grid item xs={12} md={4}>
						<Typography variant="h5" className={classes.screenTitle}>{t("Contract.pageTitle")}</Typography>
					</Grid>
					<Grid item xs={0} md={3} />
					<Grid item container className={classes.boxBorder} sx={{ p: 1 }} xs={12} md={5}>
						<Grid item xs={12} md={6}>
							<Box p={1}>
								<Grid container direction="row" justifyContent="start" alignItems="center">
									<Grid item xs={4}>
										<Typography variant="h6">{"Status: "}</Typography>
									</Grid>
									<Grid item container xs={8} justifyContent="start">
										<Typography variant="h6" color={(projectInfo.status === "Approved") ? "green.main" : ((projectInfo.status === "Rejected") ? "error.main" : "primary.main")}>{projectInfo.status}</Typography>
									</Grid>
								</Grid>
							</Box>
						</Grid>
						<Grid item container xs={12} md={6} alignItems="center" justifyContent="end">
							<Button
								variant="contained"
								size="medium"
								sx={{ width: "170px", backgroundColor: "#5C9AE1", color: "common.white", ":hover": { backgroundColor: "primary.main" }, px: 3, marginRight: "4px", borderRadius: "10px" }}
								disabled={!isProjectDeveloper}
							>
								{projectInfo.status === "In Progress" ? "Submit Project" : "Revert Project"}
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid container item direction="row" alignItems="center" sx={{ ">.MuiGrid-item": { p: 0 }, background: "#F4F4F4", borderRadius: "0px 0px 10px 10px" }} p={0}>
					<Grid item xs={0} md={7} />
					<Grid item container sx={{ p: 1 }} xs={12} md={5} alignItems="center">
						<Grid item xs={12} md={6}>
							<Box px={1}>
								{!disableAccess
									&& (
										<Grid container direction="row" justifyContent="center" alignItems="center" p={0}>
											<Grid item xs={4}>
												<Typography variant="h6" fontSize="medium">{"Edit: "}</Typography>
											</Grid>
											<Grid item xs={8}>
												<Switch
													color="primary"
													size="small"
													onChange={(e) => setEnableEditing(e.target.checked)}
												/>
											</Grid>
										</Grid>
									)}
							</Box>
						</Grid>
						<Grid item xs={12} md={6}>
							<Box px={1} display="flex" flexDirection="row">
								<Grid container direction="row" justifyContent="flex-end" alignItems="center">
									<Button
										variant="outlined"
										size="medium"
										color="primary"
										sx={{ width: "100px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
										disabled={
											docusignDisable()
											|| ((projectInfo.custom_contract && projectInfo.custom_contract_signed.save_name)
											|| (!projectInfo.custom_contract && projectInfo.contract_signed.save_name))
										}
										onClick={onOpenDocusignDialog}
									>
										{"SIGN"}
									</Button>
								</Grid>
								<Grid container direction="row" justifyContent="flex-end" alignItems="center" ml={1}>
									<Button
										variant="contained"
										size="medium"
										sx={{ width: "100px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
										disabled={!enableEditing || !dataChanged || disableTab(selectedTab)}
										onClick={next}
									>

										{getButtonTitle()}
									</Button>
								</Grid>
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "105px" }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{"GENERAL"}
					</Typography>
				</Grid>
				<Grid item container justifyContent="space-between" mt={3}>
					<Grid item container md={6} justifyContent="start" alignItems="center">
						<Grid item xs={12} md={8}>
							<Typography variant="h6" fontWeight="bold">{t("Contract.uploadContract")}</Typography>
						</Grid>
						<Grid item xs={12} md={4}>
							<Switch
								color="primary"
								checked={projectInfo.custom_contract}
								disabled={
									shouldUiBeDisabled
									|| ((projectInfo.custom_contract && projectInfo.custom_contract_signed.save_name)
									|| (!projectInfo.custom_contract && projectInfo.contract_signed.save_name))
								}
								onChange={() => {
									setProjectInfo({ ...projectInfo, custom_contract: !projectInfo.custom_contract });
									submit(false, !projectInfo.custom_contract);
								}}
							/>
						</Grid>
					</Grid>
					<Grid item container md={6} justifyContent="end" alignItems="center">
						<Grid item xs={12} md={7}>
							<Typography variant="h6" fontWeight="bold">{"Select Language"}</Typography>
						</Grid>
						<Grid item container xs={12} md={4} justifyContent="end">
							<FormControl variant="outlined">
								<InputLabel id="demo-simple-select-filled-label">{"Language"}</InputLabel>
								<Select
									id="demo-simple-select-outlined-2"
									value={projectInfo.registered_office_location}
									label="Language"
									sx={{ width: "180px", height: "45px", borderRadius: "15px 15px 15px 15px", backgroundColor: "white" }}
									disabled={shouldUiBeDisabled}
									onChange={(e) => {
										onValueChange(e, "registered_office_location");
										i18n.changeLanguage(localization[e.target.value].code);
										submitLanguage(e.target.value);
									}}
								>
									{Object.keys(localization).map((cur) => (
										<MenuItem key={cur} value={cur}>
											{localization[cur].text}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 } }}>
				{projectInfo.custom_contract && !projectInfo.custom_contract_signed.save_name
					&& (
						<>
							<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mb={4} bgcolor="#6F96C6">
								<Typography variant="h6" color="common.white" alignItems="center">
									{projectInfo.custom_contract ? "CUSTOM CONTRACT" : "SEFA CONTRACT"}
								</Typography>
							</Grid>
							<Grid item xs={12} md={4}>
								<Typography variant="h6" fontWeight="bold">{t("Contract.customContract")}</Typography>
							</Grid>
							<Grid item xs={8} md={6}>
								{projectInfo.custom_contract_file.save_name
									&& (
										<Link href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${projectid}/${projectInfo.custom_contract_file.save_name}?token=${jwt.getToken()}`} target="_blank">
											{projectInfo.custom_contract_file.original_name || t("Contract.downloadFile")}
										</Link>
									)}
								{!projectInfo.custom_contract_file.save_name
									&& (
										<Typography color="grey.dark">{t("Contract.noUploadedFile")}</Typography>
									)}
								{errors.custom_contract
									&& (
										<Typography color="error.main">{t("Contract.needFile")}</Typography>
									)}
							</Grid>
							<Grid item xs={4} md={2} display="flex" justifyContent="space-evenly">
								<FileUpload disabled={shouldUiBeDisabled} projectId={projectid} variableComponent="contract" variable="custom_contract_file" component="toggle" oldFile={projectInfo.custom_contract_file.save_name} onSuccess={fetchSchedules} />
								{projectInfo.custom_contract_file.save_name
									&& (
										<ToggleButton
											value="delete"
											title="delete"
											aria-label="delete"
											sx={{ borderColor: "primary.main" }}
											disabled={shouldUiBeDisabled}
											onClick={() => setDeleteModal({ component: "contract", variable: "custom_contract_file" })}
										>
											<Delete color="error" />
										</ToggleButton>
									)}
							</Grid>
							<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold">{t("Contract.currency")}</Typography>
								</Grid>
								<Grid item xs={12} md={8}>
									<TextField
										fullWidth
										select
										error={errors.registered_office_currency}
										helperText={errors.registered_office_currency ? t("Contract.blankField") : ""}
										id="registered_office_currency"
										value={projectInfo.registered_office_currency}
										variant="filled"
										placeholder="The desired currency"
										disabled={shouldUiBeDisabled}
										onChange={(e) => onValueChange(e, "registered_office_currency")}
									>
										{Object.keys(currencies).map((cur) => (
											<MenuItem key={cur} value={cur}>
												{currencies[cur]}
											</MenuItem>
										))}
									</TextField>
								</Grid>
							</Grid>
							<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold">{t("Contract.serviceSubscriberEmail")}</Typography>
								</Grid>
								<Grid item xs={12} md={8}>
									<TextField
										fullWidth
										error={errors.service_subscriber_email}
										helperText={errors.service_subscriber_email ? t("Contract.signerEmailError") : ""}
										id="signer-email"
										value={projectInfo.service_subscriber_email}
										variant="filled"
										placeholder={t("Contract.serviceSubscriberEmailText")}
										disabled={shouldUiBeDisabled}
										onChange={(e) => onValueChange(e, "service_subscriber_email")}
									/>
								</Grid>
							</Grid>
							<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold">{t("Contract.serviceSubscriberDirectorName")}</Typography>
								</Grid>
								<Grid item xs={12} md={8}>
									<TextField
										fullWidth
										error={errors.service_subscriber_director_name}
										helperText={errors.service_subscriber_director_name ? t("Contract.blankField") : ""}
										id="signer-name"
										value={projectInfo.service_subscriber_director_name}
										variant="filled"
										placeholder={t("Contract.serviceSubscriberDirectorNameText")}
										disabled={shouldUiBeDisabled}
										onChange={(e) => onValueChange(e, "service_subscriber_director_name")}
									/>
								</Grid>
							</Grid>
						</>
					)}
				{!projectInfo.custom_contract && !projectInfo.contract_signed.save_name
					&& (
						<>
							<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mb={1} bgcolor="#6F96C6">
								<Typography variant="h6" color="common.white" alignItems="center">
									{projectInfo.custom_contract ? "CUSTOM CONTRACT" : "GENERIC CONTRACT"}
								</Typography>
							</Grid>
							<Grid item container justifyContent="center">
								<Tabs
									value={selectedTab}
									variant="fullWidth"
									classes={{
										root: classes.menuBox,
									}}
									sx={{ "& button.Mui-selected": { color: getSelectedTabColor() } }}
									onChange={(_, newValue) => setSelectedTab(newValue)}
								>
									<Tab
										sx={{ color: getTabColor("company_details") }}
										label={<Typography>{"COMPANY DETAILS"}</Typography>}
										value="company_details"
									/>
									<Tab
										sx={{ color: getTabColor("service_subscriber_details") }}
										label={<Typography>{"SERVICE SUBSCRIBER DETAILS"}</Typography>}
										value="service_subscriber_details"
									/>
									<Tab
										sx={{ color: getTabColor("contract_schedules") }}
										label={<Typography>{"CONTRACT SCHEDULES"}</Typography>}
										value="contract_schedules"
									/>
								</Tabs>
							</Grid>
						</>
					)}
			</Grid>

			{!projectInfo.custom_contract && !projectInfo.contract_signed.save_name
				&& (
					renderSelectedTabs()
				)}
			{!projectInfo.custom_contract && projectInfo.contract_signed.save_name
				&& (
					<Grid container mt={6} sx={{ "> .MuiGrid-item": { p: 1 } }}>
						<Grid item container sx={{ p: 1 }} alignItems="center">
							<Grid item xs={12}>
								<Typography variant="h5" color="secondary">{t("Contract.signedDocuments")}</Typography>
							</Grid>
						</Grid>
						<Grid item container sx={{ p: 1 }} alignItems="center">
							<Grid item xs={12} md={4}>
								<Typography variant="h6" fontWeight="bold">{t("Contract.contractSigned")}</Typography>
							</Grid>
							<Grid item xs={12} md={8}>
								<Link href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${projectid}/${projectInfo.contract_signed.save_name}.pdf?token=${jwt.getToken()}`} target="_blank">
									{projectInfo.contract_signed.original_name || t("Contract.downloadFile")}
								</Link>
							</Grid>
						</Grid>
						<Grid item container sx={{ p: 1 }} alignItems="center">
							<Grid item xs={12} md={4}>
								<Typography variant="h6" fontWeight="bold">{t("Contract.schedule1Signed")}</Typography>
							</Grid>
							<Grid item xs={12} md={8}>
								<Link href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${projectid}/${projectInfo.schedule_1_signed.save_name}.pdf?token=${jwt.getToken()}`} target="_blank">
									{projectInfo.schedule_1_signed.original_name || t("Contract.downloadFile")}
								</Link>
							</Grid>
						</Grid>
						<Grid item container sx={{ p: 1 }} alignItems="center">
							<Grid item xs={12} md={4}>
								<Typography variant="h6" fontWeight="bold">{t("Contract.schedule2Signed")}</Typography>
							</Grid>
							<Grid item xs={12} md={8}>
								<Link href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${projectid}/${projectInfo.schedule_2_signed.save_name}.pdf?token=${jwt.getToken()}`} target="_blank">
									{projectInfo.schedule_2_signed.original_name || t("Contract.downloadFile")}
								</Link>
							</Grid>
						</Grid>
						<Grid item container sx={{ p: 1 }} alignItems="center">
							<Grid item xs={12} md={4}>
								<Typography variant="h6" fontWeight="bold">{t("Contract.schedule3Signed")}</Typography>
							</Grid>
							<Grid item xs={12} md={8}>
								<Link href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${projectid}/${projectInfo.schedule_3_signed.save_name}.pdf?token=${jwt.getToken()}`} target="_blank">
									{projectInfo.schedule_3_signed.original_name || t("Contract.downloadFile")}
								</Link>
							</Grid>
						</Grid>
						<Grid item container sx={{ p: 1 }} alignItems="center">
							<Grid item xs={12} md={4}>
								<Typography variant="h6" fontWeight="bold">{t("Contract.schedule4Signed")}</Typography>
							</Grid>
							<Grid item xs={12} md={8}>
								<Link href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${projectid}/${projectInfo.schedule_4_signed.save_name}.pdf?token=${jwt.getToken()}`} target="_blank">
									{projectInfo.schedule_4_signed.original_name || t("Contract.downloadFile")}
								</Link>
							</Grid>
						</Grid>
						<Grid item container sx={{ p: 1 }} alignItems="center">
							<Grid item xs={12} md={4}>
								<Typography variant="h6" fontWeight="bold">{t("Contract.schedule5Signed")}</Typography>
							</Grid>
							<Grid item xs={12} md={8}>
								<Link href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${projectid}/${projectInfo.schedule_5_signed.save_name}.pdf?token=${jwt.getToken()}`} target="_blank">
									{projectInfo.schedule_5_signed.original_name || t("Contract.downloadFile")}
								</Link>
							</Grid>
						</Grid>
						<Grid item container sx={{ p: 1 }} alignItems="center">
							<Grid item xs={12} md={4}>
								<Typography variant="h6" fontWeight="bold">{t("Contract.schedule6Signed")}</Typography>
							</Grid>
							<Grid item xs={12} md={8}>
								<Link href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${projectid}/${projectInfo.schedule_6_signed.save_name}.pdf?token=${jwt.getToken()}`} target="_blank">
									{projectInfo.schedule_6_signed.original_name || t("Contract.downloadFile")}
								</Link>
							</Grid>
						</Grid>
						<Grid item container sx={{ p: 1 }} alignItems="center">
							<Grid item xs={12} md={4}>
								<Typography variant="h6" fontWeight="bold">{t("Contract.schedule7Signed")}</Typography>
							</Grid>
							<Grid item xs={12} md={8}>
								<Link href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${projectid}/${projectInfo.schedule_7_signed.save_name}.pdf?token=${jwt.getToken()}`} target="_blank">
									{projectInfo.schedule_7_signed.original_name || t("Contract.downloadFile")}
								</Link>
							</Grid>
						</Grid>
					</Grid>
				)}
			{projectInfo.custom_contract && projectInfo.custom_contract_signed.save_name
				&& (
					<Grid container mt={6} sx={{ "> .MuiGrid-item": { p: 1 } }}>
						<Grid item container sx={{ p: 1 }} alignItems="center">
							<Grid item xs={12}>
								<Typography variant="h5" color="secondary">{"Signed Documents"}</Typography>
							</Grid>
						</Grid>
						<Grid item container sx={{ p: 1 }} alignItems="center">
							<Grid item xs={12} md={4}>
								<Typography variant="h6" fontWeight="bold">{"Contract Signed"}</Typography>
							</Grid>
							<Grid item xs={12} md={8}>
								<Link href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${projectid}/${projectInfo.custom_contract_signed.save_name}.pdf?token=${jwt.getToken()}`} target="_blank">
									{projectInfo.custom_contract_signed.original_name || t("Contract.downloadFile")}
								</Link>
							</Grid>
						</Grid>
					</Grid>
				)}
			{deleteModal.component !== null
				&& deleteModal.variable !== null
				&& (
					<Dialog
						keepMounted
						open={deleteModal.component !== null && deleteModal.variable !== null}
						TransitionComponent={Transition}
						scroll="body"
						PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4" } }}
						onClose={() => setDeleteModal({ component: null, variable: null })}
					>
						<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (ti) => ti.tileShadow, m: 0, p: 1 }}>
							<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
								{t("Contract.areYouSure")}
							</Typography>
						</DialogTitle>
						<DialogContent dividers sx={{ overflowY: "hidden" }}>
							<Grid item container sx={{ p: 1, marginTop: "5px" }} mt={1} alignItems="center">
								{t("Contract.areYouSureDelete")}
							</Grid>
							<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="25px">
								<Grid item sm={3} display="flex" justifyContent="start">
									<Button
										fullWidth
										variant="outlined"
										sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
										color="primary"
										onClick={() => setDeleteModal({ component: null, variable: null })}
									>
										{t("Contract.cancel")}
									</Button>
								</Grid>
								<Grid item display="flex" sm={3} justifyContent="end">
									<Button
										fullWidth
										variant="contained"
										sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
										color="primary"
										onClick={() => {
											onDelete(deleteModal);
											setDeleteModal({ component: null, variable: null });
										}}
									>
										{t("Contract.delete")}
									</Button>
								</Grid>
							</Grid>
						</DialogContent>
					</Dialog>
				)}
			<Paper className={classes.dialog} elevation={10}>
				<Dialog
					open={docusignDialogOpen}
					TransitionComponent={Transition}
					maxWidth="md"
					scroll="body"
					PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4", maxWidth: "700px", zIndex: 999 } }}
					onClose={() => setDocusignDialogOpen(false)}
				>
					<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (ti) => ti.tileShadow, m: 0, p: 1 }}>
						<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
							{"Sign Contract"}
						</Typography>
					</DialogTitle>
					<DialogContent dividers sx={{ overflowY: "hidden" }}>
						<DialogContentText>
							<Grid item container sx={{ p: 1 }} alignItems="start" justifyContent="center">
								<Typography>
									{"You will now be"}
									&nbsp;
									<b>{"redirected"}</b>
									&nbsp;
									{"to"}
									&nbsp;
									<b>{"DocuSign"}</b>
									&nbsp;
									{"secure environment in order to"}
									&nbsp;
									<b>{"sign"}</b>
									&nbsp;
									{"this Contract."}
								</Typography>
							</Grid>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Grid container alignItems="center" justifyContent="space-between">
							<Grid item sm={3} display="flex" justifyContent="start">
								<Button
									fullWidth
									variant="outlined"
									size="large"
									sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
									color="primary"
									onClick={() => setDocusignDialogOpen(false)}
								>
									{"Cancel"}
								</Button>
							</Grid>
							<Grid item sm={1} />
							<Grid item display="flex" sm={3} justifyContent="end">
								<Button
									fullWidth
									variant="contained"
									size="large"
									sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
									color="primary"
									onClick={sign}
								>
									{"Proceed"}
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				</Dialog>
			</Paper>
		</>
	);
};

export default memo(Contract);
