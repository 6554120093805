/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	Grid,
	LinearProgress,
	Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { httpResponseIsValid, useSnackbar } from "../../utils";
import usePrompt from "../../utils/use-blocker";
import {
	MainMenu,
	LabelTextField,
	LabelDropdown,
	LabelRadioTextField,
	LabelFileUpload,
	LabelRadioFileUpload,
} from "../../components/basic/index";
import {
	getProjectEsgAllAspects,
	updateProjectEsgAllAspects,
} from "../../api";

const EsgAllAspects = ({ disableAccess = true, isProjectDeveloper = false, onChangeProjectState }) => {
	const { projectid } = useParams();

	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(disableAccess);
	const [isLoading, setIsLoading] = useState(false);

	const [user, setUser] = useState({
		company_id: "",
	});
	const [options, setOptions] = useState({
		esgAllAspectsOptions1: {},
	});
	const [project, setProject] = useState({
		name: "",
		status: "-",
		createdAt: "-",
		data: {
			management_txt_1: "",
			management_txt_2: "",
			management_radio_1: "false",
			management_txt_3: "",
			management_radio_2: "false",
			management_radio_3: "false",
			management_txt_5: "",
			performance_radio_1: "false",
		},
		docs: {
			all_aspects_management_doc_1: {
				original_name: "",
				save_name: "",
			},
			environmental_social_business_ethic_issues_doc_1: {
				original_name: "",
				save_name: "",
			},
		},
	});

	const [enableEditing, setEnableEditing] = useState(false);
	const { success, error } = useSnackbar();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getProjectEsgAllAspects(projectid);
				if (httpResponseIsValid(response)) {
					setOptions(response.options);
					setProject(response.project);
					setUser(response.user);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				console.log(`Error occured when fetching esg's all aspects data: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, projectid],
	);

	const handleSubmit = async (values) => {
		setIsLoading(true);

		try {
			const response = await updateProjectEsgAllAspects(projectid, values);
			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			console.log(`Error occured when updating esg's all aspects data: ${_error}`);
		}

		setIsLoading(false);
	};

	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: project,
		onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	});

	const { dirty } = formik;

	usePrompt("You have unsaved changes which will be lost. Are you sure you want to leave the page?", dirty);

	useEffect(() => {
		setShouldUiBeDisabled(disableAccess || !enableEditing);
	}, [disableAccess, enableEditing]);

	useEffect(() => {
		formik.resetForm({ values: project, dirty: false });
	}, [project]);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData, projectid]);

	return (
		<form onSubmit={formik.handleSubmit}>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<MainMenu
				title={t("ESG.AllAspects.title")}
				dirty={dirty}
				project={project}
				isDeveloper={isProjectDeveloper}
				disabled={disableAccess}
				onFetch={fetchData}
				onEdit={setEnableEditing}
				onChange={onChangeProjectState}
			/>

			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "105px" }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("ESG.AllAspects.Management.title")}
					</Typography>
				</Grid>

				<LabelDropdown
					id="data.management_txt_1"
					size="large"
					label={t("ESG.AllAspects.Management.drop1")}
					options={options.esgAllAspectsOptions1}
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>

				<LabelTextField
					id="data.management_txt_2"
					label={t("ESG.AllAspects.Management.text1")}
					size="large"
					placeholder="Answer"
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>

				<LabelRadioTextField
					radioId="data.management_radio_1"
					fieldId="data.management_txt_3"
					label={t("ESG.AllAspects.Management.text2")}
					placeholder="Answer"
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>

				<LabelRadioFileUpload
					radioId="data.management_radio_2"
					fieldId="docs.all_aspects_management_doc_1"
					projectId={projectid}
					label={t("ESG.AllAspects.Management.doc1")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="esg"
					onSuccess={() => fetchData()}
				/>

				<LabelRadioTextField
					radioId="data.management_radio_3"
					fieldId="data.management_txt_5"
					label={t("ESG.AllAspects.Management.text3")}
					placeholder="Answer"
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>

				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("ESG.AllAspects.Performance.title")}
					</Typography>
				</Grid>

				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center">
					<LabelFileUpload
						viewOnly
						fieldId="docs.environmental_social_business_ethic_issues_doc_1"
						projectId={user.company_id}
						label={t("ESG.AllAspects.Performance.docIssues1")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
				</Grid>
			</Grid>
		</form>
	);
};

export default memo(EsgAllAspects);
