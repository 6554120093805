import { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, LinearProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import { useSnackbar, httpResponseIsValid } from "../../utils";
import { getProjectRiskAssessentProtocol, updateProjectRiskAssessentProtocol } from "../../api";
import usePrompt from "../../utils/use-blocker";
import {
	MainMenu,
	LabelRadio,
} from "../../components/basic/index";

const InterfaceRisk = ({ disableAccess = true, isProjectDeveloper = false, onChangeProjectState }) => {
	const { projectid } = useParams();
	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(disableAccess);
	const [isLoading, setIsLoading] = useState(false);
	const [project, setProject] = useState({
		status: "",
		createdAt: "-",
		interface_risk: {
			interface_risk_relevance: "",
			interface_risk_responsibility: "",
			interface_risk_mitigation: "",
			clean_and_safe: "",
			installed_location: "",
			alteration: "",
			inspection_and_maintenance: "",
		},
	});
	const [enableEditing, setEnableEditing] = useState(false);
	const { success, error } = useSnackbar();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getProjectRiskAssessentProtocol(projectid);
				if (httpResponseIsValid(response)) {
					setProject(response);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured while fetching risk assessment interface risk data: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, projectid],
	);

	const handleSubmit = async (values) => {
		setIsLoading(true);

		try {
			const response = await updateProjectRiskAssessentProtocol(projectid, values);

			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			error(`Error occured while submitting risk assessment interface risk data: ${_error}`);
		}

		setIsLoading(false);
	};

	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: project,
		onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	});

	const { dirty } = formik;

	usePrompt("You have unsaved changes which will be lost. Are you sure you want to leave the page?", dirty);

	useEffect(() => {
		setShouldUiBeDisabled(disableAccess || !enableEditing);
	}, [disableAccess, enableEditing]);

	useEffect(() => {
		formik.resetForm({ values: project, dirty: false });
	}, [project]);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData, projectid]);

	return (
		<form onSubmit={formik.handleSubmit}>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<MainMenu
				title={t("RiskAssessmentOverview.interfaceRisk")}
				dirty={dirty}
				project={project}
				isDeveloper={isProjectDeveloper}
				disabled={disableAccess}
				onFetch={fetchData}
				onEdit={setEnableEditing}
				onChange={onChangeProjectState}
			/>

			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "105px" }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("RegulatoryRisk.relevance")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} alignItems="center">
					<Grid item xs={12} md={9} sm={8} p={1}>
						<Typography variant="h8" sx={{ color: "blue.dark" }}>
							{t("InterfaceRisk.relevanceVar1")}
						</Typography>
					</Grid>
					<Grid item md={0.2} />
					{/* <Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center"> */}
					<Grid item xs={12} sm={4} md={2} direction="row" alignItems="center">

						<LabelRadio
							id="interface_risk.interface_risk_relevance"
							label={t("RegulatoryRisk.riskPresent")}
							size="large"
							options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
					</Grid>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("RegulatoryRisk.responsibility")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} alignItems="center" bgcolor="grey.main">
					<Grid item xs={12} md={9} sm={8} p={1}>
						<Typography variant="h8" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
							{t("InterfaceRisk.responsibilityVar1")}
						</Typography>
						{"\n"}
						<LabelRadio
							id="interface_risk.clean_and_safe"
							label={t("InterfaceRisk.responsibilityVar2")}
							size="flat"
							typosize="h8"
							options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
						{"\n"}
						<LabelRadio
							id="interface_risk.alteration"
							label={t("InterfaceRisk.responsibilityVar3")}
							size="flat"
							typosize="h8"
							options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
						{"\n"}
						<LabelRadio
							id="interface_risk.installed_location"
							label={t("InterfaceRisk.responsibilityVar4")}
							size="flat"
							typosize="h8"
							options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
						{"\n"}
						<LabelRadio
							id="interface_risk.inspection_and_maintenance"
							label={t("InterfaceRisk.responsibilityVar5")}
							size="flat"
							typosize="h8"
							options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
						{"\n"}
					</Grid>
					<Grid item md={0.2} />
					<Grid item xs={12} sm={4} md={2} direction="row" alignItems="center">
						<LabelRadio
							id="interface_risk.interface_risk_responsibility"
							label={t("RegulatoryRisk.riskBearer")}
							size="large"
							options={[{ value: "false", label: "Client" }, { value: "true", label: "Contractor" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
					</Grid>
				</Grid>

				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("EnergyPriceRisk.mitigation")}
					</Typography>
				</Grid>
				<Grid item xs={12} md={9} sm={8} p={1}>
					<Grid item container sx={{ p: 1 }} alignItems="center">
						<Typography variant="h8" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
							{t("InterfaceRisk.mitigationVar1")}
						</Typography>
					</Grid>
				</Grid>
				<Grid item md={0.5} />
				<Grid item xs={12} sm={4} md={2} direction="row" alignItems="center">
					<LabelRadio
						id="interface_risk.interface_risk_mitigation"
						label={t("EnergyPriceRisk.fullyMitigated")}
						size="large"
						options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>
				</Grid>
			</Grid>
		</form>
	);
};

export default memo(InterfaceRisk);
