import { memo, useEffect, useState } from "react";
import { Typography, FormControl, Paper, InputBase, FormHelperText, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PropTypes from "prop-types";

import sefaTheme from "../../utils/theme";

export const LoginPasswordStyles = {
	iconStyle: {
		backgroundColor: "white",
		color: sefaTheme.palette.green.main,
		width: 30,
		height: 30,
		marginLeft: "8px",
	},
};

const LoginInput = ({ id, icon: inputIcon, value: inputValue, type: inputType = "password", name: inputName = "Login Password", disabled: inputDisabled = false, error: inputError, onSetVisible, onChange }) => {
	const [icon, setIcon] = useState(inputIcon);
	const [value, setValue] = useState(inputValue);
	const [type, setType] = useState(inputType);
	const [name, setName] = useState(inputName);
	const [disabled, setDisabled] = useState(inputDisabled);
	const [error, setError] = useState(inputError);

	useEffect(() => {
		setIcon(inputIcon);
	}, [inputIcon]);

	useEffect(() => {
		setValue(inputValue);
	}, [inputValue]);

	useEffect(() => {
		setType(inputType);
	}, [inputType]);

	useEffect(() => {
		setName(inputName);
	}, [inputName]);

	useEffect(() => {
		setDisabled(inputDisabled);
	}, [inputDisabled]);

	useEffect(() => {
		setError(inputError);
	}, [inputError]);

	return (
		<Paper sx={{ display: "flex", alignItems: "center", width: 320, height: 45, borderRadius: "8px" }}>
			{icon}
			<Paper
				sx={{
					display: "flex",
					alignItems: "center",
					width: 275,
					height: 45,
					marginLeft: "8px",
					borderRadius: "0px 8px 8px 0px",
					backgroundColor: "#aac2d6",
				}}
			>
				<FormControl sx={{ width: 310, height: 45 }}>
					<InputBase
						id={id}
						sx={{
							flex: 1,
							height: 45,
							padding: "10px",
							borderRadius: "0px 8px 8px 0px",
							backgroundColor: "#aac2d6",
							"&:hover": { backgroundColor: "#B9CBD8" },
							color: "white",
							input: { "&::placeholder": { opacity: 0.8 } },
						}}
						inputProps={{
							"aria-label": `${name}`,
							style: {
								WebkitBoxShadow: "0 0 0 1000px #aac2d6 inset",
							},
						}}
						placeholder={name}
						value={value}
						type={type}
						disabled={disabled}
						onChange={onChange}
					/>
					<FormHelperText id="my-helper-text" error={error !== ""}>
						<Typography variant="h8" sx={{ color: "#FA7D63", width: "380px" }}>
							{error === "" ? "" : error}
						</Typography>
					</FormHelperText>

				</FormControl>
				{value !== "" && (
					<IconButton tabIndex={-1} disabled={value === ""} onClick={() => onSetVisible()}>
						{type === "password" ? (
							<VisibilityIcon sx={{ tabIndex: "-1", width: 25, height: 25 }} />
						) : (
							<VisibilityOffIcon sx={{ tabIndex: "-1", width: 25, height: 25 }} />
						)}
					</IconButton>
				)}
			</Paper>
		</Paper>
	);
};

LoginInput.propTypes = {
	id: PropTypes.string.isRequired,
	icon: PropTypes.any,
	value: PropTypes.any.isRequired,
	type: PropTypes.string,
	name: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	error: PropTypes.string,
	onSetVisible: PropTypes.func,
	onChange: PropTypes.func.isRequired,
};

export default memo(LoginInput);
