import { useEffect, memo } from "react";
import { Grid, Box, Typography, Button, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { Image } from "mui-image";

import { jwt } from "../utils";
import logo from "../assets/images/logo_white.png";
import background from "../assets/images/background.jpg";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(0, -1),
		overflow: "hidden",
		width: "100vw",
		height: "100vh",
		backgroundImage: `url(${background})`,
		backgroundPosition: "center",
		backgroundSize: "cover",
	},
	title: {
		color: theme.palette.common.white,
		letterSpacing: theme.spacing(0.6),
	},
	text: {
		color: theme.palette.common.white,
		letterSpacing: theme.spacing(0.2),
	},
	button: {
		fontSize: "20px",
		letterSpacing: theme.spacing(0.2),
		backgroundColor: theme.palette.green.main,
		color: "#fff",
		"&:hover": {
			backgroundColor: "#fff",
			color: theme.palette.green.main,
		},
		height: "50px",
		width: "600px",
	},
}));

const SignIn = () => {
	const { state } = useLocation();
	const classes = useStyles();
	const navigate = useNavigate();

	useEffect(() => {
		try {
			if (state?.from.pathname) {
				sessionStorage.setItem("redirectTo", JSON.stringify(state?.from || { pathname: `/${state?.from.pathname}` }));
			} else {
				sessionStorage.setItem("redirectTo", JSON.stringify(state?.from || { pathname: "/projects" }));
			}
		} catch { /** */ }
	}, [state]);

	return (
		<Grid container direction="row" justifyContent="center" align="center" className={classes.root}>
			<Grid item container direction="column" justifyContent="center" align="center" sm={5} xs={12} sx={{ "> .MuiGrid-item": { p: 1 } }}>
				<Grid item container alignItems="center" justifyContent="center">
					<Box sx={{ minWidth: "250px" }}>
						<Image src={logo} alt="Sefa" />
					</Box>
				</Grid>
				<Grid item mt={1}>
					<Typography variant="h4" className={classes.title}>{"Thank you for joining SEFA!"}</Typography>
				</Grid>
				<Grid item container direction="column" justifyContent="center" alignItems="center" mt={2}>
					<Typography variant="h5" align="left" sx={{ width: "600px" }} className={classes.text}>{"Your account has been created successfully, but you can not perform any actions, because you are not yet a verified user."}</Typography>
				</Grid>
				<Grid item container direction="column" justifyContent="center" alignItems="center">
					<Typography variant="h5" align="left" sx={{ width: "600px" }} className={classes.text}>{"A request for verification has been sent to SEFA Admins. They will process your request and respond back to you shortly."}</Typography>
				</Grid>
				<Grid item container mt={4} direction="row" justifyContent="center" alignItems="center">
					<Button
						className={classes.button}
						component={Link}
						variant="contained"
						size="large"
						color="green"
						onClick={() => {
							jwt.destroyToken();
							navigate("/");
						}}
					>
						{"Log out"}
					</Button>
				</Grid>
			</Grid>
			<Grid item sm={7} />
		</Grid>
	);
};

export default memo(SignIn);
