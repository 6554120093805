import { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getIn } from "formik";
import {
	Grid,
	Typography,
	Tooltip,
	Radio,
	RadioGroup,
	FormControlLabel,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import { CustomFileUpload } from "../custom/index";

export const LabelRadioFileUpload = ({
	radioId,
	fieldId,
	label,
	bold = false,
	projectId,
	fileTool,
	size: labelSize = "medium",
	disabled = false,
	formik,
	infoText = "",
	typosize = "h6",
	onSuccess,
}) => {
	const [size, setSize] = useState({ label: 5, sep: 2, field: 5 });

	const {
		values,
		handleChange,
	} = formik;

	useEffect(() => {
		switch (labelSize) {
			case "small":
				setSize({ label: 4, sep: 2, field: 6 });
				break;
			case "medium":
				setSize({ label: 5, sep: 2, field: 5 });
				break;
			case "large":
				setSize({ label: 6, sep: 2, field: 4 });
				break;
			case "flat":
				setSize({ label: 5.5, sep: 3, field: 3.5 });
				break;
			default:
				setSize({ label: 5, sep: 2, field: 5 });
		}
	}, [labelSize]);

	return (
		<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems="start">
			<Grid item container xs={12} md={size.label}>
				<Typography variant={typosize} fontWeight={bold ? "bold" : ""} sx={{ textAlign: "justify", color: "blue.dark" }}>
					{label}
					{infoText && (
						<Tooltip placement="bottom-start" title={infoText}>
							<InfoIcon fontSize="small" sx={{ padding: 0.2, color: "green.main" }} />
						</Tooltip>
					)}
				</Typography>
			</Grid>
			<Grid item container md={size.sep} sx={{ marginTop: "-5px" }} justifyContent="center" alignItems="center">
				<RadioGroup
					row
					id={radioId}
					name={radioId}
					value={getIn(values, radioId)}
					size="small"
					onChange={handleChange}
				>
					<FormControlLabel
						key="no"
						value="false"
						control={<Radio />}
						disabled={disabled || (getIn(values, fieldId)?.save_name !== "")}
						label={(
							<Typography variant="h7">
								{"No"}
							</Typography>
						)}
					/>
					<FormControlLabel
						key="yes"
						value="true"
						control={<Radio />}
						disabled={disabled}
						label={(
							<Typography variant="h7">
								{"Yes"}
							</Typography>
						)}
					/>
				</RadioGroup>
				<Typography variant="h7" fontWeight="bold" sx={{ marginLeft: "10px", color: "blue.dark" }}>
					{"If yes, please specify."}
				</Typography>
			</Grid>
			<Grid item xs={12} md={size.field}>
				<CustomFileUpload
					id={fieldId}
					projectId={projectId}
					size={typosize === "h8" ? "small" : "large"}
					fileTool={fileTool}
					disabled={disabled || (getIn(values, radioId) === "false")}
					variable={fieldId}
					formik={formik}
					onSuccess={onSuccess}
				/>
			</Grid>
		</Grid>
	);
};

LabelRadioFileUpload.propTypes = {
	radioId: PropTypes.string.isRequired,
	fieldId: PropTypes.string.isRequired,
	projectId: PropTypes.string.isRequired,
	fileTool: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	bold: PropTypes.bool,
	size: PropTypes.string,
	disabled: PropTypes.bool,
	formik: PropTypes.any.isRequired,
	infoText: PropTypes.string,
	onSuccess: PropTypes.func.isRequired,
	typosize: PropTypes.string,
};

export default memo(LabelRadioFileUpload);
