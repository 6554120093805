import { useState, useEffect, memo } from "react";
import { useLocation, Routes, Route, Link } from "react-router-dom";
import { Drawer, Box, List, ListItem, ListItemText, Collapse } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { getUserProfile } from "../../api";
import { useSnackbar, httpResponseIsValid } from "../../utils";

import PreAssessment from "./PreAssessment";
import SpreadsheetComponent from "./SpreadsheetComponent";
import CompanyKycOverview from "./Kyc";
import CompanyDocumentsOverview from "./Documents";

const useStyles = makeStyles((theme) => ({
	background: {
		position: "fixed",
		background: "#1d4363",
		width: "300px",
		// position: "fixed",
		// background: theme.palette.dark.main,
	},
	menuItem: {
		color: theme.palette.common.white,
		padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
		cursor: "pointer",
		"&:hover[aria-disabled=false]": {
			backgroundColor: "#4a82c3",
			color: theme.palette.common.white,
		},
		"&[aria-checked=true][aria-disabled=false]": {
			backgroundColor: "#4a82c3",
		},
		"&[aria-disabled=true]": {
			pointerEvents: "none",
			cursor: "not-allowed",
		},
		"&:hover[aria-disabled=true]": {
			color: theme.palette.common.white,
		},
		"&[aria-checked=true][aria-disabled=true]": {
			color: theme.palette.common.white,
		},
	},
	submenuItem: {
		color: theme.palette.primary.light,
		padding: `${theme.spacing(1)} ${theme.spacing(5)}`,
		"&:hover[aria-disabled=false]": {
			backgroundColor: "#4a82c3",
			color: theme.palette.common.white,
		},
		"&[aria-checked=true][aria-disabled=false]": {
			backgroundColor: "#4a82c3",
			color: theme.palette.common.white,
		},
		"&[aria-disabled=true]": {
			pointerEvents: "none",
			cursor: "not-allowed",
		},
		"&:hover[aria-disabled=true]": {
			color: theme.palette.common.white,
		},
		"&[aria-checked=true][aria-disabled=true]": {
			color: theme.palette.common.white,
		},
	},
	routeDiv: {
		flex: 1,
		flexGrow: 1,
		margin: "0",
		float: "right",
		display: "flex",
		flexDirection: "column",
		overflow: "auto",
		maxWidth: "1300px",
	},
	outRouteDiv: {
		width: "100%",
		marginLeft: "300px",
		display: "flex",
		justifyContent: "center",
	},
}));

const availablePaths = new Set([
	"company-output",
	"company-pre-assessment",
	"company-profit-loss",
	"company-financial-position",
	"company-cash-flow",
	"company-kpis",
	"company-kyc",
	"company-documents",
]);

const availableSubPaths = new Set([
	"past-years",
	"estimate-current-year",
	"forecast-year-1",
	"forecast-year-2",
]);

const CompanyTools = () => {
	const { error } = useSnackbar();
	const { pathname } = useLocation();
	const [userRole, setUserRole] = useState("member");
	const [companyId, setCompanyId] = useState(null);

	const [value, setValue] = useState();

	const [height, setHeight] = useState(100);
	const classes = useStyles();

	useEffect(() => {
		const resizeObserver = new ResizeObserver((event) => {
			setHeight(event[0].contentBoxSize[0].blockSize);
		});

		resizeObserver.observe(document.querySelector("#header"));
	});

	useEffect(() => {
		(async () => {
			try {
				const response = await getUserProfile();
				if (httpResponseIsValid(response)) {
					setCompanyId(response?.company_id);
					setUserRole(response?.role);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				console.log(`Error occured when fetching company headers data: ${_error}`);
			}
		})();
	}, [error]);

	const [selectedIndex, setSelectedIndex] = useState(window.location.pathname.split("/").at(-1));
	const [lastSelectedIndex, setLastSelectedIndex] = useState("");

	const handleCollapses = (section) => {
		switch (section) {
			case "company-spreadsheet":
				if ([
					"company-spreadsheet",
					"company-output",
					"company-pre-assessment",
					"company-profit-loss",
					"company-financial-position",
					"company-cash-flow",
					"company-kpis",
				].includes(selectedIndex)) {
					return true;
				}

				break;
			case "company-kyc":
				if ([
					"company-kyc",
				].includes(selectedIndex)) {
					return true;
				}

				break;
			case "company-documents":
				if ([
					"company-documents",
				].includes(selectedIndex)) {
					return true;
				}

				break;
			default:
				break;
		}

		return false;
	};

	useEffect(() => {
		setValue(() => {
			const pathParts = pathname.split("/");

			if (availableSubPaths.has(pathParts.at(-1)) && availablePaths.has(pathParts.at(-2))) {
				return pathParts.at(-2);
			}

			if (availablePaths.has(pathParts.at(-1))) {
				return pathParts.at(-1);
			}

			return "output";
		});
	}, [pathname]);

	const onSelectListMenu = (menu) => {
		if (menu === lastSelectedIndex) {
			setSelectedIndex("none");
			setLastSelectedIndex("none");
		} else {
			setSelectedIndex(menu);
			setLastSelectedIndex(menu);
		}
	};

	return (
		<div id="drawer-container" style={{ position: "relative", display: "flex", minHeight: `calc(100vh - ${height}px)` }}>
			<Drawer
				variant="permanent"
				sx={{
					width: "300px",
					height: "100%",
					position: "fixed",
					flexShrink: 0,
				}}
				ModalProps={{
					container: document.querySelector("#drawer-container"),
					style: { position: "absolute" },
				}}
				classes={{
					paperAnchorLeft: classes.background,
				}}
			>
				<Box sx={{ overflow: "auto", marginTop: "105px" }}>
					<List>
						<ListItem key="company-spreadsheet" component={Link} aria-disabled="false" className={classes.menuItem} to="company-output" onClick={() => onSelectListMenu("company-spreadsheet")}>
							<ListItemText primary="Company Financial Assessment" />
						</ListItem>
						<Collapse unmountOnExit in={handleCollapses("company-spreadsheet")} timeout="auto">
							<List disablePadding component="div">
								<ListItem component={Link} aria-checked={value === "company-output"} className={classes.submenuItem} to="company-output" aria-disabled={false}>
									<ListItemText key="company-output" primary="Overview" />
								</ListItem>
								<ListItem component={Link} aria-checked={value === "company-pre-assessment"} className={classes.submenuItem} to="company-pre-assessment" aria-disabled={false}>
									<ListItemText key="company-pre-assessment" primary="Pre-Assessment" />
								</ListItem>
								<ListItem component={Link} aria-checked={value === "company-profit-loss"} className={classes.submenuItem} to="company-profit-loss" aria-disabled={false}>
									<ListItemText key="company-profit-loss" primary="Income Statement" />
								</ListItem>
								<ListItem component={Link} aria-checked={value === "company-financial-position"} className={classes.submenuItem} to="company-financial-position" aria-disabled={false}>
									<ListItemText key="company-financial-position" primary="Balance Sheet" />
								</ListItem>
								<ListItem component={Link} aria-checked={value === "company-cash-flow"} className={classes.submenuItem} to="company-cash-flow" aria-disabled={false}>
									<ListItemText key="company-cash-flow" primary="Cash Flow" />
								</ListItem>
								<ListItem component={Link} aria-checked={value === "company-kpis"} className={classes.submenuItem} to="company-kpis" aria-disabled={false}>
									<ListItemText key="company-kpis" primary="KPIs" />
								</ListItem>
							</List>
						</Collapse>
						<ListItem key="company-kyc" component={Link} aria-checked={value === "company-kyc"} aria-disabled="false" className={classes.menuItem} to="company-kyc" onClick={() => onSelectListMenu("company-kyc")}>
							<ListItemText primary="Company KYC" />
						</ListItem>
						<ListItem key="company-documents" component={Link} aria-checked={value === "company-documents"} aria-disabled="false" className={classes.menuItem} to="company-documents" onClick={() => onSelectListMenu("company-documents")}>
							<ListItemText primary="Company Documents" />
						</ListItem>
					</List>
				</Box>
			</Drawer>
			<div className={classes.outRouteDiv}>
				<div className={classes.routeDiv}>
					<Routes>
						<Route path="/company-pre-assessment/*" element={<PreAssessment key={userRole} userCompanyId={companyId} companyRole={userRole} />} />
						<Route path="/company-profit-loss/*" element={<SpreadsheetComponent key={userRole} componentName="profit_loss" companyRole={userRole} />} />
						<Route path="/company-financial-position/*" element={<SpreadsheetComponent key={userRole} componentName="financial_position" companyRole={userRole} />} />
						<Route path="/company-cash-flow/*" element={<SpreadsheetComponent key={userRole} componentName="cash_flow" companyRole={userRole} />} />
						<Route path="/company-kpis/*" element={<SpreadsheetComponent key={userRole} componentName="kpis" companyRole={userRole} />} />
						<Route path="/company-output/*" element={<SpreadsheetComponent key={userRole} componentName="output" companyRole={userRole} />} />
						<Route path="/company-kyc/*" element={<CompanyKycOverview key={userRole} userCompanyId={companyId} companyRole={userRole} />} />
						<Route path="/company-documents/*" element={<CompanyDocumentsOverview key={userRole} userCompanyId={companyId} companyRole={userRole} />} />
					</Routes>
				</div>
			</div>
		</div>
	);
};

export default memo(CompanyTools);
