/* eslint-disable unicorn/consistent-destructuring */
import { memo, useState, useEffect } from "react";
import { Grid, Typography, Button, Link, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import EmailIcon from "@mui/icons-material/Email";
import { decode } from "js-base64";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
	LoginInput,
	LoginInputStyles,
	LoginPassword,
	LoginPasswordStyles,
} from "../components/login/index";
import { useSnackbar, httpResponseIsValid } from "../utils";
import Spinner from "../components/Spinner";
import background from "../assets/images/background.jpg";
import { changePassword } from "../api";

const ResetPasswordSchema = Yup.object().shape({
	email: Yup.string().trim().email().required("E-mail is required"),
	password: Yup.string().min(8).required("Password is required"),
	confirm_password: Yup.string().test("passwords-match", "Passwords must match", function (value) {
		return this.parent.password === value;
	}),
});

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(0, -1),
		overflow: "hidden",
		width: "100vw",
		height: "100vh",
		backgroundImage: `url(${background})`,
		backgroundPosition: "center",
		backgroundSize: "cover",
	},
	title: {
		color: theme.palette.common.white,
		letterSpacing: theme.spacing(0.01),
	},
	subtitle: {
		color: theme.palette.green.main,
		letterSpacing: theme.spacing(0.05),
	},
	buttontitle: {
		color: theme.palette.primary.main,
		letterSpacing: theme.spacing(0.1),
	},
	buttonLarge: {
		backgroundColor: theme.palette.green.main,
		color: theme.palette.common.white,
		borderRadius: "8px",
		width: "320px",
		"&:hover": {
			backgroundColor: "#248F6A",
		},
	},
	loginInputIcons: LoginInputStyles.iconStyle,
	loginPasswordIcons: LoginPasswordStyles.iconStyle,
}));

const ResetPassword = () => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const classes = useStyles();
	const { error, success } = useSnackbar();

	const navigate = useNavigate();

	const handleSubmit = async (values) => {
		setIsSubmitting(true);
		console.log(values);

		try {
			const response = await changePassword({
				email: values.email,
				token: values.token,
				password: values.password,
			});

			if (httpResponseIsValid(response)) {
				success(response?.message);
				setIsSubmitting(false);
				navigate("/");
			} else {
				setIsSubmitting(false);
				error(response?.message);
			}
		} catch (_error) {
			setIsSubmitting(false);
			error(`Error occured while changing the password: ${_error}`);
		}
	};

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
			confirm_password: "",
			token: "",
		},
		validationSchema: ResetPasswordSchema,
		onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	});

	const { errors, touched } = formik;

	useEffect(() => {
		(async () => {
			try {
				const token = window.location.pathname.split("/")[2];
				const params = new URLSearchParams(window.location.search);
				const email = decode(params.get("email"));

				const validationSchema = Yup.object().shape({
					email: Yup.string().trim().email().required(),
				});

				const emailValid = await validationSchema.isValid({ email });

				if (emailValid && token) {
					formik.setFieldValue("email", email);
					formik.setFieldValue("token", token);
				}
			} catch (_error) {
				console.log(_error);
			}
		})();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error]);

	return (
		<form onSubmit={formik.handleSubmit}>
			<Spinner open={isSubmitting} />
			<Grid container direction="row" justifyContent="center" align="center" className={classes.root}>
				<Grid item container direction="column" justifyContent="center" align="center" sm={5} xs={12} sx={{ "> .MuiGrid-item": { p: 1 } }}>
					<Grid item mt={2}>
						<Typography variant="h4" className={classes.title}>{"Reset Password"}</Typography>
					</Grid>
					<Grid item container direction="column" justifyContent="center" alignItems="center">
						<Grid item>
							<LoginInput
								id="email"
								icon={<EmailIcon className={classes.loginInputIcons} />}
								name="Email Address"
								value={formik.values.email}
								disabled={formik.values.email !== ""}
								error={errors.email && touched.email ? errors.email : ""}
								onChange={formik.handleChange}
							/>
						</Grid>
						<Grid item mb={1} />
						<Grid item>
							<LoginPassword
								id="password"
								icon={<LockIcon className={classes.loginPasswordIcons} />}
								name="New password"
								value={formik.values.password}
								type={showPassword ? "text" : "password"}
								error={errors.password && touched.password ? errors.password : ""}
								onSetVisible={() => setShowPassword(!showPassword)}
								onChange={formik.handleChange}
							/>
						</Grid>
						<Grid item mb={errors.password && touched.password ? 5 : 1} />
						<Grid item>
							<LoginPassword
								id="confirm_password"
								icon={<LockIcon className={classes.loginPasswordIcons} />}
								name="Repeat password"
								value={formik.values.confirm_password}
								type={showPassword ? "text" : "password"}
								error={errors.confirm_password && touched.confirm_password ? errors.confirm_password : ""}
								onSetVisible={() => setShowPassword(!showPassword)}
								onChange={formik.handleChange}
							/>
						</Grid>
						<Grid item mb={errors.confirm_password && touched.confirm_password ? 6 : 2} />
						<Grid item>
							<Button type="submit" className={classes.buttonLarge} variant="contained" size="large">
								<Typography variant="h7" className={classes.buttonTitle}>
									<b>{"Change Password"}</b>
								</Typography>
							</Button>
						</Grid>
					</Grid>
					<Grid item container direction="column" justifyContent="center" alignItems="space-between">
						<Grid item>
							<Typography variant="h7" color="white">{"Otherwise "}</Typography>
							<Typography variant="h7" className={classes.subtitle}>
								<Link color="inherit" underline="none" href="sign-in">{"Sign In Here"}</Link>
							</Typography>
						</Grid>
						<Grid item>
							<Divider style={{ width: "200px", margin: "0px", marginTop: "5px", marginBottom: "5px", borderBottom: "2px solid #dddddd", borderRight: "2px solid #dddddd" }} />
						</Grid>
						<Grid item>
							<Typography variant="h7" color="white">
								{"Don't have an account? "}
							</Typography>
							<Typography variant="h7" className={classes.subtitle}>
								<Link color="inherit" sx={{ "&:hover": { color: "#76EAC3" } }} underline="none" href="sign-up">
									{"Sign Up Here"}
								</Link>
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item sm={7} />
			</Grid>
		</form>
	);
};

export default memo(ResetPassword);
