import { memo, useState, useCallback, useEffect } from "react";
import { Grid, Typography, TextField, FormControl, FormHelperText, RadioGroup, Radio, FormControlLabel, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";

import { getExpertContactForm, postExpertContactForm } from "../../api";
import { httpResponseIsValid, useSnackbar } from "../../utils";

// Comment

const useStyles = makeStyles((theme) => ({
	screenTitle: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		letterSpacing: theme.spacing(0.2),
	},
}));

const ExpertContactForm = () => {
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [googleForm, setGoogleForm] = useState({});
	const [formData, setFormData] = useState({
		first_name: "",
		last_name: "",
		email_address: "",
		phone_number: "",
		subject_type: "",
		subject_other: "",
		description: "",
	});

	const [formErrors, setFormErrors] = useState({
		first_name: "",
		last_name: "",
		email_address: "",
		phone_number: "",
		subject_type: "",
		subject_other: "",
		description: "",
	});

	const classes = useStyles();

	const { success, error } = useSnackbar();

	const fetchData = useCallback(
		async () => {
			try {
				const response = await getExpertContactForm();
				if (httpResponseIsValid(response)) {
					setGoogleForm(response?.form);
				} else {
					error(response?.message);
				}

				setIsSubmitted(false);
			} catch (_error) {
				console.log(`Error occured while fetching google form: ${_error}`);
			}
		}, [error],
	);

	const onValueChange = (field, event) => {
		setFormData({
			...formData,
			[field]: event.target.value,
		});

		if (field !== "") {
			setFormErrors({
				...formErrors,
				[field]: "",
			});
		}
	};

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData]);

	const onSubmit = useCallback(
		async () => {
			try {
				setIsSubmitted(true);

				// check for any errors
				let errorsFound = false;
				const tempErrors = { ...formErrors };
				for (const [key, value] of Object.entries(formData)) {
					if (value === "" && googleForm[key]?.required) {
						tempErrors[key] = "This field can not be empty.";
						errorsFound = true;
					}
				}

				if (formData.subject_type === "__other_option__" && formData.subject_other === "") {
					tempErrors.subject_type = "This field can not be empty.";
					errorsFound = true;
				}

				const validationSchema = Yup.object().shape({
					email: Yup.string().trim().email().required(),
				});

				const emailValid = await validationSchema.isValid({ email: formData.email_address });

				if (!emailValid && !errorsFound) {
					tempErrors.email_address = "Invalid email format.";
					errorsFound = true;
				}

				if (errorsFound) {
					setFormErrors(tempErrors);
					return;
				}

				const data = {};
				for (const key of Object.keys(googleForm)) {
					data[googleForm[key].id] = formData[key];
				}

				if (formData.subject_type === "__other_option__") {
					data[`${googleForm.subject_type.id}.other_option_response`] = formData.subject_other;
				}

				const response = await postExpertContactForm(data);
				if (httpResponseIsValid(response)) {
					success(response?.message);
				} else {
					error(response?.message);
				}

				setFormData({
					first_name: "",
					last_name: "",
					email_address: "",
					phone_number: "",
					subject_type: "",
					subject_other: "",
					description: "",
				});
			} catch (_error) {
				error(`Error occured while submitting google form: ${_error})`);
			}
		}, [error, formData, formErrors, googleForm, success],
	);

	return (
		<>
			<Grid container direction="column" alignItems="center" py={1} px={3} sx={{ "> .MuiGrid-item": { p: 1 }, position: "fixed", maxWidth: "1300px", width: "calc(100% - 300px)", background: "white", zIndex: 999 }}>
				<Grid container item direction="row" alignItems="center" sx={{ "> .MuiGrid-item": { p: 0 }, background: "#F4F4F4", borderRadius: "10px 10px 10px 10px" }}>
					<Grid item xs={12} md={5}>
						<Typography variant="h5" className={classes.screenTitle}>{"Expert Contact Form"}</Typography>
					</Grid>
					<Grid item xs={12} md={5} />
					<Grid item container md={2} justifyContent="end">
						<Button
							variant="outlined"
							size="medium"
							color="primary"
							sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c", color: "common.white" }, px: 3 }}
							onClick={onSubmit}
						>
							{"Submit Form"}
						</Button>
					</Grid>
				</Grid>
			</Grid>
			<FormControl onSubmit={onSubmit}>
				<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "45px" }}>
					<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} mb={2} bgcolor="#1d4363">
						<Typography variant="h6" color="common.white" alignItems="center">
							{"INFORMATION"}
						</Typography>
					</Grid>
					<Grid item container sx={{ p: 1 }} mb={2} justifyContent="space-between">
						<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
							<Grid item md={2}>
								<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
									{googleForm.first_name?.label}
								</Typography>
							</Grid>
							<Grid item container md={3} direction="row" alignItems="center" justifyContent="end">
								<TextField
									hiddenLabel
									size="large"
									sx={{ width: "100%" }}
									value={formData.first_name}
									placeholder={googleForm.first_name?.description}
									variant="filled"
									error={formErrors.first_name !== ""}
									helperText={formErrors.first_name === "" ? "" : formErrors.first_name}
									onChange={(e) => onValueChange("first_name", e)}
								/>
							</Grid>
							<Grid item md={2} />
							<Grid item md={2}>
								<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
									{googleForm.last_name?.label}
								</Typography>
							</Grid>
							<Grid item container md={3} direction="row" alignItems="center" justifyContent="end">
								<TextField
									hiddenLabel
									size="large"
									sx={{ width: "100%" }}
									value={formData.last_name}
									placeholder={googleForm.last_name?.description}
									variant="filled"
									error={formErrors.last_name !== ""}
									helperText={formErrors.last_name === "" ? "" : formErrors.last_name}
									onChange={(e) => onValueChange("last_name", e)}
								/>
							</Grid>
						</Grid>
						<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
							<Grid item md={2}>
								<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
									{googleForm.email_address?.label}
								</Typography>
							</Grid>
							<Grid item container md={3} direction="row" alignItems="center" justifyContent="end">
								<TextField
									hiddenLabel
									size="large"
									sx={{ width: "100%" }}
									value={formData.email_address}
									placeholder={googleForm.email_address?.description}
									variant="filled"
									error={formErrors.email_address !== ""}
									helperText={formErrors.email_address === "" ? "" : formErrors.email_address}
									onChange={(e) => onValueChange("email_address", e)}
								/>
							</Grid>
							<Grid item md={2} />
							<Grid item md={2}>
								<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
									{googleForm.phone_number?.label}
								</Typography>
							</Grid>
							<Grid item container md={3} direction="row" alignItems="center" justifyContent="end">
								<TextField
									hiddenLabel
									size="large"
									sx={{ width: "100%" }}
									value={formData.phone_number}
									placeholder={googleForm.phone_number?.description}
									variant="filled"
									error={formErrors.phone_number !== ""}
									helperText={formErrors.phone_number === "" ? "" : formErrors.phone_number}
									onChange={(e) => onValueChange("phone_number", e)}
								/>
							</Grid>
						</Grid>
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={3} mb={2} bgcolor="#1d4363">
							<Typography variant="h6" color="common.white" alignItems="center">
								{"SUBJECT"}
							</Typography>
						</Grid>
						<Grid item container sx={{ p: 1 }} mt={1} alignItems="start">
							<Grid item md={3} mt={2}>
								<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
									{googleForm.subject_type?.label}
								</Typography>
								{(formErrors.subject_type !== "" && isSubmitted)
									&& (
										<FormHelperText id="my-helper-text" error={formErrors.subject_type !== ""}>
											<Typography variant="h8" sx={{ color: "red", width: "380px" }}>
												{"You need to select one of the following options."}
											</Typography>
										</FormHelperText>
									)}
							</Grid>
							<Grid item container md={9} justifyContent="center">
								<RadioGroup
									aria-labelledby="demo-radio-buttons-group-label"
									defaultValue="female"
									name="radio-buttons-group"
									value={formData.subject_type}
									onChange={(e) => onValueChange("subject_type", e)}
								>
									<Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: "-5px" }} direction="row">
										{googleForm.subject_type?.options.map((option) => {
											if (!option.custom) {
												return (
													<Grid key={option.label} item md={4}>
														<FormControlLabel
															value={option.label}
															control={<Radio />}
															label={option.label}
														/>
													</Grid>
												);
											}

											return (
												<Grid key={5} item md={4}>
													<FormControlLabel
														value="__other_option__"
														control={<Radio />}
														label="Other"
													/>
												</Grid>
											);
										})}
									</Grid>
								</RadioGroup>
							</Grid>
						</Grid>
						{formData.subject_type === "__other_option__"
							&& (
								<Grid item container sx={{ p: 1 }} mt={1} alignItems="start">
									<Grid item md={3} mt={2}>
										<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
											{"Other Category"}
										</Typography>
									</Grid>
									<Grid item container md={9} justifyContent="center">
										<TextField
											hiddenLabel
											size="large"
											sx={{ width: "100%" }}
											value={formData.subject_other}
											placeholder="Fill the specific subject."
											variant="filled"
											error={formErrors.subject_other !== ""}
											helperText={formErrors.subject_other === "" ? "" : formErrors.subject_other}
											onChange={(e) => onValueChange("subject_other", e)}
										/>
									</Grid>
								</Grid>
							)}
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={3} mb={2} bgcolor="#1d4363">
							<Typography variant="h6" color="common.white" alignItems="center">
								{"DETAILS"}
							</Typography>
						</Grid>
						<Grid item container sx={{ p: 1 }} mt={1} alignItems="start">
							<Grid item md={4}>
								<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
									{googleForm.description?.label}
								</Typography>
							</Grid>
							<Grid item md={1} />
							<Grid item container md={7} direction="row" alignItems="left" justifyContent="end">
								<TextField
									hiddenLabel
									multiline
									value={formData.description}
									size="large"
									placeholder="Description Details"
									minRows={4}
									maxRows={8}
									sx={{ width: "95%" }}
									variant="filled"
									error={formErrors.description !== ""}
									helperText={formErrors.description === "" ? "" : formErrors.description}
									onChange={(e) => onValueChange("description", e)}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</FormControl>
		</>
	);
};

export default memo(ExpertContactForm);
