/* eslint-disable no-unused-vars */
import { memo, useCallback, useEffect, useState, forwardRef } from "react";
import { Button, Grid, LinearProgress, Typography, Dialog, Slide, DialogTitle, DialogContent, DialogContentText, DialogActions, Tooltip } from "@mui/material";
import { Delete } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import { useFormik } from "formik";

import { useSnackbar, httpResponseIsValid } from "../../utils";
import { deleteFile, getCompanyDocs } from "../../api";
import {
	MainMenu,
	LabelFileUpload,
} from "../../components/basic/index";

const Transition = forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const UserRoles = {
	owner: "owner",
	member: "member",
};

// eslint-disable-next-line react/prop-types
const CompanyDocumentsOverview = ({ userCompanyId = null, companyRole = "member" }) => {
	const [companyId, setCompanyId] = useState(userCompanyId);
	const [projectId, setProjectId] = useState(null);
	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [enableEditing, setEnableEditing] = useState(false);
	const [userRole, setUserRole] = useState(companyRole);
	const [companyInfo, setCompanyInfo] = useState({
		status: "",
		createdAt: "-",
		projectSize: null,
		typeOfFinance: "project_finance",
		management_doc_1: {
			original_name: "",
			save_name: "",
		},
		management_doc_2: {
			original_name: "",
			save_name: "",
		},
		management_doc_3: {
			original_name: "",
			save_name: "",
		},
		management_doc_4: {
			original_name: "",
			save_name: "",
		},
		management_doc_5: {
			original_name: "",
			save_name: "",
		},
		esco_overview_doc_1: {
			original_name: "",
			save_name: "",
		},
		esco_overview_doc_2: {
			original_name: "",
			save_name: "",
		},
		esco_overview_doc_3: {
			original_name: "",
			save_name: "",
		},
		esco_overview_doc_4: {
			original_name: "",
			save_name: "",
		},
		esco_overview_doc_5: {
			original_name: "",
			save_name: "",
		},
		esco_overview_doc_6: {
			original_name: "",
			save_name: "",
		},
		esco_overview_doc_7: {
			original_name: "",
			save_name: "",
		},
		track_record_doc_1: {
			original_name: "",
			save_name: "",
		},
		track_record_doc_2: {
			original_name: "",
			save_name: "",
		},
		track_record_doc_3: {
			original_name: "",
			save_name: "",
		},
		track_record_doc_4: {
			original_name: "",
			save_name: "",
		},
		management_organization_doc_1: {
			original_name: "",
			save_name: "",
		},
		management_organization_doc_2: {
			original_name: "",
			save_name: "",
		},
		management_organization_doc_3: {
			original_name: "",
			save_name: "",
		},
		sales_strategy_doc_1: {
			original_name: "",
			save_name: "",
		},
		sales_strategy_doc_2: {
			original_name: "",
			save_name: "",
		},
		sales_strategy_doc_3: {
			original_name: "",
			save_name: "",
		},
		detailed_ee_information_doc_1: {
			original_name: "",
			save_name: "",
		},
		environmental_social_business_ethic_issues_doc_1: {
			original_name: "",
			save_name: "",
		},
		environmental_issues_doc_1: {
			original_name: "",
			save_name: "",
		},
		environmental_issues_doc_2: {
			original_name: "",
			save_name: "",
		},
		social_issues_doc_1: {
			original_name: "",
			save_name: "",
		},
		social_issues_doc_2: {
			original_name: "",
			save_name: "",
		},
		social_issues_doc_3: {
			original_name: "",
			save_name: "",
		},
		social_issues_doc_4: {
			original_name: "",
			save_name: "",
		},
		social_issues_doc_5: {
			original_name: "",
			save_name: "",
		},
		social_issues_doc_6: {
			original_name: "",
			save_name: "",
		},
		goverance_management_doc_1: {
			original_name: "",
			save_name: "",
		},
		goverance_management_doc_2: {
			original_name: "",
			save_name: "",
		},
		goverance_management_doc_3: {
			original_name: "",
			save_name: "",
		},
		goverance_management_doc_4: {
			original_name: "",
			save_name: "",
		},
		goverance_management_doc_5: {
			original_name: "",
			save_name: "",
		},
		goverance_management_doc_6: {
			original_name: "",
			save_name: "",
		},
		goverance_management_doc_7: {
			original_name: "",
			save_name: "",
		},
		goverance_management_doc_8: {
			original_name: "",
			save_name: "",
		},
		goverance_management_doc_9: {
			original_name: "",
			save_name: "",
		},
		goverance_management_doc_10: {
			original_name: "",
			save_name: "",
		},
		goverance_performance_doc_1: {
			original_name: "",
			save_name: "",
		},
		goverance_performance_doc_2: {
			original_name: "",
			save_name: "",
		},
		goverance_actions_doc_1: {
			original_name: "",
			save_name: "",
		},
		goverance_actions_doc_2: {
			original_name: "",
			save_name: "",
		},
		goverance_actions_doc_3: {
			original_name: "",
			save_name: "",
		},
		goverance_actions_doc_4: {
			original_name: "",
			save_name: "",
		},
		goverance_kpis_doc_1: {
			original_name: "",
			save_name: "",
		},
	});

	const { success, error } = useSnackbar();
	const [deleteModal, setDeleteModal] = useState({ component: null, variable: null });

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getCompanyDocs();
				if (httpResponseIsValid(response)) {
					setCompanyInfo(response);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				console.log(`Error occured when fetching company documents: ${_error}`);
			}

			setProjectId(null);
			setIsLoading(false);
		},
		[error],
	);

	const onDelete = async (comp) => {
		try {
			setIsLoading(true);

			// eslint-disable-next-line max-len
			const { success: deleteSuccess } = await deleteFile({ projectid: undefined, component: comp.component, variable: comp.variable });
			if (deleteSuccess) {
				success("File deleted successfully");
				await fetchData(false);
				setIsLoading(false);
			} else {
				error();
				setIsLoading(false);
			}
		} catch { /* empty */ }
	};

	const formik = useFormik({
		initialValues: companyInfo,
		// onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	});

	useEffect(() => {
		const disableAccess = userRole !== UserRoles.owner;
		setShouldUiBeDisabled(disableAccess || !enableEditing);
	}, [userRole, enableEditing]);

	useEffect(() => {
		setCompanyId(userCompanyId);
	}, [userCompanyId]);

	useEffect(() => {
		setUserRole(companyRole);
	}, [companyRole]);

	useEffect(() => {
		formik.resetForm({ values: companyInfo, dirty: false });
	}, [companyInfo]);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData, projectId]);

	return (
		<form>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<MainMenu
				title="Company KYC"
				// dirty={dirty}
				project={companyInfo}
				isDeveloper={companyRole === "owner"}
				disabled={companyRole !== "owner"}
				onFetch={fetchData}
				onEdit={setEnableEditing}
				// onChange={onChangeProjectState}
			/>
			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "100px" }}>
				<Grid item container direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Grid item md={1} />
					<Typography variant="h6" color="common.white" alignItems="center">
						{"Management Documents"}
					</Typography>
					<Grid item container justifyContent="end" md={1}>
						<Tooltip placement="top-start" title="These documents are used in the Risk Assessment Tool">
							<InfoIcon fontSize="medium" sx={{ color: "#ffffff" }} />
						</Tooltip>
					</Grid>
				</Grid>
				<Grid item container sx={{ p: 1 }} alignItems="center" justifyContent="space-between">
					<LabelFileUpload
						companydoc
						fieldId="management_doc_1"
						projectId={userCompanyId}
						label="Chamber of Commerce registration document"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="management_doc_2"
						projectId={userCompanyId}
						label="Documentation of shareholder structure"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="management_doc_3"
						projectId={userCompanyId}
						label="CVs of key management personnel"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="management_doc_4"
						projectId={userCompanyId}
						label="Ownership structure"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="management_doc_5"
						projectId={userCompanyId}
						label="Background and track record of key management personnel"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>

				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Grid item md={1} />
					<Typography variant="h6" color="common.white" alignItems="center">
						{"ESCO Overview Documents"}
					</Typography>
					<Grid item container justifyContent="end" md={1}>
						<Tooltip placement="top-start" title="These documents are used in the Loan Application Tool">
							<InfoIcon fontSize="medium" sx={{ color: "#ffffff" }} />
						</Tooltip>
					</Grid>
				</Grid>
				<Grid item container sx={{ p: 1 }} alignItems="center" bgcolor="grey.main" justifyContent="space-between">
					<LabelFileUpload
						companydoc
						fieldId="esco_overview_doc_1"
						projectId={userCompanyId}
						label="Financial Forecasts"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="esco_overview_doc_2"
						projectId={userCompanyId}
						label="End-Client pool-pipeline"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="esco_overview_doc_3"
						projectId={userCompanyId}
						label="Accounting policies (full of simplified)"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="esco_overview_doc_4"
						projectId={userCompanyId}
						label="Management Team"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="esco_overview_doc_5"
						projectId={userCompanyId}
						label="Audited financial statements"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="esco_overview_doc_6"
						projectId={userCompanyId}
						label="Details of short, medium and long-term liabilities"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="esco_overview_doc_7"
						projectId={userCompanyId}
						label="Dividend policy"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Grid item md={1} />
					<Typography variant="h6" color="common.white" alignItems="center">
						{"Track Record Documents"}
					</Typography>
					<Grid item container justifyContent="end" md={1}>
						<Tooltip placement="top-start" title="These documents are used in the Loan Application Tool">
							<InfoIcon fontSize="medium" sx={{ color: "#ffffff" }} />
						</Tooltip>
					</Grid>
				</Grid>
				<Grid item container sx={{ p: 1 }} mt={1} alignItems="center" justifyContent="space-between">
					<LabelFileUpload
						companydoc
						fieldId="track_record_doc_1"
						projectId={userCompanyId}
						label="Proof of track record"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="track_record_doc_2"
						projectId={userCompanyId}
						label="Track record of technical team, team credentials and capacity"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="track_record_doc_3"
						projectId={userCompanyId}
						label="Description of other team members that are part of the project"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						infoText="E.g. Financial department, Sales, Marketing, Sustainability, and other departments"
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="track_record_doc_4"
						projectId={userCompanyId}
						label="EE audit"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Grid item md={1} />
					<Typography variant="h6" color="common.white" alignItems="center">
						{"Management Organization Documents"}
					</Typography>
					<Grid item container justifyContent="end" md={1}>
						<Tooltip placement="top-start" title="These documents are used in the Loan Application Tool">
							<InfoIcon fontSize="medium" sx={{ color: "#ffffff" }} />
						</Tooltip>
					</Grid>
				</Grid>
				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center" justifyContent="space-between">
					<LabelFileUpload
						companydoc
						fieldId="management_organization_doc_1"
						projectId={userCompanyId}
						label="Organigram"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="management_organization_doc_2"
						projectId={userCompanyId}
						label="Present Management Team"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="management_organization_doc_3"
						projectId={userCompanyId}
						label="CVs"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						infoText="Please upload the CVs of your team in zip format."
						onSuccess={() => fetchData()}
					/>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Grid item md={1} />
					<Typography variant="h6" color="common.white" alignItems="center">
						{"Sales Strategy Documents"}
					</Typography>
					<Grid item container justifyContent="end" md={1}>
						<Tooltip placement="top-start" title="These documents are used in the Loan Application Tool">
							<InfoIcon fontSize="medium" sx={{ color: "#ffffff" }} />
						</Tooltip>
					</Grid>
				</Grid>
				<Grid item container sx={{ p: 1 }} mt={1} alignItems="center" justifyContent="space-between">
					<LabelFileUpload
						companydoc
						fieldId="sales_strategy_doc_1"
						projectId={userCompanyId}
						label="Sales performance, pipeline"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="sales_strategy_doc_2"
						projectId={userCompanyId}
						label="Sales current strategy (short and long term)"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="sales_strategy_doc_3"
						projectId={userCompanyId}
						label="CSR analysis"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Grid item md={1} />
					<Typography variant="h6" color="common.white" alignItems="center">
						{"Detailed/EE Information Documents"}
					</Typography>
					<Grid item container justifyContent="end" md={1}>
						<Tooltip placement="top-start" title="These documents are used in the Loan Application Tool">
							<InfoIcon fontSize="medium" sx={{ color: "#ffffff" }} />
						</Tooltip>
					</Grid>
				</Grid>
				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center" justifyContent="space-between">
					<LabelFileUpload
						companydoc
						fieldId="detailed_ee_information_doc_1"
						projectId={userCompanyId}
						label="Business plan"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Grid item md={1} />
					<Typography variant="h6" color="common.white" alignItems="center">
						{"Environmental, Social or Business Ethics Issues Documents"}
					</Typography>
					<Grid item container justifyContent="end" md={1}>
						<Tooltip placement="top-end" title="These documents are used in the ESG Tool">
							<InfoIcon fontSize="medium" sx={{ color: "#ffffff" }} />
						</Tooltip>
					</Grid>
				</Grid>
				<Grid item container sx={{ p: 1 }} mt={1} alignItems="center" justifyContent="space-between">
					<LabelFileUpload
						companydoc
						fieldId="environmental_social_business_ethic_issues_doc_1"
						projectId={userCompanyId}
						label="Certification regarding environmental, social or business ethics issues (e.g.: ISO 14001, ISO 45001, regional/sector specific certifications)"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Grid item md={1} />
					<Typography variant="h6" color="common.white" alignItems="center">
						{"Environmental Issues Documents"}
					</Typography>
					<Grid item container justifyContent="end" md={1}>
						<Tooltip placement="top-end" title="These documents are used in the ESG Tool">
							<InfoIcon fontSize="medium" sx={{ color: "#ffffff" }} />
						</Tooltip>
					</Grid>
				</Grid>
				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center" justifyContent="space-between">
					<LabelFileUpload
						companydoc
						fieldId="environmental_issues_doc_1"
						projectId={userCompanyId}
						label="Policy (or policies) on environmental issues"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="environmental_issues_doc_2"
						projectId={userCompanyId}
						label="Action Plan to reduce their CO2 emissions"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Grid item md={1} />
					<Typography variant="h6" color="common.white" alignItems="center">
						{"Social Issues Documents"}
					</Typography>
					<Grid item container justifyContent="end" md={1}>
						<Tooltip placement="top-end" title="These documents are used in the ESG Tool">
							<InfoIcon fontSize="medium" sx={{ color: "#ffffff" }} />
						</Tooltip>
					</Grid>
				</Grid>
				<Grid item container sx={{ p: 1 }} mt={1} alignItems="center" justifyContent="space-between">
					<LabelFileUpload
						companydoc
						fieldId="social_issues_doc_1"
						projectId={userCompanyId}
						label="Human Rights and Labour standarts Policy"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="social_issues_doc_2"
						projectId={userCompanyId}
						label="Discrimination inequality and social inclusiveness Policy"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="social_issues_doc_3"
						projectId={userCompanyId}
						label="Career Development policy"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="social_issues_doc_4"
						projectId={userCompanyId}
						label="Code of Conduct/Code of Ethics"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="social_issues_doc_5"
						projectId={userCompanyId}
						label="Customer safety and protection Policy or internal guidelines"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="social_issues_doc_6"
						projectId={userCompanyId}
						label="Company track record"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Grid item md={1} />
					<Typography variant="h6" color="common.white" alignItems="center">
						{"Goverance Management Documents"}
					</Typography>
					<Grid item container justifyContent="end" md={1}>
						<Tooltip placement="top-end" title="These documents are used in the ESG Tool">
							<InfoIcon fontSize="medium" sx={{ color: "#ffffff" }} />
						</Tooltip>
					</Grid>
				</Grid>
				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center" justifyContent="space-between">
					<LabelFileUpload
						companydoc
						fieldId="goverance_management_doc_1"
						projectId={userCompanyId}
						label="Anti-corruption and bribery Policy"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="goverance_management_doc_2"
						projectId={userCompanyId}
						label="Confidentiality and Privacy Work Instructions"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="goverance_management_doc_3"
						projectId={userCompanyId}
						label="Systematic process for identifying physical risks that could have a financial impack on the entity"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="goverance_management_doc_4"
						projectId={userCompanyId}
						label="Formal process for stakeholders to communicate grievances that apply to this entity"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="goverance_management_doc_5"
						projectId={userCompanyId}
						label="Business ethics and values policy"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="goverance_management_doc_6"
						projectId={userCompanyId}
						label="Accountability and transparency policy"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="goverance_management_doc_7"
						projectId={userCompanyId}
						label="Steering committee and escalation path"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="goverance_management_doc_8"
						projectId={userCompanyId}
						label="Escalation procedures and reporting at a governance level"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="goverance_management_doc_9"
						projectId={userCompanyId}
						label="Sustainable Procurement Policy (including supplier and sub-supplier chain)"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="goverance_management_doc_10"
						projectId={userCompanyId}
						label="Alignment of the remuneration policy with ESG risks"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Grid item md={1} />
					<Typography variant="h6" color="common.white" alignItems="center">
						{"Goverance Performance Documents"}
					</Typography>
					<Grid item container justifyContent="end" md={1}>
						<Tooltip placement="top-end" title="These documents are used in the ESG Tool">
							<InfoIcon fontSize="medium" sx={{ color: "#ffffff" }} />
						</Tooltip>
					</Grid>
				</Grid>
				<Grid item container sx={{ p: 1 }} mt={1} alignItems="center" justifyContent="space-between">
					<LabelFileUpload
						companydoc
						fieldId="goverance_performance_doc_1"
						projectId={userCompanyId}
						label="Diversity on company boards"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="goverance_performance_doc_2"
						projectId={userCompanyId}
						label="CSR-related external recognition obtained by your company within the last five years, if any"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Grid item md={1} />
					<Typography variant="h6" color="common.white" alignItems="center">
						{"Goverance Actions Documents"}
					</Typography>
					<Grid item container justifyContent="end" md={1}>
						<Tooltip placement="top-end" title="These documents are used in the ESG Tool">
							<InfoIcon fontSize="medium" sx={{ color: "#ffffff" }} />
						</Tooltip>
					</Grid>
				</Grid>
				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center" justifyContent="space-between">
					<LabelFileUpload
						companydoc
						fieldId="goverance_actions_doc_1"
						projectId={userCompanyId}
						label="Awareness training to prevent discrimination and/or harassment"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="goverance_actions_doc_2"
						projectId={userCompanyId}
						label="Awareness training performed to prevent corruption and bribery"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="goverance_actions_doc_3"
						projectId={userCompanyId}
						label="Whistleblower procedure to report ethics issues"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="goverance_actions_doc_4"
						projectId={userCompanyId}
						label="Awareness training to prevent information security breaches"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Grid item md={1} />
					<Typography variant="h6" color="common.white" alignItems="center">
						{"Goverance KPIs Documents"}
					</Typography>
					<Grid item container justifyContent="end" md={1}>
						<Tooltip placement="top-end" title="These documents are used in the ESG Tool">
							<InfoIcon fontSize="medium" sx={{ color: "#ffffff" }} />
						</Tooltip>
					</Grid>
				</Grid>
				<Grid item container sx={{ p: 1 }} mt={1} alignItems="center" justifyContent="space-between">
					<LabelFileUpload
						companydoc
						fieldId="goverance_actions_doc_4"
						projectId={userCompanyId}
						label="Board diversity in terms of age, gender, educational and professional background"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
				</Grid>
			</Grid>
			{deleteModal.component !== null
				&& deleteModal.variable !== null
				&& (
					<Dialog
						keepMounted
						open={deleteModal.component !== null && deleteModal.variable !== null}
						TransitionComponent={Transition}
						onClose={() => setDeleteModal({ component: null, variable: null })}
					>
						<DialogTitle>
							{"Are you sure?"}
						</DialogTitle>
						<DialogContent dividers>
							<DialogContentText>
								{"Are you sure you want to delete the file?"}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								autoFocus
								startIcon={<Delete />}
								variant="contained"
								onClick={() => {
									onDelete(deleteModal);
									setDeleteModal({ component: null, variable: null });
								}}
							>
								{"Delete"}
							</Button>
							<Button variant="outlined" onClick={() => setDeleteModal({ component: null, variable: null })}>{"Cancel"}</Button>
						</DialogActions>
					</Dialog>
				)}
		</form>
	);
};

export default memo(CompanyDocumentsOverview);
