import { Typography, Button } from "@mui/material";
import PropTypes from "prop-types";

import sefaTheme from "../../utils/theme";

const LoginButton = ({ title, onClick, type }) => (
	<Button
		sx={{
			backgroundColor: sefaTheme.palette.green.main,
			color: sefaTheme.palette.common.white,
			borderRadius: "8px",
			width: "320px",
			"&:hover": {
				backgroundColor: "#248F6A",
			},
		}}
		variant="contained"
		size="large"
		type={type ?? ""}
		onClick={onClick}
	>
		<Typography variant="h7">
			<b>{title}</b>
		</Typography>
	</Button>
);

LoginButton.propTypes = {
	title: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	type: PropTypes.string,
};

export default LoginButton;
