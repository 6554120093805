import { useState, memo, useCallback, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { AppBar, Toolbar, Menu, MenuItem, IconButton, Button, Paper, Breadcrumbs, Box } from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
	ExpandMore,
	AccountCircle,
	MoreVert as MoreIcon,
	Inbox,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import shallow from "zustand/shallow";
import { Image } from "mui-image";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import BuildIcon from "@mui/icons-material/Build";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import GroupIcon from "@mui/icons-material/Group";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import SchoolIcon from "@mui/icons-material/School";

import { capitalize, jwt, useSnackbar, httpResponseIsValid } from "../utils";
import logo from "../assets/images/logo_white.png";
import useGlobalState from "../use-global-state";
import { getUserProfile } from "../api";

const useStyles = makeStyles((theme) => ({
	grow: {
		flexGrow: 1,
		flexBasis: "auto",
		background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.dark.main})`,
		zIndex: 1200,
	},
	root: {
		padding: theme.spacing(0.5),
		borderRadius: "0px",
		background: theme.palette.grey.main,
	},
	icon: {
		marginRight: 0.5,
		width: 20,
		height: 20,
	},
	expanded: {
		background: "transparent",
	},
	innerSmallAvatar: {
		color: theme.palette.common.black,
		fontSize: "inherit",
	},
	anchorOriginBottomRightCircular: {
		".MuiBadge-anchorOriginBottomRightCircular": {
			right: 0,
			bottom: 0,
		},
	},
	avatar: {
		width: "30px",
		height: "30px",
		background: "white",
	},
	iconButton: {
		padding: "3px 6px",
	},
	menuItemButton: {
		width: "100%",
		bgcolor: "grey.light",
		"&:hover": {
			bgcolor: "grey.dark",
		},
	},
	grey: {
		color: "grey.500",
	},
}));

const Header = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const classes = useStyles();
	const { name } = useGlobalState((e) => ({
		name: e.name,
	}), shallow);
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorElTasks, setAnchorElTasks] = useState(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
	const [userRole, setUserRole] = useState("owner");

	const { error } = useSnackbar();

	const fetchData = useCallback(
		async () => {
			try {
				const response = await getUserProfile();
				if (httpResponseIsValid(response)) {
					setUserRole(response?.role);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				console.log(`Error occured while fetchinf user's role in header: ${_error}`);
			}
		},
		[error],
	);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData]);

	const isMenuOpen = Boolean(anchorEl);
	const isMenuOpenTasks = Boolean(anchorElTasks);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const handleProfileMenuOpen = (event) => setAnchorEl(event.currentTarget);
	const handleProjectHubMenuOpen = (event) => setAnchorElTasks(event.currentTarget);
	const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);
	const handleMenuClose = () => { setAnchorEl(null); handleMobileMenuClose(); };
	const handleProjectHubMenuClose = () => { setAnchorElTasks(null); handleMobileMenuClose(); };
	const handleMobileMenuOpen = (event) => setMobileMoreAnchorEl(event.currentTarget);

	const CrumpLink = styled(Link)(({ theme }) => ({ display: "flex", color: theme.palette.primary.main }));

	const renderMenu = (
		<>
			<Menu
				keepMounted
				id="test"
				anchorEl={anchorEl}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				open={isMenuOpen}
				onClose={handleMenuClose}
			>
				<MenuItem onClick={() => { handleMenuClose(); navigate("/profile"); }}>
					<IconButton color="primary" justifyContent="flex-end"><PersonIcon /></IconButton>
					<p>{"Profile"}</p>
				</MenuItem>
				{userRole !== "admin"
					&& (
						<MenuItem onClick={() => { handleMenuClose(); navigate("/company"); }}>
							<IconButton color="primary" justifyContent="flex-end"><GroupsIcon /></IconButton>
							<p>{"Company"}</p>
						</MenuItem>
					)}
				<MenuItem onClick={() => {
					handleMenuClose();
					jwt.destroyToken();
					navigate("/");
				}}
				>
					<IconButton color="primary" justifyContent="flex-end"><LogoutIcon /></IconButton>
					<p>{"Sign Out"}</p>
				</MenuItem>
			</Menu>
			<Menu
				keepMounted
				anchorEl={anchorElTasks}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				open={isMenuOpenTasks}
				onClose={handleProjectHubMenuClose}
			>
				<MenuItem onClick={() => { handleProjectHubMenuClose(); navigate("/learning-hub/tools-and-resources"); }}>
					<IconButton color="primary" justifyContent="flex-end"><BuildIcon /></IconButton>
					<p>{"Tools and Resources"}</p>
				</MenuItem>
				<MenuItem onClick={() => { handleProjectHubMenuClose(); navigate("/learning-hub/courses-and-training"); }}>
					<IconButton color="primary" justifyContent="flex-end"><CastForEducationIcon /></IconButton>
					<p>{"Courses and Training"}</p>
				</MenuItem>
				<MenuItem onClick={() => { handleProjectHubMenuClose(); navigate("/learning-hub/q-n-a"); }}>
					<IconButton color="primary" justifyContent="flex-end"><QuestionAnswerIcon /></IconButton>
					<p>{"Q&A"}</p>
				</MenuItem>
				<MenuItem onClick={() => { handleProjectHubMenuClose(); navigate("/learning-hub/expert-contact-form"); }}>
					<IconButton color="primary" justifyContent="flex-end"><ContactSupportIcon /></IconButton>
					<p>{"Expert Contact Form"}</p>
				</MenuItem>
			</Menu>
		</>
	);

	const renderMobileMenu = (
		<Menu
			keepMounted
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			<MenuItem onClick={() => { handleMenuClose(); navigate("/projects"); }}>
				<IconButton color="primary"><Inbox /></IconButton>
				<p>{userRole === "admin" ? "All Projects" : "Projects"}</p>
			</MenuItem>
			{userRole === "admin"
				&& (
					<MenuItem onClick={() => { handleMenuClose(); navigate("/AdminUsers"); }}>
						<IconButton color="primary"><Inbox /></IconButton>
						<p>{"Approved Projects"}</p>
					</MenuItem>
				)}

			{userRole === "admin"
				&& (
					<MenuItem onClick={() => { handleMenuClose(); navigate("/AdminUsers"); }}>
						<IconButton color="primary"><Inbox /></IconButton>
						<p>{"Investor Requests"}</p>
					</MenuItem>
				)}

			{userRole === "admin"
				&& (
					<MenuItem onClick={() => { handleMenuClose(); navigate("/AdminUsers"); }}>
						<IconButton color="primary"><GroupIcon /></IconButton>
						<p>{"Users"}</p>
					</MenuItem>
				)}
			<MenuItem onClick={handleProjectHubMenuOpen}>
				<IconButton color="primary"><SchoolIcon /></IconButton>
				<p>{"Learning Hub"}</p>
			</MenuItem>
			<MenuItem onClick={() => { handleMenuClose(); navigate("/invitations"); }}>
				<IconButton color="primary"><MarkAsUnreadIcon /></IconButton>
				<p>{"Invitations"}</p>
			</MenuItem>
			<MenuItem onClick={() => { handleMenuClose(); navigate("/profile"); }}>
				<IconButton color="primary"><PersonIcon /></IconButton>
				<p>{"Profile"}</p>
			</MenuItem>
			{
				userRole !== "admin"
				&& (
					<MenuItem onClick={() => { handleMenuClose(); navigate("/company"); }}>
						<IconButton color="primary"><GroupsIcon /></IconButton>
						<p>{"Company"}</p>
					</MenuItem>
				)
			}
			<MenuItem onClick={() => {
				handleMenuClose();
				jwt.destroyToken();
				navigate("/");
			}}
			>
				<IconButton color="primary"><LogoutIcon /></IconButton>
				<p>{"Sign Out"}</p>
			</MenuItem>
		</Menu>
	);

	const pathnames = location.pathname.split("/").filter(Boolean);
	const crumps = [];
	crumps.push(
		<CrumpLink to="/">
			{"Home"}
		</CrumpLink>,
	);

	let prePath = "";

	for (const [ind, path] of pathnames.entries()) {
		if (ind === 1 && prePath === "project") {
			// eslint-disable-next-line no-continue
			continue;
		}

		prePath = path;

		let text = "...";
		if ([
			"all",
			"approved",
			"project",
			"projects",
			"invitations",
			"profile",
			"company",
			"overview",
			"users",
			"contract",
			"equad",
		].includes(path)) text = capitalize(path);
		else {
			switch (path) {
				case "adminUsers": {
					text = "Users";

					break;
				}

				case "company-pre-assessment": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Financial Assessment"}</CrumpLink>);
					text = "Pre-Assessment";

					break;
				}

				case "company-profit-loss": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Financial Assessment"}</CrumpLink>);
					text = "Income Statement";

					break;
				}

				case "company-financial-position": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Financial Assessment"}</CrumpLink>);
					text = "Balance Sheet";

					break;
				}

				case "company-cash-flow": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Financial Assessment"}</CrumpLink>);
					text = "Cash Flow";

					break;
				}

				case "company-kpis": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Financial Assessment"}</CrumpLink>);
					text = "KPIs";

					break;
				}

				case "company-output": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Financial Assessment"}</CrumpLink>);
					text = "Overview";

					break;
				}

				case "company-kyc": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"KYC"}</CrumpLink>);
					text = "Overview";

					break;
				}

				case "company-documents": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Documents"}</CrumpLink>);
					text = "Overview";

					break;
				}

				case "pipelines": {
					text = "Pipelines";

					break;
				}

				case "pipeline-pool": {
					text = "My Pipeline Pool";

					break;
				}

				case "new-project": {
					text = "New Project";

					break;
				}

				case "learning-hub": {
					text = "Learning Hub";

					break;
				}

				case "investor-requests": {
					text = "Investor Requests";

					break;
				}

				case "tools-and-resources": {
					text = "Tools and Resources";

					break;
				}

				case "courses-and-training": {
					text = "Courses and Training";

					break;
				}

				case "q-n-a": {
					text = "Q & A";

					break;
				}

				case "project-pool": {
					text = "My Project Pool";

					break;
				}

				case "expert-contact-form": {
					text = "Expert Contact Form";

					break;
				}

				case "pre-assessment": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Financial Assessment"}</CrumpLink>);
					text = "Pre-Assessment";

					break;
				}

				case "profit-loss": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Financial Assessment"}</CrumpLink>);
					text = "Income Statement";

					break;
				}

				case "financial-position": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Financial Assessment"}</CrumpLink>);
					text = "Balance Sheet";

					break;
				}

				case "cash-flow": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Financial Assessment"}</CrumpLink>);
					text = "Cash Flow";

					break;
				}

				case "kpis": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Financial Assessment"}</CrumpLink>);
					text = "KPIs";

					break;
				}

				case "output": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Financial Assessment"}</CrumpLink>);
					text = "Overview";

					break;
				}

				case "risk-assessment-protocol": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Risk Assessment"}</CrumpLink>);
					text = "Risk Assessment Overview";

					break;
				}

				case "regulatory-risk": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Risk Assessment"}</CrumpLink>);
					text = "Regulatory Risk";

					break;
				}

				case "energy-price-risk": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Risk Assessment"}</CrumpLink>);
					text = "Energy Price Risk";

					break;
				}

				case "currency-risk": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Risk Assessment"}</CrumpLink>);
					text = "Currency Risk";

					break;
				}

				case "technical-risk": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Risk Assessment"}</CrumpLink>);
					text = "Technical Risk";

					break;
				}

				case "performance-risk": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Risk Assessment"}</CrumpLink>);
					text = "Performance Risk";

					break;
				}

				case "o-m-risk": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Risk Assessment"}</CrumpLink>);
					text = "O&M Risk";

					break;
				}

				case "management-risk": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Risk Assessment"}</CrumpLink>);
					text = "Management Risk";

					break;
				}

				case "change-in-control-risk": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Risk Assessment"}</CrumpLink>);
					text = "Change In Control Risk";

					break;
				}

				case "construction-risk": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Risk Assessment"}</CrumpLink>);
					text = "Construction Risk";

					break;
				}

				case "occupacy-risk": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Risk Assessment"}</CrumpLink>);
					text = "Occupancy Risk";

					break;
				}

				case "pre-payment-risk": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Risk Assessment"}</CrumpLink>);
					text = "Pre-Payment Risk";

					break;
				}

				case "pipeline-risk": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Risk Assessment"}</CrumpLink>);
					text = "Pipeline Risk";

					break;
				}

				case "interface-risk": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Risk Assessment"}</CrumpLink>);
					text = "Interface Risk";

					break;
				}

				case "market-risk": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Risk Assessment"}</CrumpLink>);
					text = "Market Risk";

					break;
				}

				case "supply-chain-risk": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Risk Assessment"}</CrumpLink>);
					text = "Supply Chain Risk";

					break;
				}

				case "credit-risk-implementor-client": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Risk Assessment"}</CrumpLink>);
					text = "Credit Risk Implementor / End Client";

					break;
				}

				case "credit-risk-service-provider": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Risk Assessment"}</CrumpLink>);
					text = "Credit Risk Service Provider / Contractor";

					break;
				}

				case "past-years": {
					text = "Past Years";

					break;
				}

				case "estimate-current-year": {
					text = "Estimate Current Year";

					break;
				}

				case "forecast-year-1": {
					text = "Forecast Year 1";

					break;
				}

				case "forecast-year-2": {
					text = "Forecast Year 2";

					break;
				}

				case "loan-application-overview": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Loan Application"}</CrumpLink>);
					text = "Overview";

					break;
				}

				case "loan-application-company-overview": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Loan Application"}</CrumpLink>);
					text = "Company Overview";

					break;
				}

				case "loan-application-detailed-ee-information": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Loan Application"}</CrumpLink>);
					text = "Detailed/EE Information";

					break;
				}

				case "loan-application-technical-information": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Loan Application"}</CrumpLink>);
					text = "Technical Information";

					break;
				}

				case "loan-application-financial-information": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Loan Application"}</CrumpLink>);
					text = "Financial Information";

					break;
				}

				case "loan-application-project-finance": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"Loan Application"}</CrumpLink>);
					text = "Project Finance";

					break;
				}

				case "kyc": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"KYC"}</CrumpLink>);
					text = "KYC Overview";

					break;
				}

				case "esg-enviromental": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"ESG"}</CrumpLink>);
					text = "ESG Environmental";

					break;
				}

				case "esg-all-aspects": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"ESG"}</CrumpLink>);
					text = "ESG All Aspects";

					break;
				}

				case "esg-social": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"ESG"}</CrumpLink>);
					text = "ESG Social";

					break;
				}

				case "esg-governance": {
					crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{"ESG"}</CrumpLink>);
					text = "ESG Governance";

					break;
				}

				default:
				// Do nothing
			}
		}

		if (text === "..." && pathnames[ind - 1] === "projects") text = name || "...";

		crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{text}</CrumpLink>);
	}

	return (
		<>
			<AppBar id="header" position="fixed" className={classes.grow}>
				<Toolbar className="header-container">
					<Box component={Link} to="/">
						<Image src={logo} alt="Sefa" width="4rem" sx={{ p: 0.5, my: 1, minWidth: "130px" }} />
					</Box>
					<Box className={classes.grow} />
					<>
						<Box sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
							<Button color="inherit" onClick={() => navigate("/pipelines")}>
								{"Pipelines"}
							</Button>
							{(userRole === "investor_owner" || userRole === "investor_member")
								&& (
									<Button color="inherit" onClick={() => navigate("/pipelines/pipeline-pool")}>
										{"My Pipeline Pool"}
									</Button>
								)}
							{userRole !== "admin"
								&& (
									<Button color="inherit" onClick={() => navigate("/projects")}>
										{userRole === "investor" ? "Available Projects" : "Projects"}
									</Button>
								)}
							{(userRole === "investor_owner" || userRole === "investor_member")
								&& (
									<Button color="inherit" onClick={() => navigate("/projects/project-pool")}>
										{"My Project Pool"}
									</Button>
								)}
							{userRole === "admin"
								&& (
									<>
										<Button color="inherit" onClick={() => navigate("/projects/all")}>
											{"All Projects"}
										</Button>
										<Button color="inherit" onClick={() => navigate("/projects/approved")}>
											{"Approved Projects"}
										</Button>
										<Button color="inherit" onClick={() => navigate("/investor-requests")}>
											{"Investor Requests"}
										</Button>
										<Button color="inherit" onClick={() => navigate("/adminUsers")}>
											{"Users"}
										</Button>
									</>
								)}
							<Button color="inherit" onClick={handleProjectHubMenuOpen}>
								{"Learning Hub"}
								<ExpandMore />
							</Button>
							<Button color="inherit" onClick={() => navigate("/invitations")}>
								{"Invitations"}
							</Button>
							<IconButton color="inherit" onClick={handleProfileMenuOpen}><AccountCircle /></IconButton>
						</Box>
						<Box sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}>
							<IconButton color="inherit" onClick={handleMobileMenuOpen}><MoreIcon /></IconButton>
						</Box>
					</>
				</Toolbar>
				<Paper elevation={0} className={classes.root}>
					<Breadcrumbs className="header-container">{crumps.map((e, ind) => <div key={`crump_${ind}`}>{e}</div>)}</Breadcrumbs>
				</Paper>
			</AppBar>
			{renderMobileMenu}
			{renderMenu}
		</>
	);
};

export default memo(Header);
