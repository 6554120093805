
import { memo, useCallback, useEffect, useState } from "react";
import { Link, Route, Routes, useLocation, useParams } from "react-router-dom";
import { Grid, Tab, Tabs, Typography, MenuItem, LinearProgress, Box, FormControl, InputLabel, Select, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { getProjectSpreadsheet, updateProjectSpreadsheet } from "../../api";
import { useSnackbar, httpResponseIsValid } from "../../utils";
import PlotlyComponent from "../../components/PlotOutput";
import StatusDialog from "../../components/StatusDialog";

import TableComponent from "./TableComponent";

const currencies = {
	euro: "Euro (€)",
	dollar: "Dollar ($)",
	pound: "GBP (£)",
	chf: "CHF",
	zloty: "Zloty (zl)",
};

const useStyles = makeStyles((theme) => ({
	menuBox: {
		width: "100%",
	},
	boxBorder: {
		border: `2px solid ${theme.palette.primary.main}`,
		borderRadius: theme.spacing(2),
		backgroundColor: "white",
	},
	screenTitle: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		letterSpacing: theme.spacing(0.2),
	},
}));

const pathToTool = {
	output: "Your Financial Assessment",
	profit_loss: "Income Statement",
	financial_position: "Balance Sheet",
	cash_flow: "Cash Flow",
	kpis: "KPIs",
};

const SpreadsheetComponent = ({ componentName, disableAccess = true, isProjectDeveloper = false, onChangeProjectState }) => {
	const [component, setComponent] = useState(componentName);
	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(disableAccess);
	const [projectStateDialog, setProjectStateDialog] = useState(false);
	const { pathname } = useLocation();
	const { projectid } = useParams();
	const [hasFetched, setHasFetched] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [toolTitle, setToolTitle] = useState("");
	const [projectInfo, setProjectInfo] = useState({
		status: "",
		createdAt: "-",
		pre_assessment: {
			financial_assessment_currency: Object.keys(currencies)[0],
		},
	});
	const { error, success } = useSnackbar();

	const [value, setValue] = useState(() => {
		for (const path of ["past-years", "estimate-current-year", "forecast-year-1", "forecast-year-2"]) {
			if (pathname.includes(path)) return path;
		}

		return "past-years";
	});

	const classes = useStyles();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getProjectSpreadsheet(projectid);
				if (httpResponseIsValid(response)) {
					setProjectInfo(response);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured while fetching financial assessment spreadsheet data: ${_error}`);
			}

			setHasFetched(true);
			setIsLoading(false);
		},
		[error, projectid],
	);

	const submit = async () => {
		setIsLoading(true);

		try {
			const response = await updateProjectSpreadsheet(projectid, projectInfo);
			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			error(`Error occured while submiting financial assessment spreadsheet data: ${_error}`);
		}

		setIsLoading(false);
	};

	const changeProjectState = useCallback(
		async () => {
			await onChangeProjectState();
			fetchData();
		}, [fetchData, onChangeProjectState],
	);

	useEffect(() => {
		setShouldUiBeDisabled(disableAccess);
	}, [disableAccess]);

	useEffect(() => {
		setComponent(componentName);
	}, [componentName]);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData, projectid]);

	const onValueChange = (e, name) => {
		setProjectInfo({
			...projectInfo,
			pre_assessment: {
				...projectInfo.pre_assessment,
				[name]: e.target.value,
			},
		});
	};

	useEffect(() => {
		setValue(() => {
			for (const path of ["past-years", "estimate-current-year", "forecast-year-1", "forecast-year-2"]) {
				if (pathname.includes(path)) return path;
			}

			return "past-years";
		});
	}, [pathname]);

	useEffect(() => {
		if (hasFetched && !shouldUiBeDisabled) {
			submit();
		}
	}, [projectInfo.pre_assessment.financial_assessment_currency]);

	useEffect(() => {
		let toolName = window.location.pathname.split("/")[3];
		toolName = toolName.replaceAll("-", "_");

		setToolTitle(pathToTool[toolName]);
	}, [window.location.pathname]);

	return (
		<>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<Grid container direction="column" alignItems="center" py={1} px={3} sx={{ "> .MuiGrid-item": { p: 1 }, position: "fixed", maxWidth: "1300px", width: "calc(100% - 300px)", background: "white", zIndex: 999 }}>
				<Grid container item direction="row" alignItems="center" sx={{ "> .MuiGrid-item": { p: 0 }, background: "#F4F4F4", borderRadius: "10px 10px 0px 0px" }}>
					<Grid item xs={12} md={5}>
						<Typography variant="h5" className={classes.screenTitle}>{toolTitle}</Typography>
					</Grid>
					<Grid item xs={0} md={2} />
					<Grid item container className={classes.boxBorder} sx={{ p: 1 }} xs={12} md={5}>
						<Grid item xs={12} md={6}>
							<Box p={1}>
								<Grid container direction="row" justifyContent="start" alignItems="center">
									<Grid item xs={4}>
										<Typography variant="h6">{"Status: "}</Typography>
									</Grid>
									<Grid item container xs={8} justifyContent="start">
										<Typography variant="h6" color={(projectInfo.status === "Approved") ? "green.main" : ((projectInfo.status === "Rejected") ? "error.main" : "primary.main")}>{projectInfo.status}</Typography>
									</Grid>
								</Grid>
							</Box>
						</Grid>
						<Grid item container xs={12} md={6} alignItems="center" justifyContent="end">
							<Button
								variant="contained"
								size="medium"
								sx={{ width: "170px", backgroundColor: "#5C9AE1", color: "common.white", ":hover": { backgroundColor: "primary.main" }, px: 3, marginRight: "4px", borderRadius: "10px" }}
								disabled={!isProjectDeveloper}
								onClick={() => setProjectStateDialog(true)}
							>
								{projectInfo.status === "In Progress" ? "Submit Project" : "Revert Project"}
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid container item direction="row" alignItems="center" sx={{ ">.MuiGrid-item": { p: 0 }, background: "#F4F4F4", borderRadius: "0px 0px 10px 10px" }} p={0}>
					<Grid item xs={0} md={7} />
					<Grid item container sx={{ p: 1 }} xs={12} md={5} alignItems="center" justifyContent="end">
						<FormControl variant="outlined">
							<InputLabel id="demo-simple-select-filled-label">{"Currency"}</InputLabel>
							<Select
								id="demo-simple-select-outlined"
								value={projectInfo.pre_assessment.financial_assessment_currency}
								label="Currency"
								sx={{ width: "180px", height: "45px", borderRadius: "15px 15px 15px 15px", backgroundColor: "white" }}
								disabled={shouldUiBeDisabled}
								onChange={(e) => onValueChange(e, "financial_assessment_currency")}
							>
								{Object.keys(currencies).map((cur) => (
									<MenuItem key={cur} value={cur}>
										{currencies[cur]}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			</Grid>
			<Grid container p={4} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "135px" }}>
				<Tabs
					value={value}
					variant="fullWidth"
					classes={{
						root: classes.menuBox,
					}}
					onChange={(_, newValue) => setValue(newValue)}
				>
					<Tab
						label={<Typography>{"Past Years"}</Typography>}
						value="past-years"
						to="past-years"
						component={Link}
					/>
					<Tab
						label={<Typography>{"Estimate Current Year"}</Typography>}
						value="estimate-current-year"
						to="estimate-current-year"
						component={Link}
					/>
					<Tab
						label={<Typography>{"Forecast Year 1"}</Typography>}
						value="forecast-year-1"
						to="forecast-year-1"
						component={Link}
					/>
					<Tab
						label={<Typography>{"Forecast Year 2"}</Typography>}
						value="forecast-year-2"
						to="forecast-year-2"
						component={Link}
					/>
				</Tabs>
			</Grid>
			<Routes>
				<Route index element={<TableComponent key={projectInfo.pre_assessment.financial_assessment_currency} isPercentage={component === "kpis"} screen={component} variables={["year_3", "year_2", "year_1"]} currency={projectInfo.pre_assessment.financial_assessment_currency} blocked={shouldUiBeDisabled} />} />
				<Route path="/past-years" element={<TableComponent key={projectInfo.pre_assessment.financial_assessment_currency} isPercentage={component === "kpis"} screen={component} variables={["year_3", "year_2", "year_1"]} currency={projectInfo.pre_assessment.financial_assessment_currency} blocked={shouldUiBeDisabled} />} />
				<Route path="/estimate-current-year" element={<TableComponent key={projectInfo.pre_assessment.financial_assessment_currency} isPercentage={component === "kpis"} screen={component} variables={["current_quarter_1", "current_quarter_2", "current_quarter_3", "current_quarter_4"]} currency={projectInfo.pre_assessment.financial_assessment_currency} blocked={shouldUiBeDisabled} />} />
				<Route path="/forecast-year-1" element={<TableComponent key={projectInfo.pre_assessment.financial_assessment_currency} isPercentage={component === "kpis"} screen={component} variables={["forecast_1_quarter_1", "forecast_1_quarter_2", "forecast_1_quarter_3", "forecast_1_quarter_4"]} currency={projectInfo.pre_assessment.financial_assessment_currency} blocked={shouldUiBeDisabled} />} />
				<Route path="/forecast-year-2" element={<TableComponent key={projectInfo.pre_assessment.financial_assessment_currency} isPercentage={component === "kpis"} screen={component} variables={["forecast_2_quarter_1", "forecast_2_quarter_2", "forecast_2_quarter_3", "forecast_2_quarter_4"]} currency={projectInfo.pre_assessment.financial_assessment_currency} blocked={shouldUiBeDisabled} />} />
			</Routes>
			{component === "output" && <PlotlyComponent />}
			<StatusDialog
				open={projectStateDialog}
				status={projectInfo.status}
				name={projectInfo.name}
				onClose={setProjectStateDialog}
				onChange={changeProjectState}
			/>
		</>
	);
};

export default memo(SpreadsheetComponent);
