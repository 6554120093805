import { memo, useEffect, useState } from "react";
import { Typography, FormControl, Paper, InputBase, FormHelperText } from "@mui/material";
import PropTypes from "prop-types";

import sefaTheme from "../../utils/theme";

export const LoginInputStyles = {
	iconStyle: {
		backgroundColor: "white",
		color: sefaTheme.palette.green.main,
		width: 30,
		height: 30,
		marginLeft: "8px",
	},
};

const LoginInput = ({ id, icon: inputIcon, value: inputValue, name: inputName = "Login Input", disabled: inputDisabled = false, error: inputError, onChange }) => {
	const [icon, setIcon] = useState(inputIcon);
	const [value, setValue] = useState(inputValue);
	const [name, setName] = useState(inputName);
	const [disabled, setDisabled] = useState(inputDisabled);
	const [error, setError] = useState(inputError);

	useEffect(() => {
		setIcon(inputIcon);
	}, [inputIcon]);

	useEffect(() => {
		setValue(inputValue);
	}, [inputValue]);

	useEffect(() => {
		setName(inputName);
	}, [inputName]);

	useEffect(() => {
		setDisabled(inputDisabled);
	}, [inputDisabled]);

	useEffect(() => {
		setError(inputError);
	}, [inputError]);

	return (
		<Paper sx={{ display: "flex", alignItems: "center", width: 320, height: 45, borderRadius: "8px" }}>
			{icon}
			<FormControl sx={{ width: 320, height: 45 }}>
				<InputBase
					id={id}
					sx={{
						ml: 1,
						flex: 1,
						height: 45,
						padding: "10px",
						borderRadius: "0px 8px 8px 0px",
						backgroundColor: "#aac2d6",
						"&:hover": { backgroundColor: "#B9CBD8" },
						color: "white",
						input: { "&::placeholder": { opacity: 0.8 } },
					}}
					inputProps={{
						"aria-label": `${name}`,
						style: {
							WebkitBoxShadow: "0 0 0 1000px #aac2d6 inset",
						},
					}}
					placeholder={name}
					value={value}
					disabled={disabled}
					onChange={onChange}
				/>
				<FormHelperText id="my-helper-text" error={error !== ""}>
					<Typography variant="h8" sx={{ color: "#FA7D63", width: "380px" }}>
						{error === "" ? "" : error}
					</Typography>
				</FormHelperText>
			</FormControl>
		</Paper>
	);
};

LoginInput.propTypes = {
	id: PropTypes.string.isRequired,
	icon: PropTypes.any,
	value: PropTypes.any.isRequired,
	name: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	error: PropTypes.string,
	onChange: PropTypes.func.isRequired,
};

export default memo(LoginInput);
