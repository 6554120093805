import { memo, useCallback, useEffect, useState, forwardRef } from "react";
import {
	Button,
	Grid,
	LinearProgress,
	Typography,
	Dialog,
	Slide,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import shallow from "zustand/shallow";
import { useFormik } from "formik";

import usePrompt from "../../utils/use-blocker";
import { useSnackbar, httpResponseIsValid } from "../../utils";
import { deleteFile, getCompanyKyc, updateCompanyKyc } from "../../api";
import useGlobalState from "../../use-global-state";
import {
	MainMenu,
	LabelFileUpload,
	LabelTextField,
} from "../../components/basic/index";

const Transition = forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const UserRoles = {
	owner: "owner",
	member: "member",
};

// eslint-disable-next-line react/prop-types
const CompanyKycOverview = ({ userCompanyId = null, companyRole = "member" }) => {
	const [projectId, setProjectId] = useState(null);
	const [userRole, setUserRole] = useState(companyRole);
	const [companyId, setCompanyId] = useState(userCompanyId);
	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [enableEditing, setEnableEditing] = useState(false);
	const [companyInfo, setCompanyInfo] = useState({
		status: "",
		createdAt: "-",
		// projectSize: nusll,
		// typeOfFinance: "project_finance",
		general_info_doc_1: {
			original_name: "",
			save_name: "",
		},
		general_info_doc_2: {
			original_name: "",
			save_name: "",
		},
		general_info_doc_3: {
			original_name: "",
			save_name: "",
		},
		general_info_doc_4: {
			original_name: "",
			save_name: "",
		},
		general_info_doc_5: {
			original_name: "",
			save_name: "",
		},
		sales_performance_doc_1: {
			original_name: "",
			save_name: "",
		},
		legal_status_doc_1: {
			original_name: "",
			save_name: "",
		},
		legal_status_doc_2: {
			original_name: "",
			save_name: "",
		},
		legal_status_doc_3: {
			original_name: "",
			save_name: "",
		},
		legal_status_doc_4: {
			original_name: "",
			save_name: "",
		},
		legal_status_doc_5: {
			original_name: "",
			save_name: "",
		},
		legal_status_doc_6: {
			original_name: "",
			save_name: "",
		},
		legal_status_doc_7: {
			original_name: "",
			save_name: "",
		},
		legal_status_doc_8: {
			original_name: "",
			save_name: "",
		},
		legal_status_doc_9: {
			original_name: "",
			save_name: "",
		},
		legal_status_doc_10: {
			original_name: "",
			save_name: "",
		},
		legal_status_doc_11: {
			original_name: "",
			save_name: "",
		},
		procedures_and_policies_doc_1: {
			original_name: "",
			save_name: "",
		},
		procedures_and_policies_doc_2: {
			original_name: "",
			save_name: "",
		},
		procedures_and_policies_doc_3: {
			original_name: "",
			save_name: "",
		},
		procedures_and_policies_doc_4: {
			original_name: "",
			save_name: "",
		},
		procedures_and_policies_doc_5: {
			original_name: "",
			save_name: "",
		},
		procedures_and_policies_doc_6: {
			original_name: "",
			save_name: "",
		},
		procedures_and_policies_doc_7: {
			original_name: "",
			save_name: "",
		},
		procedures_and_policies_doc_8: {
			original_name: "",
			save_name: "",
		},
		technical_legal_or_insurance_doc_1: {
			original_name: "",
			save_name: "",
		},
		technical_legal_or_insurance_doc_2: {
			original_name: "",
			save_name: "",
		},
		kyc_overview: {
			sales_performance_txt_1: "",
			credit_request_txt_1: "",
			credit_request_txt_2: "",
		},
	});
	const { success, error } = useSnackbar();
	const [deleteModal, setDeleteModal] = useState({ component: null, variable: null });
	const { setProjectSize, setTypeOfFinance } = useGlobalState(
		useCallback(
			(e) => ({
				setProjectSize: e.setProjectSize,
				setTypeOfFinance: e.setTypeOfFinance,
			}),
			[],
		),
		shallow,
	);

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getCompanyKyc();
				if (httpResponseIsValid(response)) {
					setCompanyInfo(response);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured when fetching kyc data: ${_error}`);
			}

			setProjectId(null);
			setIsLoading(false);
		}, [error, setProjectSize, setTypeOfFinance]);

	const onDelete = async (comp) => {
		// console.log("LOG", comp)
		try {
			setIsLoading(true);
			setProjectId(undefined);

			// const companyid = comp.component === "documents" ? undefined : companyId;
			// eslint-disable-next-line max-len
			const { success: deleteSuccess } = await deleteFile({ projectid: projectId, component: comp.component, variable: comp.variable });
			if (deleteSuccess) {
				success("File deleted successfully");
				await fetchData();
				setIsLoading(false);
			} else {
				error();
				setIsLoading(false);
			}
		} catch {
			/* empty */
		}
	};

	const handleSubmit = async (values) => {
		setIsLoading(true);
		const { success: setSuccess } = await updateCompanyKyc(companyInfo, values);
		if (setSuccess) {
			setIsLoading(false);
			success("Information saved successfully");
			await fetchData();
		} else {
			error();
		}
	};

	const formik = useFormik({
		initialValues: companyInfo,
		onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	});


	useEffect(() => {
		const disableAccess = userRole !== UserRoles.owner;
		setShouldUiBeDisabled(disableAccess || !enableEditing);
	}, [userRole, enableEditing]);

	const { dirty } = formik;
	usePrompt("You have unsaved changes which will be lost. Are you sure you want to leave the page?", dirty);

	useEffect(() => {
		setCompanyId(userCompanyId);
	}, [userCompanyId]);

	useEffect(() => {
		setUserRole(companyRole);
	}, [companyRole]);

	useEffect(() => {
		formik.resetForm({ values: companyInfo, dirty: false });
	}, [companyInfo]);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch {
				/* empty */
			}
		})();
	}, [error, fetchData, projectId]);

	return (
		<form onSubmit={formik.handleSubmit}>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<MainMenu
				title="Company KYC"
				dirty={dirty}
				project={companyInfo}
				isDeveloper={companyRole === "owner"}
				disabled={companyRole !== "owner"}
				onFetch={fetchData}
				onEdit={setEnableEditing}
				// onChange={onChangeProjectState}
			/>
			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "100px" }}>
				<Grid
					item
					container
					direction="row"
					alignItems="center"
					justifyContent="center"
					sx={{ p: 1 }}
					mt={1}
					bgcolor="#1d4363"
				>
					<Typography variant="h6" color="common.white" alignItems="center">
						{"GENERAL INFO"}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
					<LabelFileUpload
						companydoc
						fieldId="general_info_doc_1"
						projectId={userCompanyId}
						label="Company Description"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="general_info_doc_2"
						projectId={userCompanyId}
						label="Concise Business Plan"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						infoText="Provide a business plan that provides a clear overview of key cost categories, the expected revenues and the economic performance. It should also include a reference to the most important risks, mitigation measures and expected effects on the economic performance."
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="general_info_doc_3"
						projectId={userCompanyId}
						label="Company Track Record"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						infoText="Add proof of track record by providing case studies of 5 projects and/or the status of other current projects (under development / operational)."
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="general_info_doc_4"
						projectId={userCompanyId}
						label="Experience of technical team, team credentials and capacity"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						infoText="Description of other team members that are part of the project such as Financial , Sales, Marketing, Sustainability, and relevant departments."
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="general_info_doc_5"
						projectId={userCompanyId}
						label="Sales details and strategy"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						onSuccess={() => fetchData()}
					/>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="center" bgcolor="grey.main">
					<Typography variant="h6" alignItems="center">
						{"Sales Performance"}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
					<LabelFileUpload
						companydoc
						fieldId="sales_performance_doc_1"
						projectId={userCompanyId}
						label="Sales current strategy (short and long term)"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						onSuccess={() => fetchData()}
					/>
					<LabelTextField
						id="kyc_overview.sales_performance_txt_1"
						label="Pipeline overview (offers and uptake contracts if any)"
						size="large"
						aligntitlebox="center"
						typosize="h8"
						placeholder="Answer"
						infoText="E.g. some lenders have a minimal requirement of i.e. min. 500k under 1 VAT number"
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>
				</Grid>

				<Grid
					item
					container
					direction="row"
					alignItems="center"
					justifyContent="center"
					sx={{ p: 1 }}
					mt={1}
					bgcolor="#1d4363"
				>
					<Typography variant="h6" color="common.white" alignItems="center">
						{"LEGAL STATUS"}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
					<LabelFileUpload
						companydoc
						fieldId="legal_status_doc_1"
						projectId={userCompanyId}
						label="Certificate of Incorporation and company statutes"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="legal_status_doc_2"
						projectId={userCompanyId}
						label="Description of activities"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="legal_status_doc_3"
						projectId={userCompanyId}
						label="Accounting policies"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="legal_status_doc_4"
						projectId={userCompanyId}
						label="Management team"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="legal_status_doc_5"
						projectId={userCompanyId}
						label="Ownership"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						infoText="Ownership up to the final beneficiary owner and link to the national register of beneficiary owners above 25% (10% if Political Exposed Person presence."
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="legal_status_doc_6"
						projectId={userCompanyId}
						label="Audited financial statements or simplified accounts"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						infoText="Balance sheets, profit and loss and cashflow statements for the last three financial years."
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="legal_status_doc_7"
						projectId={userCompanyId}
						label="Details of short, medium and long-term liabilities"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="legal_status_doc_8"
						projectId={userCompanyId}
						label="Dividend distribution policy"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="legal_status_doc_9"
						projectId={userCompanyId}
						label="Financial forecasts"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="legal_status_doc_10"
						projectId={userCompanyId}
						label="Legal and tax good standing document"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						infoText="Legal and tax good standing document of the company and its representative or/and shareholders. In case of factoring, information related to the customers of the company is needed."
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="legal_status_doc_11"
						projectId={userCompanyId}
						label="Identification of UBO's & senior management"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						infoText="Identification documentation of UBO's & senior management, including proof of address: i.e. telephone or energy bill."
						onSuccess={() => fetchData()}
					/>
				</Grid>
				<Grid
					item
					container
					direction="row"
					alignItems="center"
					justifyContent="center"
					sx={{ p: 1 }}
					mt={1}
					bgcolor="#1d4363"
				>
					<Typography variant="h6" color="common.white" alignItems="center">
						{"PROCEDURES AND POLICIES"}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
					<LabelFileUpload
						companydoc
						fieldId="procedures_and_policies_doc_1"
						projectId={userCompanyId}
						label="Procedure for tendering and awarding contracts"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="procedures_and_policies_doc_2"
						projectId={userCompanyId}
						label="Procurement Policy and/or Supply chain policy"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="procedures_and_policies_doc_3"
						projectId={userCompanyId}
						label="ESG/Sustainability Policy"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="procedures_and_policies_doc_4"
						projectId={userCompanyId}
						label="Labour and Human Rights Policy"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="procedures_and_policies_doc_5"
						projectId={userCompanyId}
						label="Health and Safety Policy"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						companydoc
						fieldId="procedures_and_policies_doc_6"
						projectId={userCompanyId}
						label="Other relevant policies"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						onSuccess={() => fetchData()}
					/>
				</Grid>

			</Grid>
			<Grid
				item
				container
				direction="row"
				alignItems="center"
				justifyContent="center"
				sx={{ p: 1 }}
				mt={1}
				bgcolor="#1d4363"
			>
				<Typography variant="h6" color="common.white" alignItems="center">
					{"TECHNICAL LEGAL OR INSURANCE"}
				</Typography>
			</Grid>
			<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
				<LabelFileUpload
					companydoc
					fieldId="technical_legal_or_insurance_doc_1"
					projectId={userCompanyId}
					label="Technical, Legal or insurance advice"
					size="large"
					typosize="h8"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="kyc"
					infoText="Please attach any Technical, Legal or Insurance Advice or study that was provided and might be relevant."
					onSuccess={() => fetchData()}
				/>
				<LabelFileUpload
					companydoc
					fieldId="technical_legal_or_insurance_doc_2"
					projectId={userCompanyId}
					label="Legal Status and UBO of the asset provider"
					size="large"
					typosize="h8"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="kyc"
					onSuccess={() => fetchData()}
				/>
			</Grid>
			{deleteModal.component !== null && deleteModal.variable !== null && (
				<Dialog
					keepMounted
					open={deleteModal.component !== null && deleteModal.variable !== null}
					TransitionComponent={Transition}
					onClose={() => setDeleteModal({ component: null, variable: null })}
				>
					<DialogTitle>{"Are you sure?"}</DialogTitle>
					<DialogContent dividers>
						<DialogContentText>{"Are you sure you want to delete the file?"}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							autoFocus
							startIcon={<Delete />}
							variant="contained"
							onClick={() => {
								onDelete(deleteModal);
								setDeleteModal({ component: null, variable: null });
							}}
						>
							{"Delete"}
						</Button>
						<Button variant="outlined" onClick={() => setDeleteModal({ component: null, variable: null })}>
							{"Cancel"}
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</form>
	);
};

export default memo(CompanyKycOverview);
