/* eslint-disable unicorn/consistent-destructuring */
/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	Grid,
	LinearProgress,
	Typography,
} from "@mui/material";
import { useFormik } from "formik";
import shallow from "zustand/shallow";
import { useTranslation } from "react-i18next";

import { httpResponseIsValid, useSnackbar } from "../../utils";
import useGlobalState from "../../use-global-state";
import usePrompt from "../../utils/use-blocker";
import {
	MainMenu,
	LabelRadio,
	LabelTextField,
	LabelDropdown,
	LabelFileUpload,
} from "../../components/basic/index";
import {
	getProjectLoanApplicationOverview,
	updateProjectLoanApplicationOverview,
} from "../../api";

const LoanApplicationOverview = ({ disableAccess = true, isProjectDeveloper = false, onChangeProjectState }) => {
	const { projectid } = useParams();

	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(disableAccess);
	const [isLoading, setIsLoading] = useState(false);

	const [user, setUser] = useState({
		company_id: "",
	});
	const [options, setOptions] = useState({
		taxonomies: { category: [] },
		loanOverviewTypeOfFinance: {},
		loanOverviewApplicationStatus: {},
	});
	const [project, setProject] = useState({
		name: "",
		status: "-",
		createdAt: "-",
		data: {
			funding_amount: 0,
			type_of_finance: "",
			application_status: "",
			esco_name: "",
			main_business_activity: "",
			category: "",
			subcategory: "",
			specific_technology: "",
			new_market_player: "false",
			num_of_years_operation: 0,
			status_of_projects: "",
			pipeline_overview: "",
		},
		docs: {
			value_curve: {
				original_name: "",
				save_name: "",
			},
			asset_gap: {
				original_name: "",
				save_name: "",
			},
			main_parts: {
				original_name: "",
				save_name: "",
			},
			spare_parts: {
				original_name: "",
				save_name: "",
			},
			technical_risk_assessment: {
				original_name: "",
				save_name: "",
			},
			esco_overview_doc_1: {
				original_name: "",
				save_name: "",
			},
			esco_overview_doc_2: {
				original_name: "",
				save_name: "",
			},
			esco_overview_doc_3: {
				original_name: "",
				save_name: "",
			},
			esco_overview_doc_4: {
				original_name: "",
				save_name: "",
			},
			esco_overview_doc_5: {
				original_name: "",
				save_name: "",
			},
			esco_overview_doc_6: {
				original_name: "",
				save_name: "",
			},
			esco_overview_doc_7: {
				original_name: "",
				save_name: "",
			},
			track_record_doc_1: {
				original_name: "",
				save_name: "",
			},
			track_record_doc_2: {
				original_name: "",
				save_name: "",
			},
			track_record_doc_3: {
				original_name: "",
				save_name: "",
			},
			track_record_doc_4: {
				original_name: "",
				save_name: "",
			},
			management_organization_doc_1: {
				original_name: "",
				save_name: "",
			},
			management_organization_doc_2: {
				original_name: "",
				save_name: "",
			},
			management_organization_doc_3: {
				original_name: "",
				save_name: "",
			},
			sales_strategy_doc_1: {
				original_name: "",
				save_name: "",
			},
			sales_strategy_doc_2: {
				original_name: "",
				save_name: "",
			},
			sales_strategy_doc_3: {
				original_name: "",
				save_name: "",
			},
		},
	});

	const { setProjectSize, setTypeOfFinance } = useGlobalState(
		useCallback(
			(e) => ({
				setProjectSize: e.setProjectSize,
				setTypeOfFinance: e.setTypeOfFinance,
			}),
			[],
		),
		shallow,
	);

	const [categoryOptions, setCategoryOptions] = useState({});
	const [subcategoryOptions, setSubcategoryOptions] = useState({});
	const [specificTechnologyOptions, setSpecificTechnologyOptions] = useState({});

	const [enableEditing, setEnableEditing] = useState(false);
	const { success, error } = useSnackbar();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getProjectLoanApplicationOverview(projectid);
				if (httpResponseIsValid(response)) {
					console.log("response:", response.project);
					setOptions(response.options);
					setProject(response.project);
					setUser(response.user);

					setProjectSize(response.project.projectSize);
					setTypeOfFinance(response.project.typeOfFinance || "project_finance");
				} else {
					error(response?.message);
				}
			} catch (_error) {
				console.log(`Error occured when fetching load's application overview data: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, projectid, setProjectSize, setTypeOfFinance],
	);

	const handleSubmit = async (values) => {
		setIsLoading(true);

		try {
			const response = await updateProjectLoanApplicationOverview(projectid, values);
			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			console.log(`Error occured when updating loan's application overview data: ${_error}`);
		}

		setIsLoading(false);
	};

	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: project,
		onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	});

	const { dirty } = formik;

	usePrompt("You have unsaved changes which will be lost. Are you sure you want to leave the page?", dirty);

	useEffect(() => {
		setShouldUiBeDisabled(disableAccess || !enableEditing);
	}, [disableAccess, enableEditing]);

	useEffect(() => {
		const search = options.taxonomies.category.find((cat) => cat.name === (formik.values.data.category));

		if (search !== undefined) {
			setSubcategoryOptions(search.subcategory.reduce((acc, value) => {
				acc[value.name] = value.name;
				return acc;
			}, {}));

			setSpecificTechnologyOptions({
				[options.taxonomies.category[0].name]: options.taxonomies.category[0].name,
			});

			formik.setFieldValue("data.subcategory", "");
			formik.setFieldValue("data.specific_technology", "");
		}
	}, [formik.values.data.category]);

	useEffect(() => {
		const search = options.taxonomies.category.find((c) => c.name === (formik.values.data.category));

		if (search !== undefined) {
			const technology = search.subcategory.find((tech) => tech.name === (formik.values.data.subcategory));

			if (technology !== undefined) {
				setSpecificTechnologyOptions(technology.specificTechnology.reduce((acc, value) => {
					acc[value] = value;
					return acc;
				}, {}));

				formik.setFieldValue("data.specific_technology", "");
			}
		}
	}, [formik.values.data.subcategory]);

	useEffect(() => {
		formik.resetForm({ values: project, dirty: false });

		const { taxonomies: { category } } = options;

		setCategoryOptions(category.reduce((acc, value) => {
			acc[value.name] = value.name;
			return acc;
		}, {}));
	}, [project]);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData, projectid]);

	return (
		<form onSubmit={formik.handleSubmit}>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<MainMenu
				title={t("LoanApplication.Overview.title")}
				dirty={dirty}
				project={project}
				isDeveloper={isProjectDeveloper}
				disabled={disableAccess}
				onFetch={fetchData}
				onEdit={setEnableEditing}
				onChange={onChangeProjectState}
			/>

			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "105px" }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("LoanApplication.Overview.LoanOverview.title")}
					</Typography>
				</Grid>

				<LabelTextField
					id="data.funding_amount"
					label={t("LoanApplication.Overview.LoanOverview.fundingAmount")}
					size="large"
					type="number"
					placeholder="Answer"
					disabled={shouldUiBeDisabled}
					infoText={t("LoanApplication.Overview.LoanOverview.info1")}
					formik={formik}
				/>

				<LabelDropdown
					id="data.type_of_finance"
					size="large"
					label={t("LoanApplication.Overview.LoanOverview.typeOfFinance")}
					options={options.loanOverviewTypeOfFinance}
					disabled={shouldUiBeDisabled}
					infoText={t("LoanApplication.Overview.LoanOverview.info2")}
					formik={formik}
				/>

				<LabelDropdown
					id="data.application_status"
					size="large"
					label={t("LoanApplication.Overview.LoanOverview.applicationStatus")}
					options={options.loanOverviewApplicationStatus}
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>

				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("LoanApplication.Overview.EscoOverview.title")}
					</Typography>
				</Grid>

				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center">
					<LabelTextField
						id="data.esco_name"
						label={t("LoanApplication.Overview.EscoOverview.escoName")}
						size="large"
						placeholder="Answer"
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>

					<LabelTextField
						id="data.main_business_activity"
						label={t("LoanApplication.Overview.EscoOverview.mainBusinessActivity")}
						size="large"
						placeholder="Answer"
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>

					<LabelFileUpload
						viewOnly
						fieldId="docs.esco_overview_doc_1"
						projectId={user.company_id}
						label={t("LoanApplication.Overview.EscoOverview.doc1")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>

					<LabelFileUpload
						viewOnly
						fieldId="docs.esco_overview_doc_2"
						projectId={user.company_id}
						label={t("LoanApplication.Overview.EscoOverview.doc2")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>

					<LabelFileUpload
						viewOnly
						fieldId="docs.esco_overview_doc_3"
						projectId={user.company_id}
						label={t("LoanApplication.Overview.EscoOverview.doc3")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>

					<LabelFileUpload
						viewOnly
						fieldId="docs.esco_overview_doc_4"
						projectId={user.company_id}
						label={t("LoanApplication.Overview.EscoOverview.doc4")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>

					<LabelFileUpload
						viewOnly
						fieldId="docs.esco_overview_doc_5"
						projectId={user.company_id}
						label={t("LoanApplication.Overview.EscoOverview.doc5")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						infoText={t("LoanApplication.Overview.EscoOverview.info1")}
						onSuccess={() => fetchData()}
					/>

					<LabelFileUpload
						viewOnly
						fieldId="docs.esco_overview_doc_6"
						projectId={user.company_id}
						label={t("LoanApplication.Overview.EscoOverview.doc6")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>

					<LabelFileUpload
						viewOnly
						fieldId="docs.esco_overview_doc_7"
						projectId={user.company_id}
						label={t("LoanApplication.Overview.EscoOverview.doc7")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
				</Grid>

				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#6F96C6">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("LoanApplication.Overview.TechnologyUsed.title")}
					</Typography>
				</Grid>

				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center">
					<LabelDropdown
						id="data.category"
						size="large"
						label={t("LoanApplication.Overview.TechnologyUsed.category")}
						options={categoryOptions}
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>

					<LabelDropdown
						id="data.subcategory"
						size="large"
						label={t("LoanApplication.Overview.TechnologyUsed.subcategory")}
						options={subcategoryOptions}
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>

					<LabelDropdown
						id="data.specific_technology"
						size="large"
						label={t("LoanApplication.Overview.TechnologyUsed.specificTechnology")}
						options={specificTechnologyOptions}
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>

					<LabelFileUpload
						fieldId="docs.value_curve"
						projectId={projectid}
						label={t("LoanApplication.Overview.TechnologyUsed.doc1")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="loanApplication"
						onSuccess={() => fetchData()}
					/>

					<LabelFileUpload
						fieldId="docs.asset_gap"
						projectId={projectid}
						label={t("LoanApplication.Overview.TechnologyUsed.doc2")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="loanApplication"
						onSuccess={() => fetchData()}
					/>
				</Grid>

				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#6F96C6">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("LoanApplication.Overview.TechnicalSpecifications.title")}
					</Typography>
				</Grid>

				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center">
					<LabelFileUpload
						fieldId="docs.main_parts"
						projectId={projectid}
						label={t("LoanApplication.Overview.TechnicalSpecifications.doc1")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="loanApplication"
						onSuccess={() => fetchData()}
					/>

					<LabelFileUpload
						fieldId="docs.spare_parts"
						projectId={projectid}
						label={t("LoanApplication.Overview.TechnicalSpecifications.doc2")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="loanApplication"
						onSuccess={() => fetchData()}
					/>

					<LabelFileUpload
						fieldId="docs.technical_risk_assessment"
						projectId={projectid}
						label={t("LoanApplication.Overview.TechnicalSpecifications.doc3")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="loanApplication"
						infoText={t("LoanApplication.Overview.TechnicalSpecifications.info1")}
						onSuccess={() => fetchData()}
					/>
				</Grid>

				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#6F96C6">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("LoanApplication.Overview.TrackRecord.title")}
					</Typography>
				</Grid>

				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center">
					<LabelRadio
						id="data.new_market_player"
						label={t("LoanApplication.Overview.TrackRecord.radio1")}
						size="large"
						options={[{ value: "false", label: "No" }, { value: "true", label: "yes" }]}
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>

					<LabelFileUpload
						viewOnly
						fieldId="docs.track_record_doc_1"
						projectId={user.company_id}
						label={t("LoanApplication.Overview.TrackRecord.doc1")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						infoText={t("LoanApplication.Overview.TrackRecord.info1")}
						onSuccess={() => fetchData()}
					/>

					<LabelTextField
						id="data.num_of_years_operation"
						label={t("LoanApplication.Overview.TrackRecord.text1")}
						size="large"
						type="number"
						placeholder="Answer"
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>

					<LabelTextField
						id="data.status_of_projects"
						label={t("LoanApplication.Overview.TrackRecord.text2")}
						size="large"
						placeholder="Answer"
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>

					<LabelFileUpload
						viewOnly
						fieldId="docs.track_record_doc_2"
						projectId={user.company_id}
						label={t("LoanApplication.Overview.TrackRecord.doc2")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>

					<LabelFileUpload
						viewOnly
						fieldId="docs.track_record_doc_3"
						projectId={user.company_id}
						label={t("LoanApplication.Overview.TrackRecord.doc3")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						infoText={t("LoanApplication.Overview.TrackRecord.info2")}
						onSuccess={() => fetchData()}
					/>

					<LabelFileUpload
						viewOnly
						fieldId="docs.track_record_doc_4"
						projectId={user.company_id}
						label={t("LoanApplication.Overview.TrackRecord.doc4")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
				</Grid>

				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#6F96C6">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("LoanApplication.Overview.ManagementOrganization.title")}
					</Typography>
				</Grid>

				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center">
					<LabelFileUpload
						viewOnly
						fieldId="docs.management_organization_doc_1"
						projectId={user.company_id}
						label={t("LoanApplication.Overview.ManagementOrganization.doc1")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>

					<LabelFileUpload
						viewOnly
						fieldId="docs.management_organization_doc_2"
						projectId={user.company_id}
						label={t("LoanApplication.Overview.ManagementOrganization.doc2")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>

					<LabelFileUpload
						viewOnly
						fieldId="docs.management_organization_doc_3"
						projectId={user.company_id}
						label={t("LoanApplication.Overview.ManagementOrganization.doc3")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						infoText={t("LoanApplication.Overview.TechnicalSpecifications.info1")}
						onSuccess={() => fetchData()}
					/>
				</Grid>

				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#6F96C6">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("LoanApplication.Overview.SalesStrategy.title")}
					</Typography>
				</Grid>

				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center">
					<LabelFileUpload
						viewOnly
						fieldId="docs.sales_strategy_doc_1"
						projectId={user.company_id}
						label={t("LoanApplication.Overview.SalesStrategy.doc1")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>

					<LabelFileUpload
						viewOnly
						fieldId="docs.sales_strategy_doc_2"
						projectId={user.company_id}
						label={t("LoanApplication.Overview.SalesStrategy.doc2")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>

					<LabelTextField
						id="data.pipeline_overview"
						label={t("LoanApplication.Overview.SalesStrategy.text1")}
						size="large"
						rows={3}
						placeholder="Answer"
						disabled={shouldUiBeDisabled}
						infoText={t("LoanApplication.Overview.SalesStrategy.info1")}
						formik={formik}
					/>

					<LabelFileUpload
						viewOnly
						fieldId="docs.sales_strategy_doc_3"
						projectId={user.company_id}
						label={t("LoanApplication.Overview.SalesStrategy.doc3")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
				</Grid>
			</Grid>
		</form>
	);
};

export default memo(LoanApplicationOverview);
