/* eslint-disable no-unused-vars */
import { memo, useState, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import {
	Box,
	Grid,
	Switch,
	Button,
	Typography,
} from "@mui/material";

import { ContainedButton } from "../custom/index";
import StatusDialog from "../StatusDialog";

const useStyles = makeStyles((theme) => ({
	boxBorder: {
		border: `2px solid ${theme.palette.primary.main}`,
		borderRadius: theme.spacing(2),
		backgroundColor: "white",
	},
	screenTitle: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		letterSpacing: theme.spacing(0.2),
	},
}));

const MainMenu = ({
	title = "Sefa Main Menu",
	project = {},
	isDeveloper = false,
	dirty = false,
	disabled = false,
	onFetch,
	onEdit,
	onChange,
}) => {
	const [enableEditing, setEnableEditing] = useState(false);
	const [projectStateDialog, setProjectStateDialog] = useState(false);

	const classes = useStyles();

	const changeProjectState = useCallback(
		async () => {
			await onChange();
			onFetch();
		}, [onFetch, onChange],
	);

	return (
		<Grid container direction="column" alignItems="center" py={1} px={3} sx={{ "> .MuiGrid-item": { p: 1 }, position: "fixed", maxWidth: "1300px", width: "calc(100% - 300px)", background: "white", zIndex: 999 }}>
			<Grid container item direction="row" alignItems="center" sx={{ "> .MuiGrid-item": { p: 0 }, background: "#F4F4F4", borderRadius: "10px 10px 0px 0px" }}>
				<Grid item xs={12} md={4}>
					<Typography variant="h5" className={classes.screenTitle}>{title}</Typography>
				</Grid>
				<Grid item xs={0} md={3} />
				<Grid item container className={classes.boxBorder} sx={{ p: 1 }} xs={12} md={5}>
					<Grid item xs={12} md={6}>
						<Box p={1}>
							<Grid container direction="row" justifyContent="start" alignItems="center">
								<Grid item xs={4}>
									<Typography variant="h6">{"Status: "}</Typography>
								</Grid>
								<Grid item container xs={8} justifyContent="start">
									<Typography variant="h6" color={(project.status === "Approved") ? "green.main" : ((project.status === "Rejected") ? "error.main" : "primary.main")}>{project.status}</Typography>
								</Grid>
							</Grid>
						</Box>
					</Grid>
					<Grid item container xs={12} md={6} alignItems="center" justifyContent="end">
						<Button
							variant="contained"
							size="medium"
							sx={{ width: "170px", backgroundColor: "#5C9AE1", color: "common.white", ":hover": { backgroundColor: "primary.main" }, px: 3, marginRight: "4px", borderRadius: "10px" }}
							disabled={!isDeveloper}
							onClick={() => setProjectStateDialog(true)}
						>
							{project.status === "In Progress" ? "Submit Project" : "Revert Project"}
						</Button>
					</Grid>
				</Grid>
			</Grid>
			<Grid container item direction="row" alignItems="center" sx={{ ">.MuiGrid-item": { p: 0 }, background: "#F4F4F4", borderRadius: "0px 0px 10px 10px" }} p={0}>
				<Grid item xs={0} md={7} />
				<Grid item container sx={{ p: 1 }} xs={12} md={5} alignItems="center">
					<Grid item xs={12} md={6}>
						<Box px={1}>
							{!disabled
								&& (
									<Grid container direction="row" justifyContent="center" alignItems="center" p={0}>
										<Grid item xs={4}>
											<Typography variant="h6" fontSize="medium">{"Edit: "}</Typography>
										</Grid>
										<Grid item xs={8}>
											<Switch
												color="primary"
												size="small"
												onChange={(e) => { setEnableEditing(e.target.checked); onEdit(e.target.checked); }}
											/>
										</Grid>
									</Grid>
								)}
						</Box>
					</Grid>
					<Grid item xs={12} md={6}>
						<Box px={1} display="flex" flexDirection="row">
							<Grid container direction="row" justifyContent="flex-end" alignItems="center" ml={1}>
								<ContainedButton
									disabled={!enableEditing || !dirty}
									type="submit"
									label="SAVE"
								/>
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Grid>
			<StatusDialog
				open={projectStateDialog}
				status={project.status}
				name={project.name}
				onClose={setProjectStateDialog}
				onChange={changeProjectState}
			/>
		</Grid>
	);
};

MainMenu.propTypes = {
	title: PropTypes.string.isRequired,
	dirty: PropTypes.bool,
	project: PropTypes.object.isRequired,
	isDeveloper: PropTypes.bool,
	disabled: PropTypes.bool,
	onFetch: PropTypes.func.isRequired,
	onEdit: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default memo(MainMenu);
