import { memo, useCallback, useEffect, useState, forwardRef } from "react";
import { useParams } from "react-router-dom";
import { Button, Grid, LinearProgress, Typography, Slide, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import shallow from "zustand/shallow";
import { Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import usePrompt from "../../utils/use-blocker";
import { useSnackbar, httpResponseIsValid } from "../../utils";
import { getProjectKyc, updateProjectKyc, deleteFile } from "../../api";
import useGlobalState from "../../use-global-state";
import StatusDialog from "../../components/StatusDialog";
import {
	MainMenu,
	LabelTextField,
	LabelFileUpload,
} from "../../components/basic/index";

const Transition = forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const ProjectKycOverview = ({ userCompanyId = null, disableAccess = true, isProjectDeveloper = false, onChangeProjectState }) => {
	const { projectid } = useParams();
	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(disableAccess);
	const [companyId, setCompanyId] = useState(userCompanyId);
	const [projectStateDialog, setProjectStateDialog] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [projectInfo, setProjectInfo] = useState({
		status: "",
		createdAt: "-",
		projectSize: null,
		typeOfFinance: "project_finance",
		credit_request_doc_1: {
			original_name: "",
			save_name: "",
		},
		info_on_the_project_doc_1: {
			original_name: "",
			save_name: "",
		},
		info_on_the_project_doc_2: {
			original_name: "",
			save_name: "",
		},
		info_on_the_project_doc_3: {
			original_name: "",
			save_name: "",
		},
		info_on_the_project_doc_4: {
			original_name: "",
			save_name: "",
		},
		info_on_the_project_doc_5: {
			original_name: "",
			save_name: "",
		},
		info_on_the_project_doc_6: {
			original_name: "",
			save_name: "",
		},
		info_on_the_project_doc_7: {
			original_name: "",
			save_name: "",
		},
		procedures_and_policies_doc_1: {
			original_name: "",
			save_name: "",
		},
		procedures_and_policies_doc_2: {
			original_name: "",
			save_name: "",
		},
		kyc_overview: {
			sales_performance_txt_1: "",
			credit_request_txt_1: "",
			credit_request_txt_2: "",
		},
		docs: {
			general_info_doc_1: {
				original_name: "",
				save_name: "",
			},
			general_info_doc_3: {
				original_name: "",
				save_name: "",
			},
			legal_status_doc_1: {
				original_name: "",
				save_name: "",
			},
			legal_status_doc_2: {
				original_name: "",
				save_name: "",
			},
			legal_status_doc_3: {
				original_name: "",
				save_name: "",
			},
			legal_status_doc_4: {
				original_name: "",
				save_name: "",
			},
			legal_status_doc_5: {
				original_name: "",
				save_name: "",
			},
			legal_status_doc_6: {
				original_name: "",
				save_name: "",
			},
			legal_status_doc_7: {
				original_name: "",
				save_name: "",
			},
			legal_status_doc_8: {
				original_name: "",
				save_name: "",
			},
			legal_status_doc_9: {
				original_name: "",
				save_name: "",
			},
			legal_status_doc_10: {
				original_name: "",
				save_name: "",
			},
			legal_status_doc_11: {
				original_name: "",
				save_name: "",
			},
			technical_legal_or_insurance_doc_1: {
				original_name: "",
				save_name: "",
			},
			technical_legal_or_insurance_doc_2: {
				original_name: "",
				save_name: "",
			},
		},
	});

	const { success, error } = useSnackbar();
	const [deleteModal, setDeleteModal] = useState({ component: null, variable: null });

	const { setProjectSize, setTypeOfFinance } = useGlobalState(useCallback((e) => ({
		setProjectSize: e.setProjectSize,
		setTypeOfFinance: e.setTypeOfFinance,
	}), []), shallow);

	const [enableEditing, setEnableEditing] = useState(false);

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getProjectKyc(projectid);
				if (httpResponseIsValid(response)) {
					setProjectInfo(response);

					if (!companyId) {
						setCompanyId(response.company_id);
					}

					setProjectSize(response?.projectSize || null);
					setTypeOfFinance(response?.typeOfFinance || "project_finance");
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured when fetching kyc data: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, setProjectSize, setTypeOfFinance, companyId, projectid],
	);

	const handleSubmit = async (values) => {
		setIsLoading(true);

		try {
			const response = await updateProjectKyc(projectid, values);
			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			console.log(`Error occured when updating KYC's all aspects data: ${_error}`);
		}

		setIsLoading(false);
	};

	const onDelete = async (comp) => {
		try {
			setIsLoading(true);

			const projectId = comp.component === "documents" ? undefined : projectid;
			// eslint-disable-next-line max-len
			const { success: deleteSuccess } = await deleteFile({ projectid: projectId, component: comp.component, variable: comp.variable });
			console.log(deleteSuccess);
			if (deleteSuccess) {
				success("File deleted successfully");
				await fetchData(false);
				setIsLoading(false);
			} else {
				error();
				setIsLoading(false);
			}
		} catch { /* empty */ }
	};

	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: projectInfo,
		onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	});

	const { dirty } = formik;
	usePrompt("You have unsaved changes which will be lost. Are you sure you want to leave the page?", dirty);
	const changeProjectState = useCallback(
		async () => {
			await onChangeProjectState();
			fetchData();
		}, [fetchData, onChangeProjectState],
	);

	useEffect(() => {
		setShouldUiBeDisabled(disableAccess || !enableEditing);
	}, [disableAccess, enableEditing]);

	useEffect(() => {
		setCompanyId(userCompanyId);
	}, [userCompanyId]);

	useEffect(() => {
		formik.resetForm({ values: projectInfo, dirty: false });
	}, [projectInfo]);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData, projectid]);

	return (
		<form onSubmit={formik.handleSubmit}>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<MainMenu
				title={t("RiskAssessmentOverview.managementRisk")}
				dirty={dirty}
				project={projectInfo}
				isDeveloper={isProjectDeveloper}
				disabled={disableAccess}
				onFetch={fetchData}
				onEdit={setEnableEditing}
				onChange={onChangeProjectState}
			/>
			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "110px" }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{"GENERAL INFO"}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} mt={1} alignItems="center" justifyContent="space-between">
					<LabelFileUpload
						viewOnly
						fieldId="docs.general_info_doc_1"
						projectId={companyId}
						label="Company Description"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						viewOnly
						fieldId="docs.general_info_doc_3"
						projectId={companyId}
						label="Company Track Record"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						infoText="Add proof of track record by providing case studies of 5 projects and/or the status of other current projects (under development / operational)"
						onSuccess={() => fetchData()}
					/>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{"LEGAL STATUS"}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center" justifyContent="space-between">
					<LabelFileUpload
						viewOnly
						fieldId="docs.legal_status_doc_1"
						projectId={companyId}
						label="Certificate of incorporation and company statutes"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						viewOnly
						fieldId="docs.legal_status_doc_2"
						projectId={companyId}
						label="Description of activities"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						viewOnly
						fieldId="docs.legal_status_doc_3"
						projectId={companyId}
						label="Accounting policies"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						viewOnly
						fieldId="docs.legal_status_doc_4"
						projectId={companyId}
						label="Management team"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						viewOnly
						fieldId="docs.legal_status_doc_5"
						projectId={companyId}
						label="Ownership"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						infoText="Ownership up to the final beneficiary owner and link to the national register of beneficiary owners above 25% (10% if Political Exposed Person presence)"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						viewOnly
						fieldId="docs.legal_status_doc_6"
						projectId={companyId}
						label="Audited financial statements or simplified accounts"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						infoText="Balance sheets, profit and loss and cashflow statements for the last three financial years"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						viewOnly
						fieldId="docs.legal_status_doc_7"
						projectId={companyId}
						label="Details of short, medium and long-term liabilities"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						viewOnly
						fieldId="docs.legal_status_doc_8"
						projectId={companyId}
						label="Dividend distribution policy"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						viewOnly
						fieldId="docs.legal_status_doc_9"
						projectId={companyId}
						label="Financial forecasts"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						viewOnly
						fieldId="docs.legal_status_doc_10"
						projectId={companyId}
						label="Legal and tax good standing document"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						infoText="Legal and tax good standing document of the company and its representative or/and shareholders. In case of factoring, information related to the customers of the company is needed."
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						viewOnly
						fieldId="docs.legal_status_doc_11"
						projectId={companyId}
						label={"Identification of UBO's & senior management"}
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						infoText="Identification documentation of UBO's & senior management, including proof of address: i.e. telephone or energy bill."
						onSuccess={() => fetchData()}
					/>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{"CREDIT REQUEST"}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} alignItems="center" justifyContent="space-between">
					<LabelTextField
						id="kyc_overview.credit_request_txt_1"
						label="Information on the project or technical solution"
						size="large"
						aligntitlebox="center"
						typosize="h8"
						placeholder="Answer"
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>
					<LabelFileUpload
						fieldId="credit_request_doc_1"
						projectId={projectid}
						label="Technical, legal or insurance documents on project/entity"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						onSuccess={() => fetchData()}
					/>
					<LabelTextField
						id="kyc_overview.credit_request_txt_2"
						label="KY provider or supplier"
						size="large"
						aligntitlebox="center"
						typosize="h8"
						placeholder="Answer"
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{"INFORMATION ON THE PROJECT"}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center" justifyContent="space-between">
					<LabelFileUpload
						fieldId="info_on_the_project_doc_1"
						projectId={projectid}
						label="Legal status of the proposed project"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						fieldId="info_on_the_project_doc_2"
						projectId={projectid}
						label="Relationship with borrower's/promoter's other activities"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						fieldId="info_on_the_project_doc_4"
						projectId={projectid}
						label="Licences obtained, IP (if any)"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						fieldId="info_on_the_project_doc_5"
						projectId={projectid}
						label="Concessions obtained (if any)"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						fieldId="info_on_the_project_doc_6"
						projectId={projectid}
						label="Permissions and relevant technical studies"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						onSuccess={() => fetchData()}
					/>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{"PROCEDURES AND POLICIES"}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} alignItems="center" justifyContent="space-between">
					<LabelFileUpload
						fieldId="procedures_and_policies_doc_1"
						projectId={projectid}
						label="Final offer made to/accepted by the end client"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						infoText="Final offer made to the final client/accepted offer of services by the client (to contain: costs, energy savings, how will they maintain it, maintenance cost)"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						fieldId="procedures_and_policies_doc_2"
						projectId={projectid}
						label="End client documents"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="kyc"
						infoText="Letters of Intent, Pilot agreement, any other document received from the end client"
						onSuccess={() => fetchData()}
					/>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{"TECHNICAL LEGAL OR INSURANCE"}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center" justifyContent="space-between">
					<LabelFileUpload
						viewOnly
						fieldId="docs.technical_legal_or_insurance_doc_1"
						projectId={companyId}
						label="Technical, Legal or Insurance advice"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						infoText="Please attach any Technical, Legal or Insurance Advice or study that was provided and might be relevant."
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						viewOnly
						fieldId="docs.technical_legal_or_insurance_doc_2"
						projectId={companyId}
						label="Legal Status and UBO of the provider asset"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
				</Grid>
			</Grid>
			{deleteModal.component !== null
			&& deleteModal.variable !== null
			&& (
				<Dialog
					keepMounted
					open={deleteModal.component !== null && deleteModal.variable !== null}
					TransitionComponent={Transition}
					onClose={() => setDeleteModal({ component: null, variable: null })}
				>
					<DialogTitle>
						{"Are you sure?"}
					</DialogTitle>
					<DialogContent dividers>
						<DialogContentText>
							{"Are you sure you want to delete the file?"}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							autoFocus
							startIcon={<Delete />}
							variant="contained"
							onClick={() => {
								onDelete(deleteModal);
								setDeleteModal({ component: null, variable: null });
							}}
						>
							{"Delete"}
						</Button>
						<Button variant="outlined" onClick={() => setDeleteModal({ component: null, variable: null })}>{"Cancel"}</Button>
					</DialogActions>
				</Dialog>
			)}
			<StatusDialog
				open={projectStateDialog}
				status={projectInfo.status}
				name={projectInfo.name}
				onClose={setProjectStateDialog}
				onChange={changeProjectState}
			/>
		</form>
	);
};

export default memo(ProjectKycOverview);
