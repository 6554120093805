import { useCallback, memo, useMemo, useState, useEffect, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography, Grid, LinearProgress, Box, ToggleButton, MenuItem, Dialog, FormControl, ListItemIcon, Checkbox, FormHelperText, Select, InputLabel, ListItemText, DialogTitle, DialogContent, Switch, Slide, ToggleButtonGroup, Tooltip, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Edit, Delete } from "@mui/icons-material";
import clsx from "clsx";
import shallow from "zustand/shallow";
import * as Yup from "yup";
import InfoIcon from "@mui/icons-material/Info";
// import { useFormik } from "formik";

import CertificateUpload from "../components/CertificateUpload";
import { options } from "../utils/options";
import TextField from "../components/TextField";
import { useSnackbar, httpResponseIsValid, jwt } from "../utils";
import Table from "../components/Table";
import useGlobalState from "../use-global-state";
import { getCompany, setCompany, updateCompanyInformation, updateCompanyCertificates, deleteCompanyCertificate, leaveCompany, sendUserInviation, getCompanyUsers, updateCompanyUserRole, deleteCompanyUser, getUserProfile, deleteFile } from "../api";
// import { LabelDropdown } from "../components/basic/LabelDropdown";

const Transition = forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);
const { REACT_APP_MAIN_SERVER_URL } = process.env;

const useStyles = makeStyles((theme) => ({
	header: {
		color: theme.palette.green.main,
	},
	page: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
	boxBorder: {
		border: `2px solid ${theme.palette.primary.main}`,
		borderRadius: theme.spacing(2),
		backgroundColor: "white",
	},
	screenTitle: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		letterSpacing: theme.spacing(0.2),
		marginLeft: "10px",
	},
	textFieldStyle: {
		padding: "5px",
	},
	button: {
		backgroundColor: "white",
		height: "50px",
		width: "50%",
		"&:hover": {
			backgroundColor: "white",
		},
	},
	buttonDisabled: {
		backgroundColor: "#979797",
		height: "45px",
		width: "50%",
		"&:hover": {
			backgroundColor: "#A0ACB0",
		},
	},
	buttonTitle: {
		color: "#818C90",
		letterSpacing: theme.spacing(0.1),
	},
	buttonDisabledTitle: {
		color: "white",
		letterSpacing: theme.spacing(0.1),
	},
	toggles: {
		"& .Mui-selected": {
			color: "#ffffff !important",
			backgroundColor: "#6F96C6 !important",
		},
		"& .MuiToggleButton-root": {
			height: "40px",
			"&:hover": {
				color: "#1d4363",
				backgroundColor: "#F0F0F0",
			},
			color: "#1d4363",
			backgroundColor: "#ffffff",
			borderRadius: "10px",
		},
	},
}));

const UserRoles = {
	owner: "owner",
	member: "member",
	investorOwner: "investor_owner",
};

const defaultCompanyInfo = {
	name: "Company Name",
	description: "Company Description",
	website: "www.company's-website.com",
	country: "Company Country",
	city: "Company City",
	address: "Company Address",
	postal_code: "Company Postal Code",
	telephone: "+XXXXXXXXXX",
	type: "Company Type",
	vat_number: "XXXXXXXXX",
	minimum_project_size: "",
	active_countries: [],
	technologies: [],
};

const projectTaxonomies = [
	"Building Fabric Measure",
	"Electric Power System Measure",
	"HVAC And Hot Water Measure",
	"Lighting Measure",
	"Management Measure",
	"Refrigeration Systems Measure",
	"Renewable Generation Measure",
	"Heating And Cooling Control Measure",
	"Other",
];

const CompanyProfile = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [hasChanged, setHasChanged] = useState(false);
	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(false);
	const [companyExisting, setCompanyExisting] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);
	const [enableEditing, setEnableEditing] = useState(false);
	const [deleteUserDialog, setDeleteUserDialog] = useState(false);
	const [deleteCertificateFileDialog, setDeleteCertificateFileDialog] = useState(false);

	const [selectedUserToDelete, setSelectedUserToDelete] = useState("");
	const [userRole, setUserRole] = useState("");
	const [userName, setUserName] = useState("");
	const { setName } = useGlobalState(useCallback((e) => ({
		setName: e.setName,
	}), []), shallow);
	const navigate = useNavigate();
	const { success, error } = useSnackbar();
	// const [deleteModal, setDeleteModal] = useState({ component: null, variable: null });

	const [companyInfo, setCompanyInfo] = useState({
		id: "",
		createdAt: "",
		info: {
			name: "",
			description: "",
			website: "",
			country: "",
			city: "",
			address: "",
			postal_code: "",
			telephone: "",
			type: "",
			vat_number: "",
			active_countries: [],
			technologies: [],
			minimum_project_size: "",
		},
		certifications: [],
	});

	const [tempCompanyInfo, setTempCompanyInfo] = useState({
		name: "",
		description: "",
		website: "",
		country: "",
		city: "",
		address: "",
		postal_code: "",
		telephone: "",
		type: "",
		vat_number: "",
		active_countries: [],
		technologies: [],
		minimum_project_size: "",
	});

	const isAllCountriesSelected = options.length > 0 && tempCompanyInfo.active_countries.length === options.length;
	// eslint-disable-next-line max-len
	const isAllTechnologiesSelected = projectTaxonomies.length > 0 && tempCompanyInfo.technologies.length === projectTaxonomies.length;

	const [companyInfoError, setCompanyInfoError] = useState({
		name: "",
		description: "",
		website: "",
		country: "",
		city: "",
		address: "",
		postal_code: "",
		telephone: "",
		type: "",
		vat_number: "",
		minimum_project_size: "",
		active_countries: "",
		technologies: "",
	});

	const [tempUser, setTempUser] = useState({
		user_email: "",
		user_role: "",
	});

	const [tempCertificate, setTempCertificate] = useState({
		name: "",
		description: "",
		file: {
			original_name: "",
			save_name: "",
		},
	});

	const [tempCertificateFile, setTempCertificateFile] = useState({
		original_name: "",
		save_name: "",
	});

	const [addCertificate, setAddCertificate] = useState(false);
	const [addEditCompanyUser, setAddEditCompanyUser] = useState("");
	const [editCertificate, setEditCertificate] = useState(false);
	const [onDeleteFile, setOnDeleteFile] = useState(false);
	const [companyUsers, setCompanyUsers] = useState([]);
	const [tempEditCertificateName, setTempEditCertificateName] = useState(null);
	const [companyFieldFieldError, setCompanyFieldFieldError] = useState([]);
	const [companyUserRole, setCompanyUserRole] = useState("member");
	const [leaveCompanyDialog, setLeaveCompanyDialog] = useState(false);

	const isUserHost = (name) => {
		if (name === userName) {
			return true;
		}

		return false;
	};

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			let response;
			try {
				response = await getCompany();
				if (httpResponseIsValid(response)) {
					console.log("info:", response);

					setCompanyInfo({
						...companyInfo,
						id: response.id,
						createdAt: response.createdAt,
						info: response.info,
						certifications: response.certifications,
					});

					setTempCompanyInfo(response.info);
					setDataChanged(false);
					setCompanyExisting(true);
				} else {
					setCompanyExisting(false);
				}

				response = await getCompanyUsers();
				if (httpResponseIsValid(response)) {
					setCompanyUsers(response.users);
				}

				response = await getUserProfile();
				if (httpResponseIsValid(response)) {
					setUserRole(response?.role);
					setUserName(response?.email);
				}
			} catch (_error) {
				setCompanyExisting(false);

				error(`Error occured while fetching company's data: ${_error}`);
			}

			setIsLoading(false);
		},
		[],
	);

	useEffect(() => {
		setShouldUiBeDisabled(!enableEditing);
	}, [enableEditing]);

	useEffect(() => {
		if (JSON.stringify(tempCompanyInfo) === JSON.stringify(companyInfo.info)) {
			setDataChanged(false);
		} else {
			setDataChanged(true);
		}
	}, [tempCompanyInfo, companyInfo.info]);

	const createCompany = async () => {
		setIsLoading(true);

		try {
			const response = await setCompany(companyInfo.info);
			if (httpResponseIsValid(response)) {
				success(response.message);
				await fetchData();
			}
		} catch (_error) {
			error(`Error occured while creating company: ${_error}`);
		}

		setIsLoading(false);
	};

	const updateCompanyInfo = async () => {
		setIsLoading(true);

		try {
			const response = await updateCompanyInformation(companyInfo.info);
			if (httpResponseIsValid(response)) {
				success(response.message);
				await fetchData();
			}
		} catch (_error) {
			error(`Error occured while updating company's information: ${_error}`);
		}

		setIsLoading(false);
	};

	const updateCompanyCerts = async () => {
		setIsLoading(true);

		try {
			const response = await updateCompanyCertificates(...companyInfo.certifications);
			if (httpResponseIsValid(response)) {
				success(response.message);
				await fetchData();
			}
		} catch (_error) {
			error(`Error occured while updating company's certificates: ${_error}`);
		}

		setIsLoading(false);
	};

	const handleLeaveCompany = () => {
		setLeaveCompanyDialog(true);
	};

	const onSetCompanyUserRole = (e) => {
		setCompanyUserRole(e.target.value);
	};

	const onCompanyInfoChange = (e, name) => {
		if (name === "vat_number") {
			if (/^\d{0,9}$/.test(e.target.value)) {
				setTempCompanyInfo({
					...tempCompanyInfo,
					[name]: e.target.value,
				});

				setCompanyInfoError({
					...companyInfoError,
					[name]: "",
				});
			}
		} else if (name === "telephone") {
			if (/^\d{0,10}$/.test(e.target.value)) {
				setTempCompanyInfo({
					...tempCompanyInfo,
					[name]: e.target.value,
				});

				setCompanyInfoError({
					...companyInfoError,
					[name]: "",
				});
			}
		} else {
			setTempCompanyInfo({
				...tempCompanyInfo,
				[name]: e.target.value,
			});

			setCompanyInfoError({
				...companyInfoError,
				[name]: "",
			});
		}
	};

	const onSaveCompanyInfo = () => {
		try {
			let errorsFound = false;

			const fieldErrors = {
				name: "",
				description: "",
				website: "",
				country: "",
				city: "",
				address: "",
				postal_code: "",
				telephone: "",
				type: "",
				vat_number: "",
				minimum_project_size: "",
				active_countries: [],
				technologies: [],
			};

			for (const k of Object.keys(tempCompanyInfo)) {
				if (!["minimum_project_size", "active_countries", "technologies", "description"].includes(k) && tempCompanyInfo[k] === "") {
					fieldErrors[k] = `Field ${k} can not be left empty.`;
					errorsFound = true;
				}
			}

			const vatRegex = /^\d{9}$/;
			if (!vatRegex.test(tempCompanyInfo.vat_number)) {
				fieldErrors.vat_number = "Field VAT Number must be a number and 9 characters in length.";
				errorsFound = true;
			}

			const telephoneRegex = /^\d{10}$/;
			if (!telephoneRegex.test(tempCompanyInfo.telephone)) {
				fieldErrors.telephone = "Field Telephone Number must be a number and 10 characters in length.";
				errorsFound = true;
			}

			const websiteRegex = /^(https?:\/\/)?(www\.)?[\dA-Za-z-]+\.[A-Za-z]{2,}(\/\S*)?$/;
			if (!websiteRegex.test(tempCompanyInfo.website)) {
				fieldErrors.website = "Field Website must follow the format: www.website.com";
				errorsFound = true;
			}

			if (tempCompanyInfo.country === "") {
				fieldErrors.country = "A Country must be selected in this field";
				errorsFound = true;
			}

			if (userRole === "investor_owner" || userRole === "investor_member") {
				if (tempCompanyInfo.technologies.length === 0) {
					fieldErrors.technologies = "At least one Technology must be selected.";
					errorsFound = true;
				}

				if (tempCompanyInfo.active_countries.length === 0) {
					fieldErrors.active_countries = "At least one Active Country must be selected.";
					errorsFound = true;
				}

				if (tempCompanyInfo.minimum_project_size === "") {
					fieldErrors.minimum_project_size = "Minimum Project Size cannot be empty.";
					errorsFound = true;
				}
			}

			if (errorsFound) {
				setCompanyInfoError(fieldErrors);
				return;
			}

			setHasChanged(true);

			setCompanyInfo({
				...companyInfo,
				info: {
					...tempCompanyInfo,
				},
			});
		} catch (_error) {
			console.log(_error);
		}

		setEnableEditing(false);
	};

	const handleAddCertificate = () => {
		setTempCertificate({
			name: "",
			description: "",
			file: {
				original_name: "",
				save_name: "",
			},
		});

		setAddCertificate(true);
	};

	const handleAddUsers = () => {
		setTempUser({
			user_email: "",
			user_role: "member",
		});

		setAddEditCompanyUser("add");
		setCompanyUserRole("member");
	};

	const onCertificateValueChange = (e, name) => {
		setTempCertificate({
			...tempCertificate,
			[name]: e.target.value,
		});

		if (e.target.value !== "") {
			setCompanyFieldFieldError([]);
		}
	};

	const onUserValueChange = (e, name) => {
		setTempUser({
			...tempUser,
			[name]: e.target.value,
		});
	};

	const onSaveUserInvite = async () => {
		setIsLoading(true);

		try {
			if (addEditCompanyUser === "add") {
				const validationSchema = Yup.object().shape({
					email: Yup.string().trim().email().required(),
				});

				const emailValid = await validationSchema.isValid({ email: tempUser.user_email });

				if (!emailValid) {
					setCompanyFieldFieldError(["user_email"]);
					setIsLoading(false);

					return;
				}

				const response = await sendUserInviation({
					...tempUser,
					company_id: companyInfo.id,
				});

				if (httpResponseIsValid(response)) {
					success(response?.message);
				} else {
					error(response?.message);
				}
			} else if (addEditCompanyUser === "edit") {
				const response = await updateCompanyUserRole(tempUser);

				if (httpResponseIsValid(response)) {
					success(response?.message);
					await fetchData();
				} else {
					error(response?.message);
				}
			}
		} catch (_error) {
			error(`Error occured while altering company's user: ${_error}`);
		}

		setAddEditCompanyUser("");
		setIsLoading(false);
	};

	const onCancelUserInvite = () => {
		setAddEditCompanyUser("");
	};

	const onCompanyUserRoleEdit = (value) => {
		const user = companyUsers.find((u) => u.email === value);

		setTempUser({
			...tempUser,
			user_email: user.email,
		});

		const role = user.role === "investor_owner" ? "owner" : (user.role === "investor_member" ? "member" : user.role);

		setCompanyUserRole(role);
		setAddEditCompanyUser("edit");
	};

	const onCompanyUserDeleteDialog = (value) => {
		setDeleteUserDialog(true);
		setSelectedUserToDelete(value);
	};

	const onDeleteCertificateFile = async () => {
		try {
			setIsLoading(true);
			const certificate = companyInfo.certifications.find((cert) => cert._id === tempCertificate._id);
			console.log("certificate to delete its file:", certificate)
			// eslint-disable-next-line max-len
			const { success: deleteSuccess } = await deleteFile({ projectid: undefined, component: "certifications", saveName: tempCertificate.file.save_name });
			if (deleteSuccess) {
				success("File deleted successfully");
				setOnDeleteFile(true);
				tempCertificate.file.original_name = "";
				tempCertificate.file.save_name = "";
				// setOnDeleteFile(false);
				await fetchData(false);
				setIsLoading(false);
			} else {
				error();
				setIsLoading(false);
			}
		} catch (_error) {
			error(`Error occured while deleting a company user: ${_error}`);
		}

		setDeleteCertificateFileDialog(false);
	};

	const onCompanyUserDelete = async () => {
		setIsLoading(true);

		try {
			const response = await deleteCompanyUser({ user_email: selectedUserToDelete });
			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			error(`Error occured while deleting a company user: ${_error}`);
		}

		setAddEditCompanyUser("");
		setSelectedUserToDelete("");
		setDeleteUserDialog(false);
		setIsLoading(false);
	};

	const onSaveCertificate = () => {
		if (tempCertificate.name === "") {
			setCompanyFieldFieldError(["cert_name"]);
			return;
		}

		const certs = companyInfo.certifications;

		if (editCertificate) {
			const certificate = certs.filter((cert) => cert._id === tempCertificate._id);
			if (certificate.length > 1) {
				const oldCert = certs.findIndex((cert) => cert._id === tempCertificate._id);
				if (oldCert !== -1) {
					certs.splice(oldCert, 1);
				}
			} else {
				certs.splice(certificate, 1);
			}
		}

		if (tempCertificateFile !== null && tempCertificateFile.save_name !== "") {
			tempCertificate.file.original_name = tempCertificateFile.original_name;
			tempCertificate.file.save_name = tempCertificateFile.save_name;
		}

		if (onDeleteFile) {
			tempCertificate.file.original_name = "";
			tempCertificate.file.save_name = "";
			setOnDeleteFile(false);
		}

		certs.push(tempCertificate);
		setHasChanged(true);
		setCompanyInfo({
			...companyInfo,
			certifications: [
				// ...companyInfo.certifications,
				tempCertificate,
			],
		});

		setTempEditCertificateName(null);
		setTempCertificateFile(null);
		setEditCertificate(false);
		setAddCertificate(false);
	};

	const onCancelAddCerticate = () => {
		setCompanyFieldFieldError([]);
		setTempEditCertificateName(null);
		setTempCertificateFile(null);
		setEditCertificate(false);
		setAddCertificate(false);
		setOnDeleteFile(false);
	};

	const onCertificateDelete = async (id) => {
		setIsLoading(true);
		try {
			const response = await deleteCompanyCertificate(id);
			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			error(`Error occured while deleting a company certificate: ${_error}`);
		}

		setEditCertificate(false);
		setAddCertificate(false);

		setIsLoading(false);
	};

	const handleUploadSuccess = (fileobj) => {
		setTempCertificateFile({
			original_name: fileobj.original_name,
			save_name: fileobj.save_name,
		});
		// Optionally update state or fetch new data to reflect the uploaded file
		// fetchData().then(setCompanyInfo);
	};

	const onCertificateEdit = (name) => {
		const certificate = companyInfo.certifications.find((cert) => cert._id === name);

		setTempCertificate({
			name: certificate.name,
			description: certificate.description,
			file: {
				save_name: certificate.file.save_name,
				original_name: certificate.file.original_name,
			},
			_id: name,
		});
		setTempEditCertificateName(certificate.name);
		setEditCertificate(true);
		setAddCertificate(true);
	};

	const onLeaveDeleteCompany = async () => {
		setIsLoading(true);

		try {
			const response = await leaveCompany();
			if (httpResponseIsValid(response)) {
				success(response?.message);

				await fetchData();

				setCompanyInfo({
					id: "",
					createdAt: "",
					info: {
						name: "",
						description: "",
						website: "",
						country: "",
						city: "",
						address: "",
						postal_code: "",
						telephone: "",
						type: "",
						vat_number: "",
						minimum_project_size: "",
						active_countries: [],
						technologies: [],
					},
					certifications: [],
				});

				setTempCompanyInfo({
					name: "",
					description: "",
					website: "",
					country: "",
					city: "",
					address: "",
					postal_code: "",
					telephone: "",
					type: "",
					vat_number: "",
					minimum_project_size: "",
					active_countries: [],
					technologies: [],
				});

				setCompanyUsers([]);
			}
		} catch (_error) {
			error(`Error occured while leaving from comapny: ${_error}`);
		}

		setLeaveCompanyDialog(false);
		setIsLoading(false);
	};

	const roleToUpperCase = (value) => {
		switch (value) {
			case "owner":
				return "Owner";
			case "member":
				return "Member";
			case "investor_member":
				return "Investor Member";
			case "investor_owner":
				return "Investor Owner";
			case "admin":
				return "Sefa Admin";
			default:
				return "Unknown";
		}
	};

	const handleChangeOptions = (name, choices, event) => {
		let value = event.target.value;
		if (value.at(-1) === "all") {
			value = tempCompanyInfo[name].length === choices.length ? [] : choices;
		}

		setTempCompanyInfo({
			...tempCompanyInfo,
			[name]: value,
		});

		if (value.length > 0) {
			setCompanyInfoError({
				...companyInfoError,
				[name]: "",
			});
		}
	};

	// const formik = useFormik({
	// 	initialValues: companyInfo,
	// 	onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	// });

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData]);

	useEffect(() => {
		if (hasChanged) {
			setHasChanged(false);

			if (companyExisting) {
				updateCompanyInfo();
			} else {
				console.log("Creating");
				createCompany();
			}
		}
	}, [companyInfo.info]);

	useEffect(() => {
		if (hasChanged) {
			setHasChanged(false);

			if (companyExisting) {
				updateCompanyCerts();
			}
		}
	}, [companyInfo.certifications]);

	useEffect(() => {
		setTempUser({
			...tempUser,
			user_role: companyUserRole,
		});
	}, [companyUserRole]);

	const classes = useStyles();

	let currentFile;
	if (tempCertificate.file !== null) {
		currentFile = tempCertificate.file.save_name;
	}

	const userColumns = useMemo(() => [
		{
			Header: <Typography id="name_header" variant="h6">{"User"}</Typography>,
			accessor: "email",
			id: "email",
			width: 500,
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography color={value === "active" ? "green.main" : (value === "inactive" ? "error.main" : "black")}>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Role"}</Typography>,
			accessor: "role",
			id: "role",
			width: 300,
			style: { overflow: "visible" },
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{roleToUpperCase(value)}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Actions"}</Typography>,
			accessor: "email",
			id: "email",
			width: 500,
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Grid container item direction="row" justifyContent="space-around" alignItems="center">
					<Grid item mb={2} />
					<ToggleButton
						value="Edit"
						title="Edit"
						size="small"
						aria-label="view"
						sx={{ borderColor: "primary.main" }}
						disabled={isUserHost(value) || userRole === "member" || userRole === "investor_member"}
						onClick={() => onCompanyUserRoleEdit(value)}
					>
						<Edit color="primary" />
					</ToggleButton>
					<ToggleButton
						value="Delete"
						title="Delete"
						size="small"
						aria-label="delete"
						sx={{ borderColor: "primary.main" }}
						disabled={isUserHost(value) || userRole === "member" || userRole === "investor_member"}
						onClick={() => onCompanyUserDeleteDialog(value)}
					>
						<Delete color="error" />
					</ToggleButton>
					<Grid item mb={2} />
				</Grid>
			),
		},
	], [navigate, setName, companyUsers, fetchData, userRole, isUserHost]);

	const certColumns = useMemo(() => [
		{
			Header: <Typography id="name_header" variant="h6">{"Name"}</Typography>,
			accessor: "name",
			id: "name",
			width: 500,
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography color={value === "active" ? "green.main" : (value === "inactive" ? "error.main" : "black")}>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Description"}</Typography>,
			accessor: "description",
			id: "description",
			width: 300,
			style: { overflow: "visible" },
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography color={value === "active" ? "green.main" : (value === "inactive" ? "error.main" : "black")}>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Actions"}</Typography>,
			accessor: "_id",
			id: "_id",
			width: 500,
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Grid container item direction="row" justifyContent="space-around" alignItems="center">
					<Grid item mb={2} />
					<ToggleButton
						value="Edit"
						title="Edit"
						size="small"
						aria-label="view"
						sx={{ borderColor: "primary.main" }}
						disabled={(userRole !== UserRoles.owner && userRole !== UserRoles.investorOwner)}
						onClick={() => onCertificateEdit(value)}
					>
						<Edit color="primary" />
					</ToggleButton>
					<ToggleButton
						value="Delete"
						title="Delete"
						size="small"
						aria-label="delete"
						sx={{ borderColor: "primary.main" }}
						disabled={(userRole !== UserRoles.owner && userRole !== UserRoles.investorOwner)}
						onClick={() => onCertificateDelete(value)}
					>
						<Delete color="error" />
					</ToggleButton>
					<Grid item mb={2} />
				</Grid>
			),
		},
	], [navigate, setName, companyInfo, fetchData, userRole, onCertificateEdit]);

	return (
		<>
			{isLoading && (<LinearProgress color="primary" />)}
			<section style={{ paddingTop: "1rem" }}>
				<div className={clsx("container", classes.page)}>
					<Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ "> .MuiGrid-item": { p: 0 }, background: "#F4F4F4", borderRadius: "10px 10px 10px 10px", padding: "8px" }}>
						<Grid item hidden />
						<Grid item>
							<Typography variant="h5" display="flex" alignItems="center" className={classes.screenTitle}>
								{"MY COMPANY"}
							</Typography>
						</Grid>
						<Grid item xs={0} md={4} />
						<Grid item container className={classes.boxBorder} sx={{ p: 1 }} xs={12} md={4}>
							<Grid item xs={12} md={6} />
							<Grid item xs={12} md={6}>
								<Box p={1}>
									<Grid container direction="row" justifyContent="flex-end" alignItems="center">
										<Typography variant="h6">
											{"Today: "}
											<span style={{ marginLeft: "15px" }}>{(new Date()).toLocaleDateString("en-UK")}</span>
										</Typography>
									</Grid>
								</Box>
							</Grid>
						</Grid>
						<Grid item container justifyContent="end" sx={{ p: 1, marginTop: "5px" }} xs={12} md={12}>
							<Grid item xs={0} md={8} />
							<Grid item container sx={{ p: 1 }} xs={12} md={4} alignItems="center">
								{(userRole === UserRoles.owner || userRole === UserRoles.investorOwner)
									&& (
										<Grid item xs={12} md={5}>
											<Box px={1}>
												<Grid container direction="row" justifyContent="end" alignItems="center" p={0}>
													<Grid item xs={3}>
														<Typography variant="h6" fontSize="medium">{"Edit: "}</Typography>
													</Grid>
													<Grid item xs={9}>
														<Switch
															color="primary"
															size="small"
															checked={enableEditing}
															onChange={(e) => setEnableEditing(e.target.checked)}
														/>
													</Grid>
												</Grid>
											</Box>
										</Grid>
									)}
								{(userRole !== UserRoles.owner && userRole !== UserRoles.investorOwner)
									&& (
										<Grid item xs={12} md={5} />
									)}
								<Grid item xs={12} md={7}>
									<Box px={1} display="flex" flexDirection="row">
										{companyExisting
											&& (
												<Grid container direction="row" justifyContent="flex-end" alignItems="center">
													<Button
														variant="outlined"
														size="medium"
														color="primary"
														sx={{ width: "100px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
														onClick={handleLeaveCompany}
													>
														{"LEAVE"}
													</Button>
												</Grid>
											)}
										{(userRole === UserRoles.owner || userRole === UserRoles.investorOwner)
											&& (
												<Grid container direction="row" justifyContent="flex-end" alignItems="center" ml={1}>
													<Button
														variant="contained"
														size="medium"
														sx={{ width: "100px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
														disabled={!enableEditing || !dataChanged}
														onClick={onSaveCompanyInfo}
													>
														{companyExisting ? "SAVE" : "CREATE"}
													</Button>
												</Grid>
											)}
									</Box>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid container p={1} sx={{ "> .MuiGrid-item": { p: 1 } }}>
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} mb={2} bgcolor="#1d4363">
							<Typography variant="h6" color="common.white" alignItems="center">
								{"INFORMATION"}
							</Typography>
						</Grid>
						<Grid item container sx={{ p: 1 }} mb={2} justifyContent="space-between">
							<Grid item hidden />
							<Grid item md={12}>
								<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
									<Grid item md={2}>
										<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
											{"Company Name"}
										</Typography>
									</Grid>
									<Grid item container md={4} direction="row" alignItems="left">
										<TextField
											size="large"
											value={tempCompanyInfo.name}
											placeholder={defaultCompanyInfo.name}
											variant="filled"
											disabled={shouldUiBeDisabled}
											error={companyInfoError.name !== ""}
											helperText={companyInfoError.name}
											onChange={(e) => onCompanyInfoChange(e, "name")}
										/>
									</Grid>
									<Grid item md={6} />
								</Grid>

								<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
									<Grid item md={2}>
										<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
											{"Company Type"}
										</Typography>
									</Grid>
									<Grid item container md={4} direction="row" alignItems="left">
										<TextField
											size="large"
											value={tempCompanyInfo.type}
											placeholder={defaultCompanyInfo.type}
											variant="filled"
											disabled={shouldUiBeDisabled}
											error={companyInfoError.type !== ""}
											helperText={companyInfoError.type}
											onChange={(e) => onCompanyInfoChange(e, "type")}
										/>
									</Grid>
									<Grid item container justifyContent="center" md={2}>
										<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
											{"Website"}
										</Typography>
									</Grid>
									<Grid item container md={4} direction="row" alignItems="left">
										<TextField
											size="large"
											value={tempCompanyInfo.website}
											placeholder={defaultCompanyInfo.website}
											variant="filled"
											disabled={shouldUiBeDisabled}
											error={companyInfoError.website !== ""}
											helperText={companyInfoError.website}
											onChange={(e) => onCompanyInfoChange(e, "website")}
										/>
									</Grid>
								</Grid>

								<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
									<Grid item md={2}>
										<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
											{"Company Country"}
										</Typography>
									</Grid>
									<Grid item container md={4} direction="row" alignItems="left">
										<TextField
											select
											variant="filled"
											value={tempCompanyInfo.country === "" ? defaultCompanyInfo.country : tempCompanyInfo.country}
											size="large"
											inputProps={{ "aria-label": "Without label" }}
											disabled={shouldUiBeDisabled}
											error={companyInfoError.country !== ""}
											helperText={companyInfoError.country}
											onChange={(e) => onCompanyInfoChange(e, "country")}
										>
											{options.map((option) => (
												<MenuItem key={option} value={option} disabled={option === defaultCompanyInfo.country}>
													{option}
												</MenuItem>
											))}
										</TextField>
									</Grid>
									<Grid item container justifyContent="center" md={2}>
										<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
											{"City"}
										</Typography>
									</Grid>
									<Grid item container md={4} direction="row" alignItems="left">
										<TextField
											size="large"
											value={tempCompanyInfo.city}
											placeholder={defaultCompanyInfo.city}
											variant="filled"
											disabled={shouldUiBeDisabled}
											error={companyInfoError.city !== ""}
											helperText={companyInfoError.city}
											onChange={(e) => onCompanyInfoChange(e, "city")}
										/>
									</Grid>
								</Grid>

								<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
									<Grid item md={2}>
										<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
											{"Company Address"}
										</Typography>
									</Grid>
									<Grid item container md={4} direction="row" alignItems="left">
										<TextField
											size="large"
											value={tempCompanyInfo.address}
											placeholder={defaultCompanyInfo.address}
											variant="filled"
											disabled={shouldUiBeDisabled}
											error={companyInfoError.address !== ""}
											helperText={companyInfoError.address}
											onChange={(e) => onCompanyInfoChange(e, "address")}
										/>
									</Grid>
									<Grid item container justifyContent="center" md={2}>
										<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
											{"Postal Code"}
										</Typography>
									</Grid>
									<Grid item container md={4} direction="row" alignItems="left">
										<TextField
											size="large"
											value={tempCompanyInfo.postal_code}
											placeholder={defaultCompanyInfo.postal_code}
											variant="filled"
											disabled={shouldUiBeDisabled}
											error={companyInfoError.postal_code !== ""}
											helperText={companyInfoError.postal_code}
											onChange={(e) => onCompanyInfoChange(e, "postal_code")}
										/>
									</Grid>
								</Grid>

								<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
									<Grid item md={2}>
										<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
											{"Company VAT"}
										</Typography>
									</Grid>
									<Grid item container md={4} direction="row" alignItems="left">
										<TextField
											size="large"
											value={tempCompanyInfo.vat_number}
											placeholder={defaultCompanyInfo.vat_number}
											variant="filled"
											disabled={shouldUiBeDisabled}
											error={companyInfoError.vat_number !== ""}
											helperText={companyInfoError.vat_number}
											onChange={(e) => onCompanyInfoChange(e, "vat_number")}
										/>
									</Grid>
									<Grid item container justifyContent="center" md={2}>
										<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
											{"Telephone"}
										</Typography>
									</Grid>
									<Grid item container md={4} direction="row" alignItems="left">
										<TextField
											size="large"
											value={tempCompanyInfo.telephone}
											placeholder={defaultCompanyInfo.telephone}
											variant="filled"
											disabled={shouldUiBeDisabled}
											error={companyInfoError.telephone !== ""}
											helperText={companyInfoError.telephone}
											onChange={(e) => onCompanyInfoChange(e, "telephone")}
										/>
									</Grid>
								</Grid>

								{(userRole === "investor_owner" || userRole === "investor_member")
									&& (
										<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
											<Grid item md={2}>
												<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
													{"Active Countries"}
												</Typography>
											</Grid>
											<Grid item container md={4} direction="row" alignItems="left">
												<FormControl variant="filled" sx={{ width: "430px" }}>
													{tempCompanyInfo.active_countries.length === 0 && (<InputLabel id="demo-simple-select-label" sx={{ color: "black", marginLeft: "5px", marginTop: "-3px", opacity: 0.8 }}>{"Active Countries"}</InputLabel>)}
													<Select
														hiddenLabel
														multiple
														value={tempCompanyInfo.active_countries}
														renderValue={() => tempCompanyInfo.active_countries.join(", ")}
														disabled={shouldUiBeDisabled}
														inputProps={{ "aria-label": "Active Countries" }}
														onChange={(event) => handleChangeOptions("active_countries", options, event)}
													>
														<MenuItem
															value="all"
															classes={{
																root: isAllCountriesSelected ? classes.selectedAll : "",
															}}
														>
															<ListItemIcon>
																<Checkbox
																	classes={{ indeterminate: classes.indeterminateColor }}
																	checked={isAllCountriesSelected}
																	indeterminate={
																		// eslint-disable-next-line max-len
																		tempCompanyInfo.active_countries?.length > 0 && tempCompanyInfo.active_countries?.length < options.length
																	}
																/>
															</ListItemIcon>
															<ListItemText
																classes={{ primary: classes.selectAllText }}
																primary="Select All"
															/>
														</MenuItem>
														{options.map((countries) => (
															<MenuItem key={countries} value={countries}>
																<ListItemIcon>
																	<Checkbox checked={tempCompanyInfo.active_countries?.includes(countries)} />
																</ListItemIcon>
																<ListItemText primary={countries} />
															</MenuItem>
														))}
													</Select>
													<FormHelperText id="my-helper-text" error={companyInfoError.active_countries !== ""}>
														<Typography variant="h8" sx={{ color: "#FA7D63", width: "380px" }}>
															{companyInfoError.active_countries === "" ? "" : companyInfoError.active_countries}
														</Typography>
													</FormHelperText>
												</FormControl>
											</Grid>
											<Grid item container justifyContent="center" md={2}>
												<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
													{"Technologies"}
												</Typography>
											</Grid>
											<Grid item container md={4} direction="row" alignItems="left">
												<FormControl variant="filled" sx={{ width: "430px" }}>
													{tempCompanyInfo.technologies?.length === 0 && (<InputLabel id="demo-simple-select-label" sx={{ color: "black", marginLeft: "5px", marginTop: "-3px", opacity: 0.8 }}>{"Technologies"}</InputLabel>)}
													<Select
														hiddenLabel
														multiple
														value={tempCompanyInfo.technologies}
														disabled={shouldUiBeDisabled}
														renderValue={() => tempCompanyInfo.technologies?.join(", ")}
														inputProps={{ "aria-label": "Technologies" }}
														onChange={(event) => handleChangeOptions("technologies", projectTaxonomies, event)}
													>
														<MenuItem
															value="all"
															classes={{
																root: isAllTechnologiesSelected ? classes.selectedAll : "",
															}}
														>
															<ListItemIcon>
																<Checkbox
																	classes={{ indeterminate: classes.indeterminateColor }}
																	checked={isAllTechnologiesSelected}
																	indeterminate={
																		// eslint-disable-next-line max-len
																		tempCompanyInfo.technologies?.length > 0 && tempCompanyInfo.technologies?.length < projectTaxonomies.length
																	}
																/>
															</ListItemIcon>
															<ListItemText
																classes={{ primary: classes.selectAllText }}
																primary="Select All"
															/>
														</MenuItem>
														{projectTaxonomies.map((taxonomy) => (
															<MenuItem key={taxonomy} value={taxonomy}>
																<ListItemIcon>
																	<Checkbox checked={tempCompanyInfo.technologies?.includes(taxonomy)} />
																</ListItemIcon>
																<ListItemText primary={taxonomy} />
															</MenuItem>
														))}
													</Select>
													<FormHelperText id="my-helper-text" error={companyInfoError.technologies !== ""}>
														<Typography variant="h8" sx={{ color: "#FA7D63", width: "380px" }}>
															{companyInfoError.technologies === "" ? "" : companyInfoError.technologies}
														</Typography>
													</FormHelperText>
												</FormControl>
											</Grid>
										</Grid>
									)}

								{(userRole === "investor_owner" || userRole === "investor_member")
									&& (
										<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
											<Grid item md={2}>
												<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
													{"Minimum Project Size"}
												</Typography>
											</Grid>
											<Grid item container md={4} direction="row" alignItems="left">
												<TextField
													size="large"
													type="number"
													value={tempCompanyInfo.minimum_project_size}
													variant="filled"
													disabled={shouldUiBeDisabled}
													placeholder="Project size"
													error={companyInfoError.minimum_project_size !== ""}
													helperText={companyInfoError.minimum_project_size}
													onChange={(e) => onCompanyInfoChange(e, "minimum_project_size")}
												/>
											</Grid>
											<Grid item md={6} />
										</Grid>
									)}

								<Grid item container sx={{ p: 1 }} mt={1} alignItems="center">
									<Grid item md={2}>
										<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
											{"Company Description"}
										</Typography>
									</Grid>
									<Grid item container md={10} direction="row" alignItems="left">
										<TextField
											size="large"
											value={tempCompanyInfo.description}
											placeholder={defaultCompanyInfo.description}
											variant="filled"
											disabled={shouldUiBeDisabled}
											error={companyInfoError.description !== ""}
											helperText={companyInfoError.description}
											onChange={(e) => onCompanyInfoChange(e, "description")}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid container p={1} sx={{ "> .MuiGrid-item": { p: 1 } }}>
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mb={3} bgcolor="#1d4363">
							<Typography variant="h6" color="common.white" alignItems="center">
								{(userRole === UserRoles.owner || userRole === UserRoles.investorOwner) ? "ACTIONS" : "OVERVIEW"}
							</Typography>
						</Grid>
						{(userRole === UserRoles.owner)
							&& (
								<Grid item container sx={{ p: 1 }} mb={1} justifyContent="space-around" alignContent="center">
									<Grid item container justifyContent="start" md={4}>
										<Button
											startIcon={<Edit />}
											variant="outlined"
											size="large"
											sx={{
												borderColor: "#51BEA5",
												backgroundColor: "white",
												color: "#51BEA5",
												":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" },
												px: 5,
												py: 1,
												width: "400px",
												height: "50px",
											}}
											disabled={!companyExisting}
											onClick={() => navigate("/company/company-output")}
										>
											{"Company financial Assessment"}
										</Button>
									</Grid>
									<Grid item container justifyContent="center" md={4}>
										<Button
											startIcon={<Edit />}
											variant="outlined"
											size="large"
											sx={{
												borderColor: "#51BEA5",
												backgroundColor: "white",
												color: "#51BEA5",
												":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" },
												px: 5,
												py: 1,
												width: "400px",
												height: "50px",
											}}
											disabled={!companyExisting}
											onClick={() => navigate("/company/company-kyc")}
										>
											{"Company Kyc"}
										</Button>
									</Grid>
									<Grid item container justifyContent="end" md={4}>
										<Button
											startIcon={<Edit />}
											variant="outlined"
											size="large"
											sx={{
												borderColor: "#51BEA5",
												backgroundColor: "white",
												color: "#51BEA5",
												":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" },
												px: 5,
												py: 1,
												width: "400px",
												height: "50px",
											}}
											disabled={!companyExisting}
											onClick={() => navigate("/company/company-documents")}
										>
											{"Company Documents"}
										</Button>
									</Grid>
								</Grid>
							)}

						<Grid item container sx={{ p: 1 }} mb={2} mt={userRole === UserRoles.owner ? 8 : 1} justifyContent="space-between" alignContent="center">
							<Grid item container justifyContent="start" alignContent="center" md={6}>
								<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
									{"Company Users"}
								</Typography>
							</Grid>
							{(userRole === UserRoles.owner || userRole === UserRoles.investorOwner)
								&& (
									<Grid item container justifyContent="end" md={6}>
										<Button
											startIcon={<Edit />}
											variant="contained"
											size="large"
											sx={{
												backgroundColor: "#51BEA5",
												color: "common.white",
												":hover": { backgroundColor: "#3d8f7c" },
												px: 5,
												py: 1,
												width: "250px",
												height: "40px",
											}}
											disabled={!companyExisting || (userRole !== UserRoles.owner && userRole !== UserRoles.investorOwner)}
											onClick={handleAddUsers}
										>
											{"Invite new user"}
										</Button>
									</Grid>
								)}
						</Grid>

						<Grid item container mb={2} className={classes.page}>
							<Table
								data={companyUsers}
								noDataText={isLoading ? "Fetching projects..." : "No data available!"}
								columns={userColumns}
								defaultSorted={[{ id: "name", desc: true }]}
								className={classes.page}
							/>
						</Grid>

						<Grid item container sx={{ p: 1 }} mb={2} justifyContent="space-between" alignContent="center">
							<Grid item container justifyContent="start" alignContent="center" md={6}>
								<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
									{"Certifications"}
								</Typography>
							</Grid>
							{(userRole === UserRoles.owner || userRole === UserRoles.investorOwner)
								&& (
									<Grid item container justifyContent="end" md={6}>
										<Button
											startIcon={<Edit />}
											variant="contained"
											size="large"
											sx={{
												backgroundColor: "#51BEA5",
												color: "common.white",
												":hover": { backgroundColor: "#3d8f7c" },
												px: 5,
												py: 1,
												width: "250px",
												height: "40px",
											}}
											disabled={!companyExisting || (userRole !== UserRoles.owner && userRole !== UserRoles.investorOwner)}
											onClick={handleAddCertificate}
										>
											{"Add Certification"}
										</Button>
									</Grid>
								)}
						</Grid>
						<Grid item container mb={2} className={classes.page}>
							<Table
								data={companyInfo.certifications}
								noDataText={isLoading ? "Fetching certificates..." : "No data available!"}
								columns={certColumns}
								defaultSorted={[{ id: "name", desc: true }]}
								className={classes.page}
							/>
						</Grid>
					</Grid>
				</div>
			</section>

			<Dialog
				fullWidth
				keepMounted
				open={addCertificate}
				TransitionComponent={Transition}
				scroll="body"
				maxWidth="md"
				PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4" }, style: { overflowX: "hidden" } }}
			>
				<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
					<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
						<b>{editCertificate === true ? "Edit Certificate" : "Add a New Certificate"}</b>
					</Typography>
				</DialogTitle>
				<DialogContent dividers sx={{ overflowY: "hidden" }}>
					<Grid item container sx={{ p: 1 }} alignItems="center">
						<Grid item xs={12} md={4} p={1}>
							<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
								{"Name"}
							</Typography>
						</Grid>
						<Grid item container xs={12} sm={5} md={8} direction="row" alignItems="center">
							<TextField
								className={classes.textFieldStyle}
								size="large"
								value={tempCertificate.name}
								variant="filled"
								error={(companyFieldFieldError.length > 0 && companyFieldFieldError.includes("cert_name"))}
								helperText={(companyFieldFieldError.length > 0 && companyFieldFieldError.includes("cert_name")) ? "This field cannot be empty!" : ""}
								onChange={(e) => onCertificateValueChange(e, "name")}
							/>
						</Grid>

						<Grid item xs={12} md={4} p={1}>
							<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
								{"Description"}
							</Typography>
						</Grid>
						<Grid item container xs={12} sm={5} md={8} direction="row" alignItems="center">
							<TextField
								className={classes.textFieldStyle}
								size="large"
								value={tempCertificate.description}
								variant="filled"
								onChange={(e) => onCertificateValueChange(e, "description")}
							/>
						</Grid>

						<Grid item xs={12} md={4} p={1}>
							<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
								{"Upload File"}
							</Typography>
						</Grid>
						<Grid item container xs={12} sm={5} md={8} direction="row" alignItems="center">
							<CertificateUpload
								companyId={companyInfo.id}
								variableComponent="certifications"
								projectId="null"
								component="toggle"
								oldFile={tempCertificate.file.save_name}
								disabled={tempCertificate.name === "" || tempCertificate.description === ""}
								onSuccess={handleUploadSuccess}
							/>
							{tempCertificate.name
							&& (
								<ToggleButton
									value="delete"
									title="delete"
									aria-label="delete"
									sx={{ borderColor: "primary.main" }}
									onClick={() => setDeleteCertificateFileDialog(true)}
								>
									<Delete color="error" />
								</ToggleButton>
							)}
							<Grid item md={1} />
							{tempCertificate.file.save_name && currentFile !== undefined
								&& (
									<Link href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${companyInfo.id}/${currentFile}?token=${jwt.getToken()}`} target="_blank">
										{`Open ${currentFile || "Download file"}`}
									</Link>
								)}
							{/* {!projectInfo.management_doc_1.save_name
								&& (
									<Typography color="grey.dark">{"No uploaded file"}</Typography>
								)} */}
						</Grid>
					</Grid>
					{/* </Grid> */}
					<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="25px" padding="0px 10px 0px 15px">
						<Grid item sm={3} display="flex" justifyContent="start">
							<Button
								fullWidth
								variant="outlined"
								sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" } }}
								color="primary"
								onClick={onCancelAddCerticate}
							>
								{"Cancel"}
							</Button>
						</Grid>
						<Grid item display="flex" sm={3} justifyContent="end">
							<Button
								fullWidth
								variant="contained"
								sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" } }}
								color="primary"
								onClick={onSaveCertificate}
							>
								{"Save & Close"}
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>

			<Dialog
				fullWidth
				keepMounted
				open={addEditCompanyUser !== ""}
				TransitionComponent={Transition}
				scroll="body"
				maxWidth="md"
				PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4" }, style: { overflowX: "hidden" } }}
				onClose={onCancelUserInvite}
			>
				<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
					<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
						<b>{addEditCompanyUser === "add" ? "Invite new user" : "Edit User"}</b>
					</Typography>
				</DialogTitle>
				<DialogContent dividers sx={{ overflowY: "hidden" }}>
					<Grid item container sx={{ p: 1 }} alignItems="center">
						{addEditCompanyUser === "add"
							&& (
								<>
									<Grid item xs={12} md={4} p={1}>
										<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
											{"User email"}
										</Typography>
									</Grid>
									<Grid item container xs={12} sm={5} md={8} mb={2} direction="row" alignItems="center">
										<TextField
											className={classes.textFieldStyle}
											size="large"
											value={tempUser.user_email}
											variant="filled"
											error={(companyFieldFieldError.length > 0 && companyFieldFieldError.includes("user_email"))}
											helperText={(companyFieldFieldError.length > 0 && companyFieldFieldError.includes("user_email")) ? "Invalid email address format." : ""}
											onChange={(e) => onUserValueChange(e, "user_email")}
										/>
									</Grid>
								</>
							)}
						<Grid item xs={12} md={4} p={1}>
							<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
								{"User type"}
								<Tooltip placement="bottom-start" title="An owner can access everything within your account, whereas a member can only access the projects you assign to them.">
									<InfoIcon fontSize="small" sx={{ padding: 0.2, color: "green.main" }} />
								</Tooltip>
							</Typography>
						</Grid>
						<Grid item container xs={12} sm={5} md={8} sx={{ padding: "5px" }} direction="row" alignItems="center">
							<ToggleButtonGroup
								exclusive
								color="primary"
								value={companyUserRole}
								aria-label="Platform"
								className={classes.toggles}
								onChange={onSetCompanyUserRole}
							>
								<ToggleButton sx={{ width: "274px" }} value="member">{"Member"}</ToggleButton>
								<ToggleButton sx={{ width: "274px" }} value="owner">{"Owner"}</ToggleButton>
							</ToggleButtonGroup>
						</Grid>
					</Grid>
					<Grid container mt={1} alignItems="center" justifyContent="space-between" marginTop="25px" padding="0px 10px 0px 15px">
						<Grid item sm={3} display="flex" justifyContent="start">
							<Button
								fullWidth
								variant="outlined"
								sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" } }}
								color="primary"
								onClick={onCancelUserInvite}
							>
								{"Cancel"}
							</Button>
						</Grid>
						<Grid item display="flex" sm={3} justifyContent="end">
							<Button
								fullWidth
								variant="contained"
								sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" } }}
								color="primary"
								onClick={onSaveUserInvite}
							>
								{addEditCompanyUser === "add" ? "Invite" : "Update"}
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>

			<Dialog
				keepMounted
				open={leaveCompanyDialog}
				TransitionComponent={Transition}
				scroll="body"
				maxWidth="md"
				PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4" } }}
			>
				<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
					<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
						{"Are you sure?"}
					</Typography>
				</DialogTitle>
				<DialogContent dividers sx={{ overflowY: "hidden" }}>
					<Grid item container sx={{ p: 1, marginTop: "5px" }} mt={1} alignItems="center">
						{"Are you sure you want to"}
						&nbsp;
						<b>{"leave"}</b>
						&nbsp;
						{"from the company"}
						&nbsp;
						<b>{companyInfo.info.name}</b>
						{"?"}
					</Grid>
					<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="25px">
						<Grid item sm={3} display="flex" justifyContent="start">
							<Button
								fullWidth
								variant="outlined"
								sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
								color="primary"
								onClick={() => {
									setLeaveCompanyDialog(false);
								}}
							>
								{"Stay"}
							</Button>
						</Grid>
						<Grid item display="flex" sm={3} justifyContent="end">
							<Button
								fullWidth
								variant="contained"
								sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
								color="primary"
								onClick={onLeaveDeleteCompany}
							>
								{"Leave"}
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>

			<Dialog
				keepMounted
				open={deleteUserDialog}
				TransitionComponent={Transition}
				scroll="body"
				maxWidth="md"
				PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4" } }}
				onClose={() => setDeleteUserDialog(false)}
			>
				<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
					<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
						{"Are you sure?"}
					</Typography>
				</DialogTitle>
				<DialogContent dividers sx={{ overflowY: "hidden" }}>
					<Grid item container sx={{ p: 1, marginTop: "5px" }} mt={1} alignItems="center">
						{"Are you sure you want to remove"}
						&nbsp;
						<b>{selectedUserToDelete}</b>
						&nbsp;
						{"from the company"}
						&nbsp;
						<b>{companyInfo.info.name}</b>
						{"?"}
					</Grid>
					<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="25px">
						<Grid item sm={3} display="flex" justifyContent="start">
							<Button
								fullWidth
								variant="outlined"
								sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
								color="primary"
								onClick={() => setDeleteUserDialog(false)}
							>
								{"Cancel"}
							</Button>
						</Grid>
						<Grid item display="flex" sm={3} justifyContent="end">
							<Button
								fullWidth
								variant="contained"
								sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
								color="primary"
								onClick={onCompanyUserDelete}
							>
								{"Remove"}
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>

			<Dialog
				keepMounted
				open={deleteCertificateFileDialog}
				TransitionComponent={Transition}
				scroll="body"
				maxWidth="md"
				PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4" } }}
				onClose={() => setDeleteCertificateFileDialog(false)}
			>
				<DialogContent dividers sx={{ overflowY: "hidden" }}>
					<Grid item container sx={{ p: 1, marginTop: "5px" }} mt={1} alignItems="center">
						{"Are you sure you want to remove"}
						&nbsp;
						<b>{ tempCertificate.file.original_name }</b>
						&nbsp;
						{ " from " }
						&nbsp;
						<b>{ tempCertificate.name }</b>
						{"?"}
					</Grid>
					<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="25px">
						<Grid item sm={3} display="flex" justifyContent="start">
							<Button
								fullWidth
								variant="outlined"
								sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
								color="primary"
								onClick={() => setDeleteCertificateFileDialog(false)}
							>
								{"Cancel"}
							</Button>
						</Grid>
						<Grid item display="flex" sm={3} justifyContent="end">
							<Button
								fullWidth
								variant="contained"
								sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
								color="primary"
								onClick={onDeleteCertificateFile}
							>
								{"Remove"}
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default memo(CompanyProfile);
