import { memo, useEffect, useState } from "react";
import { Grid, Chip, ToggleButton, Typography, Link } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";
import { getIn } from "formik";

import { uploadFile, reUploadFile, deleteFile } from "../../api";
import { jwt, useSnackbar } from "../../utils";

import CustomDialog from "./CustomDialog";

const { REACT_APP_MAIN_SERVER_URL } = process.env;

const CustomFileUpload = ({
	disabled = false,
	pid = "",
	cf = false,
	fileTool = "",
	variable = "",
	viewOnly = false,
	formik,
	onSuccess,
}) => {
	const { success, error } = useSnackbar();

	const [projectId, setProjectId] = useState(null);

	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

	const [errors, setErrors] = useState(false);

	const [data, setData] = useState({
		save_name: "",
		original_name: "",
	});

	// eslint-disable-next-line no-unused-vars
	const [fileId, setFileId] = useState(variable.split(".").at(-1));
	const onClickLink = () => {
		if (data?.save_name) {
			window.open(`${REACT_APP_MAIN_SERVER_URL}/uploads/${pid}/${data.save_name}?token=${jwt.getToken()}`);
		} else {
			error("Error: File name does not exist.");
		}
	};

	const UploadFile = () => {
		const fileInput = document.querySelector(`#file_input_${pid}_${fileTool}_${fileId}`);
		fileInput.click();
	};

	const onFileUpload = async (e) => {
		const file = e.target.files[0];
		const { name: nameOfFile } = file;

		const specialCharacters = /["*/:<>?\\|]/;

		if (specialCharacters.test(nameOfFile.split(".pdf")[0])) {
			error("File name should not contain any special character.");
		} else {
			const formData = new FormData();
			formData.append("projectId", projectId);
			formData.append("component", fileTool);
			formData.append("variable", fileId);
			if (data?.save_name) formData.append("oldFile", data?.save_name);
			formData.append("pdf", file);

			const response = await ((data?.save_name) ? reUploadFile(formData) : uploadFile(formData));
			if (response.success) {
				success("File uploaded successfully");
				onSuccess();
			} else {
				error("There was a problem uploading the file");
			}
		}
	};

	const onDelete = async () => {
		// console.log(projectId)
		try {
			// eslint-disable-next-line max-len
			const { success: deleteSuccess } = await deleteFile(cf ? { projectid: undefined, component: fileTool, variable: fileId } : { projectid: projectId, component: fileTool, variable: fileId });

			if (deleteSuccess) {
				success("File deleted successfully");
				onSuccess();
			} else {
				error(fileId);
			}

			formik.setFieldValue(
				variable,
				{
					save_name: "",
					original_name: "",
				},
			);
		} catch (_error) {
			console.log("here", _error);
		}
	};

	useEffect(() => {
		const newName = data;
		if (!newName) {
			setErrors(true);
			return;
		}

		const { save_name: saveName, original_name: originalName } = data;
		if (saveName === undefined || originalName === undefined) {
			setErrors(true);
			return;
		}

		formik.setFieldValue(
			variable,
			{
				save_name: saveName,
				original_name: originalName,
			},
		);

		setErrors(false);
	}, [variable]);

	const renderDialog = () => (
		<Typography variant="h6" sx={{ textAlign: "justify", color: "#1d4363" }}>
			{"Are you sure you want to"}
			&nbsp;
			<b>{"Delete"}</b>
			&nbsp;
			{"the file"}
			&nbsp;
			<Link href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${pid}/${data.save_name}?token=${jwt.getToken()}`} target="_blank">
				<b>{`${REACT_APP_MAIN_SERVER_URL}/uploads/${pid}/${data.save_name}} ?`}</b>
			</Link>
		</Typography>
	);

	useEffect(() => {
		const url = window.location.pathname;
		const urlPathArray = url.split("/");

		if ((urlPathArray.length >= 2) && (urlPathArray[1] !== "company")) {
			setProjectId(urlPathArray[2]);
		}

		if (pid !== projectId) {
			setProjectId(pid);
		}

		if (cf) {
			// console.log("flag")
			setProjectId(null);
		}
	}, []);
	// console.log(pid)
	// console.log("projectidinfileupload:", projectId)

	useEffect(() => {
		setData(getIn(formik.values, variable));
	}, [formik.values]);
	// console.log(data)

	return (
		<>
			<Grid item container md={12} justifyContent="end" alignItems="center">
				<Chip
					label={errors ? "Warning: Out of use" : (data?.original_name || "No uploaded file")}
					sx={{
						width: `${viewOnly ? "100%" : "70%"}`,
						height: "55px",
						fontSize: "15px",
						borderRadius: "5px 0px 0px 5px",
						borderColor: errors ? "#D89D2A" : "primary.main",
						color: errors ? "#D89D2A" : "primary.main",
						"&.Mui-disabled": {
							opacity: "0.8",
						},
						"&:hover": {
							fontWeight: "bold",
						},
					}}
					variant="outlined"
					disabled={errors || data?.save_name === ""}
					onClick={onClickLink}
				/>
				{!viewOnly
					&& (
						<>
							<ToggleButton
								value="edit info"
								title="Delete File"
								aria-label="edit info"
								size="small"
								disabled={disabled || data?.save_name === ""}
								sx={{
									width: "15%",
									height: "55px",
									borderColor: "primary.main",
									borderRadius: "0px 0px 0px 0px",
									"&:hover": {
										backgroundColor: "#F7E1E0",
									},
									transition: "width 0.4s",
								}}
								onClick={() => setDeleteDialogOpen(true)}
							>
								<DeleteIcon color={data?.save_name === "" ? "neutral" : "error"} />
							</ToggleButton>
							<ToggleButton
								label="edit info"
								value="edit info"
								title="Upload File"
								aria-label="left aligned"
								size="small"
								disabled={disabled}
								sx={{
									width: "15%",
									height: "55px",
									borderColor: "primary.main",
									borderRadius: "0px 5px 5px 0px",
									"&:hover": {
										backgroundColor: "#E0E8F7",
									},
									transition: "width 0.4s",
								}}
								onClick={() => UploadFile()}
							>
								<UploadFileIcon color="primary" />
							</ToggleButton>
						</>
					)}
			</Grid>

			<CustomDialog
				open={deleteDialogOpen}
				content={renderDialog()}
				title="Are you sure?"
				proceedText="Delete"
				declineText="Cancel"
				onClose={() => setDeleteDialogOpen(false)}
				onChange={() => onDelete()}
			/>

			<input
				id={`file_input_${pid}_${fileTool}_${fileId}`}
				type="file"
				accept="application/pdf"
				style={{ display: "none", position: "relative", zIndex: "2" }}
				onChange={onFileUpload}
				onClick={(event) => { event.target.value = null; }}
			/>
		</>
	);
};

CustomFileUpload.propTypes = {
	fileTool: PropTypes.string.isRequired,
	pid: PropTypes.string,
	cf: PropTypes.bool,
	variable: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	viewOnly: PropTypes.bool,
	formik: PropTypes.any.isRequired,
	onSuccess: PropTypes.func.isRequired,
};

export default memo(CustomFileUpload);
