import { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	Grid,
	LinearProgress,
	Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { httpResponseIsValid, useSnackbar } from "../../utils";
import usePrompt from "../../utils/use-blocker";
import {
	MainMenu,
	LabelTextField,
	LabelFileUpload,
} from "../../components/basic/index";
import {
	getProjectLoanApplicationDetailedEEInformation,
	updateProjectLoanApplicationDetailedEEInformation,
} from "../../api";

const DetailedEEInformation = ({ disableAccess = true, isProjectDeveloper = false, onChangeProjectState }) => {
	const { projectid } = useParams();

	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(disableAccess);
	const [isLoading, setIsLoading] = useState(false);

	const [project, setProject] = useState({
		name: "",
		status: "-",
		createdAt: "-",
		data: {
			project_risk_assessment: "",
		},
		docs: {
			detailed_ee_information_doc_1: {
				original_name: "",
				save_name: "",
			},
		},
	});

	const [enableEditing, setEnableEditing] = useState(false);
	const { success, error } = useSnackbar();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getProjectLoanApplicationDetailedEEInformation(projectid);
				if (httpResponseIsValid(response)) {
					setProject(response.project);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				console.log(`Error occured when fetching loan's appplication project finance data: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, projectid],
	);

	const handleSubmit = async (values) => {
		setIsLoading(true);

		try {
			const response = await updateProjectLoanApplicationDetailedEEInformation(projectid, values);
			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			console.log(`Error occured when updating loan's appplication project finance data: ${_error}`);
		}

		setIsLoading(false);
	};

	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: project,
		onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	});

	const { dirty } = formik;

	usePrompt("You have unsaved changes which will be lost. Are you sure you want to leave the page?", dirty);

	useEffect(() => {
		setShouldUiBeDisabled(disableAccess || !enableEditing);
	}, [disableAccess, enableEditing]);

	useEffect(() => {
		formik.resetForm({ values: project, dirty: false });
	}, [project]);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData, projectid]);

	return (
		<form onSubmit={formik.handleSubmit}>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<MainMenu
				title={t("LoanApplication.DetailedEEInformation.title")}
				dirty={dirty}
				project={project}
				isDeveloper={isProjectDeveloper}
				disabled={disableAccess}
				onFetch={fetchData}
				onEdit={setEnableEditing}
				onChange={onChangeProjectState}
			/>

			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "105px" }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("LoanApplication.DetailedEEInformation.DetailedEEInformation.title")}
					</Typography>
				</Grid>

				<LabelFileUpload
					viewOnly
					fieldId="docs.detailed_ee_information_doc_1"
					projectId={projectid}
					label={t("LoanApplication.DetailedEEInformation.DetailedEEInformation.doc1")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="documents"
					onSuccess={() => fetchData()}
				/>

				<Grid item container sx={{ p: 1 }} mt={1} alignItems="center" justifyContent="space-between">
					<Grid item xs={12} md={12}>
						<Typography variant="h6" sx={{ p: 1 }}>
							{t("LoanApplication.DetailedEEInformation.DetailedEEInformation.textMain")}
						</Typography>
					</Grid>
					<Grid item xs={12} md={12}>
						<Typography variant="h6" sx={{ p: 3 }}>
							<ui>
								<li>{t("LoanApplication.DetailedEEInformation.DetailedEEInformation.text1")}</li>
								<li>{t("LoanApplication.DetailedEEInformation.DetailedEEInformation.text2")}</li>
								<li>{t("LoanApplication.DetailedEEInformation.DetailedEEInformation.text3")}</li>
								<li>{t("LoanApplication.DetailedEEInformation.DetailedEEInformation.text4")}</li>
								<li>{t("LoanApplication.DetailedEEInformation.DetailedEEInformation.text5")}</li>
								<li>{t("LoanApplication.DetailedEEInformation.DetailedEEInformation.text6")}</li>
								<li>{t("LoanApplication.DetailedEEInformation.DetailedEEInformation.text7")}</li>
								<li>{t("LoanApplication.DetailedEEInformation.DetailedEEInformation.text8")}</li>
								<li>{t("LoanApplication.DetailedEEInformation.DetailedEEInformation.text9")}</li>
								<li>{t("LoanApplication.DetailedEEInformation.DetailedEEInformation.text10")}</li>
							</ui>
						</Typography>
					</Grid>
				</Grid>

				<LabelTextField
					id="data.project_risk_assessment"
					label={t("LoanApplication.DetailedEEInformation.DetailedEEInformation.projectRiskAssessment")}
					size="large"
					placeholder="Answer"
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>
			</Grid>
		</form>
	);
};

export default memo(DetailedEEInformation);
