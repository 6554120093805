import { useCallback, memo, useMemo, useState, useEffect, forwardRef, useRef } from "react";
import { useParams } from "react-router-dom";
import { Button, Typography, Grid, LinearProgress, Box, FormControl, Switch, Select, MenuItem, FormHelperText, ToggleButton, Dialog, ToggleButtonGroup, DialogTitle, Paper, Chip, DialogContent, Autocomplete, TextField, Slide, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { Edit, Delete } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import { stringAToZInsensitive } from "@iamnapo/sort";
import * as Yup from "yup";

import { isFuzzyMatch, useSnackbar, httpResponseIsValid } from "../../utils";
import Table from "../../components/Table";
import { getProjectUsers, getUserProfile, getCompanyUsers, getCompanyInvitedUsers, getInvestorProjectUsers, updateProjectUsers, deleteProjectUsers, getAdminProjectUsers, getProject, addMaterialExpert } from "../../api";
import StatusDialog from "../../components/StatusDialog";

const Transition = forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const useStyles = makeStyles((theme) => ({
	header: {
		color: theme.palette.green.main,
	},
	boxBorder: {
		border: `2px solid ${theme.palette.primary.main}`,
		borderRadius: theme.spacing(2),
		backgroundColor: "white",
	},
	screenTitle: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		letterSpacing: theme.spacing(0.2),
	},
	page: {
		height: "100%",
		display: "flex",
		overflowX: "scroll",
		flexDirection: "column",
		justifyContent: "center",
	},
	form: {
		width: "420px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "start",
	},
	toggles: {
		"& .Mui-selected": {
			color: "#ffffff !important",
			backgroundColor: "#6F96C6 !important",
			border: "2px solid #7DA1D1",
		},
		"& .MuiToggleButton-root": {
			height: "40px",
			"&:hover": {
				color: "#1d4363",
				backgroundColor: "#F0F0F0",
			},
			color: "#1d4363",
			backgroundColor: "#ffffff",
			borderRadius: "10px",
		},
	},
}));

const UserAccessTypes = {
	none: { name: "Role...", value: "Role.." },
	project_developer: { name: "Project Developer", value: "ProjectDeveloper" },
	material_expert: { name: "Material Expert", value: "MaterialExpert" },
	tool_expert: { name: "Tool Expert", value: "ToolExpert" },
};

const AccessTypes = {
	contract: "Contract",
	financial_assessment: "Financial",
	risk_assessment_protocol: "Risk",
	loan_application: "Loan",
	kyc: "KYC",
	esg: "ESG",
	equad: "eQuad",
};

const userRoleValueToName = (value) => {
	switch (value) {
		case "ProjectDeveloper":
			return "Project Developer";
		case "MaterialExpert":
			return "Material Expert";
		case "ToolExpert":
			return "Tool Expert";
		default:
			break;
	}

	return "Unknown";
};

const userStatusValueToName = (value) => {
	switch (value) {
		case "active":
			return "Company Active";
		case "company_invited":
			return "Company Invited";
		case "external_invited":
			return "External Invited";
		case "external_active":
			return "External Active";
		default:
			break;
	}

	return "Unknown";
};

const Projects = ({ disableAccess = true, role = "member", isProjectDeveloper = false, onChangeProjectState }) => {
	const { projectid } = useParams();
	const [isLoading, setIsLoading] = useState(true);
	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(disableAccess);
	const [projectStateDialog, setProjectStateDialog] = useState(false);
	const [userRole, setUserRole] = useState(role);
	const [userEmail, setUserEmail] = useState("");
	const [projectUsers, setProjectUsers] = useState([]);
	const [projectInfo, setProjectInfo] = useState({});
	const [deleteModal, setDeleteModal] = useState(null);
	const [userType, setUserType] = useState("member");

	const [userInviteDialogOpen, setUserInviteDialogOpen] = useState(false);
	const [editProjectMember, setEditProjectMember] = useState(false);
	const [userAccessType, setUserAccessType] = useState(UserAccessTypes.none.value);
	const [selectedUserToAdd, setSelectedUserToAdd] = useState("");
	const [toolOptions, setToolOptions] = useState({
		contract: false,
		financial_assessment: false,
		risk_assessment_protocol: false,
		loan_application: false,
		kyc: false,
		esg: false,
		equad: false,
	});

	const [userErrors, setUserErrors] = useState({
		autocomplete: "",
		access_type: "",
		edit_access: "",
	});

	const [usersToInvite, setUsersToInvite] = useState([]);

	const { error, success } = useSnackbar();

	const classes = useStyles();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			let response;
			try {
				switch (userRole) {
					case "admin":
						response = await getAdminProjectUsers(projectid);
						break;
					case "investor":
						response = await getInvestorProjectUsers(projectid);
						break;
					default:
						response = await getProjectUsers(projectid);
						break;
				}

				if (httpResponseIsValid(response)) {
					setProjectUsers(response.members);
				} else {
					error(response?.message);
				}

				response = await getUserProfile();
				if (httpResponseIsValid(response)) {
					setUserEmail(response?.email);
				} else {
					error(response?.message);
				}

				response = await getProject(projectid);
				if (httpResponseIsValid(response)) {
					setProjectInfo(response);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				console.log(`Error occured when fetching user's profile: ${_error}`);
			}

			setIsLoading(false);
		}, [error, userRole, projectid],
	);

	const editDisabled = (value) => {
		const user = projectUsers.find((u) => u.email === value);

		if (userRole === "investor" || userRole === "admin") {
			return true;
		}

		if (user.company_role === "owner") {
			return true;
		}

		return user?.status === "external_active" || user?.status === "external_invited" ? true : (value === userEmail || !userEmail);
	};

	const deleteDisabled = (value) => {
		const user = projectUsers.find((u) => u.email === value);

		if (userRole === "investor" || userRole === "admin") {
			return true;
		}

		if (user.company_role === "owner") {
			return true;
		}

		return (value === userEmail || !userEmail);
	};

	const handleToolOptionChange = (name) => {
		setToolOptions({
			...toolOptions,
			[name]: !toolOptions[name],
		});
	};

	const handleAccessTypeSelect = (event) => {
		setUserAccessType(event.target.value);

		setUserErrors({
			...userErrors,
			access_type: "",
		});
	};

	const openInviteUserDialog = async () => {
		setIsLoading(true);

		let response;
		let totalUsers = [];
		try {
			response = await getCompanyUsers();
			if (httpResponseIsValid(response)) {
				totalUsers = [...totalUsers, ...response.users];
			}

			response = await getCompanyInvitedUsers();
			if (httpResponseIsValid(response)) {
				totalUsers = [...totalUsers, ...response.users];
			}

			const foreignUsers = totalUsers.filter((user) => !projectUsers.some((u) => (u.email === user.email)));

			setUsersToInvite(foreignUsers);
		} catch (_error) {
			error(`Error occured while fetching company's users: ${_error}`);
		}

		setToolOptions({
			contract: false,
			financial_assessment: false,
			risk_assessment_protocol: false,
			loan_application: false,
			kyc: false,
			esg: false,
			equad: false,
		});

		setIsLoading(false);
		setUserType("member");
		setEditProjectMember(false);
		setUserInviteDialogOpen(true);
	};

	const onEditProjectMember = useCallback((member) => {
		const findProjectMember = projectUsers.find((u) => u.email === member);

		if (findProjectMember) {
			setSelectedUserToAdd(findProjectMember.email);
			setUserAccessType(findProjectMember.role);

			setUserErrors({
				...userErrors,
				autocomplete: "",
			});

			let contract = false;
			let financialAssessment = false;
			let riskAssessmentProtocol = false;
			let loanApplication = false;
			let kyc = false;
			let esg = false;
			let equad = false;

			if (findProjectMember.access.includes("contract")) {
				contract = true;
			}

			if (findProjectMember.access.includes("financial_assessment")) {
				financialAssessment = true;
			}

			if (findProjectMember.access.includes("risk_assessment_protocol")) {
				riskAssessmentProtocol = true;
			}

			if (findProjectMember.access.includes("loan_application")) {
				loanApplication = true;
			}

			if (findProjectMember.access.includes("kyc")) {
				kyc = true;
			}

			if (findProjectMember.access.includes("esg")) {
				esg = true;
			}

			if (findProjectMember.access.includes("equad")) {
				equad = true;
			}

			setToolOptions({
				contract,
				financial_assessment: financialAssessment,
				risk_assessment_protocol: riskAssessmentProtocol,
				loan_application: loanApplication,
				kyc,
				esg,
				equad,
			});

			setEditProjectMember(true);
			setUserInviteDialogOpen(true);
		}
	}, [projectUsers, userErrors]);

	const onSelectUserToAdd = (newValue) => {
		if (newValue) {
			setSelectedUserToAdd(newValue.email);

			setUserErrors({
				...userErrors,
				autocomplete: "",
			});
		}
	};

	const onAddUserToProject = async () => {
		setIsLoading(true);

		try {
			const validationSchema = Yup.object().shape({
				email: Yup.string().trim().email().required(),
			});

			const emailValid = await validationSchema.isValid({ email: selectedUserToAdd });

			if (!emailValid) {
				setUserErrors({
					...userErrors,
					autocomplete: "Invalid Email Address.",
				});

				setIsLoading(false);

				return;
			}

			if (userAccessType === UserAccessTypes.none.value) {
				setUserErrors({
					...userErrors,
					access_type: "Invalid Access Type.",
				});

				setIsLoading(false);

				return;
			}

			if (userAccessType === UserAccessTypes.tool_expert.value) {
				const editAccessValid = Object.values(toolOptions).some(Boolean);

				if (!editAccessValid) {
					setUserErrors({
						...userErrors,
						edit_access: "Select at least on tool",
					});

					setIsLoading(false);

					return;
				}
			}

			setUserErrors({
				autocomplete: "",
				access_type: "",
				edit_access: "",
			});

			const info = {
				email: selectedUserToAdd,
				access_type: userAccessType,
				edit_access: toolOptions,
			};

			const response = (userType === "member") ? await updateProjectUsers(projectid, info) : await addMaterialExpert(projectid, info);

			if (httpResponseIsValid(response)) {
				success(response?.message);
				fetchData();
				setUserInviteDialogOpen(false);
			} else {
				error(response?.message);
			}
		} catch (_error) {
			error(`Error occured when adding new user to project: ${_error}`);
		}

		setIsLoading(false);
	};

	const onCancelUserToAdd = () => {
		setUserErrors({
			autocomplete: "",
			access_type: "",
			edit_access: "",
		});
		setUserAccessType(UserAccessTypes.none.value);
		setSelectedUserToAdd("");
		setUserInviteDialogOpen(false);
	};

	const onSetUserType = (value) => {
		setUserType(value);
		setSelectedUserToAdd("");
		setUserErrors({ ...userErrors, autocomplete: "" });

		if (value === "member") {
			setUserAccessType(UserAccessTypes.none.value);
		} else {
			setUserAccessType(UserAccessTypes.material_expert.value);
		}
	};

	const onDeleteProjectMember = async (member) => {
		setIsLoading(true);

		try {
			const response = await deleteProjectUsers(projectid, { member });

			if (httpResponseIsValid(response)) {
				setDeleteModal(null);
				fetchData();
				success(response?.message);
			} else {
				error(response?.message);
			}
		} catch (_error) {
			error(`Error occured when deleting member from project: ${_error}`);
		}

		setIsLoading(false);
	};

	const userRoleValueToDescription = useCallback(
		(value) => {
			const user = projectUsers.find((u) => u.email === value);

			switch (user.role) {
				case "ProjectDeveloper":
					return (
						<Grid container alignItems="center" justifyContent="center">
							<Chip sx={{ margin: "2px", borderRadius: "10px", backgroundColor: "white", borderColor: "black" }} label="Project" variant="outlined" />
						</Grid>
					);
				case "MaterialExpert":
					return (
						<Grid container alignItems="center" justifyContent="center">
							<Chip sx={{ margin: "2px", borderRadius: "10px", backgroundColor: "white", borderColor: "black" }} label="None" variant="outlined" />
						</Grid>
					);
				case "ToolExpert":
					return (
						<Grid container alignItems="center" justifyContent="center">
							{user.access.map((a, index) => (
								<Chip key={index} sx={{ margin: "2px", borderRadius: "10px", backgroundColor: "white", borderColor: "black" }} label={AccessTypes[a]} variant="outlined" />
							))}
						</Grid>
					);
				default:
					break;
			}

			return "Unknown";
		},
		[projectUsers],
	);

	const gridRef = useRef(null);
	const [tableOverflow, setTableOverflow] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			const width = gridRef.current.clientWidth;

			console.log(width);
			// Perform action based on width
			if (width > 1100) {
				setTableOverflow(false);
			} else {
				setTableOverflow(true);
			}
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [setTableOverflow]);

	const getTableSizeFactor = () => (tableOverflow ? 0.65 : 1);
	const getTableFontSize = () => (tableOverflow ? "h7" : "h6");

	useEffect(() => {
		setShouldUiBeDisabled(disableAccess);
	}, [disableAccess]);

	useEffect(() => {
		setUserRole(role);
	}, [role]);

	const changeProjectState = useCallback(
		async () => {
			await onChangeProjectState();
			fetchData();
		}, [fetchData, onChangeProjectState],
	);

	useEffect(() => {
		fetchData();
	}, []);

	const userTableColumns = useMemo(() => [
		{
			Header: <Typography id="name_header" variant={getTableFontSize()}>{"User"}</Typography>,
			accessor: "email",
			id: "email",
			filterable: true,
			width: 300 * getTableSizeFactor(),
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant={getTableFontSize()}>{"Role in project"}</Typography>,
			accessor: "role",
			id: "role",
			filterable: true,
			minWidth: 200 * getTableSizeFactor(),
			maxWidth: 400,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{userRoleValueToName(value)}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant={getTableFontSize()}>{"Status"}</Typography>,
			accessor: "status",
			id: "status",
			filterable: true,
			minWidth: 250 * getTableSizeFactor(),
			maxWidth: 400,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{userStatusValueToName(value)}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant={getTableFontSize()}>{"Actions"}</Typography>,
			accessor: "email",
			id: "actions",
			width: 200 * getTableSizeFactor(),
			Cell: ({ value }) => (
				<Grid container item direction="row" justifyContent="space-around" alignItems="center">
					<Grid item mb={1} />
					<ToggleButton
						value="Edit"
						title="Edit User"
						size="small"
						aria-label="Edit"
						disabled={editDisabled(value)}
						sx={{ borderColor: "primary.main" }}
						onClick={() => onEditProjectMember(value)}
					>
						<Edit color="primary" />
					</ToggleButton>
					<ToggleButton
						value="Delete"
						title="Delete User"
						size="small"
						aria-label="Delete"
						disabled={deleteDisabled(value)}
						sx={{ borderColor: "primary.main" }}
						onClick={() => setDeleteModal(value)}
					>
						<Delete color="error" />
					</ToggleButton>
					<Grid item mb={1} />
				</Grid>
			),
		},
	], [onEditProjectMember, userEmail, tableOverflow]);

	const adminTableColumns = useMemo(() => [
		{
			Header: <Typography id="name_header" variant="h6">{"User"}</Typography>,
			accessor: "email",
			id: "email",
			filterable: true,
			width: 250,
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Role in project"}</Typography>,
			accessor: "role",
			id: "role",
			filterable: true,
			width: 200,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{userRoleValueToName(value)}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"View Access"}</Typography>,
			accessor: "email",
			filterable: false,
			sortable: false,
			minWidth: 350,
			maxWidth: 600,
			Cell: ({ value }) => (
				<Grid container alignItems="center" justifyContent="center">
					<Typography>{userRoleValueToDescription(value)}</Typography>
				</Grid>
			),
		},
		{
			Header: <Typography variant="h6">{"Edit Access"}</Typography>,
			accessor: "email",
			filterable: false,
			sortable: false,
			minWidth: 350,
			maxWidth: 600,
			Cell: ({ value }) => (
				<Grid container item sx={{ overflow: "auto", alignItems: "center", justifyContent: "center" }}>
					<Typography>{userRoleValueToDescription(value)}</Typography>
				</Grid>
			),
		},
	], [userRoleValueToDescription]);

	return (
		<>
			{isLoading && (<LinearProgress color="primary" />)}
			<Grid container direction="column" alignItems="center" py={1} px={3} sx={{ "> .MuiGrid-item": { p: 1 }, position: "fixed", maxWidth: "1300px", width: "calc(100% - 300px)", background: "white", zIndex: 999 }}>
				<Grid container item direction="row" alignItems="center" sx={{ "> .MuiGrid-item": { p: 0 }, background: "#F4F4F4", borderRadius: "10px 10px 0px 0px" }}>
					<Grid item xs={12} md={4}>
						<Typography variant="h5" className={classes.screenTitle}>{"PROJECT USERS"}</Typography>
					</Grid>
					<Grid item xs={0} md={3} />
					<Grid item container className={classes.boxBorder} sx={{ p: 1 }} xs={12} md={5}>
						<Grid item xs={12} md={6}>
							<Box p={1}>
								<Grid container direction="row" justifyContent="start" alignItems="center">
									<Grid item xs={4}>
										<Typography variant="h6">{"Status: "}</Typography>
									</Grid>
									<Grid item container xs={8} justifyContent="start">
										<Typography variant="h6" color={(projectInfo.status === "Approved") ? "green.main" : ((projectInfo.status === "Rejected") ? "error.main" : "primary.main")}>{projectInfo.status}</Typography>
									</Grid>
								</Grid>
							</Box>
						</Grid>
						<Grid item container xs={12} md={6} alignItems="center" justifyContent="end">
							<Button
								variant="contained"
								size="medium"
								sx={{ width: "170px", backgroundColor: "#5C9AE1", color: "common.white", ":hover": { backgroundColor: "primary.main" }, px: 3, marginRight: "4px", borderRadius: "10px" }}
								disabled={!isProjectDeveloper}
								onClick={() => setProjectStateDialog(true)}
							>
								{projectInfo.status === "In Progress" ? "Submit Project" : "Revert Project"}
							</Button>
						</Grid>
					</Grid>
				</Grid>
				{!shouldUiBeDisabled && (
					<Grid container item direction="row" alignItems="center" sx={{ ">.MuiGrid-item": { p: 0 }, background: "#F4F4F4", borderRadius: "0px 0px 10px 10px" }}>
						<Grid item xs={0} md={7} />
						<Grid item container sx={{ p: 1 }} xs={12} md={5} alignItems="center">
							<Grid item xs={12} md={6} />
							<Grid item xs={12} md={6}>
								<Box px={1} display="flex" flexDirection="row">
									<Grid container direction="row" justifyContent="end" alignItems="center">
										<Button
											variant="contained"
											size="medium"
											sx={{ width: "160px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
											// disabled={!enableEditing || !dataChanged}
											onClick={openInviteUserDialog}
										>
											{"Invite user"}
										</Button>
									</Grid>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				)}
			</Grid>
			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "100px" }}>
				{(userRole === "investor" || userRole === "admin") && (
					<Grid ref={gridRef} item container mb={2}>
						<div className={clsx("container", classes.page)}>
							<Table
								data={projectUsers}
								noDataText={isLoading ? "Fetching users..." : "No data available!"}
								columns={adminTableColumns}
								defaultSorted={[{ id: "name", desc: true }]}
							/>
						</div>
					</Grid>
				)}
				{!(userRole === "investor" || userRole === "admin") && (
					<Grid ref={gridRef} item container mb={2}>
						<div className={clsx("container", classes.page)}>
							<Table
								data={projectUsers}
								noDataText={isLoading ? "Fetching users..." : "No data available!"}
								columns={userTableColumns}
								defaultSorted={[{ id: "name", desc: true }]}
							/>
						</div>
					</Grid>
				)}
			</Grid>
			{userInviteDialogOpen
				&& (
					<Paper className={classes.dialog} elevation={10}>
						<Dialog
							open={userInviteDialogOpen}
							TransitionComponent={Transition}
							maxWidth="md"
							scroll="body"
							PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4", maxWidth: "710px", zIndex: 999 } }}
							onClose={() => setUserInviteDialogOpen(false)}
						>
							<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
								<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
									<b>{editProjectMember ? `Edit Project's ${projectInfo.name} Member` : `Invite user to Project ${projectInfo.name}`}</b>
								</Typography>
							</DialogTitle>
							<DialogContent dividers sx={{ overflowY: "hidden" }}>
								<Grid item container margin="5px" sx={{ p: 1, width: "650px" }} alignItems="center">
									<Grid item xs={12} md={4} p={1}>
										<Typography variant="h6" fontWeight="bold" sx={{ p: 1, color: "#1d4363" }}>
											{"User Type"}
										</Typography>
									</Grid>
									<Grid item md={8}>
										<ToggleButtonGroup
											exclusive
											color="primary"
											value={userType}
											aria-label="Platform"
											className={classes.toggles}
											onChange={(e) => onSetUserType(e.target.value)}
										>
											<ToggleButton sx={{ width: "210px", fontWeight: "bold" }} value="member">{"Company Member"}</ToggleButton>
											<ToggleButton sx={{ width: "215px", fontWeight: "bold" }} value="external">{"External Collaborator"}</ToggleButton>
										</ToggleButtonGroup>
									</Grid>
								</Grid>
								<Grid item container margin="5px" sx={{ p: 1, width: "650px" }} alignItems="center">
									<Grid item xs={12} md={4} p={1}>
										<Typography variant="h6" fontWeight="bold" sx={{ p: 1, color: "#1d4363" }}>
											{"User Email"}
										</Typography>
									</Grid>
									{editProjectMember
										&& (
											<TextField
												disabled
												sx={{ width: "420px" }}
												size="large"
												id="user-text-field"
												value={selectedUserToAdd}
											/>
										)}
									{(!editProjectMember && userType === "member")
										&& (
											<Grid item xs={12} sm={5} md={8} direction="row" alignItems="center">
												<Autocomplete
													id="user-select-combo"
													options={usersToInvite}
													size="large"
													variant="filled"
													disabled={usersToInvite.length === 0}
													getOptionLabel={(option) => option.email}
													renderInput={(params) => (
														<TextField
															{...params}
															label={usersToInvite.length === 0 ? "No other users available in this company" : "email@address..."}
															error={userErrors.autocomplete !== ""}
															helperText={userErrors.autocomplete === "" ? "" : userErrors.autocomplete}
														/>
													)}
													onChange={(e, newValue) => onSelectUserToAdd(newValue)}
												/>
											</Grid>
										)}
									{(!editProjectMember && userType === "external")
										&& (
											<TextField
												sx={{ width: "420px" }}
												size="large"
												id="user-text-field-2"
												placeholder="Email Address..."
												value={selectedUserToAdd}
												error={userErrors.autocomplete !== ""}
												helperText={userErrors.autocomplete === "" ? "" : userErrors.autocomplete}
												onChange={(e) => setSelectedUserToAdd(e.target.value)}
											/>
										)}
								</Grid>
								<Grid item container margin="5px" sx={{ p: 1, width: "650px" }} alignItems="center">
									<Grid item xs={12} md={4} p={1}>
										<Typography variant="h6" fontWeight="bold" sx={{ p: 1, color: "#1d4363" }}>
											{"Access Type"}
											<Tooltip placement="bottom-start" title="Project developer has full access, material expert is for external users (such as SEFA personnel) and they only have access to what you assign them to, the tool expert only has access to what you assign them to.">
												<InfoIcon fontSize="small" sx={{ padding: 0.2, color: "green.main" }} />
											</Tooltip>
										</Typography>
									</Grid>
									<Grid item xs={12} sm={5} md={8} direction="row" alignItems="center">
										<FormControl fullWidth>
											<Select
												disabled={userType === "external"}
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												value={userAccessType}
												placeholder="Access Type"
												error={userErrors.access_type !== ""}
												helperText={userErrors.access_type === "" ? "" : userErrors.access_type}
												onChange={handleAccessTypeSelect}
											>
												{
													Object.values(UserAccessTypes).map((r, index) => (
														<MenuItem key={index} value={r.value}>{r.name}</MenuItem>
													))
												}
											</Select>
											{userErrors.access_type !== ""
												&& (
													<FormHelperText
														sx={{ color: "#bf3333", marginLeft: "16px !important" }}
													>
														{userErrors.access_type}
													</FormHelperText>
												)}
										</FormControl>
									</Grid>
								</Grid>
								{userAccessType === UserAccessTypes.tool_expert.value
									&& (
										<Grid item container margin="5px" sx={{ p: 1, width: "650px" }} alignItems="start">
											<Grid item xs={12} md={4} p={1}>
												<Typography variant="h6" fontWeight="bold" sx={{ p: 1, color: "#1d4363" }}>
													{"Edit Access"}
												</Typography>
											</Grid>
											<Grid item container xs={12} sm={5} md={8} direction="column" alignItems="center" justifyContent="start">
												<FormControl fullWidth className={classes.form}>
													<Grid item xs={12} sm={5} md={12} className={classes.boxBorder} direction="column" alignItems="center" justifyContent="start">
														<Grid item container xs={12} md={4} p={1} justifyContent="space-between">
															<Typography color={toolOptions.contract ? "common.black" : "#A0ACB0"} variant="h6" sx={{ p: 1 }}>
																{"Contract"}
															</Typography>
															<Switch
																checked={toolOptions.contract}
																inputProps={{ "aria-label": "controlled" }}
																onChange={() => handleToolOptionChange("contract")}
															/>
														</Grid>
														<Grid item container xs={12} md={4} p={1} justifyContent="space-between">
															<Typography color={toolOptions.financial_assessment ? "common.black" : "#A0ACB0"} variant="h6" sx={{ p: 1 }}>
																{"Financial Assessment"}
															</Typography>
															<Switch
																checked={toolOptions.financial_assessment}
																inputProps={{ "aria-label": "controlled" }}
																onChange={() => handleToolOptionChange("financial_assessment")}
															/>
														</Grid>
														<Grid item container xs={12} md={4} p={1} justifyContent="space-between">
															<Typography color={toolOptions.risk_assessment_protocol ? "common.black" : "#A0ACB0"} variant="h6" sx={{ p: 1 }}>
																{"Risk Assessment Protocol"}
															</Typography>
															<Switch
																checked={toolOptions.risk_assessment_protocol}
																inputProps={{ "aria-label": "controlled" }}
																onChange={() => handleToolOptionChange("risk_assessment_protocol")}
															/>
														</Grid>
														<Grid item container xs={12} md={4} p={1} justifyContent="space-between">
															<Typography color={toolOptions.loan_application ? "common.black" : "#A0ACB0"} variant="h6" sx={{ p: 1 }}>
																{"Loan Application"}
															</Typography>
															<Switch
																checked={toolOptions.loan_application}
																inputProps={{ "aria-label": "controlled" }}
																onChange={() => handleToolOptionChange("loan_application")}
															/>
														</Grid>
														<Grid item container xs={12} md={4} p={1} justifyContent="space-between">
															<Typography color={toolOptions.kyc ? "common.black" : "#A0ACB0"} variant="h6" sx={{ p: 1 }}>
																{"KYC"}
															</Typography>
															<Switch
																checked={toolOptions.kyc}
																inputProps={{ "aria-label": "controlled" }}
																onChange={() => handleToolOptionChange("kyc")}
															/>
														</Grid>
														<Grid item container xs={12} md={4} p={1} justifyContent="space-between">
															<Typography color={toolOptions.esg ? "common.black" : "#A0ACB0"} variant="h6" sx={{ p: 1 }}>
																{"ESG"}
															</Typography>
															<Switch
																checked={toolOptions.esg}
																inputProps={{ "aria-label": "controlled" }}
																onChange={() => handleToolOptionChange("esg")}
															/>
														</Grid>
														<Grid item container xs={12} md={4} p={1} justifyContent="space-between">
															<Typography color={toolOptions.equad ? "common.black" : "#A0ACB0"} variant="h6" sx={{ p: 1 }}>
																{"eQuad"}
															</Typography>
															<Switch
																checked={toolOptions.equad}
																inputProps={{ "aria-label": "controlled" }}
																onChange={() => handleToolOptionChange("equad")}
															/>
														</Grid>
													</Grid>
													{userErrors.edit_access !== ""
														&& (
															<FormHelperText
																sx={{ color: "#bf3333", marginLeft: "16px !important" }}
															>
																{userErrors.edit_access}
															</FormHelperText>
														)}
												</FormControl>
											</Grid>
										</Grid>
									)}
								<Grid container mt={3} alignItems="center" justifyContent="space-between">
									<Grid item sm={3} display="flex" justifyContent="start">
										<Button
											fullWidth
											variant="outlined"
											size="large"
											sx={{ width: "150px", height: "40px", marginLeft: "15px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
											color="primary"
											onClick={onCancelUserToAdd}
										>
											{"Cancel"}
										</Button>
									</Grid>
									<Grid item sm={1} />
									<Grid item display="flex" sm={3} justifyContent="end">
										<Button
											fullWidth
											variant="contained"
											size="large"
											disabled={selectedUserToAdd === ""}
											sx={{ width: "150px", height: "40px", marginRight: "10px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
											color="primary"
											onClick={onAddUserToProject}
										>
											{editProjectMember ? "Edit User" : "Add User"}
										</Button>
									</Grid>
								</Grid>
							</DialogContent>
						</Dialog>
					</Paper>
				)}
			{deleteModal !== null
				&& (
					<Dialog
						fullWidth
						open={deleteModal !== null}
						TransitionComponent={Transition}
						scroll="body"
						maxWidth="md"
						PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4", maxWidth: "750px" } }}
						onClose={() => setDeleteModal(null)}
					>
						<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
							<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
								{"Are you sure?"}
							</Typography>
						</DialogTitle>
						<DialogContent dividers sx={{ overflowY: "hidden" }}>
							<Grid item container sx={{ p: 1, marginTop: "5px" }} mt={1} alignItems="center" justifyContent="center">
								<Typography variant="h6" sx={{ color: "#1d4363" }}>
									{"Are you sure you want to"}
									&nbsp;
									<b>{"delete"}</b>
									&nbsp;
									{"the member"}
									&nbsp;
									<b>{`${deleteModal};`}</b>
									{/* &nbsp;
									{"of the company"}
									&nbsp;
									<b>{selectedInvitation.company_name}</b>
									&nbsp;
									{"as a"}
									&nbsp;
									<b>{"Material Expert."}</b> */}
								</Typography>
							</Grid>
							<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="25px">
								<Grid item sm={3} display="flex" justifyContent="start">
									<Button
										fullWidth
										variant="outlined"
										sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
										color="primary"
										onClick={() => setDeleteModal(null)}
									>
										{"Cancel"}
									</Button>
								</Grid>
								<Grid item display="flex" sm={3} justifyContent="end">
									<Button
										fullWidth
										variant="contained"
										sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
										color="primary"
										onClick={() => onDeleteProjectMember(deleteModal)}
									>
										{"Delete"}
									</Button>
								</Grid>
							</Grid>
						</DialogContent>
					</Dialog>
				)}
			<StatusDialog
				open={projectStateDialog}
				status={projectInfo.status}
				name={projectInfo.name}
				onClose={setProjectStateDialog}
				onChange={changeProjectState}
			/>
		</>
	);
};

export default memo(Projects);
