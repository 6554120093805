import { useState, useEffect, memo, useCallback } from "react";
import { useLocation, Routes, Route, Link, useParams } from "react-router-dom";
import { Grid, Drawer, Box, List, ListItem, ListItemText, Collapse, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import shallow from "zustand/shallow";
import InfoIcon from "@mui/icons-material/Info";

import useGlobalState from "../../use-global-state";
import { getProjectSize, getUserProfile, getProjectUserRole, getProject, updateProjectStatus } from "../../api";
import { httpResponseIsValid, useSnackbar } from "../../utils";

import Overview from "./Overview";
import ProjectUsers from "./ProjectUsers";
import Contract from "./Contract";
import PreAssessment from "./PreAssessment";
import RiskAssessmentProtocol from "./RiskAssessmentProtocol";
import ConstructionRisk from "./ConstructionRisk";
import ChangeInControlRisk from "./ChangeInControlRisk";
import ManagementRisk from "./ManagementRisk";
import OccupacyRisk from "./OccupacyRisk";
import PrePaymentRisk from "./PrePaymentRisk";
import PipelineRisk from "./PipelineRisk";
import InterfaceRisk from "./InterfaceRisk";
import OMRisk from "./OMRisk";
import PerformanceRisk from "./PerformanceRisk";
import TechnicalRisk from "./TechnicalRisk";
import CurrencyRisk from "./CurrencyRisk";
import EnergyPriceRisk from "./EnergyPriceRisk";
import MarketRisk from "./MarketRisk";
import RegulatoryRisk from "./RegulatoryRisk";
import CreditRiskImplementorClient from "./CreditRiskImplementorClient";
import CreditRiskServiceProvider from "./CreditRiskServiceProvider";
import SupplyChainRisk from "./SupplyChainRisk";
import SpreadsheetComponent from "./SpreadsheetComponent";
import LoanApplicationOverview from "./LoanApplicationOverview";
import LoanApplicationCompanyOverview from "./LoanApplicationCompanyOverview";
import LoanApplicationDetailedEEInformation from "./DetailedEeInformation";
import LoanApplicationTechnicalInformation from "./LoanApplicationTechnicalInformation";
import LoanApplicationFinancialInformation from "./LoanApplicationFinacialInformation";
import LoanApplicationProjectFinance from "./LoanApplicationProjectFinance";
import KycOverview from "./Kyc";
import EsgEnvironmental from "./EsgEnvironmental";
import EsgAllAspects from "./EsgAllAspects";
import EsgSocial from "./EsgSocial";
import EsgGovernance from "./EsgGovernance";
import Equad from "./equad";

const useStyles = makeStyles((theme) => ({
	background: {
		position: "fixed",
		background: "#1d4363",
		width: "300px",
		// position: "fixed",
		// background: theme.palette.dark.main,
	},
	menuItem: {
		color: theme.palette.common.white,
		padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
		cursor: "pointer",
		"&:hover[aria-disabled=false]": {
			backgroundColor: "#4a82c3",
			color: theme.palette.common.white,
		},
		"&[aria-checked=true][aria-disabled=false]": {
			backgroundColor: "#4a82c3",
		},
		"&[aria-disabled=true]": {
			pointerEvents: "none",
			cursor: "not-allowed",
		},
		"&:hover[aria-disabled=true]": {
			color: theme.palette.common.white,
		},
		"&[aria-checked=true][aria-disabled=true]": {
			color: theme.palette.common.white,
		},
	},
	submenuItem: {
		color: theme.palette.primary.light,
		padding: `${theme.spacing(1)} ${theme.spacing(5)}`,
		"&:hover[aria-disabled=false]": {
			backgroundColor: "#4a82c3",
			color: theme.palette.common.white,
		},
		"&[aria-checked=true][aria-disabled=false]": {
			backgroundColor: "#4a82c3",
			color: theme.palette.common.white,
		},
		"&[aria-disabled=true]": {
			pointerEvents: "none",
			cursor: "not-allowed",
		},
		"&:hover[aria-disabled=true]": {
			color: theme.palette.common.white,
		},
		"&[aria-checked=true][aria-disabled=true]": {
			color: theme.palette.common.white,
		},
	},
	routeDiv: {
		flex: 1,
		flexGrow: 1,
		margin: "0",
		float: "right",
		display: "flex",
		flexDirection: "column",
		overflow: "auto",
		maxWidth: "1300px",
	},
	outRouteDiv: {
		width: "100%",
		marginLeft: "300px",
		display: "flex",
		justifyContent: "center",
	},
}));

const availablePaths = [
	"overview",
	"users",
	"contract",
	"output",
	"pre-assessment",
	"profit-loss",
	"financial-position",
	"cash-flow",
	"kpis",
	"risk-assessment-protocol",
	"construction-risk",
	"change-in-control-risk",
	"management-risk",
	"occupacy-risk",
	"pre-payment-risk",
	"pipeline-risk",
	"interface-risk",
	"o-m-risk",
	"performance-risk",
	"technical-risk",
	"currency-risk",
	"energy-price-risk",
	"market-risk",
	"regulatory-risk",
	"credit-risk-implementor-client",
	"credit-risk-service-provider",
	"supply-chain-risk",
	"loan-application-overview",
	"loan-application-company-overview",
	"loan-application-detailed-ee-information",
	"loan-application-technical-information",
	"loan-application-financial-information",
	"loan-application-project-finance",
	"kyc",
	"esg-enviromental",
	"esg-all-aspects",
	"esg-social",
	"esg-governance",
	"equad",
];

const availableSubPaths = new Set([
	"past-years",
	"estimate-current-year",
	"forecast-year-1",
	"forecast-year-2",
]);

const Project = () => {
	const { error, success } = useSnackbar();
	const { pathname } = useLocation();
	const { projectid } = useParams();
	const [companyId, setCompanyId] = useState(null);
	const [isProjectDeveloper, setIsProjectDeveloper] = useState(false);
	const [user, setUser] = useState({
		platform_role: null,
		project_role: null,
		tool_access: [],
		available_tools: {
			contract: true,
			financial_assessment: true,
			risk_assessment_protocol: true,
			loan_application: true,
			kyc: true,
			esg: true,
			equad: true,
		},
	});

	const updateLIstedTools = useCallback((newToolList) => {
		setUser({
			...user,
			available_tools: newToolList,
		});
	}, [user, setUser]);

	const { projectSize, setProjectSize, typeOfFinance, setTypeOfFinance } = useGlobalState(useCallback((e) => ({
		projectSize: e.projectSize,
		setProjectSize: e.setProjectSize,
		typeOfFinance: e.typeOfFinance,
		setTypeOfFinance: e.setTypeOfFinance,
	}), []), shallow);

	const [value, setValue] = useState(() => {
		for (const path of availablePaths) {
			if (pathname.includes(path)) return path;
		}

		return availablePaths[0];
	});

	const hasViewAccess = (tool) => {
		if (tool !== "overview" && tool !== "users" && user.available_tools !== undefined && !user.available_tools[tool]) {
			return false;
		}

		if (user.platform_role === "admin" || user.platform_role === "investor" || user.project_role === "ProjectDeveloper") {
			return true;
		}

		if (tool === "users") {
			return false;
		}

		if (tool === "overview") {
			return true;
		}

		if (user.project_role === "MaterialExpert") {
			return true;
		}

		if (user.project_role === "ToolExpert" && user.tool_access.includes(tool)) {
			return true;
		}

		if (user.platform_role === "owner" && projectid === "new-project") {
			return true;
		}

		return false;
	};

	const disableEditAccess = (tool) => {
		if (tool !== "overview" && tool !== "users" && user.available_tools !== undefined && !user.available_tools[tool]) {
			return true;
		}

		if (user.platform_role === "owner" && projectid === "new-project" && tool === "overview") {
			return false;
		}

		if (user.project_role === "ProjectDeveloper") {
			return false;
		}

		if (user.project_role === "ToolExpert" && user.tool_access.includes(tool)) {
			return false;
		}

		return true;
	};

	const [height, setHeight] = useState(100);
	const classes = useStyles();

	useEffect(() => {
		const resizeObserver = new ResizeObserver((event) => {
			setHeight(event[0].contentBoxSize[0].blockSize);
		});

		resizeObserver.observe(document.querySelector("#header"));
	});

	useEffect(() => {
		setValue(() => {
			const pathParts = pathname.split("/");

			if (availableSubPaths.has(pathParts.at(-1)) && availablePaths.includes(pathParts.at(-2))) {
				return pathParts.at(-2);
			}

			if (availablePaths.includes(pathParts.at(-1))) {
				return pathParts.at(-1);
			}

			return "overview";
		});
	}, [pathname]);

	useEffect(() => {
		(async () => {
			try {
				let response;
				let projectRole;
				let platformRole;
				let toolAccess;
				let availableTools;

				if (projectid !== "new-project") {
					response = await getProjectSize(projectid);
					if (httpResponseIsValid(response)) {
						setProjectSize(response?.pSize);
						setTypeOfFinance(response?.tFinance);
					} else {
						setProjectSize(null);
						setTypeOfFinance("project_finance");
						error(response?.message);
					}

					response = await getProjectUserRole(projectid);
					if (httpResponseIsValid(response)) {
						projectRole = response?.role;
						toolAccess = response?.access;
					} else {
						error(response?.message);
					}

					response = await getProject(projectid);
					if (httpResponseIsValid(response)) {
						availableTools = response?.tools;
					} else {
						error(response?.message);
					}
				}

				response = await getUserProfile();
				if (httpResponseIsValid(response)) {
					setCompanyId(response?.company_id);
					platformRole = response?.role;
				} else {
					error(response?.message);
				}

				setUser({
					platform_role: platformRole,
					project_role: projectRole,
					tool_access: toolAccess,
					available_tools: availableTools,
				});
			} catch (_error) {
				console.log(`Error occured when fetching headers data: ${_error}`);
			}
		})();
	}, [error, projectid, setProjectSize, setTypeOfFinance]);

	const [selectedIndex, setSelectedIndex] = useState("");

	useEffect(
		() => {
			setIsProjectDeveloper(user.project_role === "ProjectDeveloper");
		}, [user.project_role],
	);

	const handleClick = (index) => {
		if (selectedIndex === index) {
			setSelectedIndex("");
		} else {
			setSelectedIndex(index);
		}
	};

	const changeProjectState = async () => {
		try {
			const response = await updateProjectStatus(projectid);
			if (httpResponseIsValid(response)) {
				success(response?.message);
			} else {
				error(response?.message);
			}
		} catch (_error) {
			console.log(`Error occured when updating project's: ${_error}`);
		}
	};

	return (
		<div id="drawer-container" style={{ position: "relative", display: "flex", minHeight: `calc(100vh - ${height}px)` }}>
			<Drawer
				variant="permanent"
				sx={{
					width: "300px",
					height: "100%",
					position: "fixed",
					flexShrink: 0,
				}}
				ModalProps={{
					container: document.querySelector("#drawer-container"),
					style: { position: "absolute" },
				}}
				classes={{
					paperAnchorLeft: classes.background,
				}}
			>
				<Box sx={{ overflow: "auto", marginTop: "105px" }}>
					<List>
						<ListItem key="overview" component={Link} aria-checked={value === "overview"} aria-disabled="false" className={classes.menuItem} to="overview">
							<ListItemText primary="Overview" />
						</ListItem>
						{hasViewAccess("users")
							&& (
								<ListItem key="users" component={Link} aria-checked={value === "users"} aria-disabled="false" className={classes.menuItem} to="users">
									<ListItemText primary="Users" />
								</ListItem>
							)}
						{hasViewAccess("contract")
							&& (
								<ListItem key="contract" component={Link} aria-checked={value === "contract"} className={classes.menuItem} to="contract" disabled={projectid === "new-project"} aria-disabled={projectid === "new-project"}>
									<ListItemText primary="Contract" />
								</ListItem>
							)}
						{hasViewAccess("financial_assessment")
							&& (
								<>
									<ListItem key="spreadsheet" component={Link} className={classes.menuItem} disabled={projectid === "new-project"} aria-disabled={projectid === "new-project"} to="output" onClick={() => { handleClick("spreadsheet"); }}>
										<ListItemText primary="Financial Assessment" />
									</ListItem>
									<Collapse unmountOnExit in={selectedIndex === "spreadsheet"} timeout="auto">
										<List disablePadding component="div">
											<ListItem component={Link} aria-checked={value === "output"} className={classes.submenuItem} to="output" aria-disabled={false}>
												<ListItemText key="output" primary="Overview" />
											</ListItem>
											<ListItem component={Link} aria-checked={value === "pre-assessment"} className={classes.submenuItem} to="pre-assessment" aria-disabled={false}>
												<ListItemText key="pre-assessment" primary="Pre-Assessment" />
											</ListItem>
											<ListItem component={Link} aria-checked={value === "profit-loss"} className={classes.submenuItem} to="profit-loss" aria-disabled={false}>
												<ListItemText key="profit-loss" primary="Income Statement" />
											</ListItem>
											<ListItem component={Link} aria-checked={value === "financial-position"} className={classes.submenuItem} to="financial-position" aria-disabled={false}>
												<ListItemText key="financial-position" primary="Balance Sheet" />
											</ListItem>
											<ListItem component={Link} aria-checked={value === "cash-flow"} className={classes.submenuItem} to="cash-flow" aria-disabled={false}>
												<ListItemText key="cash-flow" primary="Cash Flow" />
											</ListItem>
											<ListItem component={Link} aria-checked={value === "kpis"} className={classes.submenuItem} to="kpis" aria-disabled={false}>
												<ListItemText key="kpis" primary="KPIs" />
											</ListItem>
										</List>
									</Collapse>
								</>
							)}
						{hasViewAccess("risk_assessment_protocol")
							&& (
								<>
									<ListItem key="risk-assessment-protocol" component={Link} className={classes.menuItem} to="risk-assessment-protocol" disabled={projectid === "new-project"} aria-disabled={projectid === "new-project"} onClick={() => { handleClick("risk-assessment-protocol"); }}>
										<ListItemText primary="Risk Assessment" />
									</ListItem>
									<Collapse unmountOnExit in={selectedIndex === "risk-assessment-protocol"} timeout="auto">
										<List disablePadding component="div">
											<ListItem component={Link} aria-checked={value === "risk-assessment-protocol"} className={classes.submenuItem} to="risk-assessment-protocol" aria-disabled={false}>
												<ListItemText key="risk-assessment-protocol" primary="Overview" />
											</ListItem>
											<ListItem component={Link} aria-checked={value === "credit-risk-service-provider"} className={classes.submenuItem} to="credit-risk-service-provider" aria-disabled={false}>
												<ListItemText key="credit-risk-service-provider" primary="Credit Risk Service Provider/Contractor" />
											</ListItem>
											<ListItem component={Link} aria-checked={value === "credit-risk-implementor-client"} className={classes.submenuItem} to="credit-risk-implementor-client" aria-disabled={false}>
												<ListItemText key="credit-risk-implementor-client" primary="Credit Risk Implementor/End Client" />
											</ListItem>
											<ListItem component={Link} aria-checked={value === "regulatory-risk"} className={classes.submenuItem} to="regulatory-risk" aria-disabled={false}>
												<ListItemText key="regulatory-risk" primary="Regulatory Risk" />
											</ListItem>
											<ListItem component={Link} aria-checked={value === "market-risk"} className={classes.submenuItem} to="market-risk" aria-disabled={false}>
												<ListItemText key="market-risk" primary="Market Risk" />
											</ListItem>
											<ListItem component={Link} aria-checked={value === "energy-price-risk"} className={classes.submenuItem} to="energy-price-risk" aria-disabled={false}>
												<ListItemText key="energy-price-risk" primary="Energy Price Risk" />
											</ListItem>
											<ListItem component={Link} aria-checked={value === "currency-risk"} className={classes.submenuItem} to="currency-risk" aria-disabled={false}>
												<ListItemText key="currency-risk" primary="Currency Risk" />
											</ListItem>
											<ListItem component={Link} aria-checked={value === "technical-risk"} className={classes.submenuItem} to="technical-risk" aria-disabled={false}>
												<ListItemText key="technical-risk" primary="Technical Risk" />
											</ListItem>
											<ListItem component={Link} aria-checked={value === "performance-risk"} className={classes.submenuItem} to="performance-risk" aria-disabled={false}>
												<ListItemText key="performance-risk" primary="Performance Risk" />
											</ListItem>
											<ListItem component={Link} aria-checked={value === "o-m-risk"} className={classes.submenuItem} to="o-m-risk" aria-disabled={false}>
												<ListItemText key="o-m-risk" primary="O&M Risk" />
											</ListItem>
											<ListItem component={Link} aria-checked={value === "interface-risk"} className={classes.submenuItem} to="interface-risk" aria-disabled={false}>
												<ListItemText key="interface-risk" primary="Interface Risk" />
											</ListItem>
											<ListItem component={Link} aria-checked={value === "pipeline-risk"} className={classes.submenuItem} to="pipeline-risk" aria-disabled={false}>
												<ListItemText key="pipeline-risk" primary="Pipeline Risk" />
											</ListItem>
											<ListItem component={Link} aria-checked={value === "pre-payment-risk"} className={classes.submenuItem} to="pre-payment-risk" aria-disabled={false}>
												<ListItemText key="pre-payment-risk" primary="Pre-Payment Risk" />
											</ListItem>
											<ListItem component={Link} aria-checked={value === "occupacy-risk"} className={classes.submenuItem} to="occupacy-risk" aria-disabled={false}>
												<ListItemText key="occupacy-risk" primary="Occupancy Risk" />
											</ListItem>
											<ListItem component={Link} aria-checked={value === "management-risk"} className={classes.submenuItem} to="management-risk" aria-disabled={false}>
												<ListItemText key="management-risk" primary="Management Risk" />
											</ListItem>
											<ListItem component={Link} aria-checked={value === "change-in-control-risk"} className={classes.submenuItem} to="change-in-control-risk" aria-disabled={false}>
												<ListItemText key="change-in-control-risk" primary="Change In Control Risk" />
											</ListItem>
											<ListItem component={Link} aria-checked={value === "construction-risk"} className={classes.submenuItem} to="construction-risk" aria-disabled={false}>
												<ListItemText key="construction-risk" primary="Construction Risk" />
											</ListItem>
											<ListItem component={Link} aria-checked={value === "supply-chain-risk"} className={classes.submenuItem} to="supply-chain-risk" aria-disabled={false}>
												<ListItemText key="supply-chain-risk" primary="Supply Chain Risk" />
											</ListItem>
										</List>
									</Collapse>
								</>
							)}
						{hasViewAccess("loan_application")
							&& (
								<>
									<ListItem key="loan-application" component={Link} className={classes.menuItem} disabled={projectid === "new-project"} aria-disabled={projectid === "new-project"} to="loan-application-overview" onClick={() => { handleClick("loan-application"); }}>
										<ListItemText primary="Loan Application" />
									</ListItem>
									<Collapse unmountOnExit in={selectedIndex === "loan-application"} timeout="auto">
										<List disablePadding component="div">
											<ListItem component={Link} aria-checked={value === "loan-application-overview"} className={classes.submenuItem} to="loan-application-overview" aria-disabled={false}>
												<ListItemText key="loan-application-overview" primary="Overview" />
											</ListItem>
											{projectSize
												&& (
													<ListItem component={Link} aria-checked={value === "loan-application-company-overview"} className={classes.submenuItem} to="loan-application-company-overview" disabled={!projectSize} aria-disabled={!projectSize}>
														<ListItemText key="loan-application-company-overview" primary="Company Overview" />
													</ListItem>
												)}
											{!projectSize
												&& (
													<Grid container item flexDirection="row" justifyContent="start" alignItems="center" sx={{ height: 48, margin: "0px" }}>
														<Grid item mb={7}>
															<ListItem component={Link} aria-checked={value === "loan-application-company-overview"} className={classes.submenuItem} to="loan-application-company-overview" disabled={false} aria-disabled={false}>
																<ListItemText key="loan-application-company-overview" primary="Company Overview" />
															</ListItem>
														</Grid>
														<Grid item mb={5} justifyContent="end">
															<Tooltip
																placement="right"
																title="Please complete the Funding Amount Field in the Loan Overview page in order to enable this screen."
															>
																<InfoIcon fontSize="small" sx={{ margin: "0px 0px 5px 43px", color: "green.main" }} />
															</Tooltip>
														</Grid>
													</Grid>
												)}
											{(projectSize === "small")
												&& (
													<ListItem component={Link} aria-checked={value === "loan-application-detailed-ee-information"} className={classes.submenuItem} to="loan-application-detailed-ee-information" disabled={!projectSize} aria-disabled={!projectSize}>
														<ListItemText key="loan-application-detailed-ee-information" primary="Detailed / EE Information" />
													</ListItem>
												)}
											{(!projectSize)
												&& (
													<Grid container item flexDirection="row" justifyContent="start" alignItems="center" sx={{ height: 48 }}>
														<Grid item mb={projectSize ? 12 : 7}>
															<ListItem component={Link} aria-checked={value === "loan-application-detailed-ee-information"} className={classes.submenuItem} to="loan-application-detailed-ee-information" disabled={!projectSize} aria-disabled={!projectSize}>
																<ListItemText key="loan-application-detailed-ee-information" primary="Detailed / EE Information" />
															</ListItem>
														</Grid>
														<Grid item mb={5} justifyContent="end">
															<Tooltip
																placement="right"
																title="Please complete the Funding Amount Field in the Loan Overview page in order to enable this screen. This screen is referred to small projects ranging between 500.000 - 1.000.000 €."
															>
																<InfoIcon fontSize="small" sx={{ margin: "0px 10px 5px 0px", color: "green.main" }} />
															</Tooltip>
														</Grid>
													</Grid>
												)}
											{(projectSize === "medium" || projectSize === "large")
												&& (
													<>
														<ListItem component={Link} aria-checked={value === "loan-application-technical-information"} className={classes.submenuItem} to="loan-application-technical-information" disabled={!projectSize} aria-disabled={!projectSize}>
															<ListItemText key="loan-application-technical-information" primary="Technical Information" />
														</ListItem>
														<ListItem component={Link} aria-checked={value === "loan-application-financial-information"} className={classes.submenuItem} to="loan-application-financial-information" disabled={!projectSize} aria-disabled={!projectSize}>
															<ListItemText key="loan-application-financial-information" primary="Financial Information" />
														</ListItem>
														{(typeOfFinance === "project_finance")
															&& (
																<ListItem component={Link} aria-checked={value === "loan-application-project-finance"} className={classes.submenuItem} to="loan-application-project-finance" disabled={false} aria-disabled={false}>
																	<ListItemText key="loan-application-project-finance" primary="Project Finance" />
																</ListItem>
															)}
													</>
												)}
											{(!projectSize)
												&& (
													<>
														<Grid container item flexDirection="row" justifyContent="start" alignItems="center" sx={{ height: 48 }}>
															<Grid item mb={7}>
																<ListItem component={Link} aria-checked={value === "loan-application-technical-information"} className={classes.submenuItem} to="loan-application-technical-information" disabled={!projectSize} aria-disabled={!projectSize}>
																	<ListItemText key="loan-application-technical-information" primary="Technical Information" />
																</ListItem>
															</Grid>
															<Grid item mb={5} justifyContent="end">
																<Tooltip
																	placement="right"
																	title="Please complete the Funding Amount Field in the Loan Overview page in order to enable this screen. This screen is referred to medium & large projects which range above 1.000.000 €."

																>
																	<InfoIcon fontSize="small" sx={{ margin: "0px 10px 5px 24px", color: "green.main" }} />
																</Tooltip>
															</Grid>
														</Grid>
														<Grid container item flexDirection="row" justifyContent="start" alignItems="center" sx={{ height: 48 }}>
															<Grid item mb={7}>
																<ListItem component={Link} aria-checked={value === "loan-application-financial-information"} className={classes.submenuItem} to="loan-application-financial-information" disabled={false} aria-disabled={false}>
																	<ListItemText key="loan-application-financial-information" primary="Financial Information" />
																</ListItem>
															</Grid>
															<Grid item mb={5} justifyContent="end">
																<Tooltip
																	placement="right"
																	title="Please complete the Funding Amount Field in the Loan Overview page in order to enable this screen. This screen is referred to medium & large projects which range above 1.000.000 €."
																>
																	<InfoIcon fontSize="small" sx={{ margin: "0px 10px 5px 28px", color: "green.main" }} />
																</Tooltip>
															</Grid>
														</Grid>
														<Grid container item flexDirection="row" justifyContent="start" alignItems="center" sx={{ height: 48 }}>
															<Grid item mb={7}>
																<ListItem component={Link} aria-checked={value === "loan-application-project-finance"} className={classes.submenuItem} to="loan-application-project-finance" disabled={false} aria-disabled={false}>
																	<ListItemText key="loan-application-project-finance" primary="Project Finance" />
																</ListItem>
															</Grid>
															<Grid item mb={5} justifyContent="end">
																<Tooltip
																	placement="right"
																	title="Please complete the Funding Amount Field & Type of Finance field in the Loan Overview page in order to enable this screen. This screen is referred to medium & large projects which range above 1.000.000 €."
																>
																	<InfoIcon fontSize="small" sx={{ margin: "0px 10px 5px 69px", color: "green.main" }} />
																</Tooltip>
															</Grid>
														</Grid>
													</>
												)}
										</List>
									</Collapse>
								</>
							)}
						{hasViewAccess("kyc")
							&& (
								<ListItem key="kyc" component={Link} aria-checked={value === "kyc"} className={classes.menuItem} to="kyc" disabled={projectid === "new-project"} aria-disabled={projectid === "new-project"} onClick={() => { handleClick("kyc"); }}>
									<ListItemText primary="KYC" />
								</ListItem>
							)}
						{hasViewAccess("esg")
							&& (
								<>
									<ListItem key="esg" component={Link} className={classes.menuItem} disabled={projectid === "new-project"} aria-disabled={projectid === "new-project"} to="esg-all-aspects" onClick={() => { handleClick("esg"); }}>
										<ListItemText primary="ESG" />
									</ListItem>
									<Collapse unmountOnExit in={selectedIndex === "esg"} timeout="auto">
										<List disablePadding component="div">
											<ListItem component={Link} aria-checked={value === "esg-all-aspects"} className={classes.submenuItem} to="esg-all-aspects" aria-disabled={false}>
												<ListItemText key="esg-all-aspects" primary="ESG All Aspects" />
											</ListItem>
											<ListItem component={Link} aria-checked={value === "esg-enviromental"} className={classes.submenuItem} to="esg-enviromental" aria-disabled={false}>
												<ListItemText key="esg-enviromental" primary="ESG Environmental" />
											</ListItem>
											<ListItem component={Link} aria-checked={value === "esg-social"} className={classes.submenuItem} to="esg-social" aria-disabled={false}>
												<ListItemText key="esg-social" primary="ESG Social" />
											</ListItem>
											<ListItem component={Link} aria-checked={value === "esg-governance"} className={classes.submenuItem} to="esg-governance" aria-disabled={false}>
												<ListItemText key="esg-governance" primary="ESG Governance" />
											</ListItem>
										</List>
									</Collapse>
								</>
							)}
						{hasViewAccess("equad")
							&& (
								<ListItem key="equad" component={Link} aria-checked={value === "equad"} className={classes.menuItem} to="equad" disabled={projectid === "new-project"} aria-disabled={projectid === "new-project"} onClick={() => { handleClick("equad"); }}>
									<ListItemText primary="eQuad" />
								</ListItem>
							)}
					</List>
				</Box>
			</Drawer>
			<div className={classes.outRouteDiv}>
				<div className={classes.routeDiv}>
					<Routes>
						{hasViewAccess("overview") && <Route index element={<Overview key={user} disableAccess={disableEditAccess("overview")} isProjectDeveloper={isProjectDeveloper} onChangeTools={updateLIstedTools} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("overview") && <Route path="/overview" element={<Overview key={user} disableAccess={disableEditAccess("overview")} isProjectDeveloper={isProjectDeveloper} onChangeTools={updateLIstedTools} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("users") && <Route path="/users" element={<ProjectUsers key={user} disableAccess={disableEditAccess("overview")} role={user.platform_role} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("contract") && <Route path="/contract" element={<Contract key={user} disableAccess={disableEditAccess("contract")} isProjectDeveloper={isProjectDeveloper} />} />}
						{hasViewAccess("financial_assessment") && <Route path="/pre-assessment" element={<PreAssessment disableAccess={disableEditAccess("financial_assessment")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("financial_assessment") && <Route path="/profit-loss/*" element={<SpreadsheetComponent componentName="profit_loss" disableAccess={disableEditAccess("financial_assessment")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("financial_assessment") && <Route path="/financial-position/*" element={<SpreadsheetComponent componentName="financial_position" disableAccess={disableEditAccess("financial_assessment")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("financial_assessment") && <Route path="/cash-flow/*" element={<SpreadsheetComponent componentName="cash_flow" disableAccess={disableEditAccess("financial_assessment")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("financial_assessment") && <Route path="/kpis/*" element={<SpreadsheetComponent componentName="kpis" disableAccess={disableEditAccess("financial_assessment")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("financial_assessment") && <Route path="/output/*" element={<SpreadsheetComponent componentName="output" disableAccess={disableEditAccess("financial_assessment")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("risk_assessment_protocol") && <Route path="/risk-assessment-protocol/*" element={<RiskAssessmentProtocol disableAccess={disableEditAccess("risk_assessment_protocol")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("risk_assessment_protocol") && <Route path="/construction-risk/*" element={<ConstructionRisk disableAccess={disableEditAccess("risk_assessment_protocol")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("risk_assessment_protocol") && <Route path="/change-in-control-risk/*" element={<ChangeInControlRisk disableAccess={disableEditAccess("risk_assessment_protocol")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("risk_assessment_protocol") && <Route path="/management-risk/*" element={<ManagementRisk userCompanyId={companyId} disableAccess={disableEditAccess("risk_assessment_protocol")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("risk_assessment_protocol") && <Route path="/occupacy-risk/*" element={<OccupacyRisk disableAccess={disableEditAccess("risk_assessment_protocol")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("risk_assessment_protocol") && <Route path="/pre-payment-risk/*" element={<PrePaymentRisk disableAccess={disableEditAccess("risk_assessment_protocol")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("risk_assessment_protocol") && <Route path="/pipeline-risk/*" element={<PipelineRisk disableAccess={disableEditAccess("risk_assessment_protocol")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("risk_assessment_protocol") && <Route path="/interface-risk/*" element={<InterfaceRisk disableAccess={disableEditAccess("risk_assessment_protocol")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("risk_assessment_protocol") && <Route path="/o-m-risk/*" element={<OMRisk disableAccess={disableEditAccess("risk_assessment_protocol")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("risk_assessment_protocol") && <Route path="/performance-risk/*" element={<PerformanceRisk disableAccess={disableEditAccess("risk_assessment_protocol")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("risk_assessment_protocol") && <Route path="/technical-risk/*" element={<TechnicalRisk disableAccess={disableEditAccess("risk_assessment_protocol")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("risk_assessment_protocol") && <Route path="/currency-risk/*" element={<CurrencyRisk disableAccess={disableEditAccess("risk_assessment_protocol")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("risk_assessment_protocol") && <Route path="/energy-price-risk/*" element={<EnergyPriceRisk disableAccess={disableEditAccess("risk_assessment_protocol")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("risk_assessment_protocol") && <Route path="/market-risk/*" element={<MarketRisk disableAccess={disableEditAccess("risk_assessment_protocol")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("risk_assessment_protocol") && <Route path="/regulatory-risk/*" element={<RegulatoryRisk disableAccess={disableEditAccess("risk_assessment_protocol")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("risk_assessment_protocol") && <Route path="/credit-risk-implementor-client/*" element={<CreditRiskImplementorClient isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("risk_assessment_protocol") && <Route path="/credit-risk-service-provider/*" element={<CreditRiskServiceProvider isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("risk_assessment_protocol") && <Route path="/supply-chain-risk/*" element={<SupplyChainRisk disableAccess={disableEditAccess("risk_assessment_protocol")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("loan_application") && <Route path="/loan-application-overview/*" element={<LoanApplicationOverview key={companyId} userCompanyId={companyId} disableAccess={disableEditAccess("loan_application")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("loan_application") && <Route path="/loan-application-company-overview/*" element={<LoanApplicationCompanyOverview key={companyId} disableAccess={disableEditAccess("loan_application")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("loan_application") && <Route path="/loan-application-detailed-ee-information/*" element={<LoanApplicationDetailedEEInformation key={companyId} userCompanyId={companyId} disableAccess={disableEditAccess("loan_application")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("loan_application") && <Route path="/loan-application-technical-information/*" element={<LoanApplicationTechnicalInformation key={companyId} disableAccess={disableEditAccess("loan_application")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("loan_application") && <Route path="/loan-application-financial-information/*" element={<LoanApplicationFinancialInformation key={companyId} disableAccess={disableEditAccess("loan_application")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("loan_application") && <Route path="/loan-application-project-finance/*" element={<LoanApplicationProjectFinance key={companyId} disableAccess={disableEditAccess("loan_application")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("kyc") && <Route path="/kyc/*" element={<KycOverview key={companyId} userCompanyId={companyId} disableAccess={disableEditAccess("kyc")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("esg") && <Route path="/esg-enviromental/*" element={<EsgEnvironmental key={companyId} disableAccess={disableEditAccess("esg")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("esg") && <Route path="/esg-all-aspects/*" element={<EsgAllAspects key={companyId} disableAccess={disableEditAccess("esg")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("esg") && <Route path="/esg-social/*" element={<EsgSocial key={companyId} disableAccess={disableEditAccess("esg")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("esg") && <Route path="/esg-governance/*" element={<EsgGovernance key={companyId} disableAccess={disableEditAccess("esg")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
						{hasViewAccess("equad") && <Route path="/equad" element={<Equad key={user} disableAccess={disableEditAccess("equad")} isProjectDeveloper={isProjectDeveloper} onChangeProjectState={changeProjectState} />} />}
					</Routes>
				</div>
			</div>
		</div>
	);
};

export default memo(Project);
