import { useEffect, useState } from "react";
import { Button, ToggleButton } from "@mui/material";
import { UploadFile } from "@mui/icons-material";

import { uploadFile, reUploadFile } from "../api";
import { useSnackbar } from "../utils";

const FileUpload = ({ projectId: pid = null, oldFile: oFile = null, component: comp = "button", variableComponent: variabComp = "", variable: variab = "", disabled: dis = false, onSuccess = () => {} }) => {
	const [projectId, setProjectId] = useState(pid);
	const [oldFile, setOldFile] = useState(oFile);
	const [component, setComponent] = useState(comp);
	const [variableComponent, setVariableComponent] = useState(variabComp);
	const [variable, setVariable] = useState(variab);
	const [disabled, setDisabled] = useState(dis);
	const { success, error } = useSnackbar();

	useEffect(() => {
		setProjectId(pid);
	}, [pid]);

	useEffect(() => {
		setOldFile(oFile);
	}, [oFile]);

	useEffect(() => {
		setComponent(comp);
	}, [comp]);

	useEffect(() => {
		setVariableComponent(variabComp);
	}, [variabComp]);

	useEffect(() => {
		setVariable(variab);
	}, [variab]);

	useEffect(() => {
		setDisabled(dis);
	}, [dis]);

	const onFileUpload = async (e) => {
		const file = e.target.files[0];
		const { name } = file;

		const specialCharacters = /["*/:<>?\\|]/;

		if (specialCharacters.test(name.split(".pdf")[0])) {
			error("File name should not contain any special character");
		} else {
			const formData = new FormData();

			formData.append("projectId", projectId);
			formData.append("component", variableComponent);
			formData.append("variable", variable);
			if (oldFile) formData.append("oldFile", oldFile);
			formData.append("pdf", file);
			const response = await ((oldFile) ? reUploadFile(formData) : uploadFile(formData));
			if (response.success) {
				success("File uploaded successfully");
				onSuccess();
			} else {
				error("There was a problem uploading the file");
			}
		}
	};

	return (
		<>
			{component === "button"
			&& (
				<Button
					variant="text"
					size="small"
					sx={{ ":hover": { color: "common.black" }, px: 5, py: 1, color: "common.black" }}
					onClick={() => {
						const fileInput = document.querySelector("#file-input");
						fileInput.click();
					}}
				>
					{"Upload File Below"}
				</Button>
			)}
			{component !== "button"
			&& (
				<ToggleButton
					value="view"
					title="view"
					aria-label="view"
					sx={{ borderColor: "primary.main" }}
					disabled={disabled}
					onClick={() => {
						const fileInput = document.querySelector(`#file_input_${projectId}_${variableComponent}_${variable}`);
						fileInput.click();
					}}
				>
					<UploadFile color="primary" />
				</ToggleButton>
			)}
			<input
				id={`file_input_${projectId}_${variableComponent}_${variable}`}
				type="file"
				accept="application/pdf"
				style={{ display: "none", position: "relative", zIndex: "2" }}
				onChange={onFileUpload}
				onClick={(event) => { event.target.value = null; }}
			/>
		</>
	);
};

export default FileUpload;
