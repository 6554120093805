import PropTypes from "prop-types";
import { Navigate, useLocation } from "react-router-dom";
import queryString from "query-string";

import Unverified from "../screens/Unverified";
import { jwt } from "../utils";

import Header from "./Header";

const maybeSetToken = (Component) => (props) => {
	const { search } = useLocation();
	const { token } = queryString.parse(search);
	if (token) jwt.setToken(token);
	return <Component {...props} />;
};

const Protected = ({ c }) => {
	const location = useLocation();

	return jwt.isAuthenticated()
		? (jwt.isVerified()
			? (
				<>
					<Header />
					<div style={{ marginTop: "105px" }}>{c}</div>
				</>
			)
			: <Unverified />)
		: <Navigate replace to="/" state={{ from: location }} />;
};

Protected.propTypes = { c: PropTypes.node.isRequired };
Protected.whyDidYouRender = true;

export default maybeSetToken(Protected);
