import { memo, useCallback, useEffect, useState, forwardRef } from "react";
import { useParams } from "react-router-dom";
import { Button, Grid, LinearProgress, Typography, Link, Dialog, Slide, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import { useSnackbar, httpResponseIsValid } from "../../utils";
import { deleteFile, getProjectRiskAssessentProtocol, updateProjectRiskAssessentProtocol } from "../../api";
import usePrompt from "../../utils/use-blocker";
import {
	MainMenu,
	LabelTextField,
	LabelFileUpload,
	LabelRadio,
} from "../../components/basic/index";
import StatusDialog from "../../components/StatusDialog";

const Transition = forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const TechnicalRisk = ({ disableAccess = true, isProjectDeveloper = false, onChangeProjectState }) => {
	const { projectid } = useParams();
	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(disableAccess);
	const [projectStateDialog, setProjectStateDialog] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [projectInfo, setProjectInfo] = useState({
		name: "",
		status: "",
		createdAt: "-",
		technical_risk_doc_1: {
			original_name: "",
			save_name: "",
		},
		technical_risk_doc_2: {
			original_name: "",
			save_name: "",
		},
		technical_risk_doc_3: {
			original_name: "",
			save_name: "",
		},
		technical_risk_doc_4: {
			original_name: "",
			save_name: "",
		},
		technical_risk_doc_5: {
			original_name: "",
			save_name: "",
		},
		technical_risk_doc_6: {
			original_name: "",
			save_name: "",
		},
		technical_risk: {
			warranties: {
				original_name: "",
				save_name: "",
			},
			insurance_of_equipment: {
				original_name: "",
				save_name: "",
			},
			insurance_for_amounts: {
				original_name: "",
				save_name: "",
			},
			insurance_against: {
				original_name: "",
				save_name: "",
			},
			technical_risk_relevance: "",
			technical_risk_responsibility: "",
			technical_risk_mitigation: "",
			installed_equipment_monetary_term: "",
			installed_equipment: "",
			term_of_agreement: "",
			useful_life_term_of_agreement: "",
			depreciation_installed_equipment_1: "",
			depreciation_installed_equipment_2: "",
			ecodesign_regulation: "",
			implemented_projects: "",
			deductibles: "",
		},
	});
	const [enableEditing, setEnableEditing] = useState(false);
	const { success, error } = useSnackbar();
	const [deleteModal, setDeleteModal] = useState({ component: null, variable: null });

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getProjectRiskAssessentProtocol(projectid);
				if (httpResponseIsValid(response)) {
					setProjectInfo(response);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured while fetching risk assessment regulatory risk data: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, projectid],
	);

	const handleSubmit = async (values) => {
		setIsLoading(true);

		try {
			const response = await updateProjectRiskAssessentProtocol(projectid, values);

			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			error(`Error occured while submitting risk assessment regulatory risk language: ${_error}`);
		}

		setIsLoading(false);
	};

	const changeProjectState = useCallback(
		async () => {
			await onChangeProjectState();
			fetchData();
		}, [fetchData, onChangeProjectState],
	);

	const onDelete = async (comp) => {
		try {
			setIsLoading(true);
			const { success: deleteSuccess } = await deleteFile({ projectid, component: comp.component, variable: comp.variable });
			console.log(deleteSuccess);
			if (deleteSuccess) {
				success("File deleted successfully");
				await fetchData(false);
				setIsLoading(false);
			} else {
				error();
				setIsLoading(false);
			}
		} catch { /* empty */ }
	};

	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: projectInfo,
		onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	});
	const { dirty } = formik;
	usePrompt("You have unsaved changes which will be lost. Are you sure you want to leave the page?", dirty);

	useEffect(() => {
		setShouldUiBeDisabled(disableAccess || !enableEditing);
	}, [disableAccess, enableEditing]);

	useEffect(() => {
		formik.resetForm({ values: projectInfo, dirty: false });
	}, [projectInfo]);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData, projectid]);

	return (
		<form onSubmit={formik.handleSubmit}>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<MainMenu
				title={t("RiskAssessmentOverview.technicalRisk")}
				dirty={dirty}
				project={projectInfo}
				isDeveloper={isProjectDeveloper}
				disabled={disableAccess}
				onFetch={fetchData}
				onEdit={setEnableEditing}
				onChange={onChangeProjectState}
			/>
			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "105px" }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("RegulatoryRisk.relevance")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} alignItems="top">
					<Grid item xs={12} md={9} sm={8} p={1}>

						<LabelTextField
							id="technical_risk.installed_equipment_monetary_term"
							label={t("TechnicalRisk.relevanceVar1")}
							size="flat"
							typosize="h8"
							aligntitlebox="center"
							type='number'
							placeholder="Answer"
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
						<LabelTextField
							id="technical_risk.installed_equipment"
							label={t("TechnicalRisk.relevanceVar2")}
							size="flat"
							typosize="h8"
							aligntitlebox="center"
							type='number'
							placeholder="Answer"
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
						<LabelTextField
							id="technical_risk.term_of_agreement"
							label={t("TechnicalRisk.relevanceVar3")}
							size="flat"
							typosize="h8"
							aligntitlebox="center"
							type='number'
							placeholder="Answer"
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
						<LabelRadio
							id="technical_risk.useful_life_term_of_agreement"
							label={t("TechnicalRisk.relevanceVar4")}
							size="flat"
							typosize="h8"
							options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
						<LabelRadio
							id="technical_risk.depreciation_installed_equipment_1"
							// fieldId="technical_risk.depreciation_installed_equipment_2"
							label={t("TechnicalRisk.relevanceVar5")}
							placeholder="Over X Years"
							options={[{ value: "false", label: "Straight-Line" }, { value: "true", label: "Accelerated" }]}
							radioCase="depreciation"
							size="medium"
							typosize="h8"
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
						<LabelTextField
							id="technical_risk.depreciation_installed_equipment_2"
							label={t("Please specify over how many years is the depreciation projected to happen: ")}
							size="flat"
							typosize="h8"
							aligntitlebox="center"
							type='number'
							placeholder="Answer"
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
						<Typography variant="h8" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
							{"\n"}
							<b>{t("TechnicalRisk.relevanceVar6")}</b>
						</Typography>
						<LabelRadio
							id="technical_risk.ecodesign_regulation"
							label={t("TechnicalRisk.relevanceVar7")}
							size="flat"
							typosize="h8"
							options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
						<LabelRadio
							id="technical_risk.implemented_projects"
							label={t("TechnicalRisk.relevanceVar8")}
							size="flat"
							typosize="h8"
							options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>

						<Typography variant="h7" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
							{"\n"}
							{"* "}
							<Link href="https://energy.ec.europa.eu/topics/energy-efficiency/energy-label-and-ecodesign/list-energy-efficient-products-regulations-product-group_en?redir=1" underline="always">
								{"Ecodesign regulation"}
							</Link>
						</Typography>
					</Grid>
					<Grid item md={0.2} />
					<Grid item xs={12} sm={4} md={2} direction="row" alignItems="center">
						<LabelRadio
							id="technical_risk.technical_risk_relevance"
							label={t("RegulatoryRisk.riskPresent")}
							size="large"
							options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
					</Grid>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("RegulatoryRisk.responsibility")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center">
					<Grid item xs={12} md={9} sm={8} p={1}>
						<Typography variant="h8" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
							{t("TechnicalRisk.responsibilityVar1")}
						</Typography>
					</Grid>
					<Grid item md={0.2} />
					<Grid item xs={12} sm={4} md={2} direction="row" alignItems="center">
						<LabelRadio
							id="technical_risk.technical_risk_responsibility"
							label={t("RegulatoryRisk.riskBearer")}
							size="large"
							options={[{ value: "false", label: "Client" }, { value: "true", label: "Contractor" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
					</Grid>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("EnergyPriceRisk.mitigation")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} alignItems="top">
					<Grid item xs={12} md={9} sm={8} p={1}>
						<Typography variant="h8" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
							{t("TechnicalRisk.mitigationVar1")}
							{"\n\n"}
							{t("TechnicalRisk.mitigationVar2")}
							{"\n"}
							{t("TechnicalRisk.mitigationVar3")}
							{"\n"}
							{t("TechnicalRisk.mitigationVar4")}
							{"\n"}
							{t("TechnicalRisk.mitigationVar5")}
							{"\n"}
							{t("TechnicalRisk.mitigationVar6")}
						</Typography>
					</Grid>
					<Grid item md={0.2} />
					<Grid item xs={12} sm={4} md={2} direction="row" alignItems="center">
						<LabelRadio
							id="technical_risk.technical_risk_mitigation"
							label={t("EnergyPriceRisk.fullyMitigated")}
							size="large"
							options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
					</Grid>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("RegulatoryRisk.documentation")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center">
					<Grid item container sx={{ p: 1 }} alignItems="center">
						<LabelFileUpload
							fieldId="technical_risk_doc_1"
							projectId={projectid}
							label={t("TechnicalRisk.documentationVar1")}
							size="large"
							typosize="h8"
							disabled={shouldUiBeDisabled}
							formik={formik}
							fileTool="riskAssessmentProtocol"
							onSuccess={() => fetchData()}
						/>

					</Grid>
					<Grid item container sx={{ p: 1 }} alignItems="center">
						<LabelFileUpload
							fieldId="technical_risk_doc_2"
							projectId={projectid}
							label={t("TechnicalRisk.documentationVar2")}
							size="large"
							typosize="h8"
							disabled={shouldUiBeDisabled}
							formik={formik}
							fileTool="riskAssessmentProtocol"
							onSuccess={() => fetchData()}
						/>
					</Grid>
					<Grid item container sx={{ p: 1 }} alignItems="center">
						<LabelFileUpload
							fieldId="technical_risk_doc_3"
							projectId={projectid}
							label={t("TechnicalRisk.documentationVar3")}
							size="large"
							typosize="h8"
							disabled={shouldUiBeDisabled}
							formik={formik}
							fileTool="riskAssessmentProtocol"
							onSuccess={() => fetchData()}
						/>
					</Grid>
					<Grid item container sx={{ p: 1 }} alignItems="center">
						<LabelFileUpload
							fieldId="technical_risk_doc_4"
							projectId={projectid}
							label={t("TechnicalRisk.documentationVar4")}
							size="large"
							typosize="h8"
							disabled={shouldUiBeDisabled}
							formik={formik}
							fileTool="riskAssessmentProtocol"
							onSuccess={() => fetchData()}
						/>
					</Grid>
					<Grid item container sx={{ p: 1 }} alignItems="center">
						<LabelFileUpload
							fieldId="technical_risk_doc_5"
							projectId={projectid}
							label={t("TechnicalRisk.documentationVar5")}
							size="large"
							typosize="h8"
							disabled={shouldUiBeDisabled}
							formik={formik}
							fileTool="riskAssessmentProtocol"
							onSuccess={() => fetchData()}
						/>
						{/* <Grid item xs={12} md={9} p={1}>
							<Typography variant="h7" sx={{ whiteSpace: "pre-line", m: 3 }}>
								<b>{"5. "}</b>
								{t("TechnicalRisk.documentationVar5")}
							</Typography>
						</Grid> */}
					</Grid>
					<Grid item container sx={{ p: 1 }} alignItems="center">
						<LabelFileUpload
							fieldId="technical_risk_doc_6"
							projectId={projectid}
							label={t("TechnicalRisk.documentationVar6")}
							size="large"
							typosize="h8"
							disabled={shouldUiBeDisabled}
							formik={formik}
							fileTool="riskAssessmentProtocol"
							onSuccess={() => fetchData()}
						/>
					</Grid>
				</Grid>
			</Grid>
			{deleteModal.component !== null
				&& deleteModal.variable !== null
				&& (
					<Dialog
						keepMounted
						open={deleteModal.component !== null && deleteModal.variable !== null}
						TransitionComponent={Transition}
						onClose={() => setDeleteModal({ component: null, variable: null })}
					>
						<DialogTitle>
							{"Are you sure?"}
						</DialogTitle>
						<DialogContent dividers>
							<DialogContentText>
								{"Are you sure you want to delete the file?"}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								autoFocus
								startIcon={<Delete />}
								variant="contained"
								onClick={() => {
									onDelete(deleteModal);
									setDeleteModal({ component: null, variable: null });
								}}
							>
								{"Delete"}
							</Button>
							<Button variant="outlined" onClick={() => setDeleteModal({ component: null, variable: null })}>{"Cancel"}</Button>
						</DialogActions>
					</Dialog>
				)}
			<StatusDialog
				open={projectStateDialog}
				status={projectInfo.status}
				name={projectInfo.name}
				onClose={setProjectStateDialog}
				onChange={changeProjectState}
			/>
		</form>
	);
};

export default memo(TechnicalRisk);
