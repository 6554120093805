/* eslint-disable unicorn/consistent-destructuring */
import { memo, useState } from "react";
import { Grid, Typography, Button, Link, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
// import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import EmailIcon from "@mui/icons-material/Email";
import * as Yup from "yup";

import { useSnackbar, httpResponseIsValid } from "../utils";
import { forgotPassword } from "../api";
import Spinner from "../components/Spinner";
import background from "../assets/images/background.jpg";
import {
	LoginInput,
	LoginInputStyles,
} from "../components/login/index";

const ForgotEmailSchema = Yup.object().shape({
	email: Yup.string().trim().email().required("E-mail is required"),
});

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(0, -1),
		overflow: "hidden",
		width: "100vw",
		height: "100vh",
		backgroundImage: `url(${background})`,
		backgroundPosition: "center",
		backgroundSize: "cover",
	},
	title: {
		color: theme.palette.common.white,
		letterSpacing: theme.spacing(0.01),
	},
	subtitle: {
		color: theme.palette.green.main,
		letterSpacing: theme.spacing(0.05),
	},
	buttontitle: {
		color: theme.palette.primary.main,
		letterSpacing: theme.spacing(0.1),
	},
	buttonLarge: {
		backgroundColor: theme.palette.green.main,
		color: theme.palette.common.white,
		borderRadius: "8px",
		width: "320px",
		"&:hover": {
			backgroundColor: "#248F6A",
		},
	},
	loginInputIcons: LoginInputStyles.iconStyle,
}));

const ForgotPassword = () => {
	// const { state } = useLocation();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const classes = useStyles();
	const { success, error } = useSnackbar();

	const handleSubmit = async (values) => {
		setIsSubmitting(true);
		try {
			console.log("Forgot pass");
			const response = await forgotPassword({ email: values.email });
			if (httpResponseIsValid(response)) {
				success(response?.message);
				console.log("Forgot pass success");
			} else {
				console.log(response?.message);
				error(response?.message);
				console.log("Forgot pass fail");
			}
		} catch (_error) {
			error(`Error occured while requesting a resetting password email: ${_error}`);
			console.log("Forgot pass fail");
		}

		setIsSubmitting(false);
	};

	const formik = useFormik({
		initialValues: {
			email: "",
		},
		validationSchema: ForgotEmailSchema,
		onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	});

	const { errors, touched } = formik;

	return (
		<form onSubmit={formik.handleSubmit}>
			<Spinner open={isSubmitting} />
			<Grid container direction="row" justifyContent="center" align="center" className={classes.root}>
				<Grid item container direction="column" justifyContent="center" align="center" sm={5} xs={12} sx={{ "> .MuiGrid-item": { p: 1 } }}>
					<Grid item container direction="column" alignItems="center" justifyContent="center">
						<Grid item mb={1} sx={{ width: "320px" }}>
							<Typography variant="h4" className={classes.title}>{"Trouble signing in?"}</Typography>
						</Grid>
					</Grid>
					<Grid item container direction="column" justifyContent="center" alignItems="center">
						<Grid item>
							<LoginInput
								id="email"
								icon={<EmailIcon className={classes.loginInputIcons} />}
								name="Email Address"
								value={formik.values.email}
								error={errors.email && touched.email ? errors.email : ""}
								onChange={formik.handleChange}
							/>
						</Grid>
						<Grid item mb={errors.email && touched.email ? 4 : 2} />
						<Grid item>
							<Button type="submit" className={classes.buttonLarge} variant="contained" size="large">
								<Typography variant="h7" className={classes.buttonTitle}>
									<b>{"Reset Password"}</b>
								</Typography>
							</Button>
						</Grid>
					</Grid>
					<Grid item container direction="column" justifyContent="center" alignItems="space-between">
						<Grid item>
							<Typography variant="h7" color="white">{"Otherwise "}</Typography>
							<Typography variant="h7" className={classes.subtitle}>
								<Link color="inherit" underline="none" href="/">{"Sign In Here"}</Link>
							</Typography>
						</Grid>
						<Grid item>
							<Divider style={{ width: "200px", margin: "0px", marginTop: "5px", marginBottom: "5px", borderBottom: "2px solid #dddddd", borderRight: "2px solid #dddddd" }} />
						</Grid>
						<Grid item>
							<Typography variant="h7" color="white">
								{"Don't have an account? "}
							</Typography>
							<Typography variant="h7" className={classes.subtitle}>
								<Link color="inherit" sx={{ "&:hover": { color: "#76EAC3" } }} underline="none" href="sign-up">
									{"Sign Up Here"}
								</Link>
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item sm={7} />
			</Grid>
		</form>
	);
};

export default memo(ForgotPassword);
