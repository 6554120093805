import { memo, useCallback, useEffect, useState, forwardRef } from "react";
import { useParams } from "react-router-dom";
import { Button, Grid, LinearProgress, Typography, Dialog, Slide, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import { useSnackbar, httpResponseIsValid } from "../../utils";
import { deleteFile, updateProjectRiskAssessentProtocol, getProjectRiskAssessentProtocolManagementCompanyDocs } from "../../api";
import usePrompt from "../../utils/use-blocker";
import StatusDialog from "../../components/StatusDialog";
import {
	MainMenu,
	LabelTextField,
	LabelRadio,
	LabelFileUpload,
} from "../../components/basic/index";

const Transition = forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const ManagementRisk = ({ userCompanyId = null, disableAccess = true, isProjectDeveloper = false, onChangeProjectState }) => {
	const { projectid } = useParams();
	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(disableAccess);
	const [projectStateDialog, setProjectStateDialog] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [companyId, setCompanyId] = useState(userCompanyId);
	const [projectInfo, setProjectInfo] = useState({
		status: "",
		createdAt: "-",
		management_risk: {
			management_risk_relevance: "",
			management_risk_responsibility: "",
			type_of_entity: "",
			sector_of_activity: "",
			number_of_employees: "",
			ownership_structure: {
				original_name: "",
				save_name: "",
			},
			key_management: {
				original_name: "",
				save_name: "",
			},
		},
		docs: {
			management_doc_1: {
				original_name: "",
				save_name: "",
			},
			management_doc_2: {
				original_name: "",
				save_name: "",
			},
			management_doc_3: {
				original_name: "",
				save_name: "",
			},
			management_doc_4: {
				original_name: "",
				save_name: "",
			},
			management_doc_5: {
				original_name: "",
				save_name: "",
			},
		},

	});
	const [enableEditing, setEnableEditing] = useState(false);
	const [user, setUser] = useState({
		company_id: "",
	});
	const { success, error } = useSnackbar();
	const [deleteModal, setDeleteModal] = useState({ component: null, variable: null });

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			let response;
			try {
				response = await getProjectRiskAssessentProtocolManagementCompanyDocs(projectid);
				if (httpResponseIsValid(response)) {
					setProjectInfo(response);
					if (!companyId) {
						setCompanyId(response.company_id);
					}
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured while fetching risk assessment mamagement risk data: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, projectid, companyId],
	);

	const handleSubmit = async (values) => {
		setIsLoading(true);

		try {
			const response = await updateProjectRiskAssessentProtocol(
				projectid, values,
			);

			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			error(`Error occured while submitting risk assessment management risk data: ${_error}`);
		}

		setIsLoading(false);
	};

	const changeProjectState = useCallback(
		async () => {
			await onChangeProjectState();
			fetchData();
		}, [fetchData, onChangeProjectState],
	);

	const onDelete = async (comp) => {
		try {
			setIsLoading(true);

			const projectId = comp.component === "documents" ? undefined : projectid;
			// eslint-disable-next-line max-len
			const { success: deleteSuccess } = await deleteFile({ projectid: projectId, component: comp.component, variable: comp.variable });
			console.log(deleteSuccess);
			if (deleteSuccess) {
				success("File deleted successfully");
				await fetchData(false);
				setIsLoading(false);
			} else {
				error();
				setIsLoading(false);
			}
		} catch { /* empty */ }
	};

	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: projectInfo,
		onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	});

	const { dirty } = formik;
	usePrompt("You have unsaved changes which will be lost. Are you sure you want to leave the page?", dirty);

	useEffect(() => {
		setShouldUiBeDisabled(disableAccess || !enableEditing);
	}, [disableAccess, enableEditing]);

	useEffect(() => {
		setCompanyId(userCompanyId);
	}, [userCompanyId]);

	useEffect(() => {
		formik.resetForm({ values: projectInfo, dirty: false });
	}, [projectInfo]);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData, projectid]);

	return (
		<form onSubmit={formik.handleSubmit}>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<MainMenu
				title={t("RiskAssessmentOverview.managementRisk")}
				dirty={dirty}
				project={projectInfo}
				isDeveloper={isProjectDeveloper}
				disabled={disableAccess}
				onFetch={fetchData}
				onEdit={setEnableEditing}
				onChange={onChangeProjectState}
			/>
			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "105px" }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("RegulatoryRisk.relevance")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} alignItems="top">
					<Grid item xs={12} md={9} sm={8} p={1}>
						<Typography variant="h8" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
							{t("ManagementRisk.relevanceVar1")}
							{"\n"}
							<LabelTextField
								id="management_risk.type_of_entity"
								label={t("ManagementRisk.relevanceVar2")}
								size="flat"
								typosize="h8"
								aligntitlebox="center"
								placeholder="Answer"
								disabled={shouldUiBeDisabled}
								formik={formik}
							/>
							<LabelTextField
								id="management_risk.sector_of_activity"
								label={t("ManagementRisk.relevanceVar3")}
								size="flat"
								typosize="h8"
								aligntitlebox="center"
								placeholder="Answer"
								disabled={shouldUiBeDisabled}
								formik={formik}
							/>
							<LabelTextField
								id="management_risk.number_of_employees"
								label={t("ManagementRisk.relevanceVar4")}
								size="flat"
								typosize="h8"
								aligntitlebox="center"
								placeholder="Answer"
								type="number"
								disabled={shouldUiBeDisabled}
								formik={formik}
							/>
							{"\n"}
						</Typography>
						<Typography variant="h8" sx={{ whiteSpace: "pre-line", color: "blue.dark", ml: 1 }}>
							{t("ManagementRisk.relevanceVar5")}
							{"\n\n"}
						</Typography>
						<Typography variant="h8" sx={{ whiteSpace: "pre-line", color: "blue.dark", ml: 1 }}>
							{t("ManagementRisk.relevanceVar6")}
						</Typography>
						{"\n"}
					</Grid>
					<Grid item md={0.2} />
					<Grid item xs={12} sm={4} md={2} direction="row" alignItems="center">
						<LabelRadio
							id="management_risk.management_risk_relevance"
							label={t("RegulatoryRisk.riskPresent")}
							size="large"
							options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
					</Grid>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("RegulatoryRisk.responsibility")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center">
					<Grid item xs={12} md={9} sm={8} p={1}>
						<Typography variant="h8" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
							{t("ManagementRisk.responsibilityVar1")}
						</Typography>
					</Grid>
					<Grid item md={0.2} />
					<Grid item xs={12} sm={4} md={2} direction="row" alignItems="center">
						<LabelRadio
							id="management_risk.management_risk_responsibility"
							label={t("RegulatoryRisk.riskBearer")}
							size="large"
							options={[{ value: "false", label: "Client" }, { value: "true", label: "Contractor" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
					</Grid>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("RegulatoryRisk.documentation")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} alignItems="center" justifyContent="space-between">
					<LabelFileUpload
						viewOnly
						fieldId="docs.management_doc_1"
						projectId={user.company_id}
						label={t("ManagementRisk.documentationVar1")}
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						viewOnly
						fieldId="docs.management_doc_2"
						projectId={user.company_id}
						label={t("ManagementRisk.documentationVar2")}
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						viewOnly
						fieldId="docs.management_doc_4"
						projectId={user.company_id}
						label={t("ManagementRisk.documentationVar4")}
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						viewOnly
						fieldId="docs.management_doc_3"
						projectId={user.company_id}
						label={t("ManagementRisk.documentationVar3")}
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="documents"
						onSuccess={() => fetchData()}
					/>
				</Grid>
			</Grid>
			{deleteModal.component !== null
				&& deleteModal.variable !== null
				&& (
					<Dialog
						keepMounted
						open={deleteModal.component !== null && deleteModal.variable !== null}
						TransitionComponent={Transition}
						onClose={() => setDeleteModal({ component: null, variable: null })}
					>
						<DialogTitle>
							{"Are you sure?"}
						</DialogTitle>
						<DialogContent dividers>
							<DialogContentText>
								{"Are you sure you want to delete the file?"}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								autoFocus
								startIcon={<Delete />}
								variant="contained"
								onClick={() => {
									onDelete(deleteModal);
									setDeleteModal({ component: null, variable: null });
								}}
							>
								{"Delete"}
							</Button>
							<Button variant="outlined" onClick={() => setDeleteModal({ component: null, variable: null })}>{"Cancel"}</Button>
						</DialogActions>
					</Dialog>
				)}
			<StatusDialog
				open={projectStateDialog}
				status={projectInfo.status}
				name={projectInfo.name}
				onClose={setProjectStateDialog}
				onChange={changeProjectState}
			/>
		</form>
	);
};

export default memo(ManagementRisk);
