
import { forwardRef, memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography, ToggleButtonGroup, ToggleButton, Dialog, Slide, DialogTitle, IconButton, DialogContent, InputAdornment, Button, LinearProgress } from "@mui/material";
import { Close, Edit } from "@mui/icons-material";

import Table from "../../components/Table";
import TextField from "../../components/TextField";
import { useSnackbar, spreadsheet, names, httpResponseIsValid } from "../../utils";
import { getProjectSpreadsheet, updateProjectSpreadsheet } from "../../api";

const Transition = forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const TableComponent = ({ screen, variables, isPercentage = false, currency = "euro", blocked = false }) => {
	const { projectid } = useParams();
	const [initialFetching, setInitialFetching] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [dialogOpen, setDialogOpen] = useState(null);
	const [financialAssessmentCurrency, setFinancialAssessmentCurrency] = useState(currency);
	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(blocked);
	const [projectInfo, setProjectInfo] = useState({
		status: "-",
		createdAt: "-",
	});

	const [tempData, setTempData] = useState({});
	const { error } = useSnackbar();

	const openDialog = useCallback(
		(value) => {
			setTempData(projectInfo);
			setDialogOpen(value);
		},
		[projectInfo],
	);

	const onValueChange = (event, id, period) => {
		setTempData({
			...tempData,
			[screen]: {
				...tempData[screen],
				[id]: {
					...(tempData[screen][id]),
					[period]: Number.parseFloat(event.target.value),
				},
			},
		});
	};

	const onValueClick = (event, id, period) => {
		if (event.target.value === "0") {
			setTempData({
				...tempData,
				[screen]: {
					...tempData[screen],
					[id]: {
						...(tempData[screen][id]),
						[period]: "",
					},
				},
			});
		}
	};

	const onValueLeave = (event, id, period) => {
		if (event.target.value === "") {
			setTempData({
				...tempData,
				[screen]: {
					...tempData[screen],
					[id]: {
						...(tempData[screen][id]),
						[period]: "0",
					},
				},
			});
		}
	};

	const onDialogClose = () => setDialogOpen(null);

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getProjectSpreadsheet(projectid);
				if (httpResponseIsValid(response)) {
					setProjectInfo(response);
					setInitialFetching(false);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured while fetching financial assessment table data: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, projectid],
	);

	const submit = async () => {
		setIsLoading(true);
		setDialogOpen(null);

		try {
			for (const spreadsheetKey of Object.keys(spreadsheet)) {
				for (const [key, obj] of Object.entries(spreadsheet[spreadsheetKey]).filter(([, el]) => el.editable)) {
					for (const variable of variables) {
						const variableResult = obj.calculate(Object.values(obj.dependsOn).map((str) => [...str.split("."), variable].reduce((r, k) => r?.[k], tempData))) || 0;
						tempData[spreadsheetKey][key][variable] = Number.isFinite(variableResult) ? variableResult : 0;
						console.log("Value:", variableResult, tempData[spreadsheetKey][key][variable]);
					}
				}
			}

			for (const spreadsheetKey of Object.keys(spreadsheet)) {
				for (const [key, obj] of Object.entries(spreadsheet[spreadsheetKey]).filter(([, el]) => !el.editable)) {
					for (const variable of variables) {
						const variableResult = obj.calculate(Object.values(obj.dependsOn).map((str) => [...str.split("."), variable].reduce((r, k) => r?.[k], tempData))) || 0;
						tempData[spreadsheetKey][key][variable] = Number.isFinite(variableResult) ? variableResult : 0;
					}
				}
			}

			const response = await updateProjectSpreadsheet(projectid, tempData);
			if (httpResponseIsValid(response)) {
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			error(`Error occured while submiting financial assessment table data: ${_error}`);
		}
	};

	useEffect(() => {
		setShouldUiBeDisabled(blocked);
	}, [blocked, setShouldUiBeDisabled]);

	useEffect(() => {
		setFinancialAssessmentCurrency(currency);
	}, [currency, setFinancialAssessmentCurrency]);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData, projectid]);

	const tableColumns = [
		{
			Header: <Typography variant="h6">{"Financial Metric"}</Typography>,
			accessor: "id",
			id: "metric",
			filterable: false,
			sortable: false,
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center" }}>
					<Typography
						sx={
							(spreadsheet[screen][value].editable
								? {}
								: {
									fontWeight: "bold",
									fontStyle: "italic",
									color: "primary.main",
								})
						}
					>
						{names[screen][value]}
					</Typography>
				</Box>
			),
		},
		variables.map((variable) => ({
			Header: <Typography variant="h6">{names[variable]}</Typography>,
			accessor: (e) => ({ v: e[variable], id: e.id }),
			id: variable,
			filterable: false,
			sortable: false,
			width: 120,
			show: screen !== "output",
			Cell: ({ value: { v, id } }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography
						sx={
							(spreadsheet[screen][id].editable
								? {}
								: {
									fontWeight: "bold",
									fontStyle: "italic",
									color: "primary.main",
								})
						}
					>
						{`${isPercentage
							? (spreadsheet[screen][id].disablePercentage ? v.toFixed(1) : (v * 100).toFixed(0))
							: v.toFixed(1)}
							${isPercentage ? (spreadsheet[screen][id].disablePercentage ? "" : "%") : (financialAssessmentCurrency === "euro" ? "€" : (financialAssessmentCurrency === "pound" ? "£" : (financialAssessmentCurrency === "dollar" ? "$" : (financialAssessmentCurrency === "zloty" ? "zl" : (financialAssessmentCurrency === "chf" ? "CHF" : "")))))}`}
					</Typography>
				</Box>
			),
		})),
		variables.map((variable) => ({
			Header: <Typography variant="h6">{names[variable]}</Typography>,
			accessor: (e) => ({ v: e[variable], id: e.id }),
			id: variable,
			filterable: false,
			sortable: false,
			width: 120,
			show: screen === "output" && (variable === "year_1" || variable === "year_2" || variable === "year_3"),
			Cell: ({ value: { v, id } }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography
						sx={
							(spreadsheet[screen][id].editable
								? {}
								: {
									fontWeight: "bold",
									fontStyle: "italic",
									color: "primary.main",
								})
						}
					>
						{`${isPercentage
							? (spreadsheet[screen][id].disablePercentage ? v.toFixed(1) : (v * 100).toFixed(0))
							: v.toFixed(1)}
							${isPercentage ? (spreadsheet[screen][id].disablePercentage ? "" : "%") : (financialAssessmentCurrency === "euro" ? "€" : (financialAssessmentCurrency === "pound" ? "£" : (financialAssessmentCurrency === "dollar" ? "$" : (financialAssessmentCurrency === "zloty" ? "zl" : (financialAssessmentCurrency === "chf" ? "CHF" : "")))))}`}
					</Typography>
				</Box>
			),
		})),

		{
			Header: <Typography variant="h6">{"Sum"}</Typography>,
			accessor: (e) => ({ v: e[variables[0]], c: e[variables[1]], d: e[variables[2]], l: e[variables[3]], id: e.id }),
			id: variables[0],
			filterable: false,
			sortable: false,
			width: 120,
			show: screen === "output" && (variables[2] !== "year_1" || variables[1] !== "year_2" || variables[0] !== "year_3"),
			Cell: ({ value: { v, c, d, l, id } }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography
						sx={
							(spreadsheet[screen][id].editable
								? {}
								: {
									fontWeight: "bold",
									fontStyle: "italic",
									color: "primary.main",
								})
						}
					>
						{`${isPercentage
							? (spreadsheet[screen][id].disablePercentage ? v.toFixed(1) : (v * 100).toFixed(0))
							: (v + c + d + l).toFixed(1)}
							${isPercentage ? (spreadsheet[screen][id].disablePercentage ? "" : "%") : (financialAssessmentCurrency === "euro" ? "€" : (financialAssessmentCurrency === "pound" ? "£" : (financialAssessmentCurrency === "dollar" ? "$" : (financialAssessmentCurrency === "zloty" ? "zl" : (financialAssessmentCurrency === "chf" ? "CHF" : "")))))}`}
					</Typography>
				</Box>
			),
		},

		{
			Header: <Typography variant="h6">{"Action"}</Typography>,
			accessor: "id",
			filterable: false,
			sortable: false,
			width: 100,
			show: screen !== "kpis" && screen !== "output",
			Cell: ({ value }) => (
				spreadsheet[screen][value].editable
					? (
						<ToggleButtonGroup exclusive size="small" aria-label="actions">
							<ToggleButton
								value="view"
								title="view"
								aria-label="view"
								sx={{ borderColor: "primary.main" }}
								disabled={shouldUiBeDisabled}
								onClick={() => openDialog(value)}
							>
								<Edit color="primary" />
							</ToggleButton>
						</ToggleButtonGroup>
					) : null
			),
		},
	].flat();

	return (
		<>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 } }}>
				<Grid item sx={{ width: "100%" }}>
					{!initialFetching
						&& (
							<Table
								data={Object.keys(spreadsheet[screen]).map((el) => ({ id: el, ...(projectInfo[screen][el]) }))}
								noDataText="No data available!"
								columns={tableColumns}
								showPageSizeOptions={false}
								customPageSize={40}
								defaultSorted={[]}
							/>
						)}
				</Grid>
			</Grid>
			<Dialog
				fullWidth
				open={dialogOpen !== null}
				TransitionComponent={Transition}
				maxWidth="md"
				scroll="body"
				PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4" } }}
				onClose={onDialogClose}
			>
				<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
					<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>{names[screen][dialogOpen]}</Typography>
					<IconButton
						size="small"
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: "common.white",
						}}
						disabled={shouldUiBeDisabled}
						onClick={onDialogClose}
					>
						<Close />
					</IconButton>
				</DialogTitle>
				<DialogContent dividers sx={{ overflowY: "hidden" }}>
					<Grid container justifyContent="space-around">
						<Grid item sm={variables.length === 2 ? 3 : 2} />
						{variables.map((variable) => (
							<Grid key={variable} item sm={variables.length === 2 ? 3 : 2} textAlign="center" fontWeight="bold">
								{names[variable]}
							</Grid>
						))}
					</Grid>
					{dialogOpen !== null
						&& Object.keys(spreadsheet[screen][dialogOpen].dependsOn).map((v) => (
							<Grid key={v} container mt={3} justifyContent="space-around">
								<Grid item sm={variables.length === 2 ? 3 : 2} display="flex" alignItems="center">
									{names[screen][v]}
								</Grid>
								{variables.map((variable) => (
									<Grid key={variable} item px={1} sm={variables.length === 2 ? 3 : 2} display="flex" textAlign="center">
										<TextField
											type="number"
											size="small"
											value={tempData[screen][v][variable]}
											variant="filled"
											sx={{ input: { color: "#1d4363", fontWeight: "bold" } }}
											InputProps={{
												disableUnderline: true, endAdornment: <InputAdornment position="end"><div style={{ color: "#1d4363", fontWeight: "bold" }}>{(financialAssessmentCurrency === "euro" ? "€" : (financialAssessmentCurrency === "pound" ? "£" : (financialAssessmentCurrency === "dollar" ? "$" : (financialAssessmentCurrency === "zloty" ? "zl" : (financialAssessmentCurrency === "chf" ? "CHF" : "")))))}</div></InputAdornment>,
											}}
											disabled={shouldUiBeDisabled}
											onClick={(e) => onValueClick(e, v, variable)}
											onChange={(e) => onValueChange(e, v, variable)}
											onBlur={(e) => onValueLeave(e, v, variable)}
										/>
									</Grid>
								))}
							</Grid>
						))}
					<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="50px">
						<Grid item sm={3} display="flex" justifyContent="start" marginLeft="30px">
							<Button
								fullWidth
								variant="outlined"
								size="large"
								sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
								color="primary"
								onClick={onDialogClose}
							>
								{"Cancel"}
							</Button>
						</Grid>
						<Grid item sm={1} />
						<Grid item display="flex" sm={2} justifyContent="end" marginRight="40px">
							<Button
								fullWidth
								variant="contained"
								size="large"
								sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
								color="primary"
								onClick={submit}
							>
								{"Save"}
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default memo(TableComponent);
