/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */

import { memo, useEffect, forwardRef, useState } from "react";
import { Link, Route, Routes, useLocation, useParams } from "react-router-dom";
import { Grid, Tab, Tabs, Typography, Box, List, ListItem, Divider, ListItemText, Drawer, Button, Link as LinkMui, Paper, Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { jwt, useSnackbar } from "../../utils";

import ToolsAndResources from "./ToolsAndResources";
import QnA from "./QnA";
import ExpertContactForm from "./ExpertContactForm";

const { REACT_APP_MAIN_SERVER_URL } = process.env;

const useStyles = makeStyles((theme) => ({
	screenTitle: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		letterSpacing: theme.spacing(0.2),
	},
}));

const Transition = forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const CoursesAndTraining = () => {
	const { pathname } = useLocation();
	const { companyid } = useParams();
	const [height, setHeight] = useState(100);
	const { error } = useSnackbar();
	const [copiedValue, setCopiedValue] = useState("");
	const [redirectDialog, setRedirectDialog] = useState(false);
	const [redirectSite, setRedirectSite] = useState({
		name: "",
		link: "",
	});

	const classes = useStyles();

	const onRedirect = () => {
		try {
			window.open(redirectSite.link);
		} catch (_error) {
			error(`Error occured while redirecting to Nearpod tutorial screen: ${_error}`);
		}

		setRedirectDialog(false);
	};

	return (
		<>
			<Grid container direction="column" alignItems="center" py={1} px={3} sx={{ "> .MuiGrid-item": { p: 1 }, position: "fixed", maxWidth: "1300px", width: "calc(100% - 300px)", background: "white", zIndex: 999 }}>
				<Grid container item direction="row" alignItems="center" sx={{ "> .MuiGrid-item": { p: 0 }, background: "#F4F4F4", borderRadius: "10px 10px 10px 10px" }}>
					<Grid item xs={12} md={7}>
						<Typography variant="h5" className={classes.screenTitle}>{"COURSES AND TRAINING"}</Typography>
					</Grid>
					<Grid item xs={12} md={5} />
				</Grid>
			</Grid>
			{/* <Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "45px" }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} mb={2} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{"RISK ASSESSMENT PROTOCOL"}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} justifyContent="space-between">
					<Grid item container sx={{ p: 1 }} alignItems="center">
						<Grid item md={7}>
							<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
								{"This is a course about the Risk Assessment Protocol, or RAP. It contains useful information about risk assessment and tips on completing it."}
							</Typography>
						</Grid>
						<Grid item container md={5} justifyContent="end" alignItems="start">
							<Button
								component={Link}
								variant="outlined"
								size="medium"
								color="primary"
								sx={{ width: "300px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c", color: "common.white" }, px: 3 }}
								to=""
							>
								{"Access RAP Udemy course here!"}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid> */}
			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "45px" }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} mb={2} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{"UDEMY COURSES"}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} justifyContent="space-between">
					<Grid item container sx={{ p: 1 }} alignItems="center">
						<Grid item md={7}>
							<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
								{"In this course you'll meet the experts behind EU project PROPEL and SEFA and learn about the current challenges in Europe's energy sector. We'll introduce you to the hybrid business model and show you how it can help get your projects running."}
							</Typography>
							<br />
							<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
								{"We'll also delve into the collateral we have developed giving you an in-depth understanding of risk assessment, equity collateral, sales & marketing, and know your customer (KYC) guidelines."}
							</Typography>
							<br />
							<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
								{"Finally, we'll introduce you to the Member Access Platform, a software tool suite to help you apply what you have learned to your own projects."}
							</Typography>
						</Grid>
						<Grid item container md={5} justifyContent="end" alignItems="start">
							<Button
								variant="outlined"
								size="medium"
								color="primary"
								sx={{ width: "300px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c", color: "common.white" }, px: 3 }}
								onClick={() => { setRedirectSite({ name: "https://sefaeu.us8.list-manage.com/", link: "https://sefaeu.us8.list-manage.com/subscribe?u=d23b43db1d216681ee0244784&id=944f1abe9e" }); setRedirectDialog(true); }}
							>
								{"Access Udemy course here!"}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 } }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} mb={2} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{"CBP"}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} justifyContent="space-between">
					<Grid item container sx={{ p: 1 }} alignItems="center">
						<Grid item md={7}>
							<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
								{"This is a course about financial information, and contains useful information about SEFA platform."}
							</Typography>
						</Grid>
						<Grid item container md={5} justifyContent="end" alignItems="start">
							<Button
								component={Link}
								variant="outlined"
								size="medium"
								color="primary"
								sx={{ width: "300px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c", color: "common.white" }, px: 3 }}
								to=""
							>
								{"Access CBP Udemy course here!"}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 } }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} mb={2} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{"NEARPOD COURSES"}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} justifyContent="space-between">
					<Grid item container sx={{ p: 1 }} alignItems="center">
						<Grid item md={7}>
							<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
								{"Below there is a series of free training sessions in Nearpod about SEFA Platform concepts. Use the corresponding code for to access each session."}
							</Typography>
						</Grid>
						<Grid item container md={5} justifyContent="end" alignItems="start">
							<Button
								variant="outlined"
								size="medium"
								color="primary"
								sx={{ width: "300px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c", color: "common.white" }, px: 3 }}
								onClick={() => { setRedirectSite({ name: "https://nearpod.com/student/", link: "https://nearpod.com/student/" }); setRedirectDialog(true); }}
							>
								{"Access Nearpod courses here!"}
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item container sx={{ p: 1 }} mt={2} justifyContent="space-between">
					<Grid item container sx={{ p: 1 }} alignItems="start">
						<Grid item md={7}>
							<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
								{"Standard “Energy-Efficiency-as-a-Service (EEaaS)” contract"}
							</Typography>
							<Typography variant="h6" sx={{ color: "#1d4363", width: "600px" }}>
								{"This is a course about the Risk Assessment Protocol, or RAP. It contains useful information about risk assessment and tips on completing it."}
							</Typography>
						</Grid>
						<Grid item container md={2} direction="column" alignItems="start" justifyContent="center">
							<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
								{"Code"}
							</Typography>
							<Typography variant="h6" sx={{ color: "#1d4363", marginTop: "30px" }}>
								{"5FKJ4"}
							</Typography>
						</Grid>
						<Grid item container md={3} justifyContent="center" alignItems="center">
							<CopyToClipboard text="5FKJ4" onCopy={() => setCopiedValue("5FKJ4")}>
								<Button
									variant="outlined"
									size="medium"
									color="primary"
									sx={{ width: "200px", backgroundColor: copiedValue === "5FKJ4" ? "#1d4363" : "#51BEA5", color: "common.white", ":hover": { backgroundColor: copiedValue === "5FKJ4" ? "#1d4363" : "#51BEA5", color: "common.white" }, px: 3, marginTop: "55px" }}
								>
									{copiedValue === "5FKJ4" ? "Code Copied!" : "Copy Code"}
								</Button>
							</CopyToClipboard>
						</Grid>
					</Grid>
				</Grid>
				<Grid item container sx={{ p: 1 }} justifyContent="space-between">
					<Grid item container sx={{ p: 1 }} alignItems="center">
						<Grid item md={7}>
							<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
								{"Hybrid Business Model Approach"}
							</Typography>
							<Typography variant="h6" sx={{ color: "#1d4363", width: "600px" }}>
								{"The Hybrid Business Model approach combines standardized and flexible contractual elements, from performance-driven and service-driven contract models."}
							</Typography>
						</Grid>
						<Grid item container md={2} direction="column" alignItems="start" justifyContent="center">
							<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
								{"Code"}
							</Typography>
							<Typography variant="h6" sx={{ color: "#1d4363", marginTop: "30px" }}>
								{"3DB6P"}
							</Typography>
						</Grid>
						<Grid item container md={3} justifyContent="center" alignItems="center">
							<CopyToClipboard text="3DB6P" onCopy={() => setCopiedValue("3DB6P")}>
								<Button
									variant="outlined"
									size="medium"
									color="primary"
									sx={{ width: "200px", backgroundColor: copiedValue === "3DB6P" ? "#1d4363" : "#51BEA5", color: "common.white", ":hover": { backgroundColor: copiedValue === "3DB6P" ? "#1d4363" : "#51BEA5", color: "common.white" }, px: 3, marginTop: "55px" }}
								>
									{copiedValue === "3DB6P" ? "Code Copied!" : "Copy Code"}
								</Button>
							</CopyToClipboard>
						</Grid>
					</Grid>
				</Grid>
				<Grid item container sx={{ p: 1 }} justifyContent="space-between">
					<Grid item container sx={{ p: 1 }} alignItems="center">
						<Grid item md={7}>
							<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
								{"Know Your Customer (KYC) tool"}
							</Typography>
							<Typography variant="h6" sx={{ color: "#1d4363", width: "600px" }}>
								{"Compliance with KYC regulations helps ascertain credibility and deters money laundering and other run-of-the-mill fraud schemes."}
							</Typography>
						</Grid>
						<Grid item container md={2} direction="column" alignItems="start" justifyContent="center">
							<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
								{"Code"}
							</Typography>
							<Typography variant="h6" sx={{ color: "#1d4363", marginTop: "30px" }}>
								{"2B5YT"}
							</Typography>
						</Grid>
						<Grid item container md={3} justifyContent="center" alignItems="center">
							<CopyToClipboard text="2B5YT" onCopy={() => setCopiedValue("2B5YT")}>
								<Button
									variant="outlined"
									size="medium"
									color="primary"
									sx={{ width: "200px", backgroundColor: copiedValue === "2B5YT" ? "#1d4363" : "#51BEA5", color: "common.white", ":hover": { backgroundColor: copiedValue === "2B5YT" ? "#1d4363" : "#51BEA5", color: "common.white" }, px: 3, marginTop: "55px" }}
								>
									{copiedValue === "2B5YT" ? "Code Copied!" : "Copy Code"}
								</Button>
							</CopyToClipboard>
						</Grid>
					</Grid>
				</Grid>
				<Grid item container sx={{ p: 1 }} justifyContent="space-between">
					<Grid item container sx={{ p: 1 }} alignItems="center">
						<Grid item md={7}>
							<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
								{"Risk Assessment Protocol (RAP)"}
							</Typography>
							<Typography variant="h6" sx={{ color: "#1d4363", width: "600px" }}>
								{"The Risk Assessment Protocol represents a key tool to ensure the bankability of your projects and to gain the investors’ trust."}
							</Typography>
						</Grid>
						<Grid item container md={2} direction="column" alignItems="start" justifyContent="center">
							<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
								{"Code"}
							</Typography>
							<Typography variant="h6" sx={{ color: "#1d4363", marginTop: "30px" }}>
								{"KDW6G"}
							</Typography>
						</Grid>
						<Grid item container md={3} justifyContent="center" alignItems="center">
							<CopyToClipboard text="KDW6G" onCopy={() => setCopiedValue("KDW6G")}>
								<Button
									variant="outlined"
									size="medium"
									color="primary"
									sx={{ width: "200px", backgroundColor: copiedValue === "KDW6G" ? "#1d4363" : "#51BEA5", color: "common.white", ":hover": { backgroundColor: copiedValue === "KDW6G" ? "#1d4363" : "#51BEA5", color: "common.white" }, px: 3, marginTop: "55px" }}
								>
									{copiedValue === "KDW6G" ? "Code Copied!" : "Copy Code"}
								</Button>
							</CopyToClipboard>
						</Grid>
					</Grid>
				</Grid>
				<Grid item container sx={{ p: 1 }} justifyContent="space-between">
					<Grid item container sx={{ p: 1 }} alignItems="center">
						<Grid item md={7}>
							<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
								{"Loan Application Guidelines"}
							</Typography>
							<Typography variant="h6" sx={{ color: "#1d4363", width: "600px" }}>
								{"The Loan application guidelines allow for the energy efficiency project developer to understand the need for the requested documents and facilitates the producing the documents in house."}
							</Typography>
						</Grid>
						<Grid item container md={2} direction="column" alignItems="start" justifyContent="center">
							<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
								{"Code"}
							</Typography>
							<Typography variant="h6" sx={{ color: "#1d4363", marginTop: "30px" }}>
								{"QX2LI"}
							</Typography>
						</Grid>
						<Grid item container md={3} justifyContent="center" alignItems="center">
							<CopyToClipboard text="QX2LI" onCopy={() => setCopiedValue("QX2LI")}>
								<Button
									variant="outlined"
									size="medium"
									color="primary"
									sx={{ width: "200px", backgroundColor: copiedValue === "QX2LI" ? "#1d4363" : "#51BEA5", color: "common.white", ":hover": { backgroundColor: copiedValue === "QX2LI" ? "#1d4363" : "#51BEA5", color: "common.white" }, px: 3, marginTop: "55px" }}
								>
									{copiedValue === "QX2LI" ? "Code Copied!" : "Copy Code"}
								</Button>
							</CopyToClipboard>
						</Grid>
					</Grid>
				</Grid>
				<Grid item container sx={{ p: 1 }} justifyContent="space-between">
					<Grid item container sx={{ p: 1 }} alignItems="center">
						<Grid item md={7}>
							<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
								{"SEFA Member Access Platform"}
							</Typography>
							<Typography variant="h6" sx={{ color: "#1d4363", width: "600px" }}>
								{"The SEFA member access platform is a single-access web service for SEFA members which enables preliminary due diligence on energy efficiency projects."}
							</Typography>
						</Grid>
						<Grid item container md={2} direction="column" alignItems="start" justifyContent="center">
							<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
								{"Code"}
							</Typography>
							<Typography variant="h6" sx={{ color: "#1d4363", marginTop: "30px" }}>
								{"F9PQD"}
							</Typography>
						</Grid>
						<Grid item container md={3} justifyContent="center" alignItems="center">
							<CopyToClipboard text="F9PQD" onCopy={() => setCopiedValue("F9PQD")}>
								<Button
									variant="outlined"
									size="medium"
									color="primary"
									sx={{ width: "200px", backgroundColor: copiedValue === "F9PQD" ? "#1d4363" : "#51BEA5", color: "common.white", ":hover": { backgroundColor: copiedValue === "F9PQD" ? "#1d4363" : "#51BEA5", color: "common.white" }, px: 3, marginTop: "55px" }}
								>
									{copiedValue === "F9PQD" ? "Code Copied!" : "Copy Code"}
								</Button>
							</CopyToClipboard>
						</Grid>
					</Grid>
				</Grid>
				<Grid item container sx={{ p: 1 }} justifyContent="space-between">
					<Grid item container sx={{ p: 1 }} alignItems="center">
						<Grid item md={7}>
							<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
								{"Environmental, Social and Goverance (ESG) assessment"}
							</Typography>
							<Typography variant="h6" sx={{ color: "#1d4363", width: "600px" }}>
								{"Analyzing ESG risks provides crucial non-financial information that allows financiers to make better investment decisions in addition to purely financial data."}
							</Typography>
						</Grid>
						<Grid item container md={2} direction="column" alignItems="start" justifyContent="center">
							<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
								{"Code"}
							</Typography>
							<Typography variant="h6" sx={{ color: "#1d4363", marginTop: "30px" }}>
								{"NZC9R"}
							</Typography>
						</Grid>
						<Grid item container md={3} justifyContent="center" alignItems="center">
							<CopyToClipboard text="NZC9R" onCopy={() => setCopiedValue("NZC9R")}>
								<Button
									variant="outlined"
									size="medium"
									color="primary"
									sx={{ width: "200px", backgroundColor: copiedValue === "NZC9R" ? "#1d4363" : "#51BEA5", color: "common.white", ":hover": { backgroundColor: copiedValue === "NZC9R" ? "#1d4363" : "#51BEA5", color: "common.white" }, px: 3, marginTop: "55px" }}
								>
									{copiedValue === "NZC9R" ? "Code Copied!" : "Copy Code"}
								</Button>
							</CopyToClipboard>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Paper className={classes.dialog} elevation={10}>
				<Dialog
					open={redirectDialog}
					TransitionComponent={Transition}
					maxWidth="md"
					scroll="body"
					PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4", maxWidth: "700px", zIndex: 999 } }}
					onClose={() => setRedirectDialog(false)}
				>
					<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
						<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
							{"Are you sure?"}
						</Typography>
					</DialogTitle>
					<DialogContent dividers sx={{ overflowY: "hidden" }}>
						<DialogContentText>
							<Grid item container sx={{ p: 1 }} alignItems="start" justifyContent="center">
								<Typography>
									{"Are you sure you want to be"}
									&nbsp;
									<b>{"redirected"}</b>
									&nbsp;
									{"to"}
									&nbsp;
								</Typography>
								<Typography sx={{ color: "blue" }}>
									{` [${redirectSite.name}]`}
									&nbsp;
								</Typography>
								<Typography>
									{"page, hosting eQuad's"}
									&nbsp;
									{"tutorials?"}
								</Typography>
							</Grid>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Grid container alignItems="center" justifyContent="space-between">
							<Grid item sm={3} display="flex" justifyContent="start">
								<Button
									fullWidth
									variant="outlined"
									size="large"
									sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
									color="primary"
									onClick={() => setRedirectDialog(false)}
								>
									{"Cancel"}
								</Button>
							</Grid>
							<Grid item sm={1} />
							<Grid item display="flex" sm={3} justifyContent="end">
								<Button
									fullWidth
									variant="contained"
									size="large"
									sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
									color="primary"
									onClick={onRedirect}
								>
									{"Redirect"}
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				</Dialog>
			</Paper>
		</>
	);
};

export default memo(CoursesAndTraining);
