import { useCallback, memo, useMemo, useState, useEffect, forwardRef } from "react";
import { useLocation, Link } from "react-router-dom";
import { Button, Typography, Grid, LinearProgress, Box, ToggleButton, TextField, Dialog, DialogTitle, DialogContent, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";
import RemoveIcon from "@mui/icons-material/Remove";
import ArticleIcon from "@mui/icons-material/Article";
import { Delete, Visibility, Edit, Add } from "@mui/icons-material";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { dateOldToNew, stringAToZInsensitive } from "@iamnapo/sort";
// import clsx from "clsx";
import clsx from "clsx";

import { isFuzzyMatch, dayjs, useSnackbar, httpResponseIsValid } from "../utils";
import Table from "../components/Table";
import { getUserProfile, deletePipeline, createPipeline, getPipelines, removePipelinePool, getPoolPipelines, getAvailablePipelineProjects, requestPipelineIntroduction } from "../api";

const Transition = forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const useStyles = makeStyles((theme) => ({
	header: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		letterSpacing: theme.spacing(0.2),
	},
	page: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
	boxBorder: {
		border: `2px solid ${theme.palette.primary.main}`,
		borderRadius: theme.spacing(2),
		backgroundColor: "white",
	},
	screenTitle: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		letterSpacing: theme.spacing(0.2),
	},
	form: {
		width: "420px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "start",
	},
}));

const Pipelines = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [userRole, setUserRole] = useState("");
	const [pipelines, setPipelines] = useState([]);
	const [pipelineScreenState, setPipelineScreenState] = useState("all");

	const [pipelineDialongState, setPipelineDialongState] = useState("close");
	const [tempSelectedPipeline, setTempSelectedPipeline] = useState({ name: "", description: "", projects: [] });
	const [tempPipelineProject, setTempPipelineProject] = useState({});
	const [tempProjectsToAdd, setTempProjectsToAdd] = useState([]);

	const [tempSelectedPipelineErrors, setTempSelectedPipelineErrors] = useState({
		name: "",
		description: "",
		projects: "",
	});

	const location = useLocation();
	const { error, success } = useSnackbar();

	const fetchData = useCallback(
		async (state = "all") => {
			setIsLoading(true);

			try {
				let response = await getUserProfile();
				if (httpResponseIsValid(response)) {
					setUserRole(response?.role);
				}

				response = (state === "all") ? await getPipelines() : await getPoolPipelines();
				if (httpResponseIsValid(response)) {
					for (const pipeline of response.pipelines) {
						const countries = [];
						const technologies = [];
						for (const project of pipeline.projects) {
							countries.push(project.country);
							technologies.push(project.technology);
							project.projectSize = project.projectSize || 0;
						}

						const uniqueCountries = [...new Set(countries)];
						const uniqueTechnologies = [...new Set(technologies)];
						// Parse fields
						pipeline.size = pipeline.projects.reduce((acc, curr) => acc + Number.parseFloat(curr.projectSize), 0);
						pipeline.num_projects = pipeline.projects.length;
						pipeline.countries = uniqueCountries.join(", ");
						// pipeline.countries = Array.from(new Set(pipeline.countries))
						pipeline.technologies = uniqueTechnologies.join(", ");
						pipeline.approved = 100;
					}

					setPipelines(response.pipelines);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured when fetching the available invitations: ${_error}`);
			}

			setIsLoading(false);
		},
		[error],
	);

	const onOpenCreatePipelineDialog = async () => {
		setIsLoading(true);

		try {
			setTempSelectedPipeline({ name: "", description: "", projects: [] });

			setTempSelectedPipelineErrors({
				name: "",
				description: "",
				projects: "",
			});

			const response = await getAvailablePipelineProjects();
			if (httpResponseIsValid(response)) {
				setTempProjectsToAdd(response?.availableProjects);
			} else {
				error(response?.message);
			}

			setPipelineDialongState("create");
		} catch (_error) {
			error(`Error occured while fetching available projects: ${_error}`);
		}

		setIsLoading(false);
	};

	const onClosePipelineDialog = () => {
		setPipelineDialongState("close");
	};

	const onCreateNewPipeline = async () => {
		try {
			let errorExist = false;
			const tempErrors = {
				name: "",
				description: "",
				projects: "",
			};

			if (tempSelectedPipeline.name === "") {
				errorExist = true;
				tempErrors.name = "Name field cannot be empty.";
			}

			if (tempSelectedPipeline.description === "") {
				errorExist = true;
				tempErrors.description = "Description field cannot be empty.";
			}

			if (tempSelectedPipeline.projects.length === 0) {
				errorExist = true;
				tempErrors.projects = "Pipeline must have at least one project.";
			}

			if (errorExist) {
				setTempSelectedPipelineErrors({ ...tempErrors });
				return;
			}

			setIsLoading(true);

			try {
				const response = await createPipeline(tempSelectedPipeline);
				if (httpResponseIsValid(response)) {
					await fetchData(pipelineScreenState);
					success(response?.message);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured while creating new pipeline: ${_error}`);
			}

			onClosePipelineDialog();
			setIsLoading(false);
		} catch (_error) {
			error(`Error occured while creating a new company pipeline: ${_error}`);
		}
	};

	const onSelectProjectToPipeline = useCallback((projectId) => {
		const index = tempProjectsToAdd.findIndex((p) => p._id === projectId);

		if (tempProjectsToAdd[index].toAdd === true) {
			tempProjectsToAdd[index].toAdd = false;
		} else {
			tempProjectsToAdd[index].toAdd = true;
		}

		setTempProjectsToAdd([...tempProjectsToAdd]);
	}, [tempProjectsToAdd]);

	const onAddProjectToPipeline = useCallback(() => {
		const selectedProjects = tempProjectsToAdd.filter((p) => p.toAdd === true);
		const remainedProjects = tempProjectsToAdd.filter((p) => p.toAdd !== true);

		const newProjects = [...tempSelectedPipeline.projects];

		setTempProjectsToAdd([...remainedProjects]);

		setTempSelectedPipeline({
			...tempSelectedPipeline,
			projects: [...newProjects, ...selectedProjects],
		});

		if (selectedProjects.length > 0) {
			setTempSelectedPipelineErrors({
				...tempSelectedPipelineErrors,
				projects: "",
			});
		}

		setPipelineDialongState("create");
	}, [tempProjectsToAdd, tempSelectedPipeline, tempSelectedPipelineErrors]);

	const isProjectSelectedToAdd = useCallback((projectId) => {
		const index = tempProjectsToAdd.findIndex((p) => p._id === projectId);

		return (tempProjectsToAdd[index]?.toAdd === true);
	}, [tempProjectsToAdd]);

	const onOpenAddProjectDialog = () => {
		setPipelineDialongState("addProject");
	};

	const onOpenEditPipelineDialog = useCallback(async (_id) => {
		setIsLoading(true);

		try {
			const pipelineToEdit = pipelines.find((p) => p._id === _id);

			setTempSelectedPipeline(pipelineToEdit);

			const response = await getAvailablePipelineProjects();
			if (httpResponseIsValid(response)) {
				setTempProjectsToAdd(response?.availableProjects);
			} else {
				error(response?.message);
			}

			setPipelineDialongState("edit");
		} catch (_error) {
			error(`Error occured editing pipeline: ${_error}`);
		}

		setIsLoading(false);
	}, [error, pipelines]);

	const onOpenRequestDialog = useCallback((_id) => {
		const pipelineToRequest = pipelines.find((p) => p._id === _id);

		setTempSelectedPipeline(pipelineToRequest);

		setPipelineDialongState("request");
	}, [pipelines]);

	const onRequestPipeline = useCallback(async () => {
		setIsLoading(true);

		try {
			const response = await requestPipelineIntroduction(tempSelectedPipeline);
			if (httpResponseIsValid(response)) {
				setTempProjectsToAdd(response?.availableProjects);
				await fetchData(pipelineScreenState);
				success(response?.message);
			} else {
				error(response?.message);
			}
		} catch (_error) {
			error(`Error while requesting pipeline ${_error}`);
		}

		setPipelineDialongState("close");

		setIsLoading(false);
	}, [error, success, tempSelectedPipeline]);

	const onOpenDeletePipelineDialog = useCallback((_id) => {
		const pipelineToDelete = pipelines.find((p) => p._id === _id);

		setTempSelectedPipeline(pipelineToDelete);

		setPipelineDialongState("delete");
	}, [pipelines]);

	const onOpenRemovePoolPipelineDialog = useCallback((_id) => {
		const pipelinePoolToRemove = pipelines.find((p) => p._id === _id);

		setTempSelectedPipeline(pipelinePoolToRemove);

		setPipelineDialongState("removePool");
	}, [pipelines]);

	const onDeletePipeline = async () => {
		setIsLoading(true);

		try {
			const response = await deletePipeline(tempSelectedPipeline);
			if (httpResponseIsValid(response)) {
				await fetchData(pipelineScreenState);
				success(response?.message);
			} else {
				error(response?.message);
			}
		} catch (_error) {
			error(`Error occured while deleting pipeline: ${_error}`);
		}

		onClosePipelineDialog();

		setIsLoading(false);
	};

	const onRemovePoolPipeline = async () => {
		setIsLoading(true);

		try {
			const response = await removePipelinePool(tempSelectedPipeline);
			if (httpResponseIsValid(response)) {
				await fetchData(pipelineScreenState);
				success(response?.message);
			} else {
				error(response?.message);
			}
		} catch (_error) {
			error(`Error occured while removing pipeline from pool: ${_error}`);
		}

		onClosePipelineDialog();

		setIsLoading(false);
	};

	const onOpenDeletePipelineProjectDialog = useCallback((_id) => {
		const pipelineProjectToDelete = tempSelectedPipeline?.projects.find((p) => p._id === _id);

		setTempPipelineProject(pipelineProjectToDelete);

		setPipelineDialongState("deleteProject");
	}, [tempSelectedPipeline?.projects]);

	const onDeletePipelineProject = () => {
		setIsLoading(true);

		tempPipelineProject.toAdd = false;
		const availableProjects = [...tempProjectsToAdd, tempPipelineProject];
		setTempProjectsToAdd([...availableProjects]);

		const addedProjects = tempSelectedPipeline.projects.filter((p) => p._id !== tempPipelineProject._id);

		setTempSelectedPipeline({
			...tempSelectedPipeline,
			projects: [...addedProjects],
		});

		setPipelineDialongState("edit");

		setIsLoading(false);
	};

	const classes = useStyles();

	const pipelineTableColumns = useMemo(() => [
		{
			Header: <Typography id="name_header" variant="h6">{"Pipeline"}</Typography>,
			accessor: "name",
			id: "name",
			filterable: true,
			minWidth: 200,
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Size"}</Typography>,
			accessor: "size",
			id: "size",
			filterable: true,
			minWidth: 150,
			maxWidth: 380,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Projects"}</Typography>,
			accessor: "num_projects",
			id: "num_projects",
			filterable: true,
			minWidth: 100,
			maxWidth: 380,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Countries"}</Typography>,
			accessor: "countries",
			id: "countries",
			minWidth: 200,
			maxWidth: 250,
			style: { whiteSpace: "unset" },
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Technologies"}</Typography>,
			accessor: "technologies",
			id: "technologies",
			minWidth: 200,
			maxWidth: 250,
			style: { whiteSpace: "unset" },
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Created"}</Typography>,
			accessor: "createdAt",
			id: "createdAt",
			minWidth: 100,
			maxWidth: 380,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => dateOldToNew((v) => new Date(v))(value1, value2),
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{dayjs(value).format("DD/MM/YYYY")}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Approved"}</Typography>,
			accessor: "approved",
			id: "approved",
			minWidth: 110,
			maxWidth: 250,
			style: { overflow: "visible" },
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{`${value} %`}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Status"}</Typography>,
			accessor: "status",
			id: "status",
			minWidth: 110,
			maxWidth: 250,
			style: { overflow: "visible" },
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{`${value}`}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Actions"}</Typography>,
			accessor: "_id",
			filterable: false,
			sortable: false,
			width: 180,
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Grid item container justifyContent="space-around">
					{userRole === "owner" && (
						<>
							<ToggleButton
								value="edit"
								title="Edit Pipeline"
								size="small"
								aria-label="edit"
								sx={{ borderColor: "primary.main" }}
								onClick={() => onOpenEditPipelineDialog(value)}
							>
								<Edit color="primary" />
							</ToggleButton>
							<ToggleButton
								value="delete"
								title="Delete Pipeline"
								size="small"
								aria-label="delete"
								sx={{ borderColor: "primary.main" }}
								onClick={() => onOpenDeletePipelineDialog(value)}
							>
								<Delete color="error" />
							</ToggleButton>
						</>
					)}
					{(userRole === "admin" || userRole === "investor_owner" || userRole === "investor_member") && (
						<ToggleButton
							value="view"
							title="View Pipeline"
							size="small"
							aria-label="view"
							sx={{ borderColor: "primary.main" }}
							onClick={() => onOpenEditPipelineDialog(value)}
						>
							{pipelineScreenState === "all"
								&& (
									<Visibility color="primary" />
								)}
							{pipelineScreenState === "pipeline-pool"
								&& (
									<ArticleIcon color="primary" />
								)}
						</ToggleButton>
					)}
					{(pipelineScreenState === "all" && (userRole === "investor_owner" || userRole === "investor_member"))
						&& (
							<ToggleButton
								value="introduce"
								title="Request Introduction"
								size="small"
								aria-label="introduce"
								sx={{ borderColor: "primary.main" }}
								onClick={() => onOpenRequestDialog(value)}
							>
								<PostAddIcon color="primary" />
							</ToggleButton>
						)}
					{(pipelineScreenState === "pipeline-pool" && (userRole === "investor_owner" || userRole === "investor_member"))
						&& (
							<ToggleButton
								value="introduce"
								title="Request Introduction"
								size="small"
								aria-label="introduce"
								sx={{ borderColor: "primary.main" }}
								onClick={() => onOpenRemovePoolPipelineDialog(value)}
							>
								<Delete color="error" />
							</ToggleButton>
						)}
				</Grid>
			),
		},
		// eslint-disable-next-line max-len
	], [userRole, onOpenEditPipelineDialog, onOpenDeletePipelineDialog, onOpenRemovePoolPipelineDialog, onOpenRequestDialog, pipelineScreenState]);

	const projectTableColumns = useMemo(() => [
		{
			Header: <Typography id="name_header" variant="h6">{"Project"}</Typography>,
			accessor: "name",
			id: "name",
			filterable: true,
			minWidth: 200,
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id].name, value),
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Project Size"}</Typography>,
			accessor: "projectSize",
			id: "projectSize",
			filterable: true,
			minWidth: 150,
			maxWidth: 380,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Country"}</Typography>,
			accessor: "country",
			id: "country",
			filterable: true,
			minWidth: 100,
			maxWidth: 380,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Technology"}</Typography>,
			accessor: "technology",
			id: "technology",
			minWidth: 200,
			maxWidth: 250,
			style: { overflow: "visible" },
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Term"}</Typography>,
			accessor: "term",
			id: "term",
			minWidth: 110,
			maxWidth: 250,
			style: { overflow: "visible" },
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Sector"}</Typography>,
			accessor: "sector",
			id: "sector",
			minWidth: 110,
			maxWidth: 250,
			style: { overflow: "visible" },
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Actions"}</Typography>,
			accessor: "_id",
			filterable: false,
			sortable: false,
			width: 180,
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Grid item container justifyContent="space-around">
					<Link to={`/project/${value}`} target="_blank">
						<ToggleButton
							value="view"
							title="View Pipeline Project"
							size="small"
							aria-label="view"
							sx={{ borderColor: "primary.main" }}
						>
							<Visibility color="primary" />
						</ToggleButton>
					</Link>
					{(pipelineDialongState !== "addProject" && userRole === "owner")
						&& (
							<ToggleButton
								value="delete"
								title="Remove Pipeline Project"
								size="small"
								aria-label="delete"
								sx={{ borderColor: "primary.main" }}
								onClick={() => onOpenDeletePipelineProjectDialog(value)}
							>
								<Delete color="error" />
							</ToggleButton>
						)}
					{(pipelineDialongState === "addProject" && userRole === "owner")
						&& (
							<ToggleButton
								value="add"
								title="Add Pipeline Project"
								size="small"
								aria-label="add"
								sx={{ borderColor: "primary.main" }}
								onClick={() => onSelectProjectToPipeline(value)}
							>
								{!isProjectSelectedToAdd(value)
									&& (
										<Add color="success" />
									)}
								{isProjectSelectedToAdd(value)
									&& (
										<RemoveIcon color="error" />
									)}
							</ToggleButton>
						)}
				</Grid>
			),
		},
	], [pipelineDialongState, userRole, isProjectSelectedToAdd, onOpenDeletePipelineProjectDialog, onSelectProjectToPipeline]);
	useEffect(() => {
		(async () => {
			try {
				const state = location.pathname.split("/")[2] || "all";
				setPipelineScreenState(state);

				await fetchData(state);
			} catch { /* empty */ }
		})();
	}, [fetchData, location]);

	return (
		<>
			{isLoading && (<LinearProgress color="primary" />)}
			<section style={{ paddingTop: "1rem" }}>
				<div className={clsx("container", classes.page)}>
					<Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ "> .MuiGrid-item": { p: 0 }, background: "#F4F4F4", borderRadius: "10px 10px 10px 10px", padding: "8px" }} mb={2}>
						<Grid item hidden />
						<Grid item md={4}>
							<Typography variant="h5" className={classes.screenTitle}>{userRole === "owner" ? "My Pipelines" : "Available Pipelines"}</Typography>
						</Grid>
						<Grid item container className={classes.boxBorder} sx={{ p: 1 }} xs={12} md={4}>
							<Grid item xs={12} md={6} />
							<Grid item xs={12} md={6}>
								<Box p={1}>
									<Grid container direction="row" justifyContent="flex-end" alignItems="center">
										<Typography variant="h6">
											{"Today: "}
											<span style={{ marginLeft: "10px" }}>{(new Date()).toLocaleDateString("en-US")}</span>
										</Typography>
									</Grid>
								</Box>
							</Grid>
						</Grid>
						<Grid item container justifyContent="end" sx={{ p: 1, marginTop: "15px" }} xs={12} md={12}>
							<Grid item xs={0} md={7} />
							{userRole === "owner"
								&& (
									<Grid item>
										<Button
											variant="outlined"
											size="medium"
											color="primary"
											sx={{ width: "250px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c", color: "common.white" }, px: 3 }}
											onClick={() => onOpenCreatePipelineDialog()}
										>
											{"Create New Pipeline"}
										</Button>
									</Grid>
								)}
						</Grid>
					</Grid>
					<Table
						data={pipelines}
						noDataText={isLoading ? "Fetching projects..." : "No data available!"}
						columns={pipelineTableColumns}
						defaultSorted={[{ id: "name", desc: true }]}
					/>
				</div>
			</section>
			{(pipelineDialongState === "create" || pipelineDialongState === "edit" || pipelineDialongState === "deleteProject" || pipelineDialongState === "addProject")
				&& (
					<Dialog
						fullWidth
						open={(pipelineDialongState === "create" || pipelineDialongState === "edit" || pipelineDialongState === "deleteProject" || pipelineDialongState === "addProject")}
						TransitionComponent={Transition}
						maxWidth="lg"
						scroll="body"
						PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4", width: "1150px" } }}
						onClose={() => onClosePipelineDialog()}
					>
						<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
							<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
								{(pipelineDialongState === "create") ? "Create New Pipeline" : "Edit Existing Pipeline"}
							</Typography>
						</DialogTitle>
						<DialogContent dividers sx={{ overflowY: "hidden" }}>
							<Grid item container sx={{ p: 1, marginTop: "20px" }} mt={1} mb={3} alignItems="center">
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
										{"Name"}
									</Typography>
								</Grid>
								<Grid item xs={12} md={8}>
									<TextField
										fullWidth
										hiddenLabel
										value={tempSelectedPipeline.name}
										variant="filled"
										placeholder="Pipeline name"
										disabled={userRole !== "owner"}
										error={tempSelectedPipelineErrors.name !== ""}
										helperText={tempSelectedPipelineErrors.name}
										onChange={(e) => { setTempSelectedPipeline({ ...tempSelectedPipeline, name: e.target.value }); setTempSelectedPipelineErrors({ ...tempSelectedPipelineErrors, name: "" }); }}
									/>
								</Grid>
							</Grid>
							<Grid item container sx={{ p: 1, marginTop: "20px" }} mt={1} mb={3} alignItems="start">
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363", marginTop: "10px" }}>
										{"Description"}
									</Typography>
								</Grid>
								<Grid item xs={12} md={8}>
									<TextField
										multiline
										fullWidth
										hiddenLabel
										minRows={2}
										maxRows={3}
										value={tempSelectedPipeline.description}
										variant="filled"
										placeholder="Pipeline Description"
										disabled={userRole !== "owner"}
										error={tempSelectedPipelineErrors.description !== ""}
										helperText={tempSelectedPipelineErrors.description}
										onChange={(e) => { setTempSelectedPipeline({ ...tempSelectedPipeline, description: e.target.value }); setTempSelectedPipelineErrors({ ...tempSelectedPipelineErrors, description: "" }); }}
									/>
								</Grid>
							</Grid>
							<Grid item container sx={{ p: 1, marginTop: "20px" }} mt={1} mb={1} alignItems="start">
								<Grid item xs={12} md={8}>
									<Typography variant="h6" fontWeight="bold" sx={{ marginTop: "10px" }}>
										<span style={{ color: "#1d4363" }}>{"Pipeline Projects"}</span>
										&nbsp;
										{tempSelectedPipelineErrors.projects !== ""
											&& (
												<span style={{ color: "red", fontWeight: "normal" }}>{` - ${tempSelectedPipelineErrors.projects}`}</span>
											)}
									</Typography>
								</Grid>
								{userRole === "owner" && (
									<Grid item container xs={12} md={4} justifyContent="end">
										<Button
											fullWidth
											variant="contained"
											size="large"
											sx={{ width: "180px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
											color="primary"
											disabled={tempSelectedPipeline.name === "" || tempSelectedPipeline.description === ""}
											onClick={() => onOpenAddProjectDialog()}
										>
											{"Add Projects"}
										</Button>
									</Grid>
								)}
							</Grid>
							<Grid item>
								<Table
									data={tempSelectedPipeline?.projects}
									noDataText={isLoading ? "Fetching projects..." : "No data available!"}
									columns={projectTableColumns}
									defaultSorted={[{ id: "name", desc: true }]}
								/>
							</Grid>
							<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="30px">
								<Grid item sm={3} display="flex" justifyContent="start">
									{userRole === "owner" && (
										<Button
											fullWidth
											variant="outlined"
											size="large"
											sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
											color="primary"
											onClick={() => onClosePipelineDialog()}
										>
											{"Cancel"}
										</Button>
									)}
								</Grid>
								<Grid item sm={1} />
								<Grid item display="flex" sm={3} justifyContent="end">
									{(userRole === "admin" || userRole === "investor_owner" || userRole === "investor_member")
										&& (
											<Button
												fullWidth
												variant="contained"
												size="large"
												sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
												color="primary"
												onClick={() => onClosePipelineDialog()}
											>
												{"Close"}
											</Button>
										)}
									{userRole === "owner"
										&& (
											<Button
												fullWidth
												variant="contained"
												size="large"
												sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
												color="primary"
												onClick={() => onCreateNewPipeline()}
											>
												{"Save"}
											</Button>
										)}
								</Grid>
							</Grid>
						</DialogContent>
					</Dialog>
				)}
			{
				pipelineDialongState === "request"
				&& (
					<Dialog
						keepMounted
						open={pipelineDialongState === "request"}
						TransitionComponent={Transition}
						scroll="body"
						PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4" } }}
						onClose={() => onClosePipelineDialog()}
					>
						<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
							<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
								{"Are you sure?"}
							</Typography>
						</DialogTitle>
						<DialogContent dividers sx={{ overflowY: "hidden" }}>
							<Grid item container sx={{ p: 1, marginTop: "5px" }} mt={1} alignItems="center">
								<Typography>
									{"Are you sure you want to add the pipeline: ["}
									&nbsp;
									<b>{tempSelectedPipeline?.name}</b>
									&nbsp;
									{"] to your pool and request an introduction by the admin?"}
								</Typography>
							</Grid>
							<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="25px">
								<Grid item sm={3} display="flex" justifyContent="start">
									<Button
										fullWidth
										variant="outlined"
										sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
										color="primary"
										onClick={() => onClosePipelineDialog()}
									>
										{"Cancel"}
									</Button>
								</Grid>
								<Grid item display="flex" sm={3} justifyContent="end">
									<Button
										fullWidth
										variant="contained"
										sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
										color="primary"
										onClick={() => onRequestPipeline()}
									>
										{"Add"}
									</Button>
								</Grid>
							</Grid>
						</DialogContent>
					</Dialog>
				)
			}
			{
				(pipelineDialongState === "delete")
				&& (
					<Dialog
						fullWidth
						open={(pipelineDialongState === "delete")}
						TransitionComponent={Transition}
						scroll="body"
						maxWidth="md"
						PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4", maxWidth: "700px" } }}
						onClose={() => onClosePipelineDialog()}
					>
						<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
							<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
								{"Are you sure?"}
							</Typography>
						</DialogTitle>
						<DialogContent dividers sx={{ overflowY: "hidden" }}>
							<Grid item container sx={{ p: 1, marginTop: "5px" }} mt={1} alignItems="center" justifyContent="center">
								<Typography variant="h6" sx={{ color: "#1d4363" }}>
									{"Are you sure you want to permanently delete ["}
									&nbsp;
									<b>{tempSelectedPipeline.name}</b>
									&nbsp;
									{"] from your pipelines?"}
								</Typography>
							</Grid>
							<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="25px">
								<Grid item sm={3} display="flex" justifyContent="start">
									<Button
										fullWidth
										variant="outlined"
										sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
										color="primary"
										onClick={() => onClosePipelineDialog()}
									>
										{"Cancel"}
									</Button>
								</Grid>
								<Grid item display="flex" sm={3} justifyContent="end">
									<Button
										fullWidth
										variant="contained"
										sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
										color="primary"
										onClick={() => onDeletePipeline()}
									>
										{"Delete"}
									</Button>
								</Grid>
							</Grid>
						</DialogContent>
					</Dialog>
				)
			}
			{
				(pipelineDialongState === "removePool")
				&& (
					<Dialog
						fullWidth
						open={(pipelineDialongState === "removePool")}
						TransitionComponent={Transition}
						scroll="body"
						maxWidth="md"
						PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4", maxWidth: "700px" } }}
						onClose={() => onClosePipelineDialog()}
					>
						<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
							<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
								{"Are you sure?"}
							</Typography>
						</DialogTitle>
						<DialogContent dividers sx={{ overflowY: "hidden" }}>
							<Grid item container sx={{ p: 1, marginTop: "5px" }} mt={1} alignItems="center" justifyContent="center">
								<Typography variant="h6" sx={{ color: "#1d4363" }}>
									{"Are you sure you want to remove pipeline ["}
									&nbsp;
									<b>{tempSelectedPipeline.name}</b>
									&nbsp;
									{"] from your pool? This will also retrieve the introduction request."}
								</Typography>
							</Grid>
							<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="25px">
								<Grid item sm={3} display="flex" justifyContent="start">
									<Button
										fullWidth
										variant="outlined"
										sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
										color="primary"
										onClick={() => onClosePipelineDialog()}
									>
										{"Cancel"}
									</Button>
								</Grid>
								<Grid item display="flex" sm={3} justifyContent="end">
									<Button
										fullWidth
										variant="contained"
										sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
										color="primary"
										onClick={() => onRemovePoolPipeline()}
									>
										{"Remove"}
									</Button>
								</Grid>
							</Grid>
						</DialogContent>
					</Dialog>
				)
			}
			{
				(pipelineDialongState === "addProject")
				&& (
					<Dialog
						fullWidth
						open={(pipelineDialongState === "addProject")}
						TransitionComponent={Transition}
						scroll="body"
						maxWidth="md"
						PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4", maxWidth: "1150px" } }}
						onClose={() => setPipelineDialongState("create")}
					>
						<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
							<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
								{`Add Projects to Pipeline ${tempSelectedPipeline.name}`}
							</Typography>
						</DialogTitle>
						<DialogContent dividers sx={{ overflowY: "hidden" }}>
							<Grid item>
								<Table
									data={tempProjectsToAdd}
									noDataText={isLoading ? "Fetching projects..." : "No data available!"}
									columns={projectTableColumns}
									defaultSorted={[{ id: "name", desc: true }]}
								/>
							</Grid>
							<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="25px">
								<Grid item sm={3} display="flex" justifyContent="start">
									<Button
										fullWidth
										variant="outlined"
										sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
										color="primary"
										onClick={() => setPipelineDialongState("create")}
									>
										{"Cancel"}
									</Button>
								</Grid>
								<Grid item display="flex" sm={3} justifyContent="end">
									<Button
										fullWidth
										variant="contained"
										sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
										color="primary"
										onClick={() => onAddProjectToPipeline()}
									>
										{"Add"}
									</Button>
								</Grid>
							</Grid>
						</DialogContent>
					</Dialog>
				)
			}
			{
				(pipelineDialongState === "deleteProject")
				&& (
					<Dialog
						fullWidth
						open={(pipelineDialongState === "deleteProject")}
						TransitionComponent={Transition}
						scroll="body"
						maxWidth="md"
						PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4", maxWidth: "700px" } }}
						onClose={() => setPipelineDialongState("edit")}
					>
						<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
							<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
								{"Are you sure?"}
							</Typography>
						</DialogTitle>
						<DialogContent dividers sx={{ overflowY: "hidden" }}>
							<Grid item container sx={{ p: 1, marginTop: "5px" }} mt={1} alignItems="center" justifyContent="center">
								<Typography variant="h6" sx={{ color: "#1d4363" }}>
									{"Are you sure you want to remove project ["}
									&nbsp;
									<b>{tempPipelineProject.name}</b>
									&nbsp;
									{"] your pipelines ["}
									&nbsp;
									<b>{tempSelectedPipeline.name}</b>
									&nbsp;
									{"]?"}
								</Typography>
							</Grid>
							<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="25px">
								<Grid item sm={3} display="flex" justifyContent="start">
									<Button
										fullWidth
										variant="outlined"
										sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
										color="primary"
										onClick={() => setPipelineDialongState("edit")}
									>
										{"Cancel"}
									</Button>
								</Grid>
								<Grid item display="flex" sm={3} justifyContent="end">
									<Button
										fullWidth
										variant="contained"
										sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
										color="primary"
										onClick={() => onDeletePipelineProject()}
									>
										{"Remove"}
									</Button>
								</Grid>
							</Grid>
						</DialogContent>
					</Dialog>
				)
			}
		</>
	);
};

export default memo(Pipelines);
