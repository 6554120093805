/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	Grid,
	LinearProgress,
	Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { httpResponseIsValid, useSnackbar } from "../../utils";
import usePrompt from "../../utils/use-blocker";
import {
	MainMenu,
	LabelRadio,
	LabelDropdown,
	LabelTextField,
	LabelFileUpload,
} from "../../components/basic/index";
import {
	getProjectLoanApplicationTechnicalInformation,
	updateProjectLoanApplicationTechnicalInformation,
} from "../../api";

const LoanApplicationTechnicalInformation = ({ disableAccess = true, isProjectDeveloper = false, onChangeProjectState }) => {
	const { projectid } = useParams();

	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(disableAccess);
	const [isLoading, setIsLoading] = useState(false);

	const [options, setOptions] = useState({
		buidlingPurpose: {},
		developmentProcess: {},
	});
	const [project, setProject] = useState({
		name: "",
		status: "-",
		createdAt: "-",
		data: {
			site_development: "",
			current_building_quality: "",
			square_meters: "",
			building_purpose: "commercial",
			current_usage_energy_class: "",
			development_process: "operational_phase",
			building_and_production: "",
			transport_systems: "",
			reference_laws: "",
			supervision_structure: "",
			work_schedule: "",
			raw_materials: "",
			timetable: "",
			technical_assistance: "false",
		},
		docs: {
			permissions: {
				original_name: "",
				save_name: "",
			},
			specifications: {
				original_name: "",
				save_name: "",
			},
			economy: {
				original_name: "",
				save_name: "",
			},
			transport_details: {
				original_name: "",
				save_name: "",
			},
			feasibility_study: {
				original_name: "",
				save_name: "",
			},
			measures: {
				original_name: "",
				save_name: "",
			},
			engineering_studies: {
				original_name: "",
				save_name: "",
			},
			procedures: {
				original_name: "",
				save_name: "",
			},
			certificates: {
				original_name: "",
				save_name: "",
			},
			flowcharts: {
				original_name: "",
				save_name: "",
			},
		},
	});

	const [enableEditing, setEnableEditing] = useState(false);
	const { success, error } = useSnackbar();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getProjectLoanApplicationTechnicalInformation(projectid);
				if (httpResponseIsValid(response)) {
					setProject(response.project);
					setOptions(response.options);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				console.log(`Error occured when fetching loan's appplication technical information data: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, projectid],
	);

	const handleSubmit = async (values) => {
		setIsLoading(true);

		try {
			const response = await updateProjectLoanApplicationTechnicalInformation(projectid, values);
			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			console.log(`Error occured when updating loan's appplication technical information data: ${_error}`);
		}

		setIsLoading(false);
	};

	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: project,
		onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	});

	const { dirty } = formik;

	usePrompt("You have unsaved changes which will be lost. Are you sure you want to leave the page?", dirty);

	useEffect(() => {
		setShouldUiBeDisabled(disableAccess || !enableEditing);
	}, [disableAccess, enableEditing]);

	useEffect(() => {
		formik.resetForm({ values: project, dirty: false });
	}, [project]);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData, projectid]);

	return (
		<form onSubmit={formik.handleSubmit}>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<MainMenu
				title={t("LoanApplication.TechnicalInformation.title")}
				dirty={dirty}
				project={project}
				isDeveloper={isProjectDeveloper}
				disabled={disableAccess}
				onFetch={fetchData}
				onEdit={setEnableEditing}
				onChange={onChangeProjectState}
			/>

			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "105px" }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("LoanApplication.TechnicalInformation.TechnicalInformation.title")}
					</Typography>
				</Grid>

				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#6F96C6">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("LoanApplication.TechnicalInformation.SiteDevelopment.title")}
					</Typography>
				</Grid>

				<LabelTextField
					disabled
					id="data.site_development"
					label={t("LoanApplication.TechnicalInformation.SiteDevelopment.siteDevelopment")}
					size="large"
					placeholder="Answer"
					formik={formik}
				/>

				<LabelTextField
					disabled
					id="data.current_building_quality"
					label={t("LoanApplication.TechnicalInformation.SiteDevelopment.currentBuildingQuality")}
					size="large"
					placeholder="Answer"
					formik={formik}
				/>

				<LabelTextField
					disabled
					id="data.square_meters"
					label={t("LoanApplication.TechnicalInformation.SiteDevelopment.squareMeters")}
					size="large"
					placeholder="Answer"
					formik={formik}
				/>

				<LabelDropdown
					id="data.building_purpose"
					size="large"
					label={t("LoanApplication.TechnicalInformation.SiteDevelopment.buildingPurpose")}
					options={options.buidlingPurpose}
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>

				<LabelTextField
					disabled
					id="data.current_usage_energy_class"
					label={t("LoanApplication.TechnicalInformation.SiteDevelopment.currentUsageEnergyClass")}
					size="large"
					placeholder="Answer"
					formik={formik}
				/>

				<LabelDropdown
					id="data.development_process"
					size="large"
					label={t("LoanApplication.TechnicalInformation.SiteDevelopment.developmentProcess")}
					options={options.developmentProcess}
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>

				<LabelTextField
					disabled
					id="data.building_and_production"
					label={t("LoanApplication.TechnicalInformation.SiteDevelopment.buildingAndProduction")}
					size="large"
					placeholder="Answer"
					formik={formik}
				/>

				<LabelFileUpload
					fieldId="docs.permissions"
					projectId={projectid}
					label={t("LoanApplication.TechnicalInformation.SiteDevelopment.doc1")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="loanApplication"
					infoText={t("LoanApplication.ProjectFinance.EnvisagedCommercial.info1")}
					onSuccess={() => fetchData()}
				/>

				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#6F96C6">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("LoanApplication.TechnicalInformation.EquipmentAndMaterial.title")}
					</Typography>
				</Grid>

				<LabelFileUpload
					fieldId="docs.specifications"
					projectId={projectid}
					label={t("LoanApplication.TechnicalInformation.EquipmentAndMaterial.doc1")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="loanApplication"
					infoText={t("LoanApplication.TechnicalInformation.EquipmentAndMaterial.info1")}
					onSuccess={() => fetchData()}
				/>

				<LabelFileUpload
					fieldId="docs.economy"
					projectId={projectid}
					label={t("LoanApplication.TechnicalInformation.EquipmentAndMaterial.doc2")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="loanApplication"
					onSuccess={() => fetchData()}
				/>

				<LabelTextField
					disabled
					id="data.building_and_production"
					label={t("LoanApplication.TechnicalInformation.EquipmentAndMaterial.transportSystems")}
					size="large"
					placeholder="Answer"
					formik={formik}
				/>

				<LabelFileUpload
					fieldId="docs.transport_details"
					projectId={projectid}
					label={t("LoanApplication.TechnicalInformation.EquipmentAndMaterial.doc3")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="loanApplication"
					infoText={t("LoanApplication.TechnicalInformation.EquipmentAndMaterial.info2")}
					onSuccess={() => fetchData()}
				/>

				<LabelFileUpload
					fieldId="docs.feasibility_study"
					projectId={projectid}
					label={t("LoanApplication.TechnicalInformation.EquipmentAndMaterial.doc4")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="loanApplication"
					infoText={t("LoanApplication.TechnicalInformation.EquipmentAndMaterial.info3")}
					onSuccess={() => fetchData()}
				/>

				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#6F96C6">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("LoanApplication.TechnicalInformation.EnvironmentalImpactAssessment.title")}
					</Typography>
				</Grid>

				<LabelTextField
					disabled
					id="data.reference_laws"
					label={t("LoanApplication.TechnicalInformation.EnvironmentalImpactAssessment.referencelaws")}
					size="large"
					placeholder="Answer"
					formik={formik}
				/>

				<LabelFileUpload
					fieldId="docs.measures"
					projectId={projectid}
					label={t("LoanApplication.TechnicalInformation.EnvironmentalImpactAssessment.doc1")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="loanApplication"
					infoText={t("LoanApplication.TechnicalInformation.EnvironmentalImpactAssessment.info1")}
					onSuccess={() => fetchData()}
				/>

				<LabelFileUpload
					fieldId="docs.engineering_studies"
					projectId={projectid}
					label={t("LoanApplication.TechnicalInformation.EnvironmentalImpactAssessment.doc2")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="loanApplication"
					infoText={t("LoanApplication.TechnicalInformation.EnvironmentalImpactAssessment.info2")}
					onSuccess={() => fetchData()}
				/>

				<LabelFileUpload
					fieldId="docs.procedures"
					projectId={projectid}
					label={t("LoanApplication.TechnicalInformation.EnvironmentalImpactAssessment.doc3")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="loanApplication"
					infoText={t("LoanApplication.TechnicalInformation.EnvironmentalImpactAssessment.info3")}
					onSuccess={() => fetchData()}
				/>

				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#6F96C6">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("LoanApplication.TechnicalInformation.ImplementationPlan.title")}
					</Typography>
				</Grid>

				<LabelTextField
					disabled
					id="data.supervision_structure"
					label={t("LoanApplication.TechnicalInformation.ImplementationPlan.supervisionStructure")}
					size="large"
					placeholder="Answer"
					formik={formik}
				/>

				<LabelTextField
					disabled
					id="data.work_schedule"
					label={t("LoanApplication.TechnicalInformation.ImplementationPlan.workSchedule")}
					size="large"
					placeholder="Answer"
					formik={formik}
				/>

				<LabelTextField
					disabled
					id="data.timetable"
					label={t("LoanApplication.TechnicalInformation.ImplementationPlan.timetable")}
					size="large"
					placeholder="Answer"
					formik={formik}
				/>

				<LabelFileUpload
					fieldId="docs.certificates"
					projectId={projectid}
					label={t("LoanApplication.TechnicalInformation.ImplementationPlan.doc1")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="loanApplication"
					infoText={t("LoanApplication.TechnicalInformation.ImplementationPlan.info1")}
					onSuccess={() => fetchData()}
				/>

				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#6F96C6">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("LoanApplication.TechnicalInformation.Operation.title")}
					</Typography>
				</Grid>

				<LabelTextField
					disabled
					id="data.raw_materials"
					label={t("LoanApplication.TechnicalInformation.Operation.rawMaterials")}
					size="large"
					placeholder="Answer"
					formik={formik}
				/>

				<LabelFileUpload
					fieldId="docs.flowcharts"
					projectId={projectid}
					label={t("LoanApplication.TechnicalInformation.Operation.doc1")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="loanApplication"
					onSuccess={() => fetchData()}
				/>

				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#6F96C6">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("LoanApplication.TechnicalInformation.TechnicalAndFinancialAsssistance.title")}
					</Typography>
				</Grid>

				<LabelRadio
					id="data.technical_assistance"
					label={t("LoanApplication.TechnicalInformation.TechnicalAndFinancialAsssistance.technicalAssistance")}
					size="large"
					options={[{ value: "false", label: "No" }, { value: "true", label: "yes" }]}
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>
			</Grid>
		</form>
	);
};

export default memo(LoanApplicationTechnicalInformation);
