/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	Grid,
	LinearProgress,
	Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { httpResponseIsValid, useSnackbar } from "../../utils";
import usePrompt from "../../utils/use-blocker";
import {
	MainMenu,
	LabelTextField,
	LabelFileUpload,
} from "../../components/basic/index";
import {
	getProjectLoanApplicationProjectFinance,
	updateProjectLoanApplicationProjectFinance,
} from "../../api";

const LoanApplicationProjectFinance = ({ disableAccess = true, isProjectDeveloper = false, onChangeProjectState }) => {
	const { projectid } = useParams();

	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(disableAccess);
	const [isLoading, setIsLoading] = useState(false);

	const [project, setProject] = useState({
		name: "",
		status: "-",
		createdAt: "-",
		data: {
			purpose_of_project: "",
			envisioned_financing_structure: "",
			financial_model: "",
		},
		docs: {
			envisaged_contract: {
				original_name: "",
				save_name: "",
			},
			analysis: {
				original_name: "",
				save_name: "",
			},
			advice: {
				original_name: "",
				save_name: "",
			},
			project_risk_assessment: {
				original_name: "",
				save_name: "",
			},
		},
	});

	const [enableEditing, setEnableEditing] = useState(false);
	const { success, error } = useSnackbar();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getProjectLoanApplicationProjectFinance(projectid);
				if (httpResponseIsValid(response)) {
					setProject(response.project);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				console.log(`Error occured when fetching loan's appplication project finance data: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, projectid],
	);

	const handleSubmit = async (values) => {
		setIsLoading(true);

		try {
			const response = await updateProjectLoanApplicationProjectFinance(projectid, values);
			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			console.log(`Error occured when updating loan's appplication project finance data: ${_error}`);
		}

		setIsLoading(false);
	};

	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: project,
		onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	});

	const { dirty } = formik;

	usePrompt("You have unsaved changes which will be lost. Are you sure you want to leave the page?", dirty);

	useEffect(() => {
		setShouldUiBeDisabled(disableAccess || !enableEditing);
	}, [disableAccess, enableEditing]);

	useEffect(() => {
		formik.resetForm({ values: project, dirty: false });
	}, [project]);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData, projectid]);

	return (
		<form onSubmit={formik.handleSubmit}>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<MainMenu
				title={t("LoanApplication.ProjectFinance.title")}
				dirty={dirty}
				project={project}
				isDeveloper={isProjectDeveloper}
				disabled={disableAccess}
				onFetch={fetchData}
				onEdit={setEnableEditing}
				onChange={onChangeProjectState}
			/>

			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "105px" }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("LoanApplication.ProjectFinance.ProjectFinance.title")}
					</Typography>
				</Grid>

				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#6F96C6">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("LoanApplication.ProjectFinance.EnvisagedCommercial.title")}
					</Typography>
				</Grid>

				<LabelTextField
					disabled
					id="data.purpose_of_project"
					label={t("LoanApplication.ProjectFinance.EnvisagedCommercial.purposeOfProject")}
					size="large"
					placeholder="Answer"
					formik={formik}
				/>

				<LabelFileUpload
					fieldId="docs.envisaged_contract"
					projectId={projectid}
					label={t("LoanApplication.ProjectFinance.EnvisagedCommercial.doc1")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="loanApplication"
					infoText={t("LoanApplication.ProjectFinance.EnvisagedCommercial.info1")}
					onSuccess={() => fetchData()}
				/>

				<LabelFileUpload
					fieldId="docs.analysis"
					projectId={projectid}
					label={t("LoanApplication.ProjectFinance.EnvisagedCommercial.doc2")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="loanApplication"
					onSuccess={() => fetchData()}
				/>

				<LabelTextField
					id="data.envisioned_financing_structure"
					label={t("LoanApplication.ProjectFinance.EnvisagedCommercial.envisionedFinancingStructure")}
					size="large"
					placeholder="Answer"
					disabled={shouldUiBeDisabled}
					infoText={t("LoanApplication.ProjectFinance.EnvisagedCommercial.info2")}
					formik={formik}
				/>

				<LabelTextField
					id="data.financial_model"
					label={t("LoanApplication.ProjectFinance.EnvisagedCommercial.financialModel")}
					size="large"
					rows={3}
					placeholder="Answer"
					disabled={shouldUiBeDisabled}
					infoText={t("LoanApplication.ProjectFinance.EnvisagedCommercial.info3")}
					formik={formik}
				/>

				<LabelFileUpload
					fieldId="docs.advice"
					projectId={projectid}
					label={t("LoanApplication.ProjectFinance.EnvisagedCommercial.doc3")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="loanApplication"
					infoText={t("LoanApplication.ProjectFinance.EnvisagedCommercial.info4")}
					onSuccess={() => fetchData()}
				/>

				<LabelFileUpload
					fieldId="docs.project_risk_assessment"
					projectId={projectid}
					label={t("LoanApplication.ProjectFinance.EnvisagedCommercial.doc4")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="loanApplication"
					onSuccess={() => fetchData()}
				/>
			</Grid>
		</form>
	);
};

export default memo(LoanApplicationProjectFinance);
