import { createTheme } from "@mui/material/styles";

// Define Green Color Theme
const greenTheme = {
	main: "#4FB8A0",
	dark: "#3E8877",
	light: "#53C2A9",
	neutral: "#ffffff",
};

// Define Blue Color Theme
const blueTheme = {
	dark: "#1d4363",
	light: "#5C9AE1",
	neutral: "#ffffff",
};

export const themeColors = ["green", "blue"];

const sefaTheme = createTheme({
	palette: {
		primary: { main: "#3A85C5" },
		secondary: { main: "#429BBD" },

		green: greenTheme,
		blue: blueTheme,

		success: { main: "#90cb1b" },
		error: { main: "#ff1a22" },
		warning: { main: "#ff9f00" },
		info: { main: "#006ba4" },

		dark: { main: "#262835" },
		orange: { main: "#F1A542" },
		grey: {
			main: "#DBDFE1",
			dark: "#ccd9e2",
			light: "#dfeaf1",
			transparent: "#f2f7f9",
		},

		darkTransparent: { main: "rgba(0, 0, 0, 0.1)" },
	},
	tileShadow: "0px 0px 4px -1px rgba(0,0,0,0.2), 0px 0px 5px 0px rgba(0,0,0,0.14), 0px 0px 10px 0px rgba(0,0,0,0.12)",
	typography: {
		h7: {
			fontSize: "0.88rem",
			fontFamily: "Poppins, sans-serif",
		},
		h6: {
			fontSize: "1.125rem",
			fontFamily: "Poppins, sans-serif",
		},
		h1: {
			fontFamily: "Poppins, sans-serif",
		},
		h2: {
			fontFamily: "Poppins, sans-serif",
		},
		h3: {
			fontFamily: "Poppins, sans-serif",
		},
		h4: {
			fontFamily: "Poppins, sans-serif",
		},
		h5: {
			fontFamily: "Poppins, sans-serif",
		},
		fontFamily: "Roboto, sans-serif",
		title: {
			color: "#ffffff",
			letterSpacing: "2px",
		},
	},
});

export default sefaTheme;
