import { memo, useCallback, useEffect, useState, forwardRef } from "react";
import { useParams } from "react-router-dom";
import { Button, Grid, LinearProgress, Typography, InputAdornment, Link, Dialog, Slide, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import { useSnackbar, httpResponseIsValid } from "../../utils";
import { deleteFile, getProjectRiskAssessentProtocol, updateProjectRiskAssessentProtocol } from "../../api";
import StatusDialog from "../../components/StatusDialog";
import {
	MainMenu,
	LabelTextField,
	LabelRadioTextField,
	LabelFileUpload,
	LabelRadio,
} from "../../components/basic/index";

const Transition = forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const EnergyPriceRisk = ({ disableAccess = true, isProjectDeveloper = false, onChangeProjectState }) => {
	const { projectid } = useParams();
	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(disableAccess);
	const [projectStateDialog, setProjectStateDialog] = useState(false);
	const [enableEditing, setEnableEditing] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [projectInfo, setProjectInfo] = useState({
		name: "",
		status: "",
		createdAt: "-",
		energy_price_risk: {
			risk_of_deviation_radio: "",
			energy_price_risk_relevance: "",
			energy_price_risk_responsibility: "",
			energy_price_risk_mitigation: "",
			risk_of_deviation: "",
			hedge_energy_price_risk: "",
			duration_and_percentage: "",
			energy_price_development: "",
			energy_price_development_percentage: "",
			energy_price_movement_year_5: "",
			energy_price_movement_year_4: "",
			energy_price_movement_year_3: "",
			energy_price_movement_year_2: "",
			energy_price_movement_year_1: "",
			inflation_rate_year_5: "",
			inflation_rate_year_4: "",
			inflation_rate_year_3: "",
			inflation_rate_year_2: "",
			inflation_rate_year_1: "",
		},
		energy_price_risk_doc_1: {
			original_name: "",
			save_name: "",
		},
		energy_price_risk_doc_2: {
			original_name: "",
			save_name: "",
		},
		energy_price_risk_doc_3: {
			original_name: "",
			save_name: "",
		},

	});
	const { success, error } = useSnackbar();
	const [deleteModal, setDeleteModal] = useState({ component: null, variable: null });

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getProjectRiskAssessentProtocol(projectid);
				if (httpResponseIsValid(response)) {
					setProjectInfo(response);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured while fetching risk assessment energy price risk data: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, projectid],
	);

	const changeProjectState = useCallback(
		async () => {
			await onChangeProjectState();
			fetchData();
		}, [fetchData, onChangeProjectState],
	);

	const handleSubmit = async (values) => {
		setIsLoading(true);
		try {
			const response = await updateProjectRiskAssessentProtocol(projectid, values);
			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			error(`Error occured while submitting risk assessment regulatory risk language: ${_error}`);
		}

		setIsLoading(false);
	};

	const onDelete = async (comp) => {
		try {
			setIsLoading(true);
			const { success: deleteSuccess } = await deleteFile({ projectid, component: comp.component, variable: comp.variable });
			console.log(deleteSuccess);
			if (deleteSuccess) {
				success("File deleted successfully");
				await fetchData(false);
				setIsLoading(false);
			} else {
				error();
				setIsLoading(false);
			}
		} catch { /* empty */ }
	};

	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: projectInfo,
		onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	});

	const { dirty } = formik;

	useEffect(() => {
		setShouldUiBeDisabled(disableAccess || !enableEditing);
	}, [disableAccess, enableEditing]);

	useEffect(() => {
		formik.resetForm({ values: projectInfo, dirty: false });
	}, [projectInfo]);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData, projectid]);

	return (
		<form onSubmit={formik.handleSubmit}>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<MainMenu
				title={t("RiskAssessmentOverview.energyPriceRisk")}
				dirty={dirty}
				project={projectInfo}
				isDeveloper={isProjectDeveloper}
				disabled={disableAccess}
				onFetch={fetchData}
				onEdit={setEnableEditing}
				onChange={onChangeProjectState}
			/>
			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "105px" }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("RegulatoryRisk.relevance")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} alignItems="top">
					<Grid item xs={12} md={9} sm={8} p={1}>
						<LabelRadioTextField
							radioId="energy_price_risk.energy_price_development"
							fieldId="energy_price_risk.energy_price_development_percentage"
							label={t("EnergyPriceRisk.relevanceVar1")}
							size="flat"
							typosize="h8"
							type="number"
							disabled={shouldUiBeDisabled}
							placeholder="%"
							formik={formik}
						/>
						<Typography variant="h7" sx={{ whiteSpace: "pre-line", m: 1, color: "blue.dark" }}>
							{"\n"}

							{t("EnergyPriceRisk.relevanceVar4")}
							<Link href="https://ec.europa.eu/eurostat/statistics-explained/index.php?title=Electricity_price_statistics" underline="always">
								{"Eurostat"}
							</Link>
							{"\n"}
							{t("EnergyPriceRisk.relevanceVar5")}
							<Link href="https://www.ecb.europa.eu/stats/ecb_statistics/escb/html/table.en.html?id=JDF_ICP_COICOP_ANR" underline="always">
								{"ECB"}
							</Link>
							{"\n"}
						</Typography>
						<Grid item xs={12} md={11.9} sm={8} p={1}>

							<Grid container justify="center" sx={{ ml: 1 }}>
								<Grid item xs={2} align="center" sx={{ border: 1, borderLeft: 1, borderBottom: 0, borderRight: 0, borderTop: 1 }}>
									<Typography variant="h7" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
										{""}
									</Typography>
								</Grid>
								<Grid item xs align="center" sx={{ border: 1, borderLeft: 1, borderBottom: 0, borderRight: 0, borderTop: 1 }}>
									<Typography variant="h7" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
										{t("EnergyPriceRisk.year")}
										&nbsp;
										{"5"}
									</Typography>
								</Grid>
								<Grid item xs align="center" sx={{ border: 1, borderLeft: 1, borderBottom: 0, borderRight: 0, borderTop: 1 }}>
									<Typography variant="h7" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
										{t("EnergyPriceRisk.year")}
										&nbsp;
										{"4"}
									</Typography>
								</Grid>
								<Grid item xs align="center" sx={{ border: 1, borderLeft: 1, borderBottom: 0, borderRight: 0, borderTop: 1 }}>
									<Typography variant="h7" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
										{t("EnergyPriceRisk.year")}
										&nbsp;
										{"3"}
									</Typography>
								</Grid>
								<Grid item xs align="center" sx={{ border: 1, borderLeft: 1, borderBottom: 0, borderRight: 0, borderTop: 1 }}>
									<Typography variant="h7" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
										{t("EnergyPriceRisk.year")}
										&nbsp;
										{"2"}
									</Typography>
								</Grid>
								<Grid item xs align="center" sx={{ border: 1, borderLeft: 1, borderBottom: 0, borderRight: 1, borderTop: 1 }}>
									<Typography variant="h7" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
										{t("EnergyPriceRisk.year")}
										&nbsp;
										{"1"}
									</Typography>
								</Grid>
							</Grid>
							<Grid container justifyContent="center" sx={{ ml: 1, mt: 0 }}>
								<Grid container item xs={2} alignItems="center" justifyContent="center" sx={{ border: 1, borderLeft: 1, borderBottom: 0, borderRight: 0, borderTop: 1 }}>
									<Typography variant="h7" sx={{ whiteSpace: "pre-line", color: "blue.dark", textAlign: "center" }}>
										{t("EnergyPriceRisk.energyPriceMovement")}
									</Typography>
								</Grid>
								<Grid item xs align="center" sx={{ border: 1, borderLeft: 1, borderBottom: 0, borderRight: 0, borderTop: 1 }}>
									<LabelTextField
										id="energy_price_risk.energy_price_movement_year_5"
										label=""
										size="verysmall"
										typosize="h8"
										titleAlign="center"
										placeholder="%"
										type='number'
										InputProps={{
											inputProps: { min: 0 },
											startAdornment: <InputAdornment position="start">{"%"}</InputAdornment>,
										}}
										disabled={shouldUiBeDisabled}
										formik={formik}
									/>
								</Grid>
								<Grid item xs align="center" sx={{ border: 1, borderLeft: 1, borderBottom: 0, borderRight: 0, borderTop: 1 }}>
									<LabelTextField
										id="energy_price_risk.energy_price_movement_year_4"
										label=""
										size="verysmall"
										typosize="h8"
										titleAlign="center"
										placeholder="%"
										type='number'
										InputProps={{
											inputProps: { min: 0 },
											startAdornment: <InputAdornment position="start">{"%"}</InputAdornment>,
										}}
										disabled={shouldUiBeDisabled}
										formik={formik}
									/>
								</Grid>
								<Grid item xs align="center" sx={{ border: 1, borderLeft: 1, borderBottom: 0, borderRight: 0, borderTop: 1 }}>
									<LabelTextField
										id="energy_price_risk.energy_price_movement_year_3"
										label=""
										size="verysmall"
										typosize="h8"
										titleAlign="center"
										placeholder="%"
										type='number'
										InputProps={{
											inputProps: { min: 0 },
											startAdornment: <InputAdornment position="start">{"%"}</InputAdornment>,
										}}
										disabled={shouldUiBeDisabled}
										formik={formik}
									/>
								</Grid>
								<Grid item xs align="center" sx={{ border: 1, borderLeft: 1, borderBottom: 0, borderRight: 0, borderTop: 1 }}>
									<LabelTextField
										id="energy_price_risk.energy_price_movement_year_2"
										label=""
										size="verysmall"
										typosize="h8"
										titleAlign="center"
										placeholder="%"
										type='number'
										InputProps={{
											inputProps: { min: 0 },
											startAdornment: <InputAdornment position="start">{"%"}</InputAdornment>,
										}}
										disabled={shouldUiBeDisabled}
										formik={formik}
									/>
								</Grid>
								<Grid item xs align="center" sx={{ border: 1, borderLeft: 1, borderBottom: 0, borderRight: 1, borderTop: 1 }}>
									<LabelTextField
										id="energy_price_risk.energy_price_movement_year_1"
										label=""
										size="verysmall"
										typosize="h8"
										titleAlign="center"
										placeholder="%"
										type='number'
										InputProps={{
											inputProps: { min: 0 },
											startAdornment: <InputAdornment position="start">{"%"}</InputAdornment>,
										}}
										disabled={shouldUiBeDisabled}
										formik={formik}
									/>
								</Grid>
							</Grid>
							<Grid container justifyContent="center" sx={{ ml: 1, mt: 0 }}>
								<Grid container item xs={2} alignItems="center" justifyContent="center" sx={{ border: 1, borderLeft: 1, borderBottom: 1, borderRight: 0, borderTop: 1 }}>
									<Typography variant="h7" sx={{ whiteSpace: "pre-line", color: "blue.dark", textAlign: "center" }}>
										{t("EnergyPriceRisk.inflationRate")}
									</Typography>
								</Grid>
								<Grid item xs align="center" sx={{ border: 1, borderLeft: 1, borderBottom: 1, borderRight: 0, borderTop: 1 }}>
									<LabelTextField
										id="energy_price_risk.inflation_rate_year_5"
										label=""
										size="verysmall"
										typosize="h8"
										titleAlign="center"
										placeholder="%"
										type='number'
										InputProps={{
											inputProps: { min: 0 },
											startAdornment: <InputAdornment position="start">{"%"}</InputAdornment>,
										}}
										disabled={shouldUiBeDisabled}
										formik={formik}
									/>
								</Grid>
								<Grid item xs align="center" sx={{ border: 1, borderLeft: 1, borderBottom: 1, borderRight: 0, borderTop: 1 }}>
									<LabelTextField
										id="energy_price_risk.inflation_rate_year_4"
										label=""
										size="verysmall"
										typosize="h8"
										titleAlign="center"
										placeholder="%"
										type='number'
										InputProps={{
											inputProps: { min: 0 },
											startAdornment: <InputAdornment position="start">{"%"}</InputAdornment>,
										}}
										disabled={shouldUiBeDisabled}
										formik={formik}
									/>
								</Grid>
								<Grid item xs align="center" sx={{ border: 1, borderLeft: 1, borderBottom: 1, borderRight: 0, borderTop: 1 }}>
									<LabelTextField
										id="energy_price_risk.inflation_rate_year_3"
										label=""
										size="verysmall"
										typosize="h8"
										titleAlign="center"
										placeholder="%"
										type='number'
										InputProps={{
											inputProps: { min: 0 },
											startAdornment: <InputAdornment position="start">{"%"}</InputAdornment>,
										}}
										disabled={shouldUiBeDisabled}
										formik={formik}
									/>
								</Grid>
								<Grid item xs align="center" sx={{ border: 1, borderLeft: 1, borderBottom: 1, borderRight: 0, borderTop: 1 }}>
									<LabelTextField
										id="energy_price_risk.inflation_rate_year_2"
										label=""
										size="verysmall"
										typosize="h8"
										titleAlign="center"
										placeholder="%"
										type='number'
										InputProps={{
											inputProps: { min: 0 },
											startAdornment: <InputAdornment position="start">{"%"}</InputAdornment>,
										}}
										disabled={shouldUiBeDisabled}
										formik={formik}
									/>
								</Grid>
								<Grid item xs align="center" sx={{ border: 1, borderLeft: 1, borderBottom: 1, borderRight: 1, borderTop: 1 }}>
									<LabelTextField
										id="energy_price_risk.inflation_rate_year_1"
										label=""
										size="verysmall"
										typosize="h8"
										titleAlign="center"
										placeholder="%"
										type='number'
										InputProps={{
											inputProps: { min: 0 },
											startAdornment: <InputAdornment position="start">{"%"}</InputAdornment>,
										}}
										disabled={shouldUiBeDisabled}
										formik={formik}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item md={0.2} />
					<Grid item xs={12} sm={4} md={2} direction="row" alignItems="center">
						<LabelRadio
							id="energy_price_risk.energy_price_risk_relevance"
							label={t("RegulatoryRisk.riskPresent")}
							size="large"
							options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
					</Grid>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("RegulatoryRisk.responsibility")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center">
					<LabelRadio
						id="energy_price_risk.risk_of_deviation"
						label={t("EnergyPriceRisk.responsibilityVar1")}
						size="large"
						typosize="h8"
						options={[{ value: "false", label: "Client" }, { value: "true", label: "Contractor" }]}
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("EnergyPriceRisk.mitigation")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} alignItems="top">
					<Grid item xs={12} md={9} sm={8} p={1}>

						<LabelTextField
							id="energy_price_risk.hedge_energy_price_risk"
							label={t("EnergyPriceRisk.mitigationVar1")}
							size="flat"
							typosize="h8"
							placeholder="Answer"
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
						<LabelTextField
							id="energy_price_risk.duration_and_percentage"
							label={t("EnergyPriceRisk.mitigationVar2")}
							size="flat"
							typosize="h8"
							placeholder="Answer"
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
					</Grid>
					<Grid item md={0.2} />
					<Grid item xs={12} sm={4} md={2} direction="row" alignItems="center">
						<LabelRadio
							id="energy_price_risk.energy_price_risk_mitigation"
							label={t("EnergyPriceRisk.fullyMitigated")}
							size="flat"
							typosize="h8"
							options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
					</Grid>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("RegulatoryRisk.documentation")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center">
					<Grid item xs={12} md={9} p={1}>
						<Typography variant="h8" alignItems="center" color="blue.dark">
							{t("RegulatoryRisk.documentationVar1")}
						</Typography>
					</Grid>
					<Grid item container sx={{ p: 1 }} alignItems="center">
						<LabelFileUpload
							fieldId="energy_price_risk_doc_1"
							projectId={projectid}
							label={t("EnergyPriceRisk.documentationVar1")}
							size="large"
							typosize="h8"
							disabled={shouldUiBeDisabled}
							formik={formik}
							fileTool="riskAssessmentProtocol"
							onSuccess={() => fetchData()}
						/>
					</Grid>
					<Grid item container sx={{ p: 1 }} alignItems="center">
						<LabelFileUpload
							fieldId="energy_price_risk_doc_2"
							projectId={projectid}
							label={t("EnergyPriceRisk.documentationVar2")}
							size="large"
							typosize="h8"
							disabled={shouldUiBeDisabled}
							formik={formik}
							fileTool="riskAssessmentProtocol"
							onSuccess={() => fetchData()}
						/>
					</Grid>
					<Grid item container sx={{ p: 1 }} alignItems="center">
						<LabelFileUpload
							fieldId="energy_price_risk_doc_3"
							projectId={projectid}
							label={t("EnergyPriceRisk.documentationVar3")}
							size="large"
							typosize="h8"
							disabled={shouldUiBeDisabled}
							formik={formik}
							fileTool="riskAssessmentProtocol"
							onSuccess={() => fetchData()}
						/>
					</Grid>
				</Grid>
			</Grid>
			{deleteModal.component !== null
				&& deleteModal.variable !== null
				&& (
					<Dialog
						keepMounted
						open={deleteModal.component !== null && deleteModal.variable !== null}
						TransitionComponent={Transition}
						onClose={() => setDeleteModal({ component: null, variable: null })}
					>
						<DialogTitle>
							{"Are you sure?"}
						</DialogTitle>
						<DialogContent dividers>
							<DialogContentText>
								{"Are you sure you want to delete the file?"}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								autoFocus
								startIcon={<Delete />}
								variant="contained"
								onClick={() => {
									onDelete(deleteModal);
									setDeleteModal({ component: null, variable: null });
								}}
							>
								{"Delete"}
							</Button>
							<Button variant="outlined" onClick={() => setDeleteModal({ component: null, variable: null })}>{"Cancel"}</Button>
						</DialogActions>
					</Dialog>
				)}
			<StatusDialog
				open={projectStateDialog}
				status={projectInfo.status}
				name={projectInfo.name}
				onClose={setProjectStateDialog}
				onChange={changeProjectState}
			/>
		</form>
	);
};

export default memo(EnergyPriceRisk);
