
module.exports = {
	RiskAssessmentOverview: {
		riskAssessmentOverview: "Risikobewertung im Überblick",
		riskTitle: "Risikotitel",
		riskPresence: "Risiko Vorhanden",
		action: "Aktion",
		manage: "Manage",
		creditRiskServiceProvider: "Kreditirisiko Projektträger/Auftraggeber ",
		creditRiskImplementorClient: "Kreditrisiko Dienstleister/Auftragnehmer ",
		regulatoryRisk: "Regulierungsrisiko ",
		marketRisk: "Marktrisiko",
		energyPriceRisk: "Energiepreisrisiko",
		currencyRisk: "Währungsrisiko ",
		technicalRisk: "Technischesrisiko",
		performanceRisk: "Leistungsrisiko",
		omRisk: "Betrieb-und Wartungsrisiko",
		interfaceRisk: "Schnittstellenrisiko",
		pipelineRisk: "Vertriebs-Pipeline-Risiko",
		prepaymentRisk: "Vorauszahlungsrisiko ",
		occupacyRisk: "Belegungsrisiko",
		managementRisk: "Managementrisiko",
		changeInControlRisk: "Eigentümerwechselrisiko",
		constructionRisk: "Baurisiko",
		supplyChainRisk: "Lieferkettenrisiko",
	},
	CreditRiskServiceProvider: {
		riskTitle: "Kreditrisiko Dienstleister/Auftragnehmer",
		relevance: "RELEVANZ",
		formula: "Formel",
		year1: "Jahr 1",
		kpiYear1: "KPI Jahr 1",
		year2: "Jahr 2",
		kpiYear2: "KPI Jahr 2",
		year3: "Jahr 3",
		kpiYear3: "KPI Jahr 3",
		liquidity: "Liquidität",
		currentRatio: "Liquidität 3. Grades (current ratio)",
		quickRatio: "Liquidität 2. Grades (quick ratio)",
		comparison: "Vergleich",
		solvency: "Solvabilität",
		solvencyRatio: "Solvenzquote",
		profitability: "Rentabilität",
		netProfitMargin: "Nettomarge",
		commercialProfitability: "Wirtschaftliche Rentabilität",
		repaymentCapacityRatio: "Tilgungs-kapazitätsquote",
		supplyChainRatios: "Lieferkettenkennzahlen",
		workingCapital: "Netto-Umlaufvermögen",
		totalScore: "Gesmtpunktzahl",
	},
	CreditRiskImplementorClient: {
		riskTitle: "Kreditrisiko Endkunde/Auftraggebers",
		relevance: "RELEVANZ",
		formula: "Formel",
		year1: "Jahr 1",
		kpiYear1: "KPI Jahr 1",
		year2: "Jahr 2",
		kpiYear2: "KPI Jahr 2",
		year3: "Jahr 3",
		kpiYear3: "KPI Jahr 3",
		liquidity: "Liquidität",
		currentRatio: "Liquidität 3. Grades (current ratio)",
		quickRatio: "Liquidität 2. Grades (quick ratio)",
		comparison: "Vergleich",
		solvency: "Solvabilität",
		solvencyRatio: "Solvenzquote",
		profitability: "Rentabilität",
		commercialProfitability: "Wirtschaftliche Rentabilität",
		debtServiceCoverageRatio: "Tilgungs-kapazitätsquote",
		supplyChainRatios: "Lieferkettenkennzahlen",
		workingCapital: "Netto-Umlaufvermögen",
		totalScore: "Gesmtpunktzahl",
	},
	RegulatoryRisk: {
		relevance: "RELEVANZ",
		yes: "Ja",
		no:	"Nein",
		relevanceVar1:	"Anreize",
		relevanceVar2:	"- Sind staatliche Anreize im Erlösmodell enthalten?",
		relevanceVar3:	"- Welcher Art ist der Anreiz - Zuschuss im Voraus, Steueranreiz für eine Reihe von Jahren, Einspeisetarife, weiße Zertifikate...? ",
		relevanceVar4:	"- Wie hoch ist der Anteil der Anreizeinnahmen in % des Gesamtumsatzes?  ",
		relevanceVar5:	"Behördliche Vorschriften",
		relevanceVar6:	"- Unterliegt das Projekt irgendwelchen relevanten Umwelt- und/oder Gesundheits- und Sicherheitsbestimmungen?",
		riskPresent:	"Risiko Vorhanden?",
		responsibility:	"VERANTWORTUNG",
		responsibilityVar1:	"- Welche Partei ist der Begünstigte des Anreizes?",
		responsibilityVar2:	"- Kann der Anreiz neu zugewiesen werden?",
		responsibilityVar3:	"- Gibt es Einschränkungen bei der Neuzuweisung des Anreizes?",
		responsibilityVar4:	"- Falls die Einnahmen aus dem Anreiz aufgeteilt werden, geben Sie die prozentualen Anteile für alle betroffenen Parteien an.",
		responsibilityVar5:	"- Beschreiben Sie, wie Auftraggeber und/oder Auftragnehmer alle relevanten Umwelt- und/oder Gesundheits- und Sicherheitsvorschriften einhalten.",
		riskBearer:	"Risikoträger?",
		documentation:	"DOKUMENTATION",
		documentationVar1:	"Führen Sie alle verfügbaren Unterlagen auf, um die oben genannten Angaben zu belegen:",
		documentationVar2:	"Einschlägige Gesetze oder Verordnungen, die die Anreize regeln, und ihre mögliche Neuzuweisung	",
		documentationVar3:	"Historische Daten über das Volumen der gewährten Anreize und die Art der Projekte, die von dem Anreiz profitiert haben (offizielle Quelle)	",
		documentationVar4:	"Relevante Gesetze oder Verordnungen bezüglich der Einhaltung von Umwelt- und/oder Gesundheits- und Sicherheitsvorschriften",
	},
	MarketRisk: {
		relevanceVar1:	"- In welchem Land/welchen Ländern sind der Auftragnehmer und der Auftraggeber tätig? ",
		relevanceVar2:	"- Wie lautet das aktuelle Marktrating und der jeweilige Ausblick?",
		relevanceVar3:	"- Nennen Sie das jeweilige Datum des Ratings",
		currentRating:	"Aktuelles Marktrating",
		outlook:	"Ausblick ",
		contractor:	"Auftragnehmer",
		client:	"Endkunde",
		date:	"Datum",

	},
	EnergyPriceRisk: {
		relevanceVar1:	"Berücksichtigt das Finanzierungsmodel des Projekts die Energiepreisentwicklung? ",
		relevanceVar2:	"- Wenn Ja, machen Sie bitte die folgenden Angaben:",
		relevanceVar3:	"- Wie wird die Entwicklung des Energiepreises im Laufe der Investitionslaufzeit angenommen? [in % pro Jahr]",
		relevanceVar4:	"- Wie war die jährliche Entwicklung des (relevanten) Energiepreises in % in den letzten 5 Jahren? Eurostat",
		relevanceVar5:	"- Wie war die Entwicklung der Inflationsrate im relevanten Markt in % in den letzten 5 Jahren? EZB",
		year:	"Jahr",
		energyPriceMovement:	"Energiepreisentwicklung",
		inflationRate:	"Inflationsrate",
		responsibilityVar1:	"- Wer trägt das Risiko der Abweichung von der angenommenen Energiepreiskurve (Fehlbetrag, Mehrerlös?)",
		mitigation: "MILDERUNG",
		mitigationVar1:	"- Hat der Dienstleister/Auftragnehmer das Energiepreisrisiko abgesichert (z. B. durch Kauf/Verkauf von Energie-Futures)?",
		mitigationVar2:	"- Für welche Dauer und in welchem % des monatlichen Basisenergieverbrauchs ist diese Absicherung erfolgt?",
		fullyMitigated:	"Risiko Vollständig Abgesichert?",
		documentationVar1:	"Liefern Sie Informationen über historische Energiepreisbewegungen aus relevanten Quellen (Eurostat)",
		documentationVar2:	"Liefern Sie Informationen über historische Inflationsraten aus relevanten Quellen (EZB)",
		documentationVar3:	"Liefern Sie unterstützende Informationen über ein potenzielles Energiepreis-Absicherungsinstrument	",

	},
	CurrencyRisk: {
		relevanceVar1:	"Gibt es eine einheitliche Währung, die für dieses Projekt verwendet wird?",
		relevanceVar2:	"Wenn Ja, geben Sie die Währung an: [Währung]",
		relevanceVar3:	"Wenn Nein, beantworten Sie bitte die folgenden Fragen:",
		relevanceVar4:	"- In welcher Währung werden die Cashflows des Projekts generiert? [Währung]",
		relevanceVar5:	"- In welcher Währung wird die Ausrüstung gekauft? [Währung]",
		relevanceVar6:	"- Ist für wesentliche vorhersehbare betriebliche Ausgaben während der Projektlaufzeit eine andere Währung vorgesehen? Wenn Ja, geben Sie die Währung an: [Währung] und geben Sie die Betriebskosten an: [Aufwand]",
		mitigationVar1:	"- Hat der Dienstleister/Auftragnehmer das Währungsrisiko abgesichert (z. B. durch Kauf/Verkauf von Devisentermingeschäften)?",
		mitigationVar2:	"- Für welche Dauer und in welchem Prozentsatz des Umsatzes und/oder der betrieblichen Aufwendungen ist diese Absicherung erfolgt?",
		documentationVar1:	"Stellen Sie unterstützende Informationen zu einem potenziellen Währungskursabsicherungsinstrument bereit",

	},
	TechnicalRisk: {
		relevanceVar1:	"- Gesamtwert der installierten Geräte: [monetärer Begriff]",
		relevanceVar2:	"- Nutzungsdauer der installierten Geräte: [Jahre] ",
		relevanceVar3:	"- Laufzeit des Vertrages mit dem Endkunden/Auftraggeber: [Jahre]",
		relevanceVar4:	"- Nutzungsdauer > Laufzeit des Vertrages?",
		relevanceVar5:	"- Abschreibung der installierten Geräte: ",
		over: "über, ",
		years: "Jahre.",
		straightLine:	"linear",
		accelerated:	"beschleunigt",
		relevanceVar6:	"- Es sollen die besten verfügbaren Technologien angewendet werden - bitte machen Sie Angaben:",
		relevanceVar7:	"Ob das/die Produkt(e) in der Ökodesign-Verordnung definiert ist/sind",
		relevanceVar8:	"Eine Referenzliste von min. 3 realisierten Projekten, in denen das/die Produkt(e) verwendet wurden",
		responsibilityVar1:	"Gemäß des standardisierten LAUNCH-Vertrags, Artikel 2.11, ist der Dienstleister/Auftragnehmer verpflichtet, Versicherungen abzuschließen und aufrechtzuerhalten, die im Wesentlichen technische Risiken ausschließen (siehe unten).",
		mitigationVar1:	"Die folgenden Versicherungen sind abzuschließen und aufrechtzuerhalten:",
		mitigationVar2:	"- Garantien auf die gesamte installierte Ausrüstung für eine Dauer, die der Dauer des Servicevertrags und des Investitionsvertrags entspricht oder darüber hinausgeht;",
		mitigationVar3:	"- Versicherung der Ausrüstung zu einem Wert, der nicht unter ihrem vollen Wiederbeschaffungswert liegt, umfassend gegen alle üblichen Risiken von Verlust, Beschädigung oder Zerstörung durch Feuer, Diebstahl oder Unfall;",
		mitigationVar4:	"- eine Versicherung in Höhe der Beträge, die ein umsichtiger Eigentümer oder Betreiber der Ausrüstung versichern würde, oder in Höhe der Beträge, die der Dienstleister/Auftragnehmer von Zeit zu Zeit vernünftigerweise verlangen kann, um alle Risiken Dritter oder Haftpflichtrisiken, gleich welcher Art und wie auch immer, die in Verbindung mit der Ausrüstung entstehen, abzudecken; und",
		mitigationVar5:	"- eine Versicherung gegen andere oder weitere Risiken im Zusammenhang mit der Ausrüstung, die gesetzlich vorgeschrieben ist, zusammen mit anderen Versicherungen, die der Dienstleister/Auftragnehmer von Zeit zu Zeit für angemessen erforderlich hält.",
		mitigationVar6:	"- Der Dienstleister/Auftragnehmer ist für die Zahlung von Selbstbeteiligungen bei Ansprüchen aus diesen Versicherungen verantwortlich.",
		documentationVar1:	"Technisches Datenblatt des installierten Geräts mit Angabe der Nutzungsdauer",
		documentationVar2:	"Garantien für die installierten Geräte",
		documentationVar3:	"Versicherung der Geräte (Verlust/Beschädigung/Diebstahl): Police beifügen",
		documentationVar4:	"Haftpflichtversicherung für die installierten Geräte: Police beifügen",
		documentationVar5:	"Sonstige Versicherungen, die gesetzlich vorgeschrieben sind: Police beifügen",
		documentationVar6:	"Referenzliste der durchgeführten Projekte, in denen das Gerät verwendet wurde (falls nicht in der Ökodesign-Verordnung definiert)",

	},
	PerformanceRisk: {
		relevanceVar1:	"Relevante Faktoren, die zu dieser Risikoart gehören:",
		relevanceVar2:	"1. Fehlerhaftes Energieaudit",
		relevanceVar3:	"2. Schlechte oder fehlerhafte Projektplanung",
		relevanceVar4:	"3. Mängel bei der Umsetzung von EE-Maßnahmen",
		relevanceVar5:	"4. Fehlfunktion der Ausrüstung",
		relevanceVar6:	"5. Fehler bei der Durchführung der Maßnahmen",
		relevanceVar7:	"1. Instandhaltung/Betrieb durch den Dienstleister/Auftragnehmer",
		relevanceVar8:	"2. Gewartet/betrieben durch den Teilnehmer/Auftraggeber",
		relevanceVar9:	"6. Fluktuation der Nutzungsmuster, einschließlich Änderung des Nutzerverhaltens Das Energiepreisrisiko wird in diesem Risikobewertungsprotokoll als eine eigene Risikoart behandelt.",
		relevanceVar10:	"Mindest-Energieeinsparungen wie im standardisierten LAUNCH-Dienstleistungsvertrag angegeben:",
		responsibilityVar1: "Gemäß der standardisierten LAUNCH-Dienstleistungsvereinbarung garantiert der Dienstleister/Auftragnehmer, dass bestimmte Dienstleistungsniveaus während der gesamten Laufzeit erreicht werden, sofern der Projektträger/Auftraggeber alle seine Verpflichtungen zufriedenstellend erfüllt. Daher wird eine unzureichende Leistung der EE-Maßnahmen, die auf den oben genannten Faktoren 1-4 sowie 5a) beruhen, vom Dienstleister/Auftragnehmer übernommen. Dieses garantierte Einsparsystem (im Gegensatz zum geteilten Einsparsystem) führt zu einem höheren Anreiz für den Dienstleister/Auftragnehmer, die geplanten Einsparungen zu erzielen.",
		responsibilityVar2:	"Der Dienstleister/Auftragnehmer muss außerdem einen detaillierten Mess- und Verifizierungsplan (M&V) vorlegen, der Folgendes enthält",
		responsibilityVar3:	"- Klare Spezifikation der für M&V verwendeten Standards (IPMVP, ISO 50015)",
		responsibilityVar4:	"- Auflistung aller M&V-Aktivitäten",
		responsibilityVar5:	"- Auflistung der für die Berechnungen verwendeten Algorithmen",
		responsibilityVar6:	"- Liste aller verantwortlichen Akteure, die an den M&V-Protokollen beteiligt sind",
		responsibilityVar7:	"Darüber hinaus muss der Projektantrag eine Sensitivitätsanalyse enthalten, in der die vorgeschlagenen Leistungsniveaus mit +/- 10 % und +/- 20 % modelliert und die sich daraus ergebenden Auswirkungen auf den Projekt-Cashflow angegeben werden.",
		mitigationVar1:	"- Hat der Dienstleister/Auftragnehmer eine Leistungsversicherung abgeschlossen?",
		mitigationVar2:	"- Wenn ja, wie viel Prozent der Leistung (in Bezug auf das garantierte Leistungsniveau) ist versichert?",
		documentationVar1:	"Detaillierter M&V Plan",
		documentationVar2:	"Worst-, Real- und Best-Case-Szenarien für das Leistungsniveau",
		documentationVar3:	"Falls vorhanden, Dokumentation der Leistungsversicherung",

	},
	OMRisk: {
		relevanceVar1:	"Da der Dienstleister/Auftragnehmer (gemäß der standardisierten LAUNCH-Vereinbarung) rechtlicher und finanzieller Eigentümer der Ausrüstung ist, besteht für ihn ein Anreiz, den sorgfältigen Betrieb und die rechtzeitige Wartung der Ausrüstung während der gesamten Nutzungsdauer sicherzustellen.",
		relevanceVar2:	"Nichtsdestotrotz muss der Dienstleister/Auftragnehmer einen detaillierten Betrieb&Wartungs-Zeitplan vorlegen und alle relevanten Betrieb&Wartungs-Dokumente von früher durchgeführten und/oder laufenden Projekten ähnlicher Art offenlegen, um dies zu belegen.",
		responsibilityVar1:	"Gemäß der standardisierten LAUNCH-Dienstleistungsvereinbarung ist der Dienstleister/Auftragnehmer für die Durchführung aller erforderlichen Betrieb&Wartungs-Arbeiten während des gesamten Dienstleistungszeitraums verantwortlich. Die folgenden Informationen müssen im Betrieb&Wartungs-Plan enthalten sein:",
		responsibilityVar2:	"- Wer ist der Betrieb&Wartungs-Anbieter?",
		responsibilityVar3:	"- Bonität des Betrieb&Wartungs-Anbieters (falls sie sich vom Dienstleister/Auftragnehmer unterscheidet)",
		responsibilityVar4:	"- Historische Betrieb&Wartungs-Leistungen des Betrieb&Wartungs-Anbieters",
		responsibilityVar5:	"- Überwachungs- und Kommunikationssysteme für die Erkennung von Ausfällen und die Systemleistung",
		responsibilityVar6:	"- Dokumentiertes und einvernehmlich vereinbartes Reaktionsprotokoll (als Anlage zur standardisierten LAUNCH-Vereinbarung)",
		responsibilityVar7:	"- Vorhandensein und Art von Back-up Betrieb&Wartungs-Vereinbarungen",
		responsibilityVar8:	"- Die standardisierte LAUNCH-Dienstleistungsvereinbarung enthält darüber hinaus eine Einsprungklausel, die in bestimmten Fällen den Austausch des Dienstleisters/Auftragnehmers ermöglicht.",
		mitigationVar1:	"[N.O.: Vertragliche Bestimmungen zur Sicherstellung des Betrieb&Wartungs-Servicelevels nach der Verbriefung sollten in die Übertragungsvereinbarung aufgenommen werden.]",
		documentationVar1:	"Detaillierter Betrieb&Wartungs-Plan",
		documentationVar2:	"Vollständige Referenzen und frühere Erfahrungen des zugewiesenen Betrieb&Wartungs-Anbieters",
		documentationVar3:	"Back-up Betrieb&Wartungs-Vereinbarungen (falls vorhanden)",

	},
	InterfaceRisk: {
		relevanceVar1:	"Die standardisierte LAUNCH-Vereinbarung legt klare Rollen und Verantwortlichkeiten zwischen dem Dienstanbieter/Auftragnehmer und dem Endkunden/Auftraggeber fest, um Störungen auf ein Minimum zu beschränken und den ordnungsgemäßen Betrieb der Geräte sicherzustellen.",
		responsibilityVar1:	"Gemäß dem standardisierten LAUNCH-Dienstleistungsvertrag ist der Teilnehmer/Auftraggeber (unter anderem) dazu verpflichtet:",
		responsibilityVar2:	"- die installierten Geräte und ihre Umgebung sauber und sicher zu halten",
		responsibilityVar3:	"- keine Veränderungen an den Geräten vorzunehmen",
		responsibilityVar4:	"- das Gerät an seinem Installationsort zu belassen",
		responsibilityVar5:	"- den Zugang für Inspektion und Wartung zu jeder angemessenen Zeit zu ermöglichen",
		mitigationVar1:	"[N.O.: Der standardisierte LAUNCH-Dienstleistungsvertrag enthält eine Rückbaukostenklausel für den Fall eines Vertragsbruchs oder einer vorzeitigen einseitigen Beendigung des Vertrags.]",

	},
	PipelineRisk: {
		relevanceVar1: "Größe der kommerziellen Projektpipeline des Dienstleisters/Auftragnehmers mit denselben Spezifikationen, insbesondere:",
		relevanceVar2: "- Ähnliche Technologie",
		relevanceVar3: "- Derselbe Kundentyp (Branche, Reifegrad)",
		relevanceVar4: "- Die gleiche vertragliche Vereinbarung",
		relevanceVar5: "- Die gleichen Projektbedingungen",
		relevanceVar6: "Vertrag mit Auftraggeber unterzeichnet - bereit für die Installation:",
		relevanceVar7: "Mit Vereinbarungen zur Durchführung (in weniger als 6 Monaten abzuschließen):",
		relevanceVar8: "Mit Vereinbarungen zur Durchführung (in weniger als 12 Monaten abzuschließen):",
		relevanceVar9: "Wählen Sie die relevanten Währungen aus:",
		documentationVar1: "Detailliertes Dokument zur Projektpipeline, in dem alle relevanten Gelegenheiten gemäß den 3 oben genannten Phasen aufgeführt sind",

	},
	PrePaymentRisk: {
		relevanceVar1:	"In der LAUNCH Standardvereinbarung werden in Artikel 13 eine Reihe von Gründen genannt, aus denen die Vereinbarung vorzeitig beendet werden kann.",
		relevanceVar2:	"Im Falle einer vorzeitigen Beendigung des Dienstes sind die folgenden Bedingungen zu prüfen:",
		relevanceVar3:	"- Technische Erwägung: Die Ausrüstung kann technisch entfernt werden.",
		relevanceVar4:	"- Wirtschaftliche Erwägung: Die Geräte können zu einem angemessenen Preis verkauft werden (entweder an einem neuen Standort oder durch einen neuen Nutzer am selben Standort)",
		relevanceVar5:	"- Rechtliche Erwägung: Das Eigentum an den Geräten kann übertragen werden.",
		responsibilityVar1:	"[N.O.: Letztendlich wird das Risiko der Vorauszahlung in der Übertragungsvereinbarung zwischen dem Dienstleister/Auftragnehmer und dem Verbriefungsvehikel zu regeln sein.]",
		mitigationVar1:	"[N.O.: Eine 'Umzugskosten'-Klausel in der standardisierten LAUNCH-Vereinbarung mindert diese spezielle Risikoart im Falle einer einseitigen vorzeitigen Kündigung ohne triftigen Grund oder eines Vertragsbruchs].",

	},
	OccupacyRisk: {
		relevanceVar1:	"- Wie werden die Basiswerte für den Energieverbrauch an die veränderte Belegung angepasst?",
		relevanceVar2:	"- Ist eine Mindestvergütung festgelegt, und falls nicht: Ab welchem Prozentsatz des Belegungsrückgangs ist dies (noch) finanziell tragbar?",
		relevanceVar3:	"- Gibt es eine vertragliche Regelung für den Fall, dass die Einrichtung, in der Maßnahmen installiert wurden, stillgelegt wird?",
		responsibilityVar1:	"Der Dienstleister/Auftragnehmer ist für die regelmäßige Anpassung der Energieverbrauchsgrundlagen verantwortlich. Der Kunde ist für eine rechtzeitige und transparente Kommunikation über jede Änderung des Belegungsgrads verantwortlich.",
		responsibilityVar2:	"[N.O.: Bei welchem Belegungsgrad ist der Dienstleister/Auftragnehmer nicht mehr verpflichtet, Dienstleistungen zu erbringen?]",
		mitigationVar1:	"[N.O.: In der standardisierten LAUNCH-Vereinbarung ist nun eine Mindestzahlungshöhe in Prozent des Abonnementwerts festgelegt, die dieses spezifische Risiko abmildert.]",

	},
	ManagementRisk: {
		relevanceVar1:	"Um eine solide Verwaltung der Investitionen und der installierten Ausrüstung zu gewährleisten, sind die folgenden 'Know-Your-Customer'-Daten (KYC) erforderlich:",
		relevanceVar2:	"- Art der Einrichtung",
		relevanceVar3:	"- Tätigkeitsbereich, einschließlich Branchencode",
		relevanceVar4:	"- Anzahl der Mitarbeiter",
		relevanceVar5:	" Struktur der Eigentümerschaft",
		relevanceVar6: " Hintergrund und Erfolgsbilanz des wichtigsten Führungspersonals",
		responsibilityVar1:	"Der Dienstleister/Auftragnehmer ist verpflichtet, die KYC-Informationen vom Abonnenten/Auftraggeber einzuholen und zusammen mit denselben Daten an das Unternehmen des Dienstleisters/Auftragnehmers zu übermitteln.",
		documentationVar1:	"Registrierungsdokument der Handelskammer",
		documentationVar2:	"Dokumentation der Aktionärsstruktur",
		documentationVar3:	"Lebensläufe von Führungskräften in Schlüsselpositionen",

	},
	ChangeInControlRisk: {
		relevanceVar1:	"Ist der Abonnent/Kunde der rechtmäßige Eigentümer des Gebäudes, in dem die Geräte installiert werden? ",
		relevanceVar2:	"Falls das Gebäude gemietet ist, machen Sie bitte Angaben zu Mietvertrag und Laufzeit.",
		responsibilityVar1:	"Die standardisierte LAUNCH-Vereinbarung wird eine Klausel enthalten, die entweder:",
		responsibilityVar2:	"- Der neue Abonnent/Kunde tritt in den bestehenden Dienstleistungsvertrag mit den gleichen Bedingungen ein oder",
		responsibilityVar3:	"- der bestehende/alte Abonnent/Auftraggeber, die Umzugskosten an den gesetzlich zugewiesenen Empfänger/Gegenpartei zu zahlen.",
		new:	"Neu",
		old:	"Alt",
		mitigationVar1:	"Gemäß der standardisierten LAUNCH-Vereinbarung würde diese Risikoart vollständig gemindert.",
		documentationVar1:	"Informationen über den Mietvertrag des Projectstandorts und dessen Laufzeit",

	},
	ConstructionRisk: {
		relevanceVar1:	"- Wurden alle erforderlichen Baugenehmigungen eingeholt, um eine rechtzeitige Ausführung zu ermöglichen?",
		relevanceVar2:	"- Wie lang sind die Vorlaufzeiten für die Ausrüstung, und wird die gesamte Ausrüstung rechtzeitig verfügbar sein?",
		relevanceVar3:	"- Besteht eine erhebliche Beeinträchtigung der derzeitigen Bewohner oder des Betriebs in dem geplanten Gebäude, die eine zeitliche Begrenzung der Bauarbeiten erfordert oder diese auf ein bestimmtes Zeitfenster beschränkt?",
		relevanceVar4:	"- Wenn ja, welche Folgen hat die Nichteinhaltung dieser Beschränkungen oder Einschränkungen?",
		relevanceVar5:	"- Sind Änderungsaufträge zulässig, und wenn ja, in welchem Umfang und bis zu welchem Stadium der Ausführung?",
		responsibilityVar1:	"-Als verantwortliche Partei sollte der Dienstleister/Auftragnehmer zwei wichtige Unterlagen vorlegen:",
		responsibilityVar2:	"- Ein detailliertes Übergabeverfahren, in dem alle Schritte, Eingriffe und Übergaben zwischen den an der Ausführung der Arbeiten beteiligten (Unter-)Vertragspartnern aufgeführt sind.",
		responsibilityVar3:	"- Eine detaillierte Vorlage für einen Inbetriebnahmebericht, in dem alle notwendigen Prüfungen zur Fertigstellung, Qualitätskontrolle, Einhaltung der Vorschriften und Inspektionen, die in der Bauphase zu beachten waren, aufgeführt sind.",
		mitigationVar1:	"Komplexere oder invasivere Maßnahmen, insbesondere in Gebäuden, die für Produktionszwecke, wichtige Dienstleistungen usw. genutzt werden, könnten zusätzliche Maßnahmen zur Abfederung von Verzögerungen erforderlich machen, wie z. B. eine Klausel über pauschalierten Schadenersatz (z. B. für den Fall, dass ein Gebäude oder ein Gebäudeteil über einen längeren Zeitraum nicht genutzt werden kann) oder eine Baurisikoversicherungspolice.",
		documentationVar1:	"Übergabeverfahren",
		documentationVar2:	"Vorlage für den Inbetriebnahmebericht",

	},
	SupplyChainRisk: {
		relevanceVar1:	"Verfügt mindestens eine der Vertragsparteien (Dienstleister/Auftragnehmer, O&M und/oder Technologieanbieter) über eine Lieferkette, die sich an einem oder mehreren anderen Standorten als dem eigenen befindet und von mindestens einer anderen dritten Partei abhängig ist?",
		relevanceVar2: "Wenn ja, wählen Sie diese Standorte aus:",
		responsibilityVar1:	"Der Dienstleister/Auftragnehmer ist für das Management und die Rückverfolgbarkeit seiner Lieferkette verantwortlich, einschließlich derjenigen seiner Zulieferer und (falls zutreffend) ihrer jeweiligen Zulieferer.",
		responsibilityVar2:	"- Kann das Unternehmen nachweisen, dass es einen Mindestanteil an Zulieferungen über Reshoring oder z. B. über sogenannte 'hybride' Lieferketten* bezieht?",
		responsibilityVar3: "Wenn ja, Bericht erstatten:",
		responsibilityVar4:	"- Verfolgt das Unternehmen die Risiken der Lieferkette in seinen Betrieben (z. B. durch Belastbarkeitsindikatoren oder andere Instrumente)? Führt das Unternehmen ein Mapping durch oder verfügt es über eine vollständige Transparenz seiner Lieferkette?",
		responsibilityVar5: "Wenn ja, Bericht erstatten:",
		responsibilityVar6:	"*Re-Shoring - Verlagerung der Produktion oder Herstellung von Waren zurück in das Herkunftsland des Unternehmens, wobei die Lieferkette hauptsächlich im Lieferland angesiedelt ist (z. B. Rossignol-Ski); auch bekannt als 'Inshoring', 'Nearshoring', 'Backshoring'",
		responsibilityVar7:	"Hybride Lieferketten: Lieferketten, die Offshoring und Reshoring kombinieren (z. B. chinesische Elektronik, die teilweise in Südostasien hergestellt wird)",
		mitigationVar1:	"[N.O.: Die Vertragsbedingungen mit dem Dienstleister/Auftragnehmer könnten alle Parteien im Falle von Verspätungen/fehlenden Ausrüstungen/Rückrufen absichern, indem sie z. B. Mindest- und Höchstzahlungen in solchen Fällen und/oder Versicherungs-, Haftpflichtversicherungs- und Haftpflichtanspruchsbedingungen im Falle von Verlusten oder Schäden enthalten.]",
		mitigationVar2:	"Mögliche Abhilfemaßnahmen könnten in Form von Fragen an die verantwortliche(n) Partei(en) überprüft werden, z. B.:",
		mitigationVar3:	"- Über welche Garantien/Versicherungen gegen Lieferengpässe verfügt das Unternehmen? Hat das Unternehmen z. B. eine Versicherung, die das Risiko in der Lieferkette abdeckt, z. B. im Falle von Engpässen durch Übernahme der damit verbundenen Kosten?",
		mitigationVar4: "Wenn ja, Versicherungspolice vorlegen:",
		mitigationVar5:	"- Wie viel Prozent der Lieferkette des Unternehmens befindet sich 'offshore' im Vergleich zu 'inshore'/'re-shore' / 'backshore' / 'nearshore'?",
		mitigationVar6:	"- Sichert das Unternehmen das Risiko der Lieferkette durch Finanzierungsmechanismen oder -instrumente ab (z. B. Versicherungspolice oder andere)?",
		mitigationVar7: "Wenn ja, beschreiben Sie unten:",
		mitigationVar8:	"- Kann das Unternehmen über Maßnahmen zur Minderung des Risikos zusätzlicher Kosten aufgrund unerwarteter Unterbrechungen/Verzögerungen/Entlassungen in der Lieferkette berichten?",
		mitigationVar9: "Wenn ja, Bericht erstatten:",
		mitigationVar10: "-- Kann das Unternehmen über die maximale Anzahl. / % / Umfang der nicht EU-Taxonomie-konformen Produkte/Tätigkeiten in seiner Lieferkette und/oder Maßnahmen zu deren Reduzierung berichten?",
		mitigationVar11: "Wenn ja, Bericht erstatten:",
		documentationVar1:	"Versicherungspolice",
		documentationVar2:	"Jahresbericht, inkl. Auditbericht",

	},
	Contract: {
		pageTitle: "Vertag",
		status: "Status",
		created: "Erstellt",
		customContract: "Vertag hochladen",
		uploadContract: "Benutzereigenen Vertag hochladen",
		serviceProviderName: "Name des Dienstleisters",
		serviceProviderNameText: "Der name des Dienstleisters",
		directorName: "Name des Vertetungsberechtigten",
		directorNameText: "Der name des Vertetungsberechtigten",
		countryOfRegistration: "Land des Firmensitzes",
		countryOfRegistrationText: "Das land des Firmensitzes",
		companyNumber: "Firmennummer",
		companyNumberText: "Die firmennummer",
		registeredOfficeAddress: "Eingetragener Firmensitz-Anschrift",
		registeredOfficeAddressText: "Die Anschrift des eingtragenen Firmensitzes",
		registeredOfficePostcode: "Eingetragener Firmensitz-PLZ",
		registeredOfficePostcodeText: "Die PLZ des eingtragenen Firmensitzes",
		registeredOfficeCity: "Eingetragener Firmensitz-Ort",
		registeredOfficeCityText: "Die Ort des eingtragenen Firmensitzes",
		registeredOutputType: "Der Ausgabetyp",
		currency: "Währung",
		outputType: "Leistungstyp",
		schedule1: "Anhang 1",
		schedule2: "Anhang 2",
		schedule3: "Anhang 3",
		schedule4: "Anhang 4",
		schedule5: "Anhang 5",
		schedule6: "Anhang 6",
		schedule7: "Anhang 7",
		sign: "Unterschrieben",
		save: "Speicherm",
		clear: "Löschen",
		signedDocuments: "Unterschriebene Dokumente",
		contractSigned: "Vertag Unterschrieben",
		schedule1Signed: "Anhang 1 Unterschrieben",
		schedule2Signed: "Anhang 2 Unterschrieben",
		schedule3Signed: "Anhang 3 Unterschrieben",
		schedule4Signed: "Anhang 4 Unterschrieben",
		schedule5Signed: "Anhang 5 Unterschrieben",
		schedule6Signed: "Anhang 6 Unterschrieben",
		schedule7Signed: "Anhang 7 Unterschrieben",
		blankField: "Das Feld darf nicht leer bleiben",
		blankFields: "Diese Felder dürfen nicht leer bleiben",
		downloadTemplatePDF: "(Vorlage als PDF herunterladen)",
		downloadTemplateDOCX: "(Vorlage als DOCX herunterladen)",
		noUploadedFile: "Keine hochgeladene Datei",
		needFile: "Sie müssen eine Datei hochladen",
		areYouSure: "Bist du dir sicher?",
		areYouSureDelete: "Möchten Sie die Datei wirklich löschen?",
		delete: "Löschen",
		cancel: "Abbrechen",
		downloadFile: "Download-Datei",
		signerEmailError: "Sie müssen eine gültige E-Mail-Adresse angeben",
		serviceSubscriberName: "Name des Dienstabonnenten",
		serviceSubscriberEmail: "E-Mail-Adresse des Dienstabonnenten",
		serviceSubscriberDirectorName: "Direktorname des Dienstabonnenten",
		serviceSubscriberCountryOfRegistration: "Registrierungsland des Dienstabonnenten",
		serviceSubscriberCompanyNumber: "Firmennummer des Dienstabonnenten",
		serviceSubscriberRegisteredOfficeAddress: "Eingetragene Büroadresse des Dienstabonnenten",
		serviceSubscriberRegisteredOfficePostcode: "Postleitzahl des registrierten Büros des Dienstabonnenten",
		serviceSubscriberRegisteredOfficeCity: "Eingetragener Sitz des Dienstabonnenten Stadt",
		serviceSubscriberNameText: "Der Name des Dienstabonnenten",
		serviceSubscriberEmailText: "Die E-Mail des Dienstabonnentendirektors",
		serviceSubscriberDirectorNameText: "Der Name des Dienstabonnentendirektors",
		serviceSubscriberCountryOfRegistrationText: "Das Registrierungsland des Dienstabonnenten",
		serviceSubscriberCompanyNumberText: "Die Firmennummer des Dienstabonnenten",
		serviceSubscriberRegisteredOfficeAddressText: "Die registrierte Büroadresse des Dienstabonnenten",
		serviceSubscriberRegisteredOfficePostcodeText: "Die eingetragene Postleitzahl des Serviceabonnenten",
		serviceSubscriberRegisteredOfficeCityText: "Der eingetragene Firmensitz des Dienstabonnenten",
		savedSuccessfully: "Informationen erfolgreich gespeichert",
		professionalIndemnityInsuranceAmount: "Höhe der Berufshaftpflichtversicherung",
		professionalIndemnityInsuranceAmountText: "Die Höhe der Berufshaftpflichtversicherung",
		daysToPaySecurityDeposit: "# Tage für den Abonnenten zur Zahlung der Kaution",
		daysToPaySecurityDepositText: "Die Anzahl der Tage für die Zahlung der Kaution",
		businessHours: "Öffnungszeiten",
		businessHoursTo: "zu",
		interest: "Interesse",
		interestOver: "über",
	},
};
