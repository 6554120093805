import { memo } from "react";
import PropTypes from "prop-types";
import { getIn } from "formik";
import {
	Grid,
	Typography,
	Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import { CustomSwitch } from "../custom/index";

const LabelSwitch = ({
	id,
	label,
	disabled = false,
	formik,
	infoText = "",
}) => {
	const {
		values,
		handleChange,
	} = formik;

	return (

		<Grid item container sx={{ p: 1 }} mt={2} alignItems="center">
			<Grid item xs={12} md={8}>
				<Typography variant="h6" fontWeight="bold" sx={{ color: "blue.dark" }}>
					{label}
					{infoText && (
						<Tooltip placement="bottom-start" title={infoText}>
							<InfoIcon fontSize="small" sx={{ padding: 0.2, color: "green.main" }} />
						</Tooltip>
					)}
				</Typography>
			</Grid>
			<Grid item container xs={12} md={4} justifyContent="flex-end">
				<CustomSwitch
					id={id}
					name={id}
					disabled={disabled}
					checked={getIn(values, id)}
					sx={{ marginLeft: "5px" }}
					onChange={handleChange}
				/>
			</Grid>
		</Grid>
	);
};

LabelSwitch.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	formik: PropTypes.any.isRequired,
	infoText: PropTypes.string,
};

export default memo(LabelSwitch);
