/* eslint-disable camelcase */
const spreadsheet = {
	profit_loss: {
		revenue: {
			editable: true,
			dependsOn: {
				service_revenue: "profit_loss.service_revenue",
				software_sales: "profit_loss.software_sales",
				hardware_sales: "profit_loss.hardware_sales",
			},
			calculate: ([service_revenue, software_sales, hardware_sales]) => service_revenue + software_sales + hardware_sales,
		},
		cost_of_sales: {
			editable: true,
			dependsOn: {
				cost_of_sales_value: "profit_loss.cost_of_sales_value",
			},
			calculate: ([cost_of_sales_value]) => cost_of_sales_value,
		},
		gross_profit: {
			editable: false,
			dependsOn: {
				revenue: "profit_loss.revenue",
				cost_of_sales: "profit_loss.cost_of_sales",
			},
			calculate: ([revenue, cost_of_sales]) => revenue - cost_of_sales,
		},
		other_income: {
			editable: true,
			dependsOn: {
				rent: "profit_loss.rent",
				royalties: "profit_loss.royalties",
			},
			calculate: ([rent, royalties]) => rent + royalties,
		},
		distribution_costs: {
			editable: true,
			dependsOn: {
				delivery_expense: "profit_loss.delivery_expense",
				depreciation_of_delivery_vehicles: "profit_loss.depreciation_of_delivery_vehicles",
				sales_prospecting_market_research: "profit_loss.sales_prospecting_market_research",
				marketing_promotion_expenses: "profit_loss.marketing_promotion_expenses",
				sales_commission: "profit_loss.sales_commission",
			},
			calculate: ([
				delivery_expense,
				depreciation_of_delivery_vehicles,
				sales_prospecting_market_research,
				marketing_promotion_expenses,
				sales_commission,
			]) => (
				delivery_expense
				+ depreciation_of_delivery_vehicles
				+ sales_prospecting_market_research
				+ marketing_promotion_expenses
				+ sales_commission
			),
		},
		administrative_costs: {
			editable: true,
			dependsOn: {
				administrative_salaries: "profit_loss.administrative_salaries",
				insurance: "profit_loss.insurance",
				accounting_legal_fees: "profit_loss.accounting_legal_fees",
				office_supplies: "profit_loss.office_supplies",
				utilities: "profit_loss.utilities",
				licenses_and_permits: "profit_loss.licenses_and_permits",
				office_rental: "profit_loss.office_rental",
				bank_fees: "profit_loss.bank_fees",
			},
			calculate: ([
				administrative_salaries,
				insurance,
				accounting_legal_fees,
				office_supplies,
				utilities,
				licenses_and_permits,
				office_rental,
				bank_fees,
			]) => (
				administrative_salaries
				+ insurance
				+ accounting_legal_fees
				+ office_supplies
				+ utilities
				+ licenses_and_permits
				+ office_rental
				+ bank_fees
			),
		},
		other_expenses: {
			editable: true,
			dependsOn: {
				consulting: "profit_loss.consulting",
				membership_fees: "profit_loss.membership_fees",
				repair_and_maintenance: "profit_loss.repair_and_maintenance",
				vehicle_operating_costs: "profit_loss.vehicle_operating_costs",
				royalty_fees: "profit_loss.royalty_fees",
			},
			calculate: ([
				consulting,
				membership_fees,
				repair_and_maintenance,
				vehicle_operating_costs,
				royalty_fees,
			]) => (
				consulting
				+ membership_fees
				+ repair_and_maintenance
				+ vehicle_operating_costs
				+ royalty_fees
			),
		},
		ebitda: {
			editable: false,
			dependsOn: {
				gross_profit: "profit_loss.gross_profit",
				other_income: "profit_loss.other_income",
				distribution_costs: "profit_loss.distribution_costs",
				administrative_costs: "profit_loss.administrative_costs",
				other_expenses: "profit_loss.other_expenses",
			},
			calculate: ([
				gross_profit,
				other_income,
				distribution_costs,
				administrative_costs,
				other_expenses,
			]) => (
				gross_profit
				+ other_income
				- distribution_costs
				- administrative_costs
				- other_expenses
			),
		},
		financial_income_interest_income: {
			editable: true,
			dependsOn: {
				financial_income_interest_income_value: "profit_loss.financial_income_interest_income_value",
			},
			calculate: ([financial_income_interest_income_value]) => (financial_income_interest_income_value),
		},
		financial_income_other: {
			editable: true,
			dependsOn: {
				interest_deposit: "profit_loss.interest_deposit",
				foreign_exchange_gain: "profit_loss.foreign_exchange_gain",
			},
			calculate: ([interest_deposit, foreign_exchange_gain]) => (interest_deposit + foreign_exchange_gain),
		},
		other_gains_and_losses: {
			editable: true,
			dependsOn: {
				other_gains_and_losses_value: "profit_loss.other_gains_and_losses_value",
			},
			calculate: ([other_gains_and_losses_value]) => (other_gains_and_losses_value),
		},
		finance_costs: {
			editable: true,
			dependsOn: {
				interest_payments: "profit_loss.interest_payments",
				foreign_exchange_loss: "profit_loss.foreign_exchange_loss",
			},
			calculate: ([interest_payments, foreign_exchange_loss]) => (interest_payments + foreign_exchange_loss),
		},
		depreciation: {
			editable: true,
			dependsOn: {
				depreciation_value: "profit_loss.depreciation_value",
			},
			calculate: ([depreciation_value]) => (depreciation_value),
		},
		earnings_before_tax: {
			editable: false,
			dependsOn: {
				ebitda: "profit_loss.ebitda",
				financial_income_interest_income: "profit_loss.financial_income_interest_income",
				financial_income_other: "profit_loss.financial_income_other",
				other_gains_and_losses: "profit_loss.other_gains_and_losses",
				finance_costs: "profit_loss.finance_costs",
				depreciation: "profit_loss.depreciation",
			},
			calculate: ([
				ebitda,
				financial_income_interest_income,
				financial_income_other,
				other_gains_and_losses,
				finance_costs,
				depreciation,
			]) => (
				ebitda
				+ financial_income_interest_income
				+ financial_income_other
				+ other_gains_and_losses
				- finance_costs
				- depreciation
			),
		},
		income_tax: {
			editable: true,
			dependsOn: {
				income_tax_value: "profit_loss.income_tax_value",
			},
			calculate: ([income_tax_value]) => (income_tax_value),
		},
		profit_after_tax: {
			editable: false,
			dependsOn: {
				earnings_before_tax: "profit_loss.earnings_before_tax",
				income_tax: "profit_loss.income_tax",
			},
			calculate: ([earnings_before_tax, income_tax]) => (earnings_before_tax - income_tax),
		},
	},
	financial_position: {
		goodwill: {
			editable: true,
			dependsOn: {
				goodwill_value: "financial_position.goodwill_value",
			},
			calculate: ([goodwill_value]) => (goodwill_value),
		},
		intangible_assets: {
			editable: true,
			dependsOn: {
				computer_software: "financial_position.computer_software",
				patent_copyrights_licenses: "financial_position.patent_copyrights_licenses",
				right_of_use: "financial_position.right_of_use",
				development_activities: "financial_position.development_activities",
			},
			calculate: ([
				computer_software,
				patent_copyrights_licenses,
				right_of_use,
				development_activities,
			]) => (
				computer_software
				+ patent_copyrights_licenses
				+ right_of_use
				+ development_activities
			),
		},
		property_plant_and_equipment: {
			editable: true,
			dependsOn: {
				land_and_buildings: "financial_position.land_and_buildings",
				machinery_production_equipment: "financial_position.machinery_production_equipment",
				vehicles: "financial_position.vehicles",
				office_equipment: "financial_position.office_equipment",
			},
			calculate: ([
				land_and_buildings,
				machinery_production_equipment,
				vehicles,
				office_equipment,
			]) => (
				land_and_buildings
				+ machinery_production_equipment
				+ vehicles
				+ office_equipment
			),
		},
		investment_property: {
			editable: true,
			dependsOn: {
				investment_property_value: "financial_position.investment_property_value",
			},
			calculate: ([investment_property_value]) => (investment_property_value),
		},
		other_tangible_assets: {
			editable: true,
			dependsOn: {
				other_tangible_assets_value: "financial_position.other_tangible_assets_value",
			},
			calculate: ([other_tangible_assets_value]) => (other_tangible_assets_value),
		},
		financial_assets: {
			editable: true,
			dependsOn: {
				investment_in_associates: "financial_position.investment_in_associates",
				interests_in_joint_ventures: "financial_position.interests_in_joint_ventures",
				investment_in_financial_assets: "financial_position.investment_in_financial_assets",
			},
			calculate: ([
				investment_in_associates,
				interests_in_joint_ventures,
				investment_in_financial_assets,
			]) => (
				investment_in_associates
				+ interests_in_joint_ventures
				+ investment_in_financial_assets
			),
		},
		finance_lease_receivables: {
			editable: true,
			dependsOn: {
				finance_lease_receivables_value: "financial_position.finance_lease_receivables_value",
			},
			calculate: ([finance_lease_receivables_value]) => (finance_lease_receivables_value),
		},
		other_non_current_assets: {
			editable: true,
			dependsOn: {
				contract_assets: "financial_position.contract_assets",
				contract_costs: "financial_position.contract_costs",
			},
			calculate: ([
				contract_assets,
				contract_costs,
			]) => (
				contract_assets
				+ contract_costs
			),
		},
		total_non_current_assets: {
			editable: false,
			dependsOn: {
				goodwill: "financial_position.goodwill",
				intangible_assets: "financial_position.intangible_assets",
				property_plant_and_equipment: "financial_position.property_plant_and_equipment",
				investment_property: "financial_position.investment_property",
				other_tangible_assets: "financial_position.other_tangible_assets",
				financial_assets: "financial_position.financial_assets",
				finance_lease_receivables: "financial_position.finance_lease_receivables",
				other_non_current_assets: "financial_position.other_non_current_assets",
			},
			calculate: ([
				goodwill,
				intangible_assets,
				property_plant_and_equipment,
				investment_property,
				other_tangible_assets,
				financial_assets,
				finance_lease_receivables,
				other_non_current_assets,
			]) => (
				goodwill
				+ intangible_assets
				+ property_plant_and_equipment
				+ investment_property
				+ other_tangible_assets
				+ financial_assets
				+ finance_lease_receivables
				+ other_non_current_assets
			),
		},
		inventories: {
			editable: true,
			dependsOn: {
				inventories_value: "financial_position.inventories_value",
			},
			calculate: ([inventories_value]) => (inventories_value),
		},
		investments: {
			editable: true,
			dependsOn: {
				investments_value: "financial_position.investments_value",
			},
			calculate: ([investments_value]) => (investments_value),
		},
		finance_lease_receivables_current: {
			editable: true,
			dependsOn: {
				finance_lease_receivables_current_value: "financial_position.finance_lease_receivables_current_value",
			},
			calculate: ([finance_lease_receivables_current_value]) => (finance_lease_receivables_current_value),
		},
		trade_and_other_receivables: {
			editable: true,
			dependsOn: {
				trade_and_other_receivables_value: "financial_position.trade_and_other_receivables_value",
			},
			calculate: ([trade_and_other_receivables_value]) => (trade_and_other_receivables_value),
		},
		other_current_assets: {
			editable: true,
			dependsOn: {
				differed_expenses: "financial_position.differed_expenses",
				contract_assets_current: "financial_position.contract_assets_current",
				contract_costs_current: "financial_position.contract_costs_current",
				advance_payments: "financial_position.advance_payments",
			},
			calculate: ([
				differed_expenses,
				contract_assets_current,
				contract_costs_current,
				advance_payments,
			]) => (
				differed_expenses
				+ contract_assets_current
				+ contract_costs_current
				+ advance_payments
			),
		},
		assets_classified_as_held_for_sale: {
			editable: true,
			dependsOn: {
				assets_classified_as_held_for_sale_value: "financial_position.assets_classified_as_held_for_sale_value",
			},
			calculate: ([assets_classified_as_held_for_sale_value]) => (assets_classified_as_held_for_sale_value),
		},
		cash_and_bank_balances: {
			editable: true,
			dependsOn: {
				cash_and_bank_balances_value: "financial_position.cash_and_bank_balances_value",
			},
			calculate: ([cash_and_bank_balances_value]) => (cash_and_bank_balances_value),
		},
		total_current_assets: {
			editable: false,
			dependsOn: {
				inventories: "financial_position.inventories",
				investments: "financial_position.investments",
				finance_lease_receivables_current: "financial_position.finance_lease_receivables_current",
				trade_and_other_receivables: "financial_position.trade_and_other_receivables",
				other_current_assets: "financial_position.other_current_assets",
				assets_classified_as_held_for_sale: "financial_position.assets_classified_as_held_for_sale",
				cash_and_bank_balances: "financial_position.cash_and_bank_balances",
			},
			calculate: ([
				inventories,
				investments,
				finance_lease_receivables_current,
				trade_and_other_receivables,
				other_current_assets,
				assets_classified_as_held_for_sale,
				cash_and_bank_balances,
			]) => (
				inventories
				+ investments
				+ finance_lease_receivables_current
				+ trade_and_other_receivables
				+ other_current_assets
				+ assets_classified_as_held_for_sale
				+ cash_and_bank_balances
			),
		},
		total_assets: {
			editable: false,
			dependsOn: {
				total_non_current_assets: "financial_position.total_non_current_assets",
				total_current_assets: "financial_position.total_current_assets",
			},
			calculate: ([
				total_non_current_assets,
				total_current_assets,
			]) => (
				total_non_current_assets
				+ total_current_assets
			),
		},
		issued_share_capital_and_share_premium: {
			editable: true,
			dependsOn: {
				issued_share_capital_and_share_premium_value: "financial_position.issued_share_capital_and_share_premium_value",
			},
			calculate: ([issued_share_capital_and_share_premium_value]) => (issued_share_capital_and_share_premium_value),
		},
		other_reserves: {
			editable: true,
			dependsOn: {
				other_reserves_value: "financial_position.other_reserves_value",
			},
			calculate: ([other_reserves_value]) => (other_reserves_value),
		},
		retained_earnings: {
			editable: true,
			dependsOn: {
				retained_earnings_value: "financial_position.retained_earnings_value",
			},
			calculate: ([retained_earnings_value]) => (retained_earnings_value),
		},
		total_equity: {
			editable: false,
			dependsOn: {
				issued_share_capital_and_share_premium: "financial_position.issued_share_capital_and_share_premium",
				other_reserves: "financial_position.other_reserves",
				retained_earnings: "financial_position.retained_earnings",
			},
			calculate: ([
				issued_share_capital_and_share_premium,
				other_reserves,
				retained_earnings,
			]) => (
				issued_share_capital_and_share_premium
				+ other_reserves
				+ retained_earnings
			),
		},
		borrowings: {
			editable: true,
			dependsOn: {
				borrowings_value: "financial_position.borrowings_value",
			},
			calculate: ([borrowings_value]) => (borrowings_value),
		},
		convertible_loan_notes: {
			editable: true,
			dependsOn: {
				convertible_loan_notes_value: "financial_position.convertible_loan_notes_value",
			},
			calculate: ([convertible_loan_notes_value]) => (convertible_loan_notes_value),
		},
		obligations_under_finance_lease: {
			editable: true,
			dependsOn: {
				obligations_under_finance_lease_value: "financial_position.obligations_under_finance_lease_value",
			},
			calculate: ([obligations_under_finance_lease_value]) => (obligations_under_finance_lease_value),
		},
		long_term_provisions: {
			editable: true,
			dependsOn: {
				long_term_provisions_value: "financial_position.long_term_provisions_value",
			},
			calculate: ([long_term_provisions_value]) => (long_term_provisions_value),
		},
		other_non_current_liabilities: {
			editable: true,
			dependsOn: {
				retirement_benefit_obligations: "financial_position.retirement_benefit_obligations",
				deferred_income_government_grant: "financial_position.deferred_income_government_grant",
				contract_liabilities: "financial_position.contract_liabilities",
				lease_incentives: "financial_position.lease_incentives",
			},
			calculate: ([
				retirement_benefit_obligations,
				deferred_income_government_grant,
				contract_liabilities,
				lease_incentives,
			]) => (
				retirement_benefit_obligations
				+ deferred_income_government_grant
				+ contract_liabilities
				+ lease_incentives
			),
		},
		total_non_current_liabilities: {
			editable: false,
			dependsOn: {
				borrowings: "financial_position.borrowings",
				convertible_loan_notes: "financial_position.convertible_loan_notes",
				obligations_under_finance_lease: "financial_position.obligations_under_finance_lease",
				long_term_provisions: "financial_position.long_term_provisions",
				other_non_current_liabilities: "financial_position.other_non_current_liabilities",
			},
			calculate: ([
				borrowings,
				convertible_loan_notes,
				obligations_under_finance_lease,
				long_term_provisions,
				other_non_current_liabilities,
			]) => (
				borrowings
				+ convertible_loan_notes
				+ obligations_under_finance_lease
				+ long_term_provisions
				+ other_non_current_liabilities
			),
		},
		trade_and_other_payables: {
			editable: true,
			dependsOn: {
				trade_and_other_payables_value: "financial_position.trade_and_other_payables_value",
			},
			calculate: ([trade_and_other_payables_value]) => (trade_and_other_payables_value),
		},
		current_tax_liabilities: {
			editable: true,
			dependsOn: {
				current_tax_liabilities_value: "financial_position.current_tax_liabilities_value",
			},
			calculate: ([current_tax_liabilities_value]) => (current_tax_liabilities_value),
		},
		obligations_under_finance_lease_current: {
			editable: true,
			dependsOn: {
				obligations_under_finance_lease_current_value: "financial_position.obligations_under_finance_lease_current_value",
			},
			calculate: ([obligations_under_finance_lease_current_value]) => (obligations_under_finance_lease_current_value),
		},
		borrowings_current: {
			editable: true,
			dependsOn: {
				borrowings_current_value: "financial_position.borrowings_current_value",
			},
			calculate: ([borrowings_current_value]) => (borrowings_current_value),
		},
		provisions: {
			editable: true,
			dependsOn: {
				provisions_value: "financial_position.provisions_value",
			},
			calculate: ([provisions_value]) => (provisions_value),
		},
		other_financial_liabilities: {
			editable: true,
			dependsOn: {
				short_term_loan_payable: "financial_position.short_term_loan_payable",
				current_portion_of_long_term_debt: "financial_position.current_portion_of_long_term_debt",
				interest_payable: "financial_position.interest_payable",
			},
			calculate: ([
				short_term_loan_payable,
				current_portion_of_long_term_debt,
				interest_payable,
			]) => (
				short_term_loan_payable
				+ current_portion_of_long_term_debt
				+ interest_payable
			),
		},
		other_current_liabilities: {
			editable: true,
			dependsOn: {
				deferred_income_government_grant_current: "financial_position.deferred_income_government_grant_current",
				contract_liabilities_current: "financial_position.contract_liabilities_current",
				customer_prepayments: "financial_position.customer_prepayments",
				refund_liability: "financial_position.refund_liability",
				liabilities_directly_associated: "financial_position.liabilities_directly_associated",
			},
			calculate: ([
				deferred_income_government_grant_current,
				contract_liabilities_current,
				customer_prepayments,
				refund_liability,
				liabilities_directly_associated,
			]) => (
				deferred_income_government_grant_current
				+ contract_liabilities_current
				+ customer_prepayments
				+ refund_liability
				+ liabilities_directly_associated
			),
		},
		total_current_liabilities: {
			editable: false,
			dependsOn: {
				trade_and_other_payables: "financial_position.trade_and_other_payables",
				current_tax_liabilities: "financial_position.current_tax_liabilities",
				obligations_under_finance_lease_current: "financial_position.obligations_under_finance_lease_current",
				borrowings_current: "financial_position.borrowings_current",
				provisions: "financial_position.provisions",
				other_financial_liabilities: "financial_position.other_financial_liabilities",
				other_current_liabilities: "financial_position.other_current_liabilities",
			},
			calculate: ([
				trade_and_other_payables,
				current_tax_liabilities,
				obligations_under_finance_lease_current,
				borrowings_current,
				provisions,
				other_financial_liabilities,
				other_current_liabilities,
			]) => (
				trade_and_other_payables
				+ current_tax_liabilities
				+ obligations_under_finance_lease_current
				+ borrowings_current
				+ provisions
				+ other_financial_liabilities
				+ other_current_liabilities
			),
		},
		total_liabilities: {
			editable: false,
			dependsOn: {
				total_non_current_liabilities: "financial_position.total_non_current_liabilities",
				total_current_liabilities: "financial_position.total_current_liabilities",
			},
			calculate: ([
				total_non_current_liabilities,
				total_current_liabilities,
			]) => (
				total_non_current_liabilities
				+ total_current_liabilities
			),
		},
		total_equity_and_liabilities: {
			editable: false,
			dependsOn: {
				total_equity: "financial_position.total_equity",
				total_liabilities: "financial_position.total_liabilities",
			},
			calculate: ([
				total_equity,
				total_liabilities,
			]) => (
				total_equity
				+ total_liabilities
			),
		},
	},
	cash_flow: {
		depreciation: {
			editable: false,
			dependsOn: {
				depreciation: "profit_loss.depreciation",
			},
			calculate: ([depreciation]) => (depreciation),
		},
		loss_on_sale_of_ppe: {
			editable: true,
			dependsOn: {
				loss_on_sale_of_ppe_value: "cash_flow.loss_on_sale_of_ppe_value",
			},
			calculate: ([loss_on_sale_of_ppe_value]) => (loss_on_sale_of_ppe_value),
		},
		interest_expense: {
			editable: true,
			dependsOn: {
				interest_expense_value: "cash_flow.interest_expense_value",
			},
			calculate: ([interest_expense_value]) => (interest_expense_value),
		},
		cash_from_operations_before_working_capital_changes: {
			editable: false,
			dependsOn: {
				depreciation: "cash_flow.depreciation",
				loss_on_sale_of_ppe: "cash_flow.loss_on_sale_of_ppe",
				interest_expense: "cash_flow.interest_expense",
			},
			calculate: ([
				depreciation,
				loss_on_sale_of_ppe,
				interest_expense,
			]) => (
				depreciation
				+ loss_on_sale_of_ppe
				+ interest_expense
			),
		},
		decrease_in_trade_and_other_receivables: {
			editable: true,
			dependsOn: {
				decrease_in_trade_and_other_receivables_value: "cash_flow.decrease_in_trade_and_other_receivables_value",
			},
			calculate: ([decrease_in_trade_and_other_receivables_value]) => (decrease_in_trade_and_other_receivables_value),
		},
		increase_in_inventories: {
			editable: true,
			dependsOn: {
				increase_in_inventories_value: "cash_flow.increase_in_inventories_value",
			},
			calculate: ([increase_in_inventories_value]) => (increase_in_inventories_value),
		},
		increase_in_payables: {
			editable: true,
			dependsOn: {
				increase_in_payables_value: "cash_flow.increase_in_payables_value",
			},
			calculate: ([increase_in_payables_value]) => (increase_in_payables_value),
		},
		cash_generated_from_operations: {
			editable: false,
			dependsOn: {
				decrease_in_trade_and_other_receivables: "cash_flow.decrease_in_trade_and_other_receivables",
				increase_in_inventories: "cash_flow.increase_in_inventories",
				increase_in_payables: "cash_flow.increase_in_payables",
			},
			calculate: ([
				decrease_in_trade_and_other_receivables,
				increase_in_inventories,
				increase_in_payables,
			]) => (
				decrease_in_trade_and_other_receivables
				+ increase_in_inventories
				+ increase_in_payables
			),
		},
		interest_paid: {
			editable: true,
			dependsOn: {
				interest_paid_value: "cash_flow.interest_paid_value",
			},
			calculate: ([interest_paid_value]) => (interest_paid_value),
		},
		dividend_paid: {
			editable: true,
			dependsOn: {
				dividend_paid_value: "cash_flow.dividend_paid_value",
			},
			calculate: ([dividend_paid_value]) => (dividend_paid_value),
		},
		income_taxes_paid: {
			editable: true,
			dependsOn: {
				income_taxes_paid_value: "cash_flow.income_taxes_paid_value",
			},
			calculate: ([income_taxes_paid_value]) => (income_taxes_paid_value),
		},
		net_cash_from_operating_activities: {
			editable: false,
			dependsOn: {
				cash_from_operations_before_working_capital_changes: "cash_flow.cash_from_operations_before_working_capital_changes",
				cash_generated_from_operations: "cash_flow.cash_generated_from_operations",
				interest_paid: "cash_flow.interest_paid",
				dividend_paid: "cash_flow.dividend_paid",
				income_taxes_paid: "cash_flow.income_taxes_paid",
			},
			calculate: ([
				cash_from_operations_before_working_capital_changes,
				cash_generated_from_operations,
				interest_paid,
				dividend_paid,
				income_taxes_paid,
			]) => (
				cash_from_operations_before_working_capital_changes
				+ cash_generated_from_operations
				+ interest_paid
				+ dividend_paid
				+ income_taxes_paid
			),
		},
		purchase_of_ppe: {
			editable: true,
			dependsOn: {
				purchase_of_ppe_value: "cash_flow.purchase_of_ppe_value",
			},
			calculate: ([purchase_of_ppe_value]) => (purchase_of_ppe_value),
		},
		proceeds_from_sale_of_ppe: {
			editable: true,
			dependsOn: {
				proceeds_from_sale_of_ppe_value: "cash_flow.proceeds_from_sale_of_ppe_value",
			},
			calculate: ([proceeds_from_sale_of_ppe_value]) => (proceeds_from_sale_of_ppe_value),
		},
		net_cash_used_in_investing_activities: {
			editable: false,
			dependsOn: {
				purchase_of_ppe: "cash_flow.purchase_of_ppe",
				proceeds_from_sale_of_ppe: "cash_flow.proceeds_from_sale_of_ppe",
			},
			calculate: ([purchase_of_ppe, proceeds_from_sale_of_ppe]) => (purchase_of_ppe + proceeds_from_sale_of_ppe),
		},
		proceeds_from_issue_of_share_capital: {
			editable: true,
			dependsOn: {
				proceeds_from_issue_of_share_capital_value: "cash_flow.proceeds_from_issue_of_share_capital_value",
			},
			calculate: ([proceeds_from_issue_of_share_capital_value]) => (proceeds_from_issue_of_share_capital_value),
		},
		payment_of_long_term_borrowings: {
			editable: true,
			dependsOn: {
				payment_of_long_term_borrowings_value: "cash_flow.payment_of_long_term_borrowings_value",
			},
			calculate: ([payment_of_long_term_borrowings_value]) => (payment_of_long_term_borrowings_value),
		},
		proceeds_from_finance_lease_liabilities: {
			editable: true,
			dependsOn: {
				proceeds_from_finance_lease_liabilities_value: "cash_flow.proceeds_from_finance_lease_liabilities_value",
			},
			calculate: ([proceeds_from_finance_lease_liabilities_value]) => (proceeds_from_finance_lease_liabilities_value),
		},
		net_cash_from_financing_activities: {
			editable: false,
			dependsOn: {
				proceeds_from_issue_of_share_capital: "cash_flow.proceeds_from_issue_of_share_capital",
				payment_of_long_term_borrowings: "cash_flow.payment_of_long_term_borrowings",
				proceeds_from_finance_lease_liabilities: "cash_flow.proceeds_from_finance_lease_liabilities",
			},
			calculate: ([
				proceeds_from_issue_of_share_capital,
				payment_of_long_term_borrowings,
				proceeds_from_finance_lease_liabilities,
			]) => (
				proceeds_from_issue_of_share_capital
				+ payment_of_long_term_borrowings
				+ proceeds_from_finance_lease_liabilities
			),
		},
		net_increase_in_cash_and_cash_equivalents: {
			editable: false,
			dependsOn: {
				net_cash_from_operating_activities: "cash_flow.net_cash_from_operating_activities",
				net_cash_used_in_investing_activities: "cash_flow.net_cash_used_in_investing_activities",
				net_cash_from_financing_activities: "cash_flow.net_cash_from_financing_activities",
			},
			calculate: ([
				net_cash_from_operating_activities,
				net_cash_used_in_investing_activities,
				net_cash_from_financing_activities,
			]) => (
				net_cash_from_operating_activities
				+ net_cash_used_in_investing_activities
				+ net_cash_from_financing_activities
			),
		},
		cash_and_cash_equivalents_at_the_beginning_of_period: {
			editable: true,
			dependsOn: {
				cash_and_cash_equivalents_at_the_beginning_of_period_value: "cash_flow.cash_and_cash_equivalents_at_the_beginning_of_period_value",
			},
			calculate: ([cash_and_cash_equivalents_at_the_beginning_of_period_value]) => (
				cash_and_cash_equivalents_at_the_beginning_of_period_value
			),
		},
		cash_and_cash_equivalents_at_the_end_of_the_period: {
			editable: false,
			dependsOn: {
				net_increase_in_cash_and_cash_equivalents: "cash_flow.net_increase_in_cash_and_cash_equivalents",
				cash_and_cash_equivalents_at_the_beginning_of_period: "cash_flow.cash_and_cash_equivalents_at_the_beginning_of_period",
			},
			calculate: ([
				net_increase_in_cash_and_cash_equivalents,
				cash_and_cash_equivalents_at_the_beginning_of_period,
			]) => (
				net_increase_in_cash_and_cash_equivalents
				+ cash_and_cash_equivalents_at_the_beginning_of_period
			),
		},
	},
	kpis: {
		gross_profit_margin: {
			editable: false,
			dependsOn: {
				gross_profit: "profit_loss.gross_profit",
				revenue: "profit_loss.revenue",
			},
			calculate: ([gross_profit, revenue]) => (gross_profit / revenue),
			disablePercentage: false,
		},
		other_income_as_a_percentage_of_revenue: {
			editable: false,
			dependsOn: {
				other_income: "profit_loss.other_income",
				revenue: "profit_loss.revenue",
			},
			calculate: ([other_income, revenue]) => (other_income / revenue),
			disablePercentage: false,
		},
		distribution_costs_as_a_percentage_of_revenue: {
			editable: false,
			dependsOn: {
				distribution_costs: "profit_loss.distribution_costs",
				revenue: "profit_loss.revenue",
			},
			calculate: ([distribution_costs, revenue]) => (distribution_costs / revenue),
			disablePercentage: false,
		},
		administrative_costs_as_a_percentage_of_revenue: {
			editable: false,
			dependsOn: {
				administrative_costs: "profit_loss.administrative_costs",
				revenue: "profit_loss.revenue",
			},
			calculate: ([administrative_costs, revenue]) => (administrative_costs / revenue),
			disablePercentage: false,
		},
		other_expenses_as_a_percentage_of_revenue: {
			editable: false,
			dependsOn: {
				other_expenses: "profit_loss.other_expenses",
				revenue: "profit_loss.revenue",
			},
			calculate: ([other_expenses, revenue]) => (other_expenses / revenue),
			disablePercentage: false,
		},
		operation_cash_flow_as_a_percentage_of_revenue: {
			editable: false,
			dependsOn: {
				ebitda: "profit_loss.ebitda",
				revenue: "profit_loss.revenue",
			},
			calculate: ([ebitda, revenue]) => (ebitda / revenue),
			disablePercentage: false,
		},
		ebt_as_a_percentage_of_revenue: {
			editable: false,
			dependsOn: {
				earnings_before_tax: "profit_loss.earnings_before_tax",
				revenue: "profit_loss.revenue",
			},
			calculate: ([earnings_before_tax, revenue]) => (earnings_before_tax / revenue),
			disablePercentage: false,
		},
		profit_after_tax_as_a_percentage_of_revenue: {
			editable: false,
			dependsOn: {
				profit_after_tax: "profit_loss.profit_after_tax",
				revenue: "profit_loss.revenue",
			},
			calculate: ([profit_after_tax, revenue]) => (profit_after_tax / revenue),
			disablePercentage: false,
		},
		current_ratio: {
			editable: false,
			dependsOn: {
				total_current_assets: "financial_position.total_current_assets",
				total_current_liabilities: "financial_position.total_current_liabilities",
			},
			calculate: ([total_current_assets, total_current_liabilities]) => (total_current_assets / total_current_liabilities),
			disablePercentage: false,
		},
		acid_test_ratio: {
			editable: false,
			dependsOn: {
				total_current_assets: "financial_position.total_current_assets",
				inventories: "financial_position.inventories",
				total_current_liabilities: "financial_position.total_current_liabilities",
			},
			calculate: ([
				total_current_assets,
				inventories,
				total_current_liabilities,
			]) => ((total_current_assets - inventories) / total_current_liabilities),
			disablePercentage: false,
		},
		working_capital: {
			editable: false,
			dependsOn: {
				total_current_assets: "financial_position.total_current_assets",
				total_current_liabilities: "financial_position.total_current_liabilities",
			},
			calculate: ([total_current_assets, total_current_liabilities]) => (total_current_assets - total_current_liabilities),
			disablePercentage: true,
		},
		debt_to_equity_ratio: {
			editable: false,
			dependsOn: {
				total_liabilities: "financial_position.total_liabilities",
				total_equity: "financial_position.total_equity",
			},
			calculate: ([total_liabilities, total_equity]) => (total_liabilities / total_equity),
			disablePercentage: false,
		},
	},
	output: {
		revenue_output: {
			edidable: false,
			dependsOn: {
				revenue: "profit_loss.revenue",
			},
			calculate: ([revenue]) => revenue,
			disableSum: false,
		},
		gross_profit_output: {
			edidable: false,
			dependsOn: {
				gross_profit: "profit_loss.gross_profit",
			},
			calculate: ([gross_profit]) => gross_profit,
			disableSum: false,
		},
		ebitda_output: {
			edidable: false,
			dependsOn: {
				ebitda: "profit_loss.ebitda",
			},
			calculate: ([ebitda]) => ebitda,
			disableSum: false,
		},
		profit_after_tax_output: {
			edidable: false,
			dependsOn: {
				profit_after_tax: "profit_loss.profit_after_tax",
			},
			calculate: ([profit_after_tax]) => profit_after_tax,
			disableSum: false,
		},
		total_assets_output: {
			edidable: false,
			dependsOn: {
				total_assets: "financial_position.total_assets",
			},
			calculate: ([total_assets]) => total_assets,
			disableSum: false,
		},
		total_liabilities_output: {
			edidable: false,
			dependsOn: {
				total_liabilities: "financial_position.total_liabilities",
			},
			calculate: ([total_liabilities]) => total_liabilities,
			disableSum: false,
		},

		cashflow_output: {
			edidable: false,
			dependsOn: {
				net_cash_from_operating_activities: "cash_flow.net_cash_from_operating_activities",
				net_cash_used_in_investing_activities: "cash_flow.net_cash_used_in_investing_activities",
				net_cash_from_financing_activities: "cash_flow.net_cash_from_financing_activities",
			},
			calculate: ([
				net_cash_from_operating_activities,
				net_cash_used_in_investing_activities,
				net_cash_from_financing_activities,
			]) => (
				net_cash_from_operating_activities
				+ net_cash_used_in_investing_activities
				+ net_cash_from_financing_activities
			),
			disableSum: false,
		},
	},
};

export default spreadsheet;
