const httpResponseIsValid = (response) => {
	if (response?.http_status >= 200 && response?.http_status < 300) {
		return true;
	}

	if (response?.http_status >= 300 && response?.http_status < 500) {
		return false;
	}

	throw (response.message ? response.message : "");
};

export default httpResponseIsValid;
