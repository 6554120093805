/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	Grid,
	LinearProgress,
	Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { httpResponseIsValid, useSnackbar } from "../../utils";
import usePrompt from "../../utils/use-blocker";
import {
	MainMenu,
	LabelTextField,
	LabelDropdown,
	LabelRadioTextField,
	LabelFileUpload,
	LabelRadioFileUpload,
} from "../../components/basic/index";
import {
	getProjectEsgSocial,
	updateProjectEsgSocial,
} from "../../api";

const ESGSocial = ({ disableAccess = true, isProjectDeveloper = false, onChangeProjectState }) => {
	const { projectid } = useParams();

	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(disableAccess);
	const [isLoading, setIsLoading] = useState(false);

	const [user, setUser] = useState({
		company_id: "",
	});
	const [options, setOptions] = useState({
		esgSocialOptions1: {},
	});
	const [project, setProject] = useState({
		name: "",
		status: "-",
		createdAt: "-",
		data: {
			performance_radio_1: "false",
			performance_radio_2: "false",
			performance_radio_3: "false",
			performance_radio_4: "false",
			performance_radio_5: "false",
			performance_radio_6: "false",
			performance_txt_1: "",
			performance_txt_2: "",
			actions_radio_1: "false",
			actions_radio_2: "false",
			actions_radio_3: "false",
			actions_radio_4: "false",
			actions_radio_5: "false",
			actions_radio_6: "false",
			actions_drop_1: "",
			kpis_txt_1: "",
			kpis_txt_2: "",
			kpis_txt_3: "",
			kpis_txt_4: "",
		},
		docs: {
			social_performance_doc_1: {
				original_name: "",
				save_name: "",
			},
			social_performance_doc_2: {
				original_name: "",
				save_name: "",
			},
			social_performance_doc_3: {
				original_name: "",
				save_name: "",
			},
			social_performance_doc_4: {
				original_name: "",
				save_name: "",
			},
			social_actions_doc_1: {
				original_name: "",
				save_name: "",
			},
			social_actions_doc_2: {
				original_name: "",
				save_name: "",
			},
			social_actions_doc_3: {
				original_name: "",
				save_name: "",
			},
			social_actions_doc_4: {
				original_name: "",
				save_name: "",
			},
			social_actions_doc_5: {
				original_name: "",
				save_name: "",
			},
			social_actions_doc_6: {
				original_name: "",
				save_name: "",
			},
			social_issues_doc_1: {
				original_name: "",
				save_name: "",
			},
			social_issues_doc_2: {
				original_name: "",
				save_name: "",
			},
			social_issues_doc_3: {
				original_name: "",
				save_name: "",
			},
			social_issues_doc_4: {
				original_name: "",
				save_name: "",
			},
			social_issues_doc_5: {
				original_name: "",
				save_name: "",
			},
			social_issues_doc_6: {
				original_name: "",
				save_name: "",
			},
		},
	});

	const [enableEditing, setEnableEditing] = useState(false);
	const { success, error } = useSnackbar();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getProjectEsgSocial(projectid);
				if (httpResponseIsValid(response)) {
					setOptions(response.options);
					setProject(response.project);
					setUser(response.user);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				console.log(`Error occured when fetching esg's social data: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, projectid],
	);

	const handleSubmit = async (values) => {
		setIsLoading(true);

		try {
			const response = await updateProjectEsgSocial(projectid, values);
			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			console.log(`Error occured when updating esg's social data: ${_error}`);
		}

		setIsLoading(false);
	};

	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: project,
		onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	});

	const { dirty } = formik;

	usePrompt("You have unsaved changes which will be lost. Are you sure you want to leave the page?", dirty);

	useEffect(() => {
		setShouldUiBeDisabled(disableAccess || !enableEditing);
	}, [disableAccess, enableEditing]);

	useEffect(() => {
		formik.resetForm({ values: project, dirty: false });
	}, [project]);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData, projectid]);

	return (
		<form onSubmit={formik.handleSubmit}>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<MainMenu
				title={t("ESG.Social.title")}
				dirty={dirty}
				project={project}
				isDeveloper={isProjectDeveloper}
				disabled={disableAccess}
				onFetch={fetchData}
				onEdit={setEnableEditing}
				onChange={onChangeProjectState}
			/>

			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "105px" }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="blue.dark">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("ESG.Social.Management.title")}
					</Typography>
				</Grid>

				<LabelFileUpload
					viewOnly
					fieldId="docs.social_issues_doc_1"
					projectId={user.company_id}
					label={t("ESG.Social.Management.docIssues1")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="documents"
					onSuccess={() => fetchData()}
				/>

				<LabelFileUpload
					viewOnly
					fieldId="docs.social_issues_doc_2"
					projectId={user.company_id}
					label={t("ESG.Social.Management.docIssues2")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="documents"
					onSuccess={() => fetchData()}
				/>

				<LabelFileUpload
					viewOnly
					fieldId="docs.social_issues_doc_3"
					projectId={user.company_id}
					label={t("ESG.Social.Management.docIssues3")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="documents"
					onSuccess={() => fetchData()}
				/>

				<LabelFileUpload
					viewOnly
					fieldId="docs.social_issues_doc_4"
					projectId={user.company_id}
					label={t("ESG.Social.Management.docIssues4")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="documents"
					onSuccess={() => fetchData()}
				/>

				<LabelFileUpload
					viewOnly
					fieldId="docs.social_issues_doc_5"
					projectId={user.company_id}
					label={t("ESG.Social.Management.docIssues5")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="documents"
					onSuccess={() => fetchData()}
				/>

				<LabelFileUpload
					viewOnly
					fieldId="docs.social_issues_doc_6"
					projectId={user.company_id}
					label={t("ESG.Social.Management.docIssues6")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="documents"
					onSuccess={() => fetchData()}
				/>

				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="blue.dark">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("ESG.Social.Performance.title")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center">
					<LabelRadioFileUpload
						radioId="data.performance_radio_1"
						fieldId="docs.social_performance_doc_1"
						projectId={projectid}
						label={t("ESG.Social.Performance.doc1")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="esg"
						onSuccess={() => fetchData()}
					/>

					<LabelRadioTextField
						radioId="data.performance_radio_2"
						fieldId="data.performance_txt_1"
						label={t("ESG.Social.Performance.text1")}
						placeholder="Answer"
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>

					<LabelRadioFileUpload
						radioId="data.performance_radio_3"
						fieldId="docs.social_performance_doc_2"
						projectId={projectid}
						label={t("ESG.Social.Performance.doc2")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="esg"
						onSuccess={() => fetchData()}
					/>

					<LabelRadioTextField
						radioId="data.performance_radio_4"
						fieldId="data.performance_txt_2"
						label={t("ESG.Social.Performance.text2")}
						placeholder="Answer"
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>

					<LabelRadioFileUpload
						radioId="data.performance_radio_5"
						fieldId="docs.social_performance_doc_3"
						projectId={projectid}
						label={t("ESG.Social.Performance.doc3")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="esg"
						onSuccess={() => fetchData()}
					/>

					<LabelRadioFileUpload
						radioId="data.performance_radio_6"
						fieldId="docs.social_performance_doc_4"
						projectId={projectid}
						label={t("ESG.Social.Performance.doc4")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="esg"
						onSuccess={() => fetchData()}
					/>
				</Grid>

				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="blue.dark">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("ESG.Social.Actions.title")}
					</Typography>
				</Grid>

				<LabelFileUpload
					viewOnly
					fieldId="docs.social_actions_doc_1"
					projectId={user.company_id}
					label={t("ESG.Social.Actions.doc1")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="documents"
					onSuccess={() => fetchData()}
				/>

				<LabelFileUpload
					viewOnly
					fieldId="docs.social_actions_doc_2"
					projectId={user.company_id}
					label={t("ESG.Social.Actions.doc2")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="documents"
					onSuccess={() => fetchData()}
				/>

				<LabelFileUpload
					viewOnly
					fieldId="docs.social_actions_doc_3"
					projectId={user.company_id}
					label={t("ESG.Social.Actions.doc3")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="documents"
					onSuccess={() => fetchData()}
				/>

				<LabelFileUpload
					viewOnly
					fieldId="docs.social_actions_doc_4"
					projectId={user.company_id}
					label={t("ESG.Social.Actions.doc4")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="documents"
					onSuccess={() => fetchData()}
				/>

				<LabelFileUpload
					viewOnly
					fieldId="docs.social_actions_doc_5"
					projectId={user.company_id}
					label={t("ESG.Social.Actions.doc5")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="documents"
					onSuccess={() => fetchData()}
				/>

				<LabelDropdown
					id="data.actions_drop_1"
					size="large"
					label={t("ESG.Social.Actions.drop1")}
					options={options.esgSocialOptions1}
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>

				<LabelFileUpload
					viewOnly
					fieldId="docs.social_actions_doc_6"
					projectId={user.company_id}
					label={t("ESG.Social.Actions.doc6")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="documents"
					infoText={t("ESG.Social.Actions.info1")}
					onSuccess={() => fetchData()}
				/>

				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("ESG.Social.KPIS.title")}
					</Typography>
				</Grid>

				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center">
					<LabelTextField
						id="data.kpis_txt_1"
						label={t("ESG.Social.KPIS.text1")}
						size="large"
						placeholder="Answer"
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>

					<LabelTextField
						id="data.kpis_txt_2"
						label={t("ESG.Social.KPIS.text2")}
						size="large"
						placeholder="Answer"
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>

					<LabelTextField
						id="data.kpis_txt_3"
						label={t("ESG.Social.KPIS.text3")}
						size="large"
						placeholder="Answer"
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>

					<LabelTextField
						id="data.kpis_txt_4"
						label={t("ESG.Social.KPIS.text4")}
						size="large"
						placeholder="Answer"
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>
				</Grid>
			</Grid>
		</form>
	);
};

export default memo(ESGSocial);
