/* eslint-disable unicorn/consistent-destructuring */
import { useEffect, memo, useState } from "react";
import {
	Grid,
	Box,
	Typography,
	ToggleButtonGroup,
	ToggleButton,
} from "@mui/material";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { Image } from "mui-image";
import { decode } from "js-base64";
import * as Yup from "yup";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";

import {
	LoginInput,
	LoginInputStyles,
	LoginPassword,
	LoginPasswordStyles,
	LoginButton,
	LoginDivider,
	LoginLinkText,
} from "../components/login/index";
import { httpResponseIsValid, useSnackbar } from "../utils";
import Spinner from "../components/Spinner";
import logo from "../assets/images/logo_white.png";
import background from "../assets/images/background.jpg";
import { authGoogle, signInEmail } from "../api";

const { REACT_APP_GOOGLE_CLIENT_ID } = process.env;

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(0, -1),
		overflow: "hidden",
		width: "100vw",
		height: "100vh",
		backgroundImage: `url(${background})`,
		backgroundPosition: "center",
		backgroundSize: "cover",
	},
	title: {
		color: theme.palette.common.white,
		letterSpacing: theme.spacing(0.6),
	},
	subtitle: {
		color: theme.palette.green.main,
		letterSpacing: theme.spacing(0.2),
	},
	toggles: {
		"& .Mui-selected": {
			color: "#1d4363 !important",
			backgroundColor: "#ffffff !important",
			border: "2px solid #7DA1D1",
		},
		"& .MuiToggleButton-root": {
			height: "35px",
			"&:hover": {
				color: "#ffffff",
				backgroundColor: "#9CBAE1",
			},
			color: "#ffffff",
			backgroundColor: "#7DA1D1",
			borderRadius: "8px",
		},
	},
	loginInputIcons: LoginInputStyles.iconStyle,
	loginPasswordIcons: LoginPasswordStyles.iconStyle,
}));

const SignInSchema = Yup.object().shape({
	email: Yup.string().trim().email().required("E-mail is required"),
	password: Yup.string().min(8).required("Password is required"),
});

const SignIn = () => {
	const { state } = useLocation();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const classes = useStyles();
	const { error } = useSnackbar();
	const navigate = useNavigate();
	const [signInWithGoogle, setSignInWithGoogle] = useState("email");
	const [showPassword, setShowPassword] = useState(false);
	const [urlParams, setUrlParams] = useState({ token: null, email: null });
	const [invitation, setInvitation] = useState("");

	const handleSubmit = async (values) => {
		try {
			setIsSubmitting(true);

			const response = await signInEmail({
				...values,
				invite_token: urlParams.token,
			});

			setIsSubmitting(false);

			if (httpResponseIsValid(response)) {
				navigate(`/auth/?token=${response.token}`);
			} else {
				error(response.message);
				throw new Error(response.message);
			}
		} catch (_error) {
			setIsSubmitting(false);
			console.log(`Error while trying to Sign Up: ${_error}`);
		}
	};

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validationSchema: SignInSchema,
		onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	});

	const { errors, touched } = formik;

	const handleGoogleSuccess = async (e) => {
		try {
			setIsSubmitting(true);

			const response = await authGoogle({ credential: e?.credential, invite_token: urlParams.token });

			setIsSubmitting(false);

			if (httpResponseIsValid(response)) {
				navigate(`/auth/?token=${response.token}`);
			} else {
				error(response.message);
			}
		} catch (_error) {
			console.log(`Error occured while signing in via google: ${_error}`);
			setIsSubmitting(false);
		}
	};

	const changeSignInMethod = (method) => {
		if (method !== signInWithGoogle) {
			setSignInWithGoogle(method);
		}

		if (method === "google") {
			try {
				window.google.accounts.id.initialize({
					client_id: REACT_APP_GOOGLE_CLIENT_ID,
					callback: handleGoogleSuccess,
				});
				window.google.accounts.id.renderButton(document.querySelector("#googleLoginButton"), {
					theme: "outline",
					size: "large",
				});
				window.google.accounts.id.prompt();
			} catch {
				/** */
			}
		}
	};

	useEffect(() => {
		(async () => {
			try {
				const token = window.location.pathname.split("/")[1];
				const params = new URLSearchParams(window.location.search);
				const email = decode(params.get("email"));
				const invite = params.get("invitation");

				if (invite) {
					setInvitation(invite);
				}

				const validationSchema = Yup.object().shape({
					email: Yup.string().trim().email().required(),
				});

				const emailValid = await validationSchema.isValid({ email });

				if (emailValid) {
					formik.setFieldValue("email", email);
				}

				setUrlParams({ token, email });
			} catch { /* empty */ }
		})();
	}, [error, formik]);

	useEffect(() => {
		try {
			if (invitation) {
				sessionStorage.setItem("redirectTo", JSON.stringify(state?.from || { pathname: "/invitations" }));
			} else if (state?.from.pathname) {
				sessionStorage.setItem("redirectTo", JSON.stringify(state?.from || { pathname: `/${state?.from.pathname}` }));
			} else {
				sessionStorage.setItem("redirectTo", JSON.stringify(state?.from || { pathname: "/projects" }));
			}
		} catch {
			/** */
		}
	}, [state, invitation]);

	return (
		<form onSubmit={formik.handleSubmit}>
			<Spinner open={isSubmitting} />
			<Grid container direction="row" justifyContent="center" align="center" className={classes.root}>
				<Grid
					item
					container
					direction="column"
					justifyContent="center"
					align="center"
					sm={5}
					xs={12}
					sx={{ "> .MuiGrid-item": { p: 1 } }}
				>
					<Grid item container alignItems="center" justifyContent="center">
						<Box sx={{ minWidth: "250px" }}>
							<Image src={logo} alt="Sefa" />
						</Box>
					</Grid>
					<Grid item mt={2} mb={1}>
						<Typography variant="h3" className={classes.title}>
							{"WELCOME"}
						</Typography>
						<Typography variant="h5" className={classes.subtitle}>
							{"Get started with Sefa"}
						</Typography>
					</Grid>
					<Grid item container justifyContent="center">
						<ToggleButtonGroup
							exclusive
							color="primary"
							value={signInWithGoogle}
							aria-label="Platform"
							className={classes.toggles}
							onChange={(e) => changeSignInMethod(e.target.value)}
						>
							<ToggleButton
								value="email"
								sx={{ width: "160px", fontWeight: "bold", fontSize: "13px", letterSpacing: "1px" }}
							>
								{"EMAIL"}
							</ToggleButton>
							<ToggleButton
								value="google"
								sx={{ width: "160px", fontWeight: "bold", fontSize: "13px", letterSpacing: "1px" }}
							>
								{"GOOGLE"}
							</ToggleButton>
						</ToggleButtonGroup>
					</Grid>
					{signInWithGoogle === "email" && (
						<Grid item container direction="column" justifyContent="center" alignItems="center" mt={1}>
							<Grid item mb={errors.email && touched.email ? 3 : 1}>
								<LoginInput
									id="email"
									icon={<EmailIcon className={classes.loginInputIcons} />}
									name="First Name"
									value={formik.values.email}
									disabled={urlParams.email}
									error={errors.email && touched.email ? errors.email : ""}
									onChange={formik.handleChange}
								/>
							</Grid>
							<Grid item mb={errors.password && touched.password ? 3 : 1}>
								<LoginPassword
									id="password"
									icon={<LockIcon className={classes.loginPasswordIcons} />}
									name="Password"
									value={formik.values.password}
									type={showPassword ? "text" : "password"}
									disabled={urlParams.password}
									error={errors.password && touched.password ? errors.password : ""}
									onSetVisible={() => setShowPassword(!showPassword)}
									onChange={formik.handleChange}
								/>
							</Grid>
							<Grid item mt={2}>
								<LoginButton
									title="Sign In"
									type="submit"
								/>
							</Grid>
						</Grid>
					)}
					<Grid item>
						<div
							id="googleLoginButton"
							style={{ width: "200px", display: signInWithGoogle === "google" ? "block" : "none" }}
						/>
					</Grid>
					<Grid item container direction="column" justifyContent="center" alignItems="space-between">
						<Grid item>
							<Typography variant="h7" color="white">{"Forgot Password? "}</Typography>
							<LoginLinkText text="Click Here" link="/forgot-password" />
						</Grid>
						<Grid item>
							<LoginDivider />
						</Grid>
						<Grid item>
							<Typography variant="h7" color="white">{"Don't have an account? "}</Typography>
							<LoginLinkText text="Sign Up Here" link="/sign-up" />
						</Grid>
					</Grid>
				</Grid>
				<Grid item sm={7} />
			</Grid>
		</form>

	);
};

export default memo(SignIn);
