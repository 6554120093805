/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable unicorn/consistent-destructuring */
import { useEffect, memo, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { decode } from "js-base64";
import { useFormik } from "formik";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LockIcon from "@mui/icons-material/Lock";
import * as Yup from "yup";

import {
	LoginInput,
	LoginInputStyles,
	LoginPassword,
	LoginPasswordStyles,
	LoginButton,
	LoginDivider,
	LoginLinkText,
	LoginRadio,
} from "../components/login/index";
import { useSnackbar, httpResponseIsValid, userRoles } from "../utils";
import Spinner from "../components/Spinner";
import background from "../assets/images/background.jpg";
import { signUpUser } from "../api";
import sefaTheme from "../utils/theme";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(0, -1),
		overflow: "hidden",
		width: "100vw",
		height: "100vh",
		backgroundImage: `url(${background})`,
		backgroundPosition: "center",
		backgroundSize: "cover",
	},
	title: {
		color: theme.palette.common.white,
		letterSpacing: theme.spacing(0.1),
	},

	loginInputIcons: LoginInputStyles.iconStyle,
	loginPasswordIcons: LoginPasswordStyles.iconStyle,
}));

const SignUpSchema = Yup.object().shape({
	first_name: Yup.string().trim().required("First name required"),
	last_name: Yup.string().trim().required("Last name is required"),
	phone: Yup.string().trim().required("Phone is required"),
	email: Yup.string().trim().email().required("E-mail is required"),
	password: Yup.string().min(8).required("Password is required"),
	confirm_password: Yup.string().test("passwords-match", "Passwords must match", function (value) {
		return this.parent.password === value;
	}),
	role: Yup.string().default(userRoles.CONTRACTOR_OWNER),
});

const SignUp = () => {
	const classes = useStyles();
	const navigate = useNavigate();

	const { state } = useLocation();
	const { error } = useSnackbar();

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [urlParams, setUrlParams] = useState({ token: null, email: null });
	const [invitation, setInvitation] = useState(null);
	const [showPassword, setShowPassword] = useState(false);

	const handleSubmit = async (values) => {
		try {
			setIsSubmitting(true);

			const res = await signUpUser({
				token: urlParams.token,
				name: `${values.first_name} ${values.last_name}`,
				phone: values.phone,
				email: values.email,
				password: values.password,
				role: values.role,
				active_countries: "",
				technologies: "",
				minimum_project_size: "",
			});

			setIsSubmitting(false);

			if (httpResponseIsValid(res)) {
				navigate(`/auth/?token=${res.token}`);
			} else {
				throw new Error(res.message);
			}
		} catch (_error) {
			setIsSubmitting(false);
			error("Someting went wrong while Signing Up.");

			console.log(`Error while trying to Sign Up: ${_error}`);
		}
	};

	const formik = useFormik({
		initialValues: {
			first_name: "",
			last_name: "",
			phone: "",
			email: "",
			password: "",
			confirm_password: "",
			role: userRoles.CONTRACTOR_OWNER,
		},
		validationSchema: SignUpSchema,
		onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	});

	const { errors, touched } = formik;

	const parseInvitation = async () => {
		try {
			// Parse Invitation token
			const urlComponents = window.location.pathname.split("/");
			const token = (urlComponents.length >= 2) ? urlComponents[2] : undefined;

			// Parse Invitation params
			const params = new URLSearchParams(window.location.search);
			const encodedCEmail = params.get("email");
			const invite = params.get("invitation");

			if (encodedCEmail !== null && token !== undefined && invite !== null) {
				const decodeEmail = decode(encodedCEmail);

				const validationSchema = Yup.object().shape({
					email: Yup.string().trim().email().required(),
				});

				const emailValid = await validationSchema.isValid({ email: decodeEmail });

				if (emailValid) {
					formik.setFieldValue("email", decodeEmail);
				}

				setUrlParams({ token, email: decodeEmail });
				setInvitation(invite);
			}
		} catch (_error) {
			console.log(`Error occured while trying to parse invitation token: ${_error}`);
		}
	};

	useEffect(() => {
		parseInvitation();
	}, [error]);

	useEffect(() => {
		try {
			if (invitation === "projectDeveloper" || invitation === "company") {
				sessionStorage.setItem("redirectTo", JSON.stringify(state?.from || { pathname: "/invitations" }));
			} else if (state?.from.pathname) {
				sessionStorage.setItem("redirectTo", JSON.stringify(state?.from || { pathname: `/${state?.from.pathname}` }));
			} else {
				sessionStorage.setItem("redirectTo", JSON.stringify(state?.from || { pathname: "/projects" }));
			}
		} catch {
			/** */
		}
	}, [state, invitation]);

	return (
		<form onSubmit={formik.handleSubmit}>
			<Spinner open={isSubmitting} />
			<Grid container direction="row" justifyContent="center" align="center" className={classes.root}>
				<Grid
					item
					container
					direction="column"
					justifyContent="center"
					align="center"
					sm={5}
					xs={12}
					sx={{ "> .MuiGrid-item": { p: 1 } }}
				>
					<Grid item container alignItems="center" justifyContent="center">
						<Grid item mt={2}>
							<Typography variant="h4" sx={sefaTheme.typography.title}>
								{"Request Account"}
							</Typography>
						</Grid>
					</Grid>
					<Grid item container direction="column" justifyContent="center" alignItems="center">
						{(invitation === null)
							&& (
								<LoginRadio
									id="role"
									name="Account Type:"
									value={formik.values.role}
									options={[{ value: "owner", label: "Contractor" }, { value: "investor_owner", label: "Investor" }]}
									setFieldValue={formik.setFieldValue}
								/>
							)}
						<Grid item mb={errors.first_name && touched.first_name ? 3 : 1}>
							<LoginInput
								id="first_name"
								icon={<PersonIcon className={classes.loginInputIcons} />}
								name="First Name"
								value={formik.values.first_name}
								disabled={urlParams.first_name}
								error={errors.first_name && touched.first_name ? errors.first_name : ""}
								onChange={formik.handleChange}
							/>
						</Grid>
						<Grid item mb={errors.last_name && touched.last_name ? 3 : 1}>
							<LoginInput
								id="last_name"
								icon={<PersonIcon className={classes.loginInputIcons} />}
								name="Last Name"
								value={formik.values.last_name}
								disabled={urlParams.last_name}
								error={errors.last_name && touched.last_name ? errors.last_name : ""}
								onChange={formik.handleChange}
							/>
						</Grid>
						<Grid item mb={errors.email && touched.email ? 3 : 1}>
							<LoginInput
								id="email"
								icon={<EmailIcon className={classes.loginInputIcons} />}
								name="Email Address"
								value={formik.values.email}
								disabled={urlParams.email}
								error={errors.email && touched.email ? errors.email : ""}
								onChange={formik.handleChange}
							/>
						</Grid>
						<Grid item mb={errors.phone && touched.phone ? 3 : 1}>
							<LoginInput
								id="phone"
								icon={<LocalPhoneIcon className={classes.loginInputIcons} />}
								name="Phone Number"
								value={formik.values.phone}
								disabled={urlParams.phone}
								error={errors.phone && touched.phone ? errors.phone : ""}
								onChange={formik.handleChange}
							/>
						</Grid>
						<Grid item mb={errors.password && touched.password ? 3 : 1}>
							<LoginPassword
								id="password"
								icon={<LockIcon className={classes.loginPasswordIcons} />}
								name="Password"
								value={formik.values.password}
								type={showPassword ? "text" : "password"}
								disabled={urlParams.password}
								error={errors.password && touched.password ? errors.password : ""}
								onSetVisible={() => setShowPassword(!showPassword)}
								onChange={formik.handleChange}
							/>
						</Grid>
						<Grid item mb={errors.confirm_password && touched.confirm_password ? 3 : 1}>
							<LoginPassword
								id="confirm_password"
								icon={<LockIcon className={classes.loginPasswordIcons} />}
								name="Confirm Password"
								value={formik.values.confirm_password}
								type={showPassword ? "text" : "password"}
								disabled={urlParams.confirm_password}
								error={errors.confirm_password && touched.confirm_password ? errors.confirm_password : ""}
								onSetVisible={() => setShowPassword(!showPassword)}
								onChange={formik.handleChange}
							/>
						</Grid>
						<Grid item mt={2}>
							<LoginButton
								title="Sign Up"
								type="submit"
							/>
						</Grid>
					</Grid>
					<Grid item container direction="column" justifyContent="center" alignItems="space-between">
						<Grid item>
							<Typography variant="h7" color="white">{"This is how "}</Typography>
							<LoginLinkText text="we use your data" link="/sign-up" />
						</Grid>
						<Grid item>
							<LoginDivider />
						</Grid>
						<Grid item>
							<Typography variant="h7" color="white">{"Already have an account? "}</Typography>
							<LoginLinkText text="Sign In Here" link="/" />
						</Grid>
					</Grid>
				</Grid>
				<Grid item sm={7} />
			</Grid>
		</form>
	);
};

export default memo(SignUp);
