export const userRoles = {
	CONTRACTOR_MEMBER: "member",
	CONTRACTOR_OWNER: "owner",
	INVESTOR_MEMBER: "investor_member",
	INVESTOR_OWNER: "investor_owner",
	ADMIN: "admin",
};

export const projectTaxonomies = {
	selectTechnology: "Select Technology",
	buildingFabric: "Building Fabric Measure",
	electricPowerSystem: "Electric Power System Measure",
	hvac: "HVAC And Hot Water Measure",
	lighting: "Lighting Measure",
	management: "Management Measure",
	refrigeration: "Refrigeration Systems Measure",
	renewable: "Renewable Generation Measure",
	heating: "Heating And Cooling Control Measure",
	other: "Other",
};

export const projectSectors = {
	selectSector: "Select Sector",
	residential: "Residential",
	tertiary: "Tertiary",
	agriculture: "Agriculture",
	industry: "Industry",
	powerGrid: "Power Grid",
	manufacturing: "Manufacturing",
};

export const projectSubsidies = [
	{ value: "false", label: "No" },
	{ value: "true", label: "Yes" },
];

export const projectTypes = [
	{ value: "false", label: "New Build" },
	{ value: "true", label: "Renovation/Refurbishment" },
];
