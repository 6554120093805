
module.exports = {
	RiskAssessmentOverview: {
		riskAssessmentOverview: "Risk Assessment Overview",
		riskTitle: "Risk Title",
		riskPresence: "Risk Presence",
		action: "Action",
		manage: "Manage",
		creditRiskServiceProvider: "Credit Risk Service Provider/Contractor",
		creditRiskImplementorClient: "Credit Risk Implementor End/Client",
		regulatoryRisk: "Regulatory Risk",
		marketRisk: "Market Risk",
		energyPriceRisk: "Energy Price Risk",
		currencyRisk: "Currency Risk",
		technicalRisk: "Technical Risk",
		performanceRisk: "Performance Risk",
		omRisk: "O&M Risk",
		interfaceRisk: "Interface Risk",
		pipelineRisk: "Pipeline Risk",
		prepaymentRisk: "Prepayment Risk",
		occupancyRisk: "Occupancy Risk",
		managementRisk: "Management Risk",
		changeInControlRisk: "Change In Control Risk",
		constructionRisk: "Construction Risk",
		supplyChainRisk: "Supply Chain Risk",
	},
	CreditRiskServiceProvider: {
		riskTitle: "Credit Risk Service Provider/Contractor",
		relevance: "RELEVANCE",
		formula: "Formula",
		year1: "Year 1",
		kpiYear1: "KPI Year 1",
		year2: "Year 2",
		kpiYear2: "KPI Year 2",
		year3: "Year 3",
		kpiYear3: "KPI Year 3",
		liquidity: "Liquidity",
		currentRatio: "Current Ratio",
		quickRatio: "Quick Ratio",
		comparison: "Comparison",
		solvency: "Solvency",
		solvencyRatio: "Solvency Ratio",
		profitability: "Profitability",
		netProfitMargin: "Net Margin",
		commercialProfitability: "Commercial Profitability",
		repaymentCapacityRatio: "Repayment Ratio",
		supplyChainRatios: "Supply Ratios",
		workingCapital: "Working Capital",
		totalScore: "Total Score",
	},
	CreditRiskImplementorClient: {
		riskTitle: "Credit Risk Service Provider/Contractor",
		relevance: "RELEVANCE",
		formula: "Formula",
		year1: "Year 1",
		kpiYear1: "KPI Year 1",
		year2: "Year 2",
		kpiYear2: "KPI Year 2",
		year3: "Year 3",
		kpiYear3: "KPI Year 3",
		liquidity: "Liquidity",
		currentRatio: "Current Ratio",
		quickRatio: "Quick Ratio",
		comparison: "Comparison",
		solvency: "Solvency",
		solvencyRatio: "Solvency Ratio",
		profitability: "Profitability",
		netProfitMargin: "Net Margin",
		commercialProfitability: "Commercial Profitability",
		debtServiceCoverageRatio: "Debt Service Coverage Ratio",
		supplyChainRatios: "Supply Ratios",
		workingCapital: "Working Capital",
		totalScore: "Total Score",
	},
	RegulatoryRisk: {
		relevance: "RELEVANCE",
		yes: "Yes",
		no: "No",
		relevanceVar1: "Incentives",
		relevanceVar2: "- Are government incentives included in the revenue model?",
		relevanceVar3: "- What is the nature of the incentive - upfront grant, tax incentive for number of years, feed-in tariffs, white certificates...? ",
		relevanceVar4: "- What is the share of incentive revenue as % of total revenue?  ",
		relevanceVar5: "Compliance",
		relevanceVar6: "- Is the project governed by any relevant environmental and/or health & safety compliance regulation?",
		riskPresent: "Risk Present",
		responsibility: "RESPONSIBILITY",
		responsibilityVar1: "- Which party is the beneficiary of the incentive?",
		responsibilityVar2: "- Can the incentive be reassigned?",
		responsibilityVar3: "- Are there restrictions that apply in reassigning the incentive?",
		responsibilityVar4: "- In case the incentive revenue is spilt, provide % shares for all parties concerned.",
		responsibilityVar5: "- Describe how client and/or contractor comply with all relevant environmental and/or health & safety regulation(s).",
		riskBearer: "Risk Bearer",
		documentation: "DOCUMENTATION",
		documentationVar1: "List all available documentation to support any of the claims made above:",
		documentationVar2: "Relevant law or regulation that govern the incentives, and its potential reassignment",
		documentationVar3: "Historic data on volume of incentives granted and types of projects that benefitted from the incentive (official source)",
		documentationVar4: "Relevant law or regulation regarding environmental and/or health & safety compliance",
	},
	MarketRisk: {
		relevanceVar1: "Which country/countries are the contractor and the client operating in?",
		relevanceVar2: "What is the current market rating and respective outlook? (requires login)",
		relevanceVar3: "State the relevant date of the rating",
		currentRating: "Current Rating",
		outlook: "Outlook ",
		contractor: "Contractor",
		client: "Client",
		date: "Date",

	},
	EnergyPriceRisk: {
		relevanceVar1: "Does the financial model of the project factor in energy price development? How is it assumed to develop?",
		relevanceVar2: "- If Yes, please provide the following information:",
		relevanceVar3: "- How is the energy price assumed to develop over the course of the investment term?",
		relevanceVar4: "- What was the annual movement of (relevant) energy price in % over the last 5 years? ",
		relevanceVar5: "- What was the inflation rate movement in the relevant market in % over the last 5 years? ",
		year: "year",
		energyPriceMovement: "Energy Price Movement",
		inflationRate: "Inflation Rate",
		responsibilityVar1: "Who bears the risk of deviation from the assumed energy price curve (shortfall, excess revenue?)",
		mitigation: "MITIGATION",
		mitigationVar1: "Has the Service provider/Contractor hedged the energy price risk (e.g. through buying/selling energy futures)?",
		mitigationVar2: "For which duration and which % of the Baseline Monthly Energy Used is covered by this hedge?",
		fullyMitigated: "Fully Mitigated",
		documentationVar1: "Provide information on historic energy price movements from relevant sources (Eurostat)",
		documentationVar2: "Provide information on historic inflation rates from relevant sources (ECB)",
		documentationVar3: "Provide supporting information on a potential energy price hedging instrument",

	},
	CurrencyRisk: {
		relevanceVar1: "Is there a single currency applied to this project?",
		relevanceVar2: "If Yes, state the currency:",
		relevanceVar3: "If No, answer the following:",
		relevanceVar4: "- Which currency will the project cash flows be generated in?",
		relevanceVar5: "- Which currency will be used to purchase the equipment?",
		relevanceVar6: "- Is there any significant foreseeable operational expense throughout the project term in a different currency?",
		mitigationVar1: "Has the Service provider/Contractor hedged the currency risk (e.g. through buying/selling currency futures)?",
		mitigationVar2: "For which duration and which % of revenue and/or operational expenses are covered by this hedge?",
		documentationVar1: "Provide supporting information on a potential currency price hedging instrument",

	},
	TechnicalRisk: {
		relevanceVar1: "Total value of the installed equipment (monetary terms): ",
		relevanceVar2: "Useful life of the installed equipment (years): ",
		relevanceVar3: "Term of the agreement with the Implementor/Client: (years): ",
		relevanceVar4: "Useful life > Term of the agreement?",
		relevanceVar5: "Depreciation of the installed equipment:",
		over: "over, ",
		years: "years.",
		straightLine: "Straight-line",
		accelerated: "Accelerated",
		relevanceVar6: "Best available technologies should be applied – please provide information:",
		relevanceVar7: "- Whether the product(s) is/are defined in the Ecodesign regulation*",
		relevanceVar8: "- A reference list of min. 3 implemented projects where the product(s) were used",
		responsibilityVar1: "According to the LAUNCH Standardized Service Agreement, article 2.11, the Service provider/Contractor is obliged to obtain and maintain insurances, essentially removing technical risks (see below).",
		mitigationVar1: "The following insurances shall be obtained and maintained:",
		mitigationVar2: "- Warranties on all the installed Equipment for a duration that is equal to or greater than the service agreement and the investment agreement period; (Please upload file on Documentation - Warranties on the installed equipment document)",
		mitigationVar3: "- Insurance of the Equipment to a value not less than its full replacement value comprehensively against all usual risks of loss, damage or destruction by fire, theft or accident; (Please upload file on Documentation - Insurance of the equipment (loss/damage/theft): include policy document)",
		mitigationVar4: "- Insurance for such amounts as a prudent owner or operator of the Equipment would insure for, or such amount as the Service provider/Contractor may from time to time reasonably require, to cover any third party or public liability risks of whatever nature and however arising in connection with the Equipment; (Please upload file on Documentation - Liability insurance for the installed equipment: include policy document)",
		mitigationVar5: "- Insurance against such other or further risks relating to the Equipment as may be required by law, together with such other insurance as the Service provider/Contractor may from time to time consider reasonably necessary. (Please upload file on Documentation - Any other insurance as may be required by law: include policy document)",
		mitigationVar6: "- The Service provider/Contractor shall be responsible for paying any deductibles due on any claims under such insurance policies.",
		documentationVar1: "Technical data sheet of installed equipment, indicating useful life",
		documentationVar2: "Warranties on the installed equipment",
		documentationVar3: "Insurance of the equipment (loss/damage/theft): include policy",
		documentationVar4: "Liability insurance for the installed equipment: include policy",
		documentationVar5: "Any other insurance as may be required by law: include policy",
		documentationVar6: "Reference list of implemented projects where the equipment was used (in case it’s not defined in the Ecodesign regulation)",

	},
	PerformanceRisk: {
		relevanceVar1: "Relevant factors included in this risk type:",
		relevanceVar2: "- Flawed Energy Audit",
		relevanceVar3: "- Poor or faulty project design",
		relevanceVar4: "- Flaws in the implementation of EE measures",
		relevanceVar5: "- Equipment malfunctioning",
		relevanceVar6: "- Mistakes in the operation of the measures",
		relevanceVar7: "1. Maintained/operated by the Service Provider/Contractor",
		relevanceVar8: "2. Maintained/operated by the Subscriber/Client",
		relevanceVar9: "- Fluctuation of usage patterns, including change of user behaviour Energy price risk is treated as a separate risk type in this Risk Assessment Protocol",
		relevanceVar10: "Minimum Energy Savings as stated in the LAUNCH Standardized Service Agreement:",
		responsibilityVar1: "According to the LAUNCH Standardized Service Agreement, the Service provider/Contractor guarantees that specific service levels will be achieved for the full term, subject to the satisfactory performance by the Implementor/Client of all its obligations. Therefore, underperformance of the EE measures based on factors 1-4 as well as 5a) above will be covered by the Service provider/Contractor. This guaranteed savings scheme (as opposed to the shared savings scheme) leads to a higher incentive for the Service provider/Contractor to achieve envisaged savings.",
		responsibilityVar2: "The Service provider/Contractor is furthermore required to provide a detailed Measurement and Verification (M&V) Plan, containing:",
		responsibilityVar3: "- Clear specification of the standards used for M&V (IPMVP, ISO 50015)",
		responsibilityVar4: "- Schedule of M&V activities",
		responsibilityVar5: "- Schedule of algorithms used in the calculations",
		responsibilityVar6: "- List of any responsible stakeholders that are party to the M&V protocols",
		responsibilityVar7: "Additionally, the project submission shall contain a sensitivity analysis modelling the proposed performance levels with +/- 10% and +/- 20%, and indicating subsequent impacts on project cash flows.",
		mitigationVar1: "Has the Service provider/Contractor purchased performance insurance?",
		mitigationVar2: "- If yes, what % of performance (with reference to the guaranteed service level) is insured?",
		documentationVar1: "Detailed M&V Plan",
		documentationVar2: "Worst, real and best-case scenarios for performance levels",
		documentationVar3: "If available, performance insurance documentation",

	},
	OMRisk: {
		relevanceVar1: "As the Service provider/Contractor (according to the LAUNCH Standardized Agreement) retains legal and financial ownership of the equipment, he/she is incentivised to ensure careful operations and timely maintenance of the equipment throughout the service period.",
		relevanceVar2: "Nevertheless, the Service provider/Contractor shall provide a detailed O&M schedule, and disclose all relevant O&M documentation from previously undertaken and/or ongoing projects of similar nature in support of that.",
		responsibilityVar1: "According to the LAUNCH Standardized Service Agreement, the Service provider/Contractor is responsible for undertaking all necessary O&M work during the full-service period. The following information shall be provided in the O&M schedule:",
		responsibilityVar2: "- Who is the O&M provider?",
		responsibilityVar3: "- Credit quality of O&M provider (in case it differs from the Service provider/Contractor) - (Please upload file on Documentation - Detailed O&M schedule document)",
		responsibilityVar4: "- Historical O&M performance by O&M provider (Please upload file on Documentation - Back-up O&M arrangements (if present) document)",
		responsibilityVar5: "- Monitoring and communication systems used for outage detection and system performance",
		responsibilityVar6: "- Documented and mutually agreed upon response protocol (as a Schedule to the LAUNCH Standardized Agreement)",
		responsibilityVar7: "- Presence and nature of any back-up O&M arrangements (Please upload file on Documentation - Full credentials and previous experience by assigned O&M provider document)",
		responsibilityVar8: "- The LAUNCH Standardized Service Agreement furthermore includes a step-in clause to allow for the replacement of the Service provider/Contractor in certain defined cases",
		mitigationVar1: "Note: Contractual stipulations ensuring O&M service levels post-securitization should be included in the transfer agreement",
		documentationVar1: "Detailed O&M schedule",
		documentationVar2: "Full credentials and previous experience by assigned O&M provider",
		documentationVar3: "Back-up O&M arrangements (if present)",

	},
	InterfaceRisk: {
		relevanceVar1: "The LAUNCH Standardized Agreement delineates clear roles and responsibilities between the Service provider/Contractor and the Implementor/Client, in order to limit any interference to a minimum and ensure correct operation of the equipment.",
		responsibilityVar1: "According to the LAUNCH Standardized Service Agreement, the Subscriber/Client is obliged (among other things) to:",
		responsibilityVar2: "- Keep the installed equipment and its environment clean and safe",
		responsibilityVar3: "- Make no alteration to the equipment",
		responsibilityVar4: "- Keep the equipment at its installed location",
		responsibilityVar5: "- Allow access for inspection and maintenance at all reasonable times",
		mitigationVar1: "Note: the LAUNCH Standardized Service Agreement includes a removal cost clause in case of breach or pre-emptive unilateral termination of the agreement",

	},
	PipelineRisk: {
		relevanceVar1: "Size of the commercial project pipeline by the Service provider/Contractor with the same specifications, in particular:",
		relevanceVar2: "- Similar technology",
		relevanceVar3: "- The same client type (sector, maturity)",
		relevanceVar4: "- The same contractual agreement",
		relevanceVar5: "- The same project terms",
		relevanceVar6: "Contract signed with Implementor/Client – ready for installation",
		relevanceVar7: "With agreements to proceed (to be closed in less than 6 months):",
		relevanceVar8: "With agreements to proceed (to be closed in less than 12 months):",
		relevanceVar9: "Select the relevant currencies:",
		documentationVar1: " Detailed project pipeline document, listing all relevant opportunities according to the 3 stages outlined above",

	},
	PrePaymentRisk: {
		relevanceVar1: "The LAUNCH Standardised Agreement outlines in Article 13 a number of reasons for which the agreement can be terminated pre-emptively.",
		relevanceVar2: "In case of pre-emptive service termination, the following conditions have to be assessed:",
		relevanceVar3: "- Technical consideration: equipment can be technically removed",
		relevanceVar4: "- Economic consideration: equipment can be sold for a reasonable price (either in a new location or by a new user in the same location) ",
		relevanceVar5: "- Legal consideration: ownership of equipment can be transferred ",
		responsibilityVar1: "Note: Ultimately, pre-payment risk will be something to be dealt with in the transfer agreement between the Service provider/Contractor and the Securitization Vehicle.",
		mitigationVar1: "Note: A “removal cost” provision in the LAUNCH Standardized Agreement mitigates this specific risk type in case of unilateral pre-emptive termination without just cause or breach of the agreement",

	},
	OccupacyRisk: {
		relevanceVar1: "How are energy consumption baselines adjusted for change in occupancy levels?",
		relevanceVar2: "Is there a minimum payment level stipulated, and in case there isn’t: which % of drop in occupancy level is (still) financially sustainable?",
		relevanceVar3: "Is there a contractual stipulation in case of decommissioning of the facility where measures were installed? ",
		responsibilityVar1: "The Service provider/Contractor is responsible for regular adjustments to the energy consumption baselines. The Subscriber/Client is responsible for timely and transparent communication about any change of occupancy level.",
		responsibilityVar2: "Note: At which state is the Service provider/Contractor no longer obliged to provide services?",
		mitigationVar1: "Note: A minimum payment level, as a % of the subscription value, is now specified in the LAUNCH Standardized Agreement which mitigates this specific risk type",

	},
	ManagementRisk: {
		relevanceVar1: "In order to ensure sound governance of investments and installed equipment, the following “Know-Your-Customer” (KYC) data is required:",
		relevanceVar2: "- Type of entity ",
		relevanceVar3: "- Sector of activity, including sector code? ",
		relevanceVar4: "- Number of employees ",
		relevanceVar5: "- Ownership structure (Please upload file on Documentation - Ownership structure document) ",
		relevanceVar6: "- Background and track record of key management personnel (Please upload file on Documentation - CVs of key management personnel document) ",
		responsibilityVar1: "The Service provider/Contractor is obliged to gather the KYC information from the Subscriber/Client and submit it together with the same data on the Service provider/Contractor company.",
		documentationVar1: "Chamber of Commerce registration document",
		documentationVar2: "Documentation of shareholder structure",
		documentationVar3: "CVs of key management personnel",
		documentationVar4: "Ownership structure",

	},
	ChangeInControlRisk: {
		relevanceVar1: "Is the Subscriber/Client the legal owner of the building where equipment will be installed? In case the building is rented, please provide information on the site lease and term.",
		responsibilityVar1: `The LAUNCH Standardized Agreement will include a clause obliging either the new Subscriber/Client to enter into the existing Service Agreement with the same terms OR 
		the existing/old Subscriber/Client to pay the removal cost to the legally assigned recipient/counterparty.
		`,
		responsibilityVar2: "- The new Subscriber/Client to enter into the existing Service Agreement with the same terms or",
		responsibilityVar3: "- The existing/old Subscriber/Client to pay the removal cost to the legally assigned recipient/counterparty.",
		new: "New",
		old: "Old",
		mitigationVar1: "According to the LAUNCH Standardized Agreement, this risk type would be fully mitigated.",
		documentationVar1: "Information on the site lease and term",

	},
	ConstructionRisk: {
		relevanceVar1: "Are all necessary construction permits obtained to allow for a timely execution?",
		relevanceVar2: "What are the lead times for the equipment, and will all equipment be available in due time?",
		relevanceVar3: "Is there a significant interference with current occupants or operations in the prospective building that requires construction works to be limited in time or restricts it to a specific time window?",
		relevanceVar4: "If yes, what are the consequences of not meeting these limitations or restrictions? ",
		relevanceVar5: "Are change orders allowed and if yes, to what extend and until what stage of execution?",
		responsibilityVar1: "As the responsible party, the Service provider/Contractor should provide two important sets of documentation:",
		responsibilityVar2: "- A detailed handoff procedure listing all steps, interferences and handoffs between the (sub-) contracted parties involved in carrying out the work. (Please upload file on Documentation - Handoff procedure document) ",
		responsibilityVar3: "- A detailed commissioning report template stating all necessary checks for completion, quality control, compliance and inspections that had to be respected in the construction phase. (Please upload file on Documentation - Commissioning report template document)",
		mitigationVar1: "More complex or more invasive measures, especially in building environments that are used for production, essential services, etc. additional mitigation measures against delays could be required, such as a clause on liquidated damages (e.g. for not having been able to use a building or a part of it for a longer period) or a construction risk insurance policy.",
		documentationVar1: "Handoff procedure",
		documentationVar2: "Commissioning report template",
	},
	SupplyChainRisk: {
		relevanceVar1: "Does at least one of the contracting parties (Service Provider/Contractor, O&M, and/or technology provider) have a supply chain that is located in one or more location(s) other than its own and reliant on at least one other third party?",
		relevanceVar2: "If Yes, select these locations:",
		responsibilityVar1: "The Service provider/Contractor is responsible for the management and traceability of its supply chain, including that of each of its suppliers and (if relevant) their respective suppliers.",
		responsibilityVar2: "- Can the company report that it sources a minimum % / level of supplies via reshoring or, for example, through so - called ‘hybrid’ supply chains* ?",
		responsibilityVar3: "If yes, provide a report:",
		responsibilityVar4: "- Does the company track supply chain risk in its operations (for example, through resilience indicators or other tools)? Does the company conduct/possess a mapping or have complete visibility of its supply chain?",
		responsibilityVar5: "If yes, provide a report:",
		responsibilityVar6: "*re - shoring – relocating the production or manufacturing of goods back to the company’s country of origin, with supply chain based mainly within country of supply (eg, Rossignol ski’s); a.k.a.: ‘inshoring’, ‘nearshoring’, ‘backshoring",
		responsibilityVar7: "‘hybrid’ supply chains: supply chains that combine offshoring and reshoring (eg, Chinese electronics produced partly in South - east Asia)",
		mitigationVar1: "Note: contract conditions with the Service provider/Contractor could insure all parties in the case of delays/missing equipment/recalls by including, for example, minimum and maximum payment levels in such cases, and/or insurance, liability insurance, and liability claims conditions in the case of loss or damage",
		mitigationVar2: "Potential mitigation measures could be verified in the form of questions to the responsible party( -ies), such as:",
		mitigationVar3: "- What guarantees/insurance against supply bottlenecks does the company have? E.g.: does the company have an insurance policy that covers supply chain risk, for instance, in the event of bottlenecks, via coverage of associated costs?",
		mitigationVar4: "If yes, provide insurance policy:",
		mitigationVar5: "- What % of the company’s supply chain is located ‘offshore’ vs ‘inshore’/ ‘re - shore’ /‘backshore’ / ‘nearshore’",
		mitigationVar6: "- Does the company hedge supply chain risk via any financing mechanisms or instruments (eg, insurance policy or other)?",
		mitigationVar7: "If yes, describe below:",
		mitigationVar8: "- Can the company report on measures to mitigate the risk of extra costs from unexpected supply chain halts / delays / redundancies?",
		mitigationVar9: "If yes, provide a report:",
		mitigationVar10: "- Can the company report on maximum no. / % / level of non-EU taxonomy compliant products/activities in its supply chain and/or measures to reduce this?",
		mitigationVar11: "If yes, provide a report:",
		documentationVar1: "Insurance policy",
		documentationVar2: "Annual report, incl. audit report",

	},
	Contract: {
		pageTitle: "Contract",
		status: "Status",
		created: "Created",
		customContract: "Custom Contract",
		uploadContract: "Upload Custom Contract",
		serviceProviderName: "Service Provider Name",
		serviceProviderNameText: "The name of the service provider",
		directorName: "Director Name",
		directorNameText: "The name of the director",
		countryOfRegistration: "Country of Registration",
		countryOfRegistrationText: "The country of registration",
		companyNumber: "Company Number",
		companyNumberText: "The company number",
		registeredOfficeAddress: "Registered Office Address",
		registeredOfficeAddressText: "The address of the registered office",
		registeredOfficePostcode: "Registered Office Postcode",
		registeredOfficePostcodeText: "The postcode of the registered office",
		registeredOfficeCity: "Registered Office City",
		registeredOfficeCityText: "The city of the registered office",
		registeredOutputType: "The output type",
		currency: "Currency",
		outputType: "Output Type",
		schedule1: "Schedule 1",
		schedule2: "Schedule 2",
		schedule3: "Schedule 3",
		schedule4: "Schedule 4",
		schedule5: "Schedule 5",
		schedule6: "Schedule 6",
		schedule7: "Schedule 7",
		sign: "Sign",
		save: "Save",
		clear: "Clear",
		signedDocuments: "Signed Documents",
		contractSigned: "Contract Signed",
		schedule1Signed: "Schedule 1 Signed",
		schedule2Signed: "Schedule 2 Signed",
		schedule3Signed: "Schedule 3 Signed",
		schedule4Signed: "Schedule 4 Signed",
		schedule5Signed: "Schedule 5 Signed",
		schedule6Signed: "Schedule 6 Signed",
		schedule7Signed: "Schedule 7 Signed",
		blankField: "This field should not be left blank",
		blankFields: "These fields should not be left blank",
		downloadTemplate: "(Download Template)",
		noUploadedFile: "No uploaded file",
		needFile: "You need to upload a file",
		areYouSure: "Are you sure?",
		areYouSureDelete: "Are you sure you want to delete the file?",
		delete: "Delete",
		cancel: "Cancel",
		downloadFile: "Download file",
		signerEmailError: "You need to provide a valid email address",
		serviceSubscriberName: "Service Subscriber Name",
		serviceSubscriberEmail: "Service Subscriber Email",
		serviceSubscriberDirectorName: "Service Subscriber Director Name",
		serviceSubscriberCountryOfRegistration: "Service Subscriber Country of Registration",
		serviceSubscriberCompanyNumber: "Service Subscriber Company Number",
		serviceSubscriberRegisteredOfficeAddress: "Service Subscriber Registered Office Address",
		serviceSubscriberRegisteredOfficePostcode: "Service Subscriber Registered Office Postcode",
		serviceSubscriberRegisteredOfficeCity: "Service Subscriber Registered Office City",
		serviceSubscriberNameText: "The name of the service subscriber",
		serviceSubscriberEmailText: "The email of the service subscriber director",
		serviceSubscriberDirectorNameText: "The name of the service subscriber director",
		serviceSubscriberCountryOfRegistrationText: "The country of registration of the service subscriber",
		serviceSubscriberCompanyNumberText: "The company number of the service subscriber",
		serviceSubscriberRegisteredOfficeAddressText: "The registered office address of the service subscriber",
		serviceSubscriberRegisteredOfficePostcodeText: "The registered office postcode of the service subscriber",
		serviceSubscriberRegisteredOfficeCityText: "The registered office city of the service subscriber",
		savedSuccessfully: "Information saved successfully",
		professionalIndemnityInsuranceAmount: "Professional Indemnity Insurance Amount",
		professionalIndemnityInsuranceAmountText: "The amount of professional indemnity insurance",
		daysToPaySecurityDeposit: "# Days for Subscriber to pay to the Security Deposit",
		daysToPaySecurityDepositText: "The number of days for the security deposit to be paid",
		businessHours: "Business Hours",
		businessHoursTo: "to",
		interest: "Interest",
		interestOver: "over",
	},
	ESG: {
		AllAspects: {
			title: "ESG ALL ASPECTS",
			Management: {
				title: "MANAGEMENT",
				drop1: "Which formal framework or reporting standards does the entity adhere to or have signed up to?",
				text1: "Does the entity have specific ESG objectives and defined targets?",
				text2: "Has the entity been involved in any significant ESG-related controversies, misconduct, penalties, incidents or accidents during the reporting period?",
				text3: "Has the entity received stakeholder grievances during the reporting period?",
				doc1: "Does the entity have an established structure of responsibilities of the management body in setting, overseeing and monitoring the risk framework, objectives, strategies and policies in the context of ESG risks?",
			},
			Performance: {
				title: "PERFORMANCE",
				docIssues1: "Has your company obtained any certification regarding environmental, social or business ethics issues (e.g.: ISO 14001, ISO 45001. regional/sector specific certifications)?",
			},
		},
		Environmental: {
			title: "ESG Environmental",
			Management: {
				title: "MANAGEMENT",
				text1: "Does the entity monitor environmental performance?",
				text2: "Does the entity promote sustainable consuption to costumers?",
				docIssues1: "Does the entity have a policy or policies on environmental issues?",
				docIssues2: "Does the entity have an Action Plan to reduce their CO2 emissions?",
				info1: "I.e.: waste management, energy conservation and pollution prevention / reduction.",
				info2: "Does the entity have an Action Plan to reduce their CO2 emissions and contribute to the climate mitigation and climate adaptation in accordance with the Paris Climate Agreement objectives?",
			},
			Performance: {
				title: "PERFORMANCE",
				doc1: "Does the entity report on energy consumption?",
				doc2: "Can the entity report on greenhouse gas emissions?",
				doc3: "Does the entity report on CO2 and on the energy savings in MWh ?",
				doc4: "Can the entity report on air pollution?",
				doc5: "Can the entity report on water inflows / withdrawals in liters?",
				doc6: "Can the entity report on waste generated and disposed in tones?",
				doc7: "Can the entity report on blodiversity and habitat?",
				doc8: "Can the entity list the key actions implemented to mitigate environmental risks or improve environmental performance?",
				doc9: "Can the entity provide Energy Performance Certificate of the office / building / facility that they are occupying / owning / using / managing?",
				info1: "Or any information given through energy standards and labelling for buildings.",
			},
			Actions: {
				title: "ACTIONS",
				text1: "Reduction of energy consumption through technology or equipment upgrades.",
				text2: "Measures to reduce energy consumption of IT infrastructure.",
				text3: "Actions to reduce emissions from transportation.",
				text4: "Recycling of office waste (paper, cardboard, ink cartridges etc.).",
				text5: "Recycling of IT equipment (please specify what and in what quantity).",
				text6: "Environmental consulting or environmental solutions services for clients.",
				doc1: "Employee awareness/training program on energy conservation.",
			},
		},
		Social: {
			title: "ESG SOCIAL",
			Management: {
				title: "MANAGEMENT",
				docIssues1: "Does the entity have a Human Rights and Labour standards Policy?",
				docIssues2: "Does the entity have Discrimination inequality and social inclusiveness Policy?",
				docIssues3: "Does the entity have a Career Development policy?",
				docIssues4: "Does the entity have a Code of Conduct/Code of Ethics?",
				docIssues5: "Does the entity have Customer safety and protection Policy or internal guidelines?",
				docIssues6: "Company track record.",
			},
			Performance: {
				title: "PERFORMANCE",
				text1: "Can the entity report on the health and safety of its contructors?",
				text2: "Does the entity engage with its employees through training or satisfaction monitoring?",
				doc1: "Can the entity report on the health and safety performance of its employees for the current year and/vs previous years?",
				doc2: "Does the entity report on inclusion and diversity?",
				doc3: "Can the entity list the key actions implemented to mitigate social risks or improve social performance?",
				doc4: "Does the entity motitor social performance?",
			},
			Actions: {
				title: "ACTIONS",
				drop1: "Regular assessment (at least once a year) of individual performance.",
				doc1: "Training of employees on health & safety risks and best working practices.",
				doc2: "Health care coverage of employees in place.",
				doc3: "Employee health & safety detailed risk assessment.",
				doc4: "Flexible organization of work available to employees.",
				doc5: "Provision of skills development training.",
				doc6: "Additional comments regarding Labour and Human Rights topics of your company's management system.",
				info1: "Please also indicate any CSR-related external recognition obtained by your company within the last five years, such as CSR-related awards, or listing in CSR rankings or indexes.",
			},
			KPIS: {
				title: "KPIS",
				text1: "Absentee rate (days lost due to absenteeism / scheduled working days x 100).",
				text2: "Sick days due to work related injury (days lost due to sick leave/scheduled working days x 100).",
				text3: "Percentage of employees receiving social benefits.",
				text4: "Average number of hours of training per employee / per topic",
			},
		},
		Governance: {
			title: "ESG Governance",
			Management: {
				title: "MANAGEMENT",
				text1: "Does the entity monitor governance performance? Does entity have targets set up?",
				text2: "Does the entity disclose its ESG actions and/or performance?",
				doc1: "Does the entity have an Anti-Corruption and bribery Policy?",
				doc2: "Does the entity have a Confidentiality and Privacy Work Instructions?",
				doc3: "Does the entity have a systematic process for identifying physical risks that could have a financial impact on the entity?",
				doc4: "Is there a formal process for the stakeholders to communicate grievances that apply to this entity?",
				doc5: "Does the entity have a Business ethics and values policy?",
				doc6: "Does the entity have Steering committee and escalation path set up?",
				doc7: "Does the entity have escalation procedures and reporting at a governance level?",
				doc8: "Does the entity have a Sustainable Procurement Policy (including supplier and sub-supplier chain)?",
				doc9: "Is there an alignment of the remuneration policy with ESG risks?",
				doc10: "Does the entity have a Confidentiality and Privacy Work Instructions?",
				info1: "Does the integration of ESG risks into the organisational arrangements include the role of risk committees, business lines and internal control functions set up?",
			},
			Performance: {
				title: "PERFORMANCE",
				doc1: "Diversity on company boards.",
				doc2: "Please also indicate any CSR-related external recognition obtained by your company within the last five years.",
				info1: "Such as CSR-related awards, or listing in CSR rankings or indexes.",
			},
			Actions: {
				title: "ACTIONS",
				doc1: "Awareness training to prevent discrimination and /or harassment.",
				doc2: "Awareness training performed to prevent corruption and bribery.",
				doc3: "Whistleblower procedure to report ethics issues.",
				doc4: "Awareness training to prevent information security breaches",
			},
			KPIS: {
				title: "KPIS",
				doc1: "Board diversity in terms of age, gender, educational and professional background.",
				text1: "Average number of hours of training per employee on corruption and bribery and discrimination and/or harassment.",
				text2: "Number of reported grievances in the last 3 years",
			},
		},
	},
	LoanApplication: {
		Overview: {
			title: "LOAN APPLICATION OVERVIEW",
			LoanOverview: {
				title: "LOAN OVERVIEW",
				fundingAmount: "Funding Amount",
				typeOfFinance: "Type of Finance",
				applicationStatus: "Application Status",
				info1: "Small projects range between 500,000 - 1,000,000. Medium to large projects range above 1,000,000.",
				info2: "Type of financing can be corporate lending or project finance.",
			},
			EscoOverview: {
				title: "ESCO OVERVIEW",
				escoName: "ESCo Name",
				mainBusinessActivity: "Main Business Activity",
				doc1: "Financial Forecasts",
				doc2: "End-Client pool-pipeline",
				doc3: "Accounting policies (full or simplified)",
				doc4: "Management Team",
				doc5: "Audited financial statements",
				doc6: "Details of short, medium and long-term liabilities",
				doc7: "Dividend policy",
				info1: "Balance sheets, profit and loss and cashflow statements for the last three financial years",
			},
			TechnologyUsed: {
				title: "Technology Used",
				category: "Category",
				subcategory: "Subcategory",
				specificTechnology: "Specific Technology",
				doc1: "Value curve in the future",
				doc2: "Asset Gap (optional)",
			},
			TechnicalSpecifications: {
				title: "Technical Specifications",
				doc1: "Technical specifications and warranty for main parts",
				doc2: "Technical specifications and warranty for spare parts",
				doc3: "Technical risk assessment and allocation",
				info1: "Collateral which will be built on the Risk Assessment Protocol (RAP) that was developed under LAUNCH",
			},
			TrackRecord: {
				title: "Track Record",
				radio1: "Is ESCo a new market player?",
				doc1: "Proof of track record",
				doc2: "Track record of technical team, team credentials and capacity",
				doc3: "Description of other team members that are part of the project",
				doc4: "EE audit",
				text1: "Number of years of operation",
				text2: "Status of other current projects (under development / operational)",
				info1: "Provide case studies of at least 5 projects that were successful.",
				info2: "E.g. Financial department, Sales, Marketing, Sustainability, and other departments",
			},
			ManagementOrganization: {
				title: "Management Organization",
				doc1: "Organigram",
				doc2: "Present Management Team",
				doc3: "CVs",
				info1: "Please upload the CVs of your team in zip format.",
			},
			SalesStrategy: {
				title: "Sales Strategy",
				doc1: "Sales performance, pipeline",
				doc2: "Sales current strategy (short and long term)",
				doc3: "CSR analysis",
				text1: "Pipeline Overview",
				info1: "E.g. some lenders have a minimal requirement of i.e. min. 500 000 EURO under 1 VAT number",
			},
		},
		CompanyOverview: {
			title: "COMPANY OVERVIEW",
			CompanyOverview: {
				title: "COMPANY OVERVIEW",
				escoName: "ESCo Name",
				assetFinanced: "Asset financed",
				maintenanceIntergrated: "Maintenance Integrated",
				vendorOfAsset: "Vendor of the asset",
				typeOfFinancing: "Type of financing",
				amount: "Amount",
				tenor: "Tenor",
				residualValue: "Residual value at the end of the contract",
				refurbishAsset: "Who will be refurbish the asset",
				doc1: "Payment schedule",
				doc2: "Economics of the request",
				info1: "Who takes the responsibility to remarket the asset",
				info2: "Example: min. 30% savings on the energy bill",
			},
		},

		ProjectFinance: {
			title: "PROJECT FINANCE",
			ProjectFinance: {
				title: "PROJECT FINANCE",
			},
			EnvisagedCommercial: {
				title: "Project's envisaged commercial structure and risk allocation",
				purposeOfProject: "Purpose of the project",
				envisionedFinancingStructure: "Envisioned Financing Structure",
				financialModel: "Financial Model",
				doc1: "Envisaged Contract",
				doc2: "Analysis of project's revenue and cost risks",
				doc3: "Technical, Legal or Insurance Advice received on project",
				doc4: "Project Risk Assessment",
				info1: "Any envisaged offtake (revenue) or supply contracts (including construction and operation/maintenance) and the parties to these contracts (including their experience in the domain of the project and their credit standing)",
				info2: "Describe in a very concise manner the envisioned project’s financing structure including a detailed breakdown of the financing sources: equity, mezzanine and senior debt, and the envisaged role of bank within the financing structure.",
				info3: "Description of the financial model, including cashflow forecasts for the life of the project in sufficient detail to enable the analysis of the underlying assumption (e.g. detailed revenue, funding, operating and maintenance cost forecasts). The underlining assumptions need to be disclosed, transparent and well supported.",
				info4: "Please provide any technical, legal or insurance advice or study that was provided and received on this project that might be relevant.",
			},
		},
		FinancialInformation: {
			title: "FINANCIAL INFORMATION",
			FinancialInformation: {
				title: "FinancialInformation",
				doc1: "Breakdown of project operating and maintenance costs, depreciations and overheads",
				doc2: "Financing plan and schedule of projected expenditure",
				doc3: "Cashflows, profit/loss accounts, balance sheets",
				doc4: "Estimate of project working capital requirements over time",
				doc5: "Securities and guarantees",
				doc6: "Payments terms/schedule",
				doc7: "Debt/equity ratio disclose calculation",
				doc8: "Liquidity ratio disclose calculation",
				info1: "Projected cashflows, profit and loss accounts, and balance sheets, until the project is expected to come fully on stream.",
				info2: "Securities and guarantees offered such as Blank bill of exchange, Parent company guarantee, Mortgage",
				info3: "I.e. is early termination clause foreseen? Skip payment, and other relevant info",
				irr: "Calculations of project's IRR",
				accounts: "Accounts",
			},
		},
		TechnicalInformation: {
			title: "TECHNICAL INFORMATION",
			TechnicalInformation: {
				title: "TECHNICAL INFORMATION",
			},
			SiteDevelopment: {
				title: "Site development and size, building, production and storage plant, general services",
				siteDevelopment: "Site Development",
				currentBuildingQuality: "Current building quality",
				squareMeters: "Square meters",
				buildingPurpose: "Building Purpose",
				currentUsageEnergyClass: "Current usage energy class",
				developmentProcess: "Development Process",
				buildingAndProduction: "Building and production storage plant and general services",
				doc1: "Permissions and relevant technical studies, designing plans/contruction",
			},
			EquipmentAndMaterial: {
				title: "Equipment and material",
				transportSystems: "Transport Systems",
				doc1: "Specifications & Installation",
				doc2: "Economy of project",
				doc3: "Transport Details",
				doc4: "Feasibility study",
				info1: "Please provide information about the Equipment and material used (technical specifications, justify choice of brand) and provide details about the installation procedure in a concise manner.",
				info2: "If transport of the equipment is needed to the site of the project please describe the system procedure, cost, means.",
				info3: "Study with assumptions included and references to EU International standards, including GHG or CO2 assessment and abatement (where applicable).",
			},
			EnvironmentalImpactAssessment: {
				title: "Environmental impact assessment",
				referencelaws: "Reference to relevant laws (national and international)",
				doc1: "Measures",
				doc2: "Engineering studies",
				doc3: "Procedures for tendering and awarding contracts",
				info1: "Mitigating measures to protect the environment, specific studies, environmental risk assessment matrix",
				info2: "Mitigating measures to protect the environment, specific studies, environmental risk assessment matrix",
				info3: "If any offers have been accepted already from suppliers please share the terms and conditions of the offer that was chosen.",
			},
			ImplementationPlan: {
				title: "Implementation Plan",
				supervisionStructure: "Supervision structure",
				workSchedule: "Work Schedule",
				timetable: "Implementation timetable/timelines",
				doc1: "White/Green Certficates or Similar Certificates",
				info1: "If any white or green certificates or similar are planned to be used to support the cash flow of the company/project the please disclose how this are being accessed, what type of certificate, procedure of obtaining and/or disposing of them, deadlines and assumptions and proof should be provided in detail (how much, how much energy savings will be produced, energy prices assumptions - source of the energy price index used, how much power/energy/heat/steam will be produced. If CO2, F-gas abatement is used in the calculation assumptions please disclose the full assumptions (energy saving proof). Categories with reference to the EU standards or International standards specific to the service/technology provided would need to be included in the loan application.",
			},
			Operation: {
				title: "Operation",
				rawMaterials: "Raw materials and products used",
				doc1: "Flowcharts, consumption and output level",
			},
			TechnicalAndFinancialAsssistance: {
				title: "Technical and Financial Assistance",
				technicalAssistance: "Was any technical or financial assistance provided by any international, national, regional or local body?",
			},
		},
		DetailedEEInformation: {
			title: "DETAILED/EE INFORMATION",
			DetailedEEInformation: {
				title: "DETAILED/EE INFORMATION",
				projectRiskAssessment: "Project Risk Assessment",
				doc1: "Business plan",
				textMain: "The business plan must contain the following data:",
				text1: "Detailed cost estimate, itemizing site and plant expenditure, cost of installation Provision for physical and price contingencies,",
				text2: "Interest expenses or cost during construction,",
				text3: "Initial and start-up expenses, maintenance cost, (together with a breakdown in foreign and local currencies if such is the case),",
				text4: "Please include and describe what is the percentage of the equity contribution foreseen by the company,",
				text5: "The assumptions used in their business case needs to reflect the ESCo's view, however the underlying parameters and assumptions need to be clearly and specifically spelled out in the models (no generalization): i.e. electricity index price used, how it was chosen and why. Financial understanding and translation of energy savings in monetary output is essential, please disclose the assumptions and benchmarks used,",
				text6: "If no benchmarks are available due to a lack o/data and impossibility of comparing the past consumption vs. the present/future one and prove the future savings international and EU standards should be used as a prerequisite or a simple 'pilot/monitoring' installation can be done at the site to be able measure the current need/consumption and createa valid benchmark,",
				text7: "Estimations need to be provided with proper evidence: disclose technical specifications, when it comes to the equipment. provide the name of the brand and not only the product, plus cost of spare parts and warranty.",
				text8: "Please include the Payment terms/schedule (is early termination clause foreseen? Skip payment Please disclose/ elaborate),",
				text9: "Final offer made to the final client /accepted offer of services by the client (to contain: costs, energy savings, how will they maintain it, maintenance cost),",
				text10: "Equipment offers or accepted offer on the equipment,",
			},
		},
	},
};
