/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */

import { memo, useEffect, useState } from "react";
import { Link, Route, Routes, useLocation, useParams } from "react-router-dom";
import { Grid, Tab, Tabs, Typography, Box, List, ListItem, ListItemText, Drawer, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useSnackbar } from "../../utils";

import CoursesAndTraining from "./CoursesAndTraining";
import ToolsAndResources from "./ToolsAndResources";
import ExpertContactForm from "./ExpertContactForm";

const useStyles = makeStyles((theme) => ({
	screenTitle: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		letterSpacing: theme.spacing(0.2),
	},
}));

const QnA = () => {
	const { pathname } = useLocation();
	const { companyid } = useParams();
	const [height, setHeight] = useState(100);
	const { error } = useSnackbar();
	const [expanded, setExpanded] = useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const classes = useStyles();

	return (
		<>
			<Grid container direction="column" alignItems="center" py={1} px={3} sx={{ "> .MuiGrid-item": { p: 1 }, position: "fixed", maxWidth: "1300px", width: "calc(100% - 300px)", background: "white", zIndex: 999 }}>
				<Grid container item direction="row" alignItems="center" sx={{ "> .MuiGrid-item": { p: 0 }, background: "#F4F4F4", borderRadius: "10px 10px 10px 10px" }}>
					<Grid item xs={12} md={7}>
						<Typography variant="h5" className={classes.screenTitle}>{"Q & A"}</Typography>
					</Grid>
					<Grid item xs={12} md={5} />
				</Grid>
			</Grid>
			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "45px" }}>
				<Accordion expanded={expanded === "panel1"} sx={{ width: "100%", backgroundColor: expanded === "panel1" ? "#E7EBF0" : "grey.main" }} onChange={handleChange("panel1")}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1bh-content"
						id="panel1bh-header"
					>
						<Typography variant="h6" sx={{ width: "100%" }}>
							{"Why can I not create a project?"}
						</Typography>
						<Divider />
					</AccordionSummary>
					<Divider sx={{ margin: "0px 15px 10px 15px" }} />
					<AccordionDetails>
						<Typography>
							{"Firstly, check whether you are an owner or a member of the account, only owners can create new projects. Secondly, have you completed your company profile? You are required to do this before you can create projects. If you are still having issues, please contact us using the expert contact form."}
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Grid item />
				<Accordion expanded={expanded === "panel2"} sx={{ width: "100%", backgroundColor: expanded === "panel2" ? "#E7EBF0" : "grey.main" }} onChange={handleChange("panel2")}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1bh-content"
						id="panel1bh-header"
					>
						<Typography variant="h6" sx={{ width: "100%" }}>
							{"How can I access and use the eQuad tool for my projects?"}
						</Typography>
						<Divider />
					</AccordionSummary>
					<Divider sx={{ margin: "0px 15px 10px 15px" }} />
					<AccordionDetails>
						<Typography>
							{"If you would like to use the eQuad tool to create a financial pro forma for your project but do not have an eQuad account, please contact us using the expert contact form or email"}
							&nbsp;
							<a href="mailto:estreng@jouleassets.com">{"estreng@jouleassets.com"}</a>
							{"."}
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Grid item />
				<Accordion expanded={expanded === "panel3"} sx={{ width: "100%", backgroundColor: expanded === "panel3" ? "#E7EBF0" : "grey.main" }} onChange={handleChange("panel3")}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1bh-content"
						id="panel1bh-header"
					>
						<Typography variant="h6" sx={{ width: "100%" }}>
							{"What is the difference between a member and an owner?"}
						</Typography>
						<Divider />
					</AccordionSummary>
					<Divider sx={{ margin: "0px 15px 10px 15px" }} />
					<AccordionDetails>
						<Typography>
							{"An owner has access to everything in the account automatically. A member has access to what the owner assigns them access to."}
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Grid item />
				<Accordion expanded={expanded === "panel4"} sx={{ width: "100%", backgroundColor: expanded === "panel4" ? "#E7EBF0" : "grey.main" }} onChange={handleChange("panel4")}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1bh-content"
						id="panel1bh-header"
					>
						<Typography variant="h6" sx={{ width: "100%" }}>
							{"What are the different project user types?"}
						</Typography>
						<Divider />
					</AccordionSummary>
					<Divider sx={{ margin: "0px 15px 10px 15px" }} />
					<AccordionDetails>
						<Typography>
							{"A project developer has access to all tools. A tool expert is an internal user from your company whom you assign project tools on which they should focus on. A material expert is a third-party user (e.g. a SEFA admin) who you can invite to review and advice on certain parts of your project."}
						</Typography>
					</AccordionDetails>
				</Accordion>
			</Grid>
		</>
	);
};

export default memo(QnA);
