/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	Grid,
	LinearProgress,
	Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { httpResponseIsValid, useSnackbar } from "../../utils";
import usePrompt from "../../utils/use-blocker";
import {
	MainMenu,
	LabelTextField,
	LabelFileUpload,
	LabelRadio,
} from "../../components/basic/index";
import {
	getProjectLoanApplicationFinancialInformation,
	updateProjectLoanApplicationFinancialInformation,
} from "../../api";

const LoanApplicationFinancialInformation = ({ disableAccess = true, isProjectDeveloper = false, onChangeProjectState }) => {
	const { projectid } = useParams();

	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(disableAccess);
	const [isLoading, setIsLoading] = useState(false);

	const [project, setProject] = useState({
		name: "",
		status: "-",
		createdAt: "-",
		data: {
			irr: "",
			accounts: "false",
		},
		docs: {
			breakdown_of_project: {
				original_name: "",
				save_name: "",
			},
			financing_plan: {
				original_name: "",
				save_name: "",
			},
			cashflows: {
				original_name: "",
				save_name: "",
			},
			capital_requirements: {
				original_name: "",
				save_name: "",
			},
			securities: {
				original_name: "",
				save_name: "",
			},
			payment_terms: {
				original_name: "",
				save_name: "",
			},
			ratio_disclose: {
				original_name: "",
				save_name: "",
			},
			liquidity_ratio: {
				original_name: "",
				save_name: "",
			},
		},
	});

	const [enableEditing, setEnableEditing] = useState(false);
	const { success, error } = useSnackbar();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getProjectLoanApplicationFinancialInformation(projectid);
				if (httpResponseIsValid(response)) {
					setProject(response.project);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				console.log(`Error occured when fetching loan's appplication project finance data: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, projectid],
	);

	const handleSubmit = async (values) => {
		setIsLoading(true);

		try {
			const response = await updateProjectLoanApplicationFinancialInformation(projectid, values);
			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			console.log(`Error occured when updating loan's appplication project finance data: ${_error}`);
		}

		setIsLoading(false);
	};

	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: project,
		onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	});

	const { dirty } = formik;

	usePrompt("You have unsaved changes which will be lost. Are you sure you want to leave the page?", dirty);

	useEffect(() => {
		setShouldUiBeDisabled(disableAccess || !enableEditing);
	}, [disableAccess, enableEditing]);

	useEffect(() => {
		formik.resetForm({ values: project, dirty: false });
	}, [project]);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData, projectid]);

	return (
		<form onSubmit={formik.handleSubmit}>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<MainMenu
				title={t("LoanApplication.FinancialInformation.title")}
				dirty={dirty}
				project={project}
				isDeveloper={isProjectDeveloper}
				disabled={disableAccess}
				onFetch={fetchData}
				onEdit={setEnableEditing}
				onChange={onChangeProjectState}
			/>

			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "105px" }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("LoanApplication.FinancialInformation.FinancialInformation.title")}
					</Typography>
				</Grid>

				<LabelFileUpload
					fieldId="docs.breakdown_of_project"
					projectId={projectid}
					label={t("LoanApplication.FinancialInformation.FinancialInformation.doc1")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="loanApplication"
					onSuccess={() => fetchData()}
				/>

				<LabelFileUpload
					fieldId="docs.financing_plan"
					projectId={projectid}
					label={t("LoanApplication.FinancialInformation.FinancialInformation.doc2")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="loanApplication"
					onSuccess={() => fetchData()}
				/>

				<LabelFileUpload
					fieldId="docs.cashflows"
					projectId={projectid}
					label={t("LoanApplication.FinancialInformation.FinancialInformation.doc3")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="loanApplication"
					infoText={t("LoanApplication.FinancialInformation.FinancialInformation.info1")}
					onSuccess={() => fetchData()}
				/>

				<LabelFileUpload
					fieldId="docs.capital_requirements"
					projectId={projectid}
					label={t("LoanApplication.FinancialInformation.FinancialInformation.doc4")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="loanApplication"
					onSuccess={() => fetchData()}
				/>

				<LabelTextField
					id="data.irr"
					label={t("LoanApplication.FinancialInformation.FinancialInformation.irr")}
					size="large"
					placeholder="Answer"
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>

				<LabelFileUpload
					fieldId="docs.securities"
					projectId={projectid}
					label={t("LoanApplication.FinancialInformation.FinancialInformation.doc5")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="loanApplication"
					infoText={t("LoanApplication.FinancialInformation.FinancialInformation.info2")}
					onSuccess={() => fetchData()}
				/>

				<LabelFileUpload
					fieldId="docs.payment_terms"
					projectId={projectid}
					label={t("LoanApplication.FinancialInformation.FinancialInformation.doc6")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="loanApplication"
					infoText={t("LoanApplication.FinancialInformation.FinancialInformation.info3")}
					onSuccess={() => fetchData()}
				/>

				<LabelRadio
					id="data.accounts"
					label={t("LoanApplication.FinancialInformation.FinancialInformation.accounts")}
					size="large"
					options={[{ value: "true", label: "FULL" }, { value: false, label: "Simplified" }]}
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>

				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#6F96C6">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("LoanApplication.ProjectFinance.EnvisagedCommercial.title")}
					</Typography>
				</Grid>

				<LabelFileUpload
					fieldId="docs.ratio_disclose"
					projectId={projectid}
					label={t("LoanApplication.FinancialInformation.FinancialInformation.doc7")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="loanApplication"
					onSuccess={() => fetchData()}
				/>

				<LabelFileUpload
					fieldId="docs.liquidity_ratio"
					projectId={projectid}
					label={t("LoanApplication.FinancialInformation.FinancialInformation.doc8")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="loanApplication"
					onSuccess={() => fetchData()}
				/>
			</Grid>
		</form>
	);
};

export default memo(LoanApplicationFinancialInformation);
