import { memo, useEffect, useState } from "react";
import { Radio, RadioGroup, FormControlLabel, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";

const LoginRadio = ({ id, name: radioName = "radio", value, options: radioOptions = [], setFieldValue }) => {
	const [name, setName] = useState(radioName);
	const [options, setOptions] = useState(radioOptions);

	useEffect(() => {
		setName(radioName);
	}, [radioName]);

	useEffect(() => {
		setOptions(radioOptions);
	}, [radioOptions]);

	return (
		<Grid container item sx={{ width: 400, height: 45, marginLeft: "-30px" }} alignItems="center">
			<Grid item xs={12} md={5}>
				<Typography sx={{ color: "white", textAlign: "right", marginRight: "10px" }}>
					{name}
				</Typography>
			</Grid>
			<Grid item xs={12} md={7}>
				<RadioGroup
					row
					id={id}
					aria-labelledby="demo-radio-buttons-group-label"
					aria-label="Subsidies"
					name="radio-buttons-group"
					value={value}
					sx={{ justifyContent: "left", color: "white" }}
					onChange={(e) => {
						console.log(e.target.value);
						setFieldValue(id, e.target.value);
					}}
				>
					{options.map((option) => (
						<FormControlLabel
							key={option.value}
							value={option.value}
							control={(
								<Radio
									sx={{
										"&, &.Mui-checked": {
											color: "white",
										},
									}}
									size="small"
								/>
							)}
							label={option.label}
						/>
					))}
				</RadioGroup>
			</Grid>
		</Grid>
	);
};

LoginRadio.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.any.isRequired,
	options: PropTypes.array.isRequired,
	setFieldValue: PropTypes.func.isRequired,
};

export default memo(LoginRadio);
