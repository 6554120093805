import { memo, forwardRef } from "react";
import PropTypes from "prop-types";
import {
	Grid,
	Typography,
	Slide,
	Paper,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from "@mui/material";

import { ContainedButton, OutlinedButton } from "./CustomButtons";

const Transition = forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const CustomDialog = ({
	open,
	title,
	proceedText,
	declineText,
	content,
	onClose,
	onChange,
}) => (
	<Paper elevation={10}>
		<Dialog
			open={open}
			TransitionComponent={Transition}
			maxWidth="md"
			scroll="body"
			PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4", zIndex: 999 } }}
			onClose={onClose}
		>
			<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
				<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
					{title}
				</Typography>
			</DialogTitle>
			<DialogContent dividers sx={{ overflowY: "hidden" }}>
				<DialogContentText>
					<Grid item container sx={{ p: 1 }} alignItems="start" justifyContent="center">
						{content}
					</Grid>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Grid container item alignItems="center" justifyContent="space-between">
					<Grid item container sm={5} display="flex" justifyContent="start">
						<OutlinedButton
							label={declineText}
							onClick={onClose}
						/>
					</Grid>
					<Grid item sm={2} />
					<Grid item container sm={5} justifyContent="end">
						<ContainedButton
							label={proceedText}
							onClick={() => { onChange(); onClose(); }}
						/>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	</Paper>
);

CustomDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	title: PropTypes.string,
	proceedText: PropTypes.string,
	declineText: PropTypes.string,
	content: PropTypes.any.isRequired,
	onClose: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default memo(CustomDialog);
