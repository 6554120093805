/* eslint-disable react/jsx-boolean-value */
import { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getIn } from "formik";
import {
	Grid,
	Typography,
	TextField,
	Tooltip,
	Radio,
	RadioGroup,
	FormControlLabel,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

export const LabelRadioTextField = ({
	radioId,
	fieldId,
	label,
	bold = false,
	type = "text",
	titleAlign = "left",
	size: labelSize = "medium",
	radioCase: rc = "yn",
	placeholder = "",
	disabled = false,
	formik,
	infoText = "",
	typosize = "h6",
}) => {
	const [size, setSize] = useState({ label: 5, sep: 2, field: 5 });
	const [radioCase, setRadioCase] = useState({ firstOption: "Yes", secondOption: "No", radioLabel: "If yes, please specify." });

	const {
		values,
		handleChange,
		errors,
		touched,
	} = formik;

	useEffect(() => {
		switch (labelSize) {
			case "small":
				setSize({ label: 4, sep: 2, field: 6 });
				break;
			case "medium":
				setSize({ label: 5, sep: 2, field: 5 });
				break;
			case "large":
				setSize({ label: 6, sep: 2, field: 4 });
				break;
			case "flat":
				setSize({ label: 5.5, sep: 3, field: 3.5 });
				break;
			default:
				setSize({ label: 5, sep: 2, field: 5 });
		}

		switch (rc) {
			case "yn":
				setRadioCase({ firstOption: "Yes", secondOption: "No", radioLabel: "If yes, please specify" });
				break;
			case "riskBearer":
				setRadioCase({ firstOption: "Client", secondOption: "Contractor", radioLabel: "Risk Bearer" });
				break;
			case "riskPresent":
				setRadioCase({ firstOption: "Yes", secondOption: "No", radioLabel: "Risk Present" });
				break;
			case "depreciation":
				setRadioCase({ firstOption: "Straight-Line", secondOption: "Accelerated", radioLabel: "" });
				break;
			default:
				setRadioCase({ firstOption: "Yes", secondOption: "No", radioLabel: "If yes, please specify" });
		}
	}, [labelSize, rc]);

	return (
		<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems="start">
			<Grid item container xs={12} md={size.label} textAlign={titleAlign}>
				<Typography variant={typosize} fontWeight={bold ? "bold" : ""} sx={{ textAlign: "justify", color: "blue.dark" }}>
					{label}
					{infoText && (
						<Tooltip placement="bottom-start" title={infoText}>
							<InfoIcon fontSize="small" sx={{ padding: 0.2, color: "green.main" }} />
						</Tooltip>
					)}
				</Typography>
			</Grid>
			<Grid item container md={size.sep} sx={{ marginTop: "-5px" }} justifyContent="center" alignItems="center">
				<RadioGroup
					row
					id={radioId}
					name={radioId}
					value={getIn(values, radioId)}
					size="small"
					onChange={handleChange}
				>
					<FormControlLabel
						key={radioCase.secondOption}
						value="false"
						control={<Radio />}
						// disabled={disabled}
						disabled={disabled || (getIn(values, fieldId) !== "")}
						// the commented out is the original, I just need to see whether this can evolve to smth more universal.
						label={(
							<Typography variant="h7">
								{radioCase.secondOption}
							</Typography>
						)}
					/>
					<FormControlLabel
						key={radioCase.firstOption}
						value="true"
						control={<Radio />}
						disabled={disabled}
						label={(
							<Typography variant="h7">
								{radioCase.firstOption}
							</Typography>
						)}
					/>
				</RadioGroup>
				<Typography variant="h7" fontWeight="bold" sx={{ marginLeft: "20px", color: "blue.dark" }}>
					{radioCase.radioLabel}
				</Typography>
			</Grid>
			<Grid item xs={12} md={size.field}>
				<TextField
					fullWidth
					hiddenLabel
					id={fieldId}
					name={fieldId}
					type={type}
					style={{ borderRadius: "30px" }}
					size={typosize === "h8" ? "small" : "large"}
					value={getIn(values, fieldId)}
					variant="filled"
					placeholder={placeholder === "" ? "If yes, please specify." : placeholder}
					error={(getIn(touched, fieldId) && (getIn(errors, fieldId) !== ""))}
					helperText={getIn(errors, fieldId)}
					disabled={disabled || (getIn(values, radioId) === "false")}
					onChange={handleChange}
				/>
			</Grid>
		</Grid>
	);
};

LabelRadioTextField.propTypes = {
	radioId: PropTypes.string.isRequired,
	fieldId: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	bold: PropTypes.bool,
	type: PropTypes.string,
	titleAlign: PropTypes.string,
	size: PropTypes.string,
	radioCase: PropTypes.string,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	formik: PropTypes.any.isRequired,
	infoText: PropTypes.string,
	typosize: PropTypes.string,
};

export default memo(LabelRadioTextField);
