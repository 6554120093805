import { useCallback, memo, useMemo, useState, useEffect, forwardRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Typography, Grid, Divider, LinearProgress, Link as MaterialLink, Box, Slider, IconButton, Chip, ToggleButton, MenuItem, Select, FormControl, InputLabel, ToggleButtonGroup, Autocomplete, TextField, Dialog, DialogTitle, DialogContent, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Edit, Archive, Delete, Visibility } from "@mui/icons-material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import ArticleIcon from "@mui/icons-material/Article";
import PostAddIcon from "@mui/icons-material/PostAdd";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import clsx from "clsx";
import { dateOldToNew, stringAToZInsensitive } from "@iamnapo/sort";
import shallow from "zustand/shallow";

import { options } from "../utils/options";
import { isFuzzyMatch, dayjs, useSnackbar, httpResponseIsValid } from "../utils";
import Table from "../components/Table";
import useGlobalState from "../use-global-state";
import { archiveProject, deleteProject, getProjects, getUserProfile, getInvestorViews, createInvestorView, updateInvestorView, deleteInvestorView, createAdminView, updateAdminView, deleteAdminView, getAdminViews, getInvestorApprovedProjects, investorRemovePoolProject, getInvestorPoolProjects, investorRequestProject, getAllProjects, getAllApprovedProjects, getAdminProjectUsers, duplicateProject, getAllProjectPipelines, createNewPipeline, updateAdminProjectStatus } from "../api";

const Transition = forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const useStyles = makeStyles((theme) => ({
	header: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		letterSpacing: theme.spacing(0.2),
	},
	page: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
	boxBorder: {
		border: `2px solid ${theme.palette.primary.main}`,
		borderRadius: theme.spacing(2),
		backgroundColor: "white",
	},
	screenTitle: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		letterSpacing: theme.spacing(0.2),
	},
	textFieldStyle: {
		padding: "5px",
	},
	pipelineToggles: {
		"& .Mui-selected": {
			color: "#ffffff !important",
			backgroundColor: "#6F96C6 !important",
		},
		"& .MuiToggleButton-root": {
			height: "40px",
			"&:hover": {
				color: "#1d4363",
				backgroundColor: "#F0F0F0",
			},
			color: "#1d4363",
			backgroundColor: "#ffffff",
			borderRadius: "10px",
		},
	},
	toggles: {
		"& .MuiToggleButton-root": {
			height: "40px",
			"&:hover": {
				color: "#1d4363",
				backgroundColor: "#F0F0F0",
			},
			color: "#1d4363",
			backgroundColor: "#ffffff",
			borderRadius: "10px",
		},
	},
	toggles2: {
		"& .Mui-selected": {
			color: "#ffffff !important",
			backgroundColor: "#6F96C6 !important",
		},
		"& .MuiToggleButton-root": {
			height: "40px",
			"&:hover": {
				color: "#1d4363",
				backgroundColor: "#F0F0F0",
			},
			color: "#1d4363",
			backgroundColor: "#ffffff",
			borderRadius: "10px",
		},
	},
}));

const projectSectors = {
	residential: "Residential",
	tertiary: "Tertiary",
	agriculture: "Agriculture",
	industry: "Industry",
	powerGrid: "Power Grid",
	manufacturing: "Manufacturing",
};

const projectTaxonomies = {
	buildingFabric: "Building Fabric Measure",
	electricPowerSystem: "Electric Power System Measure",
	hvac: "HVAC And Hot Water Measure",
	lighting: "Lighting Measure",
	management: "Management Measure",
	refrigeration: "Refrigeration Systems Measure",
	renewable: "Renewable Generation Measure",
	heating: "Heating And Cooling Control Measure",
	other: "Other",
};

const projectStatus = {
	"In Progress": "In Progress",
	Submitted: "Submitted",
	Approved: "Approved",
	Rejected: "Rejected",
	Archived: "Archived",
	Requested: "Requested",
	Introduced: "Introduced",
};

const userRoleValueToName = (value) => {
	switch (value) {
		case "ProjectDeveloper":
			return "Project Developer";
		case "MaterialExpert":
			return "Material Expert";
		case "ToolExpert":
			return "Tool Expert";
		default:
			break;
	}

	return "Unknown";
};

const AccessTypes = {
	contract: "Contract",
	financial_assessment: "Financial",
	risk_assessment_protocol: "Risk",
	loan_application: "Loan",
	kyc: "KYC",
	esg: "ESG",
};

const emptyView = {
	id: 1,
	name: "",
	description: "",
	filters: {
		name: "",
		status: "In Progress",
		projectSize: [0, 100],
		country: "",
		technology: "",
		portfolioSize: [0, 100],
		projectTerm: 0,
		sector: "",
		subsidies: "yes",
		type: "yes",
	},
};

const emptyFilters = {
	name: false,
	status: false,
	projectSize: false,
	country: false,
	technology: false,
	portfolioSize: false,
	projectTerm: false,
	sector: false,
	subsidies: false,
	type: false,
};

const Projects = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [dialogLoading, setDialogLoading] = useState(false);
	const [projects, setProjects] = useState([]);
	const [deleteModal, setDeleteModal] = useState(null);
	const [rejectModal, setRejectModal] = useState(null);
	const [summaryModal, setSummaryModal] = useState(null);
	const [addProjectToPoolModal, setAddProjectToPoolModal] = useState(null);
	const [removeProjectFromPoolModal, setRemoveProjectFromPoolModal] = useState(null);
	const [viewProjectUsers, setViewProjectUsers] = useState(false);
	const [projectUsers, setProjectUsers] = useState({});
	const [selectedProject, setSelectedProject] = useState({});
	const [userRole, setUserRole] = useState("");
	const [companyId, setCompanyId] = useState("");
	const [pipelineAction, setPipelineAction] = useState("assign");
	const [pipelineDialogOpen, setPipelineDialogOpen] = useState(false);
	const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(null);
	const [projectState, setProjectState] = useState("");
	const [duplicateData, setDuplicateData] = useState({
		name: "",
		user_action: "all",
		project_id: "",
	});
	const [decideStatusAction, setDecideStatusAction] = useState("submitted");
	const [decideStatusDialogOpen, setDecideStatusDialogOpen] = useState(false);
	const [pipelines, setPipelines] = useState([]);
	const [newPipeline, setNewPipeline] = useState({ name: "", id: "" });
	const [archiveModal, setArchiveModal] = useState(null);

	const [selectedViewIndex, setSelectedViewIndex] = useState(0);

	const [viewToCreate, setViewToCreate] = useState({});
	const [previousEditView, setPreviousEditView] = useState({});
	const [previousSelectedFilters, setPreviousSelectedFilters] = useState({});
	const [projectViews, setProjectViews] = useState([]);
	const [selectedProjectView, setSelectedProjectView] = useState({
		id: 1,
		name: "",
		description: "",
		filters: {
			name: "",
			status: "In Progress",
			projectSize: [0, 100],
			country: "",
			technology: "",
			portfolioSize: [0, 100],
			projectTerm: 0,
			sector: "",
			subsidies: "yes",
			type: "yes",
		},
	});

	const [selectedFilters, setSelectedFilters] = useState({
		projectName: true,
		status: true,
		projectSize: true,
		country: true,
		technology: true,
		portfolioSize: true,
		projectTerm: true,
		sector: true,
		subsidies: true,
		type: true,
	});

	const [tempSelectedFilters, setTempSelectedFilters] = useState({
		projectName: true,
		status: true,
		projectSize: true,
		country: true,
		technology: true,
		portfolioSize: true,
		projectTerm: true,
		sector: true,
		subsidies: true,
		type: true,
	});

	const [viewErrors, setViewErrors] = useState({ filters: {} });

	const [filtersModal, setFiltersModal] = useState(null);

	const [projectViewDialogState, setProjectViewDialogState] = useState("close");

	const { setName } = useGlobalState(useCallback((e) => ({
		setName: e.setName,
	}), []), shallow);
	const navigate = useNavigate();
	const { error, success, info } = useSnackbar();

	const onChangeProjectView = (name, value, state) => {
		if (state === "create") {
			setViewToCreate({
				...viewToCreate,
				[name]: value,
			});
		} else {
			setSelectedProjectView({
				...selectedProjectView,
				[name]: value,
			});
		}

		const tmpError = { ...viewErrors };

		if (value) {
			delete viewErrors.name;
			setViewErrors(tmpError);
		}
	};

	const onChangeProjectViewFilter = (name, value, state) => {
		if (state === "create") {
			setViewToCreate({
				...viewToCreate,
				filters: {
					...viewToCreate.filters,
					[name]: value,
				},
			});
		} else {
			setSelectedProjectView({
				...selectedProjectView,
				filters: {
					...selectedProjectView.filters,
					[name]: value,
				},
			});
		}

		const tmpError = { ...viewErrors };

		if (value) {
			delete viewErrors.filters.name;
			setViewErrors(tmpError);
		}
	};

	const isProjectDeveloper = useCallback((project) => {
		const projectToCheck = projects.find((p) => (
			p.name.id === project.id
		));

		return (projectToCheck.role === "ProjectDeveloper");
	}, [projects]);

	const editAccess = useCallback((project) => {
		if (userRole === "admin") {
			return false;
		}

		const projectToCheck = projects.find((p) => (
			p.name.id === project.id
		));

		return (projectToCheck.role !== "MaterialExpert");
	}, [projects]);

	const openDuplicateDialog = useCallback(
		(projectId) => {
			const project = projects.find((p) => p.name.id === projectId);

			const projectName = `${project.name.name}_copy`;

			setDuplicateData({
				name: projectName,
				project_id: projectId,
				user_action: "all",
			});

			setDuplicateDialogOpen(projectName);
		}, [projects],
	);

	const onSelectPipelineAction = (e) => {
		setPipelineAction(e.target.value);
	};

	const onSelectProjectStatus = (e) => {
		setDecideStatusAction(e.target.value);
	};

	const onOpenPipelineDialog = useCallback(
		async (projectId) => {
			setIsLoading(true);

			try {
				const response = await getAllProjectPipelines();
				if (httpResponseIsValid(response)) {
					setPipelines(response?.pipelines);
				} else {
					info(response.message);
				}
			} catch (_error) {
				error(`Error occured while fetching user's projects: ${_error}`);
			}

			const project = projects.find((p) => p.name.id === projectId);

			setNewPipeline({ name: "", id: projectId });
			setSelectedProject(project);
			setPipelineAction("assign");
			setPipelineDialogOpen(true);

			setIsLoading(false);
		}, [error, info, projects],
	);

	useEffect(() => {
		(async () => {
			setIsLoading(false);

			try {
				const response = await getUserProfile();
				if (httpResponseIsValid(response)) {
					setUserRole(response?.role);
					setCompanyId(response?.company_id);
				}
			} catch (_error) {
				error(`Error occured while fetching user's profile: ${_error}`);
			}

			setIsLoading(false);
		})();
	}, [error]);

	const fetchProjects = useCallback(
		async (state = "all", viewIndex = 0) => {
			setIsLoading(true);

			try {
				let response = { http_status: 200, projects: [] };

				if (userRole === "admin" || userRole === "investor_owner" || userRole === "investor_member") {
					response = (userRole === "admin") ? await getAdminViews() : await getInvestorViews();

					if (response.views) {
						response.views.unshift({
							_id: 0,
							name: "All",
							description: "This is the default admin View",
							filters: {},
						});
					} else {
						response.views = [];
						response.views[0] = {
							_id: 0,
							name: "All",
							description: "This is the default admin View",
							filters: {},
						};
					}

					if (httpResponseIsValid(response)) {
						setProjectViews(response.views);
					} else {
						info(response.message);
					}

					if (response.views.length > 0) {
						setSelectedProjectView(response.views[viewIndex]);
					}
				}

				switch (userRole) {
					case "admin":
						response = (state === "all" ? await getAllProjects(response.views[viewIndex]) : await getAllApprovedProjects(response.views[viewIndex]));
						break;
					case "investor_owner":
					case "investor_member":
						response = (state === "project-pool" ? await getInvestorPoolProjects(response.views[viewIndex]) : await getInvestorApprovedProjects(response.views[viewIndex]));
						break;
					case "member":
					case "owner":
						response = await getProjects();
						break;
					default:
						break;
				}

				if (httpResponseIsValid(response)) {
					setProjects(response.projects);
				} else {
					info(response.message);
				}
			} catch (_error) {
				error(`Error occured while fetching user's projects: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, info, userRole],
	);

	const onDuplicateProject = async () => {
		setIsLoading(true);

		const projectId = duplicateData.project_id;

		try {
			const response = await duplicateProject(projectId, duplicateData);
			if (httpResponseIsValid(response)) {
				success(response?.message);
				navigate(`/project/${response?.project_id}`);
			} else {
				error(response?.message);
			}
		} catch (_error) {
			error(`Error occured while duplicating project: ${_error}`);
		}

		setDuplicateDialogOpen(null);

		setIsLoading(false);
	};

	const onAssignPipeline = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await createNewPipeline(newPipeline.id, { pipeline: newPipeline.name });
				if (httpResponseIsValid(response)) {
					fetchProjects();
				} else {
					info(response.message);
				}
			} catch (_error) {
				error(`Error occured while creating new pipeline: ${_error}`);
			}

			setPipelineDialogOpen(false);
			setIsLoading(false);
		}, [newPipeline, fetchProjects, error, info],
	);

	const onDelete = useCallback(
		async (projectId) => {
			setIsLoading(true);

			try {
				const response = await deleteProject(projectId);
				if (httpResponseIsValid(response)) {
					success(response?.message);
					await fetchProjects();
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured while deleting user's project: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, success, fetchProjects],
	);

	const onArchive = useCallback(
		async (projectId) => {
			setIsLoading(true);

			try {
				const response = await archiveProject(projectId);
				if (httpResponseIsValid(response)) {
					success(response?.message);
					await fetchProjects();
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured while deleting user's project: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, success, fetchProjects],
	);

	useEffect(() => {
		(async () => {
			try {
				const state = window.location.pathname.split("/")[2] || "all";
				setProjects([]);
				setProjectState(state);

				await fetchProjects(state, selectedViewIndex);
			} catch { /* empty */ }
		})();
	}, [error, fetchProjects, window.location.pathname]);

	const onEditViewProjectUsers = useCallback(
		async (value) => {
			setDialogLoading(true);

			if (userRole === "admin") {
				const currentProject = projects.find((p) => p.name.name === value.name);

				setSelectedProject(currentProject);
				setViewProjectUsers(true);

				try {
					const response = await getAdminProjectUsers(currentProject.name.id);
					if (httpResponseIsValid(response)) {
						setProjectUsers(response);
					} else {
						error(response?.message);
					}
				} catch (_error) {
					console.log(`Error occured when admin requested users of a specific project: ${_error}`);
				}
			} else {
				setName(value.name);
				navigate(`/project/${value.id}/users`);
			}

			setDialogLoading(false);
		}, [navigate, projects, setName, userRole, error],
	);

	const onOpenProjectStatusDialog = useCallback(
		(value) => {
			setDialogLoading(true);

			const currentProject = projects.find((p) => p.name.name === value.name);

			setSelectedProject(currentProject);
			setDecideStatusAction("submitted");
			setDecideStatusDialogOpen(true);

			setDialogLoading(false);
		}, [projects],
	);

	const onDecideProjectStatus = async () => {
		setDialogLoading(true);

		try {
			const response = await updateAdminProjectStatus(selectedProject.name.id, { status: decideStatusAction });
			if (httpResponseIsValid(response)) {
				fetchProjects();
				setDecideStatusDialogOpen(false);
				success(response?.message);
			} else {
				error(response?.message);
			}
		} catch (_error) {
			console.log(`Error occured while admin updating project's status: ${_error}`);
		}

		setDialogLoading(false);
	};

	const onOpenProjectSummary = useCallback(
		(projectId) => {
			setDialogLoading(true);

			const projectToViewSummary = projects.find((p) => p.name.id === projectId);

			setSelectedProject(projectToViewSummary);
			setSummaryModal(projectId);

			setDialogLoading(false);
		}, [projects],
	);

	const onOpenRejectDialog = useCallback(
		(projectId) => {
			setDialogLoading(true);

			const projectToReject = projects.find((p) => p.name.id === projectId);

			setSelectedProject(projectToReject);
			setDecideStatusAction("reject");
			setRejectModal(projectId);

			setDialogLoading(false);
		}, [projects],
	);

	const onCreateProjectDialogView = () => {
		setIsLoading(true);

		setViewErrors({ filters: {} });
		setViewToCreate({ ...emptyView });
		setSelectedFilters({ ...emptyFilters });
		setProjectViewDialogState("create");

		setIsLoading(false);
	};

	const onEditProjectDialogView = () => {
		setIsLoading(true);

		const newSelectedFilters = { ...emptyFilters };
		for (const filterName of Object.keys(selectedProjectView.filters)) {
			if (filterName !== "portfolioSize" && filterName !== "projectSize") {
				newSelectedFilters[filterName] = true;
			} else if (selectedProjectView.filters[filterName].length > 0) {
				newSelectedFilters[filterName] = true;
			}
		}

		setPreviousEditView(JSON.parse(JSON.stringify(selectedProjectView)));
		setPreviousSelectedFilters(JSON.parse(JSON.stringify(selectedFilters)));
		setViewErrors({ filters: {} });
		setSelectedFilters(newSelectedFilters);
		setProjectViewDialogState("edit");

		setIsLoading(false);
	};

	const onDeleteProjectDialogView = () => {
		setIsLoading(true);

		setProjectViewDialogState("delete");

		setIsLoading(false);
	};

	const onCloseProjectViewDialog = () => {
		if (projectViewDialogState === "edit") {
			setSelectedProjectView({ ...previousEditView });
			setSelectedFilters({ ...previousSelectedFilters });
		}

		setProjectViewDialogState("close");
	};

	const onRequestProject = useCallback(
		async () => {
			setDialogLoading(true);

			try {
				const response = await investorRequestProject({ projectId: selectedProject.name.id });
				if (httpResponseIsValid(response)) {
					fetchProjects();
					setDecideStatusDialogOpen(false);
					success(response?.message);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				console.log(`Error occured while admin updating project's status: ${_error}`);
			}

			setDialogLoading(false);
		}, [error, fetchProjects, selectedProject, success],
	);

	const onRemoveProjectFromPool = useCallback(
		async () => {
			setDialogLoading(true);

			try {
				const response = await investorRemovePoolProject({ projectId: selectedProject.name.id });
				if (httpResponseIsValid(response)) {
					fetchProjects(projectState);
					success(response?.message);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				console.log(`Error occured while admin updating project's status: ${_error}`);
			}

			setDialogLoading(false);
		}, [error, fetchProjects, selectedProject, success],
	);

	const onAddToProjectPool = useCallback(
		(projectId) => {
			setDialogLoading(true);

			const projectToAddToPool = projects.find((p) => p.name.id === projectId);

			setSelectedProject(projectToAddToPool);
			setAddProjectToPoolModal(projectId);

			setDialogLoading(false);
		}, [projects],
	);

	const isProjectIntroduced = (value) => {
		const tmpProject = projects.find((p) => p.name.id === value);

		return (tmpProject?.status === "Introduced");
	};

	const onRemoveToProjectPool = useCallback(
		(projectId) => {
			setDialogLoading(true);

			const projectToAddToPool = projects.find((p) => p.name.id === projectId);

			setSelectedProject(projectToAddToPool);
			setRemoveProjectFromPoolModal(projectId);

			setDialogLoading(false);
		}, [projects],
	);

	const onCloneViewProjectUsers = () => {
		setViewProjectUsers(false);
		setProjectUsers({
			members: [],
		});
	};

	const userRoleValueToDescription = useCallback(
		(value) => {
			const user = projectUsers.members.find((u) => u.user_id === value);

			switch (user.role) {
				case "ProjectDeveloper":
					return (
						<Grid container alignItems="center" justifyContent="center">
							<Chip sx={{ margin: "2px", borderRadius: "10px", backgroundColor: "white", borderColor: "black" }} label="Project" variant="outlined" />
						</Grid>
					);
				case "MaterialExpert":
					return (
						<Grid container alignItems="center" justifyContent="center">
							<Chip sx={{ margin: "2px", borderRadius: "10px", backgroundColor: "white", borderColor: "black" }} label="None" variant="outlined" />
						</Grid>
					);
				case "ToolExpert":
					return (
						<Grid container alignItems="center" justifyContent="center">
							{user.access.map((a, index) => (
								<Chip key={index} sx={{ margin: "2px", borderRadius: "10px", backgroundColor: "white", borderColor: "black" }} label={AccessTypes[a]} variant="outlined" />
							))}
						</Grid>
					);
				default:
					break;
			}

			return "Unknown";
		},
		[projectUsers.members],
	);

	const classes = useStyles();

	const contractorTableColumns = useMemo(() => [
		{
			Header: <Typography id="name_header" variant="h6">{"Project"}</Typography>,
			accessor: "name",
			id: "name",
			filterable: true,
			minWidth: 200,
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1.name, value2.name),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id].name, value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<MaterialLink
						underline="none"
						component={Link}
						to={`/project/${value.id}`}
						onClick={() => setName(value.name)}
					>
						{value.name}
					</MaterialLink>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Status"}</Typography>,
			accessor: "status",
			id: "status",
			filterable: true,
			minWidth: 120,
			maxWidth: 380,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography variant="h6" color={(value === "Approved") ? "green.main" : ((value === "Rejected") ? "error.main" : "primary.main")}>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Created"}</Typography>,
			accessor: "createdAt",
			id: "createdAt",
			minWidth: 120,
			maxWidth: 380,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => dateOldToNew((v) => new Date(v))(value1, value2),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{dayjs(value).format("DD/MM/YYYY")}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Role in project"}</Typography>,
			accessor: "role",
			id: "role",
			filterable: true,
			minWidth: 180,
			maxWidth: 380,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{userRoleValueToName(value)}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Pipeline"}</Typography>,
			accessor: "pipeline",
			id: "pipeline",
			filterable: true,
			minWidth: 210,
			maxWidth: 380,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value || "None"}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Actions"}</Typography>,
			id: "actions",
			accessor: "name",
			width: 300,
			Cell: ({ value }) => (
				<Grid container item direction="row" justifyContent="space-around" alignItems="center">
					<ToggleButton
						value="edit info"
						title={`${editAccess(value) ? "Edit" : "View"} Project`}
						aria-label="edit info"
						size="small"
						sx={{ borderColor: "primary.main" }}
						onClick={() => navigate(`/project/${value.id}`)}
					>
						{editAccess(value) ? <Edit color="primary" /> : <Visibility color="primary" />}
					</ToggleButton>
					{isProjectDeveloper(value)
						&& (
							<ToggleButton
								value="edit users"
								title={`${userRole === "owner" ? "Edit" : "View"} users`}
								aria-label="edit users"
								size="small"
								sx={{ borderColor: "primary.main" }}
								onClick={() => onEditViewProjectUsers(value)}
							>
								<ManageAccountsIcon color="primary" />
							</ToggleButton>
						)}
					{isProjectDeveloper(value)
						&& (
							<ToggleButton
								value="pipeline"
								title="Pipeline"
								aria-label="pipeline"
								size="small"
								sx={{ borderColor: "primary.main" }}
								onClick={() => onOpenPipelineDialog(value.id)}
							>
								<AccountTreeIcon color="primary" />
							</ToggleButton>
						)}
					{isProjectDeveloper(value)
						&& (
							<ToggleButton
								value="duplicate"
								title="Duplicate"
								aria-label="duplicate"
								size="small"
								sx={{ borderColor: "primary.main" }}
								onClick={() => openDuplicateDialog(value.id)}
							>
								<FolderCopyIcon color="primary" />
							</ToggleButton>
						)}
					{isProjectDeveloper(value)
						&& (
							<ToggleButton
								value="archive"
								title="Archive"
								aria-label="archive"
								size="small"
								sx={{ borderColor: "primary.main" }}
								onClick={() => setArchiveModal(value.id)}
							>
								<Archive color="primary" />
							</ToggleButton>
						)}
					{isProjectDeveloper(value)
						&& (
							<ToggleButton
								value="delete"
								title="Delete"
								aria-label="delete"
								size="small"
								sx={{ borderColor: "primary.main" }}
								onClick={() => setDeleteModal(value.id)}
							>
								<Delete color="error" />
							</ToggleButton>
						)}
				</Grid>
			),
		},
	], [
		editAccess,
		isProjectDeveloper,
		navigate,
		onEditViewProjectUsers,
		onOpenPipelineDialog,
		openDuplicateDialog,
		setName,
		userRole,
	]);

	const adminTableColumns = useMemo(() => [
		{
			Header: <Typography id="name_header" variant="h6">{"Project"}</Typography>,
			accessor: "name",
			id: "name",
			filterable: true,
			minWidth: 200,
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1.name, value2.name),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id].name, value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<MaterialLink
						underline="none"
						component={Link}
						to={`/project/${value.id}`}
						onClick={() => setName(value.name)}
					>
						{value.name}
					</MaterialLink>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Status"}</Typography>,
			accessor: "status",
			id: "status",
			filterable: true,
			minWidth: 120,
			maxWidth: 160,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography variant="h6" color={(value === "Approved") ? "green.main" : ((value === "Rejected") ? "error.main" : "primary.main")}>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Created"}</Typography>,
			accessor: "createdAt",
			id: "createdAt",
			minWidth: 120,
			maxWidth: 160,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => dateOldToNew((v) => new Date(v))(value1, value2),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{dayjs(value).format("DD/MM/YYYY")}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Project Developer"}</Typography>,
			accessor: "projectCreator",
			id: "projectCreator",
			filterable: true,
			minWidth: 150,
			style: { overflowWrap: "wrap" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Pipeline"}</Typography>,
			accessor: "pipeline",
			id: "pipeline",
			filterable: true,
			minWidth: 120,
			maxWidth: 160,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value || "None"}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Actions"}</Typography>,
			id: "actions",
			accessor: "name",
			width: 260,
			Cell: ({ value }) => (
				<Grid container item direction="row" justifyContent="space-around" alignItems="center">
					{projectState === "approved"
						&& (
							<ToggleButton
								value="view info"
								title="View Project Summary"
								aria-label="view info"
								size="small"
								sx={{ borderColor: "primary.main" }}
								onClick={() => onOpenProjectSummary(value.id)}
							>
								{editAccess(value) ? <Edit color="primary" /> : <Visibility color="primary" />}
							</ToggleButton>
						)}
					<ToggleButton
						value="view info"
						title="View Full Project"
						aria-label="view info"
						size="small"
						sx={{ borderColor: "primary.main" }}
						onClick={() => navigate(`/project/${value.id}`)}
					>
						{editAccess(value) ? <Edit color="primary" /> : <Visibility color="primary" />}
					</ToggleButton>
					<ToggleButton
						value="view users"
						title="View Users"
						aria-label="view users"
						size="small"
						sx={{ borderColor: "primary.main" }}
						onClick={() => onEditViewProjectUsers(value)}
					>
						<ManageAccountsIcon color="primary" />
					</ToggleButton>
					<ToggleButton
						value="Review Project"
						title={`${projectState === "approved" ? "Reject" : "Review"} Project`}
						aria-label="edit users"
						size="small"
						sx={{ borderColor: "primary.main" }}
						onClick={() => (projectState === "approved" ? onOpenRejectDialog(value.id) : onOpenProjectStatusDialog(value))}
					>
						<ArticleIcon color={projectState === "approved" ? "error" : "primary"} />
					</ToggleButton>
				</Grid>
			),
		},
	], [setName,
		editAccess,
		navigate,
		projectState,
		onOpenRejectDialog,
		onOpenProjectSummary,
		onEditViewProjectUsers,
		onOpenProjectStatusDialog,
	]);

	const investorTableColumns = useMemo(() => [
		{
			Header: <Typography id="name_header" variant="h6">{"Anonymized Project"}</Typography>,
			accessor: "name",
			id: "name",
			filterable: true,
			minWidth: 200,
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1.name, value2.name),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id].name, value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<MaterialLink
						underline="none"
						component={Link}
						to={`/project/${value.id}`}
						onClick={() => setName(value.name)}
					>
						{value.name}
					</MaterialLink>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Country"}</Typography>,
			accessor: "country",
			id: "country",
			filterable: true,
			minWidth: 120,
			maxWidth: 380,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Technology"}</Typography>,
			accessor: "technology",
			id: "technology",
			minWidth: 140,
			maxWidth: 380,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => dateOldToNew((v) => new Date(v))(value1, value2),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Project Size (€)"}</Typography>,
			accessor: "projectSize",
			id: "projectSize",
			filterable: true,
			minWidth: 120,
			maxWidth: 380,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{`${value} €`}</Typography>

				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Term (Years)"}</Typography>,
			accessor: "term",
			id: "term",
			filterable: true,
			minWidth: 120,
			maxWidth: 380,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		// {
		// 	Header: <Typography variant="h6">{"Status"}</Typography>,
		// 	accessor: "status",
		// 	id: "status",
		// 	minWidth: 110,
		// 	maxWidth: 250,
		// 	style: { overflow: "visible" },
		// 	// eslint-disable-next-line react/prop-types
		// 	Cell: ({ value }) => (
		// 		<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
		// 			<Typography>{`${value}`}</Typography>
		// 		</Box>
		// 	),
		// },
		{
			Header: <Typography variant="h6">{"Actions"}</Typography>,
			id: "actions",
			accessor: "name",
			width: 200,
			Cell: ({ value }) => (
				<Grid container item direction="row" justifyContent="space-around" alignItems="center">
					<ToggleButton
						value="view-info"
						title="View Project"
						aria-label="view-info"
						size="small"
						sx={{ borderColor: "primary.main" }}
						onClick={() => onOpenProjectSummary(value.id)}
					>
						<Visibility color="primary" />
					</ToggleButton>
					<ToggleButton
						value="add-to-pool"
						title="Add to Project Pool"
						aria-label="add-to-pool"
						size="small"
						sx={{ borderColor: "primary.main" }}
						onClick={() => onAddToProjectPool(value.id)}
					>
						<PostAddIcon color="primary" />
					</ToggleButton>
				</Grid>
			),
		},
	], [setName,
		onOpenProjectSummary,
		onAddToProjectPool,
	]);

	const [filterValues, setFilterValues] = useState({});

	const toggleFilterOption = (option) => {
		// if (projectViewDialogState === "create") {
		// 	const tmpView = { ...viewToCreate };

		// 	if (tempSelectedFilters[option]) {
		// 		delete tmpView.filters[option];
		// 	} else {
		// 		tmpView.filters[option] = emptyView.filters[option];
		// 	}

		// 	setViewToCreate(tmpView);
		// } else if (projectViewDialogState === "edit") {
		// 	const tmpView = { ...selectedProjectView };

		// 	if (tempSelectedFilters[option]) {
		// 		delete tmpView.filters[option];
		// 	} else {
		// 		tmpView.filters[option] = emptyView.filters[option];
		// 	}

		// 	setSelectedProjectView(tmpView);
		// }

		setTempSelectedFilters({
			...tempSelectedFilters,
			[option]: !tempSelectedFilters[option],
		});

		const tmpErrors = viewErrors;

		delete tmpErrors.nofilter;
		setViewErrors(tmpErrors);
	};

	const onCreateProjectView = async () => {
		setIsLoading(true);

		try {
			// check filter view
			const tmpViewErrors = { filters: {} };
			if (viewToCreate.name === "") {
				tmpViewErrors.name = "Error: View [Name] cannot be empty.";
			}

			if (viewToCreate.description === "") {
				tmpViewErrors.description = "Error: View [Description] cannot be empty.";
			}

			const filters = {};
			for (const filter of Object.keys(selectedFilters)) {
				if (selectedFilters[filter]) {
					filters[filter] = viewToCreate.filters[filter];

					if (viewToCreate.filters[filter] === emptyView.filters[filter]) {
						tmpViewErrors.filters[filter] = `Error: View Filter [${filter}] cannot be empty.`;
					}
				}
			}

			if (Object.keys(filters).length === 0) {
				tmpViewErrors.nofilter = "There must be at least one filter selected";
			}

			// eslint-disable-next-line max-len
			if (Object.keys(tmpViewErrors).length > 1 || Object.keys(tmpViewErrors.filters).length > 0) {
				setViewErrors({ ...tmpViewErrors });
				setIsLoading(false);
				return;
			}

			setViewErrors({});

			const response = (userRole === "admin") ? await createAdminView({
				name: viewToCreate.name,
				description: viewToCreate.description,
				filters,
			}) : await createInvestorView({
				name: viewToCreate.name,
				description: viewToCreate.description,
				filters,
			});

			if (httpResponseIsValid(response)) {
				setSelectedViewIndex(projectViews.length);
				success(response?.message);
				fetchProjects(projectState, projectViews.length);
			} else {
				error(response?.message);
			}

			setProjectViewDialogState("close");
		} catch (_error) {
			console.log("Error occured while creating a new admin view:", _error);
		}

		setIsLoading(false);
	};

	const onChangeSelectedProjectView = async (viewName) => {
		const viewIndex = projectViews.findIndex((v) => v.name === viewName);

		if (viewIndex !== -1) {
			setSelectedViewIndex(viewIndex);
			setSelectedProjectView(projectViews[viewIndex]);

			await fetchProjects(projectState, viewIndex);
		}
	};

	const openFilterSelection = () => {
		setIsLoading(true);

		setTempSelectedFilters(selectedFilters);
		setFiltersModal("open");

		setIsLoading(false);
	};

	const onDeleteProjectView = async () => {
		setIsLoading(true);

		try {
			const deletingIndex = projectViews.findIndex((v) => v._id === selectedProjectView._id);

			const response = await (userRole === "admin" ? deleteAdminView({
				id: selectedProjectView._id,
			}) : deleteInvestorView({
				id: selectedProjectView._id,
			}));

			if (httpResponseIsValid(response)) {
				success(response?.message);
				fetchProjects(projectState, ((deletingIndex - 1) || 0));
			} else {
				error(response?.message);
			}
		} catch (_error) {
			console.log("Error occured while deleting an admin view:", _error);
		}

		setProjectViewDialogState("close");

		setIsLoading(false);
	};

	const onUpdateProjectView = async () => {
		setIsLoading(true);

		try {
			// check filter view
			const tmpViewErrors = { filters: {} };
			if (selectedProjectView.name === "") {
				tmpViewErrors.name = "Error: View [Name] cannot be empty.";
			}

			if (selectedProjectView.description === "") {
				tmpViewErrors.description = "Error: View [Description] cannot be empty.";
			}

			const filters = {};
			for (const filter of Object.keys(selectedFilters)) {
				if (selectedFilters[filter]) {
					filters[filter] = selectedProjectView.filters[filter];

					if (selectedProjectView.filters[filter] === emptyView.filters[filter]) {
						tmpViewErrors.filters[filter] = `Error: View Filter [${filter}] cannot be empty.`;
					}
				}
			}

			if (Object.keys(filters).length === 0) {
				tmpViewErrors.nofilter = "There must be at least one filter selected";
			}

			// eslint-disable-next-line max-len
			if (Object.keys(tmpViewErrors).length > 1 || Object.keys(tmpViewErrors.filters).length > 0) {
				setViewErrors({ ...tmpViewErrors });
				setIsLoading(false);
				return;
			}

			setViewErrors({});

			const response = (userRole === "admin") ? await updateAdminView({
				_id: selectedProjectView._id,
				name: selectedProjectView.name,
				description: selectedProjectView.description,
				filters,
			}) : await updateInvestorView({
				name: selectedProjectView.name,
				description: selectedProjectView.description,
				filters,
			});

			if (httpResponseIsValid(response)) {
				success(response?.message);
				fetchProjects(projectState, selectedViewIndex);
			} else {
				error(response?.message);
			}

			setProjectViewDialogState("close");
		} catch (_error) {
			console.log("Error occured while creating a new admin view:", _error);
		}

		setIsLoading(false);
	};

	const onSaveSelectedFilter = () => {
		setIsLoading(true);

		setSelectedFilters(tempSelectedFilters);
		setFiltersModal(null);

		setIsLoading(false);
	};

	const onCancelSelectedFilters = () => {
		setFiltersModal(null);
	};

	const noFilterIsSelected = () => Object.keys(selectedFilters).every((e) => !selectedFilters[e]);

	const adminApprovedTableColumns = useMemo(() => [
		{
			Header: <Typography id="name_header" variant="h6">{"Project"}</Typography>,
			accessor: "name",
			id: "name",
			filterable: true,
			minWidth: 200,
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1.name, value2.name),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id].name, value),
			Filter: ({ column, onChange, filter }) => (
				<input
					type="search"
					placeholder="🔎"
					value={filterValues[column.id]}
					onChange={(e) => {
						const newFilterValues = { ...filterValues };
						newFilterValues[column.id] = e.target.value;
						setFilterValues(newFilterValues);
						onChange(e.target.value);
					}}
				/>
			),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					{/* <MaterialLink
						underline="none"
						component={Link}
						to={`/project/${value.id}`}
						onClick={() => setName(value.name)}
					>
						{value.name}
					</MaterialLink> */}
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Project Developer"}</Typography>,
			accessor: "projectCreator",
			id: "projectCreator",
			filterable: true,
			minWidth: 200,
			maxWidth: 280,
			style: { flexWrap: "wrap", wordWrap: "break-word" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }} style={{ flexWrap: "wrap", wordWrap: "anywhere" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Country"}</Typography>,
			accessor: "country",
			id: "country",
			filterable: true,
			maxWidth: 200,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Technology"}</Typography>,
			accessor: "technology",
			id: "technology",
			maxWidth: 380,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => dateOldToNew((v) => new Date(v))(value1, value2),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Project Size (€)"}</Typography>,
			accessor: "projectSize",
			id: "projectSize",
			filterable: true,
			width: 140,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{`${value} €`}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Status"}</Typography>,
			accessor: "status",
			id: "status",
			filterable: true,
			width: 140,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography variant="h6" color={(value === "Introduced") ? "green.main" : ((value === "Rejected") ? "error.main" : "primary.main")}>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Actions"}</Typography>,
			id: "actions",
			accessor: "name",
			width: 250,
			Cell: ({ value }) => (
				<Grid container item direction="row" justifyContent="space-around" alignItems="center">
					{projectState === "approved"
						&& (
							<ToggleButton
								value="view info"
								title="View Project Summary"
								aria-label="view info"
								size="small"
								sx={{ borderColor: "primary.main" }}
								onClick={() => onOpenProjectSummary(value.id)}
							>
								<TextSnippetIcon color="primary" />
							</ToggleButton>
						)}
					<ToggleButton
						value="view info"
						title="View Full Project"
						aria-label="view info"
						size="small"
						sx={{ borderColor: "primary.main" }}
						onClick={() => navigate(`/project/${value.id}`)}
					>
						<Visibility color="primary" />
					</ToggleButton>
					<ToggleButton
						value="view users"
						title="View Users"
						aria-label="view users"
						size="small"
						sx={{ borderColor: "primary.main" }}
						onClick={() => onEditViewProjectUsers(value)}
					>
						<ManageAccountsIcon color="primary" />
					</ToggleButton>
					<ToggleButton
						value="Review Project"
						title={`${projectState === "approved" ? "Reject" : "Review"} Project`}
						aria-label="edit users"
						size="small"
						sx={{ borderColor: "primary.main" }}
						onClick={() => (projectState === "approved" ? onOpenRejectDialog(value.id) : onOpenProjectStatusDialog(value))}
					>
						<ArticleIcon color={projectState === "approved" ? "error" : "primary"} />
					</ToggleButton>
				</Grid>
			),
		},
	], [
		setName,
		projectState,
		onOpenProjectSummary,
		navigate,
		onEditViewProjectUsers,
		onOpenRejectDialog,
		onOpenProjectStatusDialog,
	]);

	const projectPoolTableColumns = useMemo(() => [
		{
			Header: <Typography id="name_header" variant="h6">{"Project"}</Typography>,
			accessor: "name",
			id: "name",
			filterable: true,
			minWidth: 200,
			maxWidth: 280,
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1.name, value2.name),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id].name, value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<MaterialLink
						underline="none"
						component={Link}
						to={`/project/${value.id}`}
						onClick={() => setName(value.name)}
					>
						{value.name}
					</MaterialLink>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Project Developer"}</Typography>,
			accessor: "projectCreator",
			id: "projectCreator",
			filterable: true,
			minWidth: 200,
			// maxWidth: 380,
			style: { overflowWrap: "anywhere", overflow: "visible", whiteSpace: "pre-wrap" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Country"}</Typography>,
			accessor: "country",
			id: "country",
			filterable: true,
			minWidth: 120,
			maxWidth: 380,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Technology"}</Typography>,
			accessor: "technology",
			id: "technology",
			minWidth: 150,
			maxWidth: 380,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => dateOldToNew((v) => new Date(v))(value1, value2),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Project Size (€)"}</Typography>,
			accessor: "projectSize",
			id: "projectSize",
			filterable: true,
			minWidth: 120,
			maxWidth: 380,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{`${value} €`}</Typography>

				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Status"}</Typography>,
			accessor: "status",
			id: "status",
			filterable: true,
			minWidth: 120,
			maxWidth: 380,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography variant="h6" color={(value === "Requested") ? "primary.main" : "green.main"}>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Actions"}</Typography>,
			id: "actions",
			accessor: "name",
			width: 200,
			Cell: ({ value }) => (
				<Grid container item direction="row" justifyContent="space-around" alignItems="center">
					<ToggleButton
						value="view-info"
						title="View Project Summary"
						aria-label="view-info"
						size="small"
						sx={{ borderColor: "primary.main" }}
						onClick={() => onOpenProjectSummary(value.id)}
					>
						<TextSnippetIcon color="primary" />
					</ToggleButton>
					<ToggleButton
						value="view-info"
						title="View Project in Detail"
						aria-label="view-info"
						size="small"
						sx={{ borderColor: "primary.main" }}
						disabled={!isProjectIntroduced(value.id)}
						onClick={() => navigate(`/project/${value.id}`)}
					>
						<Visibility color="primary" />
					</ToggleButton>
					<ToggleButton
						value="remove-to-pool"
						title="Remove to Project Pool"
						aria-label="remove-to-pool"
						size="small"
						sx={{ borderColor: "primary.main" }}
						onClick={() => onRemoveToProjectPool(value.id)}
					>
						<Delete color="error" />
					</ToggleButton>
				</Grid>
			),
		},
	], [setName,
		navigate,
		onOpenProjectSummary,
		isProjectIntroduced,
		onRemoveToProjectPool,
	]);

	const getProjectTableColumns = () => {
		switch (userRole) {
			case "admin":
				return projectState === "all" ? adminTableColumns : adminApprovedTableColumns;
			case "investor_owner":
			case "investor_member":
				return projectState === "project-pool" ? projectPoolTableColumns : investorTableColumns;
			case "owner":
				break;
			case "member":
				break;
			default:
				break;
		}

		return contractorTableColumns;
	};

	const userTableColumns = useMemo(() => [
		{
			Header: <Typography id="name_header" variant="h6">{"User"}</Typography>,
			accessor: "email",
			id: "email",
			filterable: true,
			width: 320,
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Role in project"}</Typography>,
			accessor: "role",
			id: "role",
			filterable: true,
			width: 200,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{userRoleValueToName(value)}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"View Access"}</Typography>,
			accessor: "user_id",
			filterable: false,
			sortable: false,
			minWidth: 400,
			maxWidth: 600,
			Cell: ({ value }) => (
				<Grid container alignItems="center" justifyContent="center">
					<Typography>{userRoleValueToDescription(value)}</Typography>
				</Grid>
			),
		},
		{
			Header: <Typography variant="h6">{"Edit Access"}</Typography>,
			accessor: "user_id",
			filterable: false,
			sortable: false,
			minWidth: 400,
			maxWidth: 600,
			Cell: ({ value }) => (
				<Grid container item sx={{ overflow: "auto", alignItems: "center", justifyContent: "center" }}>
					<Typography>{userRoleValueToDescription(value)}</Typography>
				</Grid>
			),
		},
	], [userRoleValueToDescription]);

	return (
		<>
			{isLoading && (<LinearProgress color="primary" />)}
			<section style={{ paddingTop: "1rem" }}>
				<div className={clsx("container", classes.page)}>
					<Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ "> .MuiGrid-item": { p: 0 }, background: "#F4F4F4", borderRadius: "10px 10px 10px 10px", padding: "8px" }} mb={2}>
						<Grid item hidden />
						<Grid item>
							<Typography variant="h5" display="flex" alignItems="center" className={classes.screenTitle}>
								{userRole === "admin" ? `${projectState || "ALL"} PROJECTS` : ((userRole === "investor_owner" || userRole === "investor_member") ? (`${projectState === "project-pool" ? "MY PROJECT POOL" : "AVAILABLE PROJECTS"}`) : "MY PROJECTS")}
							</Typography>
						</Grid>
						<Grid item xs={0} md={4} />
						<Grid item container className={classes.boxBorder} sx={{ p: 1 }} xs={12} md={4}>
							<Grid item xs={12} md={6}>
								<Box p={1}>
									<Grid container direction="row" justifyContent="start" alignItems="center">
										<Typography variant="h6">{"Completed: "}</Typography>
										&nbsp;
										&nbsp;
										<Typography variant="h6" color="green.main">{0}</Typography>
									</Grid>
								</Box>
							</Grid>
							<Grid item xs={12} md={6}>
								<Box p={1}>
									<Grid container direction="row" justifyContent="flex-end" alignItems="center">
										<Typography variant="h6">
											{"Today: "}
											<span style={{ marginLeft: "10px" }}>{(new Date()).toLocaleDateString("en-UK")}</span>
										</Typography>
									</Grid>
								</Box>
							</Grid>
						</Grid>
						{userRole === "owner"
							&& (
								<Grid item container justifyContent="end" sx={{ p: 1, marginTop: "15px" }} xs={12} md={12}>
									<Grid item xs={0} md={7} />
									<Grid item>
										<Button
											component={Link}
											variant="outlined"
											size="medium"
											color="primary"
											sx={{ width: "250px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c", color: "common.white" }, px: 3 }}
											disabled={!companyId}
											to="/project/new-project"
										>
											{"Create new project"}
										</Button>
									</Grid>
								</Grid>
							)}
						{(userRole === "admin" || userRole === "investor_owner" || userRole === "investor_member")
							&& (
								<Grid item container justifyContent="end" sx={{ p: 1, marginTop: "15px" }} xs={12} md={12}>
									<Grid item xs={0} md={8} />
									<Grid item container xs={4} align="left" justifyContent="space-between" alignItems="center">
										<Grid item md={7}>
											<FormControl variant="outlined">
												<InputLabel id="demo-simple-select-filled-label">{"Project Views"}</InputLabel>
												<Select
													labelId="demo-simple-select-filled-label-admin"
													id="demo-simple-select-outlined"
													value={selectedProjectView?.name}
													label="Project Views"
													sx={{ width: "250px", height: "45px", borderRadius: "15px 15px 15px 15px", backgroundColor: "white" }}
													disabled={!selectedProjectView}
													endAdornment={(
														<Box
															sx={{
																marginRight: "15px",
																display: "flex",
																alignItems: "center",
																width: "fit-content",
																"& svg": {
																	m: 1.5,
																},
																"& hr": {
																	mx: 0.5,
																},
															}}
														>
															<Grid item>
																<ToggleButton
																	value="edit-to-pool"
																	title={(selectedProjectView?._id === 0) ? "View Project View" : "Edit Project View"}
																	aria-label="remove-to-pool"
																	size="small"
																	sx={{ borderColor: "primary.main", width: "30px", height: "30px" }}
																	onClick={() => onEditProjectDialogView()}
																>
																	{((selectedProjectView?._id === 0) ? <Visibility color="primary" /> : <Edit color="primary" />)}
																</ToggleButton>
															</Grid>

															<Divider flexItem orientation="vertical" />

															<Grid item>
																<ToggleButton
																	value="remove-to-pool"
																	title="Delete Project View"
																	aria-label="remove-to-pool"
																	size="small"
																	sx={{ borderColor: "primary.main", width: "30px", height: "30px" }}
																	disabled={selectedProjectView?._id === 0}
																	onClick={() => onDeleteProjectDialogView()}
																>
																	<Delete color="error" />
																</ToggleButton>
															</Grid>
														</Box>
													)}
													onChange={(e) => onChangeSelectedProjectView(e.target.value)}
												>
													{projectViews.map((cur) => (
														<MenuItem key={cur._id} value={cur.name}>
															{cur.name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
										<Grid container item md={5} justifyContent="end">
											<Button
												variant="outlined"
												size="large"
												color="primary"
												sx={{ width: "160px", height: "35px", marginRight: "5px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c", color: "common.white" }, px: 3 }}
												onClick={() => onCreateProjectDialogView()}
											>
												{"Create Filter"}
											</Button>
										</Grid>
									</Grid>
								</Grid>
							)}
					</Grid>
					{userRole !== ""
						&& (
							<Table
								data={projects}
								noDataText={isLoading ? "Fetching projects..." : "No data available!"}
								columns={getProjectTableColumns()}
								defaultSorted={[{ id: "name", desc: true }]}
							/>
						)}
				</div>
			</section>
			{(projectViewDialogState !== "close" && projectViewDialogState !== "delete")
				&& (
					<Dialog
						keepMounted
						open={(projectViewDialogState !== "close")}
						TransitionComponent={Transition}
						scroll="body"
						PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4", width: "600px" } }}
						onClose={() => onCloseProjectViewDialog()}
					>
						<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
							<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
								{`${projectViewDialogState === "create" ? "Create" : "Edit"} Project View`}
							</Typography>
						</DialogTitle>
						<DialogContent dividers sx={{ overflowY: "hidden" }}>
							<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems="center">
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Name"}</Typography>
								</Grid>
								<Grid item md={1} />
								<Grid item xs={12} md={7}>
									<TextField
										hiddenLabel
										fullWidth
										required
										id="view-name"
										value={projectViewDialogState === "create" ? viewToCreate?.name : selectedProjectView?.name}
										variant="filled"
										type="text"
										placeholder="The name of the view"
										error={viewErrors?.name !== "" && viewErrors?.name}
										helperText={viewErrors?.name}
										disabled={selectedProjectView?.id === 0 && projectViewDialogState === "edit"}
										onChange={(e) => onChangeProjectView("name", e.target.value, projectViewDialogState)}
									/>
								</Grid>
							</Grid>
							<Grid item container sx={{ p: 1 }} alignItems="start" mb={3}>
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ marginTop: "10px", color: "#1d4363" }}>{"Description"}</Typography>
								</Grid>
								<Grid item md={1} />
								<Grid item xs={12} md={7}>
									<TextField
										hiddenLabel
										multiline
										fullWidth
										id="view-description"
										value={projectViewDialogState === "create" ? viewToCreate?.description : selectedProjectView?.description}
										variant="filled"
										type="text"
										minRows={2}
										maxRows={3}
										placeholder="The description of the view"
										disabled={selectedProjectView?.id === 0 && projectViewDialogState === "edit"}
										error={viewErrors?.description !== "" && viewErrors?.description}
										helperText={viewErrors?.description}
										onChange={(e) => onChangeProjectView("description", e.target.value, projectViewDialogState)}
									/>
								</Grid>
							</Grid>

							{(selectedProjectView?.id !== 0 || projectViewDialogState === "create")
								&& (
									<>
										<Grid item container display="flex" sm={3} md={12} justifyContent="center" alignItems="center">
											<Button
												fullWidth
												variant="contained"
												color="primary"
												onClick={() => openFilterSelection()}
											>
												{"Select Filters"}
											</Button>
										</Grid>

										<hr />

										{noFilterIsSelected()
											&& (
												<Grid item container display="flex" sm={3} md={12} justifyContent="center" alignItems="center">
													<Typography variant="h6" fontWeight={`${(viewErrors?.nofilter) ? "normal" : "bold"}`} color={`${(viewErrors?.nofilter) ? "error" : "default"}`}>{(viewErrors?.nofilter) ? viewErrors?.nofilter : "No Selected Filter"}</Typography>
												</Grid>
											)}

										{selectedFilters.name
											&& (
												<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems="center">
													<Grid item xs={12} md={4}>
														<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Project Name"}</Typography>
													</Grid>
													<Grid item md={1} />
													<Grid item xs={12} md={7}>
														<TextField
															hiddenLabel
															fullWidth
															id="view-filter-name"
															value={projectViewDialogState === "create" ? viewToCreate?.filters?.name : selectedProjectView?.filters?.name}
															variant="filled"
															type="text"
															placeholder="The project name"
															error={viewErrors?.filters?.name !== "" && viewErrors?.filters?.name}
															helperText={viewErrors?.filters?.name}
															onChange={(e) => onChangeProjectViewFilter("name", e.target.value, projectViewDialogState)}
														/>
													</Grid>
												</Grid>
											)}
										{selectedFilters.status
											&& (
												<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems="center">
													<Grid item xs={12} md={4}>
														<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Project Status"}</Typography>
													</Grid>
													<Grid item md={1} />
													<Grid item xs={12} md={7}>
														<TextField
															hiddenLabel
															fullWidth
															select
															id="view-filter-status"
															value={projectViewDialogState === "create" ? viewToCreate?.filters?.status : selectedProjectView?.filters?.status}
															variant="filled"
															placeholder="The project status"
															error={viewErrors?.filters?.status !== "" && viewErrors?.filters?.status}
															helperText={viewErrors?.filters?.status}
															onChange={(e) => onChangeProjectViewFilter("status", e.target.value, projectViewDialogState)}
														>
															{Object.keys(projectStatus).map((cur) => (
																<MenuItem key={cur} value={cur}>
																	{projectStatus[cur]}
																</MenuItem>
															))}
														</TextField>
													</Grid>
												</Grid>
											)}
										{selectedFilters.projectSize
											&& (
												<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems="center">
													<Grid item xs={12} md={4}>
														<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Project Size (€)"}</Typography>
													</Grid>
													<Grid item md={1} />
													<Grid item md={1}>
														<InputLabel sx={{ fontWeight: "bold" }}>{viewToCreate?.filters?.projectSize[0]}</InputLabel>
													</Grid>
													<Grid item xs={12} md={5}>
														<Slider
															id="view-filter-project-size"
															value={projectViewDialogState === "create" ? viewToCreate?.filters?.projectSize : selectedProjectView?.filters?.projectSize}
															valueLabelDisplay="auto"
															// error={viewErrors?.filters?.projectSize !== "" && viewErrors?.filters?.projectSize}
															// helperText={viewErrors?.filters?.projectSize}
															onChange={(e) => onChangeProjectViewFilter("projectSize", e.target.value, projectViewDialogState)}
														/>
													</Grid>
													<Grid item md={1}>
														<InputLabel sx={{ marginLeft: "15px", fontWeight: "bold" }}>{viewToCreate?.filters?.projectSize[1]}</InputLabel>
													</Grid>
												</Grid>
											)}
										{selectedFilters.country
											&& (
												<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems="center">
													<Grid item xs={12} md={4}>
														<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Country"}</Typography>
													</Grid>
													<Grid item md={1} />
													<Grid item xs={12} md={7}>
														<TextField
															hiddenLabel
															fullWidth
															select
															id="view-filter-country"
															value={projectViewDialogState === "create" ? viewToCreate?.filters?.country : selectedProjectView?.filters?.country}
															variant="filled"
															placeholder="The desired currency"
															error={viewErrors?.filters?.country !== "" && viewErrors?.filters?.country}
															helperText={viewErrors?.filters?.country}
															onChange={(e) => onChangeProjectViewFilter("country", e.target.value, projectViewDialogState)}
														>
															{Object.keys(options).map((cur) => (
																<MenuItem key={options[cur]} value={options[cur]}>
																	{options[cur]}
																</MenuItem>
															))}
														</TextField>
													</Grid>
												</Grid>
											)}
										{selectedFilters.technology
											&& (
												<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems="center">
													<Grid item xs={12} md={4}>
														<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Technology"}</Typography>
													</Grid>
													<Grid item md={1} />
													<Grid item xs={12} md={7}>
														<TextField
															hiddenLabel
															fullWidth
															select
															id="view-filter-technology"
															value={projectViewDialogState === "create" ? viewToCreate?.filters?.technology : selectedProjectView?.filters?.technology}
															variant="filled"
															placeholder="The desired currency"
															error={viewErrors?.filters?.technology !== "" && viewErrors?.filters?.technology}
															helperText={viewErrors?.filters?.technology}
															onChange={(e) => onChangeProjectViewFilter("technology", e.target.value, projectViewDialogState)}
														// error={Boolean(formErrors.technology)}
														// helperText={formErrors.technology}
														>
															{Object.keys(projectTaxonomies).map((cur) => (
																<MenuItem key={cur} value={cur}>
																	{projectTaxonomies[cur]}
																</MenuItem>
															))}
														</TextField>
													</Grid>
												</Grid>
											)}
										{selectedFilters.portfolioSize
											&& (
												<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems="center">
													<Grid item xs={12} md={4}>
														<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Portfolio Size"}</Typography>
													</Grid>
													<Grid item md={1} />
													<Grid item md={1}>
														<InputLabel sx={{ fontWeight: "bold" }}>{viewToCreate?.filters?.portfolioSize[0]}</InputLabel>
													</Grid>
													<Grid item xs={12} md={5}>
														<Slider
															id="view-filter-portfolio-size"
															value={projectViewDialogState === "create" ? viewToCreate?.filters?.portfolioSize : selectedProjectView?.filters?.portfolioSize}
															valueLabelDisplay="auto"
															onChange={(e) => onChangeProjectViewFilter("portfolioSize", e.target.value, projectViewDialogState)}
														/>
													</Grid>
													<Grid item md={1}>
														<InputLabel sx={{ marginLeft: "15px", fontWeight: "bold" }}>{viewToCreate?.filters?.portfolioSize[1]}</InputLabel>
													</Grid>
												</Grid>
											)}
										{selectedFilters.projectTerm
											&& (
												<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems="center">
													<Grid item xs={12} md={4}>
														<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Term (years)"}</Typography>
													</Grid>
													<Grid item md={1} />
													<Grid item md={1}>
														<InputLabel sx={{ fontWeight: "bold" }}>{viewToCreate?.filters?.projectTerm}</InputLabel>
													</Grid>
													<Grid item xs={12} md={5}>
														<Slider
															id="view-filter-project-term"
															defaultValue={50}
															aria-label="Default"
															valueLabelDisplay="auto"
															value={projectViewDialogState === "create" ? viewToCreate?.filters?.projectTerm : selectedProjectView?.filters?.projectTerm}
															onChange={(e) => onChangeProjectViewFilter("projectTerm", e.target.value, projectViewDialogState)}
														/>
													</Grid>
												</Grid>
											)}
										{selectedFilters.sector
											&& (
												<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems="center">
													<Grid item xs={12} md={4}>
														<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Sector"}</Typography>
													</Grid>
													<Grid item md={1} />
													<Grid item xs={12} md={7}>
														<TextField
															hiddenLabel
															fullWidth
															select
															id="view-filter-sector"
															value={projectViewDialogState === "create" ? viewToCreate?.filters?.sector : selectedProjectView?.filters?.sector}
															variant="filled"
															placeholder="The sector of the project"
															error={viewErrors?.filters?.sector !== "" && viewErrors?.filters?.sector}
															helperText={viewErrors?.filters?.sector}
															onChange={(e) => onChangeProjectViewFilter("sector", e.target.value, projectViewDialogState)}
														// error={Boolean(formErrors.sector)}
														// helperText={formErrors.sector}
														>
															{Object.keys(projectSectors).map((cur) => (
																<MenuItem key={cur} value={cur}>
																	{projectSectors[cur]}
																</MenuItem>
															))}
														</TextField>
													</Grid>
												</Grid>
											)}
										{selectedFilters.subsidies
											&& (
												<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems="center">
													<Grid item xs={12} md={4}>
														<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Subsidies"}</Typography>
													</Grid>
													<Grid item md={1} />
													<Grid item xs={12} md={7}>
														<ToggleButtonGroup
															exclusive
															id="view-filter-subsidies"
															color="primary"
															value={projectViewDialogState === "create" ? viewToCreate?.filters?.subsidies : selectedProjectView?.filters?.subsidies}
															aria-label="Platform"
															className={classes.toggles2}
															onChange={(e) => onChangeProjectViewFilter("subsidies", e.target.value, projectViewDialogState)}
														>
															<ToggleButton sx={{ width: "160px" }} value="yes">{"Yes"}</ToggleButton>
															<ToggleButton sx={{ width: "155px" }} value="no">{"No"}</ToggleButton>
														</ToggleButtonGroup>
													</Grid>
												</Grid>
											)}
										{selectedFilters.type
											&& (
												<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems="center">
													<Grid item xs={12} md={4}>
														<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Type"}</Typography>
													</Grid>
													<Grid item md={1} />
													<Grid item xs={12} md={7}>
														<ToggleButtonGroup
															exclusive
															id="view-filter-type"
															color="primary"
															value={projectViewDialogState === "create" ? viewToCreate?.filters?.type : selectedProjectView?.filters?.type}
															aria-label="Platform"
															className={classes.toggles2}
															onChange={(e) => onChangeProjectViewFilter("type", e.target.value, projectViewDialogState)}
														>
															<ToggleButton sx={{ width: "100px" }} value="yes">{"New-built"}</ToggleButton>
															<ToggleButton sx={{ width: "215px" }} value="no">{"Renovation/Refurbishment"}</ToggleButton>
														</ToggleButtonGroup>
													</Grid>
												</Grid>
											)}
										<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="25px">
											<Grid item display="flex" sm={3} justifyContent="end">
												<Button
													fullWidth
													variant="outlined"
													sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
													color="primary"
													onClick={() => onCloseProjectViewDialog()}
												>
													{"Cancel"}
												</Button>
											</Grid>
											<Grid item display="flex" sm={3} justifyContent="end">
												<Button
													fullWidth
													variant="contained"
													sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
													color="primary"
													onClick={() => (projectViewDialogState === "create" ? onCreateProjectView() : onUpdateProjectView())}
												>
													{"Save"}
												</Button>
											</Grid>
										</Grid>
									</>
								)}
							{(selectedProjectView?.id === 0 && projectViewDialogState === "edit")
								&& (
									<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="25px">
										<Grid item />
										<Grid item display="flex" sm={3} justifyContent="end">
											<Button
												fullWidth
												variant="contained"
												sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
												color="primary"
												onClick={() => onCloseProjectViewDialog()}
											>
												{"close"}
											</Button>
										</Grid>
									</Grid>
								)}
						</DialogContent>
					</Dialog>
				)}
			{
				filtersModal !== null
				&& (
					<Dialog
						keepMounted
						open={filtersModal !== null}
						TransitionComponent={Transition}
						scroll="body"
						PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4", width: "600px" } }}
						onClose={() => setFiltersModal(null)}
					>
						<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
							<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
								{"Select Filters"}
							</Typography>
						</DialogTitle>
						<DialogContent dividers sx={{ overflowY: "hidden" }}>
							<Grid item container sx={{ marginTop: "15px" }} alignItems="center">
								<Grid item md={2} />
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Project Name"}</Typography>
								</Grid>
								<Grid item md={3} />
								<Grid item container xs={12} md={1} alignItems="center">
									<IconButton aria-label="add-remove" onClick={() => toggleFilterOption("name")}>
										{!(tempSelectedFilters.name)
											&& (
												<AddBoxIcon sx={{ width: "30px", height: "30px" }} color="primary" />
											)}
										{(tempSelectedFilters.name)
											&& (
												<IndeterminateCheckBoxIcon sx={{ width: "30px", height: "30px" }} color="error" />

											)}
									</IconButton>
								</Grid>
								<Grid item md={2} />
							</Grid>

							<Grid item container alignItems="center">
								<Grid item md={2} />
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Project Status"}</Typography>
								</Grid>
								<Grid item md={3} />
								<Grid item container xs={12} md={1} alignItems="center">
									<IconButton aria-label="add-remove" onClick={() => toggleFilterOption("status")}>
										{!(tempSelectedFilters.status)
											&& (
												<AddBoxIcon sx={{ width: "30px", height: "30px" }} color="primary" />
											)}
										{(tempSelectedFilters.status)
											&& (
												<IndeterminateCheckBoxIcon sx={{ width: "30px", height: "30px" }} color="error" />

											)}
									</IconButton>
								</Grid>
								<Grid item md={2} />
							</Grid>

							<Grid item container alignItems="center">
								<Grid item md={2} />
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Project Size (€)"}</Typography>
								</Grid>
								<Grid item md={3} />
								<Grid item container xs={12} md={1} alignItems="center">
									<IconButton aria-label="add-remove" onClick={() => toggleFilterOption("projectSize")}>
										{!(tempSelectedFilters.projectSize)
											&& (
												<AddBoxIcon sx={{ width: "30px", height: "30px" }} color="primary" />
											)}
										{(tempSelectedFilters.projectSize)
											&& (
												<IndeterminateCheckBoxIcon sx={{ width: "30px", height: "30px" }} color="error" />

											)}
									</IconButton>
								</Grid>
								<Grid item md={2} />
							</Grid>
							<Grid item container alignItems="center">
								<Grid item md={2} />
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Country"}</Typography>
								</Grid>
								<Grid item md={3} />
								<Grid item container xs={12} md={1} alignItems="center">
									<IconButton aria-label="add-remove" onClick={() => toggleFilterOption("country")}>
										{!(tempSelectedFilters.country)
											&& (
												<AddBoxIcon sx={{ width: "30px", height: "30px" }} color="primary" />
											)}
										{(tempSelectedFilters.country)
											&& (
												<IndeterminateCheckBoxIcon sx={{ width: "30px", height: "30px" }} color="error" />

											)}
									</IconButton>
								</Grid>
								<Grid item md={2} />
							</Grid>
							<Grid item container alignItems="center">
								<Grid item md={2} />
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Technology"}</Typography>
								</Grid>
								<Grid item md={3} />
								<Grid item container xs={12} md={1} alignItems="center">
									<IconButton aria-label="add-remove" onClick={() => toggleFilterOption("technology")}>
										{!(tempSelectedFilters.technology)
											&& (
												<AddBoxIcon sx={{ width: "30px", height: "30px" }} color="primary" />
											)}
										{(tempSelectedFilters.technology)
											&& (
												<IndeterminateCheckBoxIcon sx={{ width: "30px", height: "30px" }} color="error" />

											)}
									</IconButton>
								</Grid>
								<Grid item md={2} />
							</Grid>
							<Grid item container alignItems="center">
								<Grid item md={2} />
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Portfolio Size"}</Typography>
								</Grid>
								<Grid item md={3} />
								<Grid item container xs={12} md={1} alignItems="center">
									<IconButton aria-label="add-remove" onClick={() => toggleFilterOption("portfolioSize")}>
										{!(tempSelectedFilters.portfolioSize)
											&& (
												<AddBoxIcon sx={{ width: "30px", height: "30px" }} color="primary" />
											)}
										{(tempSelectedFilters.portfolioSize)
											&& (
												<IndeterminateCheckBoxIcon sx={{ width: "30px", height: "30px" }} color="error" />

											)}
									</IconButton>
								</Grid>
								<Grid item md={2} />
							</Grid>
							<Grid item container alignItems="center">
								<Grid item md={2} />
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Term (years)"}</Typography>
								</Grid>
								<Grid item md={3} />
								<Grid item container xs={12} md={1} alignItems="center">
									<IconButton aria-label="add-remove" onClick={() => toggleFilterOption("projectTerm")}>
										{!(tempSelectedFilters.projectTerm)
											&& (
												<AddBoxIcon sx={{ width: "30px", height: "30px" }} color="primary" />
											)}
										{(tempSelectedFilters.projectTerm)
											&& (
												<IndeterminateCheckBoxIcon sx={{ width: "30px", height: "30px" }} color="error" />

											)}
									</IconButton>
								</Grid>
								<Grid item md={2} />
							</Grid>
							<Grid item container alignItems="center">
								<Grid item md={2} />
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Sector"}</Typography>
								</Grid>
								<Grid item md={3} />
								<Grid item container xs={12} md={1} alignItems="center">
									<IconButton aria-label="add-remove" onClick={() => toggleFilterOption("sector")}>
										{!(tempSelectedFilters.sector)
											&& (
												<AddBoxIcon sx={{ width: "30px", height: "30px" }} color="primary" />
											)}
										{(tempSelectedFilters.sector)
											&& (
												<IndeterminateCheckBoxIcon sx={{ width: "30px", height: "30px" }} color="error" />

											)}
									</IconButton>
								</Grid>
								<Grid item md={2} />
							</Grid>
							<Grid item container alignItems="center">
								<Grid item md={2} />
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Subsidies"}</Typography>
								</Grid>
								<Grid item md={3} />
								<Grid item container xs={12} md={1} alignItems="center">
									<IconButton aria-label="add-remove" onClick={() => toggleFilterOption("subsidies")}>
										{!(tempSelectedFilters.subsidies)
											&& (
												<AddBoxIcon sx={{ width: "30px", height: "30px" }} color="primary" />
											)}
										{(tempSelectedFilters.subsidies)
											&& (
												<IndeterminateCheckBoxIcon sx={{ width: "30px", height: "30px" }} color="error" />

											)}
									</IconButton>
								</Grid>
								<Grid item md={2} />
							</Grid>
							<Grid item container alignItems="center">
								<Grid item md={2} />
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Type"}</Typography>
								</Grid>
								<Grid item md={3} />
								<Grid item container xs={12} md={1} alignItems="center">
									<IconButton aria-label="add-remove" onClick={() => toggleFilterOption("type")}>
										{!(tempSelectedFilters.type)
											&& (
												<AddBoxIcon sx={{ width: "30px", height: "30px" }} color="primary" />
											)}
										{(tempSelectedFilters.type)
											&& (
												<IndeterminateCheckBoxIcon sx={{ width: "30px", height: "30px" }} color="error" />

											)}
									</IconButton>
								</Grid>
								<Grid item md={2} />
							</Grid>
							<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="25px">
								<Grid item sm={3}>
									<Button
										fullWidth
										variant="outlined"
										sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
										color="primary"
										onClick={() => onCancelSelectedFilters()}
									>
										{"Cancel"}
									</Button>
								</Grid>
								<Grid item display="flex" sm={3} justifyContent="end">
									<Button
										fullWidth
										variant="contained"
										sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
										color="primary"
										onClick={() => onSaveSelectedFilter()}
									>
										{"Save"}
									</Button>
								</Grid>
							</Grid>
						</DialogContent>
					</Dialog>
				)
			}
			{
				summaryModal !== null
				&& (
					<Dialog
						keepMounted
						open={summaryModal !== null}
						TransitionComponent={Transition}
						scroll="body"
						PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4", width: "600px" } }}
						onClose={() => setSummaryModal(null)}
					>
						<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
							<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
								{"Project Summary"}
							</Typography>
						</DialogTitle>
						<DialogContent dividers sx={{ overflowY: "hidden" }}>
							<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems="center">
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Project Name"}</Typography>
								</Grid>
								<Grid item md={4} />
								<Grid item xs={12} md={4}>
									<Typography variant="h6" align="right" sx={{ color: "#1d4363" }}>{selectedProject?.name?.name || "-"}</Typography>
								</Grid>
							</Grid>
							<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems="center">
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Project Size (€)"}</Typography>
								</Grid>
								<Grid item md={4} />
								<Grid item xs={12} md={4}>
									<Typography variant="h6" align="right" sx={{ color: "#1d4363" }}>{selectedProject?.projectSize || "-"}</Typography>
								</Grid>
							</Grid>
							<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems="center">
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Country"}</Typography>
								</Grid>
								<Grid item md={4} />
								<Grid item xs={12} md={4}>
									<Typography variant="h6" align="right" sx={{ color: "#1d4363" }}>{selectedProject?.country || "-"}</Typography>
								</Grid>
							</Grid>
							<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems="center">
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Technology"}</Typography>
								</Grid>
								<Grid item md={4} />
								<Grid item xs={12} md={4}>
									<Typography variant="h6" align="right" sx={{ color: "#1d4363" }}>{selectedProject?.technology || "-"}</Typography>
								</Grid>
							</Grid>
							<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems="center">
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Portfolio Size"}</Typography>
								</Grid>
								<Grid item md={4} />
								<Grid item xs={12} md={4}>
									<Typography variant="h6" align="right" sx={{ color: "#1d4363" }}>{selectedProject?.portfolio_size || "-"}</Typography>
								</Grid>
							</Grid>
							<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems="center">
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Term (years)"}</Typography>
								</Grid>
								<Grid item md={4} />
								<Grid item xs={12} md={4}>
									<Typography variant="h6" align="right" sx={{ color: "#1d4363" }}>{selectedProject?.term || "-"}</Typography>
								</Grid>
							</Grid>
							<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems="center">
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Sector"}</Typography>
								</Grid>
								<Grid item md={4} />
								<Grid item xs={12} md={4}>
									<Typography variant="h6" align="right" sx={{ color: "#1d4363" }}>{selectedProject?.sector || "-"}</Typography>
								</Grid>
							</Grid>
							<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems="center">
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Subsidies"}</Typography>
								</Grid>
								<Grid item md={4} />
								<Grid item xs={12} md={4}>
									<Typography variant="h6" align="right" sx={{ color: "#1d4363" }}>{selectedProject?.subsidies || "-"}</Typography>
								</Grid>
							</Grid>
							<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems="center">
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Type"}</Typography>
								</Grid>
								<Grid item md={4} />
								<Grid item xs={12} md={4}>
									<Typography variant="h6" align="right" sx={{ color: "#1d4363" }}>{selectedProject?.type || "-"}</Typography>
								</Grid>
							</Grid>
							<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="25px">
								<Grid item sm={3} />
								<Grid item display="flex" sm={3} justifyContent="end">
									<Button
										fullWidth
										variant="contained"
										sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
										color="primary"
										onClick={() => {
											setSummaryModal(null);
										}}
									>
										{"Close"}
									</Button>
								</Grid>
							</Grid>
						</DialogContent>
					</Dialog>
				)
			}
			{
				addProjectToPoolModal !== null
				&& (
					<Dialog
						keepMounted
						open={addProjectToPoolModal !== null}
						TransitionComponent={Transition}
						scroll="body"
						PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4" } }}
						onClose={() => setAddProjectToPoolModal(null)}
					>
						<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
							<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
								{"Are you sure?"}
							</Typography>
						</DialogTitle>
						<DialogContent dividers sx={{ overflowY: "hidden" }}>
							<Grid item container sx={{ p: 1, marginTop: "5px" }} mt={1} alignItems="center">
								<Typography>
									{"Are you sure you want to add the project: ["}
									&nbsp;
									<b>{selectedProject?.name?.name}</b>
									&nbsp;
									{"] to your pool and request an introduction by the admin?"}
								</Typography>
							</Grid>
							<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="25px">
								<Grid item sm={3} display="flex" justifyContent="start">
									<Button
										fullWidth
										variant="outlined"
										sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
										color="primary"
										onClick={() => setAddProjectToPoolModal(null)}
									>
										{"Cancel"}
									</Button>
								</Grid>
								<Grid item display="flex" sm={3} justifyContent="end">
									<Button
										fullWidth
										variant="contained"
										sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
										color="primary"
										onClick={() => {
											onRequestProject();
											setAddProjectToPoolModal(null);
										}}
									>
										{"Add"}
									</Button>
								</Grid>
							</Grid>
						</DialogContent>
					</Dialog>
				)
			}
			{
				removeProjectFromPoolModal !== null
				&& (
					<Dialog
						keepMounted
						open={removeProjectFromPoolModal !== null}
						TransitionComponent={Transition}
						scroll="body"
						PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4" } }}
						onClose={() => setRemoveProjectFromPoolModal(null)}
					>
						<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
							<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
								{"Are you sure?"}
							</Typography>
						</DialogTitle>
						<DialogContent dividers sx={{ overflowY: "hidden" }}>
							<Grid item container sx={{ p: 1, marginTop: "5px" }} mt={1} alignItems="center">
								<Typography>
									{"Are you sure you want to remove the project: ["}
									&nbsp;
									<b>{selectedProject?.name?.name}</b>
									&nbsp;
									{"] from your pool?"}
								</Typography>
							</Grid>
							<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="25px">
								<Grid item sm={3} display="flex" justifyContent="start">
									<Button
										fullWidth
										variant="outlined"
										sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
										color="primary"
										onClick={() => setRemoveProjectFromPoolModal(null)}
									>
										{"Cancel"}
									</Button>
								</Grid>
								<Grid item display="flex" sm={3} justifyContent="end">
									<Button
										fullWidth
										variant="contained"
										sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
										color="primary"
										onClick={() => {
											onRemoveProjectFromPool();
											setRemoveProjectFromPoolModal(null);
										}}
									>
										{"Remove"}
									</Button>
								</Grid>
							</Grid>
						</DialogContent>
					</Dialog>
				)
			}
			{
				deleteModal !== null
				&& (
					<Dialog
						keepMounted
						open={deleteModal !== null}
						TransitionComponent={Transition}
						scroll="body"
						PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4" } }}
						onClose={() => setArchiveModal(null)}
					>
						<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
							<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
								{"Are you sure?"}
							</Typography>
						</DialogTitle>
						<DialogContent dividers sx={{ overflowY: "hidden" }}>
							<Grid item container sx={{ p: 1, marginTop: "5px" }} mt={1} alignItems="center">
								{"Are you sure you want to"}
								&nbsp;
								<b>{"delete"}</b>
								&nbsp;
								{"the project"}
								&nbsp;
								<b>{projects.find((p) => p.name.id === deleteModal).name.name}</b>
								{"?"}
							</Grid>
							<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="25px">
								<Grid item sm={3} display="flex" justifyContent="start">
									<Button
										fullWidth
										variant="outlined"
										sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
										color="primary"
										onClick={() => setDeleteModal(null)}
									>
										{"Cancel"}
									</Button>
								</Grid>
								<Grid item display="flex" sm={3} justifyContent="end">
									<Button
										fullWidth
										variant="contained"
										sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
										color="primary"
										onClick={() => {
											onDelete(deleteModal);
											setDeleteModal(null);
										}}
									>
										{"Delete"}
									</Button>
								</Grid>
							</Grid>
						</DialogContent>
					</Dialog>
				)
			}
			{
				(projectViewDialogState === "delete")
				&& (
					<Dialog
						keepMounted
						open={projectViewDialogState === "delete"}
						TransitionComponent={Transition}
						scroll="body"
						PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4" } }}
						onClose={() => setProjectViewDialogState("close")}
					>
						<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
							<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
								{"Are you sure?"}
							</Typography>
						</DialogTitle>
						<DialogContent dividers sx={{ overflowY: "hidden" }}>
							<Grid item container sx={{ p: 1, marginTop: "5px" }} mt={1} alignItems="center">
								{"Are you sure you want to"}
								&nbsp;
								<b>{"delete"}</b>
								&nbsp;
								{"the filter view: ["}
								<b>{selectedProjectView.name}</b>
								{"] ?"}
							</Grid>
							<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="25px">
								<Grid item sm={3} display="flex" justifyContent="start">
									<Button
										fullWidth
										variant="outlined"
										sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
										color="primary"
										onClick={() => setProjectViewDialogState("close")}
									>
										{"Cancel"}
									</Button>
								</Grid>
								<Grid item display="flex" sm={3} justifyContent="end">
									<Button
										fullWidth
										variant="contained"
										sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
										color="primary"
										onClick={() => {
											onDeleteProjectView();
										}}
									>
										{"Delete"}
									</Button>
								</Grid>
							</Grid>
						</DialogContent>
					</Dialog>
				)
			}
			{
				rejectModal !== null
				&& (
					<Dialog
						keepMounted
						open={rejectModal !== null}
						TransitionComponent={Transition}
						scroll="body"
						PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4" } }}
						onClose={() => setRejectModal(null)}
					>
						<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
							<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
								{"Are you sure?"}
							</Typography>
						</DialogTitle>
						<DialogContent dividers sx={{ overflowY: "hidden" }}>
							<Grid item container sx={{ p: 1, marginTop: "5px" }} mt={1} alignItems="center">
								{"Are you sure you want to"}
								&nbsp;
								<b>{"reject"}</b>
								&nbsp;
								{"the project"}
								&nbsp;
								<b>{selectedProject?.name?.name}</b>
								{"?"}
							</Grid>
							<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="25px">
								<Grid item sm={3} display="flex" justifyContent="start">
									<Button
										fullWidth
										variant="outlined"
										sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
										color="primary"
										onClick={() => setRejectModal(null)}
									>
										{"Cancel"}
									</Button>
								</Grid>
								<Grid item display="flex" sm={3} justifyContent="end">
									<Button
										fullWidth
										variant="contained"
										sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
										color="primary"
										onClick={() => {
											setRejectModal(null);
											onDecideProjectStatus();
										}}
									>
										{"Reject"}
									</Button>
								</Grid>
							</Grid>
						</DialogContent>
					</Dialog>
				)
			}
			{
				duplicateDialogOpen !== null
				&& (
					<Dialog
						keepMounted
						open={duplicateDialogOpen !== null}
						TransitionComponent={Transition}
						scroll="body"
						maxWidth="md"
						PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4", width: "800px" } }}
						onClose={() => setDuplicateDialogOpen(null)}
					>
						<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
							<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
								{`Duplicate Project: ${duplicateDialogOpen}`}
							</Typography>
						</DialogTitle>
						<DialogContent dividers sx={{ overflowY: "hidden" }}>
							<Grid item container sx={{ marginTop: "10px" }} alignItems="center">
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ p: 1, color: "#1d4363" }}>
										{"New Project Name"}
									</Typography>
								</Grid>
								<Grid item md={8}>
									<TextField
										fullWidth
										hiddenLabel
										variant="filled"
										color="primary"
										value={duplicateData.name}
										aria-label="Platform"
										onChange={(e) => setDuplicateData({ ...duplicateData, name: e.target.value })}
									/>
								</Grid>
							</Grid>
							<Grid item container sx={{ marginTop: "10px" }} alignItems="center">
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ p: 1, color: "#1d4363" }}>
										{"New Project Users"}
									</Typography>
								</Grid>
								<Grid item md={8}>
									<ToggleButtonGroup
										exclusive
										color="primary"
										value={duplicateData.user_action}
										aria-label="Platform"
										className={classes.pipelineToggles}
										onChange={(e) => setDuplicateData({ ...duplicateData, user_action: e.target.value })}
									>
										<ToggleButton sx={{ width: "250px" }} value="all">{"All Project Users"}</ToggleButton>
										<ToggleButton sx={{ width: "250px" }} value="developers">{"Project Developers"}</ToggleButton>
									</ToggleButtonGroup>
								</Grid>
							</Grid>
							<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="25px">
								<Grid item sm={3} display="flex" justifyContent="start">
									<Button
										fullWidth
										variant="outlined"
										sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
										color="primary"
										onClick={() => setDuplicateDialogOpen(null)}
									>
										{"Cancel"}
									</Button>
								</Grid>
								<Grid item display="flex" sm={3} justifyContent="end">
									<Button
										fullWidth
										variant="contained"
										sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
										color="primary"
										onClick={onDuplicateProject}
									>
										{"Duplicate"}
									</Button>
								</Grid>
							</Grid>
						</DialogContent>
					</Dialog>
				)
			}
			{
				archiveModal !== null
				&& (
					<Dialog
						keepMounted
						open={archiveModal !== null}
						TransitionComponent={Transition}
						scroll="body"
						PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4" } }}
						onClose={() => setArchiveModal(null)}
					>
						<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
							<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
								{"Are you sure?"}
							</Typography>
						</DialogTitle>
						<DialogContent dividers sx={{ overflowY: "hidden" }}>
							<Grid item container sx={{ p: 1, marginTop: "5px" }} mt={1} alignItems="center">
								{"Are you sure you want to"}
								&nbsp;
								<b>{"archive"}</b>
								&nbsp;
								{"the project"}
								&nbsp;
								<b>{projects.find((p) => p.name.id === archiveModal).name.name}</b>
								{"?"}
							</Grid>
							<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="25px">
								<Grid item sm={3} display="flex" justifyContent="start">
									<Button
										fullWidth
										variant="outlined"
										sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
										color="primary"
										onClick={() => setArchiveModal(null)}
									>
										{"Cancel"}
									</Button>
								</Grid>
								<Grid item display="flex" sm={3} justifyContent="end">
									<Button
										fullWidth
										variant="contained"
										sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
										color="primary"
										onClick={() => {
											onArchive(archiveModal);
											setArchiveModal(null);
										}}
									>
										{"Archive"}
									</Button>
								</Grid>
							</Grid>
						</DialogContent>
					</Dialog>
				)
			}
			{
				viewProjectUsers
				&& (
					<Dialog
						keepMounted
						fullWidth
						open={viewProjectUsers}
						TransitionComponent={Transition}
						PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4", width: "1400px" } }}
						maxWidth="xl"
						scroll="body"
					>
						<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
							<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
								<b>{`Project's [${selectedProject.name.name}] Users`}</b>
							</Typography>
						</DialogTitle>
						{dialogLoading && (<LinearProgress color="primary" />)}
						<DialogContent dividers style={{ overflow: "hidden" }}>
							<Grid item container margin="5px" sx={{ p: 1 }} alignItems="center" justifyContent="center">
								<Table
									data={projectUsers.members}
									noDataText={isLoading ? "Fetching projects..." : "No data available!"}
									columns={userTableColumns}
									defaultSorted={[{ id: "name", desc: true }]}
								/>
							</Grid>
							<Grid item container direction="row" sx={{ p: 1 }} justifyContent="end">
								<Button
									fullWidth
									variant="contained"
									sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
									color="primary"
									onClick={onCloneViewProjectUsers}
								>
									{"Close"}
								</Button>
							</Grid>
						</DialogContent>
					</Dialog>
				)
			}
			{
				Object.keys(selectedProject).length > 0
				&& (
					<Dialog
						fullWidth
						open={pipelineDialogOpen}
						TransitionComponent={Transition}
						maxWidth="md"
						scroll="body"
						PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4", width: "600px" } }}
						onClose={() => setPipelineDialogOpen(false)}
					>
						<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
							<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
								{selectedProject.pipeline === "None" ? "Add" : "Edit"}
								&nbsp;
								{"Pipeline"}
								&nbsp;
								{selectedProject.pipeline === "None" ? "to" : "of"}
								&nbsp;
								{"Project"}
								&nbsp;
								{selectedProject.name.name}
							</Typography>
						</DialogTitle>
						<DialogContent dividers sx={{ overflowY: "hidden" }}>
							<Grid item container sx={{ p: 1 }} justifyContent="center">
								<ToggleButtonGroup
									exclusive
									color="primary"
									value={pipelineAction}
									aria-label="Platform"
									className={classes.pipelineToggles}
									onChange={onSelectPipelineAction}
								>
									<ToggleButton sx={{ width: "270px" }} value="assign">{"Assign Existing Pipeline"}</ToggleButton>
									<ToggleButton sx={{ width: "270px" }} value="create">{"Create New Pipeline"}</ToggleButton>
								</ToggleButtonGroup>
							</Grid>
							{pipelineAction === "assign"
								&& (
									<Grid item container sx={{ p: 1, marginTop: "20px" }} mt={1} alignItems="center">
										<Grid item xs={12} md={4}>
											<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
												{"Select Pipeline"}
											</Typography>
										</Grid>
										<Grid item xs={12} md={8}>
											<Autocomplete
												id="user-select-combo"
												options={pipelines}
												size="large"
												variant="filled"
												disabled={pipelines.length === 0}
												getOptionLabel={(option) => option}
												renderInput={(params) => (
													<TextField
														{...params}
														label={pipelines.length === 0 ? "No other pipelines found in this company" : "Available Pipelines"}
													// error={userErrors.autocomplete !== ""}
													// helperText={userErrors.autocomplete === "" ? "" : userErrors.autocomplete}
													/>
												)}
												onChange={(e, newValue) => setNewPipeline({ ...newPipeline, name: newValue })}
											/>
										</Grid>
									</Grid>
								)}
							{pipelineAction === "create"
								&& (
									<Grid item container sx={{ p: 1, marginTop: "20px" }} mt={1} alignItems="center">
										<Grid item xs={12} md={4}>
											<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
												{"Create Pipeline"}
											</Typography>
										</Grid>
										<Grid item xs={12} md={8}>
											<TextField
												fullWidth
												hiddenLabel
												value={newPipeline.name}
												variant="filled"
												placeholder="New pipeline"
												onChange={(e) => setNewPipeline({ ...newPipeline, name: e.target.value })}
											/>
										</Grid>
									</Grid>
								)}
							<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="30px">
								<Grid item sm={3} display="flex" justifyContent="start">
									<Button
										fullWidth
										variant="outlined"
										size="large"
										sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
										color="primary"
										onClick={() => setPipelineDialogOpen(false)}
									>
										{"Cancel"}
									</Button>
								</Grid>
								<Grid item sm={1} />
								<Grid item display="flex" sm={3} justifyContent="end">
									<Button
										fullWidth
										variant="contained"
										size="large"
										sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
										color="primary"
										disabled={newPipeline.name === ""}
										onClick={onAssignPipeline}
									>
										{"Save"}
									</Button>
								</Grid>
							</Grid>
						</DialogContent>
					</Dialog>
				)
			}
			<Dialog
				fullWidth
				open={decideStatusDialogOpen}
				TransitionComponent={Transition}
				scroll="body"
				maxWidth="md"
				PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4", maxWidth: "600px" } }}
				onClose={() => setDecideStatusDialogOpen(false)}
			>
				<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
					<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
						{`Review Project ${selectedProject?.name?.name}`}
					</Typography>
				</DialogTitle>
				<DialogContent dividers sx={{ overflowY: "hidden" }}>
					<Grid item container sx={{ p: 1, marginTop: "5px" }} mt={1} alignItems="start" justifyContent="center">
						<Grid item md={5}>
							<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363", marginTop: "10px" }}>
								{"Project Actions"}
							</Typography>
							<Typography variant="h6" sx={{ color: "#1d4363", marginTop: "10px" }}>
								{decideStatusAction === "submitted" ? "This project is currently registered as submitted" : decideStatusAction === "approve" ? "This project will be registered as Approved" : "This project will be registered as Rejected"}
							</Typography>
						</Grid>
						<Grid item md={1} />
						<Grid item md={6}>
							<ToggleButtonGroup
								exclusive
								orientation="vertical"
								color="primary"
								value={decideStatusAction}
								aria-label="Platform"
								sx={{
									"& .Mui-selected": {
										color: "#ffffff !important",
										backgroundColor: decideStatusAction === "submitted" ? "#6F96C6 !important" : decideStatusAction === "approve" ? "#4EC37A !important" : "#ED664B !important",
									},
								}}
								className={classes.toggles}
								onChange={onSelectProjectStatus}
							>
								<ToggleButton sx={{ width: "270px", fontWeight: "bold" }} value="submitted">{"Select Below"}</ToggleButton>
								<ToggleButton sx={{ width: "270px", fontWeight: "bold" }} value="approve">{"Approve"}</ToggleButton>
								<ToggleButton sx={{ width: "270px", fontWeight: "bold" }} value="reject">{"Reject"}</ToggleButton>
							</ToggleButtonGroup>
						</Grid>
					</Grid>

					<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="25px">
						<Grid item sm={3} display="flex" justifyContent="start">
							<Button
								fullWidth
								variant="outlined"
								sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
								color="primary"
								onClick={() => setDecideStatusDialogOpen(false)}
							>
								{"Cancel"}
							</Button>
						</Grid>
						<Grid item display="flex" sm={3} justifyContent="end">
							<Button
								fullWidth
								variant="contained"
								sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
								color="primary"
								disabled={decideStatusAction === "submitted"}
								onClick={onDecideProjectStatus}
							>
								{decideStatusAction === "approve" ? "Approve" : decideStatusAction === "reject" ? "Reject" : "No Actions"}
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default memo(Projects);
