import { memo, useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Grid, LinearProgress, Typography, TextField, MenuItem, ToggleButton, FormControl, InputLabel, Select, Button } from "@mui/material";
import { Edit, Visibility } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";

import { useSnackbar, httpResponseIsValid } from "../../utils";
import { getProjectRiskAssessentProtocol, updateProjectRiskAssessentProtocol } from "../../api";
import StatusDialog from "../../components/StatusDialog";

const localization = {
	UK: {
		nativeName: "English",
		code: "en",
	},
	Deutschland: {
		nativeName: "Deutsch",
		code: "de",
	},
	Italia: {
		nativeName: "Italiano",
		code: "it",
	},
	France: {
		nativeName: "Français",
		code: "fr",
	},
};

const useStyles = makeStyles((theme) => ({
	boxBorder: {
		border: `2px solid ${theme.palette.primary.main}`,
		borderRadius: theme.spacing(2),
		backgroundColor: "white",
	},
	screenTitle: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		letterSpacing: theme.spacing(0.2),
	},
}));

const RiskAssessmentProtocol = ({ disableAccess = true, isProjectDeveloper = false, onChangeProjectState }) => {
	const { projectid } = useParams();
	const navigate = useNavigate();
	const [initialFetching, setInitialFetching] = useState(true);
	const [hasFetched, setHasFetched] = useState(false);
	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(disableAccess);
	const [projectStateDialog, setProjectStateDialog] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [projectInfo, setProjectInfo] = useState({
		status: "",
		createdAt: "-",
		language: "UK",
	});
	const [tempData, setTempData] = useState();
	const { success, error } = useSnackbar();

	const classes = useStyles();

	const { t, i18n } = useTranslation();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getProjectRiskAssessentProtocol(projectid);
				if (httpResponseIsValid(response)) {
					if (!(response?.language in localization)) {
						response.language = "UK";
					}

					setProjectInfo(response);
					setTempData(response);
					setInitialFetching(false);

					const languageCode = localization?.[response?.language];
					if (languageCode) {
						i18n.changeLanguage(languageCode.code);
					}

					setHasFetched(true);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured while fetching risk assessment overview data: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, i18n, projectid],
	);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData, projectid]);

	const submit = useCallback(
		async (displayMessage = false) => {
			setIsLoading(true);

			try {
				const response = await updateProjectRiskAssessentProtocol(
					projectid, projectInfo,
				);

				if (httpResponseIsValid(response)) {
					if (displayMessage) {
						success(t("Contract.savedSuccessfully"));
					}

					await fetchData();
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured while submitting risk assessment overview language: ${_error}`);
			}

			setIsLoading(false);
		}, [fetchData, projectInfo, projectid, error, success, t],
	);

	const changeProjectState = useCallback(
		async () => {
			await onChangeProjectState();
			fetchData();
		}, [fetchData, onChangeProjectState],
	);

	const onChangeLanguage = (value) => {
		i18n.changeLanguage(localization[value].code);

		setProjectInfo({ ...projectInfo, language: value });
	};

	useEffect(() => {
		setShouldUiBeDisabled(disableAccess);
	}, [disableAccess]);

	useEffect(() => {
		(async () => {
			try {
				if (hasFetched && !shouldUiBeDisabled) {
					await submit();
				}
			} catch { /* empty */ }
		})();
	}, [projectInfo.language]);

	return (
		<>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<Grid container direction="column" alignItems="center" py={1} px={3} sx={{ "> .MuiGrid-item": { p: 1 }, position: "fixed", maxWidth: "1300px", width: "calc(100% - 300px)", background: "white", zIndex: 999 }}>
				<Grid container item direction="row" alignItems="center" sx={{ "> .MuiGrid-item": { p: 0 }, background: "#F4F4F4", borderRadius: "10px 10px 0px 0px" }}>
					<Grid item xs={12} md={7}>
						<Typography variant="h5" className={classes.screenTitle}>{t("RiskAssessmentOverview.riskAssessmentOverview")}</Typography>
					</Grid>
					<Grid item container className={classes.boxBorder} sx={{ p: 1 }} xs={12} md={5}>
						<Grid item xs={12} md={6}>
							<Box p={1}>
								<Grid container direction="row" justifyContent="start" alignItems="center">
									<Grid item xs={4}>
										<Typography variant="h6">{"Status: "}</Typography>
									</Grid>
									<Grid item container xs={8} justifyContent="start">
										<Typography variant="h6" color={(projectInfo.status === "Approved") ? "green.main" : ((projectInfo.status === "Rejected") ? "error.main" : "primary.main")}>{projectInfo.status}</Typography>
									</Grid>
								</Grid>
							</Box>
						</Grid>
						<Grid item container xs={12} md={6} alignItems="center" justifyContent="end">
							<Button
								variant="contained"
								size="medium"
								sx={{ width: "170px", backgroundColor: "#5C9AE1", color: "common.white", ":hover": { backgroundColor: "primary.main" }, px: 3, marginRight: "4px", borderRadius: "10px" }}
								disabled={!isProjectDeveloper}
								onClick={() => setProjectStateDialog(true)}
							>
								{projectInfo.status === "In Progress" ? "Submit Project" : "Revert Project"}
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid container item direction="row" alignItems="center" sx={{ ">.MuiGrid-item": { p: 0 }, background: "#F4F4F4", borderRadius: "0px 0px 10px 10px" }} p={0}>
					<Grid container item direction="row" alignItems="center" sx={{ ">.MuiGrid-item": { p: 0 } }} p={0}>
						<Grid item xs={0} md={7} />
						<Grid item container sx={{ p: 1 }} xs={12} md={5} alignItems="center">
							<Grid item xs={7} />
							<Grid item container xs={5} align="left" justifyContent="end">
								{!shouldUiBeDisabled && (
									<FormControl variant="outlined">
										<InputLabel id="demo-simple-select-filled-label">{"Language"}</InputLabel>
										<Select
											// labelId="demo-simple-select-filled-label"
											id="demo-simple-select-outlined"
											value={projectInfo.language}
											label="Language"
											sx={{ width: "180px", height: "45px", borderRadius: "15px 15px 15px 15px", backgroundColor: "white" }}
											disabled={shouldUiBeDisabled}
											onChange={(e) => onChangeLanguage(e.target.value)}
										>
											{Object.keys(localization).map((cur) => (
												<MenuItem key={cur} value={cur}>
													{localization[cur].nativeName}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								)}
								{shouldUiBeDisabled && projectInfo.language && (
									<TextField
										fullWidth
										size="small"
										id="location"
										style={{ width: "180px" }}
										value={localization[projectInfo.language].nativeName}
										variant="outlined"
										label="Language"
										disabled={shouldUiBeDisabled}
									/>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{!initialFetching
				&& (
					<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "120px" }}>
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
							<Grid item xs={5} align="left">
								<Typography variant="h6" color="common.white">
									{t("RiskAssessmentOverview.riskTitle")}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<Typography variant="h6" color="common.white">
									{t("RiskAssessmentOverview.riskPresence")}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<Typography variant="h6" color="common.white">
									{t("RiskAssessmentOverview.action")}
								</Typography>
							</Grid>
						</Grid>
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="grey.main">
							<Grid item xs={5} align="left">
								<Typography variant="h6" color="common.black">
									{t("RiskAssessmentOverview.creditRiskServiceProvider")}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<Typography variant="h6" color="common.black">
									{"-"}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<ToggleButton
									value="edit info"
									title={`View ${t("RiskAssessmentOverview.creditRiskServiceProvider")}`}
									aria-label="edit info"
									sx={{ borderColor: "primary.main" }}
									onClick={() => navigate("../credit-risk-service-provider")}
								>
									<Visibility color="primary" />
								</ToggleButton>
							</Grid>
						</Grid>
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="common.white">
							<Grid item xs={5} align="left">
								<Typography variant="h6" color="common.black">
									{t("RiskAssessmentOverview.creditRiskImplementorClient")}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								{"-"}
							</Grid>
							<Grid item xs={3} align="center">
								<ToggleButton
									value="edit info"
									title={`View ${t("RiskAssessmentOverview.creditRiskImplementorClient")}`}
									aria-label="edit info"
									sx={{ borderColor: "primary.main" }}
									onClick={() => navigate("../credit-risk-implementor-client")}
								>
									<Visibility color="primary" />
								</ToggleButton>
							</Grid>
						</Grid>
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="grey.main">
							<Grid item xs={5} align="left">
								<Typography variant="h6" color="common.black">
									{t("RiskAssessmentOverview.regulatoryRisk")}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<Typography variant="h6" color="common.black">
									{(tempData.regulatory_risk_relevance === "false")
										? <DoneIcon fontSize="large" color="success" /> : <ClearIcon fontSize="large" color="error" />}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<ToggleButton
									value="edit info"
									title={`${shouldUiBeDisabled ? "View" : "Εdit"} ${t("RiskAssessmentOverview.regulatoryRisk")}`}
									aria-label="edit info"
									sx={{ borderColor: "primary.main" }}
									onClick={() => navigate("../regulatory-risk")}
								>
									{shouldUiBeDisabled ? <Visibility color="primary" /> : <Edit color="primary" />}
								</ToggleButton>
							</Grid>
						</Grid>
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="common.white">
							<Grid item xs={5} align="left">
								<Typography variant="h6" color="common.black">
									{t("RiskAssessmentOverview.marketRisk")}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<Typography variant="h6" color="common.black">
									{"-"}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<ToggleButton
									value="edit info"
									title={`${shouldUiBeDisabled ? "View" : "Εdit"} ${t("RiskAssessmentOverview.marketRisk")}`}
									aria-label="edit info"
									sx={{ borderColor: "primary.main" }}
									onClick={() => navigate("../market-risk")}
								>
									{shouldUiBeDisabled ? <Visibility color="primary" /> : <Edit color="primary" />}
								</ToggleButton>
							</Grid>
						</Grid>
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="grey.main">
							<Grid item xs={5} align="left">
								<Typography variant="h6" color="common.black">
									{t("RiskAssessmentOverview.energyPriceRisk")}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<Typography variant="h6" color="common.black">
									{(tempData.energy_price_risk.energy_price_risk_relevance === "false")
										? <DoneIcon fontSize="large" color="success" /> : <ClearIcon fontSize="large" color="error" />}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<ToggleButton
									value="edit info"
									title={`${shouldUiBeDisabled ? "View" : "Εdit"} ${t("RiskAssessmentOverview.energyPriceRisk")}`}
									aria-label="edit info"
									sx={{ borderColor: "primary.main" }}
									onClick={() => navigate("../energy-price-risk")}
								>
									{shouldUiBeDisabled ? <Visibility color="primary" /> : <Edit color="primary" />}
								</ToggleButton>
							</Grid>
						</Grid>
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="common.white">
							<Grid item xs={5} align="left">
								<Typography variant="h6" color="common.black">
									{t("RiskAssessmentOverview.currencyRisk")}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<Typography variant="h6" color="common.black">
									{(tempData.currency_risk.currency_risk_relevance === "false")
										? <DoneIcon fontSize="large" color="success" /> : <ClearIcon fontSize="large" color="error" />}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<ToggleButton
									value="edit info"
									title={`${shouldUiBeDisabled ? "View" : "Εdit"} ${t("RiskAssessmentOverview.currencyRisk")}`}
									aria-label="edit info"
									sx={{ borderColor: "primary.main" }}
									onClick={() => navigate("../currency-risk")}
								>
									{shouldUiBeDisabled ? <Visibility color="primary" /> : <Edit color="primary" />}
								</ToggleButton>
							</Grid>
						</Grid>
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="grey.main">
							<Grid item xs={5} align="left">
								<Typography variant="h6" color="common.black">
									{t("RiskAssessmentOverview.technicalRisk")}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<Typography variant="h6" color="common.black">
									{(tempData.technical_risk.technical_risk_relevance === "false")
										? <DoneIcon fontSize="large" color="success" /> : <ClearIcon fontSize="large" color="error" />}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<ToggleButton
									value="edit info"
									title={`${shouldUiBeDisabled ? "View" : "Εdit"} ${t("RiskAssessmentOverview.technicalRisk")}`}
									aria-label="edit info"
									sx={{ borderColor: "primary.main" }}
									onClick={() => navigate("../technical-risk")}
								>
									{shouldUiBeDisabled ? <Visibility color="primary" /> : <Edit color="primary" />}
								</ToggleButton>
							</Grid>
						</Grid>
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="common.white">
							<Grid item xs={5} align="left">
								<Typography variant="h6" color="common.black">
									{t("RiskAssessmentOverview.performanceRisk")}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<Typography variant="h6" color="common.black">
									{(tempData.performance_risk.performance_risk_relevance === "false")
										? <DoneIcon fontSize="large" color="success" /> : <ClearIcon fontSize="large" color="error" />}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<ToggleButton
									value="edit info"
									title={`${shouldUiBeDisabled ? "View" : "Εdit"} ${t("RiskAssessmentOverview.performanceRisk")}`}
									aria-label="edit info"
									sx={{ borderColor: "primary.main" }}
									onClick={() => navigate("../performance-risk")}
								>
									{shouldUiBeDisabled ? <Visibility color="primary" /> : <Edit color="primary" />}
								</ToggleButton>
							</Grid>
						</Grid>
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="grey.main">
							<Grid item xs={5} align="left">
								<Typography variant="h6" color="common.black">
									{t("RiskAssessmentOverview.omRisk")}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<Typography variant="h6" color="common.black">
									{(tempData.o_m_risk.o_m_risk_relevance === "false")
										? <DoneIcon fontSize="large" color="success" /> : <ClearIcon fontSize="large" color="error" />}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<ToggleButton
									value="edit info"
									title={`${shouldUiBeDisabled ? "View" : "Εdit"} ${t("RiskAssessmentOverview.omRisk")}`}
									aria-label="edit info"
									sx={{ borderColor: "primary.main" }}
									onClick={() => navigate("../o-m-risk")}
								>
									{shouldUiBeDisabled ? <Visibility color="primary" /> : <Edit color="primary" />}
								</ToggleButton>
							</Grid>
						</Grid>
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="common.white">
							<Grid item xs={5} align="left">
								<Typography variant="h6" color="common.black">
									{t("RiskAssessmentOverview.interfaceRisk")}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<Typography variant="h6" color="common.black">
									{(tempData.interface_risk.interface_risk_relevance === "false")
										? <DoneIcon fontSize="large" color="success" /> : <ClearIcon fontSize="large" color="error" />}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<ToggleButton
									value="edit info"
									title={`${shouldUiBeDisabled ? "View" : "Εdit"} ${t("RiskAssessmentOverview.interfaceRisk")}`}
									aria-label="edit info"
									sx={{ borderColor: "primary.main" }}
									onClick={() => navigate("../interface-risk")}
								>
									{shouldUiBeDisabled ? <Visibility color="primary" /> : <Edit color="primary" />}
								</ToggleButton>
							</Grid>
						</Grid>
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="grey.main">
							<Grid item xs={5} align="left">
								<Typography variant="h6" color="common.black">
									{t("RiskAssessmentOverview.pipelineRisk")}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<Typography variant="h6" color="common.black">
									{(tempData.pipeline_risk.pipeline_risk_relevance === "false")
										? <DoneIcon fontSize="large" color="success" /> : <ClearIcon fontSize="large" color="error" />}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<ToggleButton
									value="edit info"
									title={`${shouldUiBeDisabled ? "View" : "Εdit"} ${t("RiskAssessmentOverview.pipelineRisk")}`}
									aria-label="edit info"
									sx={{ borderColor: "primary.main" }}
									onClick={() => navigate("../pipeline-risk")}
								>
									{shouldUiBeDisabled ? <Visibility color="primary" /> : <Edit color="primary" />}
								</ToggleButton>
							</Grid>
						</Grid>
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="common.white">
							<Grid item xs={5} align="left">
								<Typography variant="h6" color="common.black">
									{t("RiskAssessmentOverview.prepaymentRisk")}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<Typography variant="h6" color="common.black">
									{(tempData.pre_payment_risk.pre_payment_risk_relevance === "false")
										? <DoneIcon fontSize="large" color="success" /> : <ClearIcon fontSize="large" color="error" />}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<ToggleButton
									value="edit info"
									title={`${shouldUiBeDisabled ? "View" : "Εdit"} ${t("RiskAssessmentOverview.prepaymentRisk")}`}
									aria-label="edit info"
									sx={{ borderColor: "primary.main" }}
									onClick={() => navigate("../pre-payment-risk")}
								>
									{shouldUiBeDisabled ? <Visibility color="primary" /> : <Edit color="primary" />}
								</ToggleButton>
							</Grid>
						</Grid>
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="grey.main">
							<Grid item xs={5} align="left">
								<Typography variant="h6" color="common.black">
									{t("RiskAssessmentOverview.occupacyRisk")}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<Typography variant="h6" color="common.black">
									{(tempData.occupancy_risk.occupancy_risk_relevance === "false")
										? <DoneIcon fontSize="large" color="success" /> : <ClearIcon fontSize="large" color="error" />}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<ToggleButton
									value="edit info"
									title={`${shouldUiBeDisabled ? "View" : "Εdit"} ${t("RiskAssessmentOverview.occupacyRisk")}`}
									aria-label="edit info"
									sx={{ borderColor: "primary.main" }}
									onClick={() => navigate("../occupacy-risk")}
								>
									{shouldUiBeDisabled ? <Visibility color="primary" /> : <Edit color="primary" />}
								</ToggleButton>
							</Grid>
						</Grid>
						{/* <Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="common.white">
							<Grid item xs={5} align="left">
								<Typography variant="h6" color="common.black">
									{t("RiskAssessmentOverview.managementRisk")}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<Typography variant="h6" color="common.black">
									{(tempData.project.riskAssessmentProtocol.management_risk.management_risk_relevance === "false")
										? <DoneIcon fontSize="large" color="success" /> : <ClearIcon fontSize="large" color="error" />}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<ToggleButton
									value="edit info"
									title={`${shouldUiBeDisabled ? "View" : "Εdit"} ${t("RiskAssessmentOverview.managementRisk")}`}
									aria-label="edit info"
									sx={{ borderColor: "primary.main" }}
									onClick={() => navigate("../management-risk")}
								>
									{shouldUiBeDisabled ? <Visibility color="primary" /> : <Edit color="primary" />}
								</ToggleButton>
							</Grid>
						</Grid> */}
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="common.white">
							<Grid item xs={5} align="left">
								<Typography variant="h6" color="common.black">
									{t("RiskAssessmentOverview.changeInControlRisk")}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<Typography variant="h6" color="common.black">
									{(tempData.change_in_control_risk.change_in_control_risk_relevance === "false")
										? <DoneIcon fontSize="large" color="success" /> : <ClearIcon fontSize="large" color="error" />}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<ToggleButton
									value="edit info"
									title={`${shouldUiBeDisabled ? "View" : "Εdit"} ${t("RiskAssessmentOverview.changeInControlRisk")}`}
									aria-label="edit info"
									sx={{ borderColor: "primary.main" }}
									onClick={() => navigate("../change-in-control-risk")}
								>
									{shouldUiBeDisabled ? <Visibility color="primary" /> : <Edit color="primary" />}
								</ToggleButton>
							</Grid>
						</Grid>
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="grey.main">
							<Grid item xs={5} align="left">
								<Typography variant="h6" color="common.black">
									{t("RiskAssessmentOverview.constructionRisk")}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<Typography variant="h6" color="common.black">
									{(tempData.construction_risk.construction_risk_relevance === "false")
										? <DoneIcon fontSize="large" color="success" /> : <ClearIcon fontSize="large" color="error" />}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<ToggleButton
									value="edit info"
									title={`${shouldUiBeDisabled ? "View" : "Εdit"} ${t("RiskAssessmentOverview.constructionRisk")}`}
									aria-label="edit info"
									sx={{ borderColor: "primary.main" }}
									onClick={() => navigate("../construction-risk")}
								>
									{shouldUiBeDisabled ? <Visibility color="primary" /> : <Edit color="primary" />}
								</ToggleButton>
							</Grid>
						</Grid>
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="common.white">
							<Grid item xs={5} align="left">
								<Typography variant="h6" color="common.black">
									{t("RiskAssessmentOverview.supplyChainRisk")}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<Typography variant="h6" color="common.black">
									{(tempData.supply_chain_risk.supply_chain_risk_relevance === "false")
										? <DoneIcon fontSize="large" color="success" /> : <ClearIcon fontSize="large" color="error" />}
								</Typography>
							</Grid>
							<Grid item xs={3} align="center">
								<ToggleButton
									value="edit info"
									title={`${shouldUiBeDisabled ? "View" : "Εdit"} ${t("RiskAssessmentOverview.supplyChainRisk")}`}
									aria-label="edit info"
									sx={{ borderColor: "primary.main" }}
									onClick={() => navigate("../supply-chain-risk")}
								>
									{shouldUiBeDisabled ? <Visibility color="primary" /> : <Edit color="primary" />}
								</ToggleButton>
							</Grid>
						</Grid>
					</Grid>
				)}
			<StatusDialog
				open={projectStateDialog}
				status={projectInfo.status}
				name={projectInfo.name}
				onClose={setProjectStateDialog}
				onChange={changeProjectState}
			/>
		</>
	);
};

export default memo(RiskAssessmentProtocol);
