import { memo } from "react";
import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomSwitch = styled(
	({
		yes = "Yes",
		no = "No",
		...props
	}) => (
		<Switch
			disableRipple
			focusVisibleClassName=".Mui-focusVisible"
			sx={{
				"& .MuiSwitch-track": {
					"&:before": {
						position: "relative",
						left: 10,
						top: 3,
						fontSize: 13,
						fontWeight: "bold",
						color: "white",
						content: `'${yes}'`,
					},
					"&:after": {
						position: "relative",
						right: -28,
						top: 3,
						fontSize: 13,
						fontWeight: "bold",
						color: "white",
						content: `'${no}'`,
					},
				},
			}}
			{...props}
		/>
	),
)(({ theme }) => ({
	width: 80,
	height: 30,
	padding: 0,
	"& .MuiSwitch-switchBase": {
		padding: 0,
		margin: 3,
		transitionDuration: "500ms",
		"&.Mui-checked": {
			transform: "translateX(38px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				backgroundColor: "#51BEA5",
				opacity: 1,
				border: 0,

			},
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: "0.7 !important",
			backgroundColor: "#91BBB2",
		},
	},
	"& .MuiSwitch-switchBase:hover": {
		color: "#F4F4F4",
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
		width: 36,
		height: 24,
		borderRadius: 16,
	},
	"& .MuiSwitch-track": {
		borderRadius: 30 / 2,
		backgroundColor: "#E96464",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
	},
}));

export default memo(CustomSwitch);
