import { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
	Grid,
	Typography,
	Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import { CustomFileUpload } from "../custom/index";

export const LabelFileUpload = ({
	fieldId,
	label,
	bold = false,
	viewOnly = false,
	projectId,
	fileTool,
	size: labelSize = "medium",
	disabled = false,
	formik,
	infoText = "",
	onSuccess,
	typosize = "h6",
	aligntitlebox = "center",
	companydoc = false,
}) => {
	const [size, setSize] = useState({ label: 5, sep: 2, field: 5 });

	useEffect(() => {
		switch (labelSize) {
			case "small":
				setSize({ label: 4, sep: 2, field: 6 });
				break;
			case "medium":
				setSize({ label: 5, sep: 2, field: 5 });
				break;
			case "large":
				setSize({ label: 6, sep: 2, field: 4 });
				break;
			case "flat":
				setSize({ label: 5.5, sep: 0.5, field: 6 });
				break;
			default:
				setSize({ label: 5, sep: 2, field: 5 });
		}
	}, [labelSize]);

	return (
		<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems={aligntitlebox}>
			<Grid item container xs={12} md={size.label}>
				<Typography variant={typosize} fontWeight={bold ? "bold" : ""} sx={{ textAlign: "justify", color: "blue.dark" }}>
					{label}
					{infoText && (
						<Tooltip placement="bottom-start" title={infoText}>
							<InfoIcon fontSize="small" sx={{ padding: 0.2, color: "green.main" }} />
						</Tooltip>
					)}
				</Typography>
			</Grid>
			<Grid item container md={size.sep} sx={{ marginTop: "-5px" }} justifyContent="center" alignItems="center" />
			<Grid item xs={12} md={size.field}>
				<CustomFileUpload
					id={fieldId}
					pid={projectId}
					cf={companydoc}
					viewOnly={viewOnly}
					fileTool={fileTool}
					disabled={disabled}
					variable={fieldId}
					formik={formik}
					onSuccess={onSuccess}
				/>
			</Grid>
		</Grid>
	);
};

LabelFileUpload.propTypes = {
	fieldId: PropTypes.string.isRequired,
	projectId: PropTypes.string.isRequired,
	fileTool: PropTypes.string.isRequired,
	viewOnly: PropTypes.bool,
	label: PropTypes.string.isRequired,
	bold: PropTypes.bool,
	size: PropTypes.string,
	disabled: PropTypes.bool,
	formik: PropTypes.any.isRequired,
	infoText: PropTypes.string,
	onSuccess: PropTypes.func.isRequired,
	typosize: PropTypes.string,
	aligntitlebox: PropTypes.string,
	companydoc: PropTypes.bool,
};

export default memo(LabelFileUpload);
