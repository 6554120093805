/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable unicorn/prefer-at */
/* eslint-disable unicorn/consistent-destructuring */
import { memo, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid, LinearProgress, Typography, Box, Switch } from "@mui/material";
import shallow from "zustand/shallow";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import * as Yup from "yup";

import { options } from "../../utils/options";
import {
	useSnackbar,
	httpResponseIsValid,
	projectTaxonomies,
	projectSectors,
	projectSubsidies,
	projectTypes,
} from "../../utils/index";
import useGlobalState from "../../use-global-state";
import usePrompt from "../../utils/use-blocker";
import StatusDialog from "../../components/StatusDialog";
import { ContainedButton } from "../../components/custom/index";
import { LabelTextField, LabelDropdown, LabelRadio, LabelSwitch } from "../../components/basic/index";
import { getProject, setProjectOverview, updateProjectOverview } from "../../api";

const useStyles = makeStyles((theme) => ({
	boxBorder: {
		border: `2px solid ${theme.palette.primary.main}`,
		borderRadius: theme.spacing(2),
		backgroundColor: "white",
	},
	screenTitle: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		letterSpacing: theme.spacing(0.2),
	},
}));

const countries = options.reduce((acc, currentValue) => {
	acc[currentValue] = currentValue;
	return acc;
}, {});

const verifyCountries = options.slice(1);
const verifyTechnologies = Object.keys(projectTaxonomies).slice(1);
const verifySectors = Object.keys(projectSectors).slice(1);

const OverviewSchema = Yup.object().shape({
	name: Yup.string().trim().required("Project Name is required."),
	description: Yup.string().trim().required("Project Description is required."),
	projectSize: Yup.number().required("Project Size is required.").positive("Project Size must be a positive number."),
	country: Yup.string().trim().required("Project Country is required.").oneOf(verifyCountries, "Select a valid Country Option."),
	technology: Yup.string().trim().oneOf(verifyTechnologies, "Select a vaid Tecnhology Option.").required("Project Technology is required."),
	portfolioSize: Yup.number().required("Portfolio Size is required.").positive("Portfolio Size muct be a positive number."),
	projectTerm: Yup.number().required("Project Term is required.").positive("Project Term (years) must be a positive number."),
	sector: Yup.string().trim().oneOf(verifySectors, "Select a valid Sector Option.").required("Project Sector is required."),
	subsidies: Yup.string().trim().required("Project Subsidies is required"),
	type: Yup.string().trim().required("Project Type is required."),
});

const Overview = ({ disableAccess = true, isProjectDeveloper = false, onChangeTools, onChangeProjectState }) => {
	const { projectid } = useParams();
	const [isLoading, setIsLoading] = useState(projectid !== "new-project");
	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(disableAccess);
	const [enableEditing, setEnableEditing] = useState(false);
	const [projectStateDialog, setProjectStateDialog] = useState(false);
	const [newProject, setNewProject] = useState(true);

	const [projectInfo, setProjectInfo] = useState({
		status: "-",
		createdAt: "-",
		options: {},
		name: "",
		description: "",
		projectSize: "",
		country: countries["Select Country"],
		technology: projectTaxonomies.selectTechnology,
		portfolioSize: "",
		projectTerm: "",
		sector: projectSectors.selectSector,
		subsidies: "false",
		type: "false",
		tools: {
			contract: true,
			financial_assessment: true,
			risk_assessment_protocol: true,
			loan_application: true,
			kyc: true,
			esg: true,
			equad: true,
		},

	});

	const classes = useStyles();
	const { success, error } = useSnackbar();
	const navigate = useNavigate();
	const { setName } = useGlobalState(useCallback((e) => ({
		setName: e.setName,
	}), []), shallow);

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			let response;
			try {
				if (projectid !== "new-project") {
					response = await getProject(projectid);

					if (httpResponseIsValid(response)) {
						onChangeTools(response.tools);
						setProjectInfo(response);
						// formik.setValues(response);
					} else {
						error(response?.message);
					}
				}
			} catch (_error) {
				error(`Error occured while fetching user's info: ${_error}`);
			}

			setIsLoading(false);
		}, [error, onChangeTools, projectid],
	);

	const handleSubmit = async (values) => {
		setIsLoading(true);

		try {
			let response;

			if (projectid === "new-project") {
				response = await setProjectOverview(values);
				if (httpResponseIsValid(response)) {
					success(response?.message);
					setName(response?.name);
					setProjectInfo(response);
					navigate(`/projects/${response?.projectId}`);
					setEnableEditing(false);
				} else {
					error(response?.message);
				}
			} else {
				response = await updateProjectOverview(projectid, values);
				if (httpResponseIsValid(response)) {
					success(response?.message);
					setName(response?.name);
					await fetchData();
					setEnableEditing(false);
				} else {
					error(response?.message);
				}
			}

			setNewProject(false);
		} catch (_error) {
			error(`Error occured while ${projectid === "new-project" ? "creating" : "editing"} user's project: ${_error}`);
		}

		onChangeTools(projectInfo.tools);
		setIsLoading(false);
	};

	const formik = useFormik({
		initialValues: projectInfo,
		validationSchema: OverviewSchema,
		onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	});
	const { dirty } = formik;

	usePrompt(
		"You have unsaved changes which will be lost. Are you sure you want to leave the page?",
		(dirty && projectid !== "new-project"),
	);

	useEffect(() => {
		formik.resetForm({ values: projectInfo, dirty: false });
	}, [projectInfo]);

	useEffect(() => {
		setShouldUiBeDisabled(disableAccess || !enableEditing);
	}, [disableAccess, enableEditing]);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, projectid]);

	const changeProjectState = useCallback(
		async () => {
			await onChangeProjectState();
			fetchData();
		}, [fetchData, onChangeProjectState],
	);

	useEffect(() => {
		(() => {
			try {
				const newProjectPath = window.location.pathname.split("/")[2];

				if (newProjectPath === "new-project") {
					setNewProject(true);
					setEnableEditing(true);
				} else {
					setNewProject(false);
				}
			} catch { /* empty error */ }
		})();
	}, [setNewProject]);

	return (
		<form onSubmit={formik.handleSubmit}>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<Grid container direction="column" alignItems="center" py={1} px={3} sx={{ "> .MuiGrid-item": { p: 1 }, position: "fixed", maxWidth: "1300px", width: "calc(100% - 300px)", background: "white", zIndex: 999 }}>
				<Grid container item direction="row" alignItems="center" sx={{ "> .MuiGrid-item": { p: 0 }, background: "#F4F4F4", borderRadius: "10px 10px 0px 0px" }}>
					<Grid item xs={12} md={4}>
						<Typography variant="h5" className={classes.screenTitle}>{"OVERVIEW"}</Typography>
					</Grid>
					<Grid item xs={0} md={3} />
					<Grid item container className={classes.boxBorder} sx={{ p: 1 }} xs={12} md={5}>
						<Grid item xs={12} md={6}>
							<Box p={1}>
								<Grid container direction="row" justifyContent="start" alignItems="center">
									<Grid item xs={4}>
										<Typography variant="h6">{"Status: "}</Typography>
									</Grid>
									<Grid item container xs={8} justifyContent="start">
										<Typography variant="h6" color={(projectInfo.status === "Approved") ? "green.main" : ((projectInfo.status === "Rejected") ? "error.main" : "primary.main")}>{projectInfo.status}</Typography>
									</Grid>
								</Grid>
							</Box>
						</Grid>
						<Grid item container xs={12} md={6} alignItems="center" justifyContent="end">
							<Button
								variant="contained"
								size="medium"
								disabled={!isProjectDeveloper}
								sx={{ width: "170px", backgroundColor: "#5C9AE1", color: "common.white", ":hover": { backgroundColor: "primary.main" }, px: 3, marginRight: "4px", borderRadius: "10px" }}
								onClick={() => setProjectStateDialog(true)}
							>
								{projectInfo.status === "In Progress" ? "Submit Project" : "Revert Project"}
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid container item direction="row" alignItems="center" sx={{ ">.MuiGrid-item": { p: 0 }, background: "#F4F4F4", borderRadius: "0px 0px 10px 10px" }}>
					<Grid item xs={0} md={7} />
					<Grid item container sx={{ p: 1 }} xs={12} md={5} alignItems="center">
						<Grid item xs={12} md={6}>
							<Box px={2}>
								{!disableAccess
									&& (
										<Grid container direction="row" justifyContent="center" alignItems="center" p={0}>
											<Grid item xs={4}>
												<Typography variant="h6" fontSize="medium">{"Edit: "}</Typography>
											</Grid>
											<Grid item xs={8}>
												<Switch
													color="primary"
													size="small"
													checked={enableEditing || newProject}
													disabled={newProject}
													onChange={(e) => setEnableEditing(e.target.checked)}
												/>
											</Grid>
										</Grid>
									)}
							</Box>
						</Grid>
						<Grid item xs={12} md={6}>
							<Box px={1} display="flex" flexDirection="row">
								<Grid container direction="row" justifyContent="end" alignItems="center">
									<ContainedButton
										disabled={!enableEditing || !dirty}
										type="submit"
										label="SAVE"
									/>
								</Grid>
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "105px" }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{"INFORMATION"}
					</Typography>
				</Grid>

				<LabelTextField
					bold
					id="name"
					label="Project Name"
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>

				<LabelTextField
					bold
					id="description"
					label="Project Description"
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>

				<LabelTextField
					bold
					id="projectSize"
					label="Project Size"
					type="number"
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>

				<LabelDropdown
					bold
					id="country"
					label="Project Country"
					options={countries}
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>

				<LabelDropdown
					bold
					id="technology"
					label="Project Technology"
					options={projectTaxonomies}
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>

				<LabelTextField
					bold
					id="portfolioSize"
					label="Portfolio Size"
					type="number"
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>

				<LabelTextField
					bold
					id="projectTerm"
					label="Project Term (years)"
					type="number"
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>

				<LabelDropdown
					bold
					id="sector"
					label="Project Sector"
					options={projectSectors}
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>

				<LabelRadio
					bold
					id="subsidies"
					label="Subsidies"
					options={projectSubsidies}
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>

				<LabelRadio
					bold
					id="type"
					label="Type"
					options={projectTypes}
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>

				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{"PROJECT TOOLS"}
					</Typography>
				</Grid>

				<Grid container item md={5} sx={{ p: 2 }} alignItems="begin">
					<LabelSwitch
						id="tools.contract"
						label="Contract"
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>
					<LabelSwitch
						id="tools.risk_assessment_protocol"
						label="Risk Assessment Protocol"
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>
					<LabelSwitch
						id="tools.kyc"
						label="KYC"
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>
					<LabelSwitch
						id="tools.equad"
						label="eQuad"
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>
				</Grid>
				<Grid item md={2} />
				<Grid container item md={5} sx={{ p: 2 }} alignItems="start">
					<LabelSwitch
						id="tools.financial_assessment"
						label="Financial Assessment"
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>
					<LabelSwitch
						id="tools.loan_application"
						label="Loan Application"
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>
					<LabelSwitch
						id="tools.esg"
						label="ESG"
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>
					<Grid item mt={2} mb={12} />
				</Grid>
			</Grid>
			<StatusDialog
				open={projectStateDialog}
				status={projectInfo.status}
				name={projectInfo.name}
				onClose={setProjectStateDialog}
				onChange={changeProjectState}
			/>
		</form>
	);
};

export default memo(Overview);
