import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { english, german, italian, french } from "../locales";

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		detection: {
			order: ["navigator", "htmlTag"],
			lookupFromPathIndex: 0,
		},

		resources: {
			en: { translations: english },
			de: { translations: german },
			it: { translations: italian },
			fr: { translations: french },
		},
		interpolation: { escapeValue: false },
		fallbackLng: "en",
		lng: navigator.language || navigator.userLanguage,
		debug: false,
		ns: ["translations"],
		defaultNS: "translations",
		react: { wait: true },
	});

export { default } from "i18next";
