import { memo, useCallback, useEffect, useState, forwardRef } from "react";
import { useParams } from "react-router-dom";
import { Button, Grid, LinearProgress, Typography, FormControl, Select, MenuItem, ListItemIcon, ListItemText, Checkbox, Dialog, Slide, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useFormik, getIn } from "formik";

import { useSnackbar, httpResponseIsValid } from "../../utils";
import { deleteFile, getProjectRiskAssessentProtocol, updateProjectRiskAssessentProtocol } from "../../api";
import usePrompt from "../../utils/use-blocker";
import StatusDialog from "../../components/StatusDialog";
import {
	MainMenu,
	LabelTextField,
	LabelRadio,
	LabelFileUpload,
} from "../../components/basic/index";

const Transition = forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const currencies = {
	euro: "Euro (€)",
	dollar: "Dollar ($)",
	pound: "GBP (£)",
	chf: "CHF",
	zloty: "Zloty (zl)",
};

const useStyles = makeStyles((theme) => ({
	boxBorder: {
		border: `2px solid ${theme.palette.primary.main}`,
		borderRadius: theme.spacing(2),
		backgroundColor: "white",
	},
	screenTitle: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		letterSpacing: theme.spacing(0.2),
	},
}));

const PipelineRisk = ({ disableAccess = true, isProjectDeveloper = false, onChangeProjectState }) => {
	const { projectid } = useParams();
	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(disableAccess);
	const [projectStateDialog, setProjectStateDialog] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [projectInfo, setProjectInfo] = useState({
		performance: "",
		status: "",
		createdAt: "-",
		pipeline_risk_doc_1: {
			original_name: "",
			save_name: "",
		},
		pipeline_risk: {
			pipeline_risk_relevance: "",
			pipeline_risk_relevant_currencies: [],
			contract_installation: "",
			agreement_six_months: "",
			agreement_twelve_months: "",
		},
	});
	const [enableEditing, setEnableEditing] = useState(false);
	const { success, error } = useSnackbar();
	const [deleteModal, setDeleteModal] = useState({ component: null, variable: null });

	const [selected, setSelected] = useState([]);
	const isAllSelected = Object.values(currencies).length > 0 && selected.length === Object.values(currencies).length;

	const classes = useStyles();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getProjectRiskAssessentProtocol(projectid);
				if (httpResponseIsValid(response)) {
					setProjectInfo(response);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured while fetching risk assessment pipeline risk data: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, projectid],
	);

	const handleSubmit = async (values) => {
		setIsLoading(true);

		try {
			const response = await updateProjectRiskAssessentProtocol(projectid, values);

			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			error(`Error occured while submitting risk assessment pipeline risk data: ${_error}`);
		}

		setIsLoading(false);
	};

	const changeProjectState = useCallback(
		async () => {
			await onChangeProjectState();
			fetchData();
		}, [fetchData, onChangeProjectState],
	);

	const onDelete = async (comp) => {
		try {
			setIsLoading(true);
			const { success: deleteSuccess } = await deleteFile({ projectid, component: comp.component, variable: comp.variable });
			console.log(deleteSuccess);
			if (deleteSuccess) {
				success("File deleted successfully");
				await fetchData(false);
				setIsLoading(false);
			} else {
				error();
				setIsLoading(false);
			}
		} catch { /* empty */ }
	};

	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: projectInfo,
		onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	});

	const { dirty, values } = formik;
	usePrompt("You have unsaved changes which will be lost. Are you sure you want to leave the page?", dirty);

	useEffect(() => {
		setShouldUiBeDisabled(disableAccess || !enableEditing);
	}, [disableAccess, enableEditing]);

	useEffect(() => {
		formik.resetForm({ values: projectInfo, dirty: false });
	}, [projectInfo]);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData, projectid]);

	return (
		<form onSubmit={formik.handleSubmit}>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<MainMenu
				title={t("RiskAssessmentOverview.pipelineRisk")}
				dirty={dirty}
				project={projectInfo}
				isDeveloper={isProjectDeveloper}
				disabled={disableAccess}
				onFetch={fetchData}
				onEdit={setEnableEditing}
				onChange={onChangeProjectState}
			/>
			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "105px" }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("RegulatoryRisk.relevance")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} alignItems="top">
					<Grid item xs={12} md={9} sm={8} p={1}>

						<Typography variant="h8" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
							{t("PipelineRisk.relevanceVar1")}
							{"\n"}
							<Typography variant="h8" sx={{ whiteSpace: "pre-line", m: 3, color: "blue.dark" }}>
								{t("PipelineRisk.relevanceVar2")}
								{"\n"}
							</Typography>
							<Typography variant="h8" sx={{ whiteSpace: "pre-line", m: 3, color: "blue.dark" }}>
								{t("PipelineRisk.relevanceVar3")}
								{"\n"}
							</Typography>
							<Typography variant="h8" sx={{ whiteSpace: "pre-line", m: 3, color: "blue.dark" }}>
								{t("PipelineRisk.relevanceVar4")}
								{"\n"}
							</Typography>
							<Typography variant="h8" sx={{ whiteSpace: "pre-line", m: 3, color: "blue.dark" }}>
								{t("PipelineRisk.relevanceVar5")}
								{"\n\n"}
							</Typography>
						</Typography>
						<Grid container spacing={2} sx={{ ml: 1 }}>
							<Grid item xs sx={{ border: 1, borderBottom: 0 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line", mb: 5, color: "blue.dark"}}>
									{t("PipelineRisk.relevanceVar6")}
								</Typography>
							</Grid>
							<Grid item xs sx={{ border: 1, borderLeft: 0, borderBottom: 0 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
									{t("PipelineRisk.relevanceVar7")}
								</Typography>
							</Grid>
							<Grid item xs sx={{ border: 1, borderLeft: 0, borderBottom: 0 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
									{t("PipelineRisk.relevanceVar8")}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={2} sx={{ ml: 1, mt: 0 }}>
							<Grid item xs sx={{ border: 1 }}>
								<LabelTextField
									id="pipeline_risk.contract_installation"
									label=""
									size="colossal"
									// titleAlign="center"
									type='number'
									placeholder="Answer"
									disabled={shouldUiBeDisabled}
									formik={formik}
								/>
							</Grid>
							<Grid item xs sx={{ border: 1, borderLeft: 0 }}>
								<LabelTextField
									id="pipeline_risk.agreement_six_months"
									label=""
									size="colossal"
									// titleAlign="center"
									type='number'
									placeholder="Answer"
									disabled={shouldUiBeDisabled}
									formik={formik}
								/>
							</Grid>
							<Grid item xs sx={{ border: 1, borderLeft: 0 }}>
								<LabelTextField
									id="pipeline_risk.agreement_twelve_months"
									label=""
									size="colossal"
									type='number'
									// titleAlign="center"
									placeholder="Answer"
									disabled={shouldUiBeDisabled}
									formik={formik}
								/>
							</Grid>
						</Grid>
						{/* <Typography variant="h6" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}> */}
						{/* </Grid> */}
						<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems="center">
							<Grid container item xs={12} md={4}>
								<Typography variant="h8" sx={{ textAlign: "justify", color: "blue.dark" }}>
									{t("PipelineRisk.relevanceVar9")}
									{"\n"}
									{/* {infoText && (
											<Tooltip placement="bottom-start" title={infoText}>
												<InfoIcon fontSize="small" sx={{ padding: 0.2, color: "green.main" }} />
											</Tooltip>
										)} */}
								</Typography>
							</Grid>
							<Grid item md={2.4} />

							<Grid item xs={12} md={5}>

								<FormControl className={classes.formControl}>
									<Select
										multiple
										sx={{ minWidth: "450px" }}
										labelId="mutiple-select-label"
										id="pipeline_risk.pipeline_risk_relevant_currencies"
										value={getIn(values, "pipeline_risk.pipeline_risk_relevant_currencies")}
										size="small"
										variant="filled"
										// eslint-disable-next-line no-shadow
										renderValue={(selected) => selected.join(", ")}
										disabled={shouldUiBeDisabled}
										onChange={(event) => {
											const { target: { value } } = event;
											formik.handleChange({
												target: {
													name: "pipeline_risk.pipeline_risk_relevant_currencies",
													value,
												},
											});
											if (value.onChange) {
												value.onChange(event);
											}
										}}
									>
										<MenuItem
											value="all"
											classes={{
												root: isAllSelected ? classes.selectedAll : "",
											}}
										>
											<ListItemIcon>
												<Checkbox
													classes={{ indeterminate: classes.indeterminateColor }}
													indeterminate={
														selected.length > 0 && selected.length < Object.values(currencies).length
													}
												/>
											</ListItemIcon>
											<ListItemText
												classes={{ primary: classes.selectAllText }}
												primary="Select All"
											/>
										</MenuItem>
										{Object.values(currencies).map((option) => (
											<MenuItem key={option} value={option}>
												<ListItemIcon>
													<Checkbox checked={getIn(values, "pipeline_risk.pipeline_risk_relevant_currencies").includes(option)} />
												</ListItemIcon>
												<ListItemText primary={option} />
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					<Grid item md={0.2} />
					<Grid item xs={12} sm={4} md={2} direction="row" alignItems="center">
						<LabelRadio
							id="pipeline_risk.pipeline_risk_relevance"
							label={t("RegulatoryRisk.riskPresent")}
							size="large"
							options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
					</Grid>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("RegulatoryRisk.documentation")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center">
					<LabelFileUpload
						fieldId="pipeline_risk_doc_1"
						projectId={projectid}
						label={t("PipelineRisk.documentationVar1")}
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="riskAssessmentProtocol"
						onSuccess={() => fetchData()}
					/>
				</Grid>
			</Grid>
			{deleteModal.component !== null
				&& deleteModal.variable !== null
				&& (
					<Dialog
						keepMounted
						open={deleteModal.component !== null && deleteModal.variable !== null}
						TransitionComponent={Transition}
						onClose={() => setDeleteModal({ component: null, variable: null })}
					>
						<DialogTitle>
							{"Are you sure?"}
						</DialogTitle>
						<DialogContent dividers>
							<DialogContentText>
								{"Are you sure you want to delete the file?"}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								autoFocus
								startIcon={<Delete />}
								variant="contained"
								onClick={() => {
									onDelete(deleteModal);
									setDeleteModal({ component: null, variable: null });
								}}
							>
								{"Delete"}
							</Button>
							<Button variant="outlined" onClick={() => setDeleteModal({ component: null, variable: null })}>{"Cancel"}</Button>
						</DialogActions>
					</Dialog>
				)}
			<StatusDialog
				open={projectStateDialog}
				status={projectInfo.status}
				name={projectInfo.name}
				onClose={setProjectStateDialog}
				onChange={changeProjectState}
			/>
		</form>
	);
};

export default memo(PipelineRisk);
