import { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getIn } from "formik";
import {
	Grid,
	Typography,
	TextField,
	Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

export const LabelTextField = ({
	id,
	label,
	bold = false,
	type = "text",
	titleAlign = "left",
	rows = 1,
	size: labelSize = "medium",
	placeholder = "",
	disabled = false,
	formik,
	infoText = "",
	typosize = "h6",
	aligntitlebox = "start",
}) => {
	const [size, setSize] = useState({ label: 5, sep: 2, field: 5 });

	const {
		values,
		handleChange,
		errors,
		touched,
	} = formik;

	useEffect(() => {
		switch (labelSize) {
			case "verysmall":
				setSize({ label: 3, sep: 0, field: 6 });
				break;
			case "small":
				setSize({ label: 4, sep: 2, field: 6 });
				break;
			case "medium":
				setSize({ label: 5, sep: 2, field: 5 });
				break;
			case "large":
				setSize({ label: 6, sep: 2, field: 4 });
				break;
			case "colossal":
				setSize({ label: 1, sep: 0, field: 10 });
				break;
			case "flat":
				setSize({ label: 5.5, sep: 0.5, field: 6 });
				break;
			default:
				setSize({ label: 5, sep: 2, field: 5 });
		}
	}, [labelSize]);

	return (
		<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems={aligntitlebox}>
			<Grid item container xs={12} md={size.label} textAlign={titleAlign}>
				<Typography variant={typosize} fontWeight={bold ? "bold" : ""} sx={{ textAlign: "justify", color: "blue.dark" }}>
					{label}
					{infoText && (
						<Tooltip placement="bottom-start" title={infoText}>
							<InfoIcon fontSize="small" sx={{ padding: 0.2, color: "green.main" }} />
						</Tooltip>
					)}
				</Typography>
			</Grid>
			<Grid item md={size.sep} />
			<Grid item xs={12} md={size.field}>
				<TextField
					fullWidth
					hiddenLabel
					multiline={(rows > 1)}
					id={id}
					name={id}
					type={type}
					minRows={rows}
					maxRows={rows}
					size={typosize === "h8" ? "small" : "large"}
					style={{ borderRadius: "30px" }}
					value={getIn(values, id)}
					variant="filled"
					placeholder={placeholder === "" ? label : placeholder}
					error={(getIn(touched, id) && (getIn(errors, id) !== ""))}
					helperText={getIn(errors, id)}
					disabled={disabled}
					onChange={handleChange}
				/>
			</Grid>
		</Grid>
	);
};

LabelTextField.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	bold: PropTypes.bool,
	type: PropTypes.string,
	rows: PropTypes.number,
	titleAlign: PropTypes.string,
	size: PropTypes.string,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	formik: PropTypes.any.isRequired,
	infoText: PropTypes.string,
	typosize: PropTypes.string,
	aligntitlebox: PropTypes.string,
};

export default memo(LabelTextField);
