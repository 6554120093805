import { Divider } from "@mui/material";

const LoginDivider = () => {
	const dividerStyle = {
		width: "280px",
		margin: "5px 0px 5px 0px",
		borderBottom: "2px solid #dddddd",
		borderRight: "2px solid #dddddd",
	};

	return <Divider style={dividerStyle} />;
};

export default LoginDivider;
