import { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, LinearProgress } from "@mui/material";
import Plot from "react-plotly.js";

import { useSnackbar, httpResponseIsValid } from "../utils";
import { getProjectSpreadsheet, getCompanySpreasheet } from "../api";

const 	PlotlyComponent = ({ isProject = true }) => {
	const { projectid } = useParams();
	const [initialFetching, setInitialFetching] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [tempData, setTempData] = useState({});
	const { error } = useSnackbar();

	const fetchData = useCallback(
		async () => {
			try {
				const response = isProject ? await getProjectSpreadsheet(projectid) : await getCompanySpreasheet();
				if (httpResponseIsValid(response)) {
					setTempData(response);
					setIsLoading(false);
					setInitialFetching(false);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				console.log(`Error occured when fetching Financial Assessment's plot data: ${_error}`);
			}
		},
		[error, projectid, isProject],
	);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData, projectid]);

	return (
		<>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			{!initialFetching
			&& (
				<Grid item container direction="row" alignItems="center" justifyContent="center" mt={3} sx={{ "> .MuiGrid-item": { p: 1 } }}>
					<Plot
						data={[
							{
								x: ["Year 3", "Year 2", "Year 1", "Current Year", "Forecast Year 1", "Forecast Year 2"],
								y: [tempData.profit_loss.revenue.year_3,
									tempData.profit_loss.revenue.year_2,
									tempData.profit_loss.revenue.year_1,
									(tempData.profit_loss.revenue.current_quarter_1
									+ tempData.profit_loss.revenue.current_quarter_2
									+ tempData.profit_loss.revenue.current_quarter_3
									+ tempData.profit_loss.revenue.current_quarter_4),
									(tempData.profit_loss.revenue.forecast_1_quarter_1
									+ tempData.profit_loss.revenue.forecast_1_quarter_2
									+ tempData.profit_loss.revenue.forecast_1_quarter_3
									+ tempData.profit_loss.revenue.forecast_1_quarter_4),
									(tempData.profit_loss.revenue.forecast_2_quarter_1
									+ tempData.profit_loss.revenue.forecast_2_quarter_2
									+ tempData.profit_loss.revenue.forecast_2_quarter_3
									+ tempData.profit_loss.revenue.forecast_2_quarter_4)],
								name: "Revenue",
								type: "bar",
								xaxis: {
									dividerwidth: 0.5,
								},
								textposition: "auto",
								marker: {
									color: "#3A85C5" },
							},
							{
								x: ["Year 3", "Year 2", "Year 1", "Current Year", "Forecast Year 1", "Forecast Year 2"],
								y: [tempData.profit_loss.profit_after_tax.year_3,
									tempData.profit_loss.profit_after_tax.year_2,
									tempData.profit_loss.profit_after_tax.year_1,
									(tempData.profit_loss.profit_after_tax.current_quarter_1
									+ tempData.profit_loss.profit_after_tax.current_quarter_2
									+ tempData.profit_loss.profit_after_tax.current_quarter_3
									+ tempData.profit_loss.profit_after_tax.current_quarter_4),
									(tempData.profit_loss.profit_after_tax.forecast_1_quarter_1
									+ tempData.profit_loss.profit_after_tax.forecast_1_quarter_2
									+ tempData.profit_loss.profit_after_tax.forecast_1_quarter_3
									+ tempData.profit_loss.profit_after_tax.forecast_1_quarter_4),
									(tempData.profit_loss.profit_after_tax.forecast_2_quarter_1
									+ tempData.profit_loss.profit_after_tax.forecast_2_quarter_2
									+ tempData.profit_loss.profit_after_tax.forecast_2_quarter_3
									+ tempData.profit_loss.profit_after_tax.forecast_2_quarter_4)],
								name: "Profit after tax",
								xaxis: {
									dividerwidth: 0.5,
								},
								type: "bar",
								marker: {
									color: "#C4C4C4" },
							},
							{
								x: ["Year 3", "Year 2", "Year 1", "Current Year", "Forecast Year 1", "Forecast Year 2"],
								y: [tempData.output.cashflow_output.year_3,
									tempData.output.cashflow_output.year_2,
									tempData.output.cashflow_output.year_1,
									(tempData.output.cashflow_output.current_quarter_1
									+ tempData.output.cashflow_output.current_quarter_2
									+ tempData.output.cashflow_output.current_quarter_3
									+ tempData.output.cashflow_output.current_quarter_4),
									(tempData.output.cashflow_output.forecast_1_quarter_1
									+ tempData.output.cashflow_output.forecast_1_quarter_2
									+ tempData.output.cashflow_output.forecast_1_quarter_3
									+ tempData.output.cashflow_output.forecast_1_quarter_4),
									(tempData.output.cashflow_output.forecast_2_quarter_1
									+ tempData.output.cashflow_output.forecast_2_quarter_2
									+ tempData.output.cashflow_output.forecast_2_quarter_3
									+ tempData.output.cashflow_output.forecast_2_quarter_4)],
								name: "Cashflow",
								type: "scatter",
								xaxis: {
									dividerwidth: 0.5,
									linewidth: 0.5,
								},
								mode: "lines+markers",
								marker: { color: "red" },
							},
						]}
						layout={{ width: 950,
							xaxis: {
								tickangle: 0,
							},
							height: 500,
							bargroupgap: 0.1,
							title: "Actual and forecasted financial results" }}
					/>
				</Grid>
			)}
		</>
	);
};

export default memo(PlotlyComponent);
