import { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, LinearProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import { useSnackbar, httpResponseIsValid } from "../../utils";
import { getProjectRiskAssessentProtocol, updateProjectRiskAssessentProtocol } from "../../api";
import usePrompt from "../../utils/use-blocker";
import StatusDialog from "../../components/StatusDialog";
import {
	MainMenu,
	LabelTextField,
	LabelRadio,
} from "../../components/basic/index";

const PrePaymentRisk = ({ disableAccess = true, isProjectDeveloper = false, onChangeProjectState }) => {
	const { projectid } = useParams();
	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(disableAccess);
	const [projectStateDialog, setProjectStateDialog] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [projectInfo, setProjectInfo] = useState({
		status: "",
		createdAt: "-",
		pre_payment_risk: {
			pre_payment_risk_relevance: "",
			pre_payment_risk_responsibility: "",
			pre_payment_risk_mitigation: "",
			technical_consideration: "",
			economic_consideration: "",
			legal_consideration: "",
		},
	});
	const [enableEditing, setEnableEditing] = useState(false);
	const { success, error } = useSnackbar();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getProjectRiskAssessentProtocol(projectid);
				if (httpResponseIsValid(response)) {
					setProjectInfo(response);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured while fetching risk assessment pre-payment risk data: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, projectid],
	);

	const handleSubmit = async (values) => {
		setIsLoading(true);

		try {
			const response = await updateProjectRiskAssessentProtocol(projectid, values);

			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			error(`Error occured while submitting risk assessment pre-payment risk data: ${_error}`);
		}

		setIsLoading(false);
	};

	const changeProjectState = useCallback(
		async () => {
			await onChangeProjectState();
			fetchData();
		}, [fetchData, onChangeProjectState],
	);

	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: projectInfo,
		onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	});

	const { dirty } = formik;
	usePrompt("You have unsaved changes which will be lost. Are you sure you want to leave the page?", dirty);

	useEffect(() => {
		setShouldUiBeDisabled(disableAccess || !enableEditing);
	}, [disableAccess, enableEditing]);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData, projectid]);

	return (
		<form onSubmit={formik.handleSubmit}>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<MainMenu
				title={t("RiskAssessmentOverview.prepaymentRisk")}
				dirty={dirty}
				project={projectInfo}
				isDeveloper={isProjectDeveloper}
				disabled={disableAccess}
				onFetch={fetchData}
				onEdit={setEnableEditing}
				onChange={onChangeProjectState}
			/>
			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "105px" }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("RegulatoryRisk.relevance")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} alignItems="top">
					<Grid item xs={12} md={9} sm={8} p={1}>
						<Typography variant="h8" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
							{t("PrePaymentRisk.relevanceVar1")}
							{"\n\n"}
							{t("PrePaymentRisk.relevanceVar2")}
							{"\n"}
						</Typography>
						<LabelTextField
							id="pre_payment_risk.technical_consideration"
							label={t("PrePaymentRisk.relevanceVar3")}
							size="flat"
							typosize="h8"
							aligntitlebox="center"
							placeholder="Answer"
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
						<LabelTextField
							id="pre_payment_risk.economic_consideration"
							label={t("PrePaymentRisk.relevanceVar4")}
							size="flat"
							typosize="h8"
							aligntitlebox="center"
							placeholder="Answer"
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
						<LabelTextField
							id="pre_payment_risk.legal_consideration"
							label={t("PrePaymentRisk.relevanceVar5")}
							size="flat"
							typosize="h8"
							aligntitlebox="center"
							placeholder="Answer"
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
					</Grid>
					<Grid item md={0.2} />
					<Grid item xs={12} sm={4} md={2} direction="row" alignItems="center">
						<LabelRadio
							id="pre_payment_risk.prepayment_risk_relevance"
							label={t("RegulatoryRisk.riskPresent")}
							size="large"
							options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
					</Grid>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("RegulatoryRisk.responsibility")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center">
					<Grid item xs={12} md={9} sm={8} p={1}>
						<Typography variant="h8" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
							{t("PrePaymentRisk.responsibilityVar1")}
						</Typography>
					</Grid>
					<Grid item md={0.2} />
					<Grid item xs={12} sm={4} md={2} direction="row" alignItems="center">
						<LabelRadio
							id="pre_payment_risk.prepayment_risk_responsibility"
							label={t("RegulatoryRisk.riskBearer")}
							size="large"
							options={[{ value: "false", label: "Client" }, { value: "true", label: "Contractor" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
					</Grid>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("EnergyPriceRisk.mitigation")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} alignItems="center">
					<Grid item xs={12} md={9} sm={8} p={1}>
						<Typography variant="h8" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
							{t("PrePaymentRisk.mitigationVar1")}
						</Typography>
					</Grid>
					<Grid item md={0.2} />
					<Grid item xs={12} sm={4} md={2} direction="row" alignItems="center">
						<LabelRadio
							id="pre_payment_risk.prepayment_risk_mitigation"
							label={t("EnergyPriceRisk.fullyMitigated")}
							size="large"
							options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
					</Grid>
				</Grid>
			</Grid>
			<StatusDialog
				open={projectStateDialog}
				status={projectInfo.status}
				name={projectInfo.name}
				onClose={setProjectStateDialog}
				onChange={changeProjectState}
			/>
		</form>
	);
};

export default memo(PrePaymentRisk);
