/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	Grid,
	LinearProgress,
	Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { httpResponseIsValid, useSnackbar } from "../../utils";
import usePrompt from "../../utils/use-blocker";
import {
	MainMenu,
	LabelTextField,
	LabelDropdown,
	LabelFileUpload,
} from "../../components/basic/index";
import {
	getProjectLoanApplicationCompanyOverview,
	updateProjectLoanApplicationCompanyOverview,
} from "../../api";

const LoanApplicationCompanyOverview = ({ disableAccess = true, isProjectDeveloper = false, onChangeProjectState }) => {
	const { projectid } = useParams();

	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(disableAccess);
	const [isLoading, setIsLoading] = useState(false);

	const [options, setOptions] = useState({
		typeOfFinance: {},
	});
	const [project, setProject] = useState({
		name: "",
		status: "-",
		createdAt: "-",
		data: {
			esco_name: "",
			asset_financed: "",
			maintenance_intergrated: "",
			vendor_of_asset: "",
			type_of_financing: "",
			amount: "",
			tenor: "",
			residual_value: "",
			refurbish_asset: "",
		},
		docs: {
			payment_schedule: {
				original_name: "",
				save_name: "",
			},
			economics_of_request: {
				original_name: "",
				save_name: "",
			},
		},
	});

	const [enableEditing, setEnableEditing] = useState(false);
	const { success, error } = useSnackbar();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getProjectLoanApplicationCompanyOverview(projectid);
				if (httpResponseIsValid(response)) {
					setOptions(response.options);
					setProject(response.project);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				console.log(`Error occured when fetching loan's appplication company overview data: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, projectid],
	);

	const handleSubmit = async (values) => {
		setIsLoading(true);

		try {
			const response = await updateProjectLoanApplicationCompanyOverview(projectid, values);
			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			console.log(`Error occured when updating loan's appplication company overview data: ${_error}`);
		}

		setIsLoading(false);
	};

	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: project,
		onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	});

	const { dirty } = formik;

	usePrompt("You have unsaved changes which will be lost. Are you sure you want to leave the page?", dirty);

	useEffect(() => {
		setShouldUiBeDisabled(disableAccess || !enableEditing);
	}, [disableAccess, enableEditing]);

	useEffect(() => {
		formik.resetForm({ values: project, dirty: false });
	}, [project]);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData, projectid]);

	return (
		<form onSubmit={formik.handleSubmit}>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<MainMenu
				title={t("LoanApplication.CompanyOverview.title")}
				dirty={dirty}
				project={project}
				isDeveloper={isProjectDeveloper}
				disabled={disableAccess}
				onFetch={fetchData}
				onEdit={setEnableEditing}
				onChange={onChangeProjectState}
			/>

			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "105px" }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("LoanApplication.CompanyOverview.CompanyOverview.title")}
					</Typography>
				</Grid>

				<LabelTextField
					disabled
					id="data.esco_name"
					label={t("LoanApplication.CompanyOverview.CompanyOverview.escoName")}
					size="large"
					placeholder="Answer"
					formik={formik}
				/>

				<LabelTextField
					id="data.asset_financed"
					label={t("LoanApplication.CompanyOverview.CompanyOverview.assetFinanced")}
					size="large"
					placeholder="Answer"
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>

				<LabelTextField
					id="data.maintenance_intergrated"
					label={t("LoanApplication.CompanyOverview.CompanyOverview.maintenanceIntergrated")}
					size="large"
					placeholder="Answer"
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>

				<LabelTextField
					id="data.vendor_of_asset"
					label={t("LoanApplication.CompanyOverview.CompanyOverview.vendorOfAsset")}
					size="large"
					placeholder="Answer"
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>

				<LabelDropdown
					id="data.type_of_financing"
					size="large"
					label={t("LoanApplication.CompanyOverview.CompanyOverview.typeOfFinancing")}
					options={options.typeOfFinance}
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>

				<LabelTextField
					id="data.amount"
					label={t("LoanApplication.CompanyOverview.CompanyOverview.amount")}
					size="large"
					type="number"
					placeholder="Answer"
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>

				<LabelTextField
					id="data.tenor"
					label={t("LoanApplication.CompanyOverview.CompanyOverview.tenor")}
					size="large"
					placeholder="Answer"
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>

				<LabelFileUpload
					fieldId="docs.payment_schedule"
					projectId={projectid}
					label={t("LoanApplication.CompanyOverview.CompanyOverview.doc1")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="loanApplication"
					onSuccess={() => fetchData()}
				/>

				<LabelTextField
					id="data.residual_value"
					label={t("LoanApplication.CompanyOverview.CompanyOverview.residualValue")}
					size="large"
					placeholder="Answer"
					disabled={shouldUiBeDisabled}
					formik={formik}
				/>

				<LabelTextField
					id="data.refurbish_asset"
					label={t("LoanApplication.CompanyOverview.CompanyOverview.refurbishAsset")}
					size="large"
					placeholder="Answer"
					disabled={shouldUiBeDisabled}
					infoText={t("LoanApplication.CompanyOverview.CompanyOverview.info1")}
					formik={formik}
				/>

				<LabelFileUpload
					fieldId="docs.economics_of_request"
					projectId={projectid}
					label={t("LoanApplication.CompanyOverview.CompanyOverview.doc2")}
					size="large"
					disabled={shouldUiBeDisabled}
					formik={formik}
					fileTool="loanApplication"
					infoText={t("LoanApplication.CompanyOverview.CompanyOverview.info2")}
					onSuccess={() => fetchData()}
				/>
			</Grid>
		</form>
	);
};

export default memo(LoanApplicationCompanyOverview);
