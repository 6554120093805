import { StrictMode, useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, useLocation, Routes, BrowserRouter as Router } from "react-router-dom";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import * as Sentry from "@sentry/browser";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SWRConfig } from "swr";
import { ErrorBoundary } from "react-error-boundary";
import { Alert, Button, CssBaseline, Slide, Snackbar as MaterialSnackbar } from "@mui/material";

import "./index.scss";

import sefaTheme from "./utils/theme";
import Protected from "./components/Protected";
import GuestOnly from "./components/GuestOnly";
import ErrorFallback from "./components/ErrorFallback";
import Snackbar from "./components/Snackbar";
import "./components/i18n";
import NotFound from "./screens/NotFound";
import Home from "./screens/Home";
import SignUp from "./screens/SignUp";
import ForgotPassword from "./screens/ForgotPassword";
import ResetPassword from "./screens/ResetPassword";
import Auth from "./screens/Auth";
import Company from "./screens/Company";
import CompanyTools from "./screens/CompanyTools";
import Profile from "./screens/Profile";
import Projects from "./screens/Projects";
import LearningHub from "./screens/LearningHub";
import Invitations from "./screens/Invitations";
import InvestorRequests from "./screens/InvestorRequests";
import Pipelines from "./screens/Pipelines";
import Project from "./screens/Project";
import AdminUsers from "./screens/AdminUsers";
import * as serviceWorkerRegistration from "./service-worker-registration";
import api from "./api";

const {
	NODE_ENV,
	REACT_APP_SENTRY_DSN,
	REACT_APP_SENTRY_ENVIRONMENT,
} = process.env;

Sentry.init({
	dsn: REACT_APP_SENTRY_DSN,
	environment: REACT_APP_SENTRY_ENVIRONMENT,
	ignoreErrors: [
		"ResizeObserver loop limit exceeded",
		"Non-Error promise rejection captured",
	],
	enabled: NODE_ENV === "production",
});
const swrConfig = { revalidateOnFocus: false, shouldRetryOnError: false, fetcher: (url) => api.get(url) };

const App = () => {
	const { pathname } = useLocation();
	const [waitingWorker, setWaitingWorker] = useState({});
	const [newVersionAvailable, setNewVersionAvailable] = useState(false);

	const updateSW = useCallback(() => {
		waitingWorker?.postMessage({ type: "SKIP_WAITING" });
		setNewVersionAvailable(false);
		window.location.reload();
	}, [waitingWorker]);

	useEffect(() => {
		serviceWorkerRegistration.register({
			onUpdate: (reg) => {
				setWaitingWorker(reg?.waiting);
				setNewVersionAvailable(true);
			},
		});
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return (
		<StyledEngineProvider injectFirst>
			<CssBaseline />
			<ThemeProvider theme={sefaTheme}>
				<ErrorBoundary FallbackComponent={ErrorFallback}>
					<SWRConfig value={swrConfig}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<main style={{ position: "relative", zIndex: 0 }}>
								<Routes>
									<Route index element={<GuestOnly c={<Home />} />} />
									<Route path="/:email" element={<GuestOnly c={<Home />} />} />
									<Route path="sign-up" element={<GuestOnly c={<SignUp />} />} />
									<Route path="sign-up/:email" element={<GuestOnly c={<SignUp />} />} />
									<Route path="forgot-password" element={<GuestOnly c={<ForgotPassword />} />} />
									<Route path="reset-password" element={<GuestOnly c={<ResetPassword />} />} />
									<Route path="reset-password/:email" element={<GuestOnly c={<ResetPassword />} />} />
									<Route path="auth" element={<GuestOnly c={<Auth />} />} />
									<Route path="pipelines/*" element={<Protected c={<Pipelines />} />} />
									<Route path="projects/*" element={<Protected c={<Projects />} />} />
									<Route path="AdminUsers" element={<Protected c={<AdminUsers />} />} />
									<Route path="invitations" element={<Protected c={<Invitations />} />} />
									<Route path="investor-requests" element={<Protected c={<InvestorRequests />} />} />
									<Route path="project/:projectid/*" element={<Protected c={<Project />} />} />
									<Route path="learning-hub/*" element={<Protected c={<LearningHub />} />} />
									<Route path="company" element={<Protected c={<Company />} />} />
									<Route path="company/*" element={<Protected c={<CompanyTools />} />} />
									<Route path="profile" element={<Protected c={<Profile />} />} />
									<Route path="*" element={<Protected c={<NotFound />} />} />
								</Routes>
							</main>
							<Snackbar />
							<MaterialSnackbar
								open={newVersionAvailable}
								anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
								TransitionComponent={Slide}
								TransitionProps={{ direction: "up" }}
							>
								<Alert
									variant="filled"
									severity="info"
									sx={{ alignItems: "center", ".MuiAlert-action": { p: 0 } }}
									action={(
										<Button color="inherit" size="small" sx={{ p: 1 }} onClick={updateSW}>
											{"update"}
										</Button>
									)}
								>
									{"A new version is available!"}
								</Alert>
							</MaterialSnackbar>
						</LocalizationProvider>
					</SWRConfig>
				</ErrorBoundary>
			</ThemeProvider>
		</StyledEngineProvider>
	);
};

ReactDOM.render(<StrictMode><Router><App /></Router></StrictMode>, document.querySelector("#root"));
