import { useCallback, memo, useState, useEffect, forwardRef } from "react";
import {
	Button,
	Typography,
	Grid,
	LinearProgress,
	FormControl,
	FilledInput,
	FormHelperText,
	Box,
	Switch,
	Slide,
	InputAdornment,
	Dialog,
	IconButton,
	DialogTitle,
	DialogContent,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import * as Yup from "yup";

import TextField from "../components/TextField";
import { useSnackbar, httpResponseIsValid } from "../utils";
import { updateUserProfile, getUserProfile } from "../api";

const Transition = forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const useStyles = makeStyles((theme) => ({
	header: {
		color: theme.palette.green.main,
	},
	page: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
	boxBorder: {
		border: `2px solid ${theme.palette.primary.main}`,
		borderRadius: theme.spacing(2),
		backgroundColor: "white",
	},
	textFieldStyle: {
		padding: "5px",
	},
	screenTitle: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		letterSpacing: theme.spacing(0.2),
		marginLeft: "10px",
	},
}));

const passwordMinLength = 8;

// const projectTaxonomies = [
// 	"Building Fabric Measure",
// 	"Electric Power System Measure",
// 	"HVAC And Hot Water Measure",
// 	"Lighting Measure",
// 	"Management Measure",
// 	"Refrigeration Systems Measure",
// 	"Renewable Generation Measure",
// 	"Heating And Cooling Control Measure",
// 	"Other",
// ];

const UserProfile = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(false);
	const [enableEditing, setEnableEditing] = useState(false);
	const [hasChanged, setHasChanged] = useState(false);
	const { success, error } = useSnackbar();

	const [passwordEditModal, setPasswordEditModal] = useState(null);
	const [password, setPassword] = useState({
		old: { value: "", show: false, error: "" },
		confirm: { value: "", show: false, error: "" },
		new: { value: "", show: false, error: "" },
		show: false,
	});

	const [userInfo, setUserInfo] = useState({
		createdAt: "",
		name: "",
		phone_number: "",
		role: "",
		active_countries: "",
		technologies: "",
		minimum_project_size: "",
		verified: "",
		email: "",
	});

	const [tempUserProfileInfo, setTempUserProfileInfo] = useState({
		createdAt: "",
		name: "",
		phone_number: "",
		role: "",
		active_countries: "",
		technologies: "",
		minimum_project_size: "",
		verified: "",
		email: "",
	});

	const [userInfoErrors, setUserInfoErrors] = useState({
		name: "",
		phone_number: "",
		email: "",
		old_password: "",
		new_password: "",
		active_countries: "",
		technologies: "",
		minimum_project_size: "",
	});

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getUserProfile();
				if (httpResponseIsValid(response)) {
					setUserInfo({
						createdAt: response?.createdAt,
						name: response?.name,
						phone_number: response?.phone_number,
						role: response?.role,
						active_countries: response?.active_countries,
						technologies: response?.technologies,
						minimum_project_size: response?.minimum_project_size,
						verified: response?.verified,
						email: response?.email,
					});

					setHasChanged(false);
					setEnableEditing(false);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured while fetching user data: ${_error}`);
			}

			setIsLoading(false);
		},
		[error],
	);

	const onUserInfoChange = (e, name) => {
		setTempUserProfileInfo({
			...tempUserProfileInfo,
			[name]: e.target.value,
		});

		if (name === "confirm_new_password") {
			setUserInfoErrors({
				...userInfoErrors,
				new_password: "",
			});
		} else {
			setUserInfoErrors({
				...userInfoErrors,
				[name]: "",
			});
		}
	};

	const roleToUpperCase = () => {
		switch (tempUserProfileInfo.role) {
			case "owner":
				return "Owner";
			case "member":
				return "Member";
			case "investor_member":
				return "Investor Member";
			case "investor_owner":
				return "Investor Owner";
			case "admin":
				return "Sefa Admin";
			default:
				return "Unknown";
		}
	};

	const onUserInfoSave = async () => {
		setIsLoading(true);

		try {
			let errosExist = false;

			const errors = {
				name: "",
				phone_number: "",
				email: "",
				active_countries: "",
				technologies: "",
				minimum_project_size: "",
			};

			if (tempUserProfileInfo.name === "") {
				errors.name = "Profile name cannot be empty.";
				errosExist = true;
			}

			if (tempUserProfileInfo.phone_number === "") {
				errors.phone_number = "Profile phone number cannot be empty.";
				errosExist = true;
			}

			const validationSchema = Yup.object().shape({
				email: Yup.string().trim().email().required(),
			});

			const isEmailValid = await validationSchema.isValid({ email: tempUserProfileInfo.email });

			if (!isEmailValid) {
				errors.email = "Profile email wrong format.";
				errosExist = true;
			}

			if (errosExist) {
				setUserInfoErrors(errors);
				return;
			}

			const response = await updateUserProfile({
				...tempUserProfileInfo,
				old_password: password.old.value,
				new_password: password.new.value,
			});

			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			console.log(`Internal error while updating user profile: ${_error}`);
		}

		setIsLoading(false);
	};

	const onPasswordChange = (type, newValue) => {
		switch (type) {
			case "old":
				setPassword({
					...password,
					old: { ...password.old, value: newValue, error: "" },
					new: { ...password.new, error: "" },
					confirm: { ...password.confirm, error: "" },
				});
				break;
			case "new":
				setPassword({
					...password,
					old: { ...password.old, error: "" },
					new: { ...password.new, value: newValue, error: "" },
					confirm: { ...password.confirm, error: "" },
				});
				break;
			case "confirm":
				setPassword({
					...password,
					old: { ...password.old, error: "" },
					new: { ...password.new, error: "" },
					confirm: { ...password.confirm, value: newValue, error: "" },
				});
				break;
			default:
				break;
		}
	};

	const onSavePasswordCahnges = () => {
		const passwordErrors = {};

		if (password.old.value === "") {
			passwordErrors.old = "Old password field cannot be empty.";
		} else if (password.old.value.length < passwordMinLength) {
			passwordErrors.old = "Old password field must be at least 8 characters.";
		}

		if (password.new.value === "") {
			passwordErrors.new = "New password field cannot be empty.";
		} else if (password.new.value.length < passwordMinLength) {
			passwordErrors.new = "New password field must be at least 8 characters.";
		} else if (password.new.value === password.old.value) {
			passwordErrors.new = "New password cannot must the old password.";
		} else if (password.new.value !== password.confirm.value) {
			passwordErrors.new = "New password and Confirm new password must be equal.";
		}

		if (password.confirm.value === "") {
			passwordErrors.confirm = "Confirm new password field cannot be empty.";
		} else if (password.confirm.value.length < passwordMinLength) {
			passwordErrors.confirm = "Confirm new password field must be at least 8 characters.";
		} else if (password.confirm.value === password.old.value) {
			passwordErrors.confirm = "Confirm new password cannot must the old password.";
		} else if (password.confirm.value !== password.new.value) {
			passwordErrors.confirm = "Confirm new password and New password must be equal.";
		}

		if (Object.keys(passwordErrors).length > 0) {
			setPassword({
				old: { ...password.old, error: passwordErrors?.old || "" },
				new: { ...password.new, error: passwordErrors?.new || "" },
				confirm: { ...password.confirm, error: passwordErrors?.confirm || "" },
			});

			return;
		}

		setPassword({
			old: { ...password.old, error: "" },
			new: { ...password.new, error: "" },
			confirm: { ...password.confirm, error: "" },
		});

		setPasswordEditModal(null);
	};

	const onOpenPasswordEdit = () => {
		if (password.new.value === "") {
			setPassword({
				old: { value: "", show: false },
				confirm: { value: "", show: false },
				new: { value: "", show: false },
			});
		} else {
			setPassword({
				old: { ...password.old, show: false },
				confirm: { ...password.confirm, show: false },
				new: { ...password.new, show: false },
			});
		}

		setPasswordEditModal("edit");
	};

	const onCancelPasswordChanges = () => {
		setPassword({
			old: { value: "", show: false, error: "" },
			confirm: { value: "", show: false, error: "" },
			new: { value: "", show: false, error: "" },
			show: false,
		});

		setPasswordEditModal(null);
	};

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData]);

	useEffect(() => {
		setHasChanged(false);

		setTempUserProfileInfo({
			createdAt: userInfo.createdAt,
			name: userInfo.name,
			phone_number: userInfo.phone_number,
			role: userInfo.role,
			active_countries: userInfo.active_countries,
			technologies: userInfo.technologies,
			minimum_project_size: userInfo.minimum_project_size,
			verified: userInfo.verified,
			email: userInfo.email,
		});
	}, [userInfo]);

	useEffect(() => {
		if (JSON.stringify(userInfo) === JSON.stringify(tempUserProfileInfo) && (password.new.value === "")) {
			setHasChanged(false);
		} else {
			setHasChanged(true);
		}
	}, [tempUserProfileInfo, userInfo, password.new]);

	useEffect(() => {
		setShouldUiBeDisabled(!enableEditing);
	}, [enableEditing]);

	const classes = useStyles();

	return (
		<>
			{isLoading && (<LinearProgress color="primary" />)}
			<section style={{ paddingTop: "1rem" }}>
				<div className={clsx("container", classes.page)}>
					<Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ "> .MuiGrid-item": { p: 0 }, background: "#F4F4F4", borderRadius: "10px 10px 10px 10px", padding: "8px" }}>
						<Grid item hidden />
						<Grid item>
							<Typography variant="h5" display="flex" alignItems="center" className={classes.screenTitle}>
								{"USER PROFILE"}
							</Typography>
						</Grid>
						<Grid item xs={0} md={4} />
						<Grid item container className={classes.boxBorder} sx={{ p: 1 }} xs={12} md={4}>
							<Grid item xs={12} md={6} />
							<Grid item xs={12} md={6}>
								<Box p={1}>
									<Grid container direction="row" justifyContent="flex-end" alignItems="center">
										<Typography variant="h6">
											{"Today: "}
											<span style={{ marginLeft: "15px" }}>{(new Date()).toLocaleDateString("en-US")}</span>
										</Typography>
									</Grid>
								</Box>
							</Grid>
						</Grid>
						<Grid item container justifyContent="end" sx={{ p: 1, marginTop: "5px" }} xs={12} md={12}>
							<Grid item xs={0} md={8} />
							<Grid item container sx={{ p: 1 }} xs={12} md={4} alignItems="center">
								<Grid item xs={12} md={5}>
									<Box px={1}>
										<Grid container direction="row" justifyContent="end" alignItems="center" p={0}>
											<Grid item xs={3}>
												<Typography variant="h6" fontSize="medium">{"Edit: "}</Typography>
											</Grid>
											<Grid item xs={9}>
												<Switch
													color="primary"
													size="small"
													checked={enableEditing}
													onChange={(e) => setEnableEditing(e.target.checked)}
												/>
											</Grid>
										</Grid>
									</Box>
								</Grid>
								<Grid item xs={12} md={7}>
									<Box px={1} display="flex" flexDirection="row">
										<Grid container direction="row" justifyContent="flex-end" alignItems="center">
											<Button
												variant="contained"
												size="medium"
												sx={{ width: "100px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
												disabled={!enableEditing || !hasChanged}
												onClick={onUserInfoSave}
											>
												{"SAVE"}
											</Button>
										</Grid>
									</Box>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid container p={1} sx={{ "> .MuiGrid-item": { p: 1 } }}>
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} mb={2} bgcolor="#1d4363">
							<Typography variant="h6" color="common.white" alignItems="center">
								{"INFORMATION"}
							</Typography>
						</Grid>
						<Grid container direction="row" justifyContent="space-between" alignItems="start" mb={2}>
							<Grid item container sx={{ p: 1 }}>
								<Grid item xs={12} md={4} p={1}>
									<Typography variant="h6" sx={{ p: 1 }}>
										{"Full Name"}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={5} md={8} alignItems="center">
									<TextField
										className={classes.textFieldStyle}
										size="large"
										value={tempUserProfileInfo.name}
										variant="filled"
										disabled={shouldUiBeDisabled}
										error={userInfoErrors.name !== ""}
										helperText={userInfoErrors.name === "" ? "" : userInfoErrors.name}
										onChange={(e) => onUserInfoChange(e, "name")}
									/>
								</Grid>
							</Grid>
							<Grid item container sx={{ p: 1 }}>
								<Grid item xs={12} md={4} p={1}>
									<Typography variant="h6" sx={{ p: 1 }}>
										{"Email"}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={5} md={8} alignItems="center">
									<TextField
										className={classes.textFieldStyle}
										size="large"
										value={tempUserProfileInfo.email}
										variant="filled"
										disabled={shouldUiBeDisabled || tempUserProfileInfo.role === "admin"}
										error={userInfoErrors.email !== ""}
										helperText={userInfoErrors.email === "" ? "" : userInfoErrors.email}
										onChange={(e) => onUserInfoChange(e, "email")}
									/>
								</Grid>
							</Grid>
							<Grid item container sx={{ p: 1 }}>
								<Grid item xs={12} md={4} p={1}>
									<Typography variant="h6" sx={{ p: 1 }}>
										{"Phone Number"}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={5} md={8} alignItems="center">
									<TextField
										id="user_profile_phone_number"
										autoComplete='off'
										className={classes.textFieldStyle}
										size="large"
										value={tempUserProfileInfo.phone_number}
										variant="filled"
										disabled={shouldUiBeDisabled}
										error={userInfoErrors.phone_number !== ""}
										helperText={userInfoErrors.phone_number === "" ? "" : userInfoErrors.phone_number}
										onChange={(e) => onUserInfoChange(e, "phone_number")}
									/>
								</Grid>
							</Grid>
							<Grid item container sx={{ p: 1 }}>
								<Grid item xs={12} md={4} p={1}>
									<Typography variant="h6" sx={{ p: 1 }}>
										{"User Role"}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={5} md={8} alignItems="center">
									<TextField
										disabled
										className={classes.textFieldStyle}
										size="large"
										value={roleToUpperCase()}
										variant="filled"
									/>
								</Grid>
							</Grid>
							<Grid item container>
								<Grid container item xs={12} md={4} p={1}>
									<Typography variant="h6" sx={{ p: 2 }}>
										{"Password"}
									</Typography>
									{password.new.value !== ""
										&& (
											<Typography color="#3d8f7c" variant="h6" sx={{ p: 2, marginLeft: "-25px" }}>
												{"[changed]"}
											</Typography>
										)}
								</Grid>
								<Grid item xs={12} md={4} p={1}>
									<Button
										variant="outlined"
										size="medium"
										color="primary"
										sx={{ width: "865px", height: "50px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" } }}
										disabled={shouldUiBeDisabled}
										onClick={onOpenPasswordEdit}
									>
										{`${password.new.value === "" ? "CHANGE" : "EDIT"} PASSWORD`}
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
			</section>
			{passwordEditModal !== null
				&& (
					<Dialog
						keepMounted
						open={passwordEditModal !== null}
						TransitionComponent={Transition}
						scroll="body"
						maxWidth="md"
						PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4", width: "800px" } }}
					>
						<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
							<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
								{"Change Password"}
							</Typography>
						</DialogTitle>
						<DialogContent dividers sx={{ overflowY: "hidden" }}>
							<Grid item container sx={{ marginTop: "10px" }} alignItems="center">
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ p: 1, color: "#1d4363" }}>
										{"Old Password"}
									</Typography>
								</Grid>
								<Grid item md={8}>
									<FormControl sx={{ width: "500px" }} variant="standard">
										<FilledInput
											hiddenLabel
											id="standard-adornment-password"
											type={password.old.show ? "text" : "password"}
											value={password.old.value}
											endAdornment={(
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={() => setPassword({ ...password, old: { ...password.old, show: !password.old.show } })}
													>
														{password.old.show ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											)}
											error={password.old.error}
											onChange={(e) => onPasswordChange("old", e.target.value)}
										/>
										<FormHelperText error={password.old.error}>{password.old.error}</FormHelperText>
									</FormControl>
								</Grid>
							</Grid>
							<Grid item container sx={{ marginTop: "10px" }} alignItems="center">
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ p: 1, color: "#1d4363" }}>
										{"New Password"}
									</Typography>
								</Grid>
								<Grid item md={8}>
									<FormControl sx={{ width: "500px" }} variant="standard">
										<FilledInput
											hiddenLabel
											id="standard-adornment-password"
											type={password.new.show ? "text" : "password"}
											value={password.new.value}
											endAdornment={(
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={() => setPassword({ ...password, new: { ...password.new, show: !password.new.show } })}
													>
														{password.new.show ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											)}
											error={password.new.error}
											onChange={(e) => onPasswordChange("new", e.target.value)}
										/>
										<FormHelperText error={password.new.error}>{password.new.error}</FormHelperText>
									</FormControl>
								</Grid>
							</Grid>
							<Grid item container sx={{ marginTop: "10px" }} alignItems="center">
								<Grid item xs={12} md={4}>
									<Typography variant="h6" fontWeight="bold" sx={{ p: 1, color: "#1d4363" }}>
										{"Confirm New Password"}
									</Typography>
								</Grid>
								<Grid item md={8}>
									<FormControl sx={{ width: "500px" }} variant="standard">
										<FilledInput
											hiddenLabel
											id="standard-adornment-password"
											type={password.confirm.show ? "text" : "password"}
											value={password.confirm.value}
											endAdornment={(
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														// eslint-disable-next-line max-len
														onClick={() => setPassword({ ...password, confirm: { ...password.confirm, show: !password.confirm.show } })}
													>
														{password.confirm.show ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											)}
											error={password.confirm.error}
											onChange={(e) => onPasswordChange("confirm", e.target.value)}
										/>
										<FormHelperText error={password.confirm.error}>{password.confirm.error}</FormHelperText>
									</FormControl>
								</Grid>
							</Grid>
							<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="25px">
								<Grid item sm={3} display="flex" justifyContent="start">
									<Button
										fullWidth
										variant="outlined"
										sx={{ width: "180px", height: "45px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
										color="primary"
										onClick={() => onCancelPasswordChanges()}
									>
										{"Cancel Changes"}
									</Button>
								</Grid>
								<Grid item display="flex" sm={3} justifyContent="end">
									<Button
										fullWidth
										variant="contained"
										sx={{ width: "180px", height: "45px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
										color="primary"
										onClick={onSavePasswordCahnges}
									>
										{"Keep Changes"}
									</Button>
								</Grid>
							</Grid>
						</DialogContent>
					</Dialog>
				)}
		</>
	);
};

export default memo(UserProfile);
