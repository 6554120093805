
import { Button as MuiButton, Typography } from "@mui/material";
import PropTypes from "prop-types";

import sefaTheme, { themeColors } from "../../utils/theme";

export const ContainedButton = ({ label = "ContainedButton", color = themeColors[0], ...props }) => (
	<MuiButton
		variant="contained"
		sx={{
			borderRadius: "17px",
			border: `1px solid ${sefaTheme.palette[color].main}`,
			backgroundColor: `${sefaTheme.palette[color].light}`,
			color: `${sefaTheme.palette[color].neutral}`,
			"&:hover": {
				borderColor: `${sefaTheme.palette[color].dark}`,
				backgroundColor: `${sefaTheme.palette[color].dark}`,
				color: `${sefaTheme.palette[color].neutral}`,
			},
			"&:disabled": {
				border: "1px solid",
			},
		}}
		{...props}
	>
		<Typography sx={{ ...sefaTheme.typography }}>{label}</Typography>
	</MuiButton>
);

ContainedButton.propTypes = {
	label: PropTypes.string,
	color: PropTypes.oneOf(themeColors),
};

export const OutlinedButton = ({ label = "OutlinedButton", color = themeColors[0], ...props }) => (
	<MuiButton
		variant="contained"
		sx={{
			borderRadius: "17px",
			border: `1px solid ${sefaTheme.palette[color].main}`,
			backgroundColor: `${sefaTheme.palette[color].neutral}`,
			color: `${sefaTheme.palette[color].dark}`,
			"&:hover": {
				borderColor: `${sefaTheme.palette[color].dark}`,
				backgroundColor: `${sefaTheme.palette[color].dark}`,
				color: `${sefaTheme.palette[color].neutral}`,
			},
			"&:disabled": {
				border: "1px solid",
			},
		}}
		{...props}
	>
		<Typography sx={{ ...sefaTheme.typography }}>{label}</Typography>
	</MuiButton>
);

OutlinedButton.propTypes = {
	label: PropTypes.string,
	color: PropTypes.oneOf(themeColors),
};
