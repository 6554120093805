
module.exports = {
	RiskAssessmentOverview: {
		riskAssessmentOverview: "Riepilogo di valutazione di rischio",
		riskTitle: "Titolo di rischio",
		riskPresence: "presenza di rischio",
		action: "azione",
		manage: "gestire",
		creditRiskServiceProvider: "Rischio di credito del fornitore di servizi/contraente",
		creditRiskImplementorClient: "Rischio di credito dell'implementatore/cliente finale",
		regulatoryRisk: "Rischi normativi",
		marketRisk: "Rischio di mercato",
		energyPriceRisk: "Rischio del prezzo dell'energia",
		currencyRisk: "Rischio di cambio",
		technicalRisk: "Rischio tecnico",
		performanceRisk: "Rischio di performance",
		omRisk: "Rischio O&M",
		interfaceRisk: "Rischio di interfaccia",
		pipelineRisk: "Rischio di pipeline",
		prepaymentRisk: "Rischio di pagamento anticipato",
		occupacyRisk: "Rischio di occupazione",
		managementRisk: "Rischio di gestione",
		changeInControlRisk: "Rischio di cambio nel controllo",
		constructionRisk: "Rischio di costruzione",
		supplyChainRisk: "Rischio della catena di approvvigionamento",
	},
	CreditRiskServiceProvider: {
		riskTitle: "Rischio di credito del fornitore di servizi/contraente",
		relevance: "PERTINENZA",
		formula: "Formula",
		year1: "Anno 1",
		kpiYear1: "KPI Anno 1",
		year2: "Anno 2",
		kpiYear2: "KPI Anno 2",
		year3: "Anno 3",
		kpiYear3: "KPI Anno 3",
		liquidity: "Liquidità",
		currentRatio: "Rapporto di liquidità corrente (current ratio)",
		quickRatio: "Rapporto rapido di liquidità (quick ratio/acid test)",
		comparison: "Confronto",
		solvency: "Solvibilità",
		solvencyRatio: "Rapporto di solvibilità",
		profitability: "Redditività",
		netProfitMargin: "Margine (utile) netto",
		commercialProfitability: "Redditività commerciale",
		repaymentCapacityRatio: "Rapporto di restituzione",
		supplyChainRatios: "Rapporto di fornitura",
		workingCapital: "Capitale operativo",
		totalScore: "Somma Totale",
	},
	CreditRiskImplementorClient: {
		riskTitle: "Rischio di credito del cliente finale",
		relevance: "PERTINENZA",
		formula: "Formula",
		year1: "Anno 1",
		kpiYear1: "KPI Anno 1",
		year2: "Anno 2",
		kpiYear2: "KPI Anno 2",
		year3: "Anno 3",
		kpiYear3: "KPI Anno 3",
		liquidity: "Liquidità",
		currentRatio: "Rapporto di liquidità corrente (current ratio)",
		quickRatio: "Rapporto rapido di liquidità (quick ratio/acid test)",
		comparison: "Confronto",
		solvency: "Solvibilità",
		solvencyRatio: "Rapporto di solvibilità",
		profitability: "Redditività",
		netProfitMargin: "Margine (utile) netto",
		commercialProfitability: "Redditività commerciale",
		repaymentCapacityRatio: "Rapporto di restituzione",
		supplyChainRatios: "Rapporto di fornitura",
		workingCapital: "Capitale operativo",
		totalScore: "Somma Totale",
	},
	RegulatoryRisk: {
		relevance: "PERTINENZA",
		yes: "Sì",
		no:	"No",
		relevanceVar1:	"Incentivi",
		relevanceVar2:	"- Gli incentivi statali sono inclusi nel modello di reddito?",
		relevanceVar3:	"- Qual è la natura dell'incentivo: sovvenzione anticipata, incentivo fiscale per numero di anni, tariffe di incentivo, certificati bianchi? ",
		relevanceVar4:	"- Qual è la quota di reddito derivante dagli incentivi come % delle entrate totali? ",
		relevanceVar5:	"Conformità ",
		relevanceVar6:	"- Il progetto è disciplinato da qualsivoglia normativa rilevante in materia di conformità ambientale e / o di salute e sicurezza?",
		riskPresent:	"Rischio presente?",
		responsibility:	"RESPONSABILITA",
		responsibilityVar1:	"- Chi è il beneficiario dell'incentivo?",
		responsibilityVar2:	"- L'incentivo può essere riassegnato?",
		responsibilityVar3:	"- Esistono delle restrizioni applicabili alla riassegnazione dell'incentivo?",
		responsibilityVar4:	"- Nel caso di divisione dell'incentivo, fornire la % delle quote di tutte le parti coinvolte.",
		responsibilityVar5:	"- Descrivere come il cliente e/o l'appaltatore ottemperano alle normative rilevanti in materia di conformità ambientale e/o salute e sicurezza",
		riskBearer:	"Portatore del rischio",
		documentation:	"DOCUMENTAZIONE",
		documentationVar1:	"Elencare tutta la documentazione disponibile per supportare i dati di cui sopra:",
		documentationVar2:	"Normativa rilevante disciplinante gli incentivi e la loro potenziale riassegnazione",
		documentationVar3:	"Dati storici sul volume degli incentivi concessi e tipologie di progetti che hanno beneficiato degli incentivi (fonte ufficiale))",
		documentationVar4:	"Normativa rilevante in materia di conformità ambientale e / o di salute e sicurezza",
	},
	MarketRisk: {
		relevanceVar1:	"- In quale paese/paesi operano l'appaltatore e il cliente?",
		relevanceVar2:	"- Quali sono l'attuale rating di mercato e le rispettive prospettive? (richiesta di login)",
		relevanceVar3:	"- Indicare la data di riferimento del rating",
		currentRating:	"Rating attuale",
		outlook:	"Prospettiva ",
		contractor:	"Appaltatore",
		client:	"Cliente",
		date:	"Data",

	},
	EnergyPriceRisk: {
		relevanceVar1:	"Il modello finanziario del progetto tiene conto dello sviluppo del prezzo dell'energia?",
		relevanceVar2:	"- Se Sì, fornire le seguenti informazioni:",
		relevanceVar3:	"- Come si prevede che si evolverà il prezzo dell'energia nel corso del periodo di investimento?",
		relevanceVar4:	"- Negli ultimi 5 anni, quale è stata la fluttuazione annuale del prezzo dell'energia rilevante in %?",
		relevanceVar5:	"- Negli ultimi 5 anni, quale è stata la fluttuazione annuale del tasso di inflazione nel mercato di riferimento in %?",
		year:	"anno",
		energyPriceMovement:	"Fluttuazioni del prezzo dell'energia",
		inflationRate:	"Tasso d'inflazione",
		responsibilityVar1:	"- Chi supporta il rischio di deviazione dalla curva dei prezzi dell'energia ipotizzata (deficit, entrate in eccesso?)",
		mitigation: "MITIGAZIONE",
		mitigationVar1:	"- Il fornitore di servizi / contraente ha coperto il rischio del prezzo dell'energia (ad es. acquistando / vendendo energy futures)?",
		mitigationVar2:	"- Per quanto tempo e per quale % l'energia mensile utilizzata è coperta da questa copertura?",
		fullyMitigated:	"Completamente mitigato",
		documentationVar1:	"Fornire informazioni su fluttuazioni storiche del prezzo dell'energia da fonti ufficiali (Eurostat)",
		documentationVar2:	"Fornire informazioni sui tassi di inflazione storici da fonti ufficiali (BCE)",
		documentationVar3:	"Fornire informazioni di supporto su un potenziale strumento di copertura del prezzo dell'energia",

	},
	CurrencyRisk: {
		relevanceVar1:	"Esiste un'unica valuta applicabile a questo progetto?",
		relevanceVar2:	"Se sì, indica la valuta:",
		relevanceVar3:	"Se No, rispondere alle seguenti domande:",
		relevanceVar4:	"- In quale valuta verranno generati i flussi di cassa del progetto?",
		relevanceVar5:	"- Quale valuta verrà utilizzata per acquistare l'attrezzatura?",
		relevanceVar6:	"- È prevista una valuta diversa per far fronte a possibili eventuali spese operative durante il progetto? Se sì, indicare la valuta:",
		mitigationVar1:	"- Il fornitore di servizi/appaltatore ha coperto il rischio di cambio (ad es. acquistando/vendendo futures su valuta)?",
		mitigationVar2:	"- Per quanto tempo e per quale % dei ricavi e/o delle spese operative sono coperti da questa copertura?",
		documentationVar1:	"Fornire informazioni di supporto su un potenziale strumento di copertura del rischio di valuta",

	},
	TechnicalRisk: {
		relevanceVar1:	"- Totale del valore delle attrezzature installate (termine monetario):",
		relevanceVar2:	"- Vita utile delle attrezzature installate (anni):",
		relevanceVar3:	"- Termine del contratto con l'implementatore/cliente: [anni]",
		relevanceVar4:	"- Vita utile > Periodo del contratto?",
		relevanceVar5:	"- Ammortamento delle attrezzature installate:",
		over: "in ",
		years: "[anni].",
		straightLine:	"lineare",
		accelerated:	"accelerato",
		relevanceVar6:	"- Dovrebbero essere utilizzate le migliori attrezzature disponibili – fornire informazioni:",
		relevanceVar7:	"WSe le attrezzature sono definite nel regolamento di Ecodesign",
		relevanceVar8:	"Un elenco di riferimento di min. 3 progetti realizzati in cui sono state utilizzate le attrezzature",
		responsibilityVar1:	"Secondo il Contratto Standardizzato di LAUNCH, articolo 2.11, il fornitore di servizi/contraente è obbligato a sottoscrivere e mantenere le apposite assicurazioni, eliminando essenzialmente i rischi tecnici (vedi sotto)",
		mitigationVar1:	"Devono essere sottoscritte e mantenute le seguenti assicurazioni:",
		mitigationVar2:	"- garanzie su tutte le attrezzature installate per una durata pari o superiore al periodo del contratto di servizio e del contratto di investimento",
		mitigationVar3:	"- assicurazione dell'attrezzatura per un valore non inferiore al totale valore di sostituzione contro tutti i normali rischi di perdita, danno o distruzione a causa di incendio, furto o incidente",
		mitigationVar4:	"- assicurazione di tali importi così come un fornitore di servizi / contraente assicurerebbe l’attrezzatura secondo i canoni della normale prudenza e perizia, o dell'importo che il fornitore di servizi/contraente può ragionevolmente richiedere di volta in volta per coprire eventuali rischi di responsabilità civile o di terzi di qualsivoglia natura e comunque connessi all’apparecchiatura",
		mitigationVar5:	"- assicurazione contro ulteriori rischi relativi all'apparecchiatura così come richiesto dalle leggi vigenti, oltreché qualsiasi altra assicurazione che il fornitore di servizi / contraente può ritenere di volta in volta ragionevolmente necessaria",
		mitigationVar6:	"- Il fornitore di servizi/appaltatore sarà responsabile del pagamento di eventuali franchigie dovute su eventuali reclami ai sensi di tali polizze assicurative",
		documentationVar1:	"Scheda tecnica dell'attrezzatura installata, indicante la vita utile",
		documentationVar2:	" Garanzie sull'attrezzatura installata",
		documentationVar3:	"Assicurazione dell'attrezzatura (smarrimento/danneggiamento/furto): includere polizza",
		documentationVar4:	"Responsabilità civile dell'attrezzatura installata: includere la polizza",
		documentationVar5:	"Qualsiasi altra assicurazione prevista dalla legge: includere la polizza",
		documentationVar6:	"Elenco dei progetti implementati in cui è stata utilizzata l'attrezzatura (nel caso in cui non sia definito nel regolamento di Ecodesign)	6)    Referenzliste der durchgeführten Projekte, in denen das Gerät verwendet wurde (falls nicht in der Ökodesign-Verordnung definiert)",

	},
	PerformanceRisk: {
		relevanceVar1:	"Fattori rilevanti inclusi in questo tipo di rischio:",
		relevanceVar2:	"1. Audit energetico difettoso",
		relevanceVar3:	"2. Progettazione scadente o difettosa",
		relevanceVar4:	"3. Errori nell'attuazione delle misure di EE",
		relevanceVar5:	"4. Malfunzionamento dell'apparecchiatura",
		relevanceVar6:	"5. Errori nell'applicazione delle misure",
		relevanceVar7:	"1. Effettuato/gestito dal fornitore di servizi/contraente",
		relevanceVar8:	"2. Effettuato/gestito dal sottoscrittore/cliente",
		relevanceVar9:	"6. Fluttuazione dei modelli di utilizzo, compreso il cambiamento del comportamento dell'utente",
		relevanceVar10:	"Il rischio di prezzo dell'energia viene trattato separatamente nel presente Protocollo di valutazione del rischio",
		responsibilityVar1: "Secondo il Contratto di servizi Standardizzato di LAUNCH, il fornitore di servizi/appaltatore garantisce il raggiungimento di livelli di servizio specifici per tutta la durata del contratto, subordinatamente al soddisfacimento di tutti gli obblighi da parte dell'implementatore/cliente. Pertanto, tutta performance delle misure di EE basate sui fattori 1-4, e anche del punto 5a) sopra, che sia considerata insufficiente, verrà coperta dal fornitore di servizi/appaltatore. Questo sistema di risparmio garantito (al contrario del sistema di risparmio condiviso) comporta una maggiore possibilità per il fornitore di servizi/appaltatore di ottenere i risparmi previsti.",
		responsibilityVar2:	"Il fornitore di servizi/appaltatore è inoltre tenuto a fornire un piano dettagliato di misurazione e verifica (M&V), contenente:",
		responsibilityVar3:	"- Chiara specifica degli standard utilizzati per M&V (IPMVP, ISO 50015)",
		responsibilityVar4:	"- Programma delle attività di M&V",
		responsibilityVar5:	"- Programma degli algoritmi utilizzati nei calcoli",
		responsibilityVar6:	"- Elenco di eventuali stakeholder responsabili che fanno parte dei protocolli M&V",
		responsibilityVar7:	"Inoltre, la presentazione del progetto deve contenere un'analisi di sensitività che modella i livelli di prestazione proposti con +/- 10% e +/- 20%, indicando inoltre i successivi impatti sui flussi di cassa del progetto.",
		mitigationVar1:	"- Il fornitore di servizi/l'appaltatore ha acquistato un'assicurazione sulla performance?",
		mitigationVar2:	"- In caso affermativo, quale % di prestazione (con riferimento al livello garantito di servizio) è assicurata?",
		documentationVar1:	"Piano dettagliato di M&V",
		documentationVar2:	"Scenari peggiori, reali e migliori dei livelli di performance",
		documentationVar3:	"Se disponibile, documentazione dell'assicurazione della performance",

	},
	OMRisk: {
		relevanceVar1:	"Poiché il fornitore di servizi/appaltatore (secondo il Contratto Standardizzato di LAUNCH) è il proprietario legale e finanziario dell'attrezzatura, sarà incentivato a garantire operazioni accurate e la manutenzione tempestiva dell'attrezzatura per tutto il periodo di servizio.",
		relevanceVar2:	"Tuttavia, il fornitore di servizi/appaltatore dovrà fornire un programma di O&M dettagliato e divulgare tutta la documentazione O&M pertinente da progetti precedentemente intrapresi e/o in corso di natura simile a sostegno di tale",
		responsibilityVar1:	"Secondo il Contratto di servizi Standardizzato di LAUNCH, il fornitore di servizi/appaltatore sarà responsabile di intraprendere le misure O&M necessarie durante tutto il periodo di servizio. Le seguenti informazioni devono essere fornite nel programma di O&M:",
		responsibilityVar2:	"- Chi è il fornitore O&M?",
		responsibilityVar3:	"- Qualità del credito del fornitore O&M (nel caso in cui differisca dal fornitore di servizi/appaltatore)",
		responsibilityVar4:	"- Performance storica O&M del fornitore di O&M",
		responsibilityVar5:	"- Sistemi di monitoraggio e comunicazione utilizzati per il rilevamento delle interruzioni e le prestazioni del sistema",
		responsibilityVar6:	"- Protocollo di risposta documentato e concordato (come da Allegato del Contratto Standardizzato di LAUNCH)",
		responsibilityVar7:	"- Presenza e natura di eventuali accordi O&M di riserva",
		responsibilityVar8:	"- Il Contratto Standardizzato di LAUNCH include inoltre una clausola step-in al fine di consentire la sostituzione del fornitore di servizi/appaltatore in alcuni casi definiti",
		mitigationVar1:	"Nota: le clausole contrattuali che garantiscano i livelli di servizio di O&M post-cartolarizzazione dovrebbero essere incluse nell'accordo di trasferimento ",
		documentationVar1:	"Programma dettagliato O&M",
		documentationVar2:	"Credenziali complete ed esperienza precedente del fornitore O&M assegnato",
		documentationVar3:	"Accordi O&M di riserva (se presenti)",

	},
	InterfaceRisk: {
		relevanceVar1:	"Il Contratto Standardizzato di LAUNCH delinea ruoli e responsabilità del fornitore di servizi/appaltatore e dell'implementatore/cliente, al fine di limitare al minimo le interferenze e garantire il corretto funzionamento dell'apparecchiatura.",
		responsibilityVar1:	"In base al Contratto di servizi Standardizzato di LAUNCH, l'utente/cliente è obbligato (tra le altre cose) a:",
		responsibilityVar2:	"- Mantenere l'apparecchiatura installata e l'ambiente in cui si trova puliti e sicuri",
		responsibilityVar3:	"- Non apportare modifiche all'attrezzatura",
		responsibilityVar4:	"- Tenere l'apparecchiatura nella posizione di installazione",
		responsibilityVar5:	"- Consentire l'accesso per l'ispezione e la manutenzione",
		mitigationVar1:	"Nota: il Contratto di servizi Standardizzato di LAUNCH include una clausola sui costi di rimozione in caso di violazione o risoluzione unilaterale preventiva del contratto",

	},
	PipelineRisk: {
		relevanceVar1:	"Dimensioni della pipeline di progetti con le stesse specifiche da parte del fornitore di servizi/appaltatore, in particolare:",
		relevanceVar2:	"- Tecnologia simile",
		relevanceVar3:	"- Stessa tipologia di cliente (settore, scadenza)",
		relevanceVar4:	"- Stesso contratto",
		relevanceVar5:	"- Stessi termini del progetto",
		relevanceVar6:	"Contratto stipulato con l'implementatore/cliente – pronto per l'installazione",
		relevanceVar7:	"Con accordi da procedere (da chiudere in meno di 6 mesi)",
		relevanceVar8:	"Con accordi da procedere (da chiudere in meno di 12 mesi)",
		relevanceVar9:	"Seleziona le valute rilevanti:",
		documentationVar1:	" Documento dettagliato della pipeline di progetti, elencante tutte le opportunità rilevanti in base alle 3 fasi sopra descritte",

	},
	PrePaymentRisk: {
		relevanceVar1:	"Il Contratto Standardizzato di LAUNCH delinea all'articolo 13 una serie di motivi per i quali il Contratto può essere rescisso anticipatamente.",
		relevanceVar2:	"In caso di interruzione anticipata del servizio, devono essere valutate le seguenti condizioni:",
		relevanceVar3:	"- Considerazione tecnica: l'attrezzatura può essere tecnicamente rimossa",
		relevanceVar4:	"- Considerazione economica: l'attrezzatura può essere venduta a un prezzo ragionevole (sia in un nuovo luogo o da un nuovo utente nello stesso luogo)",
		relevanceVar5:	"- Considerazione legale: la proprietà dell'attrezzatura può essere trasferita",
		responsibilityVar1:	"Nota: in definitiva, il rischio di pagamento anticipato sarà da regolare attraverso il contratto di trasferimento tra il fornitore di servizi/appaltatore e la Società Veicolo.",
		mitigationVar1:	"Nota: una disposizione sul 'costo di rimozione' nel Contratto Standardizzato di LAUNCH mitiga questo tipo di rischio specifico in caso di risoluzione unilaterale preventiva senza giusta causa o in caso di violazione del contratto",

	},
	OccupacyRisk: {
		relevanceVar1:	"- Come vengono adattati i valori di base del consumo energetico in caso di cambiamento dei livelli di occupazione?",
		relevanceVar2:	"- È stato pattuito un livello di pagamento minimo e, nel caso in cui non lo sia: quale % di calo del livello di occupazione è (ancora) finanziariamente sostenibile?",
		relevanceVar3:	"- Esiste una clausola contrattuale in caso di smantellamento dell'impianto dove sono state installate le misure?",
		responsibilityVar1:	"Il fornitore di servizi/appaltatore è responsabile per gli adeguamenti regolari alle linee di base del consumo energetico. L'utente/cliente è responsabile della comunicazione tempestiva e trasparente circa qualsiasi cambiamento del livello di occupazione",
		responsibilityVar2:	"Nota: in quali casi il fornitore di servizi/appaltatore non è più obbligato a fornire servizi?",
		mitigationVar1:	"Nota: un compenso minimo, in % in base al valore della sottoscrizione, viene ora specificato nel Contratto Standardizzato di LAUNCH in modo da mitigare questo tipo specifico di rischio ",

	},
	ManagementRisk: {
		relevanceVar1:	"Al fine di garantire una solida governance degli investimenti e delle apparecchiature installate, sono necessari i seguenti dati 'Know-Your-Customer' (KYC):",
		relevanceVar2:	"- Tipo di entità",
		relevanceVar3:	"- Settore di attività, incluso codice di settore ",
		relevanceVar4:	"- Numero di dipendenti ",
		relevanceVar5:	" Assetto proprietario",
		relevanceVar6: " Background e track record dei dirigenti con responsabilità strategiche",
		responsibilityVar1:	"Il fornitore di servizi/appaltatore è obbligato a raccogliere le informazioni KYC dall'utente/cliente e a inviarle insieme agli stessi dati sul fornitore di servizi/società appaltatrice",
		documentationVar1:	"Documento di iscrizione alla Camera di Commercio",
		documentationVar2:	"Documentazione sulla struttura azionaria",
		documentationVar3:	"CV dei dirigenti con responsabilità strategiche",

	},
	ChangeInControlRisk: {
		relevanceVar1:	"L'utente/cliente è il proprietario legale dell'edificio in cui verrà installata l'apparecchiatura?",
		relevanceVar2:	"Nel caso in cui l'edificio sia in affitto, fornire informazioni sul contratto di locazione e sulla sua durata.",
		responsibilityVar1:	"Il Contratto Standardizzato di LAUNCH includerà una clausola che obbliga a:",
		responsibilityVar2:	"- Il nuovo utente/cliente a subentrare nel contratto di servizi esistente con gli stessi termini o",
		responsibilityVar3:	"- l'esistente/vecchio cliente a pagare il costo di rimozione alla nuova controparte legalmente assegnata",
		new:	"Nuovo",
		old:	"Vecchio",
		mitigationVar1:	"Secondo il Contratto Standardizzato LAUNCH, questo tipo di rischio è totalmente mitigato",
		documentationVar1:	"Informazioni sull'edificio affittato e termini del contratto di affitto",

	},
	ConstructionRisk: {
		relevanceVar1:	"- Sono stati ottenuti tutti i permessi di costruzione necessari per consentirne una tempestiva esecuzione?",
		relevanceVar2:	"- Quali sono i tempi di consegna delle apparecchiature? Tutte le apparecchiature saranno disponibili a tempo debito?",
		relevanceVar3:	"- Sono presenti interferenze significative con gli attuali occupanti o con operazioni da svolgersi nell'edificio tali da richiedere che i lavori di costruzione vengano limitati nel tempo o limitati in una finestra temporale specifica?",
		relevanceVar4:	"- Se sì, quali sono le conseguenze del mancato rispetto di queste limitazioni o restrizioni?",
		relevanceVar5:	"- Gli ordini di modifica sono consentiti e, se sì, in che misura e fino a quale fase di esecuzione?",
		responsibilityVar1:	"- In qualità di parte responsabile, il fornitore di servizi/appaltatore deve fornire due importanti serie di documenti:",
		responsibilityVar2:	"- Una procedura dettagliata di trasferimento che elenca tutte le fasi, le interferenze e le operazioni di trasferimento tra le parti (sub)appaltate coinvolte nello svolgimento del lavoro.",
		responsibilityVar3:	"- Un modello dettagliato del rapporto di messa in servizio, indicante tutti i controlli necessari per il completamento, il controllo di qualità, la conformità e le ispezioni che dovevano essere rispettati nella fase di costruzione",
		mitigationVar1:	"Potrebbero essere necessarie misure più complesse o più invasive, soprattutto negli ambienti di edifici  adibiti alla produzione, ai servizi essenziali, ecc., ulteriori misure di mitigazione contro ritardi, come una clausola sulla liquidazione dei danni (es. per non aver potuto utilizzare un edificio o una parte di esso per un periodo più lungo) o una polizza assicurativa contro i rischi di costruzione.",
		documentationVar1:	"Procedura di trasferimento",
		documentationVar2:	"Modello di rapporto di messa in servizio",

	},
	SupplyChainRisk: {
		relevanceVar1: "Almeno una delle parti contraenti (fornitore di servizi/appaltatore, O&M e/o fornitore di tecnologia) ha una catena di approvvigionamento che si trova in una o più sedi diverse dalla propria e che dipende da almeno un'altra terza parte?",
		relevanceVar2: "Se Sì, seleziona queste posizioni:",
		responsibilityVar1:	"Il Fornitore di servizi/appaltatore è responsabile della gestione e della tracciabilità della propria catena di approvvigionamento, compresa quella di ciascuno dei suoi fornitori e (se pertinente) dei rispettivi fornitori.",
		responsibilityVar2:	"- L'azienda riesce a dichiarare di rifornirsi di una % o un livello minimo di forniture tramite reshoring o, ad esempio, attraverso le cosiddette catene di approvvigionamento 'ibride*'?",
		responsibilityVar3: "In caso affermativo, fornire un rapporto:",
		responsibilityVar4:	"- L'azienda tiene traccia del rischio della catena di approvvigionamento nelle sue operazioni (ad esempio, attraverso indicatori di resilienza o altri strumenti)? L'azienda effettua/possiede una mappatura o ha una visibilità completa della propria catena di fornitura?",
		responsibilityVar5: "In caso affermativo, fornire un rapporto:",
		responsibilityVar6:	"*re-shoring - trasferire la produzione o la fabbricazione di beni nel paese di origine dell'azienda, con filiera basata principalmente all'interno del paese di fornitura (es. ski Rossignol), alias: 'inshoring', 'nearshoring', 'backshoring'",
		responsibilityVar7:	"catene di approvvigionamento 'ibride': catene di approvvigionamento che combinano offshoring e reshoring (ad es. elettronica cinese prodotta in parte nel sud-est asiatico)",
		mitigationVar1:	"Nota: le condizioni contrattuali con il Fornitore del servizio/Appaltatore potrebbero assicurare tutte le parti in caso di ritardi/mancanza di attrezzature/richiami, ad esempio, tramite l'inclusione di livelli di pagamento minimi e massimi in tali casi e/o assicurazioni, assicurazioni di responsabilità civile, condizioni e richieste di risarcimento in caso di smarrimento o danneggiamento",
		mitigationVar2:	"Le potenziali misure di mitigazione potrebbero essere verificate sotto forma di domande alle parti responsabili, come ad esempio:",
		mitigationVar3:	"- Quali garanzie/assicurazioni ha l'azienda contro i colli di bottiglia della fornitura? Es.: l'azienda ha una polizza assicurativa che copra il rischio della filiera, ad esempio, in caso di colli di bottiglia, attraverso la copertura dei costi associati?",
		mitigationVar4: "Se sì, fornire polizza assicurativa:",
		mitigationVar5:	"- Quale % della catena di approvvigionamento dell'azienda si trova 'offshore' rispetto a 'inshore' / 're - shore' / 'backshore' / 'nearshore'?",
		mitigationVar6:	"- L'azienda copre il rischio della catena di approvvigionamento tramite qualche meccanismo o strumento di finanziamento (ad es. polizza assicurativa o altro)?",
		mitigationVar7: "Se sì, descrivi di seguito:",
		mitigationVar8:	"- L'azienda può riferire sulle misure per mitigare il rischio di costi aggiuntivi dovuti a arresti / ritardi / ridondanze imprevisti della catena di approvvigionamento?",
		mitigationVar9: "In caso affermativo, fornire un rapporto:",
		mitigationVar10:	"- L'azienda può riferire su un numero / % / livello massimo di prodotti o attività non conformi alla tassonomia dell'UE nella sua catena di approvvigionamento e/o misure per ridurlo?",
		mitigationVar11: "In caso affermativo, fornire un rapporto:",
		documentationVar1:	" Polizza assicurativa",
		documentationVar2:	" Relazione annuale, incl. relazione di revisione",

	},
	Contract: {
		pageTitle: "Contratto",
		status: "Stato attuale",
		created: "Creato",
		customContract: "Contratto su Misura",
		uploadContract: "Caricare Contratto su Misura",
		serviceProviderName: "Fornitore di Servizio Nome",
		serviceProviderNameText: "Il nome del fornitore di servizi",
		directorName: "Nome del Direttore",
		directorNameText: "Il nome del Direttore",
		countryOfRegistration: "Paese dell'Iscrizione",
		countryOfRegistrationText: "Il paese di registrazione",
		companyNumber: "Partita IVA & Codice fiscale",
		companyNumberText: "Il numero dell'azienda",
		registeredOfficeAddress: "Sede Legale",
		registeredOfficeAddressText: "L'indirizzo della sede legale",
		registeredOfficePostcode: "Codice Postale della Sede Legale",
		registeredOfficePostcodeText: "Il codice postale della sede legale",
		registeredOfficeCity: "Città della Sede Legale",
		registeredOfficeCityText: "La città della sede legale",
		registeredOutputType: "Il tipo di uscita",
		currency: "Valuta",
		outputType: "Tipo di Prodotto",
		schedule1: "Allegato 1",
		schedule2: "Allegato 2",
		schedule3: "Allegato 3",
		schedule4: "Allegato 4",
		schedule5: "Allegato 5",
		schedule6: "Allegato 6",
		schedule7: "Allegato 7",
		sign: "Firma",
		save: "Salva",
		clear: "Elimina",
		signedDocuments: "Documenti Firmati",
		contractSigned: "Contratto Firmato",
		schedule1Signed: "Allegato 1 Firmato",
		schedule2Signed: "Allegato 2 Firmato",
		schedule3Signed: "Allegato 3 Firmato",
		schedule4Signed: "Allegato 4 Firmato",
		schedule5Signed: "Allegato 5 Firmato",
		schedule6Signed: "Allegato 6 Firmato",
		schedule7Signed: "Allegato 7 Firmato",
		blankField: "Questo campo non deve essere lasciato vuoto",
		blankFields: "Questi campi non devono essere lasciati vuoti",
		downloadTemplatePDF: "(Scarica modello in PDF)",
		downloadTemplateDOCX: "(Scarica modello in DOCX)",
		noUploadedFile: "Nessun file caricato",
		needFile: "Devi caricare un file",
		areYouSure: "Sei sicuro?",
		areYouSureDelete: "Sei sicuro di voler eliminare il file?",
		delete: "Elimina",
		cancel: "Annulla",
		downloadFile: "Download file",
		signerEmailError: "Devi fornire un indirizzo email valido",
		serviceSubscriberName: "Nome dell'abbonato al servizio",
		serviceSubscriberEmail: "Email abbonato al servizio",
		serviceSubscriberDirectorName: "Nome del direttore dell'abbonato al servizio",
		serviceSubscriberCountryOfRegistration: "Abbonato al servizio Paese di registrazione",
		serviceSubscriberCompanyNumber: "Numero società abbonato al servizio",
		serviceSubscriberRegisteredOfficeAddress: "Indirizzo della sede legale dell'abbonato al servizio",
		serviceSubscriberRegisteredOfficePostcode: "Codice postale della sede legale dell'abbonato al servizio",
		serviceSubscriberRegisteredOfficeCity: "Abbonato al servizio Sede Legale Città",
		serviceSubscriberNameText: "Il nome dell'abbonato al servizio",
		serviceSubscriberEmailText: "L'e-mail del direttore dell'abbonato al servizio",
		serviceSubscriberDirectorNameText: "Il nome del direttore dell'abbonato al servizio",
		serviceSubscriberCountryOfRegistrationText: "Il paese di registrazione dell'abbonato al servizio",
		serviceSubscriberCompanyNumberText: "Il numero dell'azienda dell'abbonato al servizio",
		serviceSubscriberRegisteredOfficeAddressText: "L'indirizzo della sede legale dell'abbonato al servizio",
		serviceSubscriberRegisteredOfficePostcodeText: "Il codice postale della sede legale dell'abbonato al servizio",
		serviceSubscriberRegisteredOfficeCityText: "La città con sede legale dell'abbonato al servizio",
		savedSuccessfully: "Informazioni salvate correttamente",
		professionalIndemnityInsuranceAmount: "Importo dell'assicurazione di indennità professionale",
		professionalIndemnityInsuranceAmountText: "L'importo dell'assicurazione di responsabilità civile professionale",
		daysToPaySecurityDeposit: "# Giorni per l'Abbonato per pagare il deposito cauzionale",
		daysToPaySecurityDepositText: "Il numero di giorni per il pagamento del deposito cauzionale",
		businessHours: "Ore di lavoro",
		businessHoursTo: "a",
		interest: "Interesse",
		interestOver: "oltre",
	},
};
