import ky from "ky";
import queryString from "query-string";

import { jwt } from "../utils";

const { REACT_APP_MAIN_SERVER_URL } = process.env;

const rootApi = ky.extend({
	timeout: false,
	prefixUrl: `${REACT_APP_MAIN_SERVER_URL}/api`,
	retry: {
		statusCodes: [401, 408, 413, 429, 502, 503, 504],
		limit: 2,
		methods: ["get", "post", "put", "head", "delete", "options", "trace"],
	},
	hooks: {
		beforeRequest: [({ headers }) => {
			headers.set("x-access-token", jwt.getToken());
		}],
		beforeRetry: [
			async ({ request: { method }, error }) => {
				if (error?.response?.status === 401) {
					const res = await rootApi.extend({ throwHttpErrors: false, retry: 0 }).get("api/refresh");
					if (res.status === 401) {
						jwt.destroyToken();
						window.location.href = "/";
					} else {
						const { token } = await res.json();
						jwt.setToken(token);
					}
				} else if (method === "POST") {
					throw error;
				}
			},
		],
		afterResponse: [
			(_req, _opts, res) => {
				const { status } = res;
				if (status === 500) {
					return new Response(JSON.stringify({ success: false }), { status: 200 });
				}

				if (status === 404) {
					window.location.href = "/";
				}

				return res;
			},
		],
	},
});

const api = {
	get: (path, searchParams) => rootApi.get(path, { searchParams: queryString.stringify(searchParams) }).json(),
	post: (path, json) => rootApi.post(path, { json }).json(),
	put: (path, json) => rootApi.put(path, { json }).json(),
	patch: (path, json) => rootApi.patch(path, { json }).json(),
	delete: (path, json) => rootApi.delete(path, { json }).json(),
};

export default api;

// Sign In/Up
export const authGoogle = (creds) => api.post("authGoogle", creds);
export const signInEmail = (creds) => api.post("signInEmail", creds);
export const signUpUser = (creds) => api.post("signUpUser", creds);
export const forgotPassword = (creds) => api.post("forgotPassword", creds);
export const changePassword = (creds) => api.put("resetPassword", creds);

// User Profile
export const getUserProfile = () => api.get("user");
export const updateUserProfile = (info) => api.put("user", { info });
export const verifyOldPassword = (pass) => api.post("user/verifyPassword", { pass });

// Company routes
export const getCompany = () => api.get("company");
export const setCompany = (info) => api.post("company", { info });
export const deleteCompanyUser = (email) => api.put("company/deleteUser", { email });
export const updateCompanyInformation = (info) => api.put("company/information", { info });
export const updateCompanyCertificates = (info) => api.put("company/certificates", { info });
export const deleteCompanyCertificate = (info) => api.delete("company/certificates", { info });
export const getCompanyUsers = () => api.get("company/users");
export const getCompanyInvitedUsers = () => api.get("company/invitation/users");
export const sendUserInviation = (info) => api.post("company/invitation", { info });
export const updateCompanyUserRole = (info) => api.put("company/user/role", { info });
export const leaveCompany = () => api.put("company/leave");
export const getCompanySpreasheet = () => api.get("company/spreadsheet");
export const updateCompanySpreadsheet = (info) => api.put("company/spreadsheet", { info });
export const getCompanyKyc = () => api.get("company/kyc");
export const updateCompanyKyc = (info) => api.put("company/kyc", { info });
export const getCompanyDocs = () => api.get("company/documents");

// Invitation routes
export const getInvitations = () => api.get("invitations");
export const acceptInvitation = (invitation) => api.post("invitations/accept", { invitation });
export const declineInvitation = (invitation) => api.post("invitations/decline", { invitation });

export const getProjects = () => api.get("projects");
export const getAllProjects = (view) => api.post("projects/all", { view });
export const getAllApprovedProjects = (view) => api.post("projects/all/approved", { view });
export const getAllProjectPipelines = () => api.get("projects/all/pipelines");
export const setProjectOverview = (info) => api.post("projects", { info });

// Admin routes
export const getAllUsers = () => api.get("admin/users");
export const acceptUser = (info) => api.put("admin/user/accept", { info });
export const declineUser = (info) => api.delete("admin/user/decline", { info });
export const deleteUser = (info) => api.delete("admin/user", { info });

export const getAllInvitations = () => api.get("admin/invitation");
export const sendAdminUserInvitation = (info) => api.post("admin/invitation", { info });
export const cancelAdminUserInvitation = (info) => api.post("admin/invitation/cancel", { info });
export const getAdminProjectUsers = (pid) => api.get(`admin/project/${pid}/users`);
export const updateAdminProjectStatus = (pid, info) => api.put(`admin/project/${pid}/status`, { info });
export const getInvestorRequests = () => api.get("admin/investor/requests");
export const introduceProject = (request) => api.put("admin/investor/introduce/project", { request });
export const introducePipeline = (request) => api.put("admin/investor/introduce/pipeline", { request });

export const getAdminViews = () => api.get("admin/view/all");
export const createAdminView = (view) => api.post("admin/view", { view });
export const updateAdminView = (view) => api.put("admin/view", { view });
export const deleteAdminView = (view) => api.delete("admin/view", { view });

// Investor routes
export const getInvestorApprovedProjects = (view) => api.post("investor/projects", { view });
export const getInvestorPoolProjects = (view) => api.post("investor/projects/pool", { view });
export const investorRequestProject = (request) => api.post("investor/request", { request });
export const investorRemovePoolProject = (request) => api.delete("investor/request", { request });
export const getInvestorProjectUsers = (pid) => api.get(`investor/project/${pid}/users`);
export const getInvestorViews = () => api.get("investor/view/all");
export const createInvestorView = (view) => api.post("investor/view", { view });
export const updateInvestorView = (view) => api.put("investor/view", { view });
export const deleteInvestorView = (view) => api.delete("investor/view", { view });

// Project Utilities
export const getProject = (pid) => api.get(`project/${pid}/utils/functional`);
export const getProjectSize = (pid) => api.get(`project/${pid}/utils/functional/size`);
export const getProjectGeneralInfo = (pid) => api.get(`project/${pid}/utils/functional/info`);
export const getProjectUserRole = (pid) => api.get(`project/${pid}/utils/functional/user/role`);

// Critical Project Utilities
export const updateProjectOverview = (pid, info) => api.put(`project/${pid}/utils/critical`, { info });
export const archiveProject = (pid) => api.put(`project/${pid}/utils/critical/archive`);
export const deleteProject = (pid) => api.delete(`project/${pid}/utils/critical`);
export const createNewPipeline = (pid, info) => api.put(`project/${pid}/utils/critical/pipeline`, { info });
export const updateProjectStatus = (pid) => api.put(`project/${pid}/utils/critical/status`);
export const duplicateProject = (pid, info) => api.post(`project/${pid}/utils/critical/duplicate`, { info });

// Pipelines
export const getPipelines = () => api.get("pipelines");
export const getPoolPipelines = () => api.get("pipelines/pool");
export const getAvailablePipelineProjects = () => api.get("pipelines/available/projects");
export const createPipeline = (info) => api.post("pipelines", { info });
export const deletePipeline = (info) => api.delete("pipelines", { info });
export const removePipelinePool = (info) => api.delete("pipelines/remove", { info });
export const requestPipelineIntroduction = (info) => api.post("pipelines/request", { info });

// Learning Hub
export const getExpertContactForm = () => api.get("learningHub/expertContactForm");
export const postExpertContactForm = (info) => api.post("learningHub/expertContactForm", { info });

// Project Tools
export const getProjectUsers = (pid) => api.get(`project/${pid}/tools/users`);
export const updateProjectUsers = (pid, info) => api.put(`project/${pid}/tools/users`, { info });
export const addMaterialExpert = (pid, info) => api.post(`project/${pid}/tools/users/external`, { info });
export const deleteProjectUsers = (pid, info) => api.delete(`project/${pid}/tools/users`, { info });
export const getProjectContract = (pid) => api.get(`project/${pid}/tools/contract`);
export const getUserData = (pid) => api.get(`project/${pid}/tools/contract/userData`);
export const updateProjectContract = (pid, info) => api.put(`project/${pid}/tools/contract`, { info });
export const getProjectSpreadsheet = (pid) => api.get(`project/${pid}/tools/spreadsheet`);
export const getProjectSpreadsheetCompanyData = (pid) => api.get(`project/${pid}/tools/spreadsheet/company`);
export const updateProjectSpreadsheet = (pid, info) => api.put(`project/${pid}/tools/spreadsheet`, { info });
export const getProjectRiskAssessentProtocol = (pid) => api.get(`project/${pid}/tools/riskAssessmentProtocol`);
export const getProjectRiskAssessentProtocolManagementCompanyDocs = (pid) => api.get(`project/${pid}/tools/riskAssessmentProtocol/management`);
export const getProjectRiskAssessentProtocolSpreadsheetDocs = (pid) => api.get(`project/${pid}/tools/riskAssessmentProtocol/spreadsheet`);
export const getProjectRiskAssessentProtocolSpreadsheetCompanyDocs = (pid) => api.get(`project/${pid}/tools/riskAssessmentProtocol/spreadsheet/company`);
export const updateProjectRiskAssessentProtocol = (pid, info) => api.put(`project/${pid}/tools/riskAssessmentProtocol`, { info });
export const getProjectLoanApplication = (pid) => api.get(`project/${pid}/tools/loanApplication`);
export const getProjectLoanApplicationOverviewDocs = (pid) => api.get(`project/${pid}/tools/loanApplication/overview`);
export const getProjectLoanApplicationEEInfoDocs = (pid) => api.get(`project/${pid}/tools/loanApplication/detailedEeInformation`);
export const updateProjectLoanApplication = (pid, info) => api.put(`project/${pid}/tools/loanApplication`, { info });
export const getProjectKyc = (pid) => api.get(`project/${pid}/tools/kyc`);
export const getProjectEsg = (pid) => api.get(`project/${pid}/tools/esg`);

export const getProjectEsgAllAspectsCompanyDocs = (pid) => api.get(`project/${pid}/tools/esg/allAspects`);
export const getProjectEsgEnvironmentCompanyDocs = (pid) => api.get(`project/${pid}/tools/esg/environment`);
export const getProjectEsgSocialCompanyDocs = (pid) => api.get(`project/${pid}/tools/esg/social`);
export const getProjectEsgGoveranceCompanyDocs = (pid) => api.get(`project/${pid}/tools/esg/goverance`);
export const updateProjectKyc = (pid, info) => api.put(`project/${pid}/tools/kyc`, { info });
export const updateProjectEsg = (pid, info) => api.put(`project/${pid}/tools/esg`, { info });

export const getProjectEquad = (pid) => api.get(`project/${pid}/tools/equad`);
export const getAllProjectsEquad = (pid) => api.get(`project/${pid}/tools/equad/all`);
export const updateEquadProject = (pid, info) => api.put(`project/${pid}/tools/equad`, { info });
export const downloadEquadFile = (pid, colletionId, fileId) => api.get(`project/${pid}/tools/equad/file/${colletionId}/${fileId}`);
export const downloadEquadReport = (pid) => api.get(`project/${pid}/tools/equad/excel`);

// Project Loan Application getters
export const getProjectLoanApplicationOverview = (pid) => api.get(`project/${pid}/tools/loanApplication/overview`);
export const getProjectLoanApplicationCompanyOverview = (pid) => api.get(`project/${pid}/tools/loanApplication/companyOverview`);
export const getProjectLoanApplicationTechnicalInformation = (pid) => api.get(`project/${pid}/tools/loanApplication/technicalInformation`);
export const getProjectLoanApplicationFinancialInformation = (pid) => api.get(`project/${pid}/tools/loanApplication/financialInformation`);
export const getProjectLoanApplicationProjectFinance = (pid) => api.get(`project/${pid}/tools/loanApplication/projectFinance`);
export const getProjectLoanApplicationDetailedEEInformation = (pid) => api.get(`project/${pid}/tools/loanApplication/detailedEeInformation`);

// Project ESG Application setters
export const updateProjectLoanApplicationOverview = (pid, data) => api.put(`project/${pid}/tools/loanApplication/overview`, data);
export const updateProjectLoanApplicationCompanyOverview = (pid, data) => api.put(`project/${pid}/tools/loanApplication/companyOverview`, data);
export const updateProjectLoanApplicationTechnicalInformation = (pid, data) => api.put(`project/${pid}/tools/loanApplication/technicalInformation`, data);
export const updateProjectLoanApplicationFinancialInformation = (pid, data) => api.put(`project/${pid}/tools/loanApplication/financialInformation`, data);
export const updateProjectLoanApplicationProjectFinance = (pid, data) => api.put(`project/${pid}/tools/loanApplication/projectFinance`, data);
export const updateProjectLoanApplicationDetailedEEInformation = (pid, data) => api.put(`project/${pid}/tools/loanApplication/detailedEeInformation`, data);

// Project ESG getters
export const getProjectEsgAllAspects = (pid) => api.get(`project/${pid}/tools/esg/allAspects`);
export const getProjectEsgEnvironmental = (pid) => api.get(`project/${pid}/tools/esg/environment`);
export const getProjectEsgSocial = (pid) => api.get(`project/${pid}/tools/esg/social`);
export const getProjectEsgGovernance = (pid) => api.get(`project/${pid}/tools/esg/governance`);

// Project ESG setters
export const updateProjectEsgAllAspects = (pid, data) => api.put(`project/${pid}/tools/esg/allAspects`, data);
export const updateProjectEsgEnvironmental = (pid, data) => api.put(`project/${pid}/tools/esg/environment`, data);
export const updateProjectEsgSocial = (pid, data) => api.put(`project/${pid}/tools/esg/social`, data);
export const updateProjectEsgGovernance = (pid, data) => api.put(`project/${pid}/tools/esg/governance`, data);

// File Utilities
export const uploadFile = (body) => rootApi.post("file/", { body }).json();
export const reUploadFile = (body) => rootApi.put("file/", { body }).json();
export const deleteFile = (info) => api.post("file/delete/", info);

// Docusign Utility
export const signingCompleted = (projectid) => api.post("docusign/completed", { projectid });

// export const clearProjectSpreadsheet = (pid, section) => api.delete(`project/${pid}/tools/spreadsheet`, { section });
// eslint-disable-next-line max-len
// export const clearProjectRiskAssessentProtocol = (pid, section) => api.delete(`project/${pid}/tools/riskAssessmentProtocol`, { section });
// export const clearProjectLoanApplication = (pid, section) => api.delete(`project/${pid}/tools/loanApplication`, { section });
// export const clearProjectESG = (pid, section) => api.delete(`project/${pid}/tools/esg`, { section });
