import { memo, useCallback, useEffect, useState, forwardRef } from "react";
import { useParams } from "react-router-dom";
import { Button, Grid, LinearProgress, Typography, Dialog, Slide, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import { useSnackbar, httpResponseIsValid } from "../../utils";
import { deleteFile, getProjectRiskAssessentProtocol, updateProjectRiskAssessentProtocol } from "../../api";
import usePrompt from "../../utils/use-blocker";
import StatusDialog from "../../components/StatusDialog";
import {
	MainMenu,
	LabelTextField,
	LabelRadio,
	LabelRadioTextField,
	LabelFileUpload,
} from "../../components/basic/index";

const Transition = forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const ConstructionRisk = ({ disableAccess = true, isProjectDeveloper = false, onChangeProjectState }) => {
	const { projectid } = useParams();
	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(disableAccess);
	const [projectStateDialog, setProjectStateDialog] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [project, setProject] = useState({
		status: "",
		createdAt: "-",
		construction_risk_doc_1: {
			original_name: "",
			save_name: "",
		},
		construction_risk_doc_2: {
			original_name: "",
			save_name: "",
		},
		construction_risk: {
			construction_risk_relevance: "",
			construction_risk_responsibility: "",
			construction_risk_mitigation: "",
			construction_permits: "",
			lead_times: "",
			significant_interference: "",
			consequences: "",
			change_orders_radio: "",
			change_orders_txt: "",
		},
	});
	const [enableEditing, setEnableEditing] = useState(false);
	const { success, error } = useSnackbar();
	const [deleteModal, setDeleteModal] = useState({ component: null, variable: null });

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getProjectRiskAssessentProtocol(projectid);
				if (httpResponseIsValid(response)) {
					setProject(response);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured while fetching risk assessment construction risk data: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, projectid],
	);

	const handleSubmit = async (values) => {
		setIsLoading(true);

		try {
			const response = await updateProjectRiskAssessentProtocol(projectid, values);

			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			error(`Error occured while submitting risk assessment construction risk data: ${_error}`);
		}

		setIsLoading(false);
	};

	const changeProjectState = useCallback(
		async () => {
			await onChangeProjectState();
			fetchData();
		}, [fetchData, onChangeProjectState],
	);

	const onDelete = async (comp) => {
		try {
			setIsLoading(true);
			const { success: deleteSuccess } = await deleteFile({ projectid, component: comp.component, variable: comp.variable });
			console.log(deleteSuccess);
			if (deleteSuccess) {
				success("File deleted successfully");
				await fetchData(false);
				setIsLoading(false);
			} else {
				error();
				setIsLoading(false);
			}
		} catch { /* empty */ }
	};

	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: project,
		onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	});

	const { dirty } = formik;
	usePrompt("You have unsaved changes which will be lost. Are you sure you want to leave the page?", dirty);

	useEffect(() => {
		setShouldUiBeDisabled(disableAccess || !enableEditing);
	}, [disableAccess, enableEditing]);

	useEffect(() => {
		formik.resetForm({ values: project, dirty: false });
	}, [project]);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData, projectid]);

	return (
		<form onSubmit={formik.handleSubmit}>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<MainMenu
				title={t("RiskAssessmentOverview.constructionRisk")}
				dirty={dirty}
				project={project}
				isDeveloper={isProjectDeveloper}
				disabled={disableAccess}
				onFetch={fetchData}
				onEdit={setEnableEditing}
				onChange={onChangeProjectState}
			/>

			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "105px" }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("RegulatoryRisk.relevance")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} alignItems="top">
					<Grid item xs={12} md={9} sm={8} p={1}>
						<LabelRadio
							id="construction_risk.construction_permits"
							label={t("ConstructionRisk.relevanceVar1")}
							size="flat"
							typosize="h8"
							options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
						<LabelTextField
							id="construction_risk.lead_times"
							label={t("ConstructionRisk.relevanceVar2")}
							size="flat"
							typosize="h8"
							aligntitlebox="center"
							placeholder="Answer"
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
						<LabelRadioTextField
							radioId="construction_risk.significant_interference"
							fieldId="construction_risk.consequences"
							label={t("ConstructionRisk.relevanceVar3")}
							typosize="h8"
							placeholder="Answer"
							size="flat"
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
						<LabelRadioTextField
							radioId="construction_risk.change_orders_radio"
							fieldId="construction_risk.change_orders_txt"
							label={t("ConstructionRisk.relevanceVar5")}
							placeholder="Answer"
							size="flat"
							typosize="h8"
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
					</Grid>
					<Grid item md={0.2} />
					<Grid item xs={12} sm={4} md={2} direction="row" alignItems="center">
						<LabelRadio
							id="construction_risk.construction_risk_relevance"
							label={t("RegulatoryRisk.riskPresent")}
							size="large"
							options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
					</Grid>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("RegulatoryRisk.responsibility")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center" color="blue.dark">
					<Grid item xs={12} md={9} sm={8} p={1}>

						<Typography variant="h8" alignItems="center" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
							{t("ConstructionRisk.responsibilityVar1")}
							{"\n\n"}
						</Typography>
						<Typography variant="h8" alignItems="center" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
							{t("ConstructionRisk.responsibilityVar2")}
							{"\n\n"}
						</Typography>
						<Typography variant="h8" alignItems="center" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
							{t("ConstructionRisk.responsibilityVar3")}
							{"\n"}
						</Typography>
					</Grid>
					<Grid item md={0.2} />
					<Grid item xs={12} sm={4} md={2} direction="row" alignItems="center">
						<LabelRadio
							id="construction_risk.construction_risk_responsibility"
							label={t("RegulatoryRisk.riskBearer")}
							size="large"
							options={[{ value: "false", label: "Client" }, { value: "true", label: "Contractor" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
					</Grid>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("EnergyPriceRisk.mitigation")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} alignItems="center">
					<Grid item xs={12} md={9} sm={8} p={1}>
						<Typography variant="h8" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
							{t("ConstructionRisk.mitigationVar1")}
						</Typography>
					</Grid>
					<Grid item md={0.2} />
					<Grid item xs={12} sm={4} md={2} direction="row" alignItems="center">
						<LabelRadio
							id="construction_risk.construction_risk_mitigation"
							label={t("EnergyPriceRisk.fullyMitigated")}
							size="large"
							options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
					</Grid>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("RegulatoryRisk.documentation")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center">
					<LabelFileUpload
						fieldId="construction_risk_doc_1"
						projectId={projectid}
						label={t("ConstructionRisk.documentationVar1")}
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="riskAssessmentProtocol"
						onSuccess={() => fetchData()}
					/>
					<LabelFileUpload
						fieldId="construction_risk_doc_2"
						projectId={projectid}
						label={t("ConstructionRisk.documentationVar2")}
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="riskAssessmentProtocol"
						onSuccess={() => fetchData()}
					/>
				</Grid>
			</Grid>
			{deleteModal.component !== null
				&& deleteModal.variable !== null
				&& (
					<Dialog
						keepMounted
						open={deleteModal.component !== null && deleteModal.variable !== null}
						TransitionComponent={Transition}
						onClose={() => setDeleteModal({ component: null, variable: null })}
					>
						<DialogTitle>
							{"Are you sure?"}
						</DialogTitle>
						<DialogContent dividers>
							<DialogContentText>
								{"Are you sure you want to delete the file?"}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								autoFocus
								startIcon={<Delete />}
								variant="contained"
								onClick={() => {
									onDelete(deleteModal);
									setDeleteModal({ component: null, variable: null });
								}}
							>
								{"Delete"}
							</Button>
							<Button variant="outlined" onClick={() => setDeleteModal({ component: null, variable: null })}>{"Cancel"}</Button>
						</DialogActions>
					</Dialog>
				)}
			<StatusDialog
				open={projectStateDialog}
				status={project.status}
				name={project.name}
				onClose={setProjectStateDialog}
				onChange={changeProjectState}
			/>
		</form>
	);
};

export default memo(ConstructionRisk);
