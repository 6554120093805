import { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getIn } from "formik";
import {
	Grid,
	Typography,
	Radio,
	RadioGroup,
	FormControlLabel,
	Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

export const LabelRadio = ({
	id,
	label,
	bold = false,
	size: labelSize = "medium",
	options = [],
	horizontal = true,
	disabled = false,
	formik,
	infoText = "",
	typosize = "h6",
}) => {
	const [size, setSize] = useState({ label: 5, sep: 2, field: 5 });

	const {
		values,
		handleChange,
	} = formik;

	useEffect(() => {
		switch (labelSize) {
			case "small":
				setSize({ label: 4, sep: 2, field: 6 });
				break;
			case "medium":
				setSize({ label: 5, sep: 2, field: 5 });
				break;
			case "large":
				setSize({ label: 6, sep: 2, field: 4 });
				break;
			case "flat":
				setSize({ label: 5.5, sep: 2.5, field: 2.5 });
				break;
			default:
				setSize({ label: 5, sep: 2, field: 5 });
		}
	}, [labelSize]);

	return (
		<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems="center">
			<Grid item container xs={12} md={size.label}>
				<Typography variant={typosize} fontWeight={bold ? "bold" : ""} sx={{ color: "blue.dark" }}>
					{label}
					{infoText && (
						<Tooltip placement="bottom-start" title={infoText}>
							<InfoIcon fontSize="small" sx={{ padding: 0.2, color: "green.main" }} />
						</Tooltip>
					)}
				</Typography>
			</Grid>
			<Grid item md={size.sep} />
			<Grid item xs={12} md={size.field}>
				<RadioGroup
					id={id}
					name={id}
					row={horizontal}
					value={getIn(values, id)}
					onChange={handleChange}
				>
					<Grid item container justifyContent="space-between">
						{options.map((option) => (
							<FormControlLabel
								key={option.value}
								value={option.value}
								control={<Radio />}
								disabled={disabled}
								label={(
									<Typography variant={typosize}>
										{option.label}
									</Typography>
								)}
							/>
						))}
					</Grid>
				</RadioGroup>
			</Grid>
		</Grid>
	);
};

LabelRadio.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	bold: PropTypes.bool,
	size: PropTypes.string,
	options: PropTypes.array.isRequired,
	horizontal: PropTypes.bool,
	disabled: PropTypes.bool,
	formik: PropTypes.any.isRequired,
	infoText: PropTypes.string,
	typosize: PropTypes.string,
};

export default memo(LabelRadio);
