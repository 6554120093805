/* eslint-disable max-len */
/* eslint-disable no-negated-condition */
import { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, LinearProgress, Typography, Button, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

import { useSnackbar, httpResponseIsValid } from "../../utils";
import { getProjectRiskAssessentProtocolSpreadsheetCompanyDocs } from "../../api";
import StatusDialog from "../../components/StatusDialog";

const useStyles = makeStyles((theme) => ({
	boxBorder: {
		border: `2px solid ${theme.palette.primary.main}`,
		borderRadius: theme.spacing(2),
		backgroundColor: "white",
		height: "48px",
	},
	screenTitle: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		letterSpacing: theme.spacing(0.2),
	},
}));

const CreditRiskServiceProvider = ({ isProjectDeveloper = false, onChangeProjectState }) => {
	const { projectid } = useParams();
	const [initialFetching, setInitialFetching] = useState(true);
	const [projectStateDialog, setProjectStateDialog] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [projectInfo, setProjectInfo] = useState({
		currency: "",
		status: "",
		createdAt: "-",
	});

	const { error } = useSnackbar();

	const classes = useStyles();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getProjectRiskAssessentProtocolSpreadsheetCompanyDocs(projectid);
				if (httpResponseIsValid(response)) {
					setProjectInfo(response);
					setInitialFetching(false);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured while fetching risk assessment credit risk implementor data: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, projectid],
	);

	const changeProjectState = useCallback(
		async () => {
			await onChangeProjectState();
			fetchData();
		}, [fetchData, onChangeProjectState],
	);

	const { t } = useTranslation();

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData, projectid]);

	return (
		<>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<Grid container direction="column" alignItems="center" py={1} px={3} sx={{ "> .MuiGrid-item": { p: 1 }, position: "fixed", maxWidth: "1300px", width: "calc(100% - 300px)", background: "white", zIndex: 999 }}>
				<Grid container item direction="row" alignItems="center" sx={{ "> .MuiGrid-item": { p: 0 }, background: "#F4F4F4", borderRadius: "10px 10px 10px 10px" }}>
					<Grid item xs={12} md={7}>
						<Typography variant="h5" className={classes.screenTitle}>{t("RiskAssessmentOverview.creditRiskServiceProvider")}</Typography>
					</Grid>
					<Grid item container className={classes.boxBorder} sx={{ p: 1 }} xs={12} md={5}>
						<Grid item xs={12} md={6}>
							<Box p={1}>
								<Grid container direction="row" justifyContent="start" alignItems="center">
									<Grid item xs={4}>
										<Typography variant="h6">{"Status: "}</Typography>
									</Grid>
									<Grid item container xs={8} justifyContent="start">
										<Typography variant="h6" color={(projectInfo.status === "Approved") ? "green.main" : ((projectInfo.status === "Rejected") ? "error.main" : "primary.main")}>{projectInfo.status}</Typography>
									</Grid>
								</Grid>
							</Box>
						</Grid>
						<Grid item container xs={12} md={6} alignItems="center" justifyContent="end">
							<Button
								variant="contained"
								size="medium"
								sx={{ width: "170px", backgroundColor: "#5C9AE1", color: "common.white", ":hover": { backgroundColor: "primary.main" }, px: 3, marginRight: "4px", borderRadius: "10px" }}
								disabled={!isProjectDeveloper}
								onClick={() => setProjectStateDialog(true)}
							>
								{projectInfo.status === "In Progress" ? "Submit Project" : "Revert Project"}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{!initialFetching
				&& (
					<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "70px" }}>
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
							<Typography variant="h6" color="common.white" alignItems="center">
								{t("CreditRiskServiceProvider.relevance")}
							</Typography>
						</Grid>
						<Grid container direction="row" alignItems="center" justifyContent="center">
							<Grid item xs={3} align="center" sx={{ border: 1, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line", mb: 5 }}>
									<b>{t("CreditRiskServiceProvider.formula")}</b>
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									<b>{t("CreditRiskServiceProvider.year1")}</b>
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									<b>{t("CreditRiskServiceProvider.kpiYear1")}</b>
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line", mb: 5 }}>
									<b>{t("CreditRiskServiceProvider.year2")}</b>
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line", mb: 5 }}>
									<b>{t("CreditRiskServiceProvider.kpiYear2")}</b>
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line", mb: 5 }}>
									<b>{t("CreditRiskServiceProvider.year3")}</b>
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line", mb: 5 }}>
									<b>{t("CreditRiskServiceProvider.kpiYear3")}</b>
								</Typography>
							</Grid>
						</Grid>
						<Grid item container direction="row" alignItems="center" justifyContent="center" bgcolor="grey.main" sx={{ border: 1 }}>
							<Typography variant="h7" alignItems="center">
								<b>{t("CreditRiskServiceProvider.liquidity")}</b>
							</Typography>
						</Grid>
						<Grid container direction="row" alignItems="center" justifyContent="center">
							<Grid item xs={3} align="center" sx={{ border: 1, borderTop: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line", mb: 5 }}>
									<b>{t("CreditRiskServiceProvider.currentRatio")}</b>
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(projectInfo.financial_position.total_current_liabilities.year_1) > 0
										? ((projectInfo.financial_position.total_current_assets.year_1) / (projectInfo.financial_position.total_current_liabilities.year_1)).toFixed(3) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(projectInfo.financial_position.total_current_assets.year_1)
										/ (projectInfo.financial_position.total_current_liabilities.year_1) !== "-" ? ((projectInfo.financial_position.total_current_assets.year_1)
											/ (projectInfo.financial_position.total_current_liabilities.year_1) > 1 ? 1 : 4) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line", mb: 5 }}>
									{(projectInfo.financial_position.total_current_liabilities.year_2) > 0
										? ((projectInfo.financial_position.total_current_assets.year_2) / (projectInfo.financial_position.total_current_liabilities.year_2)).toFixed(3) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line", mb: 5 }}>
									{(projectInfo.financial_position.total_current_assets.year_2)
										/ (projectInfo.financial_position.total_current_liabilities.year_2) !== "-" ? ((projectInfo.financial_position.total_current_assets.year_2)
											/ (projectInfo.financial_position.total_current_liabilities.year_2) > 1 ? 1 : 4) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line", mb: 5 }}>
									{(projectInfo.financial_position.total_current_liabilities.year_3) > 0
										? ((projectInfo.financial_position.total_current_assets.year_3) / (projectInfo.financial_position.total_current_liabilities.year_3)).toFixed(3) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line", mb: 5 }}>
									{(projectInfo.financial_position.total_current_assets.year_3)
										/ (projectInfo.financial_position.total_current_liabilities.year_3) !== "-" ? ((projectInfo.financial_position.total_current_assets.year_3)
											/ (projectInfo.financial_position.total_current_liabilities.year_3) > 1 ? 1 : 4) : "-"}
								</Typography>
							</Grid>
						</Grid>
						<Grid container direction="row" alignItems="center" justifyContent="center">
							<Grid item xs={3} align="center" sx={{ border: 1, borderTop: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line", mb: 5 }}>
									<b>{t("CreditRiskServiceProvider.quickRatio")}</b>
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(projectInfo.financial_position.total_current_liabilities.year_1) > 0
										? ((projectInfo.financial_position.inventories.year_1 + projectInfo.financial_position.assets_classified_as_held_for_sale.year_1 + projectInfo.financial_position.trade_and_other_receivables.year_1) / (projectInfo.financial_position.total_current_liabilities.year_1)).toFixed(3) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(projectInfo.financial_position.inventories.year_1 + projectInfo.financial_position.assets_classified_as_held_for_sale.year_1 + projectInfo.financial_position.trade_and_other_receivables.year_1) / (projectInfo.financial_position.total_current_liabilities.year_1) !== "-" ? (
										(projectInfo.financial_position.inventories.year_1 + projectInfo.financial_position.assets_classified_as_held_for_sale.year_1 + projectInfo.financial_position.trade_and_other_receivables.year_1) / (projectInfo.financial_position.total_current_liabilities.year_1) > 1 ? 1 : 4) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(projectInfo.financial_position.total_current_liabilities.year_2) > 0
										? ((projectInfo.financial_position.inventories.year_2 + projectInfo.financial_position.assets_classified_as_held_for_sale.year_2 + projectInfo.financial_position.trade_and_other_receivables.year_2) / (projectInfo.financial_position.total_current_liabilities.year_2)).toFixed(3) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{((projectInfo.financial_position.inventories.year_2 + projectInfo.financial_position.assets_classified_as_held_for_sale.year_2 + projectInfo.financial_position.trade_and_other_receivables.year_2) / (projectInfo.financial_position.total_current_liabilities.year_2)) !== "-" ? (
										((projectInfo.financial_position.inventories.year_2 + projectInfo.financial_position.assets_classified_as_held_for_sale.year_2 + projectInfo.financial_position.trade_and_other_receivables.year_2) / (projectInfo.financial_position.total_current_liabilities.year_2)) > 1 ? 1 : 4) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(projectInfo.financial_position.total_current_liabilities.year_3) > 0
										? ((projectInfo.financial_position.inventories.year_3 + projectInfo.financial_position.assets_classified_as_held_for_sale.year_3 + projectInfo.financial_position.trade_and_other_receivables.year_3) / (projectInfo.financial_position.total_current_liabilities.year_3)).toFixed(3) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(projectInfo.financial_position.inventories.year_3 + projectInfo.financial_position.assets_classified_as_held_for_sale.year_3 + projectInfo.financial_position.trade_and_other_receivables.year_3) / (projectInfo.financial_position.total_current_liabilities.year_3) !== "-" ? (
										(projectInfo.financial_position.inventories.year_3 + projectInfo.financial_position.assets_classified_as_held_for_sale.year_3 + projectInfo.financial_position.trade_and_other_receivables.year_3) / (projectInfo.financial_position.total_current_liabilities.year_3) > 1 ? 1 : 4) : "-"}
								</Typography>
							</Grid>
						</Grid>
						<Grid container direction="row" alignItems="center" justifyContent="center">
							<Grid item xs={3} align="center" sx={{ border: 1, borderTop: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line", mb: 5 }}>
									<b>{t("CreditRiskServiceProvider.comparison")}</b>
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(projectInfo.financial_position.total_current_liabilities.year_1) > 0
										? (((projectInfo.financial_position.total_current_assets.year_1) / (projectInfo.financial_position.total_current_liabilities.year_1)) - ((projectInfo.financial_position.inventories.year_1 + projectInfo.financial_position.assets_classified_as_held_for_sale.year_1 + projectInfo.financial_position.trade_and_other_receivables.year_1) / (projectInfo.financial_position.total_current_liabilities.year_1))).toFixed(3) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(((projectInfo.financial_position.total_current_assets.year_1) / (projectInfo.financial_position.total_current_liabilities.year_1)) - ((projectInfo.financial_position.inventories.year_1 + projectInfo.financial_position.assets_classified_as_held_for_sale.year_1 + projectInfo.financial_position.trade_and_other_receivables.year_1) / (projectInfo.financial_position.total_current_liabilities.year_1))) !== "-" ? (
										(((projectInfo.financial_position.total_current_assets.year_1) / (projectInfo.financial_position.total_current_liabilities.year_1)) - ((projectInfo.financial_position.inventories.year_1 + projectInfo.financial_position.assets_classified_as_held_for_sale.year_1 + projectInfo.financial_position.trade_and_other_receivables.year_1) / (projectInfo.financial_position.total_current_liabilities.year_1))) < 0.3 ? 2 : 4) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(projectInfo.financial_position.total_current_liabilities.year_2) > 0
										? (((projectInfo.financial_position.total_current_assets.year_2) / (projectInfo.financial_position.total_current_liabilities.year_2)) - ((projectInfo.financial_position.inventories.year_2 + projectInfo.financial_position.assets_classified_as_held_for_sale.year_2 + projectInfo.financial_position.trade_and_other_receivables.year_2) / (projectInfo.financial_position.total_current_liabilities.year_2))).toFixed(3) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(((projectInfo.financial_position.total_current_assets.year_2) / (projectInfo.financial_position.total_current_liabilities.year_2)) - ((projectInfo.financial_position.inventories.year_2 + projectInfo.financial_position.assets_classified_as_held_for_sale.year_2 + projectInfo.financial_position.trade_and_other_receivables.year_2) / (projectInfo.financial_position.total_current_liabilities.year_2))) !== "-" ? (
										(((projectInfo.financial_position.total_current_assets.year_2) / (projectInfo.financial_position.total_current_liabilities.year_2)) - ((projectInfo.financial_position.inventories.year_2 + projectInfo.financial_position.assets_classified_as_held_for_sale.year_2 + projectInfo.financial_position.trade_and_other_receivables.year_2) / (projectInfo.financial_position.total_current_liabilities.year_2))) < 0.3 ? 2 : 4) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(projectInfo.financial_position.total_current_liabilities.year_3) > 0
										? (((projectInfo.financial_position.total_current_assets.year_3) / (projectInfo.financial_position.total_current_liabilities.year_3)) - ((projectInfo.financial_position.inventories.year_3 + projectInfo.financial_position.assets_classified_as_held_for_sale.year_3 + projectInfo.financial_position.trade_and_other_receivables.year_3) / (projectInfo.financial_position.total_current_liabilities.year_3))).toFixed(3) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(((projectInfo.financial_position.total_current_assets.year_3) / (projectInfo.financial_position.total_current_liabilities.year_3)) - ((projectInfo.financial_position.inventories.year_3 + projectInfo.financial_position.assets_classified_as_held_for_sale.year_3 + projectInfo.financial_position.trade_and_other_receivables.year_3) / (projectInfo.financial_position.total_current_liabilities.year_3))) !== "-" ? (
										(((projectInfo.financial_position.total_current_assets.year_3) / (projectInfo.financial_position.total_current_liabilities.year_3)) - ((projectInfo.financial_position.inventories.year_3 + projectInfo.financial_position.assets_classified_as_held_for_sale.year_3 + projectInfo.financial_position.trade_and_other_receivables.year_3) / (projectInfo.financial_position.total_current_liabilities.year_3))) < 0.3 ? 2 : 4) : "-"}
								</Typography>
							</Grid>
						</Grid>
						<Grid item container direction="row" alignItems="center" justifyContent="center" bgcolor="grey.main" sx={{ border: 1 }}>
							<Typography variant="h7" alignItems="center">
								<b>{t("CreditRiskServiceProvider.solvency")}</b>
							</Typography>
						</Grid>
						<Grid container direction="row" alignItems="center" justifyContent="center">
							<Grid item xs={3} align="center" sx={{ border: 1, borderTop: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line", mb: 5 }}>
									<b>{t("CreditRiskServiceProvider.solvencyRatio")}</b>
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(projectInfo.financial_position.total_liabilities.year_1) > 0
										? ((((projectInfo.profit_loss.ebitda.year_1) - (projectInfo.profit_loss.depreciation_value.year_1)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_1) / (projectInfo.profit_loss.income_tax_value.year_1)))) / (projectInfo.financial_position.total_liabilities.year_1)).toFixed(3) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{((((projectInfo.profit_loss.ebitda.year_1) - (projectInfo.profit_loss.depreciation_value.year_1)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_1) / (projectInfo.profit_loss.income_tax_value.year_1)))) / (projectInfo.financial_position.total_liabilities.year_1)) !== "-" ? (
										((((projectInfo.profit_loss.ebitda.year_1) - (projectInfo.profit_loss.depreciation_value.year_1))
											- (1 - ((projectInfo.profit_loss.earnings_before_tax.year_1) / (projectInfo.profit_loss.income_tax_value.year_1))))
											/ (projectInfo.financial_position.total_liabilities.year_1)) > 0.6 ? 1
											: ((((projectInfo.profit_loss.ebitda.year_1) - (projectInfo.profit_loss.depreciation_value.year_1))
												- (1 - ((projectInfo.profit_loss.earnings_before_tax.year_1) / (projectInfo.profit_loss.income_tax_value.year_1))))
												/ (projectInfo.financial_position.total_liabilities.year_1)) > 0.5
												&& ((((projectInfo.profit_loss.ebitda.year_1) - (projectInfo.profit_loss.depreciation_value.year_1))
													- (1 - ((projectInfo.profit_loss.earnings_before_tax.year_1) / (projectInfo.profit_loss.income_tax_value.year_1))))
													/ (projectInfo.financial_position.total_liabilities.year_1)) <= 0.6 ? 2
												: ((((projectInfo.profit_loss.ebitda.year_1) - (projectInfo.profit_loss.depreciation_value.year_1))
													- (1
														- ((projectInfo.profit_loss.earnings_before_tax.year_1) / (projectInfo.profit_loss.income_tax_value.year_1))))
													/ (projectInfo.financial_position.total_liabilities.year_1)) > 0.4
													&& ((((projectInfo.profit_loss.ebitda.year_1) - (projectInfo.profit_loss.depreciation_value.year_1))
														- (1 - ((projectInfo.profit_loss.earnings_before_tax.year_1) / (projectInfo.profit_loss.income_tax_value.year_1))))
														/ (projectInfo.financial_position.total_liabilities.year_1)) <= 0.5 ? 3
													: ((((projectInfo.profit_loss.ebitda.year_1) - (projectInfo.profit_loss.depreciation_value.year_1)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_1) / (projectInfo.profit_loss.income_tax_value.year_1)))) / (projectInfo.financial_position.total_liabilities.year_1)) > 0.3 && ((((projectInfo.profit_loss.ebitda.year_1) - (projectInfo.profit_loss.depreciation_value.year_1)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_1) / (projectInfo.profit_loss.income_tax_value.year_1)))) / (projectInfo.financial_position.total_liabilities.year_1)) <= 0.4 ? 4 : 5) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(projectInfo.financial_position.total_liabilities.year_2) > 0
										? ((((projectInfo.profit_loss.ebitda.year_2) - (projectInfo.profit_loss.depreciation_value.year_2)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_2) / (projectInfo.profit_loss.income_tax_value.year_2)))) / (projectInfo.financial_position.total_liabilities.year_2)).toFixed(3) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{((((projectInfo.profit_loss.ebitda.year_2) - (projectInfo.profit_loss.depreciation_value.year_2)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_2) / (projectInfo.profit_loss.income_tax_value.year_2)))) / (projectInfo.financial_position.total_liabilities.year_2)) !== "-" ? (
										((((projectInfo.profit_loss.ebitda.year_2) - (projectInfo.profit_loss.depreciation_value.year_2))
											- (1 - ((projectInfo.profit_loss.earnings_before_tax.year_2) / (projectInfo.profit_loss.income_tax_value.year_2))))
											/ (projectInfo.financial_position.total_liabilities.year_2)) > 0.6 ? 1
											: ((((projectInfo.profit_loss.ebitda.year_2) - (projectInfo.profit_loss.depreciation_value.year_2))
												- (1 - ((projectInfo.profit_loss.earnings_before_tax.year_2) / (projectInfo.profit_loss.income_tax_value.year_2))))
												/ (projectInfo.financial_position.total_liabilities.year_2)) > 0.5
												&& ((((projectInfo.profit_loss.ebitda.year_2) - (projectInfo.profit_loss.depreciation_value.year_2))
													- (1 - ((projectInfo.profit_loss.earnings_before_tax.year_2) / (projectInfo.profit_loss.income_tax_value.year_2))))
													/ (projectInfo.financial_position.total_liabilities.year_2)) <= 0.6 ? 2
												: ((((projectInfo.profit_loss.ebitda.year_2) - (projectInfo.profit_loss.depreciation_value.year_2))
													- (1
														- ((projectInfo.profit_loss.earnings_before_tax.year_2) / (projectInfo.profit_loss.income_tax_value.year_2))))
													/ (projectInfo.financial_position.total_liabilities.year_2)) > 0.4
													&& ((((projectInfo.profit_loss.ebitda.year_2) - (projectInfo.profit_loss.depreciation_value.year_2))
														- (1 - ((projectInfo.profit_loss.earnings_before_tax.year_2) / (projectInfo.profit_loss.income_tax_value.year_2))))
														/ (projectInfo.financial_position.total_liabilities.year_2)) <= 0.5 ? 3
													: ((((projectInfo.profit_loss.ebitda.year_2) - (projectInfo.profit_loss.depreciation_value.year_2)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_2) / (projectInfo.profit_loss.income_tax_value.year_2)))) / (projectInfo.financial_position.total_liabilities.year_2)) > 0.3 && ((((projectInfo.profit_loss.ebitda.year_2) - (projectInfo.profit_loss.depreciation_value.year_2)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_2) / (projectInfo.profit_loss.income_tax_value.year_2)))) / (projectInfo.financial_position.total_liabilities.year_2)) <= 0.4 ? 4 : 5) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(projectInfo.financial_position.total_liabilities.year_3) > 0
										? ((((projectInfo.profit_loss.ebitda.year_3) - (projectInfo.profit_loss.depreciation_value.year_3)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_3) / (projectInfo.profit_loss.income_tax_value.year_3)))) / (projectInfo.financial_position.total_liabilities.year_3)).toFixed(3) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{((((projectInfo.profit_loss.ebitda.year_3) - (projectInfo.profit_loss.depreciation_value.year_3)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_3) / (projectInfo.profit_loss.income_tax_value.year_3)))) / (projectInfo.financial_position.total_liabilities.year_3)) !== "-" ? (
										((((projectInfo.profit_loss.ebitda.year_3) - (projectInfo.profit_loss.depreciation_value.year_3))
											- (1 - ((projectInfo.profit_loss.earnings_before_tax.year_3) / (projectInfo.profit_loss.income_tax_value.year_3))))
											/ (projectInfo.financial_position.total_liabilities.year_3)) > 0.6 ? 1
											: ((((projectInfo.profit_loss.ebitda.year_3) - (projectInfo.profit_loss.depreciation_value.year_3))
												- (1 - ((projectInfo.profit_loss.earnings_before_tax.year_3) / (projectInfo.profit_loss.income_tax_value.year_3))))
												/ (projectInfo.financial_position.total_liabilities.year_3)) > 0.5
												&& ((((projectInfo.profit_loss.ebitda.year_3) - (projectInfo.profit_loss.depreciation_value.year_3))
													- (1 - ((projectInfo.profit_loss.earnings_before_tax.year_3) / (projectInfo.profit_loss.income_tax_value.year_3))))
													/ (projectInfo.financial_position.total_liabilities.year_3)) <= 0.6 ? 2
												: ((((projectInfo.profit_loss.ebitda.year_3) - (projectInfo.profit_loss.depreciation_value.year_3))
													- (1
														- ((projectInfo.profit_loss.earnings_before_tax.year_3) / (projectInfo.profit_loss.income_tax_value.year_3))))
													/ (projectInfo.financial_position.total_liabilities.year_3)) > 0.4
													&& ((((projectInfo.profit_loss.ebitda.year_3) - (projectInfo.profit_loss.depreciation_value.year_3))
														- (1 - ((projectInfo.profit_loss.earnings_before_tax.year_3) / (projectInfo.profit_loss.income_tax_value.year_3))))
														/ (projectInfo.financial_position.total_liabilities.year_3)) <= 0.5 ? 3
													: ((((projectInfo.profit_loss.ebitda.year_3) - (projectInfo.profit_loss.depreciation_value.year_3)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_3) / (projectInfo.profit_loss.income_tax_value.year_3)))) / (projectInfo.financial_position.total_liabilities.year_3)) > 0.3 && ((((projectInfo.profit_loss.ebitda.year_3) - (projectInfo.profit_loss.depreciation_value.year_3)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_3) / (projectInfo.profit_loss.income_tax_value.year_3)))) / (projectInfo.financial_position.total_liabilities.year_3)) <= 0.4 ? 4 : 5) : "-"}
								</Typography>
							</Grid>
						</Grid>
						<Grid item container direction="row" alignItems="center" justifyContent="center" bgcolor="grey.main" sx={{ border: 1 }}>
							<Typography variant="h7" alignItems="center">
								<b>{t("CreditRiskServiceProvider.profitability")}</b>
							</Typography>
						</Grid>
						<Grid container direction="row" alignItems="center" justifyContent="center">
							<Grid item xs={3} align="center" sx={{ border: 1, borderTop: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line", mb: 5 }}>
									<b>{t("CreditRiskServiceProvider.netProfitMargin")}</b>
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(projectInfo.profit_loss.revenue.year_1) > 0
										? (((projectInfo.profit_loss.profit_after_tax.year_1) / (projectInfo.profit_loss.revenue.year_1))).toFixed(3) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(((projectInfo.profit_loss.profit_after_tax.year_1) / (projectInfo.profit_loss.revenue.year_1))) !== "-" ? (
										(((projectInfo.profit_loss.profit_after_tax.year_1) / (projectInfo.profit_loss.revenue.year_1))) > 0 ? 2 : 5) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(projectInfo.profit_loss.revenue.year_2) > 0
										? (((projectInfo.profit_loss.profit_after_tax.year_2) / (projectInfo.profit_loss.revenue.year_2))).toFixed(3) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(((projectInfo.profit_loss.profit_after_tax.year_2) / (projectInfo.profit_loss.revenue.year_2))) !== "-" ? (
										(((projectInfo.profit_loss.profit_after_tax.year_2) / (projectInfo.profit_loss.revenue.year_2))) > 0 ? 2 : 5) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(projectInfo.profit_loss.revenue.year_3) > 0
										? (((projectInfo.profit_loss.profit_after_tax.year_3) / (projectInfo.profit_loss.revenue.year_3))).toFixed(3) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(((projectInfo.profit_loss.profit_after_tax.year_3) / (projectInfo.profit_loss.revenue.year_3))) !== "-" ? (
										(((projectInfo.profit_loss.profit_after_tax.year_3) / (projectInfo.profit_loss.revenue.year_3))) > 0 ? 2 : 5) : "-"}
								</Typography>
							</Grid>
						</Grid>
						<Grid container direction="row" alignItems="center" justifyContent="center">
							<Grid item xs={3} align="center" sx={{ border: 1, borderTop: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line", mb: 5 }}>
									<b>{t("CreditRiskServiceProvider.commercialProfitability")}</b>
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(projectInfo.profit_loss.revenue.year_1) > 0
										? (((projectInfo.profit_loss.ebitda.year_1) / (projectInfo.profit_loss.revenue.year_1))).toFixed(3) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(((projectInfo.profit_loss.ebitda.year_1) / (projectInfo.profit_loss.revenue.year_1))) !== "-" ? (
										(((projectInfo.profit_loss.ebitda.year_1) / (projectInfo.profit_loss.revenue.year_1))) > 0 ? 2 : 5) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(projectInfo.profit_loss.revenue.year_2) > 0
										? (((projectInfo.profit_loss.ebitda.year_2) / (projectInfo.profit_loss.revenue.year_2))).toFixed(3) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(((projectInfo.profit_loss.ebitda.year_2) / (projectInfo.profit_loss.revenue.year_2))) !== "-" ? (
										(((projectInfo.profit_loss.ebitda.year_2) / (projectInfo.profit_loss.revenue.year_2))) > 0 ? 2 : 5) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(projectInfo.profit_loss.revenue.year_3) > 0
										? (((projectInfo.profit_loss.ebitda.year_3) / (projectInfo.profit_loss.revenue.year_3))).toFixed(3) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{(((projectInfo.profit_loss.ebitda.year_3) / (projectInfo.profit_loss.revenue.year_3))) !== "-" ? (
										(((projectInfo.profit_loss.ebitda.year_3) / (projectInfo.profit_loss.revenue.year_3))) > 0 ? 2 : 5) : "-"}
								</Typography>
							</Grid>
						</Grid>
						<Grid container direction="row" alignItems="center" justifyContent="center">
							<Grid item xs={3} align="center" sx={{ border: 1, borderTop: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line", mb: 5 }}>
									<b>{t("CreditRiskServiceProvider.repaymentCapacityRatio")}</b>
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{((projectInfo.profit_loss.ebitda.year_1) + (projectInfo.profit_loss.finance_costs.year_1)
										- projectInfo.profit_loss.financial_income_other.year_1) !== 0
										? (((projectInfo.financial_position.total_non_current_liabilities.year_1) + (projectInfo.profit_loss.interest_payments.year_1)) / ((projectInfo.profit_loss.ebitda.year_1) + (projectInfo.profit_loss.finance_costs.year_1) - (projectInfo.profit_loss.financial_income_other.year_1))).toFixed(3) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{"-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{((projectInfo.profit_loss.ebitda.year_2) + (projectInfo.profit_loss.finance_costs.year_2)
										- projectInfo.profit_loss.financial_income_other.year_2) !== 0
										? (((projectInfo.financial_position.total_non_current_liabilities.year_2) + (projectInfo.profit_loss.interest_payments.year_2)) / ((projectInfo.profit_loss.ebitda.year_2) + (projectInfo.profit_loss.finance_costs.year_2) - (projectInfo.profit_loss.financial_income_other.year_2))).toFixed(3) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{"-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{((projectInfo.profit_loss.ebitda.year_3) + (projectInfo.profit_loss.finance_costs.year_3)
										- projectInfo.profit_loss.financial_income_other.year_3) !== 0
										? (((projectInfo.financial_position.total_non_current_liabilities.year_3) + (projectInfo.profit_loss.interest_payments.year_3)) / ((projectInfo.profit_loss.ebitda.year_3) + (projectInfo.profit_loss.finance_costs.year_3) - (projectInfo.profit_loss.financial_income_other.year_3))).toFixed(3) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{"-"}
								</Typography>
							</Grid>
						</Grid>
						<Grid item container direction="row" alignItems="center" justifyContent="center" bgcolor="grey.main" sx={{ border: 1 }}>
							<Typography variant="h7" alignItems="center">
								<b>{t("CreditRiskServiceProvider.supplyChainRatios")}</b>
							</Typography>
						</Grid>
						<Grid container direction="row" alignItems="center" justifyContent="center">
							<Grid item xs={3} align="center" sx={{ border: 1, borderTop: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line", mb: 5 }}>
									<b>{t("CreditRiskServiceProvider.workingCapital")}</b>
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{((projectInfo.financial_position.total_current_assets.year_1)
										- (projectInfo.financial_position.total_current_liabilities.year_1)).toFixed(3)}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{((projectInfo.financial_position.total_current_assets.year_1) - (projectInfo.financial_position.total_current_liabilities.year_1)) !== "-" ? (
										((projectInfo.financial_position.total_current_assets.year_1) - (projectInfo.financial_position.total_current_liabilities.year_1)) > 0 ? 2 : 4) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{((projectInfo.financial_position.total_current_assets.year_2)
										- (projectInfo.financial_position.total_current_liabilities.year_2)).toFixed(3)}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{((projectInfo.financial_position.total_current_assets.year_2) - (projectInfo.financial_position.total_current_liabilities.year_2)) !== "-" ? (
										((projectInfo.financial_position.total_current_assets.year_2) - (projectInfo.financial_position.total_current_liabilities.year_2)) > 0 ? 2 : 4) : "-"}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{((projectInfo.financial_position.total_current_assets.year_3)
										- (projectInfo.financial_position.total_current_liabilities.year_3)).toFixed(3)}
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ border: 1, borderTop: 0, borderLeft: 0, borderBottom: 0, p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line" }}>
									{((projectInfo.financial_position.total_current_assets.year_3) - (projectInfo.financial_position.total_current_liabilities.year_3)) !== "-" ? (
										((projectInfo.financial_position.total_current_assets.year_3) - (projectInfo.financial_position.total_current_liabilities.year_3)) > 0 ? 2 : 4) : "-"}
								</Typography>
							</Grid>
						</Grid>
						<Grid container direction="row" bgcolor="#3A85C5" alignItems="center" justifyContent="center">
							<Grid item xs={3} align="center" sx={{ p: 1 }}>
								<Typography variant="h7" color="white" sx={{ whiteSpace: "pre-line", mb: 5 }}>
									<b>{t("CreditRiskServiceProvider.totalScore")}</b>
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ p: 1 }}>
								<Typography variant="h7" color="white" sx={{ whiteSpace: "pre-line" }}>
									<b>{""}</b>
								</Typography>
							</Grid>
							<Grid item xs align="center" color="white" sx={{ p: 1 }}>
								<Typography variant="h7" color="white" sx={{ whiteSpace: "pre-line" }}>
									<b>
										{((projectInfo.financial_position.total_current_assets.year_1)
											/ (projectInfo.financial_position.total_current_liabilities.year_1) > 1 ? 1 : 4)
											+ ((projectInfo.financial_position.inventories.year_1
												+ projectInfo.financial_position.assets_classified_as_held_for_sale.year_1
												+ projectInfo.financial_position.trade_and_other_receivables.year_1)
												/ (projectInfo.financial_position.total_current_liabilities.year_1) > 1 ? 1 : 4) + (
												(((projectInfo.financial_position.total_current_assets.year_1)
													/ (projectInfo.financial_position.total_current_liabilities.year_1))
													- ((projectInfo.financial_position.inventories.year_1
														+ projectInfo.financial_position.assets_classified_as_held_for_sale.year_1
														+ projectInfo.financial_position.trade_and_other_receivables.year_1)
														/ (projectInfo.financial_position.total_current_liabilities.year_1))) < 0.3 ? 2 : 4) + (
												((((projectInfo.profit_loss.ebitda.year_1) - (projectInfo.profit_loss.depreciation_value.year_1))
													- (1 - ((projectInfo.profit_loss.earnings_before_tax.year_1) / (projectInfo.profit_loss.income_tax_value.year_1))))
													/ (projectInfo.financial_position.total_liabilities.year_1)) > 0.6 ? 1
													: ((((projectInfo.profit_loss.ebitda.year_1) - (projectInfo.profit_loss.depreciation_value.year_1))
														- (1 - ((projectInfo.profit_loss.earnings_before_tax.year_1) / (projectInfo.profit_loss.income_tax_value.year_1)))) / (projectInfo.financial_position.total_liabilities.year_1)) > 0.5
														&& ((((projectInfo.profit_loss.ebitda.year_1) - (projectInfo.profit_loss.depreciation_value.year_1))
															- (1 - ((projectInfo.profit_loss.earnings_before_tax.year_1) / (projectInfo.profit_loss.income_tax_value.year_1)))) / (projectInfo.financial_position.total_liabilities.year_1)) <= 0.6 ? 2
														: ((((projectInfo.profit_loss.ebitda.year_1) - (projectInfo.profit_loss.depreciation_value.year_1))
															- (1 - ((projectInfo.profit_loss.earnings_before_tax.year_1) / (projectInfo.profit_loss.income_tax_value.year_1)))) / (projectInfo.financial_position.total_liabilities.year_1)) > 0.4
															&& ((((projectInfo.profit_loss.ebitda.year_1) - (projectInfo.profit_loss.depreciation_value.year_1))
																- (1 - ((projectInfo.profit_loss.earnings_before_tax.year_1) / (projectInfo.profit_loss.income_tax_value.year_1)))) / (projectInfo.financial_position.total_liabilities.year_1)) <= 0.5 ? 3
															: ((((projectInfo.profit_loss.ebitda.year_1) - (projectInfo.profit_loss.depreciation_value.year_1)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_1) / (projectInfo.profit_loss.income_tax_value.year_1)))) / (projectInfo.financial_position.total_liabilities.year_1)) > 0.3 && ((((projectInfo.profit_loss.ebitda.year_1) - (projectInfo.profit_loss.depreciation_value.year_1)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_1) / (projectInfo.profit_loss.income_tax_value.year_1)))) / (projectInfo.financial_position.total_liabilities.year_1)) <= 0.4 ? 4 : 5) + (
												(((projectInfo.profit_loss.profit_after_tax.year_1) / (projectInfo.profit_loss.revenue.year_1))) > 0 ? 2 : 5) + (
												(((projectInfo.profit_loss.ebitda.year_1) / (projectInfo.profit_loss.revenue.year_1))) > 0 ? 2 : 5 + (
													((projectInfo.financial_position.total_current_assets.year_1) - (projectInfo.financial_position.total_current_liabilities.year_1)) > 0 ? 2 : 4))}
									</b>
								</Typography>
							</Grid>
							<Grid item xs align="center" sx={{ p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line", mb: 5 }}>
									<b>{""}</b>
								</Typography>
							</Grid>
							<Grid item xs align="center" color="white" sx={{ p: 1 }}>
								<Typography variant="h7" color="white" sx={{ whiteSpace: "pre-line" }}>
									<b>
										{((projectInfo.financial_position.total_current_assets.year_2) / (projectInfo.financial_position.total_current_liabilities.year_2) > 1 ? 1 : 4) + ((projectInfo.financial_position.inventories.year_2 + projectInfo.financial_position.assets_classified_as_held_for_sale.year_2 + projectInfo.financial_position.trade_and_other_receivables.year_2) / (projectInfo.financial_position.total_current_liabilities.year_2) > 1 ? 1 : 4) + (
											(((projectInfo.financial_position.total_current_assets.year_2) / (projectInfo.financial_position.total_current_liabilities.year_2)) - ((projectInfo.financial_position.inventories.year_2 + projectInfo.financial_position.assets_classified_as_held_for_sale.year_2 + projectInfo.financial_position.trade_and_other_receivables.year_2) / (projectInfo.financial_position.total_current_liabilities.year_2))) < 0.3 ? 2 : 4) + (
												((((projectInfo.profit_loss.ebitda.year_2) - (projectInfo.profit_loss.depreciation_value.year_2)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_2) / (projectInfo.profit_loss.income_tax_value.year_2)))) / (projectInfo.financial_position.total_liabilities.year_2)) > 0.6 ? 1
													: ((((projectInfo.profit_loss.ebitda.year_2) - (projectInfo.profit_loss.depreciation_value.year_2)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_2) / (projectInfo.profit_loss.income_tax_value.year_2)))) / (projectInfo.financial_position.total_liabilities.year_2)) > 0.5 && ((((projectInfo.profit_loss.ebitda.year_2) - (projectInfo.profit_loss.depreciation_value.year_2)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_2) / (projectInfo.profit_loss.income_tax_value.year_2)))) / (projectInfo.financial_position.total_liabilities.year_2)) <= 0.6 ? 2
														: ((((projectInfo.profit_loss.ebitda.year_2) - (projectInfo.profit_loss.depreciation_value.year_2)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_2) / (projectInfo.profit_loss.income_tax_value.year_2)))) / (projectInfo.financial_position.total_liabilities.year_2)) > 0.4 && ((((projectInfo.profit_loss.ebitda.year_2) - (projectInfo.profit_loss.depreciation_value.year_2)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_2) / (projectInfo.profit_loss.income_tax_value.year_2)))) / (projectInfo.financial_position.total_liabilities.year_2)) <= 0.5 ? 3
															: ((((projectInfo.profit_loss.ebitda.year_2) - (projectInfo.profit_loss.depreciation_value.year_2)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_2) / (projectInfo.profit_loss.income_tax_value.year_2)))) / (projectInfo.financial_position.total_liabilities.year_2)) > 0.3 && ((((projectInfo.profit_loss.ebitda.year_2) - (projectInfo.profit_loss.depreciation_value.year_2)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_2) / (projectInfo.profit_loss.income_tax_value.year_2)))) / (projectInfo.financial_position.total_liabilities.year_2)) <= 0.4 ? 4 : 5) + (
												(((projectInfo.profit_loss.profit_after_tax.year_2) / (projectInfo.profit_loss.revenue.year_2))) > 0 ? 2 : 5) + (
												(((projectInfo.profit_loss.ebitda.year_2) / (projectInfo.profit_loss.revenue.year_2))) > 0 ? 2 : 5 + (
													((projectInfo.financial_position.total_current_assets.year_2) - (projectInfo.financial_position.total_current_liabilities.year_2)) > 0 ? 2 : 4))}
									</b>
								</Typography>
							</Grid>
							<Grid item xs align="center" color="white" sx={{ p: 1 }}>
								<Typography variant="h7" sx={{ whiteSpace: "pre-line", mb: 5 }}>
									<b>{""}</b>
								</Typography>
							</Grid>
							<Grid item xs align="center" color="white" sx={{ p: 1 }}>
								<Typography variant="h7" color="white" sx={{ whiteSpace: "pre-line" }}>
									<b>
										{((projectInfo.financial_position.total_current_assets.year_3) / (projectInfo.financial_position.total_current_liabilities.year_3) > 1 ? 1 : 4) + ((projectInfo.financial_position.inventories.year_3 + projectInfo.financial_position.assets_classified_as_held_for_sale.year_3 + projectInfo.financial_position.trade_and_other_receivables.year_3) / (projectInfo.financial_position.total_current_liabilities.year_3) > 1 ? 1 : 4) + (
											(((projectInfo.financial_position.total_current_assets.year_3) / (projectInfo.financial_position.total_current_liabilities.year_3)) - ((projectInfo.financial_position.inventories.year_3 + projectInfo.financial_position.assets_classified_as_held_for_sale.year_3 + projectInfo.financial_position.trade_and_other_receivables.year_3) / (projectInfo.financial_position.total_current_liabilities.year_3))) < 0.3 ? 2 : 4) + (
												((((projectInfo.profit_loss.ebitda.year_3) - (projectInfo.profit_loss.depreciation_value.year_3)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_3) / (projectInfo.profit_loss.income_tax_value.year_3)))) / (projectInfo.financial_position.total_liabilities.year_3)) > 0.6 ? 1
													: ((((projectInfo.profit_loss.ebitda.year_3) - (projectInfo.profit_loss.depreciation_value.year_3)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_3) / (projectInfo.profit_loss.income_tax_value.year_3)))) / (projectInfo.financial_position.total_liabilities.year_3)) > 0.5 && ((((projectInfo.profit_loss.ebitda.year_3) - (projectInfo.profit_loss.depreciation_value.year_3)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_3) / (projectInfo.profit_loss.income_tax_value.year_3)))) / (projectInfo.financial_position.total_liabilities.year_3)) <= 0.6 ? 2
														: ((((projectInfo.profit_loss.ebitda.year_3) - (projectInfo.profit_loss.depreciation_value.year_3)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_3) / (projectInfo.profit_loss.income_tax_value.year_3)))) / (projectInfo.financial_position.total_liabilities.year_3)) > 0.4 && ((((projectInfo.profit_loss.ebitda.year_3) - (projectInfo.profit_loss.depreciation_value.year_3)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_3) / (projectInfo.profit_loss.income_tax_value.year_3)))) / (projectInfo.financial_position.total_liabilities.year_3)) <= 0.5 ? 3
															: ((((projectInfo.profit_loss.ebitda.year_3) - (projectInfo.profit_loss.depreciation_value.year_3)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_3) / (projectInfo.profit_loss.income_tax_value.year_3)))) / (projectInfo.financial_position.total_liabilities.year_3)) > 0.3 && ((((projectInfo.profit_loss.ebitda.year_3) - (projectInfo.profit_loss.depreciation_value.year_3)) - (1 - ((projectInfo.profit_loss.earnings_before_tax.year_3) / (projectInfo.profit_loss.income_tax_value.year_3)))) / (projectInfo.financial_position.total_liabilities.year_3)) <= 0.4 ? 4 : 5) + (
												(((projectInfo.profit_loss.profit_after_tax.year_3) / (projectInfo.profit_loss.revenue.year_3))) > 0 ? 2 : 5) + (
												(((projectInfo.profit_loss.ebitda.year_3) / (projectInfo.profit_loss.revenue.year_3))) > 0 ? 2 : 5 + (
													((projectInfo.financial_position.total_current_assets.year_3) - (projectInfo.financial_position.total_current_liabilities.year_3)) > 0 ? 2 : 4))}
									</b>
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				)}
			<StatusDialog
				open={projectStateDialog}
				status={projectInfo.status}
				name={projectInfo.name}
				onClose={setProjectStateDialog}
				onChange={changeProjectState}
			/>
		</>
	);
};

export default memo(CreditRiskServiceProvider);
