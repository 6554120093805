import { useCallback, memo, useMemo, useState, useEffect, forwardRef } from "react";
import { Link } from "react-router-dom";
import { Button, Typography, Grid, LinearProgress, Box, Link as MaterialLink, ToggleButton, Dialog, DialogTitle, DialogContent, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";
import GroupsIcon from "@mui/icons-material/Groups";
import { dateOldToNew, stringAToZInsensitive } from "@iamnapo/sort";
import shallow from "zustand/shallow";
import clsx from "clsx";

import { isFuzzyMatch, dayjs, useSnackbar, httpResponseIsValid } from "../utils";
import Table from "../components/Table";
import useGlobalState from "../use-global-state";
import { getInvestorRequests, introduceProject } from "../api";

const Transition = forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const useStyles = makeStyles((theme) => ({
	header: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		letterSpacing: theme.spacing(0.2),
	},
	page: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
	screenTitle: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		letterSpacing: theme.spacing(0.2),
	},
	form: {
		width: "420px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "start",
	},
}));

const InvestorRequests = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [investorRequests, setInvestorRequests] = useState([]);

	const [introduceDialogOpen, setIntroduceDialogOpen] = useState(false);
	const [selectedRequest, setSelectedRequest] = useState([]);

	const { setName } = useGlobalState(useCallback((e) => ({
		setName: e.setName,
	}), []), shallow);

	const { error, success } = useSnackbar();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getInvestorRequests();
				if (httpResponseIsValid(response)) {
					setInvestorRequests(response?.investor_requests);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured when fetching the available requests: ${_error}`);
			}

			setIsLoading(false);
		},
		[error],
	);

	const onOpenIntroduceToUser = useCallback(
		(value) => {
			setIsLoading(true);

			const request = investorRequests.find((r) => r.request.id === value);

			setSelectedRequest(request);
			setIntroduceDialogOpen(true);

			setIsLoading(false);
		}, [investorRequests],
	);

	const onIntroduceProject = async () => {
		setIsLoading(true);

		try {
			const response = await introduceProject({ investorId: selectedRequest.investor.id, projectId: selectedRequest.request.id });
			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			error(_error);
		}

		setIntroduceDialogOpen(false);
		setIsLoading(false);
	};

	const classes = useStyles();

	const tableColumns = useMemo(() => [
		{
			Header: <Typography id="name_header" variant="h6">{"Investor"}</Typography>,
			accessor: "investor",
			id: "investor",
			filterable: true,
			minWidth: 250,
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1.name, value2.name),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id].name, value),
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value?.name}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Name"}</Typography>,
			accessor: "request",
			id: "request",
			filterable: true,
			minWidth: 250,
			maxWidth: 380,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1.name, value2.name),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id].name, value),
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<MaterialLink
						underline="none"
						component={Link}
						to={`/project/${value.id}`}
						onClick={() => setName(value.name)}
					>
						{value.name}
					</MaterialLink>
				</Box>
			),
		},
		{
			Header: <Typography id="name_header" variant="h6">{"Type"}</Typography>,
			accessor: "request",
			id: "request",
			filterable: true,
			minWidth: 250,
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1.type, value2.type),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id].type, value),
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{(value.type === "project") ? "Project" : "Pipeline"}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Requested At"}</Typography>,
			accessor: "createdAt",
			id: "createdAt",
			minWidth: 200,
			maxWidth: 300,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => dateOldToNew((v) => new Date(v))(value1, value2),
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{dayjs(value).format("L")}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Actions"}</Typography>,
			accessor: "request",
			filterable: false,
			sortable: false,
			width: 220,
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Grid item container justifyContent="space-around">
					<ToggleButton
						value="introduce to user"
						title="Introduce to User"
						size="small"
						aria-label="Introduce to User"
						sx={{ borderColor: "primary.main" }}
						onClick={() => onOpenIntroduceToUser(value.id)}
					>
						<GroupsIcon color="primary" />
					</ToggleButton>
				</Grid>
			),
		},
	], [onOpenIntroduceToUser, setName]);

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<>
			{isLoading && (<LinearProgress color="primary" />)}
			<section style={{ paddingTop: "1rem" }}>
				<div className={clsx("container", classes.page)}>
					<Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ "> .MuiGrid-item": { p: 0 }, background: "#F4F4F4", borderRadius: "10px 10px 10px 10px", padding: "8px" }} mb={2}>
						<Grid item hidden />
						<Grid item md={4}>
							<Typography variant="h5" className={classes.screenTitle}>{"INVESTOR REQUESTS"}</Typography>
						</Grid>
						<Grid item xs={0} md={8} />
					</Grid>
					<Table
						data={investorRequests}
						noDataText={isLoading ? "Fetching projects..." : "No data available!"}
						columns={tableColumns}
						defaultSorted={[{ id: "name", desc: true }]}
					/>
				</div>
			</section>
			<Dialog
				fullWidth
				open={introduceDialogOpen}
				TransitionComponent={Transition}
				scroll="body"
				maxWidth="md"
				PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4", maxWidth: "900px" } }}
				onClose={() => setIntroduceDialogOpen(false)}
			>
				<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
					<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
						{"Are you sure?"}
					</Typography>
				</DialogTitle>
				<DialogContent dividers sx={{ overflowY: "hidden" }}>
					<Grid item container sx={{ p: 1, marginTop: "5px" }} mt={1} alignItems="center" justifyContent="center">
						{(selectedRequest?.request?.type === "project")
							&& (
								<Typography variant="h6" sx={{ color: "#1d4363" }}>
									{"Are you sure you want to introduce user ["}
									&nbsp;
									<b>{selectedRequest?.investor?.name}</b>
									&nbsp;
									{"] to the project ["}
									&nbsp;
									<b>{selectedRequest?.request?.name}</b>
									&nbsp;
									{"], granting them complete view access over it?"}
								</Typography>
							)}
						{(selectedRequest?.request?.type === "pipeline")
							&& (
								<Typography variant="h6" sx={{ color: "#1d4363" }}>
									{"Are you sure you want to introduce user ["}
									&nbsp;
									<b>{selectedRequest?.investor?.name}</b>
									&nbsp;
									{"] to the pipeline ["}
									&nbsp;
									<b>{selectedRequest?.request?.name}</b>
									&nbsp;
									{"], granting them complete view access over it and its projects?"}
								</Typography>
							)}
					</Grid>
					<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="25px">
						<Grid item sm={3} display="flex" justifyContent="start">
							<Button
								fullWidth
								variant="outlined"
								sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
								color="primary"
								onClick={() => setIntroduceDialogOpen(false)}
							>
								{"Cancel"}
							</Button>
						</Grid>
						<Grid item display="flex" sm={3} justifyContent="end">
							<Button
								fullWidth
								variant="contained"
								sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
								color="primary"
								onClick={() => onIntroduceProject()}
							>
								{"Introduce"}
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default memo(InvestorRequests);
