import { memo, useCallback, useEffect, useState } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import {
	Grid,
	Tab,
	Tabs,
	Typography,
	FormControl,
	InputLabel,
	Box,
	MenuItem,
	LinearProgress,
	Select,
	Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import InfoIcon from "@mui/icons-material/Info";

import { getCompanySpreasheet, updateCompanySpreadsheet } from "../../api";
import { names, useSnackbar, httpResponseIsValid } from "../../utils";
import PlotlyComponent from "../../components/PlotOutput";

import TableComponent from "./TableComponent";

const currencies = {
	euro: "Euro (€)",
	dollar: "Dollar ($)",
	pound: "GBP (£)",
	chf: "CHF",
	zloty: "Zloty (zl)",
};

const useStyles = makeStyles((theme) => ({
	menuBox: {
		width: "100%",
	},
	boxBorder: {
		border: `2px solid ${theme.palette.primary.main}`,
		borderRadius: theme.spacing(2),
		backgroundColor: "white",
	},
	screenTitle: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		letterSpacing: theme.spacing(0.2),
	},
}));

const UserRoles = {
	owner: "owner",
	member: "member",
};

// eslint-disable-next-line react/prop-types
const SpreadsheetComponent = ({ componentName, companyRole = "member" }) => {
	const [component, setComponent] = useState(componentName);
	const { pathname } = useLocation();
	const [hasFetched, setHasFetched] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [userRole, setUserRole] = useState(companyRole);
	const [projectInfo, setProjectInfo] = useState({
		status: "",
		createdAt: "-",
		pre_assessment: {
			financial_assessment_currency: Object.keys(currencies)[0],
		},
	});
	const { error, success } = useSnackbar();

	const [value, setValue] = useState(() => {
		for (const path of ["past-years", "estimate-current-year", "forecast-year-1", "forecast-year-2"]) {
			if (pathname.includes(path)) return path;
		}

		return "past-years";
	});

	const classes = useStyles();

	const fetchData = useCallback(async () => {
		setIsLoading(true);

		try {
			const response = await getCompanySpreasheet();
			if (httpResponseIsValid(response)) {
				setProjectInfo(response);
				setHasFetched(true);
			} else {
				error(response?.message);
			}
		} catch (_error) {
			console.log(`Error occured when fetching spreadsheet data user: ${_error}`);
		}

		setIsLoading(false);
	}, [error]);

	const submit = async () => {
		setIsLoading(true);

		try {
			const response = await updateCompanySpreadsheet(projectInfo);
			if (httpResponseIsValid(response)) {
				success(response?.message);
			} else {
				error(response?.message);
			}
		} catch (_error) {
			console.log(`Error occured when submiting spreadsheet data user: ${_error}`);
		}

		setIsLoading(false);
	};

	useEffect(() => {
		setUserRole(companyRole);
	}, [companyRole]);

	useEffect(() => {
		setComponent(componentName);
	}, [componentName]);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch {
				/* empty */
			}
		})();
	}, [fetchData]);

	const onValueChange = (e, name) => {
		setProjectInfo({
			...projectInfo,
			pre_assessment: {
				...projectInfo.pre_assessment,
				[name]: e.target.value,
			},
		});
	};

	useEffect(() => {
		setValue(() => {
			for (const path of ["past-years", "estimate-current-year", "forecast-year-1", "forecast-year-2"]) {
				if (pathname.includes(path)) return path;
			}

			return "past-years";
		});
	}, [pathname]);

	useEffect(() => {
		if (hasFetched) {
			submit();
		}
	}, [projectInfo.pre_assessment.financial_assessment_currency]);

	return (
		<>
			{isLoading && <LinearProgress sx={{ width: "100%" }} color="primary" />}
			<Grid
				container
				direction="column"
				alignItems="center"
				py={1}
				px={3}
				sx={{
					"> .MuiGrid-item": { p: 1 },
					position: "fixed",
					maxWidth: "1300px",
					width: "calc(100% - 300px)",
					background: "white",
					zIndex: 999,
				}}
			>
				<Grid
					container
					item
					direction="row"
					alignItems="center"
					sx={{ "> .MuiGrid-item": { p: 0 }, background: "#F4F4F4", borderRadius: "10px 10px 0px 0px" }}
				>
					<Grid item xs={12} md={4}>
						<Typography variant="h5" className={classes.screenTitle}>
							{names[component].name === "Overview" ? "Your financial Assessment" : names[component].name}
							{names[component].name === "Overview"
								&& (
									<Tooltip placement="bottom-start" title="Filling in your financial assessment here will allow you to use the input automatically for every project you create and calculate the credit risk category instantly in the risk assessment tool.">
										<InfoIcon fontSize="small" sx={{ padding: 0.2, color: "green.main" }} />
									</Tooltip>
								)}
						</Typography>
					</Grid>
					<Grid item xs={0} md={3} />
					<Grid item container className={classes.boxBorder} sx={{ p: 1 }} xs={12} md={5}>
						<Grid item xs={12} md={6} />
						<Grid item xs={12} md={6}>
							<Box p={1}>
								<Grid container direction="row" justifyContent="flex-end" alignItems="center">
									<Typography variant="h6">
										{"Created: "}
										<span style={{ marginLeft: "10px" }}>
											{projectInfo.createdAt === "-"
												? "-"
												: new Date(projectInfo.createdAt).toLocaleDateString("en-US")}
										</span>
									</Typography>
								</Grid>
							</Box>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					container
					item
					direction="row"
					alignItems="center"
					sx={{ ">.MuiGrid-item": { p: 0 }, background: "#F4F4F4", borderRadius: "0px 0px 10px 10px" }}
					p={0}
				>
					<Grid item xs={0} md={7} />
					<Grid item container sx={{ p: 1 }} xs={12} md={5} alignItems="center" justifyContent="end">
						<FormControl variant="outlined">
							<InputLabel id="demo-simple-select-filled-label">{"Currency"}</InputLabel>
							<Select
								id="demo-simple-select-outlined"
								value={projectInfo.pre_assessment.financial_assessment_currency}
								label="Currency"
								sx={{ width: "180px", height: "45px", borderRadius: "15px 15px 15px 15px", backgroundColor: "white" }}
								// disabled={shouldUiBeDisabled}
								onChange={(e) => onValueChange(e, "financial_assessment_currency")}
							>
								{Object.keys(currencies).map((cur) => (
									<MenuItem key={cur} value={cur}>
										{currencies[cur]}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			</Grid>
			<Grid container p={4} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "135px" }}>
				<Tabs
					value={value}
					variant="fullWidth"
					classes={{
						root: classes.menuBox,
					}}
					onChange={(_, newValue) => setValue(newValue)}
				>
					<Tab label={<Typography>{"Past Years"}</Typography>} value="past-years" to="past-years" component={Link} />
					<Tab
						label={<Typography>{"Estimate Current Year"}</Typography>}
						value="estimate-current-year"
						to="estimate-current-year"
						component={Link}
					/>
					<Tab
						label={<Typography>{"Forecast Year 1"}</Typography>}
						value="forecast-year-1"
						to="forecast-year-1"
						component={Link}
					/>
					<Tab
						label={<Typography>{"Forecast Year 2"}</Typography>}
						value="forecast-year-2"
						to="forecast-year-2"
						component={Link}
					/>
				</Tabs>
			</Grid>
			<Routes>
				<Route
					index
					element={
						<TableComponent
							key={projectInfo.pre_assessment.financial_assessment_currency}
							isPercentage={component === "kpis"}
							screen={component}
							variables={["year_3", "year_2", "year_1"]}
							currency={projectInfo.pre_assessment.financial_assessment_currency}
							companyRole={userRole}
						/>
					}
				/>
				<Route
					path="/past-years"
					element={
						<TableComponent
							key={projectInfo.pre_assessment.financial_assessment_currency}
							isPercentage={component === "kpis"}
							screen={component}
							variables={["year_3", "year_2", "year_1"]}
							currency={projectInfo.pre_assessment.financial_assessment_currency}
							companyRole={userRole}
						/>
					}
				/>
				<Route
					path="/estimate-current-year"
					element={
						<TableComponent
							key={projectInfo.pre_assessment.financial_assessment_currency}
							isPercentage={component === "kpis"}
							screen={component}
							variables={["current_quarter_1", "current_quarter_2", "current_quarter_3", "current_quarter_4"]}
							currency={projectInfo.pre_assessment.financial_assessment_currency}
							companyRole={userRole}
						/>
					}
				/>
				<Route
					path="/forecast-year-1"
					element={
						<TableComponent
							key={projectInfo.pre_assessment.financial_assessment_currency}
							isPercentage={component === "kpis"}
							screen={component}
							variables={[
								"forecast_1_quarter_1",
								"forecast_1_quarter_2",
								"forecast_1_quarter_3",
								"forecast_1_quarter_4",
							]}
							currency={projectInfo.pre_assessment.financial_assessment_currency}
							companyRole={userRole}
						/>
					}
				/>
				<Route
					path="/forecast-year-2"
					element={
						<TableComponent
							key={projectInfo.pre_assessment.financial_assessment_currency}
							isPercentage={component === "kpis"}
							screen={component}
							variables={[
								"forecast_2_quarter_1",
								"forecast_2_quarter_2",
								"forecast_2_quarter_3",
								"forecast_2_quarter_4",
							]}
							currency={projectInfo.pre_assessment.financial_assessment_currency}
							companyRole={userRole}
						/>
					}
				/>
			</Routes>
			{component === "output" && <PlotlyComponent isProject={false} />}
		</>
	);
};

export default memo(SpreadsheetComponent);
