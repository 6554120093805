import { memo, useCallback, useEffect, useState, forwardRef } from "react";
import { useParams } from "react-router-dom";
import { Button, Grid, LinearProgress, Typography, Slide, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useFormik } from "formik";

import { useSnackbar, httpResponseIsValid } from "../../utils";
import { deleteFile, getProjectSpreadsheet, updateProjectSpreadsheet } from "../../api";
import usePrompt from "../../utils/use-blocker";
import StatusDialog from "../../components/StatusDialog";
import {
	MainMenu,
	LabelRadioFileUpload,
	LabelRadio,
	LabelRadioTextField,
} from "../../components/basic/index";

const Transition = forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const PreAssessment = ({ disableAccess = true, isProjectDeveloper = false, onChangeProjectState }) => {
	const { projectid } = useParams();
	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(disableAccess);
	const [projectStateDialog, setProjectStateDialog] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [projectInfo, setProjectInfo] = useState({
		status: "",
		createdAt: "-",
		pre_assessment_doc_1: {
			original_name: "",
			save_name: "",
		},
		pre_assessment: {
			corporate_tax: "",
			social_contributions: "",
			vat: "",
			has_legal_pending_cases: "",
			audited_financials: "",
			financial_assessment_currency: "",
			legal_pending_cases: "",
		},
	});

	const [enableEditing, setEnableEditing] = useState(false);
	const { success, error } = useSnackbar();
	const [deleteModal, setDeleteModal] = useState({ component: null, variable: null });

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			let response;
			try {
				response = await getProjectSpreadsheet(projectid);
				if (httpResponseIsValid(response)) {
					setProjectInfo(response);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured while fetching financial pre-assessment data: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, projectid],
	);

	const handleSubmit = async (values) => {
		setIsLoading(true);

		try {
			const response = await updateProjectSpreadsheet(projectid, values);
			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			error(`Error occured while submiting financial pre-assessment data: ${_error}`);
		}

		setIsLoading(false);
	};

	const formik = useFormik({
		initialValues: projectInfo,
		onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	});

	const { dirty } = formik;
	usePrompt("You have unsaved changes which will be lost. Are you sure you want to leave the page?", dirty);

	const changeProjectState = useCallback(
		async () => {
			await onChangeProjectState();
			fetchData();
		}, [fetchData, onChangeProjectState],
	);

	const onDelete = async (comp) => {
		try {
			setIsLoading(true);
			const { success: deleteSuccess } = await deleteFile({ projectid, component: comp.component, variable: comp.variable });
			console.log(deleteSuccess);
			if (deleteSuccess) {
				success("File deleted successfully");
				await fetchData();
				setIsLoading(false);
			} else {
				error();
				setIsLoading(false);
			}
		} catch { /* empty */ }
	};

	useEffect(() => {
		setShouldUiBeDisabled(disableAccess || !enableEditing);
	}, [disableAccess, enableEditing]);

	useEffect(() => {
		formik.resetForm({ values: projectInfo, dirty: false });
	}, [projectInfo]);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData, projectid]);

	return (
		<form onSubmit={formik.handleSubmit}>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<MainMenu
				title="PRE-ASSESSMENT"
				dirty={dirty}
				project={projectInfo}
				isDeveloper={isProjectDeveloper}
				disabled={false}
				onFetch={fetchData}
				onEdit={setEnableEditing}
				onChange={onChangeProjectState}
			/>
			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "105px" }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{"INFORMATION"}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} alignItems="center">
					<LabelRadio
						id="pre_assessment.corporate_tax"
						label="Corporate tax: Have you filled your financials (tax return etc.)?"
						size="large"
						options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>
					<LabelRadio
						id="pre_assessment.social_contributions"
						label="Social contributions: Is the company up-to-date with their social contributions & salary tax?"
						size="large"
						options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>
					<LabelRadio
						id="pre_assessment.vat"
						label="Did you file for VAT?"
						size="large"
						options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>
					<LabelRadioTextField
						radioId="pre_assessment.has_legal_pending_cases"
						fieldId="pre_assessment.legal_pending_cases"
						label="Is your company having any legal pending cases against?"
						size="large"
						typosize="h8"
						type="text"
						disabled={shouldUiBeDisabled}
						formik={formik}
					/>
					<LabelRadioFileUpload
						radioId="pre_assessment.audited_financials"
						fieldId="pre_assessment_doc_1"
						projectId={projectid}
						label="Does the company have filled audited financials?"
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="spreadsheet"
						onSuccess={() => fetchData()}
					/>
				</Grid>
			</Grid>
			{deleteModal.component !== null
				&& deleteModal.variable !== null
				&& (
					<Dialog
						keepMounted
						open={deleteModal.component !== null && deleteModal.variable !== null}
						TransitionComponent={Transition}
						onClose={() => setDeleteModal({ component: null, variable: null })}
					>
						<DialogTitle>
							{"Are you sure?"}
						</DialogTitle>
						<DialogContent dividers>
							<DialogContentText>
								{"Are you sure you want to delete the file?"}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								autoFocus
								startIcon={<Delete />}
								variant="contained"
								onClick={() => {
									onDelete(deleteModal);
									setDeleteModal({ component: null, variable: null });
								}}
							>
								{"Delete"}
							</Button>
							<Button variant="outlined" onClick={() => setDeleteModal({ component: null, variable: null })}>{"Cancel"}</Button>
						</DialogActions>
					</Dialog>
				)}
			<StatusDialog
				open={projectStateDialog}
				status={projectInfo.status}
				name={projectInfo.name}
				onClose={setProjectStateDialog}
				onChange={changeProjectState}
			/>
		</form>
	);
};

export default memo(PreAssessment);
