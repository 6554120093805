import { useCallback, memo, useMemo, useState, useEffect, forwardRef } from "react";
import { Button, Typography, Grid, LinearProgress, Box, ToggleButton, Dialog, DialogTitle, DialogContent, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Done, Clear } from "@mui/icons-material";
import { dateOldToNew, stringAToZInsensitive } from "@iamnapo/sort";
import clsx from "clsx";

import { isFuzzyMatch, dayjs, useSnackbar, httpResponseIsValid } from "../utils";
import Table from "../components/Table";
import { getInvitations, acceptInvitation, declineInvitation } from "../api";

const Transition = forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const useStyles = makeStyles((theme) => ({
	header: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		letterSpacing: theme.spacing(0.2),
	},
	page: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
	screenTitle: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		letterSpacing: theme.spacing(0.2),
	},
	form: {
		width: "420px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "start",
	},
}));

const Invitations = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [invitations, setInvitations] = useState([]);

	const [invitationDialogOpen, setInvitationDialogOpen] = useState(false);
	const [declineDialogOpen, setDeclineDialogOpen] = useState(false);
	const [selectedInvitation, setSelectedInvitation] = useState({});

	const { error, success } = useSnackbar();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getInvitations();
				if (httpResponseIsValid(response)) {
					setInvitations(response?.invitations);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured when fetching the available nvitations: ${_error}`);
			}

			setIsLoading(false);
		},
		[error],
	);

	const onOpenInvitationDialog = useCallback(
		(value) => {
			const invitation = invitations.find((i) => i._id === value);

			setSelectedInvitation(invitation);
			setInvitationDialogOpen(true);
		}, [invitations],
	);

	const onOpenDeclineDialog = useCallback(
		(value) => {
			const invitation = invitations.find((i) => i._id === value);

			setSelectedInvitation(invitation);
			setDeclineDialogOpen(true);
		}, [invitations],
	);

	const onDecideLater = () => {
		setDeclineDialogOpen(false);
		setInvitationDialogOpen(false);
	};

	const onProceedAction = async (action) => {
		setIsLoading(true);

		try {
			const response = action ? await acceptInvitation(selectedInvitation) : await declineInvitation(selectedInvitation);
			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			error(_error);
		}

		setDeclineDialogOpen(false);
		setInvitationDialogOpen(false);
		setIsLoading(false);
	};

	const classes = useStyles();

	const tableColumns = useMemo(() => [
		{
			Header: <Typography id="name_header" variant="h6">{"Company"}</Typography>,
			accessor: "company_name",
			id: "company_name",
			filterable: true,
			minWidth: 200,
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1.name, value2.name),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id].name, value),
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Owner"}</Typography>,
			accessor: "host_email",
			id: "host_email",
			filterable: true,
			minWidth: 250,
			maxWidth: 380,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Type"}</Typography>,
			accessor: "type",
			id: "type",
			minWidth: 100,
			maxWidth: 250,
			style: { overflow: "visible" },
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Expires"}</Typography>,
			accessor: "expireAt",
			id: "expireAt",
			minWidth: 250,
			maxWidth: 380,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => dateOldToNew((v) => new Date(v))(value1, value2),
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{dayjs(value).format("L")}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Actions"}</Typography>,
			accessor: "_id",
			filterable: false,
			sortable: false,
			width: 180,
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Grid item container justifyContent="space-around">
					<ToggleButton
						value="view"
						title="View & Accept"
						size="small"
						aria-label="View"
						sx={{ borderColor: "primary.main" }}
						onClick={() => onOpenInvitationDialog(value)}
					>
						<Done color="primary" />
					</ToggleButton>
					<ToggleButton
						value="decline"
						title="Decline"
						size="small"
						aria-label="Decline"
						sx={{ borderColor: "primary.main" }}
						onClick={() => onOpenDeclineDialog(value)}
					>
						<Clear color="error" />
					</ToggleButton>
				</Grid>
			),
		},
	], [onOpenDeclineDialog, onOpenInvitationDialog]);

	useEffect(() => {
		fetchData();
	}, []);

	const roleToUpperCase = (value) => {
		switch (value) {
			case "owner":
				return "Owner";
			case "member":
				return "Member";
			case "investor_member":
				return "Investor Member";
			case "investor_owner":
				return "Investor Owner";
			case "admin":
				return "Sefa Admin";
			default:
				return "Unknown";
		}
	};

	return (
		<>
			{isLoading && (<LinearProgress color="primary" />)}
			<section style={{ paddingTop: "1rem" }}>
				<div className={clsx("container", classes.page)}>
					<Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ "> .MuiGrid-item": { p: 0 }, background: "#F4F4F4", borderRadius: "10px 10px 10px 10px", padding: "8px" }} mb={2}>
						<Grid item hidden />
						<Grid item md={4}>
							<Typography variant="h5" className={classes.screenTitle}>{"My Pipelines"}</Typography>
						</Grid>
						<Grid item xs={0} md={8} />
					</Grid>
					<Table
						data={invitations}
						noDataText={isLoading ? "Fetching projects..." : "No data available!"}
						columns={tableColumns}
						defaultSorted={[{ id: "name", desc: true }]}
					/>
				</div>
			</section>
			{(selectedInvitation !== undefined && Object.keys(selectedInvitation).length > 0)
				&& (
					<Dialog
						fullWidth
						open={invitationDialogOpen}
						TransitionComponent={Transition}
						scroll="body"
						maxWidth="md"
						PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4", maxWidth: "750px" } }}
						onClose={onDecideLater}
					>
						<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
							<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
								{`Review of ${selectedInvitation.type} Invitation`}
							</Typography>
						</DialogTitle>
						<DialogContent dividers sx={{ overflowY: "hidden" }}>
							{selectedInvitation.type === "project"
								&& (
									<Grid item container sx={{ p: 1, marginTop: "5px" }} mt={1} alignItems="center" justifyContent="center">
										<Typography variant="h6" sx={{ color: "#1d4363" }}>
											{"You have been invited to join the project"}
											&nbsp;
											<b>{selectedInvitation.data.project_name}</b>
											&nbsp;
											{"of the company"}
											&nbsp;
											<b>{selectedInvitation.company_name}</b>
											&nbsp;
											{"as a"}
											&nbsp;
											<b>{"Material Expert"}</b>
											&nbsp;
											{". This means you will have complete"}
											&nbsp;
											<b>{"view"}</b>
											&nbsp;
											{"access on all of its tools."}
										</Typography>
									</Grid>
								)}
							{selectedInvitation.type === "company"
								&& (
									<>
										<Grid item container sx={{ p: 1, marginTop: "5px" }} mt={1} alignItems="center" justifyContent="center">
											<Typography variant="h6" sx={{ color: "#1d4363" }}>
												{"You have been invited to join the company"}
												&nbsp;
												<b>{selectedInvitation.company_name}</b>
												&nbsp;
												{"as"}
												&nbsp;
												<b>{roleToUpperCase(selectedInvitation.data.user_role)}</b>
												&nbsp;
												{(selectedInvitation.data.user_role === "owner") && (". This means you will be granted full access to all company utilities as well as all company projects and their respective tools.")}
												{(selectedInvitation.data.user_role === "investor_owner") && (". This means you will be granted full access to all company utilities.")}
												{(selectedInvitation.data.user_role === "member" && selectedInvitation.data.projects.length > 0) && (". You have been also assigned to the following company projects:")}
												{(selectedInvitation.data.user_role === "investor_member") && (".")}
											</Typography>

										</Grid>
										<Grid item container sx={{ p: 5, marginTop: "-30px" }} mt={1} alignItems="center" justifyContent="start">
											{selectedInvitation.data.projects.length > 0
												&& (
													<ol>
														{selectedInvitation.data.projects.map((p) => (
															<li key={p._id}>
																<Typography variant="h6" sx={{ color: "#1d4363" }}>
																	{"Project"}
																	&nbsp;
																	<b>{p.name}</b>
																	&nbsp;
																	{"as"}
																	&nbsp;
																	<b>{p.role}</b>
																</Typography>
															</li>
														))}
													</ol>
												)}
										</Grid>
									</>
								)}
							<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="25px">
								<Grid item sm={3} display="flex" justifyContent="start">
									<Button
										fullWidth
										variant="outlined"
										sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
										color="primary"
										onClick={onDecideLater}
									>
										{"Cancel"}
									</Button>
								</Grid>
								<Grid item display="flex" sm={3} justifyContent="end">
									<Button
										fullWidth
										variant="contained"
										sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
										color="primary"
										onClick={() => onProceedAction(true)}
									>
										{"Accept"}
									</Button>
								</Grid>
							</Grid>
						</DialogContent>
					</Dialog>
				)}
			<Dialog
				fullWidth
				open={declineDialogOpen}
				TransitionComponent={Transition}
				scroll="body"
				maxWidth="md"
				PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4", maxWidth: "900px" } }}
				onClose={onDecideLater}
			>
				<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
					<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
						{"Are you sure?"}
					</Typography>
				</DialogTitle>
				<DialogContent dividers sx={{ overflowY: "hidden" }}>
					{selectedInvitation.type === "project"
						&& (
							<Grid item container sx={{ p: 1, marginTop: "5px" }} mt={1} alignItems="center" justifyContent="center">
								<Typography variant="h6" sx={{ color: "#1d4363" }}>
									{"Are you sure you want to decline the invitation to join the project"}
									&nbsp;
									<b>{selectedInvitation.data.project_name}</b>
									&nbsp;
									{"of the company"}
									&nbsp;
									<b>{selectedInvitation.company_name}</b>
									&nbsp;
									{"as a"}
									&nbsp;
									<b>{"Material Expert;"}</b>
								</Typography>
							</Grid>
						)}
					{selectedInvitation.type === "company"
						&& (
							<Grid item container sx={{ p: 1, marginTop: "5px" }} mt={1} alignItems="center" justifyContent="center">
								<Typography variant="h6" sx={{ color: "#1d4363" }}>
									{"Are you sure you want to decline the invitation to join the company"}
									&nbsp;
									<b>{selectedInvitation.company_name}</b>
									&nbsp;
									{"as"}
									&nbsp;
									<b>{`${roleToUpperCase(selectedInvitation.data.user_role)};`}</b>
								</Typography>
							</Grid>
						)}
					<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="25px">
						<Grid item sm={3} display="flex" justifyContent="start">
							<Button
								fullWidth
								variant="outlined"
								sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
								color="primary"
								onClick={onDecideLater}
							>
								{"Cancel"}
							</Button>
						</Grid>
						<Grid item display="flex" sm={3} justifyContent="end">
							<Button
								fullWidth
								variant="contained"
								sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
								color="primary"
								onClick={() => onProceedAction(false)}
							>
								{"Decline"}
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default memo(Invitations);
