/* eslint-disable no-shadow */
import { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, LinearProgress, Typography, Link, MenuItem, Checkbox, ListItemIcon, ListItemText, FormControl, Select } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useFormik, getIn } from "formik";

import { useSnackbar, httpResponseIsValid } from "../../utils";
import { options } from "../../utils/options";
import { getProjectRiskAssessentProtocol, updateProjectRiskAssessentProtocol } from "../../api";
import usePrompt from "../../utils/use-blocker";
import StatusDialog from "../../components/StatusDialog";
import {
	MainMenu,
	LabelTextField,
	LabelDropdown,
} from "../../components/basic/index";

const useStyles = makeStyles((theme) => ({
	boxBorder: {
		border: `2px solid ${theme.palette.primary.main}`,
		borderRadius: theme.spacing(2),
		backgroundColor: "white",
	},
	screenTitle: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		letterSpacing: theme.spacing(0.2),
	},
}));

// const countries = options.reduce((acc, currentValue) => {
// 	acc[currentValue] = currentValue;
// 	return acc;
// }, {});

const grades1 = {
	aaa: "AAA",
	aaplus: "AA+",
	aa: "AA",
	aaminus: "AA-",
	aplus: "A+",
	a: "A",
	aminus: "A-",
	bbbplus: "BBB+",
	bbb: "BBB",
	bbbminus: "BBB-",
	bbplus: "BB+",
	bb: "BB",
	bbminus: "BB-",
	bplus: "B+",
	b: "B",
	bminus: "B-",
	cccplus: "CCC+",
	ccc: "CCC",
	cccminus: "CCC-",
	cc: "CC",
	c: "C",
	ddd: "RD/DDD",
	dd: "SD/DD",
	d: "D",
};

const grades2 = {
	aaa: "Aaa",
	aaplus: "Aa1",
	aa: "Aa2",
	aaminus: "Aa3",
	aplus: "A1",
	a: "A2",
	aminus: "A3",
	bbbplus: "Baa1",
	bbb: "Baa2",
	bbbminus: "Baa3",
	bbplus: "Ba1",
	bb: "Ba2",
	bbminus: "Ba3",
	bplus: "B1",
	b: "B2",
	bminus: "B3",
	cccplus: "Caa1",
	ccc: "Caa2",
	cccminus: "Caa3",
	cc: "Ca",
	c: "C",
};

const outlook = {
	positive: "POSITIVE",
	negative: "NEGATIVE",
	stable: "STABLE",
};

const MarketRisk = ({ disableAccess = true, isProjectDeveloper = false, onChangeProjectState }) => {
	const { projectid } = useParams();
	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(disableAccess);
	const [projectStateDialog, setProjectStateDialog] = useState(false);
	const [enableEditing, setEnableEditing] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [projectInfo, setProjectInfo] = useState({
		status: "",
		createdAt: "-",
		market_risk: {
			fitch_date: "",
			moodys_date: "",
			sp_date: "",
			contractor_country: "",
			client_country: "",
			contractor_fitch_current_rating: "",
			contractor_moodys_current_rating: "",
			contractor_sp_current_rating: "",
			contractor_fitch_outlook: "",
			contractor_moodys_outlook: "",
			contractor_sp_outlook: "",
			client_fitch_current_rating: "",
			client_moodys_current_rating: "",
			client_sp_current_rating: "",
			client_fitch_outlook: "",
			client_moodys_outlook: "",
			client_sp_outlook: "",
			fitch_outlook: "",
			moodys_outlook: "",
			sp_outlook: "",
			market_risk_client_countries: [],
		},
	});
	const { success, error } = useSnackbar();

	const classes = useStyles();

	// eslint-disable-next-line no-unused-vars
	const [selected, setSelected] = useState([]);
	const isAllSelected = options.length > 0 && selected.length === options.length;

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getProjectRiskAssessentProtocol(projectid);
				if (httpResponseIsValid(response)) {
					setProjectInfo(response);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured while fetching risk assessment market risk data: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, projectid],
	);

	const handleSubmit = async (values) => {
		setIsLoading(true);

		try {
			const response = await updateProjectRiskAssessentProtocol(projectid, values);

			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			error(`Error occured while submitting risk assessment market risk language: ${_error}`);
		}

		setIsLoading(false);
	};

	const changeProjectState = useCallback(
		async () => {
			await onChangeProjectState();
			fetchData();
		}, [fetchData, onChangeProjectState],
	);

	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: projectInfo,
		onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	});

	const { dirty, values } = formik;
	usePrompt("You have unsaved changes which will be lost. Are you sure you want to leave the page?", dirty);

	useEffect(() => {
		setShouldUiBeDisabled(disableAccess || !enableEditing);
	}, [disableAccess, enableEditing]);

	useEffect(() => {
		formik.resetForm({ values: projectInfo, dirty: false });
	}, [projectInfo]);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData, projectid]);
	// const { values, errors, touched, handleChange } = formik;

	return (
		<form onSubmit={formik.handleSubmit}>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<MainMenu
				title={t("RiskAssessmentOverview.marketRisk")}
				dirty={dirty}
				project={projectInfo}
				isDeveloper={isProjectDeveloper}
				disabled={disableAccess}
				onFetch={fetchData}
				onEdit={setEnableEditing}
				onChange={onChangeProjectState}
			/>
			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "105px" }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("RegulatoryRisk.relevance")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems="center">
					<Grid container item xs={12} md={6}>
						<Typography variant="h8" sx={{ textAlign: "justify", color: "blue.dark" }}>
							{t("MarketRisk.relevanceVar1")}
							{"\n"}
							{/* {infoText && (
							<Tooltip placement="bottom-start" title={infoText}>
								<InfoIcon fontSize="small" sx={{ padding: 0.2, color: "green.main" }} />
							</Tooltip>
						)} */}
						</Typography>
					</Grid>
					<Grid item md={1.8} />
					<Grid item xs={12} md={3} sm={9}>
						<FormControl className={classes.formControl}>
							<Select
								multiple
								sx={{ minWidth: "400px" }}
								labelId="mutiple-select-label"
								id="market_risk.market_risk_client_countries"
								value={getIn(values, "market_risk.market_risk_client_countries")}
								size="small"
								variant="filled"
								renderValue={(selected) => selected.join(", ")}
								disabled={shouldUiBeDisabled}
								onChange={(event) => {
									const { target: { value } } = event;
									formik.handleChange({
										target: {
											name: "market_risk.market_risk_client_countries",
											value,
										},
									});
									if (value.onChange) {
										value.onChange(event);
									}
								}}
							>
								<MenuItem
									value="all"
									classes={{
										root: isAllSelected ? classes.selectedAll : "",
									}}
								>
									<ListItemIcon>
										<Checkbox
											classes={{ indeterminate: classes.indeterminateColor }}
											indeterminate={
												selected.length > 0 && selected.length < options.length
											}
										/>
									</ListItemIcon>
									<ListItemText
										classes={{ primary: classes.selectAllText }}
										primary="Select All"
									/>
								</MenuItem>
								{options.map((option) => (
									<MenuItem key={option} value={option}>
										<ListItemIcon>
											<Checkbox checked={getIn(values, "market_risk.market_risk_client_countries").includes(option)} />
										</ListItemIcon>
										<ListItemText primary={option} />
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Typography variant="h8" sx={{ textAlign: "justify", whiteSpace: "pre-line", color: "blue.dark" }}>
						{"\n"}
						{t("MarketRisk.relevanceVar2")}
						{"\n"}
						<Typography variant="h8" sx={{ textAlign: "center", m: 3 }}>
							{"o  "}
							<Link href="https://www.fitchratings.com/" underline="always">
								{"Fitch"}
							</Link>
							{"\n"}
						</Typography>
						<Typography variant="h8" sx={{ textAlign: "center", m: 3 }}>
							{"o  "}
							<Link href="https://www.moodys.com/researchandratings/research-type/data-reports/ratings-list/00300A000/00300A000/-/-1/0/-/0/-/-/-/-/-/-/-/-/global/pdf/-/rra" underline="always">
								{"Moody's "}
							</Link>
							{"(requires login)"}
							{"\n"}
						</Typography>
						<Typography variant="h8" sx={{ textAlign: "center", m: 3 }}>
							{"o  "}
							<Link href="https://disclosure.spglobal.com/sri/" underline="always">
								{"S&P"}
							</Link>
							{"\n"}
						</Typography>
						{"\n"}
						{t("MarketRisk.relevanceVar3")}
						{"\n"}
						{"\n"}
					</Typography>
					<Grid container justify="center" sx={{ ml: 1 }}>
						<Grid item xs={1.8} align="center" sx={{ border: 1, borderBottom: 0, borderTop: 1, borderRight: 1, borderLeft: 1 }}>
							<Typography variant="h7" sx={{ whiteSpace: "pre-line", mb: 5 }}>
								{""}
							</Typography>
						</Grid>
						<Grid item xs align="center" sx={{ border: 1, borderLeft: 0, borderRight: 0, borderBottom: 0, mb: 1 }}>
							<Typography variant="h6" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
								<b>{"FITCH"}</b>
								{"\n"}
							</Typography>
							<LabelTextField
								id="market_risk.fitch_date"
								label={t("MarketRisk.date")}
								size="verysmall"
								typosize="h8"
								aligntitlebox="center"
								placeholder="mm/dd/yyyy"
								disabled={shouldUiBeDisabled}
								formik={formik}
							/>
						</Grid>
						<Grid item xs align="center" sx={{ border: 1, borderBottom: 0, borderLeft: 1, borderRight: 1, borderTop: 1 }}>
							<Typography variant="h6" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
								<b>{"MOODY'S"}</b>
								{"\n"}
							</Typography>
							<LabelTextField
								id="market_risk.moodys_date"
								label={t("MarketRisk.date")}
								size="verysmall"
								typosize="h8"
								aligntitlebox="center"
								placeholder="mm/dd/yyyy"
								disabled={shouldUiBeDisabled}
								formik={formik}
							/>
						</Grid>
						<Grid item xs align="center" sx={{ border: 1, borderLeft: 0, borderBottom: 0, borderTop: 1, borderRight: 1 }}>
							<Typography variant="h6" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
								<b>{"S&B"}</b>
								{"\n"}
							</Typography>
							<LabelTextField
								id="market_risk.sp_date"
								label={t("MarketRisk.date")}
								size="verysmall"
								typosize="h8"
								aligntitlebox="center"
								titleAlign="center"
								placeholder="mm/dd/yyyy"
								disabled={shouldUiBeDisabled}
								formik={formik}
							/>
						</Grid>
					</Grid>
					<Grid container justify="center" sx={{ ml: 1 }}>
						<Grid item xs={1.8} align="center" sx={{ border: 1, borderBottom: 0 }}>
							<Typography variant="h8" sx={{ whiteSpace: "pre-line", mb: 5 }}>
								{""}
							</Typography>
						</Grid>
						<Grid item xs align="center" sx={{ border: 1, borderLeft: 0, borderBottom: 0 }}>
							<Typography variant="h8" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
								{t("MarketRisk.currentRating")}
							</Typography>
						</Grid>
						<Grid item xs align="center" sx={{ border: 1, borderLeft: 0, borderBottom: 0 }}>
							<Typography variant="h8" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
								{t("MarketRisk.outlook")}
							</Typography>
						</Grid>
						<Grid item xs align="center" sx={{ border: 1, borderLeft: 0, borderBottom: 0 }}>
							<Typography variant="h8" sx={{ whiteSpace: "pre-line", mb: 5, color: "blue.dark" }}>
								{t("MarketRisk.currentRating")}
							</Typography>
						</Grid>
						<Grid item xs align="center" sx={{ border: 1, borderLeft: 0, borderBottom: 0 }}>
							<Typography variant="h8" sx={{ whiteSpace: "pre-line", mb: 5, color: "blue.dark" }}>
								{t("MarketRisk.outlook")}
							</Typography>
						</Grid>
						<Grid item xs align="center" sx={{ border: 1, borderLeft: 0, borderBottom: 0 }}>
							<Typography variant="h8" sx={{ whiteSpace: "pre-line", mb: 5, color: "blue.dark" }}>
								{t("MarketRisk.currentRating")}
							</Typography>
						</Grid>
						<Grid item xs align="center" sx={{ border: 1, borderLeft: 0, borderBottom: 0 }}>
							<Typography variant="h8" sx={{ whiteSpace: "pre-line", mb: 5, color: "blue.dark" }}>
								{t("MarketRisk.outlook")}
							</Typography>
						</Grid>
					</Grid>
					<Grid container justify="center" sx={{ ml: 1, mt: 0 }}>
						<Grid item xs={1.8} align="center" sx={{ border: 1, borderBottom: 0 }}>
							<Typography variant="h6" sx={{ whiteSpace: "pre-line", color: "blue.dark", mt: 3 }}>
								<b>{t("MarketRisk.contractor")}</b>
							</Typography>
						</Grid>
						<Grid item xs align="center" sx={{ border: 1, borderLeft: 0, borderBottom: 0 }}>
							<LabelDropdown
								id="market_risk.contractor_fitch_current_rating"
								size="empty"
								label=""
								typosize="h8"
								options={grades1}
								disabled={shouldUiBeDisabled}
								// infoText={t("LoanApplication.Overview.LoanOverview.info2")}
								formik={formik}
							/>
						</Grid>
						<Grid item xs align="center" sx={{ border: 1, borderLeft: 0, borderBottom: 0, borderRight: 0, mb: 1 }}>
							<LabelDropdown
								id="market_risk.contractor_fitch_outlook"
								size="empty"
								label=""
								typosize="h8"
								options={grades1}
								disabled={shouldUiBeDisabled}
								// infoText={t("LoanApplication.Overview.LoanOverview.info2")}
								formik={formik}
							/>
						</Grid>
						<Grid item xs align="center" sx={{ border: 1, borderLeft: 1, borderBottom: 0 }}>
							<LabelDropdown
								id="market_risk.contractor_moodys_current_rating"
								size="empty"
								label=""
								typosize="h8"
								options={grades2}
								disabled={shouldUiBeDisabled}
								// infoText={t("LoanApplication.Overview.LoanOverview.info2")}
								formik={formik}
							/>
						</Grid>
						<Grid item xs align="center" sx={{ border: 1, borderLeft: 0, borderBottom: 0 }}>
							<LabelDropdown
								id="market_risk.contractor_moodys_outlook"
								size="empty"
								label=""
								typosize="h8"
								options={grades2}
								disabled={shouldUiBeDisabled}
								// infoText={t("LoanApplication.Overview.LoanOverview.info2")}
								formik={formik}
							/>
						</Grid>
						<Grid item xs align="center" sx={{ border: 1, borderLeft: 0, borderBottom: 0 }}>
							<LabelDropdown
								id="market_risk.contractor_sp_current_rating"
								size="empty"
								label=""
								typosize="h8"
								options={grades1}
								disabled={shouldUiBeDisabled}
								// infoText={t("LoanApplication.Overview.LoanOverview.info2")}
								formik={formik}
							/>
						</Grid>
						<Grid item xs align="center" sx={{ border: 1, borderLeft: 0, borderBottom: 0 }}>
							<LabelDropdown
								id="market_risk.contractor_sp_outlook"
								size="empty"
								label=""
								typosize="h8"
								options={grades1}
								disabled={shouldUiBeDisabled}
								// infoText={t("LoanApplication.Overview.LoanOverview.info2")}
								formik={formik}
							/>
						</Grid>
					</Grid>
					<Grid container sx={{ ml: 1, mt: 0 }}>
						<Grid item xs={1.8} align="center" sx={{ border: 1, borderBottom: 0 }}>
							<Typography variant="h6" sx={{ whiteSpace: "pre-line", color: "blue.dark", mt: 3 }}>
								<b>{t("MarketRisk.client")}</b>
							</Typography>
						</Grid>
						<Grid item xs align="center" sx={{ border: 1, borderLeft: 0, borderBottom: 0, borderRight: 0, mb: 1 }}>
							<LabelDropdown
								id="market_risk.client_fitch_current_rating"
								size="empty"
								label=""
								typosize="h8"
								options={grades1}
								disabled={shouldUiBeDisabled}
								// infoText={t("LoanApplication.Overview.LoanOverview.info2")}
								formik={formik}
							/>
						</Grid>
						<Grid item xs align="center" sx={{ border: 1, borderLeft: 1, borderBottom: 0 }}>
							<LabelDropdown
								id="market_risk.client_fitch_outlook"
								size="empty"
								label=""
								typosize="h8"
								options={grades1}
								disabled={shouldUiBeDisabled}
								// infoText={t("LoanApplication.Overview.LoanOverview.info2")}
								formik={formik}
							/>
						</Grid>
						<Grid item xs align="center" sx={{ border: 1, borderLeft: 0, borderBottom: 0 }}>
							<LabelDropdown
								id="market_risk.client_moodys_current_rating"
								size="empty"
								label=""
								typosize="h8"
								options={grades2}
								disabled={shouldUiBeDisabled}
								// infoText={t("LoanApplication.Overview.LoanOverview.info2")}
								formik={formik}
							/>
						</Grid>
						<Grid item xs align="center" sx={{ border: 1, borderLeft: 0, borderBottom: 0 }}>
							<LabelDropdown
								id="market_risk.client_moodys_outlook"
								size="empty"
								label=""
								typosize="h8"
								options={grades2}
								disabled={shouldUiBeDisabled}
								// infoText={t("LoanApplication.Overview.LoanOverview.info2")}
								formik={formik}
							/>
						</Grid>
						<Grid item xs align="center" sx={{ border: 1, borderLeft: 0, borderBottom: 0 }}>
							<LabelDropdown
								id="market_risk.client_sp_current_rating"
								size="empty"
								label=""
								typosize="h8"
								options={grades1}
								disabled={shouldUiBeDisabled}
								// infoText={t("LoanApplication.Overview.LoanOverview.info2")}
								formik={formik}
							/>
						</Grid>
						<Grid item xs align="center" sx={{ border: 1, borderLeft: 0, borderBottom: 0 }}>
							<LabelDropdown
								id="market_risk.client_sp_outlook"
								size="empty"
								label=""
								typosize="h8"
								options={grades1}
								disabled={shouldUiBeDisabled}
								// infoText={t("LoanApplication.Overview.LoanOverview.info2")}
								formik={formik}
							/>
						</Grid>
					</Grid>
					<Grid container sx={{ ml: 1, mt: 0 }}>
						<Grid item xs={1.8} align="center" sx={{ border: 1, borderTop: 1 }}>
							<Typography variant="h6" sx={{ whiteSpace: "pre-line", mt: 3, color: "blue.dark", mb: 3 }}>
								<b>{"Market Outlook"}</b>
							</Typography>
						</Grid>
						<Grid item xs align="center" sx={{ border: 1, borderLeft: 0, borderTop: 1, borderRight: 0 }}>
							<LabelDropdown
								id="market_risk.fitch_outlook"
								size="empty"
								label=""
								typosize="h8"
								options={outlook}
								disabled={shouldUiBeDisabled}
								// infoText={t("LoanApplication.Overview.LoanOverview.info2")}
								formik={formik}
							/>
						</Grid>
						<Grid item xs align="center" sx={{ border: 1, borderLeft: 1, borderTop: 1 }}>
							<LabelDropdown
								id="market_risk.moodys_outlook"
								size="empty"
								label=""
								typosize="h8"
								options={outlook}
								disabled={shouldUiBeDisabled}
								// infoText={t("LoanApplication.Overview.LoanOverview.info2")}
								formik={formik}
							/>
						</Grid>
						<Grid item xs align="center" sx={{ border: 1, borderLeft: 0, borderTop: 1 }}>
							<LabelDropdown
								id="market_risk.sp_outlook"
								size="empty"
								label=""
								typosize="h8"
								options={outlook}
								disabled={shouldUiBeDisabled}
								// infoText={t("LoanApplication.Overview.LoanOverview.info2")}
								formik={formik}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<StatusDialog
				open={projectStateDialog}
				status={projectInfo.status}
				name={projectInfo.name}
				onClose={setProjectStateDialog}
				onChange={changeProjectState}
			/>
		</form>
	);
};

export default memo(MarketRisk);
