/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */

import { memo, useEffect, useState } from "react";
import { Link, Route, Routes, useLocation, useParams } from "react-router-dom";
import { Grid, Tab, Tabs, Typography, Box, List, ListItem, ListItemText, Drawer, Divider, ToggleButton, Link as LinkMui, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { UploadFile } from "@mui/icons-material";

import { jwt, useSnackbar } from "../../utils";

import CoursesAndTraining from "./CoursesAndTraining";
import QnA from "./QnA";
import ExpertContactForm from "./ExpertContactForm";

const { REACT_APP_MAIN_SERVER_URL } = process.env;

const useStyles = makeStyles((theme) => ({
	boxBorder: {
		border: `2px solid ${theme.palette.primary.main}`,
		borderRadius: theme.spacing(2),
		backgroundColor: "white",
		height: "48px",
	},
	screenTitle: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		letterSpacing: theme.spacing(0.2),
	},
}));

const onOpenLink = (link) => {
	window.open(link);
};

const ToolsAndResources = () => {
	const { pathname } = useLocation();
	const { companyid } = useParams();
	const [height, setHeight] = useState(100);
	const { error } = useSnackbar();

	const classes = useStyles();

	return (
		<>
			<Grid container direction="column" alignItems="center" py={1} px={3} sx={{ "> .MuiGrid-item": { p: 1 }, position: "fixed", maxWidth: "1300px", width: "calc(100% - 300px)", background: "white", zIndex: 999 }}>
				<Grid container item direction="row" alignItems="center" sx={{ "> .MuiGrid-item": { p: 0 }, background: "#F4F4F4", borderRadius: "10px 10px 10px 10px" }}>
					<Grid item xs={12} md={7}>
						<Typography variant="h5" className={classes.screenTitle}>{"Tools and Resources"}</Typography>
					</Grid>
					<Grid item xs={12} md={5} />
				</Grid>
			</Grid>
			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "45px" }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{"SALES STRATEGY"}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1, margin: "10px 20px 0px 20px" }} alignItems="center" justifyContent="space-between">
					<Grid item xs={12} md={4}>
						<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Company Presentation Template"}</Typography>
					</Grid>
					<Grid item container xs={12} md={8} justifyContent="end">
						<ToggleButton
							value="delete"
							title="delete"
							aria-label="delete"
							sx={{ borderColor: "primary.main" }}
							onClick={() => onOpenLink(`${REACT_APP_MAIN_SERVER_URL}/templates/CompanyPresentationTemplate.pdf?token=${jwt.getToken()}`)}
						>
							<UploadFile color="primary" />
						</ToggleButton>
					</Grid>
				</Grid>
			</Grid>
			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 } }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{"LOAN APPLICATION"}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1, margin: "10px 20px 0px 20px" }} alignItems="center" justifyContent="space-between">
					<Grid item xs={12} md={4}>
						<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>{"Company Presentation Template"}</Typography>
					</Grid>
					<Grid item container xs={12} md={8} justifyContent="end">
						<ToggleButton
							value="delete"
							title="delete"
							aria-label="delete"
							sx={{ borderColor: "primary.main" }}
							onClick={() => onOpenLink(`${REACT_APP_MAIN_SERVER_URL}/templates/CompanyPresentationTemplate.pdf?token=${jwt.getToken()}`)}
						>
							<UploadFile color="primary" />
						</ToggleButton>
					</Grid>
				</Grid>
			</Grid>
		</>

	);
};

export default memo(ToolsAndResources);
