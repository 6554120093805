
module.exports = {
	RiskAssessmentOverview: {
		riskAssessmentOverview: "Aperçu de l'Évaluation de Risques",
		riskTitle: "Intitulé du Risque",
		riskPresence: "Le Risque Est-Il Présent ? ",
		action: "Action",
		manage: "Gérer",
		creditRiskServiceProvider: "Risque De Crédit du Prestataire / Contractant",
		creditRiskImplementorClient: "Risque de crédit de l'Exécutant/Client Final ",
		regulatoryRisk: " Risque Réglementaire",
		marketRisk: "Risque du Marché",
		energyPriceRisk: "Risque d'Enérgie",
		currencyRisk: "Risque de Change",
		technicalRisk: "Risque Technique",
		performanceRisk: "Risque de Performance",
		omRisk: "Risque Lié aux Opérations et Maintenance",
		interfaceRisk: "Risque d'Interface",
		pipelineRisk: "Risque Lié au Pipeline",
		prepaymentRisk: "Risque de Payment Aniticipé",
		occupacyRisk: "Risque Lié à l'Occupation",
		managementRisk: "Risque Lié à la Gestion",
		changeInControlRisk: "Risuqe Lié au Changement de Contrôle",
		constructionRisk: "Risque Lié à la Construction",
		supplyChainRisk: "Risque de la chaîne d'approvisionnement",
	},
	CreditRiskServiceProvider: {
		riskTitle: "Risque De Crédit du Prestataire / Contractant",
		relevance: "PERTINENCE",
		formula: "Formule",
		year1: "Année 1",
		kpiYear1: "ICP année 1 ",
		year2: "Année 2",
		kpiYear2: "ICP année 2",
		year3: "Année 3",
		kpiYear3: "ICP année 3",
		liquidity: "Liquidité",
		currentRatio: "Ratio de Liquidité Générale",
		quickRatio: "Ratio de Liquidité Réduite",
		comparison: "Comparaison",
		solvency: "Solvabilité",
		solvencyRatio: "Ratio de Solvabilité",
		profitability: "Profitabilité",
		netProfitMargin: "Marge Nette",
		commercialProfitability: "Marge d’EBITDA",
		repaymentCapacityRatio: "La Capacité de Remboursement",
		supplyChainRatios: "Ratios de Chaîne d'Approvisionnement",
		workingCapital: "Fonds de Roulement",
		totalScore: "Score Total",
	},
	CreditRiskImplementorClient: {
		riskTitle: "Risque de crédit de l'Exécutant/Client Final ",
		relevance: "PERTINENCE",
		formula: "Formule",
		year1: "Année 1",
		kpiYear1: "ICP année 1 ",
		year2: "Année 2",
		kpiYear2: "ICP année 2",
		year3: "Année 3",
		kpiYear3: "ICP année 3",
		liquidity: "Liquidité",
		currentRatio: "Ratio de Liquidité Générale",
		quickRatio: "Ratio de Liquidité Réduite",
		comparison: "Comparaison ",
		solvency: "Solvabilité",
		solvencyRatio: "Ratio de Solvabilité",
		profitability: "Profitabilité",
		netProfitMargin: "Marge Nette",
		commercialProfitability: "Marge d’EBITDA",
		debtServiceCoverageRatio: "La Capacité de Remboursement ",
		supplyChainRatios: "Ratios de Chaîne d'Approvisionnement",
		workingCapital: "Fonds de Roulement",
		totalScore: "Score Total",
	},
	RegulatoryRisk: {
		relevance: "PERTINENCE",
		yes: "Oui",
		no:	"Non",
		relevanceVar1:	"Incitations",
		relevanceVar2:	"- Est -ce que le modèle de revenu prend en considération des incitations?",
		relevanceVar3:	"- Quelle est la nature des mesures d'incitations – subvention versée d’avance, incitation fiscale pour une période déterminée, tarifs d'achat, certificat d'économie d'énergie... ? ",
		relevanceVar4:	"- Quel est le pourcentage des revenus générés par les incitations par rapport au revenu total ?   ",
		relevanceVar5:	"Conformité",
		relevanceVar6:	"- Existe-il une réglementation de référence, en matière d'environnement et/ou de santé et de sécurité, régissant le projet ?  ",
		riskPresent:	"Le Risque Est-Il Présent ? ",
		responsibility:	"RESPONSABILITE",
		responsibilityVar1:	"- Qui est la partie bénéficiaire des mesures incitatives ? ",
		responsibilityVar2:	"-  Est-ce que cette mesure incitative peut être réassignée?",
		responsibilityVar3:	"- Y-a-t-il des restrictions qui s'appliquent à la réassignation de cette incitation ? ",
		responsibilityVar4:	"- Dans le cas où le revenu provenant de l'incitation est réassigné, indiquez les parts en % de chaque partie concernée.",
		responsibilityVar5:	"- Décrivez comment le client et/ou le contractant se conforme(nt) à toutes les réglementations appropriées en matière d'environnement et/ou de santé et de sécurité. ",
		riskBearer:	"Qui est le porteur du risque ?",
		documentation:	"DOCUMENTATION",
		documentationVar1:	"Veuillez lister tous les documents nécessaires pour appuyer les affirmations citées ci-dessus : ",
		documentationVar2:	"Les dispositions légales applicables aux mesures d'incitation et à leur éventuelle réassignation",
		documentationVar3:	"Les données historiques sur le montant des incitations accordées et les types de projets qui en ont bénéficié (source officielle))",
		documentationVar4:	"Dispositions légales ou réglementaires relatives au respect de l'environnement et/ou de la santé et de la sécurité. ",
	},
	MarketRisk: {
		relevanceVar1:	"- Dans quel(s) pays le client et le contractant opèrent-ils ?",
		relevanceVar2:	"- Quelle est la notation du marché actuelle et ses perspectives ? (connexion par compte nécessaire)",
		relevanceVar3:	"- Indiquez la date correspondante de la notation ",
		currentRating:	"Notation Actuelle",
		outlook:	"Perspectives",
		contractor:	"Contractant",
		client:	"Client",
		date:	"Date",

	},
	EnergyPriceRisk: {
		relevanceVar1:	"Est-ce que le modèle financier du projet prend en compte l'évolution des prix d’énergie ?",
		relevanceVar2:	"- Si oui, veuillez fournir les informations suivantes : ",
		relevanceVar3:	"- Comment le prix d’énergie est susceptible d'évoluer pendant la durée de l'investissement",
		relevanceVar4:	"- Indiquez l'évolution annuelle du prix de l'énergie en % au cours des 5 dernières années.",
		relevanceVar5:	"- Indiquez la variation du taux d'inflation sur le marché concerné, en %, au cours des 5 dernières années.",
		year:	"Année",
		energyPriceMovement:	"Evolution du prix d’énergie ",
		inflationRate:	"Taux d’inflation ",
		responsibilityVar1:	"- Qui supportera le risque lié aux écarts observés entre les prix réels observés sur le marché et la courbe des prix estimés d’énergie (manque à gagner, revenus excédentaires) ? ",
		mitigation: "ATTENUATION",
		mitigationVar1:	"- Est-ce-que le prestataire /contractant a déjà procédé à une couverture du risque lié au prix d'énergie (par exemple : l'achat/la vente de contrats à terme/ Futures sur l'énergie) ?  ",
		mitigationVar2:	"- Pour quelle durée ce risque est couvert ? Quel est le % de couverture en fonction de la valeur référentielle de la consommation mensuelle d’énergie ? ",
		fullyMitigated:	"Complétement atténué",
		documentationVar1:	"Fournir les informations sur les mouvements historiques des prix de l'énergie à partir de sources pertinentes (Eurostat).",
		documentationVar2:	"Fournir les informations sur l'historique du taux d'inflation à partir de sources pertinentes (BCE).",
		documentationVar3:	"Fournir les informations sur le potentiel instrument de couverture des prix d'énergie. ",

	},
	CurrencyRisk: {
		relevanceVar1:	"Le projet est-il régi par une seule devise ?",
		relevanceVar2:	"Dans l’affirmative, mentionnez la devise :",
		relevanceVar3:	"Si Non, veuillez répondre aux questions ci-dessous :  ",
		relevanceVar4:	"- En quelle devise les flux de trésorerie du projet sont-ils générés ? ",
		relevanceVar5:	"- En quelle devise l'équipement sera-t-il acheté ? ",
		relevanceVar6:	"- Y’ aurait-il une devise différente dédiée à une dépense opérationnelle importante prévue pendant la réalisation du projet ? Si oui, indiquez la devise : [devise] et indiquez la dépense opérationnelle : ",
		mitigationVar1:	"-  Est-ce-que le prestataire ou le contractant est couvert contre le risque de change (en achetant ou en vendant par exemple des contrats à terme / Futures sur devises) ? ",
		mitigationVar2:	"- Ce risque est couvert pour quelle durée et quel est le pourcentage de couverture en fonction des revenus et/ou en fonction des dépenses opérationnelles ? ",
		documentationVar1:	"Fournir les informations sur le potentiel instrument de couverture de change. ",

	},
	TechnicalRisk: {
		relevanceVar1:	"- La valeur totale de l'équipement installé : [montant]",
		relevanceVar2:	"- La durée de vie utile de l'équipement installé : [années] ",
		relevanceVar3:	"- La durée de l'accord avec l’exécutant/ client : [années] ",
		relevanceVar4:	"- Durée de vie utile> Durée de l'accord ?",
		relevanceVar5:	"- L’amortissement de l'équipement installé :",
		over: "sur, ",
		years: "ans.",
		straightLine:	"linéaire",
		accelerated:	"accéléré",
		relevanceVar6:	"- Les meilleures technologies disponibles qui doivent être appliquées - veuillez fournir ces informations :",
		relevanceVar7:	"Si le(s) produit(s) est/sont défini(s) part la réglementation sur l'écoconception",
		relevanceVar8:	"Une liste de référence comprenant au moins 3 projets mis en œuvre dans lesquels le(s) produit(s) a/ont été utilisé(s).",
		responsibilityVar1:	"D’après l'article 2.11 de l'accord de service standardisé LAUNCH le prestataire /contractant est tenu de souscrire à des assurances, et de les maintenir le long du projet, visant essentiellement à éliminer les risques techniques (voir ci-dessous)",
		mitigationVar1:	"Les assurances suivantes doivent être souscrite et maintenues : ",
		mitigationVar2:	"- Des garanties sur tous les équipements installés pour une durée égale ou supérieure à la durée du contrat de service et celle du contrat d'investissement ; ",
		mitigationVar3:	"- Une assurance du matériel, souscrite par le prestataire, pour une valeur qui ne soit pas inférieure à sa valeur de remplacement totale. Elle doit couvrir tous les risques perte, de dommage ou de destruction par incendie, vol ou accident ; ",
		mitigationVar4: "Une assurance, souscrite par le prestataire, à concurrence d’un montant de couverture qu’un propriétaire ou opérateur prudent des équipements souscrirait, ou à concurrence du montant dont le prestataire pourrait raisonnablement avoir besoin pour couvrir tout risque de tiers ou de responsabilité civile quelle que soit sa nature, et quelle que soit la façon dont cette responsabilité pourrait être mise en lien avec les équipements ; ",
		mitigationVar5:	"- Une assurance, souscrite par le prestataire, contre tous autres  risques ou supplémentaires qui concernent les équipements et qui pourrait être obligatoire en vertu de la loi, ainsi que toute autre assurance que le prestataire pourra éventuellement considérer comme raisonnablement nécessaire. ",
		mitigationVar6:	"-  Le prestataire/ contractant est tenu de payer tous les montants dues pour toutes réclamations stipulées selon les termes du contrat ",
		documentationVar1:	"La fiche technique des équipements installés en indiquant leur durée de vie utile ",
		documantationVar2: "Les garanties sur l'équipement installé ",
		documentationVar3:	"L’assurance de l'équipement (perte/dommage/vol) : inclure la politique d'assurance ",
		documentationVar4:	"L’assurance contre les risques de tiers (responsabilité civile) pour les équipements installés : inclure la politique d'assurance ",
		documentationVar5:	"Toute autre assurance exigée par la loi : inclure la politique d'assurance ",
		documentationVar6:	"Une liste des projets implémentés dans lesquels l'équipement a été utilisé (dans le cas où l'équipement n'est pas défini dans le règlement sur l'écoconception)",

	},
	PerformanceRisk: {
		relevanceVar1:	"Les facteurs pertinents liés à ce type de risque : ",
		relevanceVar2:	"1. La défaillance de l'audit énergétique",
		relevanceVar3:	"2. La faiblesse ou la défaillance de la conception du projet",
		relevanceVar4:	"3. La mauvaise implémentation des mesures énergétiques (EE).",
		relevanceVar5:	"4.  Le dysfonctionnement de l'équipement ",
		relevanceVar6:	"5. Les erreurs dans l'application des mesures ",
		relevanceVar7:	"1. maintenues/opérées par le prestataire/contractant. ",
		relevanceVar8:	"2. maintenues/opérées par l’abonné /client.",
		relevanceVar9:	"6. Un changement dans le mode d'utilisation, notamment le comportement des utilisateurs.",
		relevanceVar10:	"Le minimum d’économies d'énergie est prévu dans l'accord de service standardisé LAUNCH :",
		responsibilityVar1: "Selon l'accord de service standardisé LAUNCH, le prestataire garantit la réalisation les niveaux de service spécifiques pour toute la durée du contrat, sous réserve de la bonne exécution de toutes les obligations par l’exécutant/client. Par conséquent, une sous-exécution des mesures d'efficacité énergétique (EE) en fonction des facteurs (1 à 4, ainsi que sur le facteur 5a) susmentionnés sera couverte par le prestataire /contractant. Ce régime d'économies garanties (par opposition au régime d'économies partagées) incite davantage le prestataire /contractant à atteindre les niveaux économies d’énergie programmés.",
		responsibilityVar2:	"Le prestataire /contractant est tenu de fournir en plus un programme détaillé sur les activités mesure et de vérification (M&V), contenant : ",
		responsibilityVar3:	"- Des indications claires sur les normes utilisées pour les activités de M&V (IPMVP, ISO 50015). ",
		responsibilityVar4:	"-  Le programme des activités de M&V ",
		responsibilityVar5:	"-  Une annexe présentant les algorithmes utilisés dans les calculs",
		responsibilityVar6:	"-  Une liste de tous les responsables impliqués dans les protocoles de M&V",
		responsibilityVar7:	"En outre, la remise du dossier de projet doit inclure une analyse de sensibilité. Cette dernière, présente les niveaux de performance proposés avec +/- 10 % et +/- 20 % et décrit les incidences qui en découlent sur les flux de trésorerie du projet. ",
		mitigationVar1:	"- Est-ce- que le prestataire /contractant a souscrit une police d’assurance sur la performance ?",
		mitigationVar2:	"- Dans l’affirmative, quel est le pourcentage de la performance est assurée ? (en fonction du niveau de service garanti) ",
		documentationVar1:	"Un programme détaillé de M&V ",
		documentationVar2:	"Trois scénarios : défavorable, réaliste et optimal pour la variation du niveau de performance. ",
		documentationVar3:	"Une documentation sur l’assurance sur la performance, si disponible ",

	},
	OMRisk: {
		relevanceVar1:	"Puisque le prestataire /contractant (conformément à l'accord standardisé LAUNCH) conserve la propriété juridique et financière de l'équipement, on l'incite à l’utiliser soigneusement et à entretenir l'équipement tout au long de la période de service.",
		relevanceVar2:	"Néanmoins, le prestataire /contractant est tenu de fournir un programme détaillé d'O&M et de communiquer à l'appui tous les documents relatifs obtenus dans le cadre de projets similaires déjà réalisés et/ou en cours. ",
		responsibilityVar1:	"D’après l'accord de service standardisé LAUNCH, le prestataire de service/contractant est responsable de la réalisation de tous les O&M nécessaires pendant la période de service. Les informations suivantes doivent être fournies dans le programme d’O&M : :",
		responsibilityVar2:	"- Qui est le fournisseur d'O&M ? ",
		responsibilityVar3:	"- La qualité de crédit du fournisseur d'O&M (si elle diffère de celle du prestataire /contractant). ",
		responsibilityVar4:	"- L'historique de performance O&M du fournisseur.",
		responsibilityVar5:	"- Le protocole d'intervention (emergency) documenté et approuvé par les deux parties (annexé à l'accord standardisé LAUNCH).",
		responsibilityVar6:	"- Documented and mutually agreed upon response protocol (as a Schedule to the LAUNCH Standardized Agreement)",
		responsibilityVar7:	"- L'existence et la nature de tout plan d'O&M alternatif. ",
		responsibilityVar8:	"- L'accord de service normalisé LAUNCH comprend également une clause de substitution qui permet de remplacer le prestataire ou le contractant dans des conditions bien définies.",
		mitigationVar1:	"[Remarque: Les clauses contractuelles garantissant les niveaux de service d'O&M après la titrisation devraient être intégrées à l'accord de transfert] ",
		documentationVar1:	"Le programme détaillé d'O&M ",
		documentationVar2:	"Les références complètes et les expériences précédentes du fournisseur O&M désigné",
		documentationVar3:	"Les plans alternatifs d'O&M (s'il y en a)",

	},
	InterfaceRisk: {
		relevanceVar1:	"L'accord standard LAUNCH définit clairement les rôles et les responsabilités du prestataire /contractant et l’exécutant/client afin de minimiser les interférences et garantir le bon fonctionnement de l'équipement.",
		responsibilityVar1:	"Selon l'accord de Service Standardisé LAUNCH, l’abonné /client est obligé (notamment) de :",
		responsibilityVar2:	"- Maintenir l'équipement installé et son environnement propres et sécurisés.  ",
		responsibilityVar3:	"- Ne pas apporter des modifications à l'équipement. ",
		responsibilityVar4:	"- Garder l'équipement à l'endroit où il a été installé. ",
		responsibilityVar5:	"-  Permettre l'accès pour l'inspection et la maintenance à tout moment approprié.",
		mitigationVar1:	"[Remarque: l'accord de service standardisé LAUNCH comprend une clause relative aux coûts désinstallation en cas de violation ou de résiliation unilatérale du contrat avant son terme]. ",

	},
	PipelineRisk: {
		relevanceVar1:	"La taille du pipeline du projet par le prestataire /contractant avec les mêmes caractéristiques, en particulier : ",
		relevanceVar2:	"-  Une technologie similaire ",
		relevanceVar3:	"- Le même profil de clients (secteur, maturité) ",
		relevanceVar4:	"- Le même accord contractuel",
		relevanceVar5:	"-Les mêmes délais de projet",
		relevanceVar6:	"Contrat signé avec l'exécutant/client – Partie prête pour l'installation ",
		relevanceVar7:	"(Poursuite de l’accord préalable) Partie à mettre en œuvre dans une échéance moins de 6 mois  ",
		relevanceVar8:	"(Poursuite de l’accord préalable) Partie à mettre en oeuvre dans une échéance moins de 12 mois  ",
		relevanceVar9:	"Sélectionnez les devises pertinentes :",
		documentationVar1:	"Un document détaillé sur le pipeline de projets, énumérant toutes les opportunités pertinentes en suivant les 3 étapes décrites ci-dessus.",

	},
	PrePaymentRisk: {
		relevanceVar1:	"L'article 13 de l'accord standardisé LAUNCH prévoit un ensemble de motifs pour lesquelles la terminaison du contrat prend lieu avant les délais prédéfinis. ",
		relevanceVar2:	"Si l'exécution de tous les services est achevée avant le terme du contrat, il convient de vérifier/ évaluer les éléments suivants :  ",
		relevanceVar3:	"- Élément technique : l'équipement peut être désinstallé",
		relevanceVar4:	"- Élément économique : l'équipement peut être vendu à un prix raisonnable (et déplacé dans un nouveau site ou exploité par un nouvel utilisateur en gardant le site initial)",
		relevanceVar5:	"-  Élément juridique : la possibilité de transféré de propriété de l'équipement   ",
		responsibilityVar1:	"Remarque:  le risque de paiement anticipé sera un élément à traiter dans l'accord de transfert entre le prestataire /contractant et le véhicule de titrisation]",
		mitigationVar1:	"Remarque: l'accord standardisé LAUNCH prévoit une disposition relative aux 'coûts de désintallation' pour réduire le risque paiement en avance dans les cas suivants : Une résiliation anticipée, unilatérale et sans motif valable de l’accord ou  d'une violation de l'accord.] ",

	},
	OccupacyRisk: {
		relevanceVar1:	"-  Comment les valeurs de référence sur la consommation d'énergie sont ajustées en fonction de la variation du taux d'occupation d’énergie ?",
		relevanceVar2:	"-  Existe-t-il un paiement minimal défini. À défaut, pour quel pourcentage une baisse du taux d'occupation peut être considérée financièrement viable. ",
		relevanceVar3:	"- Existe-t-il une disposition contractuelle sur la mise hors service du dispositif installés dans le site d’activité ?  ",
		responsibilityVar1:	"Le prestataire /contractant est chargé d'ajuster régulièrement les valeurs de référence de la consommation d'énergie.   L'abonné/Client est tenu de communiquer en toute transparence et ponctuellement tout changement de niveau d'occupation. ",
		responsibilityVar2:	"Remarque: Dans quel stade le prestataire /le contractant n'a plus l'obligation de fournir des services",
		mitigationVar1:	"Remarque: Un paiement minimum, définit en % de la valeur de la souscription, est désormais stipulé dans l'accord standardisé LAUNCH ce qui permet de minimiser ce type de risque.",

	},
	ManagementRisk: {
		relevanceVar1:	"Pour garantir une bonne gouvernance des équipements installés, il convient de fournir les informations suivantes sur l'identité des clients (KYC) : ",
		relevanceVar2:	"- Le type d'entité",
		relevanceVar3:	"- Le secteur d'activité, y compris le code du secteur",
		relevanceVar4:	"- Le nombre d'employés ",
		relevanceVar5:	"  La structure de l'actionnariat ",
		relevanceVar6: " L'expérience et le parcours professionnel des principaux dirigeants",
		responsibilityVar1:	"Lors de la présentationdes données sur sa propre société, le prestataire /contractant doit soumettre conjointement les informations (KYC) sur l'abonné/client. ",
		documentationVar1:	" Le certificat d'immatriculation dans la chambre de commerce ",
		documentationVar2:	"Un document détaillant la structure de l'actionnariat ",
		documentationVar3:	"Les CV des principaux dirigeants",

	},
	ChangeInControlRisk: {
		relevanceVar1:	"Est-ce-que l'abonné/client est-il le propriétaire légal de l'immeuble où l'équipement sera installé ?",
		relevanceVar2:	"Si l’immeuble est loué, veuillez fournir des informations sur le bail et sa durée.  ",
		responsibilityVar1:	"L'accord standardisé LAUNCH comprendra une clause imposant l'une de ces obligations : ",
		responsibilityVar2:	"- Le maintien du contrat de service déjà établi dans les mêmes conditions pour le nouvel abonné/client ou",
		responsibilityVar3:	"- Le paiement du coût de désinstallation par l'ancien Abonné/Client pour le compte du tiers légalement désigné/ la contrepartie.",
		new:	"Nouveau",
		old:	"Ancien",
		mitigationVar1:	"Selon l'accord standardisé LAUNCH, ce type de risque serait complètement mitigé. ",
		documentationVar1:	"Les informations sur le contrat de location du site et sa durée",

	},
	ConstructionRisk: {
		relevanceVar1:	"- Est-ce que tous les permis de construction nécessaires sont obtenus pour permettre l'exécution dans les délais convenus ? ",
		relevanceVar2:	"- Quels sont les délais de livraison des équipements, et est-ce que ces derniers seront tous disponibles aux dates prévues ?",
		relevanceVar3:	"- Y a-t-il une interférence importante avec les occupants actuels de l’immeuble désigné ou avec les opérations qui y prennent lieu en ce moment ce qui, par conséquence, limite la durée des travaux de construction ou restreint ces travaux dans une période/ plage horaire spécifique ?",
		relevanceVar4:	"- Dans l'affirmative, quelles sont les répercussions du non-respect de ces limites ou restrictions ? ",
		relevanceVar5:	"- Est-ce qu’un avenant est autorisé ? Dans l'affirmative, jusqu'à quelle mesure et à quel stade de l'exécution ce changement peut avoir lieu ?",
		responsibilityVar1:	"- En sa qualité de partie responsable, le prestataire /contractant doit justifier par les documents appropriés : ",
		responsibilityVar2:	"-  Les détails de déroulement de la procédure de passation ainsi que toutes les étapes, les interférences et les passations entre les sous-traitants impliqués dans la mise en œuvre des travaux.",
		responsibilityVar3:	"- Un modèle détaillé du rapport de commissioning précisant toutes les contrôles requis à la fin des travaux, les contrôles de qualité, les contrôles de conformité et toutes les inspections à respecter pendant la phase de construction",
		mitigationVar1:	"Des mesures plus sophistiquées ou plus étendues, notamment dans les zones réservées à la production, aux activités de services, etc. Des mesures supplémentaires pour la prévention des retards peuvent être exigées, comme l'insertion d'une clause de dommages-intérêts (par exemple, pour une incapacité de prolonger la période d'utilisation de l'immeuble ou d'une partie de l'immeuble) ou la souscription à une police d'assurance couvrant les risques liés à la construction. ",
		documentationVar1:	"Procédure de passation",
		documentationVar2:	"Un modèle du rapport de commissioning",

	},
	SupplyChainRisk: {
		relevanceVar1:	"Existe-t-il au moins une partie (parmi le prestataire /contractant, O&M, et/ou fournisseur de technologie), qui dispose d'une chaîne d'approvisionnement établie sur un ou plusieurs sites autres que le sien et dépendant à au moins un autre tiers ? ",
		relevanceVar2: "Si oui, sélectionnez ces emplacements :",
		responsibilityVar1:	"Le prestataire /contractant est responsable de la gestion et de la traçabilité de sa propre chaîne d'approvisionnement, et également la chaîne d'approvisionnement de ses fournisseurs et celle de leurs fournisseurs respectifs (si utile). ",
		responsibilityVar2:	"- L'entreprise peut-elle déclarer qu'un pourcentage/une partie de son approvisionnement est faite par relocalisation* ou, par exemple, à travers des chaînes d’approvisionnement 'hybrides'** ? ",
		responsibilityVar3: "Si oui, fournissez un rapport :",
		responsibilityVar4:	"- Est-ce que les risques liés à la chaîne d'approvisionnement sont suivis par l'entreprise au cours de ses opérations (par exemple, par des indicateurs de résilience ou d'autres outils) ?  Est-ce que l'entreprise prépare/détient une cartographie du system d'approvisionnement ou bien a-t-elle une visibilité complète de sa chaîne d'approvisionnement ? ",
		responsibilityVar5: "Si oui, fournissez un rapport :",
		responsibilityVar6:	"La relocalisation - c'est le rapatriement des activités de production ou de fabrication auparavant exercées en dehors du pays d’origine de l'entreprise. Ainsi, la chaîne d'approvisionnement devient principalement basée dans le pays d'origine (par exemple, les skis Rossignol). ",
		responsibilityVar7:	"Des chaînes d'approvisionnement 'hybrides' : Des chaînes d'approvisionnement dans les quelles on combine la délocalisation et la relocalisation des activités de production (par exemple, des produits électroniques chinois produits en partie en Asie du Sud-Est).",
		mitigationVar1:	"Remarque: Les dispositions contractuelles avec le prestataire  ou le contractant pourraient protéger toutes les parties en cas de retard, manque de matériel ou un rappel de produits. Cette protection pourrait être à titre d’exemple des sommes (minimums et maximums) à payer dans les cas susmentionnés, et/ou une police d'assurance, une assurance responsabilité civile et des critères d'indemnisation applicables en cas de perte ou de dommage. ",
		mitigationVar2:	"Les mesures de prévention possibles pourraient être vérifiées sous forme de questions adressées à la (aux) partie(s) responsable(s), telles que : ",
		mitigationVar3:	"- Quelles sont les garanties/assurances disposées contre les goulots d'étranglement dans la chaîne d'approvisionnement ? Par exemple : l'entreprise dispose-t-elle d'une police d'assurance qui couvre le risque lié à la chaîne d'approvisionnement, notamment par la couverture des coûts associés au goulots d'étranglement ?",
		mitigationVar4: "Si oui, fournir une police d'assurance :",
		mitigationVar5:	"- Quel est le pourcentage de la partie délocalisé (offshore) de la chaîne d'approvisionnement de l'entreprise est par rapport à la partie locale ?  ",
		mitigationVar6:	"-  Le risque lié à la chaîne d'approvisionnement est-il réduit par des mécanismes ou des instruments de financement recourus par l'entreprise (par exemple, une police d'assurance ou autres instruments) ? ",
		mitigationVar7: "Si oui, décrivez ci-dessous :",
		mitigationVar8:	"- Est-ce que l'entreprise peut communiquer les mesures adoptées pour réduire le risque de coûts supplémentaires résultant des arrêts / retards / redondances qui se produisent durant les activités d’approvisionnement? ",
		mitigationVar9: "Si oui, fournissez un rapport :",
		mitigationVar10:	"- Est-ce que l'entreprise peut communiquer le nombre (ou %) maximum des produits/activités appartenant à sa chaîne d'approvisionnement qui ne sont pas conformes aux exigences de la taxonomie Européenne et/ou les mesures prises pour les réduire ce nombre (%) ? ",
		mitigationVar11: "Si oui, fournissez un rapport :",
		documentationVar1:	"La politique d’assurance",
		documentationVar2:	" Le rapport annuel comprenant le rapport d’audit",

	},
	Contract: {
		pageTitle: "Contrat",
		status: "Statut",
		created: "Créé",
		customContract: "Contrat de l'Utilisateur",
		uploadContract: "Télécharger le Contrat de l'Utilisateur",
		serviceProviderName: "Donomination Sociale Du Prestataire",
		serviceProviderNameText: "Le nom du fournisseur de services",
		directorName: "Nom du Directeur",
		directorNameText: "Le nom du directeur",
		countryOfRegistration: "Pays du Siége Social",
		countryOfRegistrationText: "Le pays d'immatriculation",
		companyNumber: "SIRET",
		companyNumberText: "Le numéro d'entreprise",
		registeredOfficeAddress: "Addresse du Siége Social",
		registeredOfficeAddressText: "L'adresse du siège social",
		registeredOfficePostcode: "Code Postal du Siége Social",
		registeredOfficePostcodeText: "Le code postal du siège social",
		registeredOfficeCity: "Ville du Siége Social",
		registeredOfficeCityText: "La ville du siège social",
		registeredOutputType: "Le type de sortie",
		currency: "Devise",
		outputType: "Type de Service",
		schedule1: "Annexe 1",
		schedule2: "Annexe 2",
		schedule3: "Annexe 3",
		schedule4: "Annexe 4",
		schedule5: "Annexe 5",
		schedule6: "Annexe 6",
		schedule7: "Annexe 7",
		sign: "Signer",
		save: "Enregistrer",
		clear: "Effacer",
		signedDocuments: "Documents Signés",
		contractSigned: "Contrat Signé",
		schedule1Signed: "Annexe 1 Signé",
		schedule2Signed: "Annexe 2 Signé",
		schedule3Signed: "Annexe 3 Signé",
		schedule4Signed: "Annexe 4 Signé",
		schedule5Signed: "Annexe 5 Signé",
		schedule6Signed: "Annexe 6 Signé",
		schedule7Signed: "Annexe 7 Signé",
		blankField: "Ce champ ne doit pas rester vide",
		blankFields: "Ces champs ne doivent pas être laissés vides",
		downloadTemplatePDF: "(Télécharger le modèle au format PDF)",
		downloadTemplateDOCX: "(Télécharger le modèle au format DOCX)",
		noUploadedFile: "Aucun fichier téléchargé",
		needFile: "Vous devez télécharger un fichier",
		areYouSure: "Êtes-vous sûr?",
		areYouSureDelete: "Êtes-vous sûr de vouloir supprimer le fichier?",
		delete: "Effacer",
		cancel: "Annuler",
		downloadFile: "Télécharger un fichier",
		signerEmailError: "Vous devez fournir une adresse e-mail valide",
		serviceSubscriberName: "Nom de l'abonné au service",
		serviceSubscriberEmail: "Courriel de l'abonné au service",
		serviceSubscriberDirectorName: "Nom du directeur de l'abonné au service",
		serviceSubscriberCountryOfRegistration: "Pays d'enregistrement de l'abonné au service",
		serviceSubscriberCompanyNumber: "Numéro d'entreprise de l'abonné au service",
		serviceSubscriberRegisteredOfficeAddress: "Adresse du siège social de l'abonné au service",
		serviceSubscriberRegisteredOfficePostcode: "Abonné au service Siège social Code postal",
		serviceSubscriberRegisteredOfficeCity: "Service Abonné Siège social Ville",
		serviceSubscriberNameText: "Le nom de l'abonné au service",
		serviceSubscriberEmailText: "L'e-mail du directeur de l'abonnement au service",
		serviceSubscriberDirectorNameText: "Le nom du directeur de l'abonnement au service",
		serviceSubscriberCountryOfRegistrationText: "Le pays d'enregistrement de l'abonné au service",
		serviceSubscriberCompanyNumberText: "Le numéro d'entreprise de l'abonné au service",
		serviceSubscriberRegisteredOfficeAddressText: "L'adresse du siège social de l'abonné au service",
		serviceSubscriberRegisteredOfficePostcodeText: "Le code postal du siège social de l'abonné au service",
		serviceSubscriberRegisteredOfficeCityText: "La ville du siège social de l'abonné au service",
		savedSuccessfully: "Informations enregistrées avec succès",
		professionalIndemnityInsuranceAmount: "Montant de l'assurance RC professionnelle",
		professionalIndemnityInsuranceAmountText: "Le montant de l'assurance responsabilité civile professionnelle",
		daysToPaySecurityDeposit: "# Jours pour que l'abonné paie le dépôt de garantie",
		daysToPaySecurityDepositText: "Le nombre de jours pour le dépôt de garantie à payer",
		businessHours: "Heures de travail",
		businessHoursTo: "à",
		interest: "Intérêt",
		interestOver: "plus de",
	},
};
