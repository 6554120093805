import { useCallback, memo, useMemo, useState, useEffect, forwardRef } from "react";
import { Button, Typography, Grid, LinearProgress, Box, TextField, Dialog, ToggleButtonGroup, ToggleButton, DialogTitle, DialogContent, DialogContentText, DialogActions, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { stringAToZInsensitive } from "@iamnapo/sort";
import * as Yup from "yup";

import { isFuzzyMatch, useSnackbar, httpResponseIsValid } from "../utils";
import Table from "../components/Table";
import { getAllUsers, getAllInvitations, acceptUser, declineUser, deleteUser, sendAdminUserInvitation, cancelAdminUserInvitation } from "../api";

const Transition = forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const useStyles = makeStyles((theme) => ({
	header: {
		color: theme.palette.green.main,
	},
	page: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
	textFieldStyle: {
		padding: "5px",
		width: "100%",
	},
	button: {
		backgroundColor: "white",
		height: "50px",
		width: "50%",
		"&:hover": {
			backgroundColor: "white",
		},
	},
	buttonDisabled: {
		backgroundColor: "#979797",
		height: "45px",
		width: "50%",
		"&:hover": {
			backgroundColor: "#A0ACB0",
		},
	},
	buttonTitle: {
		color: "#818C90",
		letterSpacing: theme.spacing(0.1),
	},
	buttonDisabledTitle: {
		color: "white",
		letterSpacing: theme.spacing(0.1),
	},
	toggles: {
		"& .Mui-selected": {
			color: "#ffffff !important",
			backgroundColor: "#6F96C6 !important",
		},
		"& .MuiToggleButton-root": {
			height: "40px",
			"&:hover": {
				color: "#1d4363",
				backgroundColor: "#F0F0F0",
			},
			color: "#1d4363",
			backgroundColor: "#ffffff",
			borderRadius: "10px",
		},
	},
}));

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const AdminUsers = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [sefaUsers, setSefaUsers] = useState([]);
	const [inviteUser, setInviteUser] = useState(false);
	const [userToInviteEmail, setUserToInviteEmail] = useState("");
	const [userToInviteEmailError, setUserToInviteEmailError] = useState("");
	const [userToInvite, setUserToInvite] = useState("owner");
	const [dialogType, setDialogType] = useState("");
	const [referencedUser, setReferencedUser] = useState("");

	const { error, success } = useSnackbar();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			let users = [];
			let response;
			try {
				response = await getAllUsers();
				if (httpResponseIsValid(response)) {
					users = [...users, ...response.users];
				} else {
					error(response?.message);
				}

				response = await getAllInvitations();
				if (httpResponseIsValid(response)) {
					users = [...users, ...response.invitations];
				} else {
					error(response?.message);
				}

				setSefaUsers(users);
			} catch (_error) {
				error(`Error occured while fetching SEFA users: ${_error}`);
			}

			setIsLoading(false);
		},
		[error],
	);

	const roleToUpperCase = (value) => {
		switch (value) {
			case "owner":
				return "Contractor Owner";
			case "member":
				return "Contractor Member";
			case "investor_member":
				return "Investor Member";
			case "investor_owner":
				return "Investor Owner";
			case "admin":
				return "Sefa Admin";
			default:
				return "Unknown";
		}
	};

	const onOpenDialog = (type, value) => {
		setReferencedUser(value);
		setDialogType(type);
	};

	const onDecideLater = () => {
		setDialogType("");
	};

	const onProceedAction = async () => {
		setIsLoading(true);

		let response;
		try {
			switch (dialogType) {
				case "accept":
					response = await acceptUser({ email: referencedUser });
					break;
				case "decline":
					response = await declineUser({ email: referencedUser });
					break;
				case "remove":
					response = await deleteUser({ email: referencedUser });
					break;
				default: {
					break;
				}
			}

			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			error(`Error occured while resending the user invitation: ${_error}`);
		}

		setDialogType("");
		setIsLoading(false);
	};

	const onOpenInviteUserDialog = () => {
		setUserToInviteEmail("");
		setUserToInvite("owner");
		setInviteUser(true);
	};

	const resendUserInvitation = useCallback(
		async (userEmail) => {
			setIsLoading(true);

			try {
				const validationSchema = Yup.object().shape({
					email: Yup.string().trim().email().required(),
				});

				const emailValid = await validationSchema.isValid({ email: userEmail });

				if (!emailValid) {
					setUserToInviteEmailError("Invalid email format.");
					setIsLoading(false);

					return;
				}

				const response = await sendAdminUserInvitation({
					email: userEmail,
					role: userToInvite,
				});

				if (httpResponseIsValid(response)) {
					success(response?.message);
					await fetchData();
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured while re-sending the user invitation: ${_error}`);
			}

			setIsLoading(false);
		}, [error, success, fetchData, userToInvite],
	);

	const cnacelUserInvitation = useCallback(
		async (userEmail) => {
			setIsLoading(true);

			try {
				const validationSchema = Yup.object().shape({
					email: Yup.string().trim().email().required(),
				});

				const emailValid = await validationSchema.isValid({ email: userEmail });

				if (!emailValid) {
					error("Invalid email format.");
				}

				const response = await cancelAdminUserInvitation({ email: userEmail });

				if (httpResponseIsValid(response)) {
					success(response?.message);
					await fetchData();
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured while canceling the user invitation: ${_error}`);
			}

			setIsLoading(false);
		}, [error, success, fetchData],
	);

	const onInviteUser = async () => {
		setIsLoading(true);

		try {
			const validationSchema = Yup.object().shape({
				email: Yup.string().trim().email().required(),
			});

			const emailValid = await validationSchema.isValid({ email: userToInviteEmail });

			if (!emailValid) {
				setUserToInviteEmailError("Invalid email format.");
				setIsLoading(false);

				return;
			}

			const response = await sendAdminUserInvitation({
				email: userToInviteEmail,
				role: userToInvite,
			});

			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			error(`Error occured while sending the user invitation: ${_error}`);
		}

		setInviteUser(false);
		setIsLoading(false);
	};

	const renderButtons = useCallback(
		(value) => {
			const user = sefaUsers.find((u) => u.email === value);

			switch (user.status) {
				case "active":
					return (
						<Grid container direction="row" justifyContent="space-around" alignItems="center">
							<Button
								variant="outlined"
								color="error"
								onClick={() => onOpenDialog("remove", value)}
							>
								{"Remove User"}
							</Button>
						</Grid>
					);
				case "pending":
					return (
						<Grid container direction="row" justifyContent="space-around" alignItems="center">
							<Button
								variant="contained"
								sx={{ borderColor: "primary.main" }}
								onClick={() => onOpenDialog("accept", value)}
							>
								{"Accept User"}
							</Button>
							<Button
								variant="outlined"
								color="error"
								onClick={() => onOpenDialog("decline", value)}
							>
								{"Decline User"}
							</Button>
						</Grid>
					);
				case "invited":
					return (
						<Grid container direction="row" justifyContent="space-around" alignItems="center">
							<Button
								variant="contained"
								sx={{ borderColor: "primary.main" }}
								onClick={() => resendUserInvitation(user.email)}
							>
								{"Resend Email"}
							</Button>
							<Button
								variant="outlined"
								color="error"
								onClick={() => cnacelUserInvitation(user.email)}
							>
								{"Cancel Invitation"}
							</Button>
						</Grid>
					);
				default:
					return (
						<Grid container direction="row" justifyContent="space-around" alignItems="center">
							{"No actions available"}
						</Grid>
					);
			}
		}, [sefaUsers, resendUserInvitation],
	);

	const classes = useStyles();

	const tableColumns = useMemo(() => [
		{
			Header: <Typography id="name_header" variant="h6">{"User"}</Typography>,
			accessor: "email",
			id: "email",
			filterable: true,
			minWidth: 200,
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{value}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Role"}</Typography>,
			accessor: "role",
			id: "role",
			filterable: true,
			width: 180,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{roleToUpperCase(value)}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Status"}</Typography>,
			accessor: "status",
			id: "status",
			minWidth: 100,
			maxWidth: 250,
			style: { overflow: "visible" },
			// eslint-disable-next-line react/prop-types
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography>{capitalize(value)}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h6">{"Actions"}</Typography>,
			accessor: "email",
			filterable: false,
			sortable: false,
			minWidth: 200,
			maxWidth: 330,
			Cell: ({ value }) => renderButtons(value),
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
	], [renderButtons, sefaUsers]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		const id = setInterval(() => (
			fetchData()
		), 15_000);

		return () => clearInterval(id);
	}, []);

	return (
		<>
			{isLoading && (<LinearProgress color="primary" />)}
			<section style={{ paddingTop: "3rem" }}>
				<div className={clsx("container", classes.page)}>
					<Grid container direction="row" justifyContent="space-between" alignItems="center" mb={2}>
						<Grid item hidden />
						<Grid item>
							<Typography variant="h5" display="flex" alignItems="center" className={classes.header}>
								{"SEFA Platform Users"}
							</Typography>
						</Grid>
						<Grid item>
							<Button
								variant="contained"
								size="large"
								sx={{
									":hover": { color: "common.white" },
									px: 5,
									py: 1,
									color: "common.white",
									width: "300px",
									height: "50px",
								}}
								onClick={onOpenInviteUserDialog}
							>
								{"Invite user to SEFA"}
							</Button>
						</Grid>
					</Grid>
					<Table
						data={sefaUsers}
						noDataText={isLoading ? "Fetching projects..." : "No data available!"}
						columns={tableColumns}
						defaultSorted={[{ id: "name", desc: true }]}
					/>
				</div>
			</section>
			{setDialogType !== ""
				&& (
					<Dialog
						keepMounted
						maxWidth="md"
						open={dialogType !== ""}
						TransitionComponent={Transition}
						onClose={onDecideLater}
					>
						<DialogTitle>
							{"Are you sure?"}
						</DialogTitle>
						<DialogContent dividers>
							{dialogType === "accept"
								&& (
									<DialogContentText>
										{"Are you sure you want to accept the user "}
										<b>{referencedUser}</b>
										{" in SEFA ?"}
									</DialogContentText>
								)}
							{dialogType === "decline"
								&& (
									<DialogContentText>
										{"Are you sure you want to decline "}
										<b>{referencedUser}</b>
										{" entrance in SEFA ?"}
									</DialogContentText>
								)}
							{dialogType === "remove"
								&& (
									<DialogContentText>
										{"Are you sure you want to remove "}
										<b>{referencedUser}</b>
										{" user from SEFA ?"}
									</DialogContentText>
								)}
						</DialogContent>
						<DialogActions>
							<Button
								autoFocus={!dialogType === "accept"}
								variant={dialogType === "accept" ? "outlined" : "contained"}
								onClick={onDecideLater}
							>
								{"Decide later"}
							</Button>
							{dialogType === "accept"
								&& (
									<Button
										autoFocus
										variant="contained"
										color="success"
										onClick={onProceedAction}
									>
										{"Accept"}
									</Button>
								)}
							{dialogType === "decline"
								&& (
									<Button
										autoFocus={false}
										variant="outlined"
										color="error"
										onClick={onProceedAction}
									>
										{"Decline"}
									</Button>
								)}
							{dialogType === "remove"
								&& (
									<Button
										autoFocus={false}
										variant="outlined"
										color="error"
										onClick={onProceedAction}
									>
										{"Remove"}
									</Button>
								)}
						</DialogActions>
					</Dialog>
				)}
			{inviteUser
				&& (
					<Dialog
						keepMounted
						open={inviteUser}
						TransitionComponent={Transition}
						scroll="body"
						maxWidth="md"
						onClose={() => setInviteUser(false)}
					>
						<DialogTitle>
							<Typography variant="h5" sx={{ p: 1 }}>
								<b>{"Invite new user to SEFA"}</b>
							</Typography>
						</DialogTitle>
						<DialogContent dividers style={{ overflow: "hidden" }}>
							<Grid item container margin="5px" sx={{ p: 1 }} alignItems="center">
								<Grid item xs={12} md={4} p={1}>
									<Typography variant="h6" sx={{ p: 1 }}>
										{"User email"}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={5} md={8} direction="row" alignItems="center">
									<TextField
										hiddenLabel
										className={classes.textFieldStyle}
										size="large"
										value={userToInviteEmail}
										variant="filled"
										error={userToInviteEmailError !== ""}
										helperText={userToInviteEmailError === "" ? "" : userToInviteEmailError}
										onChange={(e) => { setUserToInviteEmail(e.target.value); setUserToInviteEmailError(""); }}
									/>
								</Grid>
								<Grid item xs={12} md={4} p={1}>
									<Typography variant="h6" sx={{ p: 1, width: "220px" }}>
										{"User type"}
									</Typography>
								</Grid>
								<Grid item container xs={12} sm={5} md={8} sx={{ padding: "5px" }} direction="row" alignItems="center">
									<ToggleButtonGroup
										exclusive
										color="primary"
										value={userToInvite}
										aria-label="Platform"
										className={classes.toggles}
										onChange={(e) => setUserToInvite(e.target.value)}
									>
										<ToggleButton sx={{ width: "183px" }} value="owner">{"Contractor"}</ToggleButton>
										<ToggleButton sx={{ width: "182px" }} value="investor_owner">{"Investor"}</ToggleButton>
										<ToggleButton sx={{ width: "183px" }} value="admin">{"Admin"}</ToggleButton>
									</ToggleButtonGroup>
								</Grid>
							</Grid>
							<Grid item container direction="row" sx={{ p: 1 }} justifyContent="space-around">
								<Button
									autoFocus
									size="large"
									variant="contained"
									sx={{
										width: "45%",
										":hover": { color: "common.white" },
										px: 5,
										py: 1,
										color: "common.white",
									}}
									color="green"
									onClick={() => setInviteUser(false)}
								>
									<Typography variant="h6" sx={{ p: 1 }}>
										{"Cancel"}
									</Typography>
								</Button>
								<Button
									autoFocus
									size="large"
									variant="contained"
									sx={{
										width: "45%",
									}}
									onClick={onInviteUser}
								>
									<Typography variant="h6" sx={{ p: 1 }}>
										{"Invite"}
									</Typography>
								</Button>
							</Grid>
						</DialogContent>
					</Dialog>
				)}
		</>
	);
};

export default memo(AdminUsers);
