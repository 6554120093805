import { memo, useCallback, useEffect, useState, forwardRef } from "react";
import { useParams } from "react-router-dom";
import { Button, Grid, Select, LinearProgress, Typography, Dialog, Slide, ListItemIcon, ListItemText, Checkbox, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, MenuItem } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useFormik, getIn } from "formik";
import { makeStyles } from "@mui/styles";

import { options } from "../../utils/options";
import { useSnackbar, httpResponseIsValid } from "../../utils";
import { deleteFile, getProjectRiskAssessentProtocol, updateProjectRiskAssessentProtocol } from "../../api";
import usePrompt from "../../utils/use-blocker";
import StatusDialog from "../../components/StatusDialog";
import {
	MainMenu,
	LabelTextField,
	LabelRadio,
	LabelRadioTextField,
	LabelFileUpload,
	LabelRadioFileUpload,
} from "../../components/basic/index";

const useStyles = makeStyles((theme) => ({
	boxBorder: {
		border: `2px solid ${theme.palette.primary.main}`,
		borderRadius: theme.spacing(2),
		backgroundColor: "white",
	},
	screenTitle: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		letterSpacing: theme.spacing(0.2),
	},
}));

// const countries = options.reduce((acc, currentValue) => {
// 	acc[currentValue] = currentValue;
// 	return acc;
// }, {});

const Transition = forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const SupplyChainRisk = ({ disableAccess = true, isProjectDeveloper = false, onChangeProjectState }) => {
	const { projectid } = useParams();
	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(disableAccess);
	const [projectStateDialog, setProjectStateDialog] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [project, setProject] = useState({
		status: "-",
		createdAt: "-",
		supply_chain_risk_doc_1: {
			original_name: "",
			save_name: "",
		},
		supply_chain_risk_doc_2: {
			original_name: "",
			save_name: "",
		},
		supply_chain_risk_doc_4: {
			original_name: "",
			save_name: "",
		},
		supply_chain_risk_doc_3: {
			original_name: "",
			save_name: "",
		},
		supply_chain_risk_doc_5: {
			original_name: "",
			save_name: "",
		},
		supply_chain_risk_doc_6: {
			original_name: "",
			save_name: "",
		},
		supply_chain_risk_doc_7: {
			original_name: "",
			save_name: "",
		},
		supply_chain_risk: {
			supply_chain_risk_relevance: "",
			supply_chain_risk_minimum_source: "",
			supply_chain_risk_supply_track: "",
			supply_chain_risk_mitigation_measures: "",
			supply_chain_risk_non_eu_taxonomy: "",
			supply_chain_risk_responsibility: "",
			supply_chain_risk_mitigation: "",
			supply_chain_risk_financial_mechanism: "",
			supply_chain_risk_insurance_bottlenecks: "",
			supplychain_in_off_shore_percentage: "",
			supply_chain_risk_mechanism_description: "",
			supply_chain_risk_contracting_parties_locations: [],
		},
	});
	const [enableEditing, setEnableEditing] = useState(false);
	const { success, error } = useSnackbar();
	const [deleteModal, setDeleteModal] = useState({ component: null, variable: null });
	const classes = useStyles();

	// const isAllSelected = options.length > 0 && selected.length === options.length;
	const [selected, setSelected] = useState([]);
	const isAllSelected = options.length > 0 && selected.length === options.length;

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getProjectRiskAssessentProtocol(projectid);
				if (httpResponseIsValid(response)) {
					setProject(response);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured while fetching risk assessment regulatory risk data: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, projectid],
	);

	const changeProjectState = useCallback(
		async () => {
			await onChangeProjectState();
			fetchData();
		}, [fetchData, onChangeProjectState],
	);

	const handleSubmit = async (values) => {
		setIsLoading(true);

		try {
			const response = await updateProjectRiskAssessentProtocol(projectid, values);
			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			error(`Error occured while submitting risk assessment regulatory risk data: ${_error}`);
		}

		setIsLoading(false);
	};

	const onDelete = async (comp) => {
		try {
			setIsLoading(true);
			const { success: deleteSuccess } = await deleteFile({ projectid, component: comp.component, variable: comp.variable });
			console.log(deleteSuccess);
			if (deleteSuccess) {
				success("File deleted successfully");
				await fetchData(false);
				setIsLoading(false);
			} else {
				error();
				setIsLoading(false);
			}
		} catch { /* empty */ }
	};

	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: project,
		onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	});

	const { dirty, values } = formik;
	usePrompt("You have unsaved changes which will be lost. Are you sure you want to leave the page?", dirty);

	useEffect(() => {
		setShouldUiBeDisabled(disableAccess || !enableEditing);
	}, [disableAccess, enableEditing]);

	useEffect(() => {
		formik.resetForm({ values: project, dirty: false });
	}, [project]);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData, projectid]);

	return (
		<form onSubmit={formik.handleSubmit}>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<MainMenu
				title={t("RiskAssessmentOverview.supplyChainRisk")}
				dirty={dirty}
				project={project}
				isDeveloper={isProjectDeveloper}
				disabled={disableAccess}
				onFetch={fetchData}
				onEdit={setEnableEditing}
				onChange={onChangeProjectState}
			/>
			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "105px" }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("RegulatoryRisk.relevance")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} alignItems="top">
					<Grid item container xs={12} md={9} sm={8} p={1}>
						<Typography variant="h8" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
							{t("SupplyChainRisk.relevanceVar1")}
							{"\n\n"}
						</Typography>
						<Typography variant="h8" sx={{ textAlign: "justify", color: "blue.dark", m: 1 }}>
							{t("SupplyChainRisk.relevanceVar2")}
							{"\n"}
						</Typography>
						<Grid item md={3.3} />
						<Grid item container xs={12} md={3} sm={9}>

							<FormControl className={classes.formControl}>
								<Select
									multiple
									sx={{ minWidth: "400px" }}
									labelId="mutiple-select-label"
									id="supply_chain_risk.supply_chain_risk_contracting_parties_locations"
									value={getIn(values, "supply_chain_risk.supply_chain_risk_contracting_parties_locations")}
									size="small"
									variant="filled"
									// eslint-disable-next-line no-shadow
									renderValue={(selected) => selected.join(", ")}
									disabled={shouldUiBeDisabled}
									onChange={(event) => {
										const { target: { value } } = event;
										formik.handleChange({
											target: {
												name: "supply_chain_risk.supply_chain_risk_contracting_parties_locations",
												value,
											},
										});
										if (value.onChange) {
											value.onChange(event);
										}
									}}
								>
									<MenuItem
										value="all"
										classes={{
											root: isAllSelected ? classes.selectedAll : "",
										}}
									>
										<ListItemIcon>
											<Checkbox
												classes={{ indeterminate: classes.indeterminateColor }}
												indeterminate={
													selected.length > 0 && selected.length < options.length
												}
											/>
										</ListItemIcon>
										<ListItemText
											classes={{ primary: classes.selectAllText }}
											primary="Select All"
										/>
									</MenuItem>
									{options.map((option) => (
										<MenuItem key={option} value={option}>
											<ListItemIcon>
												<Checkbox checked={getIn(values, "supply_chain_risk.supply_chain_risk_contracting_parties_locations").includes(option)} />
											</ListItemIcon>
											<ListItemText primary={option} />
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
					<Grid item md={0.2} />
					<Grid item xs={12} sm={4} md={2} direction="row" alignItems="center">
						<LabelRadio
							id="supply_chain_risk.supply_chain_risk_relevance"
							label={t("RegulatoryRisk.riskPresent")}
							size="flat"
							options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
					</Grid>
				</Grid>

				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("RegulatoryRisk.responsibility")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="top">
					<Grid item xs={12} md={9} sm={8} p={1}>
						<Typography variant="h6" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
							<b>{t("RegulatoryRisk.relevanceVar1")}</b>
							{"\n"}
						</Typography>
						<LabelRadioFileUpload
							radioId="supply_chain_risk.supply_chain_risk_minimum_source"
							fieldId="supply_chain_risk_doc_1"
							projectId={projectid}
							label={t("SupplyChainRisk.responsibilityVar2")}
							size="flat"
							typosize="h8"
							disabled={shouldUiBeDisabled}
							formik={formik}
							fileTool="riskAssessmentProtocol"
							onSuccess={() => fetchData()}
						/>
						<LabelRadioFileUpload
							radioId="supply_chain_risk.supply_chain_risk_supply_track"
							fieldId="supply_chain_risk_doc_2"
							projectId={projectid}
							label={t("SupplyChainRisk.responsibilityVar4")}
							size="flat"
							typosize="h8"
							disabled={shouldUiBeDisabled}
							formik={formik}
							fileTool="riskAssessmentProtocol"
							onSuccess={() => fetchData()}
						/>
						<Typography variant="h7" sx={{ whiteSpace: "pre-line", color: "blue.dark" }}>
							{"\n"}
							{t("SupplyChainRisk.responsibilityVar6")}
							{"\n"}
							{t("SupplyChainRisk.responsibilityVar7")}
						</Typography>
					</Grid>
					<Grid item md={0.2} />
					<Grid item xs={12} sm={4} md={2} direction="row" alignItems="center">
						<LabelRadio
							id="supply_chain_risk.supply_chain_risk_responsibility"
							label={t("RegulatoryRisk.riskBearer")}
							size="large"
							options={[{ value: "false", label: "Client" }, { value: "true", label: "Contractor" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
					</Grid>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("EnergyPriceRisk.mitigation")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} alignItems="top">
					<Grid item xs={12} md={9} sm={8} p={1}>

						<Typography variant="h8" sx={{ whiteSpace: "pre-line", color: "blue.dark"}}>
							{t("SupplyChainRisk.mitigationVar1")}
							{"\n\n"}
							{t("SupplyChainRisk.mitigationVar2")}
							{"\n\n"}
						</Typography>
						<LabelRadioFileUpload
							radioId="supply_chain_risk.supply_chain_risk_insurance_bottlenecks"
							fieldId="supply_chain_risk_doc_3"
							projectId={projectid}
							label={t("SupplyChainRisk.mitigationVar3")}
							size="flat"
							typosize="h8"
							disabled={shouldUiBeDisabled}
							formik={formik}
							fileTool="riskAssessmentProtocol"
							onSuccess={() => fetchData()}
						/>
						<LabelTextField
							id="supply_chain_risk.supplychain_in_off_shore_percentage"
							label={t("SupplyChainRisk.mitigationVar5")}
							size="flat"
							aligntitlebox="center"
							typosize="h8"
							placeholder="Answer(%)"
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
						<LabelRadioTextField
							radioId="supply_chain_risk.supply_chain_risk_financial_mechanism"
							fieldId="supply_chain_risk.supply_chain_risk_mechanism_description"
							label={t("SupplyChainRisk.mitigationVar6")}
							placeholder="Answer"
							size="flat"
							typosize="h8"
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
						<LabelRadioFileUpload
							radioId="supply_chain_risk.supply_chain_risk_mitigation_measures"
							fieldId="supply_chain_risk_doc_4"
							projectId={projectid}
							label={t("SupplyChainRisk.mitigationVar8")}
							size="flat"
							typosize="h8"
							disabled={shouldUiBeDisabled}
							formik={formik}
							fileTool="riskAssessmentProtocol"
							onSuccess={() => fetchData()}
						/>
						<LabelRadioFileUpload
							radioId="supply_chain_risk.supply_chain_risk_non_eu_taxonomy"
							fieldId="supply_chain_risk_doc_5"
							projectId={projectid}
							label={t("SupplyChainRisk.mitigationVar10")}
							size="flat"
							typosize="h8"
							disabled={shouldUiBeDisabled}
							formik={formik}
							fileTool="riskAssessmentProtocol"
							onSuccess={() => fetchData()}
						/>
					</Grid>
					<Grid item md={0.2} />
					<Grid item xs={12} sm={4} md={2} direction="row" alignItems="center">
						<LabelRadio
							id="supply_chain_risk.supply_chain_risk_mitigation"
							label={t("EnergyPriceRisk.fullyMitigated")}
							size="large"
							options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
					</Grid>
				</Grid>

			</Grid>
			<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
				<Typography variant="h6" color="common.white" alignItems="center">
					{t("RegulatoryRisk.documentation")}
				</Typography>
			</Grid>
			<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="center">
				<Grid item xs={12} md={9} p={1}>
					<Typography variant="h8" alignItems="center" color="blue.dark">
						<b>{t("RegulatoryRisk.documentationVar1")}</b>
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} alignItems="center">
					<LabelFileUpload
						fieldId="supply_chain_risk_doc_6"
						projectId={projectid}
						label={t("SupplyChainRisk.documentationVar1")}
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="riskAssessmentProtocol"
						onSuccess={() => fetchData()}
					/>
				</Grid>
				<Grid item container sx={{ p: 1 }} alignItems="center">
					<LabelFileUpload
						fieldId="supply_chain_risk_doc_7"
						projectId={projectid}
						label={t("SupplyChainRisk.documentationVar2")}
						size="large"
						typosize="h8"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="riskAssessmentProtocol"
						onSuccess={() => fetchData()}
					/>
				</Grid>
			</Grid>
			{deleteModal.component !== null
				&& deleteModal.variable !== null
				&& (
					<Dialog
						keepMounted
						open={deleteModal.component !== null && deleteModal.variable !== null}
						TransitionComponent={Transition}
						onClose={() => setDeleteModal({ component: null, variable: null })}
					>
						<DialogTitle>
							{"Are you sure?"}
						</DialogTitle>
						<DialogContent dividers>
							<DialogContentText>
								{"Are you sure you want to delete the file?"}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								autoFocus
								startIcon={<Delete />}
								variant="contained"
								onClick={() => {
									onDelete(deleteModal);
									setDeleteModal({ component: null, variable: null });
								}}
							>
								{"Delete"}
							</Button>
							<Button variant="outlined" onClick={() => setDeleteModal({ component: null, variable: null })}>{"Cancel"}</Button>
						</DialogActions>
					</Dialog>
				)}
			<StatusDialog
				open={projectStateDialog}
				status={project.status}
				name={project.name}
				onClose={setProjectStateDialog}
				onChange={changeProjectState}
			/>
		</form>
	);
};

export default memo(SupplyChainRisk);
