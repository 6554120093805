import { useState, useEffect, memo } from "react";
import { useLocation, Routes, Route, Link } from "react-router-dom";
import { Drawer, Box, List, ListItem, ListItemText, Collapse } from "@mui/material";
import { makeStyles } from "@mui/styles";

import QnA from "./QnA";
import ExpertContactForm from "./ExpertContactForm";
import ToolsAndResources from "./ToolsAndResources";
import CoursesAndTraining from "./CoursesAndTraining";

const useStyles = makeStyles((theme) => ({
	background: {
		position: "fixed",
		background: "#1d4363",
		width: "300px",
		// position: "fixed",
		// background: theme.palette.dark.main,
	},
	menuItem: {
		color: theme.palette.common.white,
		padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
		cursor: "pointer",
		"&:hover[aria-disabled=false]": {
			backgroundColor: "#4a82c3",
			color: theme.palette.common.white,
		},
		"&[aria-checked=true][aria-disabled=false]": {
			backgroundColor: "#4a82c3",
		},
		"&[aria-disabled=true]": {
			pointerEvents: "none",
			cursor: "not-allowed",
		},
		"&:hover[aria-disabled=true]": {
			color: theme.palette.common.white,
		},
		"&[aria-checked=true][aria-disabled=true]": {
			color: theme.palette.common.white,
		},
	},
	submenuItem: {
		color: theme.palette.primary.light,
		padding: `${theme.spacing(1)} ${theme.spacing(5)}`,
		"&:hover[aria-disabled=false]": {
			backgroundColor: "#4a82c3",
			color: theme.palette.common.white,
		},
		"&[aria-checked=true][aria-disabled=false]": {
			backgroundColor: "#4a82c3",
			color: theme.palette.common.white,
		},
		"&[aria-disabled=true]": {
			pointerEvents: "none",
			cursor: "not-allowed",
		},
		"&:hover[aria-disabled=true]": {
			color: theme.palette.common.white,
		},
		"&[aria-checked=true][aria-disabled=true]": {
			color: theme.palette.common.white,
		},
	},
	routeDiv: {
		flex: 1,
		flexGrow: 1,
		margin: "0",
		float: "right",
		display: "flex",
		flexDirection: "column",
		overflow: "auto",
		maxWidth: "1300px",
	},
	outRouteDiv: {
		width: "100%",
		marginLeft: "300px",
		display: "flex",
		justifyContent: "center",
	},
}));

const availablePaths = new Set([
	"",
	"tools-and-resources",
	"courses-and-training",
	"q-n-a",
	"expert-contact-form",
]);

const CompanyTools = () => {
	const { pathname } = useLocation();

	const [value, setValue] = useState();

	const [height, setHeight] = useState(100);
	const classes = useStyles();

	useEffect(() => {
		const resizeObserver = new ResizeObserver((event) => {
			setHeight(event[0].contentBoxSize[0].blockSize);
		});

		resizeObserver.observe(document.querySelector("#header"));
	});

	const [selectedIndex, setSelectedIndex] = useState(window.location.pathname.split("/").at(-1));

	useEffect(() => {
		setSelectedIndex(window.location.pathname.split("/").at(-1));
	}, []);

	const handleCollapses = (section) => {
		switch (section) {
			case "learning-hub":
				if ([
					"tools-and-resources",
					"courses-and-training",
					"q-n-a",
					"expert-contact-form",
				].includes(selectedIndex)) {
					return true;
				}

				break;
			default:
				break;
		}

		return false;
	};

	useEffect(() => {
		setValue(() => {
			const pathParts = pathname.split("/");

			if (availablePaths.has(pathParts.at(-1)) && (pathParts.at(-2) === "learning-hub")) {
				return pathParts.at(-1);
			}

			return "tools-and-resources";
		});
	}, [pathname]);

	return (
		<div id="drawer-container" style={{ position: "relative", display: "flex", minHeight: `calc(100vh - ${height}px)` }}>
			<Drawer
				variant="permanent"
				sx={{
					width: "300px",
					height: "100%",
					position: "fixed",
					flexShrink: 0,
				}}
				ModalProps={{
					container: document.querySelector("#drawer-container"),
					style: { position: "absolute" },
				}}
				classes={{
					paperAnchorLeft: classes.background,
				}}
			>
				<Box sx={{ overflow: "auto", marginTop: "105px" }}>
					<List>
						<ListItem key="learning-hub" component={Link} aria-disabled="false" className={classes.menuItem} to="tools-and-resources">
							<ListItemText primary="Learning Hub" />
						</ListItem>
						<Collapse unmountOnExit in={handleCollapses("learning-hub")} timeout="auto">
							<List disablePadding component="div">
								<ListItem component={Link} aria-checked={value === "tools-and-resources"} className={classes.submenuItem} to="tools-and-resources" aria-disabled={false}>
									<ListItemText key="tools-and-resources" primary="Tools and Resources" />
								</ListItem>
								<ListItem component={Link} aria-checked={value === "courses-and-training"} className={classes.submenuItem} to="courses-and-training" aria-disabled={false}>
									<ListItemText key="courses-and-training" primary="Courses and Training" />
								</ListItem>
								<ListItem component={Link} aria-checked={value === "q-n-a"} className={classes.submenuItem} to="q-n-a" aria-disabled={false}>
									<ListItemText key="q-n-a" primary="Q & A" />
								</ListItem>
								<ListItem component={Link} aria-checked={value === "expert-contact-form"} className={classes.submenuItem} to="expert-contact-form" aria-disabled={false}>
									<ListItemText key="expert-contact-form" primary="Expert Contact Form" />
								</ListItem>
							</List>
						</Collapse>
					</List>
				</Box>
			</Drawer>
			<div className={classes.outRouteDiv}>
				<div className={classes.routeDiv}>
					<Routes>
						<Route path="/tools-and-resources/*" element={<ToolsAndResources />} />
						<Route path="/courses-and-training/*" element={<CoursesAndTraining />} />
						<Route path="/q-n-a/*" element={<QnA />} />
						<Route path="/expert-contact-form/*" element={<ExpertContactForm />} />
					</Routes>
				</div>
			</div>
		</div>
	);
};

export default memo(CompanyTools);
