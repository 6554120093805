import { memo, useMemo, forwardRef, useCallback, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Button, Grid, LinearProgress, Typography, Box, Chip, Tooltip, Paper, ToggleButton, Dialog, DialogContent, DialogTitle, Slide, ToggleButtonGroup, DialogContentText, DialogActions } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Edit } from "@mui/icons-material";
import { stringAToZInsensitive } from "@iamnapo/sort";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ClearIcon from "@mui/icons-material/Clear";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DoneIcon from "@mui/icons-material/Done";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { saveAs } from "file-saver";

import TextField from "../../components/TextField";
import { useSnackbar, isFuzzyMatch, httpResponseIsValid, dayjs } from "../../utils";
import { getProjectEquad, getAllProjectsEquad, updateEquadProject, downloadEquadReport, downloadEquadFile } from "../../api";
import Table from "../../components/Table";
import StatusDialog from "../../components/StatusDialog";
import equadImage from "../../assets/images/equad.png";

const theme2 = createTheme({
	palette: {
		valid: {
			main: "#51BEA5",
			contrastText: "#fff",
		},
		neutral: {
			main: "#1d4363",
			contrastText: "#fff",
		},
	},
});

const useStyles = makeStyles((theme) => ({
	boxBorder: {
		border: `2px solid ${theme.palette.primary.main}`,
		borderRadius: theme.spacing(2),
		backgroundColor: "white",
	},
	page: {
		height: "100%",
		display: "flex",
		overflowX: "scroll",
		flexDirection: "column",
		justifyContent: "center",
	},
	screenTitle: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		letterSpacing: theme.spacing(0.2),
	},
	dialog: {
		position: "relative",
		zIndex: theme.zIndex.modal + 1,
	},
	toggles: {
		"& .Mui-selected": {
			color: "#ffffff !important",
			backgroundColor: "#1d4363 !important",
		},
		"& .MuiToggleButton-root": {
			height: "40px",
			"&:hover": {
				color: "#1d4363",
				backgroundColor: "#F0F0F0",
			},
			color: "#6F96C6",
			backgroundColor: "#ffffff",
			borderRadius: "10px",
		},
	},
	button: {
		padding: theme.spacing(1),
		background: `url(${equadImage}) no-repeat center center`,
		backgroundSize: "cover",
		backgroundColor: "#F4F4F4",
		border: "none",
		width: "130px",
		height: "35px",
		borderRadius: "5px",
		marginLeft: "10px",
		marginBottom: "5px",
		cursor: "pointer",
		"&:hover": {
			opacity: 0.8,
			backgroundColor: "#F4F4F4",
		},
	},
}));

const Transition = forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const b64toByteArrays = (b64Data) => {
	const byteCharacters = atob(b64Data);
	const byteArrays = [];
	for (let offset = 0; offset < byteCharacters.length; offset += 512) {
		const slice = byteCharacters.slice(offset, offset + 512);
		const byteNumbers = Array.from({ length: slice.length });
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.codePointAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	return byteArrays;
};

const Equad = ({ disableAccess = true, isProjectDeveloper = false, onChangeProjectState }) => {
	const { projectid } = useParams();
	const [isLoading, setIsLoading] = useState(projectid !== "new-project");
	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(disableAccess);

	const [editToken, setEditToken] = useState(false);
	const [editSelectedProject, setEditSelectedProject] = useState(false);
	const [projectStateDialog, setProjectStateDialog] = useState(false);
	const [redirectDialog, setRedirectDialog] = useState(false);
	const [redirectedProjectName, setRedirectedProjectName] = useState("");
	const [savingType, setSavingType] = useState("Demand");

	const [equadProjects, setEquadProjects] = useState([]);
	const [equadHome, setEquadHome] = useState(false);

	const [projectInfo, setProjectInfo] = useState({
		token: "",
		project: "",
		status: "-",
		createdAt: "-",
	});

	const [tempData, setTempData] = useState({});

	const classes = useStyles();
	const { success, error } = useSnackbar();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			let response;
			try {
				response = await getProjectEquad(projectid);
				if (httpResponseIsValid(response)) {
					setProjectInfo(response);
					setTempData(response);

					if (response.invalid_token && response.token !== "") {
						error("Error: eQuad token has expired.");
					}
				} else {
					error(response?.message);
				}

				response = await getAllProjectsEquad(projectid);
				if (httpResponseIsValid(response)) {
					setEquadProjects(response.projects);
				}
			} catch (_error) {
				error(`Error occured while fetching eQuad's data: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, projectid],
	);

	const submit = async () => {
		setIsLoading(true);

		try {
			setEditToken(false);
			setEditSelectedProject(false);

			const response = await updateEquadProject(projectid, { ...tempData });
			if (httpResponseIsValid(response)) {
				await fetchData();
				success("Success: eQuad Data saved!");
			} else {
				error(response?.message);
			}
		} catch (_error) {
			error(`Error occured while updating equad's data: ${_error}`);
		}

		setIsLoading(false);
	};

	const deselectProject = async () => {
		setIsLoading(true);

		try {
			setEditToken(false);
			setEditSelectedProject(false);

			const response = await updateEquadProject(projectid, { ...tempData, project: { name: "", id: "" } });
			if (httpResponseIsValid(response)) {
				await fetchData();
				success("Success: eQuad project deselected!");
			}
		} catch (_error) {
			error(`Error occured while deselecting equad project: ${_error}`);
		}

		setIsLoading(true);
	};

	const displayProjectSelectionText = () => {
		if (projectInfo.invalid_token && projectInfo.token !== "") {
			return "Invalid token. Could not fetch eQuad Projects.";
		}

		if (equadProjects.length === 0) {
			return "No available projects to select.";
		}

		if (projectInfo.project.name === "") {
			return "Select a project.";
		}

		return projectInfo.project.name;
	};

	const onCancel = () => {
		setEditToken(false);
		setRedirectDialog(false);
		setEditSelectedProject(false);

		setTempData(projectInfo);
	};

	const onProjectSelect = (name) => {
		const selectedProject = equadProjects.find((p) => p.name === name);

		if (selectedProject) {
			setTempData({
				...tempData,
				project: selectedProject,
			});
		}
	};

	const onRedirect = () => {
		setRedirectDialog(false);

		let projectName = redirectedProjectName;
		if (projectName === "") {
			projectName = projectInfo.project.name;
		}

		const selectedProject = equadProjects.find((p) => p.name === projectName);

		if (equadHome) {
			window.open("https://staging-c3po.equadcapital.com");
		} else if (selectedProject) {
			window.open(`https://staging-c3po.equadcapital.com/projects/${selectedProject.id}/general`);
		} else {
			error("Error: Selected Project does not exist any more.");
		}
	};

	const showTokenLabel = () => {
		if (projectInfo.token === "") {
			return "No provided token.";
		}

		if (projectInfo.token === "hide") {
			return projectInfo.invalid_token ? "Invalid Token" : "Valid Token";
		}

		return projectInfo.token;
	};

	const onSelectSavingType = (e) => {
		setSavingType(e.target.value);
	};

	const onEditSelectedProject = async () => {
		setIsLoading(true);

		try {
			const response = await getAllProjectsEquad(projectid);
			if (httpResponseIsValid(response)) {
				setEquadProjects(response.projects);
			} else {
				error(response.message);
			}
		} catch (_error) {
			error(`Error occured while updating equad's data: ${_error}`);
		}

		setIsLoading(true);
		setEditSelectedProject(true);
	};

	const renderSavingType = (value) => {
		let type;

		switch (savingType) {
			case "Demand": {
				type = value?.demand_savings;
				break;
			}

			case "Annual Selling": {
				type = value?.annual_saving;
				break;
			}

			case "Consumption": {
				type = value?.consumption_savings;
				break;
			}

			case "tC02/a": {
				type = value?.tco2_a_savings;
				break;
			}

			case "Other": {
				type = value?.other_savings;
				break;
			}

			default: {
				type = "-";
			}
		}

		if (!type && type !== 0) {
			type = "-";
		}

		return type;
	};

	const downloadFile = async (value) => {
		setIsLoading(true);

		try {
			const response = await downloadEquadFile(projectid, value.collection_id, value.file_id);
			if (httpResponseIsValid(response)) {
				const element = document.createElement("a");
				element.setAttribute("href", `data:application/pdf;base64,${(response.file64)}`);
				element.setAttribute("download", value.name);
				element.style.display = "none";
				document.body.append(element);
				element.click();
				element.remove();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			error(`Error occured while fetching equad's file: ${_error}`);
		}

		setIsLoading(false);
	};

	const downloadReport = async () => {
		setIsLoading(true);

		const fileExtension = ".xlsx";
		const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

		try {
			const response = await downloadEquadReport(projectid);
			if (httpResponseIsValid(response)) {
				const buf = b64toByteArrays(response?.report);

				const blob = new Blob(buf, { type: fileType });

				saveAs(blob, `${projectInfo.project.name}${fileExtension}`);
			}
		} catch (_error) {
			error(`Error occured while fetching equad's report: ${_error}`);
		}

		setIsLoading(false);
	};

	const gridRef = useRef(null);
	const [tableOverflow, setTableOverflow] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			const width = gridRef.current.clientWidth;

			console.log(width);
			// Perform action based on width
			if (width > 1100) {
				setTableOverflow(false);
			} else {
				setTableOverflow(true);
			}
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [setTableOverflow]);

	const getTableSizeFactor = () => (tableOverflow ? 0.65 : 1);
	const getTableFontSize = () => (tableOverflow ? "h7" : "h6");

	const changeProjectState = useCallback(
		async () => {
			await onChangeProjectState();
			fetchData();
		}, [fetchData, onChangeProjectState],
	);

	useEffect(() => {
		setShouldUiBeDisabled(disableAccess);
	}, [disableAccess]);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData, projectid]);

	const tableColumns = useMemo(() => [
		{
			Header: <Typography id="name_header" variant="h6">{"Project Name"}</Typography>,
			accessor: "name",
			id: "name",
			filterable: true,
			width: 660,
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center" }}>
					<Typography
						sx={{ fontWeight: tempData.project.name === value ? "bold" : "normal" }}
						color={tempData.project.name === value ? "#3d8f7c" : "standard"}
					>
						{value}
					</Typography>
				</Box>
			),
		},
		{
			Header: <Typography id="name_header" variant="h6">{"Actions"}</Typography>,
			accessor: "name",
			id: "actions",
			width: 140,
			Cell: ({ value }) => (
				<Grid container item direction="row" justifyContent="space-around" alignItems="center">
					<ToggleButton
						value="edit info"
						title="Select"
						aria-label="edit info"
						size="small"
						sx={{ borderColor: "primary.main" }}
						disabled={tempData.project.name === value}
						onClick={() => onProjectSelect(value)}
					>
						<DoneIcon color="primary" />
					</ToggleButton>
					<ToggleButton
						value="edit info"
						title="Redirect"
						aria-label="edit info"
						size="small"
						sx={{ borderColor: "primary.main" }}
						onClick={() => { setRedirectedProjectName(value); setEquadHome(false); setRedirectDialog(true); }}
					>
						<OpenInNewIcon color="primary" />
					</ToggleButton>
				</Grid>
			),
		},
	], [tempData, equadProjects]);

	const projectDesignColumns = useMemo(() => [
		{
			Header: <Typography id="name_header" variant={getTableFontSize()}>{"Taxonomy 1st Level"}</Typography>,
			accessor: "taxonomy1stLevel",
			id: "taxonomy1stLevel",
			filterable: true,
			minWidth: 200 * getTableSizeFactor(),
			maxWidth: 350,
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography align="center">
						{value}
					</Typography>
				</Box>
			),
		},
		{
			Header: <Typography id="name_header" variant={getTableFontSize()}>{"Taxonomy 2nd Level"}</Typography>,
			accessor: "taxonomy2ndLevel",
			id: "taxonomy2ndLevel",
			minWidth: 250 * getTableSizeFactor(),
			maxWidth: 500,
			headerStyle: { whiteSpace: "unset" },
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
					<Typography align="center">
						{value}
					</Typography>
				</Box>
			),
		},
		{
			Header: <Typography id="name_header" variant={getTableFontSize()}>{"Site"}</Typography>,
			accessor: "site_name",
			id: "site_name",
			filterable: true,
			minWidth: 120 * getTableSizeFactor(),
			maxWidth: 200,
			headerStyle: { whiteSpace: "unset" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Grid container item direction="row" justifyContent="space-around" alignItems="center">
					<Typography align="center">
						{value}
					</Typography>
				</Grid>
			),
		},
		{
			Header: <Typography id="name_header" variant={getTableFontSize()}>{"Useful Life (Years)"}</Typography>,
			accessor: "useful_life",
			id: "useful_life",
			filterable: true,
			minWidth: 230 * getTableSizeFactor(),
			maxWidth: 300,
			headerStyle: { whiteSpace: "unset" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Grid container item direction="row" justifyContent="space-around" alignItems="center">
					<Typography align="center">
						{value}
					</Typography>
				</Grid>
			),
		},
		{
			Header: <Typography id="name_header" variant={getTableFontSize()}>{`${savingType} Savings (${projectInfo.project?.financial_metrics?.currencySign})`}</Typography>,
			accessor: "savings",
			id: "savings",
			minWidth: 250 * getTableSizeFactor(),
			maxWidth: 350,
			headerStyle: { whiteSpace: "unset" },
			Cell: ({ value }) => (
				<Grid container item direction="row" justifyContent="space-around" alignItems="center">
					<Typography align="center">
						{renderSavingType(value)}
					</Typography>
				</Grid>
			),
		},
	], [tempData, savingType, projectInfo.project?.financial_metrics?.currencySign, tableOverflow]);

	const documentColumns = useMemo(() => [
		{
			Header: <Typography id="name_header" variant={getTableFontSize()}>{"Documents"}</Typography>,
			accessor: "name",
			id: "name",
			filterable: true,
			minWidth: 300 * getTableSizeFactor(),
			maxWidth: 700,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Grid container item direction="row" justifyContent="space-around" alignItems="center">
					<Typography
						sx={{ fontWeight: tempData.selected_project === value ? "bold" : "normal" }}
						color={tempData.selected_project === value ? "#3d8f7c" : "standard"}
					>
						{value}
					</Typography>
				</Grid>
			),
		},
		{
			Header: <Typography id="type_header" variant={getTableFontSize()}>{"Type"}</Typography>,
			accessor: "type",
			id: "type",
			filterable: true,
			minWidth: 300 * getTableSizeFactor(),
			maxWidth: 550,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", minWidth: "400px", maxWidth: "550px" }}>
					<Typography
						sx={{ fontWeight: tempData.selected_project === value ? "bold" : "normal" }}
						color={tempData.selected_project === value ? "#3d8f7c" : "standard"}
					>
						{value}
					</Typography>
				</Box>
			),
		},
		{
			Header: <Typography id="upload_date_header" variant={getTableFontSize()}>{"Upload Date"}</Typography>,
			accessor: "upload_date",
			id: "upload_date",
			filterable: true,
			minWidth: 230 * getTableSizeFactor(),
			maxWidth: 400,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center", minWidth: "250px", maxWidth: "400px" }}>
					<Typography>{dayjs(value).format("DD/MM/YYYY")}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography id="upload_target_header" variant={getTableFontSize()}>{"Download"}</Typography>,
			accessor: "upload_target",
			id: "actions",
			minWidth: 200 * getTableSizeFactor(),
			maxWidth: 400,
			style: { overflow: "visible" },
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center", minWidth: "200px", maxWidth: "400px" }}>
					<ToggleButton
						value="edit info"
						title="Select"
						aria-label="edit info"
						size="small"
						sx={{ borderColor: "primary.main" }}
						onClick={() => downloadFile(value)}
					>
						<SystemUpdateAltIcon color="primary" />
					</ToggleButton>
				</Box>
			),
		},
	], [tempData, tableOverflow]);

	return (
		<>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<Grid container direction="column" alignItems="center" py={1} px={3} sx={{ "> .MuiGrid-item": { p: 1 }, position: "fixed", maxWidth: "1300px", width: "calc(100% - 280px)", background: "white", zIndex: 999 }}>
				<Grid container item direction="row" alignItems="center" sx={{ "> .MuiGrid-item": { p: 0 }, background: "#F4F4F4", borderRadius: "10px 10px 0px 0px" }}>
					<Grid item xs={12} md={1}>
						<Typography variant="h5" className={classes.screenTitle}>{"eQuad"}</Typography>
					</Grid>
					<Grid item xs={12} md={5}>
						<Button variant="contained" className={classes.button} onClick={() => { setEquadHome(true); setRedirectDialog(true); }} />
					</Grid>
					<Grid item xs={0} md={1} />
					<Grid item container className={classes.boxBorder} sx={{ p: 1 }} xs={12} md={5}>
						<Grid item xs={12} md={6}>
							<Box p={1}>
								<Grid container direction="row" justifyContent="start" alignItems="center">
									<Grid item xs={4}>
										<Typography variant="h6">{"Status: "}</Typography>
									</Grid>
									<Grid item container xs={8} justifyContent="start">
										<Typography variant="h6" color={(projectInfo.status === "Approved") ? "green.main" : ((projectInfo.status === "Rejected") ? "error.main" : "primary.main")}>{projectInfo.status}</Typography>
									</Grid>
								</Grid>
							</Box>
						</Grid>
						<Grid item container xs={12} md={6} alignItems="center" justifyContent="end">
							<Button
								variant="contained"
								size="medium"
								sx={{ width: "170px", backgroundColor: "#5C9AE1", color: "common.white", ":hover": { backgroundColor: "primary.main" }, px: 3, marginRight: "4px", borderRadius: "10px" }}
								disabled={!isProjectDeveloper}
								onClick={() => setProjectStateDialog(true)}
							>
								{projectInfo.status === "In Progress" ? "Submit Project" : "Revert Project"}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "50px" }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{"CREDENTIALS"}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems="center">
					<Grid item xs={12} md={2}>
						<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
							{"eQuad Token"}
							<Tooltip placement="bottom-start" title="Set the token extracted from the profile screen of eQuad.">
								<InfoIcon fontSize="small" sx={{ padding: 0.2, color: "green.main" }} />
							</Tooltip>
						</Typography>
					</Grid>
					<Grid item container xs={12} md={10} direction="row" justifyContent="start" alignContent="center">
						<Grid item xs={12} md={5}>
							<ThemeProvider theme={theme2}>
								<Chip
									label={<Typography noWrap sx={{ overflow: "hidden", textOverflow: "ellipsis", maxWidth: "300px" }}>{showTokenLabel()}</Typography>}
									sx={{ width: "calc(100% - 55px)", height: "55px", fontSize: "15px", borderColor: "primary.main", borderRadius: "15px 0px 0px 15px" }}
									color={projectInfo.token === "" ? "neutral" : projectInfo.invalid_token ? "error" : "valid"}
									variant="outlined"
									iconColorPrimary="error"
								/>
								<ToggleButton
									value="edit info"
									title="Edit Token"
									aria-label="edit info"
									size="small"
									sx={{
										width: "55px",
										height: "55px",
										borderColor: "primary.main",
										borderRadius: "0px 15px 15px 0px",
									}}
									disabled={shouldUiBeDisabled}
									onClick={() => setEditToken(true)}
								>
									<Edit color="primary" />
								</ToggleButton>
							</ThemeProvider>
						</Grid>
						<Grid item xs={12} md={7} />
					</Grid>
				</Grid>
				<Grid item container sx={{ p: 1, marginTop: "10px" }} alignItems="center">
					<Grid item xs={12} md={2}>
						<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363", marginTop: "10px" }}>
							{"eQuad Project"}
						</Typography>
					</Grid>
					<Grid item container xs={12} md={10} direction="row" justifyContent="space-between" alignContent="center">
						<Grid item xs={12} md={5}>
							{!shouldUiBeDisabled
								&& (
									<Chip
										label={displayProjectSelectionText()}
										sx={{ width: "calc(100% - 55px)", height: "55px", fontSize: "15px", borderRadius: "15px 0px 0px 15px" }}
										color="primary"
										variant="outlined"
										disabled={projectInfo.invalid_token || equadProjects.length === 0}
										deleteIcon={<KeyboardArrowDownIcon />}
										iconColorPrimary="error"
										onClick={() => onEditSelectedProject()}
										onDelete={() => onEditSelectedProject()}
									/>
								)}
							{shouldUiBeDisabled
								&& (
									<Chip
										label={displayProjectSelectionText()}
										sx={{ width: "calc(100% - 55px)", height: "55px", fontSize: "15px", borderRadius: "15px 0px 0px 15px" }}
										color="primary"
										variant="outlined"
										disabled={projectInfo.invalid_token || equadProjects.length === 0}
										deleteIcon={<KeyboardArrowDownIcon />}
										iconColorPrimary="error"
									/>
								)}
							<ToggleButton
								value="edit info"
								title="Deselect Project"
								aria-label="edit info"
								size="small"
								// eslint-disable-next-line max-len
								disabled={projectInfo.invalid_token || equadProjects.length === 0 || !projectInfo.project.name || shouldUiBeDisabled}
								sx={{ width: "55px", height: "55px", borderColor: "primary.main", borderRadius: "0px 15px 15px 0px" }}
								onClick={deselectProject}
							>
								<ClearIcon color="primary" />
							</ToggleButton>
						</Grid>
						<Grid item container xs={12} md={7} direction="row" justifyContent="end" alignContent="center">
							<Grid item xs={12} md={4}>
								<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363", marginTop: "15px" }}>
									{"Download Report"}
								</Typography>
							</Grid>
							<Grid item xs={12} md={1}>
								<ToggleButton
									value="edit info"
									title="Download File"
									aria-label="edit info"
									size="small"
									disabled={projectInfo.invalid_token || equadProjects.length === 0 || !projectInfo.project.name}
									sx={{ width: "55px", height: "55px", borderColor: "primary.main", borderRadius: "15px" }}
									onClick={downloadReport}
								>
									<SystemUpdateAltIcon color="primary" />
								</ToggleButton>
							</Grid>
							<Grid item xs={12} md={1} />
							<Grid item xs={12} md={4}>
								<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363", marginTop: "15px" }}>
									{"Open in eQuad"}
								</Typography>
							</Grid>
							<Grid item xs={12} md={1} sx={{ marginRight: "5px" }}>
								<ToggleButton
									value="edit info"
									title="Open in a new Tab"
									aria-label="edit info"
									size="small"
									disabled={projectInfo.invalid_token || equadProjects.length === 0 || !projectInfo.project.name}
									sx={{ width: "55px", height: "55px", borderColor: "primary.main", borderRadius: "15px" }}
									onClick={() => { setEquadHome(false); setRedirectDialog(true); }}
								>
									<OpenInNewIcon color="primary" />
								</ToggleButton>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{!(projectInfo.project.name && !projectInfo.invalid_token)
				&& (
					<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 } }} alignItems="center" justifyContent="center">
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
							<Typography variant="h6" color="common.white" alignItems="center">
								{projectInfo.project.name && !projectInfo.invalid_token ? projectInfo.project.name : "No Available Project"}
							</Typography>
						</Grid>
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#D5DCE0">
							<Typography variant="h6" color="common.white" alignItems="center">
								{"Financial Metrics"}
							</Typography>
						</Grid>
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#D5DCE0">
							<Typography variant="h6" color="common.white" alignItems="center">
								{"PROJECT DESIGN"}
							</Typography>
						</Grid>
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#D5DCE0">
							<Typography variant="h6" color="common.white" alignItems="center">
								{"DOCUMENTS"}
							</Typography>
						</Grid>
					</Grid>
				)}
			{projectInfo.project.name && !projectInfo.invalid_token
				&& (
					<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 } }} alignItems="center" justifyContent="center">
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#6F96C6">
							<Typography variant="h6" color="common.white" alignItems="center">
								{"Financial Metrics"}
							</Typography>
						</Grid>
						<Grid item container sx={{ p: 1, marginTop: "10px", marginLeft: "30px" }} justifyContent="space-between">
							<Grid item xs={12} md={2}>
								<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363", marginTop: "10px" }}>
									{"IRR (%)"}
								</Typography>
							</Grid>
							<Grid item xs={12} md={2} display="flex" justifyContent="end" marginRight="30px">
								<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363", marginTop: "10px" }}>
									{`${projectInfo.project.financial_metrics?.irr}`}
								</Typography>
							</Grid>
						</Grid>
						<Grid item container sx={{ p: 1, marginTop: "10px", marginLeft: "30px" }} justifyContent="space-between">
							<Grid item xs={12} md={2}>
								<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363", marginTop: "10px" }}>
									{`NPV (${projectInfo.project.financial_metrics?.currencySign})`}
								</Typography>
							</Grid>
							<Grid item xs={12} md={2} display="flex" justifyContent="end" marginRight="30px">
								<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363", marginTop: "10px" }}>
									{`${projectInfo.project.financial_metrics?.npv}`}
								</Typography>
							</Grid>
						</Grid>
						<Grid item container sx={{ p: 1, marginTop: "10px", marginLeft: "30px" }} justifyContent="space-between">
							<Grid item xs={12} md={2}>
								<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363", marginTop: "10px" }}>
									{"Payback (years)"}
								</Typography>
							</Grid>
							<Grid item xs={12} md={2} display="flex" justifyContent="end" marginRight="30px">
								<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363", marginTop: "10px" }}>
									{`${projectInfo.project.financial_metrics?.payback}`}
								</Typography>
							</Grid>
						</Grid>
						<Grid item container sx={{ p: 1, marginTop: "10px", marginLeft: "30px" }} justifyContent="space-between">
							<Grid item xs={12} md={2}>
								<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363", marginTop: "10px" }}>
									{"Investment Multiplier"}
								</Typography>
							</Grid>
							<Grid item xs={12} md={2} display="flex" justifyContent="end" marginRight="30px">
								<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363", marginTop: "10px" }}>
									{`${projectInfo.project.financial_metrics?.annualCashFlows}`}
								</Typography>
							</Grid>
						</Grid>
						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={2} bgcolor="#6F96C6">
							<Typography variant="h6" color="common.white" alignItems="center">
								{"Project Design"}
							</Typography>
						</Grid>
						<Grid item container sx={{ p: 1, margin: "10px 10px 0px 10px" }} justifyContent="space-between">
							<Grid item xs={12} md={2}>
								<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363", marginTop: "10px" }}>
									{"Savings type"}
								</Typography>
							</Grid>
							<Grid item container xs={12} md={8} justifyContent="end">
								<ToggleButtonGroup
									exclusive
									color="primary"
									value={savingType}
									aria-label="Platform"
									className={classes.toggles}
									onChange={onSelectSavingType}
								>
									<ToggleButton value="Demand">{"Demand"}</ToggleButton>
									<ToggleButton value="Annual Selling">{"Annual Selling"}</ToggleButton>
									<ToggleButton value="Consumption">{"Consumption"}</ToggleButton>
									<ToggleButton value="tC02/a">{"tC02/a"}</ToggleButton>
									<ToggleButton value="Other">{"Other"}</ToggleButton>
								</ToggleButtonGroup>
							</Grid>
						</Grid>
						<Grid ref={gridRef} item container sx={{ p: 1 }} mt={1} alignContent="center" justifyContent="center">
							<div className={clsx("container", classes.page)}>
								<Table
									data={projectInfo.project?.project_design}
									noDataText={isLoading ? "Fetching projects..." : "No data available!"}
									columns={projectDesignColumns}
									defaultSorted={[{ id: "name", desc: true }]}
								/>
							</div>
						</Grid>

						<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#6F96C6">
							<Typography variant="h6" color="common.white" alignItems="center">
								{"Documents"}
							</Typography>

						</Grid>
						<Grid ref={gridRef} item container sx={{ p: 1 }} mt={1} alignItems="start" justifyContent="center">
							<div className={clsx("container", classes.page)}>
								<Table
									data={projectInfo.project.documents}
									noDataText={isLoading ? "Fetching projects..." : "No data available!"}
									columns={documentColumns}
									defaultSorted={[{ id: "name", desc: true }]}
								/>
							</div>
						</Grid>
					</Grid>
				)}



			{/* <Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={2} bgcolor="#6F96C6">
								<Typography variant="h6" color="common.white" alignItems="center">
									{"Project Design"}
								</Typography>
							</Grid>
							<Grid item container sx={{ p: 1, margin: "10px 10px 0px 10px" }} justifyContent="space-between">
								<Grid item xs={12} md={2}>
									<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363", marginTop: "10px" }}>
										{"Savings type"}
									</Typography>
								</Grid>
								<ToggleButtonGroup
									exclusive
									color="primary"
									value={savingType}
									aria-label="Platform"
									className={classes.toggles}
									onChange={onSelectSavingType}
								>
									<ToggleButton value="Demand">{"Demand"}</ToggleButton>
									<ToggleButton value="Annual Selling">{"Annual Selling"}</ToggleButton>
									<ToggleButton value="Consumption">{"Consumption"}</ToggleButton>
									<ToggleButton value="tC02/a">{"tC02/a"}</ToggleButton>
									<ToggleButton value="Other">{"Other"}</ToggleButton>
								</ToggleButtonGroup>
							</Grid>
							<Grid item container sx={{ p: 1 }} mt={1} alignContent="center" justifyContent="center">
								<Table
									data={projectInfo.project?.project_design}
									noDataText={isLoading ? "Fetching projects..." : "No data available!"}
									columns={projectDesignColumns}
									defaultSorted={[{ id: "name", desc: true }]}
								/>
							</Grid> */}
			{/* <Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={2} bgcolor="#6F96C6">
									<Typography variant="h6" color="common.white" alignItems="center">
										{"Documents"}
									</Typography>
								</Grid>
								<Grid item container sx={{ p: 1 }} mt={1} alignItems="start" justifyContent="center">
									
								</Grid> */}

			<Dialog
				fullWidth
				open={editToken}
				TransitionComponent={Transition}
				maxWidth="md"
				scroll="body"
				PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4" } }}
				onClose={() => setEditToken(false)}
			>
				<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
					<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
						{"Edit eQuad Token"}
					</Typography>
				</DialogTitle>
				<DialogContent dividers sx={{ overflowY: "hidden" }}>
					<Grid item container sx={{ p: 1 }} mt={1} alignItems="start">
						<Grid item xs={12} md={3}>
							<Typography variant="h6" fontWeight="bold" sx={{ color: "#1d4363" }}>
								{"New Token"}
							</Typography>
						</Grid>
						<Grid item xs={12} md={9}>
							<TextField
								fullWidth
								multiline
								id="project-description"
								value={tempData.token}
								variant="filled"
								minRows={4}
								maxRows={5}
								placeholder="No token provided."
								onChange={(e) => setTempData({ ...tempData, token: e.target.value })}
							/>
						</Grid>
					</Grid>
					<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="10px">
						<Grid item sm={3} display="flex" justifyContent="start">
							<Button
								fullWidth
								variant="outlined"
								size="large"
								sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
								color="primary"
								onClick={onCancel}
							>
								{"Cancel"}
							</Button>
						</Grid>
						<Grid item sm={1} />
						<Grid item display="flex" sm={2} justifyContent="end">
							<Button
								fullWidth
								variant="contained"
								size="large"
								sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
								color="primary"
								disabled={projectInfo.token === tempData.token || tempData.token === ""}
								onClick={submit}
							>
								{"Save"}
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>

			<Dialog
				fullWidth
				open={editSelectedProject}
				TransitionComponent={Transition}
				maxWidth="md"
				scroll="body"
				PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4", maxHeight: "800px" } }}
				onClose={() => setEditSelectedProject(false)}
			>
				<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
					<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
						{"Select eQuad Project"}
					</Typography>
				</DialogTitle>
				<DialogContent dividers sx={{ overflowY: "hidden" }}>
					<Grid item container sx={{ p: 1 }} mt={1} alignItems="start" justifyContent="center">
						<Table
							data={equadProjects}
							noDataText={isLoading ? "Fetching projects..." : "No data available!"}
							columns={tableColumns}
							defaultSorted={[{ id: "name", desc: true }]}
						/>
					</Grid>
					<Grid container mt={3} alignItems="center" justifyContent="space-between" marginTop="5px">
						<Grid item sm={3} display="flex" justifyContent="start" marginLeft="25px">
							<Button
								fullWidth
								variant="outlined"
								size="large"
								sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
								color="primary"
								onClick={onCancel}
							>
								{"Cancel"}
							</Button>
						</Grid>
						<Grid item sm={1} />
						<Grid item display="flex" sm={2} justifyContent="end" marginRight="25px">
							<Button
								fullWidth
								variant="contained"
								size="large"
								sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
								color="primary"
								onClick={submit}
							>
								{"Save"}
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
			<Paper className={classes.dialog} elevation={10}>
				<Dialog
					open={redirectDialog}
					TransitionComponent={Transition}
					maxWidth="md"
					scroll="body"
					PaperProps={{ sx: { borderRadius: 2.5, boxShadow: "shadows.4", maxWidth: "700px", zIndex: 999 } }}
					onClose={() => setRedirectDialog(false)}
				>
					<DialogTitle component="h6" sx={{ bgcolor: "#1d4363", boxShadow: (t) => t.tileShadow, m: 0, p: 1 }}>
						<Typography sx={{ fontWeight: "bold", color: "common.white", marginLeft: "10px" }}>
							{"Are you sure?"}
						</Typography>
					</DialogTitle>
					<DialogContent dividers sx={{ overflowY: "hidden" }}>
						<DialogContentText>
							{equadHome && (
								<Grid item container sx={{ p: 1 }} alignItems="start" justifyContent="center">
									<Typography>
										{"Are you sure you want to be"}
										&nbsp;
										<b>{"redirected"}</b>
										&nbsp;
										{"to "}
										&nbsp;
									</Typography>
									<Typography sx={{ color: "blue" }}>
										{" [https://staging-c3po.equadcapital.com/]"}
										&nbsp;
									</Typography>
									<Typography>
										{"hosting eQuad's"}
										&nbsp;
										{"home page?"}
									</Typography>
								</Grid>
							)}
							{!equadHome && (
								<Grid item container sx={{ p: 1 }} alignItems="start" justifyContent="center">
									<Typography>
										{"Are you sure you want to be"}
										&nbsp;
										<b>{"redirected"}</b>
										&nbsp;
										{"to"}
										&nbsp;
									</Typography>
									<Typography sx={{ color: "blue" }}>
										{" [https://staging-c3po.equadcapital.com/]"}
										&nbsp;
									</Typography>
									<Typography>
										{"hosting eQuad's"}
										&nbsp;
										<b>{`[${redirectedProjectName || projectInfo.project.name}]`}</b>
										&nbsp;
										{"page?"}
									</Typography>
								</Grid>
							)}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Grid container alignItems="center" justifyContent="space-between">
							<Grid item sm={3} display="flex" justifyContent="start">
								<Button
									fullWidth
									variant="outlined"
									size="large"
									sx={{ width: "150px", borderColor: "#51BEA5", backgroundColor: "white", color: "#51BEA5", ":hover": { borderColor: "#3d8f7c", backgroundColor: "#3d8f7c", color: "white" }, px: 3 }}
									color="primary"
									onClick={() => { setRedirectDialog(false); setRedirectedProjectName(""); }}
								>
									{"Cancel"}
								</Button>
							</Grid>
							<Grid item sm={1} />
							<Grid item display="flex" sm={3} justifyContent="end">
								<Button
									fullWidth
									variant="contained"
									size="large"
									sx={{ width: "150px", backgroundColor: "#51BEA5", color: "common.white", ":hover": { backgroundColor: "#3d8f7c" }, px: 3 }}
									color="primary"
									onClick={onRedirect}
								>
									{"Redirect"}
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				</Dialog>
			</Paper>
			<StatusDialog
				open={projectStateDialog}
				status={projectInfo.status}
				name={projectInfo.name}
				onClose={setProjectStateDialog}
				onChange={changeProjectState}
			/>
		</>
	);
};

export default memo(Equad);
