import { makeStyles } from "@mui/styles";

export const useTaskTableStyles = makeStyles((theme) => ({
	root: {
		height: theme.spacing(2),
	},
	bar: {
		borderRadius: 2.5 * theme.shape.borderRadius,
		backgroundColor: theme.palette.secondary.main,
	},
	determinate: {
		borderRadius: 2.5 * theme.shape.borderRadius,
		backgroundColor: theme.palette.grey.transparent,
	},
	progressText: {
		position: "absolute",
		top: "50%",
		left: "80%",
		marginTop: theme.spacing(-1.2),
		height: theme.spacing(2),
		marginLeft: theme.spacing(-1.2),
		fontWeight: 500,
	},
	caption: {
		wordWrap: "break-word",
		whiteSpace: "normal",
		display: "flex",
		alignItems: "center",
	},
}), { index: 1 });

export const useBoxStyles = makeStyles((theme) => ({
	box: {
		padding: theme.spacing(1),
		marginBottom: theme.spacing(1),
		borderWidth: theme.spacing(0.2),
		borderStyle: "solid",
		borderColor: theme.palette.primary.main,
		borderRadius: 2.5 * theme.shape.borderRadius,
	},
}), { index: 1 });
