import { memo, useCallback, useEffect, useState, forwardRef } from "react";
import { useParams } from "react-router-dom";
import { Button, Grid, LinearProgress, Typography, Dialog, Slide, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import { useSnackbar, httpResponseIsValid } from "../../utils";
import { deleteFile, getProjectRiskAssessentProtocol, updateProjectRiskAssessentProtocol } from "../../api";
import StatusDialog from "../../components/StatusDialog";
import {
	MainMenu,
	LabelTextField,
	LabelDropdown,
	LabelRadioTextField,
	LabelFileUpload,
	LabelRadio,
} from "../../components/basic/index";

const Transition = forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const currencies = {
	empty: "",
	euro: "Euro (€)",
	dollar: "Dollar ($)",
	pound: "GBP (£)",
	chf: "CHF",
	zloty: "Zloty (zl)",
};

const CurrencyRisk = ({ disableAccess = true, isProjectDeveloper = false, onChangeProjectState }) => {
	const { projectid } = useParams();
	const [shouldUiBeDisabled, setShouldUiBeDisabled] = useState(disableAccess);
	const [projectStateDialog, setProjectStateDialog] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [projectInfo, setProjectInfo] = useState({
		currency: "",
		status: "",
		createdAt: "-",
		currency_risk_doc_1: {
			original_name: "",
			save_name: "",
		},
		currency_risk: {
			currency_risk_relevance: "",
			currency_risk_mitigation: "",
			hedge_currency_risk: "",
			duration_and_percentage: "",
			single_currency: "",
			currency: "",
			cash_flows_currency: "",
			equipment_currency: "",
			operational_expense_currency: "",
			operational_expense_currency_radio: "",
			operational_expense: "",
		},

	});
	const [enableEditing, setEnableEditing] = useState(false);
	const { success, error } = useSnackbar();
	const [deleteModal, setDeleteModal] = useState({ component: null, variable: null });

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			try {
				const response = await getProjectRiskAssessentProtocol(projectid);
				if (httpResponseIsValid(response)) {
					setProjectInfo(response);
				} else {
					error(response?.message);
				}
			} catch (_error) {
				error(`Error occured while fetching risk assessment currency risk data: ${_error}`);
			}

			setIsLoading(false);
		},
		[error, projectid],
	);

	const handleSubmit = async (values) => {
		setIsLoading(true);

		try {
			const response = await updateProjectRiskAssessentProtocol(projectid, values);

			if (httpResponseIsValid(response)) {
				success(response?.message);
				await fetchData();
			} else {
				error(response?.message);
			}
		} catch (_error) {
			error(`Error occured while submitting risk assessment regulatory risk language: ${_error}`);
		}

		setIsLoading(false);
	};

	const changeProjectState = useCallback(
		async () => {
			await onChangeProjectState();
			fetchData();
		}, [fetchData, onChangeProjectState],
	);

	const onDelete = async (comp) => {
		try {
			setIsLoading(true);
			const { success: deleteSuccess } = await deleteFile({ projectid, component: comp.component, variable: comp.variable });
			console.log(deleteSuccess);
			if (deleteSuccess) {
				success("File deleted successfully");
				await fetchData(false);
				setIsLoading(false);
			} else {
				error();
				setIsLoading(false);
			}
		} catch { /* empty */ }
	};

	const { t } = useTranslation();
	const formik = useFormik({
		initialValues: projectInfo,
		onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
	});

	const { dirty, values } = formik;

	useEffect(() => {
		setShouldUiBeDisabled(disableAccess || !enableEditing);
	}, [disableAccess, enableEditing]);

	useEffect(() => {
		formik.resetForm({ values: projectInfo, dirty: false });
	}, [projectInfo]);

	useEffect(() => {
		(async () => {
			try {
				await fetchData();
			} catch { /* empty */ }
		})();
	}, [error, fetchData, projectid]);

	return (
		<form onSubmit={formik.handleSubmit}>
			{isLoading && (<LinearProgress sx={{ width: "100%" }} color="primary" />)}
			<MainMenu
				title={t("RiskAssessmentOverview.currencyRisk")}
				dirty={dirty}
				project={projectInfo}
				isDeveloper={isProjectDeveloper}
				disabled={disableAccess}
				onFetch={fetchData}
				onEdit={setEnableEditing}
				onChange={onChangeProjectState}
			/>
			<Grid container p={3} sx={{ "> .MuiGrid-item": { p: 1 }, marginTop: "105px" }}>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} mt={1} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("RegulatoryRisk.relevance")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} alignItems="top">
					<Grid item xs={12} md={9} sm={8} p={1}>
						<LabelRadio
							id="currency_risk.single_currency"
							label={t("CurrencyRisk.relevanceVar1")}
							size="flat"
							options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
							disabled={shouldUiBeDisabled}
							typosize="h8"
							formik={formik}
						/>
						<LabelDropdown
							id="currency_risk.currency"
							size="flat"
							label={t("CurrencyRisk.relevanceVar2")}
							options={currencies}
							disabled={shouldUiBeDisabled || values.currency_risk.single_currency === "false"}
							typosize="h8"
							// infoText={t("LoanApplication.Overview.LoanOverview.info2")}
							formik={formik}
						/>
						<Typography variant="h6" color="blue.dark" alignItems="center">
							{"\n"}
							{t("CurrencyRisk.relevanceVar3")}
							{"\n"}
						</Typography>
						<LabelDropdown
							id="currency_risk.cash_flows_currency"
							size="flat"
							label={t("CurrencyRisk.relevanceVar4")}
							options={currencies}
							disabled={shouldUiBeDisabled || values.currency_risk.single_currency === "true"}
							// infoText={t("LoanApplication.Overview.LoanOverview.info2")}
							typosize="h8"
							formik={formik}
						/>
						<LabelDropdown
							id="currency_risk.equipment_currency"
							size="flat"
							label={t("CurrencyRisk.relevanceVar5")}
							options={currencies}
							disabled={shouldUiBeDisabled || values.currency_risk.single_currency === "true"}
							// infoText={t("LoanApplication.Overview.LoanOverview.info2")}
							typosize="h8"
							formik={formik}
						/>
						<LabelRadioTextField
							radioId="currency_risk.operational_expense_currency_radio"
							fieldId="currency_risk.operational_expense"
							label={t("CurrencyRisk.relevanceVar6")}
							size="flat"
							options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
							disabled={shouldUiBeDisabled}
							typosize="h8"
							formik={formik}
						/>
						<LabelDropdown
							id="currency_risk.operational_expense_currency"
							size="flat"
							label={t("- Please State the Operational Expense Currency")}
							options={currencies}
							disabled={shouldUiBeDisabled || values.currency_risk.single_currency === "false"}
							// infoText={t("LoanApplication.Overview.LoanOverview.info2")}
							formik={formik}
							typosize="h8"
						/>
					</Grid>
					<Grid item md={0.2} />
					<Grid item xs={12} sm={4} md={2} direction="row" alignItems="center">
						<LabelRadio
							id="currency_risk.currency_risk_relevance"
							label={t("RegulatoryRisk.riskPresent")}
							size="large"
							options={[{ value: "false", label: "No" }, { value: "true", label: "Yes" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
					</Grid>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("EnergyPriceRisk.mitigation")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} bgcolor="grey.main" alignItems="top">
					<Grid item xs={12} md={9} sm={8} p={1}>

						<LabelTextField
							id="currency_risk.hedge_currency_risk"
							label={t("CurrencyRisk.mitigationVar1")}
							size="flat"
							typosize="h8"
							aligntitlebox="center"
							placeholder="Answer"
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
						<LabelTextField
							id="currency_risk.duration_and_percentage"
							label={t("CurrencyRisk.mitigationVar2")}
							size="flat"
							typosize="h8"
							aligntitlebox="center"
							placeholder="Answer"
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
					</Grid>
					<Grid item md={0.2} />
					<Grid item xs={12} sm={4} md={2} direction="row" alignItems="center">
						<LabelRadio
							id="currency_risk.currency_risk_responsibility"
							label={t("RegulatoryRisk.riskBearer")}
							size="large"
							options={[{ value: "false", label: "Client" }, { value: "true", label: "Contractor" }]}
							disabled={shouldUiBeDisabled}
							formik={formik}
						/>
					</Grid>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }} bgcolor="#1d4363">
					<Typography variant="h6" color="common.white" alignItems="center">
						{t("RegulatoryRisk.documentation")}
					</Typography>
				</Grid>
				<Grid item container sx={{ p: 1 }} alignItems="center">
					<LabelFileUpload
						fieldId="currency_risk_doc_1"
						projectId={projectid}
						label={t("CurrencyRisk.documentationVar1")}
						size="large"
						disabled={shouldUiBeDisabled}
						formik={formik}
						fileTool="riskAssessmentProtocol"
						typosize="h8"
						onSuccess={() => fetchData()}
					/>
				</Grid>
			</Grid>
			{deleteModal.component !== null
				&& deleteModal.variable !== null
				&& (
					<Dialog
						keepMounted
						open={deleteModal.component !== null && deleteModal.variable !== null}
						TransitionComponent={Transition}
						onClose={() => setDeleteModal({ component: null, variable: null })}
					>
						<DialogTitle>
							{"Are you sure?"}
						</DialogTitle>
						<DialogContent dividers>
							<DialogContentText>
								{"Are you sure you want to delete the file?"}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								autoFocus
								startIcon={<Delete />}
								variant="contained"
								onClick={() => {
									onDelete(deleteModal);
									setDeleteModal({ component: null, variable: null });
								}}
							>
								{"Delete"}
							</Button>
							<Button variant="outlined" onClick={() => setDeleteModal({ component: null, variable: null })}>{"Cancel"}</Button>
						</DialogActions>
					</Dialog>
				)}
			<StatusDialog
				open={projectStateDialog}
				status={projectInfo.status}
				name={projectInfo.name}
				onClose={setProjectStateDialog}
				onChange={changeProjectState}
			/>
		</form>
	);
};

export default memo(CurrencyRisk);
